import React from "react";
import styled from "styled-components";

import themes from "components/themes";

interface Props {
  children: React.ReactNode;
  className?: string;
  name?: string;
  spacing?: boolean;
  optional?: boolean;
}

function FormLabel({
  children,
  className = "",
  name,
  spacing = true,
  optional = false,
}: Props) {
  return (
    <Wrapper spacing={spacing ? 35 : 0}>
      <Label htmlFor={name} className={className}>
        {children}
      </Label>
      {optional && <OptionalLabel>(Optional)</OptionalLabel>}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ spacing: number }>`
  margin-top: ${(props: any) => `${props.spacing}px`};
  display: flex;
  justify-content: space-between;
`;

const Label = styled.label`
  margin-bottom: 12px;
  font-size: 16px;
  color: ${themes.colors.white};
`;

const OptionalLabel = styled.label`
  margin-bottom: 12px;
  opacity: 0.4;
  font-size: 14px;
  color: #ffffff;
`;

export default FormLabel;
