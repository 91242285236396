import React, { FC, useEffect, useContext } from "react";
import { styled } from "@mui/system";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { observer } from "mobx-react";

import { useStores } from "contexts/storeContext";

import { GET_USER_BILLING_INFO } from "api";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";
import { failedPaymentsRoutes } from "v2/routes/routesGroups/failedPaymentsRoutes";

import { ApplicationContext, ModalContext } from "contexts";

import { Section, Icons, Icon, Error, Spinner, Title } from "components";
import { InvoiceList } from "./components/InvoiceList";
import { PricingSection } from "./components/PricingSection";

import { StaticTable } from "v2/components/Table/StaticTable";

import { Customer } from "screens/Billing/UserBillingDetails/constants/types";
import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";
import { TdComponent } from "v2/components/Table/components/TdComponent";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- COMPONENT
const UserBillingDetailsPage: FC<RouteComponentProps> = ({ history }) => {
  const applicationCtx = useContext(ApplicationContext);
  const setModal = useContext(ModalContext);

  const { userStore } = useStores();

  useEffect(() => {
    applicationCtx.current && applicationCtx.setCurrent(null);
  }, [applicationCtx]);

  useEffect(() => {
    if (userStore.isOverdue) {
      setModal({
        type: "set",
        props: {
          title: "There was a problem with your payment method",
          content:
            "The current outstanding invoice is overdue. Please make payment for continued access.",
          confirmText: "Pay",
          onHide: () =>
            history &&
            history.push(
              failedPaymentsRoutes.FAILED_PAYMENTS.getUrlWithParams(),
            ),
        },
      });
    }
  }, [userStore.isOverdue, setModal, history]);

  const { data, error, loading } = useQuery(GET_USER_BILLING_INFO, {
    variables: { clientCode: userStore.clientCode },
  });

  const renderUserDetails = React.useCallback(
    (customer: Customer | undefined) => {
      if (!customer) {
        return "No data was provided";
      }

      const { name, email } = customer;

      return (
        <TableDetails>
          {name && <DetailItem bold>{name}</DetailItem>}
          {email && <DetailItem>{email}</DetailItem>}
        </TableDetails>
      );
    },
    [],
  );

  const renderAddressDetails = React.useCallback(
    (customer: Customer | undefined) => {
      if (!customer) {
        return "No data was provided";
      }

      const {
        state = "",
        postalCode = "",
        line1,
        line2,
        country,
        city = "",
      } = customer;
      const coma = city && (state || postalCode) ? "," : "";
      const details = `${city || ""}${coma || ""} ${state || ""} ${
        postalCode || ""
      }`.trim();

      return (
        <TableDetails>
          <DetailItem>
            {line2} {line1}
          </DetailItem>

          <DetailItem>{details}</DetailItem>

          <DetailItem>{country}</DetailItem>
        </TableDetails>
      );
    },
    [],
  );

  const editButton = (redirectRoute: string) => (
    <Link to={redirectRoute}>
      <EditIcon icon={Icons.faPen} />
    </Link>
  );

  const columns = React.useMemo<SingleTableColumn<unknown>[]>(
    () => [
      {
        Header: "label",
        accessor: "label",
        tdElement: CustomLabelTd as any,
      },
      {
        Header: "value",
        accessor: "value",
        tdElement: CustomValueTd as any,
      },
    ],
    [],
  );

  const tableRows: object[] = React.useMemo(() => {
    const elements = [
      {
        label: "User Details:",
        value: renderUserDetails(data?.customer),
      },
      {
        label: "Address:",
        value: renderAddressDetails(data?.customer),
      },
    ];

    return elements;
  }, [data, renderAddressDetails, renderUserDetails]);

  if (loading) {
    return <Spinner />;
  }

  if (!data || error) {
    return <Error />;
  }

  return (
    <>
      <Title>Pricing</Title>

      <PricingSection
        testCredits={data.testCredits}
        upcomingPayment={data.upcomingPayment}
        currentSubscriptions={data.subscription}
        isUnlimited={data.client.isUnlimited}
        isFreeUser={data.client.isFreeUser}
      />

      <Section
        transparent
        title="User Billing Details"
        description={
          <PermissionRestriction
            requiredPermissions={
              authRoutes.EDIT_BILLING_DETAILS.routePermissions
            }
          >
            {editButton(authRoutes.EDIT_BILLING_DETAILS.getUrlWithParams())}
          </PermissionRestriction>
        }
      >
        <StaticTable
          columns={columns}
          data={tableRows}
          highlightFirstCell
          displayHeader={false}
        />
      </Section>

      {data.paymentMethod && (
        <Section
          transparent
          title="User Payment Method"
          description={
            <PermissionRestriction
              requiredPermissions={
                authRoutes.EDIT_PAYMENT_METHOD.routePermissions
              }
            >
              {editButton(authRoutes.EDIT_PAYMENT_METHOD.getUrlWithParams())}
            </PermissionRestriction>
          }
        >
          <CardSide>
            <Chip />
            <CardNumber>**** **** **** {data.paymentMethod.last4}</CardNumber>
          </CardSide>
        </Section>
      )}

      <Section transparent title="Invoices">
        <InvoiceList />
      </Section>
    </>
  );
};

// -- STYLED
const TableDetails = styled("div")`
  padding: 10px 0;
`;
const DetailItem = styled("div")<{ bold?: boolean }>`
  ${(props) =>
    props.bold &&
    `
    font-weight: bold;
  `}
`;
const EditIcon = styled(Icon)`
  font-size: 21px;

  margin-right: 10px;

  color: ${(props: any) => props.theme.colors.white};
`;

const CardSide = styled("div")`
  display: flex;

  width: 323px;
  height: 175px;

  border-radius: 10px;

  background-color: ${(props: any) => props.theme.colors.backgroundLight};

  position: relative;

  overflow: hidden;

  &::before {
    content: "ACTIVE";

    width: 120px;
    height: 18px;

    position: absolute;

    top: 21px;
    right: -28px;

    transform: rotate(45deg);

    background-color: purple;

    display: flex;

    justify-content: center;
    align-items: center;

    font-weight: bold;
  }

  &::after {
    content: "User Card";

    position: absolute;

    font-weight: bold;
    font-size: 28px;

    right: 70px;
    top: 13px;
  }
`;
const Chip = styled("div")`
  width: 54px;
  height: 42px;

  background-color: gold;

  top: 50px;
  left: 30px;

  position: absolute;

  border-radius: 10px;
`;
const CardNumber = styled("div")`
  display: flex;

  position: absolute;

  bottom: 35px;
  left: 30px;

  font-size: 26px;
`;
const CustomLabelTd = styled(TdComponent)`
  width: 250px;
  font-size: 14px;
  padding-right: 0px;
  text-align: left;
`;

const CustomValueTd = styled(TdComponent)`
  font-size: 14px;
  background-color: ${(props: any) => props.theme.colors.backgroundLight};
  text-align: left;
`;

export default withRouter(observer(UserBillingDetailsPage));
