import { css } from 'styled-components'

const DatePickerStyle = css`
  .SingleDatePicker,
  .DateInput,
  .DateInput>input {
    width: 100%;
    height: 35px;
  }

  .SingleDatePickerInput__withBorder {
    height: 38px;
    width: 100%;
    border: 1px solid #23233a;
    background-color: #07071e;
    color: #ffffff;
    font-size: 16px;
  }

  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: transparent;
  }

  .CalendarMonth_caption.CalendarMonth_caption_1 {
    color: #36364c;
  }

  .DateInput_fangShape {
    fill: #36364c;
  }

  .DayPicker,
  .CalendarMonthGrid,
  .CalendarMonth {
    background-color: #36364c;
  }

  .CalendarMonth_caption.CalendarMonth_caption_1 {
    color: #fff;
  }

  .DayPicker_weekHeader_li {
    color: #fff;
  }

  .CalendarDay__default {
    color: #fff;
    background-color: #36364c;
    border: none;
  }

  .CalendarDay__selected {
    background-color: #2a2a42;
  }

  .CalendarDay__default:hover {
    background-color: #9743eb;
    border: none;
    color: #fff;
  }

  .CalendarDay__blocked_out_of_range, .CalendarDay__blocked_calendar {
    color: #5e5e6f;
  }

  .DateInput_input {
    background-color: #07071e;
    color: #fff;
    border: none;
    font-size: 16px;
    padding: 9px 15px;
  }

  .DayPickerNavigation_button__default {
    color: #fff;
    background-color: #36364c;
    border: none;
  }

  .DayPickerNavigation_button__default:hover {
    border: none;
    background-color: #36364c;
  }

  .DayPickerNavigation_button__default:focus {
    border: none;
    background-color: #36364c;
  }

  .DayPickerNavigation_svg__horizontal {
    fill: #fff;
  }

  .DateInput_fangStroke {
    stroke: rgba(0, 0, 0, 0);
  }

  .SingleDatePicker_picker {
    background-color: rgba(0, 0, 0, 0);
  }

  .DateRangePickerInput {
    background-color: #1e1e36;
  }

  .DateRangePickerInput>.DateInput {
    width: 150px;
    border: 1px solid #23233a;
  }

  .DateRangePickerInput__withBorder {
    border-width: 0;
  }

  .DateRangePickerInput_arrow {
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-weight: bold;
    padding: 5px;
  }

  .DateRangePicker {
    margin-top: -3px;
  }

  .DateRangePickerInput>.DateInput>input{
    height: 36px;
  }

  .CalendarDay__selected_span {
    background-color: rgba(151, 67, 235, 0.2);
  }

  .DateRangePicker_picker {
    background-color: rgba(255, 255, 255, 0);
  }
`

export default DatePickerStyle