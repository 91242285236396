import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";

import { Modal } from "components";
import { Button, TextInput } from "components/forms";
import youtube from "utils/youtube";
import { UploadFile } from "./UploadInput";
import AssetInput from "./AssetInput";

interface Props {
  placeholder?: string;
  onAdd: (data: UploadFile[]) => void;
  disabled?: boolean;
}

function VideoInput({
  placeholder = "Add Video",
  onAdd,
  disabled = false,
}: Props) {
  const [modal, setModal] = useState(false);
  const [video, setVideo] = useState("");
  const [error, setError] = useState("");

  const closeModal = () => {
    setModal(false);
    setVideo("");
    setError("");
  };

  return (
    <>
      <AssetInput
        title={placeholder}
        rootProps={{ onClick: () => !disabled && setModal(true) }}
        disabled={disabled}
      />

      <Modal
        show={modal}
        onHide={closeModal}
        title="YouTube Video URL"
        type="video"
        footer={
          <Flex alignItems="center" justifyContent="flex-end">
            <Box mr={20}>
              <Button link onClick={closeModal}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button
                disabled={!video}
                onClick={() => {
                  const linkError = youtube.validateUrl(video);

                  if (linkError) {
                    setError(linkError);
                  } else {
                    const videoId = youtube.getVideoId(video);
                    onAdd([
                      {
                        preview: youtube.getThumbnail(videoId),
                        videoId,
                        file: null,
                      },
                    ]);
                    closeModal();
                  }
                }}
              >
                Add Video
              </Button>
            </Box>
          </Flex>
        }
      >
        Enter URL to your preview video below. The video will be automatically
        added to first place.
        <Input
          placeholder="https://youtube.com/watch?v=123456789"
          onChange={(event: any) => setVideo(event.target.value)}
          error={error}
        />
        If you don't have a video link yet, click{" "}
        <Link href="https://studio.youtube.com/" target="_blank">
          here
        </Link>{" "}
        to upload a new video to YouTube.
        <br />
        <br />
        <b>Important:</b> Make sure your video privacy setting is set to
        "Unlisted" when uploading to YouTube.
      </Modal>
    </>
  );
}

const Input = styled(TextInput)`
  margin: 30px 0;
  border-color: #676777;
`;

const Link = styled.a`
  color: inherit;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
`;

export default VideoInput;
