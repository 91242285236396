import React, { useState } from "react";
import { Form } from "react-final-form";

import IconStep, { StepData } from "components/application/forms/IconStep";
import { FormFooter } from "components/forms";
import { MessageBox } from "components/application";

interface Props {
  onDiscard: () => void;
  onSubmit: (data: StepData) => void;
}

function ApplicationUpdateIconsPage({ onDiscard, onSubmit }: Props) {
  const defaultState: StepData = {
    control: null,
    assets: [],
  };
  const [formData, setFormData] = useState(defaultState);

  return (
    <Form onSubmit={() => onSubmit(formData)} initialValues={formData}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <MessageBox />
          <IconStep
            title="Upload New Icons"
            data={formData}
            disableControl
            onChange={(data: any) =>
              setFormData({
                ...formData,
                ...data,
              })
            }
          />

          <FormFooter onCancel={onDiscard} disabled={submitting} />
        </form>
      )}
    </Form>
  );
}

export default ApplicationUpdateIconsPage;
