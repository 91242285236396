import * as React from "react";
import { Box, Flex } from "@rebass/grid";

import { checkIsCurrentPhase } from "../helpers/checkIsCurrentPhase";
import { getCurrentlyTestedSectionMessage } from "../helpers/getCurrentlyTestedSectionMessage";
import { groupAssets } from "../helpers/groupAssets";

import { toLocaleNumber } from "utils/numbers";

import { AssetProps } from "../constants/assetProps";

import { ApplicationImageAssetsSection } from "./ApplicationImageAssetsSection";

// -- COMPONENT
export function ApplicationImageAssets(props: AssetProps) {
  const assets = groupAssets(props.assets);
  const {
    phaseType,
    currentPhase,
    title,
    history,
    applicationId,
    trafficIndicator,
  } = props;
  const isCurrentPhase = checkIsCurrentPhase(phaseType, currentPhase);
  const message = getCurrentlyTestedSectionMessage(
    isCurrentPhase,
    history,
    applicationId,
    currentPhase,
    props.currentIteration,
  );

  return (
    <Flex flexDirection="column">
      <Box mt={25}>
        <ApplicationImageAssetsSection
          {...props}
          assets={assets.top}
          title={`Current Top ${title}`}
          canAbort={false}
          displayConversionRate={true}
          displayConversions={true}
          displayVisits={true}
          displayLift={true}
          controlConversionRate={
            assets.control ? assets.control.conversionRate : 0
          }
        />
      </Box>
      <Box mt={25}>
        <ApplicationImageAssetsSection
          {...props}
          assets={isCurrentPhase ? assets.testing : []}
          title={`Currently Testing ${title}`}
          canAbort={isCurrentPhase}
          displayConversionRate={true}
          displayConversions={true}
          displayVisits={true}
          displayLift={true}
          controlConversionRate={
            assets.control ? assets.control.conversionRate : 0
          }
          message={message}
          trafficIndicator={trafficIndicator}
        />
      </Box>
      <Box mt={25}>
        <ApplicationImageAssetsSection
          {...props}
          assets={assets.queued}
          title={`Queued ${title} (${toLocaleNumber(assets.queued.length)})`}
          canAbort={false}
          canRemove={true}
          displayConversionRate={true}
          displayConversions={true}
          displayVisits={true}
        />
      </Box>
      <Box mt={25}>
        <ApplicationImageAssetsSection
          {...props}
          assets={assets.tested.reverse()}
          title={`Winning ${title} (${toLocaleNumber(assets.tested.length)})`}
          titleTooltip="Assets that have won in past tests."
          canAbort={false}
          displayConversionRate={true}
          displayConversions={true}
          displayVisits={true}
        />
      </Box>
      <Box mt={25}>
        <ApplicationImageAssetsSection
          {...props}
          assets={assets.discarded}
          title={`Discarded ${title} (${toLocaleNumber(
            assets.discarded.length,
          )})`}
          titleTooltip="Assets that did not win in past tests."
          canAbort={false}
          displayConversionRate={true}
          displayConversions={true}
          displayVisits={true}
        />
      </Box>
    </Flex>
  );
}
