import * as React from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import moment from "moment";

import { SET_SHIP_DATE } from "api/mutations";

import { ModalContext } from "contexts";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Icons } from "components/Icon";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ButtonBox, ShipButton, TooltipIcon } from "./UiElements";
import DatePicker from "components/forms/DatePicker";
import { PermissionRestriction } from "v2/components/PermissionRestriction";
import { UserPermissions } from "v2/api/types";

// -- TYPES
type MarkShipDateActionProps = {
  applicationId: string;
  refetchPage: () => Promise<any>;
};

// -- COMPONENT
export function MarkShipDateAction({
  applicationId,
  refetchPage,
}: MarkShipDateActionProps) {
  const [shipDate, setShipDate] = React.useState<string>(
    moment().format("DD-MM-YYYY"),
  );
  const setModal = React.useContext(ModalContext);

  const [createShipDate] = useMutation(SET_SHIP_DATE);

  const handleError = useRequestErrorHandler();

  const shipDateText = `Mark the date new assets from testing went live in the 
    app store to track actual app store results before and after.`;

  const handleShipDateModalConfirm = (date: string) => async () => {
    const options = {
      variables: { applicationId, date },
    };

    const createShipDateResult = await handleError(createShipDate(options));
    createShipDateResult && (await handleError(refetchPage()));
  };

  const handleShipDateModalCancel = () =>
    setShipDate(moment().format("DD-MM-YYYY"));

  const handleShipDateChange = (date: string | null) => {
    if (date) {
      setShipDate(date);
      setModal({
        type: "update",
        props: {
          onConfirm: handleShipDateModalConfirm(date),
        },
      });
    }
  };

  const openMarkShipDateModal = () => {
    const shipDateModalContent = (
      <>
        {shipDateText}
        <DateSelectorWrapper>
          <ShipDateLabel>Select ship date</ShipDateLabel>
          <DatePicker
            initialDate={shipDate}
            onChange={handleShipDateChange}
            id="singleDatePicker"
            allowClear={false}
          />
        </DateSelectorWrapper>
      </>
    );

    setModal({
      type: "set",
      props: {
        title: "Mark App Store Ship Date",
        content: shipDateModalContent,
        confirmText: "Mark Ship Date",
        onConfirm: handleShipDateModalConfirm(shipDate),
        onCancel: handleShipDateModalCancel,
      },
    });
  };

  return (
    <ButtonBox>
      <PermissionRestriction
        requiredPermissions={[UserPermissions.updateAppPlatformProfile]}
      >
        <ShipButton onClick={openMarkShipDateModal}>
          Mark App Store Ship Date
        </ShipButton>
        <Tooltip content={shipDateText} placement={"bottom"}>
          <TooltipIcon icon={Icons.faQuestionCircle} />
        </Tooltip>
      </PermissionRestriction>
    </ButtonBox>
  );
}

// -- STYLED
const DateSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
`;

const ShipDateLabel = styled.span`
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
`;
