// -- CORE
import * as React from "react";
// -- COMPONENTS
import { CheckboxInput } from "components/forms";

// -- PROPS
type ContentProps = {
  value: any;
  onChange: (value: boolean) => void;
};

// -- COMPONENT
export const ResetControlContent = ({ value, onChange }: ContentProps) => {
  const valueChange = (event: any) => {
    onChange(event.target.checked);
  };

  return (
    <div>
      <div>
        Resetting Control data will execute immediately and cancel any test
        currently running.
      </div>
      <br />
      <div>
        Any new tests will iterate and test from the Control page once again
        from the beginning. You will still be able to view the current Top Page
        until it is replaced by a new Top Page
      </div>
      <br />
      <div>
        <CheckboxInput
          label="Reinsert Top Page assets to test against Control"
          onChange={valueChange}
          value={value}
        />
      </div>
      <br />
      <div>Are you sure you would like to reset Control data?</div>
    </div>
  );
};
