import React, { FC, memo, useMemo } from "react";
import { Box, Flex } from "@rebass/grid";
import { Field } from "react-final-form";
import styled from "styled-components";
import { useQuery } from "@apollo/client";

// import { MessageBox } from "components";
import { GET_PLATFORM_CATEGORIES } from "api/queries/application";
import { IosCategory, Platform } from "api/models";
import { SingleReview } from "api/models/reviews/singleReviews";
import { Option } from "components/forms/SelectInput/SelectInput";

import validators from "utils/validators";
import formatters from "utils/formatters";

import {
  ageLimitsIosOptions,
  ratingsOptions,
  convertCategoriesToOptions,
} from "components/forms/data/data";

import FormSection from "components/forms/FormSection";
import { DetailCard } from "../DetailCard";
import { ReviewsWizard } from "./Reviews/ReviewsWizard";

import {
  FormHeader,
  FormLabel,
  SelectInput,
  TextInput,
  TextArea,
  DatePicker,
  NumberInput,
} from "components/forms";
import { Spinner, Error } from "components";
import { StaticInfoMDL } from "./StaticInfoMDL";

// -- TYPES
export interface StepData {
  publisher: string;
  rating: string;
  numberOfRatings: string;
  ageLimit: string;
  appVersion: string;
  releaseDate: string;
  releaseNote: string;
  category: string;
  ranking: string;
  size?: string;
  copyright?: string;
  reviews: SingleReview[];
  mdl: number;
}

type Props = {
  data: StepData;
  onChange: (values: {
    [key: string]: string | number | null | object;
  }) => void;
  validate: any;
};

// -- COMPONENT
const StaticInfoiOS: FC<Props> = ({ data, onChange }) => {
  const categoriesResponse = useQuery<{ categories: IosCategory[] }>(
    GET_PLATFORM_CATEGORIES,
    {
      variables: { platform: Platform.ios },
    },
  );

  const categories: Option[] = useMemo(
    () => convertCategoriesToOptions(categoriesResponse.data?.categories),
    [categoriesResponse.data],
  );

  const onDatepickerChange = (input: any) => (date: any) => {
    onChange({
      releaseDate: date,
    });

    input.onChange(date);
  };

  if (categoriesResponse.loading) {
    return <Spinner />;
  }

  if (categoriesResponse.error) {
    return <Error errorElement={categoriesResponse.error.message} />;
  }

  return (
    <>
      <Header>Static Info</Header>
      <FormSection>
        <FormHeader>App Details</FormHeader>

        <Flex my={30} justifyContent="space-between">
          <Box width={3 / 10}>
            <DetailCard>
              <Field
                name="publisher"
                validate={validators.required}
                formatOnBlur
                format={formatters.trim}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="publisher" spacing={false}>
                        Publisher
                      </FormLabel>
                      <TextInput
                        type="text"
                        placeholder="Enter Developer Name"
                        maxLength={30}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          onChange({
                            publisher: event.target.value,
                          });
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>

          <Box width={3 / 10}>
            <DetailCard>
              <Field
                name="rating"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                )}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="rating" spacing={false}>
                        Rating
                      </FormLabel>
                      <SelectInput
                        value={data.rating}
                        options={ratingsOptions}
                        valid={!(meta.touched && meta.error)}
                        error={meta.touched && meta.error}
                        onChange={(value: any) => {
                          onChange({
                            rating: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>

          <Box width={3 / 10}>
            <DetailCard>
              <Field
                name="ageLimit"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                )}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="ageLimit" spacing={false}>
                        Age Rating
                      </FormLabel>
                      <SelectInput
                        value={data.ageLimit}
                        options={ageLimitsIosOptions}
                        valid={!(meta.touched && meta.error)}
                        error={meta.touched && meta.error}
                        onChange={(value: any) => {
                          onChange({
                            ageLimit: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>
        </Flex>

        <Flex mb={30} justifyContent="space-between">
          <Box width={1 / 2} mr={32}>
            <DetailCard>
              <Field
                name="size"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                  validators.minValue(1),
                  validators.maxValue(9999.9),
                )}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="size" spacing={false}>
                        Size (MB)
                      </FormLabel>
                      <NumberInput
                        decimalSpaces={1}
                        placeholder="E.g. 123.33"
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(size: number | null) => onChange({ size })}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>

          <Box width={1 / 2}>
            <DetailCard>
              <Field
                name="numberOfRatings"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                  validators.minValue(1),
                  validators.maxValue(99999999),
                )}
                formatOnBlur
                format={formatters.trimNumber}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="numberOfRatings" spacing={false}>
                        Number of Ratings
                      </FormLabel>
                      <TextInput
                        type="text"
                        placeholder="E.g. 12345"
                        maxLength={8}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          if (isNaN(value)) {
                            return false;
                          }

                          onChange({
                            numberOfRatings: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>
        </Flex>

        <Flex mb={30} justifyContent="space-between">
          <Box width={3 / 10}>
            <DetailCard>
              <Field
                name="category"
                value={data.category}
                formatOnBlur
                format={formatters.trim}
                validate={
                  validators.requiredDependOnOtherFields(["ranking"]) as any
                }
              >
                {({ meta, input }) => {
                  return (
                    <div>
                      <FormLabel
                        name="category"
                        spacing={false}
                        optional={true}
                      >
                        Category
                      </FormLabel>
                      <SelectInput
                        isClearable
                        name="category"
                        error={meta.error}
                        valid={!meta.error}
                        value={data.category}
                        options={categories}
                        onChange={(value: any) => {
                          const changes: { [key: string]: string } = {
                            category: value,
                          };

                          if (!value) {
                            changes.ranking = value;
                          }

                          onChange(changes);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>

          <Box width={3 / 10}>
            <DetailCard>
              <Field
                name="ranking"
                format={formatters.trimDecimals}
                validate={validators.composeValidators(
                  true,
                  validators.minValue(1),
                  validators.maxValue(99),
                )}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="ranking" spacing={false} optional={true}>
                        Ranking
                      </FormLabel>
                      <TextInput
                        disabled={!data.category}
                        min={1}
                        maxLength={3}
                        placeholder="Enter Ranking"
                        error={meta.error}
                        {...input}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          if (isNaN(value)) {
                            return false;
                          }

                          onChange({
                            ranking: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>

          <Box width={3 / 10}>
            <DetailCard>
              <Field
                name="copyright"
                value={data.copyright}
                formatOnBlur
                format={formatters.trim}
              >
                {({ meta, input }) => {
                  return (
                    <div>
                      <FormLabel
                        name="copyright"
                        spacing={false}
                        optional={true}
                      >
                        Copyright
                      </FormLabel>
                      <TextInput
                        type="text"
                        placeholder="E.g. 2020 Company Name"
                        maxLength={30}
                        error={meta.error}
                        {...input}
                        onChange={(event: any) => {
                          onChange({
                            copyright: event.target.value,
                          });
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>
        </Flex>
      </FormSection>

      <FormSection>
        <FormHeader>What's New (Optional)</FormHeader>

        <Flex mb={30} justifyContent="space-between">
          <Box width={1 / 2} mr={32}>
            <DetailCard>
              <Field
                name="appVersion"
                value={data.appVersion}
                formatOnBlur
                format={formatters.trim}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel
                        name="appVersion"
                        spacing={false}
                        optional={true}
                      >
                        App Version
                      </FormLabel>
                      <TextInput
                        type="text"
                        placeholder="E.g. 1.0.13"
                        maxLength={8}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          onChange({
                            appVersion: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>
          <Box width={1 / 2}>
            <DetailCard>
              <Field
                name="releaseDate"
                value={data.releaseDate}
                format={formatters.trim}
                validate={validators.isDate}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel
                        name="releaseDate"
                        spacing={false}
                        optional={true}
                      >
                        Release Date
                      </FormLabel>
                      <DatePicker
                        onChange={onDatepickerChange(input)}
                        id="singleDatePicker"
                        onFocusChange={(focused) =>
                          focused ? input.onFocus() : input.onBlur()
                        }
                        externalError={meta.touched && meta.error}
                        readOnly={false}
                        allowClear={false}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>
        </Flex>

        <Flex mb={30} justifyContent="space-between">
          <Box width={1}>
            <DetailCard>
              <Field
                name="releaseNote"
                value={data.releaseNote}
                formatOnBlur
                format={formatters.trim}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="releaseNote" optional={true}>
                        What's New Description
                      </FormLabel>
                      <TextArea
                        type="text"
                        placeholder="Start typing here…"
                        maxLength={4000}
                        error={meta.error}
                        {...input}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          onChange({
                            releaseNote: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </DetailCard>
          </Box>
        </Flex>
      </FormSection>

      <ReviewsWizard
        onChange={onChange}
        data={data.reviews}
        platform={Platform.ios}
      />

      <StaticInfoMDL mdlValue={data.mdl} onChange={onChange} />
    </>
  );
};

const Header = styled(FormHeader as any)`
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const StaticInfoiOSForm = memo(StaticInfoiOS);
