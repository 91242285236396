import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import themes from "components/themes";

import { PositionLineScProps } from "./constants/positionLineScProps";

// -- COMPONENT
export function PositionLine(): JSX.Element | null {
  const activeDotData = document
    .getElementsByClassName("recharts-active-dot")[0]
    ?.getBoundingClientRect();

  if (!activeDotData) {
    return null;
  }

  const portal = document.querySelector("#chart-line-portal");
  const portalWidth = portal?.getBoundingClientRect().width;
  const yPos =
    activeDotData.top + window.pageYOffset + activeDotData.height / 2 - 1;

  return ReactDOM.createPortal(
    <Line yAxisPos={yPos} width={portalWidth} />,
    portal || document.body,
  );
}

// -- STYLED
const Line = styled("div")`
  position: absolute;

  z-index: 10;

  border-top: 2px solid ${themes.colors.purple};

  &:before {
    content: "";
    width: 10px;
    height: 10px;

    border-radius: 50%;

    position: absolute;
    top: -6px;
    left: -5px;

    background-color: ${themes.colors.purple};
  }
` as any;
