import React from "react";
import styled from "styled-components";

import themes from "components/themes";
import { FormHeader } from "components/forms";

import loader from "assets/images/loder_ v.gif";

const AppCreationLoader: React.FC = () => (
  <>
    <Header>Creating App</Header>
    <Wrapper>
      <img src={loader} alt="loader-gif" />
      <Content>
        Your App is being created.
        <br />
        This might take up to a couple of minutes.
      </Content>
    </Wrapper>
  </>
);

const Wrapper = styled.div`
  background-color: #13132b;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px 0;
  flex-direction: column;
`;
const Content = styled.p`
  margin: 30px 0 0 0;
  font-size: 16px;
  color: ${themes.colors.white};
  text-align: center;
`;
const Header = styled(FormHeader as any)`
  margin-top: 40px;
  margin-bottom: 20px;
`;

export default AppCreationLoader;
