import * as React from "react";
import { styled } from "@mui/system";
import { get } from "lodash";
import { Flex } from "@rebass/grid";

import { ApplicationAsset, AssetStatus } from "api/models";
import { AssetProps } from "../constants/assetProps";

import { getStatisticsItems } from "../helpers/getStatisticsItems";

import emptyPromoArt from "assets/images/empty-state-promo-art.svg";
import emptyImage from "assets/images/empty-state-single.png";

import {
  FloatingLabel,
  MessageBox,
  Section,
  StatsTable,
  Text,
} from "components";

import { UploadImage } from "components/forms";
import { ScrollFlex, TrafficIndicator } from "./uiElement";

// -- COMPONENT
export function ApplicationImageAssetsSection({
  title,
  assets,
  onRemove,
  onDeactivate,
  canAbort = false,
  onSetTop,
  displayConversions = false,
  displayConversionRate = false,
  displayLift = false,
  displayVisits = false,
  controlConversionRate = 0,
  message = "There are no assets in this section yet.",
  titleTooltip = "",
  canRemove = false,
  trafficIndicator,
}: AssetProps) {
  const renderLabelContent = (asset: any) => {
    const { isSet, isControl, isTop, fileName } = asset;

    if (isSet) {
      const setPrefix = "Set:";
      const setName = get(asset, "set.name");

      if (!setName && isControl) {
        return `${setPrefix} Current Control`;
      }

      if (!setName && isTop) {
        return `${setPrefix} Current Top`;
      }

      return `${setPrefix} ${setName || "Unnamed"}`;
    }

    return <Text value={fileName || "YouTube Video"} displayedLength={20} />;
  };

  const handleRemove = (asset: ApplicationAsset) => () =>
    onRemove && onRemove(asset);

  const handleDeactivate = (asset: ApplicationAsset) => () =>
    onDeactivate && onDeactivate(asset);

  const handleSetTop = (asset: ApplicationAsset) => () =>
    onSetTop && onSetTop(asset);

  const renderAssets = (): React.ReactNode => {
    if (!assets.length) {
      return <MessageBox closeable={false}>{message}</MessageBox>;
    }

    return assets.map((asset: any) => {
      let imageUrl = asset.url || asset.file!.url;

      if (!imageUrl) {
        if (asset.fileName === "No Promo Art") {
          imageUrl = emptyPromoArt;
        } else if (asset.fileName === "No Feature Graphic") {
          imageUrl = emptyImage;
        }
      }

      const statisticsItems = getStatisticsItems(
        asset,
        canAbort,
        displayConversions,
        displayConversionRate,
        displayLift,
        displayVisits,
        controlConversionRate,
        true,
      );

      return (
        <ImageBox
          key={`elements-${asset.id}`}
          mr={25}
          control={asset.isControl}
        >
          <Label testing={canAbort}>{renderLabelContent(asset)}</Label>
          <ImageBoxContent testing={canAbort}>
            <UploadImage
              image={imageUrl}
              control={false}
              onControl={null}
              onRemove={
                asset.isControl || !onRemove || !canRemove
                  ? null
                  : handleRemove(asset)
              }
              onSetTop={
                onSetTop && canAbort && asset.status === AssetStatus.testing
                  ? handleSetTop(asset)
                  : null
              }
              onDeactivate={
                !asset.isControl &&
                onDeactivate &&
                asset.status === AssetStatus.testing
                  ? handleDeactivate(asset)
                  : null
              }
              isVideoPreview={asset.isVideo}
              isStack={asset.isSet}
              previewUrl={asset.previewLink}
              historicalData={asset.historicalConversionData}
              videoUrl={!asset.isSet ? asset.embeddedVideoUrl : undefined}
            />
            <StyledStatsTable items={statisticsItems} withStack={asset.isSet} />
          </ImageBoxContent>

          {asset.isControl && (
            <FloatingLabel>Current Control Creative</FloatingLabel>
          )}
        </ImageBox>
      );
    });
  };

  return (
    <Section title={title} transparent titleTooltip={titleTooltip}>
      {canAbort && <TrafficIndicator>{trafficIndicator}</TrafficIndicator>}
      <ScrollFlex justifyContent="start">{renderAssets()}</ScrollFlex>
    </Section>
  );
}

// -- STYLED
const ImageBox = styled(Flex)<{ control: boolean }>`
  flex-direction: column;
  position: relative;
  padding: 10px 20px 30px;
  background-color: ${(props: any) => props.theme.colors.card};
  border-radius: 8px;
  border: ${(props: any) =>
    props.control ? `1px solid ${props.theme.colors.purple}` : null};
`;

const ImageBoxContent = styled(Flex)<{ testing: boolean }>`
  flex-direction: ${(props: any) => (props.testing ? `row` : `column`)};
  width: ${(props: any) => (props.testing ? `377px` : `100%`)};
`;

const Label = styled("div")<{ testing: boolean }>`
  padding-bottom: 8px;
  text-align: ${(props: any) => (props.testing ? `left` : `center`)};
  word-break: break-all;
`;

const StyledStatsTable = styled(StatsTable)<{ withStack?: boolean }>`
  margin-left: ${(props: any) => (props.withStack ? "17px" : "unset")};
`;
