import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { isArray } from "lodash";

import { convertToCurrency } from "utils/numbers";

import { Price } from "screens/Billing/UserBillingDetails/constants/types";

import themes from "components/themes";

// -- TYPES
interface Props {
  isSubscriptionSelected: boolean;
  isPayAsYouGoSelected: boolean;
  appSeatsNumber: number;
  prices?: Price[];
}

// -- COMPONENT
export const SelectedOptionSummary: FC<Props> = ({
  isSubscriptionSelected = false,
  appSeatsNumber,
  prices,
  isPayAsYouGoSelected,
}) => {
  const firstSub = useMemo(
    () =>
      isArray(prices) &&
      prices.find(
        (singlePrice) =>
          String(singlePrice?.product?.name).toLowerCase() === "first app",
      ),
    [prices],
  );

  const additionalSub = useMemo(
    () =>
      isArray(prices) &&
      prices.find(
        (singlePrice) =>
          String(singlePrice?.product?.name).toLowerCase() ===
          "additional apps",
      ),
    [prices],
  );

  const summary = useMemo(() => {
    if (!isSubscriptionSelected) {
      return `You Won't Be Charged Today`;
    }

    const firstSubValue = firstSub && firstSub.unitAmount;
    const addSubValue = additionalSub && additionalSub.unitAmount;
    const sum =
      (firstSubValue || 0) + (appSeatsNumber - 1) * (addSubValue || 0);

    return `Due Today: ${convertToCurrency(sum / 100)}`;
  }, [additionalSub, firstSub, isSubscriptionSelected, appSeatsNumber]);

  if (!isPayAsYouGoSelected && !isSubscriptionSelected) {
    return null;
  }

  return <Wrapper>{summary}</Wrapper>;
};

// -- STYLED
const Wrapper = styled.p`
  display: flex;

  justify-content: center;

  color: ${themes.colors.white};

  margin: 20px 0 0 0;

  font-size: 20px;
`;
