import React from "react";
import { Modal, ModalReducerActionType } from "api/models";

interface ModalSetterParams {
  type: ModalReducerActionType;
  props?: Modal | { [propId: string]: any };
}

export type ModalSetter = (ActionType: ModalSetterParams) => void;
type EmptyFunc = () => void;

export default React.createContext<ModalSetter | EmptyFunc>(() => {});
