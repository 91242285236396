import styled from "styled-components";

const DividerVertical = styled.div`
  height: 100%;
  width: 1px;
  background: white;
  margin: 0 auto;
`;

export default DividerVertical;
