import React, { useState } from "react";
import styled from "styled-components";
import { Field } from "react-final-form";
import { Flex } from "@rebass/grid";

import themes from "components/themes";

import config from "config";
import validators from "utils/validators";
import { Section, DividerText } from "components";
import {
  FormError,
  UploadHint,
  UploadInput,
  VideoInput,
} from "components/forms";
import { UploadFile } from "components/forms/UploadInput";
import { Platform } from "api/models";
import { ApplicationAssetsList } from "./ApplicationAssetsList";
import { assetsArrayWithMaxVideosAllowed, isVideo } from "utils/assets";

export const getControlFallback = (
  newAssets: UploadFile[],
  setAutoControl: boolean = false,
): UploadFile | null => {
  return setAutoControl ? newAssets[0] : null;
};

export interface ChangeData {
  assets: UploadFile[];
  control: UploadFile | null;
}

interface Props {
  header?: string | React.ReactNode;
  buttonText: string;
  emptyTitle: string;
  emptyDescription: string;
  emptyDescriptionFooter?: string | React.ReactNode;
  emptyHintFooter?: string | React.ReactNode;
  assets: UploadFile[];
  video?: boolean;
  onChange: (data: ChangeData) => void;
  onControl: ((data: UploadFile) => void) | null;
  controlAsset: UploadFile | null;
  maxUploadAssets?: number;
  minUploadAssets?: number;
  maxVideoUploadsIos?: number;
  disableControl?: boolean;
  autoControl?: boolean;
  required?: boolean;
  platform?: Platform.ios | Platform.android;
  formFieldName?: string;
  allowDragAndDrop?: boolean;
  disabled?: boolean;
  additionalButtons?: React.ReactNode;
  typeOfScreen?: string;
}

function ApplicationAssetsUpload({
  header = "",
  buttonText,
  emptyTitle,
  emptyDescription,
  emptyDescriptionFooter,
  emptyHintFooter,
  assets,
  video = false,
  onChange,
  controlAsset,
  onControl,
  maxUploadAssets = config.maxApplicationAssets,
  minUploadAssets = config.minApplicationAssets,
  maxVideoUploadsIos = config.maxVideoUploadsIos,
  disableControl,
  autoControl = true,
  required = true,
  platform,
  formFieldName = "assets",
  allowDragAndDrop = false,
  disabled,
  additionalButtons,
  typeOfScreen,
}: Props) {
  const [error, setError] = useState(false);

  const renderValidatorWithError = () => {
    if (!required) {
      return null;
    }

    const errorMessage = validators.applicationAssetsCount(
      assets,
      minUploadAssets,
      maxUploadAssets,
    );

    return (
      <Field name={formFieldName} validate={() => errorMessage}>
        {({ meta }) => {
          if (meta.touched && meta.error && errorMessage) {
            setError(true);
            return <FormError>{meta.error}</FormError>;
          } else {
            setError(false);
            return null;
          }
        }}
      </Field>
    );

    // -- TODO: NEW IMPLEMENTATION WE SHOULD USE IN FEATURE
    // używając validatorów i osobnych formFieldName dla każdego elementu
    // const renderValidatorWithError = () => {
    //   const validateFunc = required ? validators.applicationAssetsCount(minUploadAssets, maxUploadAssets): undefined
    //   return (
    //     <Field name={formFieldName} validate={validateFunc}>
    //       {({ meta }) => {
    //         if (meta.touched && meta.error) {
    //           setError(true);
    //           return <FormError>{meta.error}</FormError>;
    //         } else {
    //           setError(false);
    //           return null;
    //         }
    //       }}
    //     </Field>
    //   )
    // }
  };

  const renderAndroidInputs = () => (
    <>
      {video && (
        <>
          <VideoInput
            onAdd={(urls) => {
              const newAssets = [...assets, ...urls];

              onChange({
                assets: newAssets,
                control: disableControl
                  ? null
                  : controlAsset || getControlFallback(newAssets, autoControl),
              });
            }}
            placeholder="Add Video"
            disabled={assets.length >= maxUploadAssets || disabled}
          />

          <DividerText>or</DividerText>
        </>
      )}

      <UploadInput
        onUpload={(uploadData) => {
          const newAssets = [...assets, ...uploadData].slice(
            0,
            maxUploadAssets,
          );

          onChange({
            assets: newAssets,
            control:
              onControl == null
                ? null
                : controlAsset || getControlFallback(newAssets, autoControl),
          });
        }}
        placeholder={buttonText}
        disabled={assets.length >= maxUploadAssets || disabled}
      />

      {additionalButtons && (
        <>
          <DividerText>or</DividerText>
          {additionalButtons}
        </>
      )}
    </>
  );

  const renderIosInputs = () => {
    const uploadInput = video ? (
      <UploadInput
        onUpload={(uploadData) => {
          if (typeOfScreen === "set") {
            let newAssets = assets;
            let videoCount = 0;
            for (const item of newAssets) {
              if (isVideo(item)) {
                videoCount++;
              }
            }
            const filteredUploadData = assetsArrayWithMaxVideosAllowed(
              videoCount,
              uploadData,
              maxVideoUploadsIos,
            );

            if (filteredUploadData) {
              newAssets.push(...filteredUploadData);
            }
            newAssets = newAssets.slice(0, maxUploadAssets);

            onChange({
              assets: newAssets,
              control:
                onControl == null
                  ? null
                  : controlAsset || getControlFallback(newAssets, autoControl),
            });
          } else {
            const newAssets = [...assets, ...uploadData].slice(
              0,
              maxUploadAssets,
            );

            onChange({
              assets: newAssets,
              control:
                onControl == null
                  ? null
                  : controlAsset || getControlFallback(newAssets, autoControl),
            });
          }
        }}
        placeholder="Upload Video or Screen"
        disabled={assets.length >= maxUploadAssets || disabled}
        video={true}
      />
    ) : (
      <UploadInput
        onUpload={(uploadData) => {
          const newAssets = [...assets, ...uploadData].slice(
            0,
            maxUploadAssets,
          );

          onChange({
            assets: newAssets,
            control:
              onControl == null
                ? null
                : controlAsset || getControlFallback(newAssets, autoControl),
          });
        }}
        placeholder={buttonText}
        disabled={assets.length >= maxUploadAssets || disabled}
      />
    );

    return (
      <>
        {uploadInput}
        {additionalButtons && (
          <AdditionalButtonsWrapper>
            {additionalButtons}
          </AdditionalButtonsWrapper>
        )}
      </>
    );
  };

  return (
    <Section
      style={{ marginBottom: 50 }}
      error={error}
      title={
        <>
          {!!header && <Header>{header}</Header>}
          <Flex justifyContent="center">
            {platform === Platform.android
              ? renderAndroidInputs()
              : renderIosInputs()}
          </Flex>
        </>
      }
      unified
    >
      <Flex justifyContent={assets.length ? "flex-start" : "center"}>
        {assets.length ? (
          <ApplicationAssetsList
            assets={assets}
            controlAsset={controlAsset}
            autoControl={autoControl}
            onControl={onControl}
            onChange={onChange}
            allowDragAndDrop={allowDragAndDrop}
          />
        ) : (
          <>
            {video && platform === Platform.ios ? (
              <UploadHint
                title="Upload Assets"
                description="Use the button above to upload videos or screens from your computer"
                descriptionFooter={emptyDescriptionFooter}
                hintFooter={
                  <HintFooter>
                    <HintHeader>Video Requirements</HintHeader>
                    <div>Required File Size: Less Than 500MB</div>
                    <div>
                      Apple's Length Requirement: Between 15 and 30 seconds
                    </div>
                    <div>Recommended Resolution: 1080x1920 or 1920x1080</div>
                    <div>
                      For detailed Apple App Preview video specifications, click{" "}
                      <HintFooterLink
                        href="https://help.apple.com/app-store-connect/?lang=en/#/dev4e413fcb8"
                        target="_blank"
                      >
                        here
                      </HintFooterLink>
                      .
                    </div>
                  </HintFooter>
                }
              />
            ) : (
              <UploadHint
                title={emptyTitle}
                description={emptyDescription}
                descriptionFooter={emptyDescriptionFooter}
                hintFooter={emptyHintFooter}
              />
            )}
          </>
        )}
      </Flex>
      {renderValidatorWithError()}
    </Section>
  );
}

const Header = styled.div`
  font-size: 14px;
  color: ${themes.colors.lightGrey};
`;

const HintHeader = styled.a`
  font-weight: 600;
  color: inherit;
  opacity: 1;
`;

const HintFooter = styled.div`
  opacity: 0.8;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #9d9da8;
`;

const HintFooterLink = styled.a`
  font-weight: 600;
  text-decoration: underline;
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const AdditionalButtonsWrapper = styled.span`
  > * {
    margin-left: 15px;
  }
`;

export default ApplicationAssetsUpload;
