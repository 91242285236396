import * as React from "react";
import { Row, ColumnInstance, UseTableRowProps } from "react-table";

import { TrComponent } from "./TrComponent";
import { TdComponent } from "./TdComponent";
import { SingleTableColumn } from "../constants/singleTableColumn";

// -- TYPES
type TableContentProps = {
  rows: Row<object>[];
  prepareRow: (row: Row<object>) => void;
  columnsNumber: number;
  highlightFirstCell: boolean;
  trElement?: React.ComponentType<any>;
};

interface OriginalRow extends UseTableRowProps<object> {
  setRowSpan?: string;
}

// -- COMPONENT
export function TableContent({
  rows,
  prepareRow,
  columnsNumber,
  highlightFirstCell,
  trElement,
}: TableContentProps): JSX.Element {
  const renderRow = (row: Row<object>) => {
    prepareRow(row);
    const rowChildrens = row.cells.map((cell, index) => {
      const column = cell.column as SingleTableColumn<unknown> &
        ColumnInstance<object>;
      const TdComponentToRender = column.tdElement || TdComponent;

      if (cell.value === "hidden") return null;
      return (
        <TdComponentToRender
          {...cell.getCellProps()}
          key={`row-${row.id}-cell-${index}`}
          rowSpan={
            column.setRowSpan && (row.original as OriginalRow).setRowSpan
              ? 2
              : 1
          }
        >
          {cell.render("Cell")}
        </TdComponentToRender>
      );
    });

    const rowProps = {
      highlightFirstCell: !(row.values.label === "hidden"),
      rowObject: row,
      ...row.getRowProps(),
      children: rowChildrens,
    };

    const TrComponenToRender = trElement || TrComponent;

    return <TrComponenToRender {...rowProps} />;
  };

  if (!rows.length) {
    return (
      <TrComponent>
        <TdComponent colSpan={columnsNumber}>No data available</TdComponent>
      </TrComponent>
    );
  }

  return <>{rows.map(renderRow)}</>;
}
