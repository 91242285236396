import React from "react";
import styled from "styled-components";
import { Form, FormRenderProps, Field } from "react-final-form";

import { Price } from "screens/Billing/UserBillingDetails/constants/types";
import { detailsFormData } from "./constants/detailsFormData";

import validators from "utils/validators";

import { FormFooter } from "components/forms";
import { SelectedOptionSummary } from "components/billing/SelectedOptionSummary";
import { CreditCardInput } from "components/billing/CreditCardInput";
import { BillingDetailsFormFields } from "components/billing/BillingDetailsFormField/BillingDetailsFormFields";
import { AnalyticsService } from "services";

// -- TYPES
type DetailsSubPageProps = {
  onSubmit: (formData: detailsFormData) => void;
  appSeatsNumber: number;
  prices: Price[];
  onStepBack: (data: detailsFormData) => void;
  initialData: detailsFormData | null;
};

// -- COMPONENT
export function DetailsSubPage({
  onSubmit,
  appSeatsNumber,
  prices,
  onStepBack,
  initialData,
}: DetailsSubPageProps) {
  const initialValues: detailsFormData = initialData || {
    city: "",
    country: "",
    email: AnalyticsService.currentUser as string,
    line1: "",
    line2: "",
    name: "",
    postalCode: "",
    state: "",
    cardValid: false,
  };

  const onFormSubmit = (value: object) => onSubmit(value as detailsFormData);

  const handleBack = (values: any) => () =>
    onStepBack(values as detailsFormData);

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      {({ handleSubmit, values }: FormRenderProps) => (
        <form onSubmit={handleSubmit}>
          <Header>Please Enter a Credit Card</Header>

          <SelectedOptionSummary
            isPayAsYouGoSelected={!appSeatsNumber}
            isSubscriptionSelected={!!appSeatsNumber}
            appSeatsNumber={appSeatsNumber}
            prices={prices}
          />

          <Wrapper>
            <Field name="cardValid" validate={validators.required}>
              {({ input, meta }) => (
                <CreditCardInput
                  onChange={input.onChange}
                  externalError={meta.touched && meta.error}
                />
              )}
            </Field>
          </Wrapper>

          <Header>Enter Your Company Information for Invoices</Header>

          <BillingDetailsFormFields />

          <FormFooter
            onCancel={handleBack(values)}
            cancelLabel={"Back"}
            submitLabel="Get Started"
          />
        </form>
      )}
    </Form>
  );
}

// -- STYLED
const Header = styled.h3`
  margin: 0;
  margin-bottom: 30px;

  text-align: center;

  font-size: 30px;
  font-weight: 600;

  color: #fff;
`;

const Wrapper = styled.div`
  padding: 40px;
  margin: 30px 0;

  background-color: #1e1e36;

  border-radius: 8px;
`;
