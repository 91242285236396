import { gql } from "@apollo/client";

export const GET_CURRENT_SUBSCRIPTIONS_AND_PRODUCTS_PRICES = gql`
  query currentSubscriptionsAndProductPrices {
    subscription {
      currentAppSeatsNum
      items {
        quantity
        price {
          id
          unitAmount
        }
      }
      upcomingSubscription
    }
    customerPrices {
      unitAmount
      currency
      priceType
      product {
        name
      }
    }
  }
`;

export const GET_CUSTOMER_INVOICES = gql`
  query customerInvoices($page: Int!, $itemsPerPage: Int!) {
    invoices(page: $page, itemsPerPage: $itemsPerPage) {
      pagesCount
      items {
        stripeId
        linkToInvoice
        total
        currency
        billingStartDate
        billingEndDate
        status
      }
    }
  }
`;

export const GET_USER_BILLING_INFO = gql`
  query userPaymentMethod($clientCode: String!) {
    paymentMethod {
      last4
    }
    customer {
      name
      email
      state
      postalCode
      line1
      line2
      country
      city
    }
    testCredits {
      testCreditsNumber
      testCreditsExpirationDate
    }
    subscription {
      upcomingSubscription
      currentAppSeatsNum
      items {
        quantity
        price {
          id
          unitAmount
        }
      }
    }
    upcomingPayment {
      periodStart
      periodEnd
      subscription {
        appNumber
        total
      }
      payAsYouGo {
        testsNumber
        total
      }
      unlimitedPlan {
        total
      }
      freeCredits {
        used
        total
      }
      total
    }
    client(clientCode: $clientCode) {
      isUnlimited
      isFree
    }
  }
`;

export const GET_CUSTOMER_DETAILS = gql`
  query customerDetails {
    customer {
      name
      phone
      email
      state
      postalCode
      line1
      line2
      country
      city
    }
  }
`;

export const GET_BASIC_PRICES_AND_USER_DOMAIN_STATUS = gql`
  query getBasicPrices {
    basicPrices {
      unitAmount
      currency
      priceType
      product {
        name
      }
    }
    isUserDomainAlreadyRegistered
  }
`;

export const GET_OVERDUE_INVOICE_DETAILS = gql`
  query getOverdueInvoiceDetails {
    overdueInvoiceDetails {
      clientSecret
      wasPaid
      invoice {
        id
        total
        invoiceNo
        billingStartDate
        billingEndDate
      }
    }
  }
`;
