import moment from "moment";

export const dateFormat = moment.localeData().longDateFormat("L");
export const timeFormat = "hh:mm:ss A";
export const dateFormatWithTime = `${dateFormat} ${timeFormat}`;

export const convertDateToDisplayFormat = (
  date: string,
  format: string = dateFormat,
  inputParesFormat?: string,
) => {
  const parsedDate = inputParesFormat
    ? moment(date, inputParesFormat)
    : moment(date);

  return parsedDate.format(format);
};

export const dateTimeFormat = (date: string) => {
  const dateObject = new Date(date);
  return dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
    hour12: true,
  });
};

export const timeRemaining = (value: string) => {
  const date = new Date(value);
  const now = new Date();

  const diffHours = Math.round(
    (date.getTime() - now.getTime()) / (1000 * 60 * 60),
  );
  if (diffHours <= 0) {
    return "less than an hour";
  }

  const days = Math.floor(diffHours / 24);
  const hours = diffHours - days * 24;

  const daysTerm = days > 1 ? "days" : "day";
  const hoursTerm = hours > 1 ? "hours" : "hour";

  if (days > 100) {
    return "100+ days";
  } else if (days === 0) {
    return `${hours} ${hoursTerm}`;
  } else if (hours === 0) {
    return `${days} ${daysTerm}`;
  } else {
    return `${days}\u00A0${daysTerm} ${hours}\u00A0${hoursTerm}`;
  }
};

export const currentDateTime = () => {
  return moment().format(dateFormatWithTime);
};

export default {
  convertDateToDisplayFormat,
  dateTimeFormat,
  timeRemaining,
  currentDateTime,
};
