import * as React from "react";
import styled from "styled-components";
import { Cell } from "react-table";
import { isEmpty } from "lodash";
import UnStyledIframe from "react-iframe";

import { Notification } from "../consts/interfaces";
import { NotificationStatuses } from "../consts/enums";

import { truncate } from "utils/strings";
import youtube from "utils/youtube";

import { Tooltip } from "components/Tooltip/Tooltip";

// -- TYPES
// note that these are not all props which table provide to this component
type ThumbnailCellProps = {
  cell: Cell<Notification>;
};

// -- COMPONENT
export function ThumbnailCell({
  cell,
}: ThumbnailCellProps): JSX.Element | null {
  const thumbnail = React.useMemo(() => {
    const notification = cell.row.original;
    let thumbnailElement = null;

    if (isEmpty(notification.data)) {
      return thumbnailElement;
    }

    switch (notification.type) {
      case NotificationStatuses.NEW_TOP_ELEMENT_TESTED:
      case NotificationStatuses.ELIMINATED_CONTROL_ASSET:
        const { asset_info } = notification.data;
        const isTextAsset = asset_info.url === undefined;

        if (isTextAsset) {
          thumbnailElement = (
            <Tooltip content={asset_info.name}>
              <TextThumbnail>
                {truncate(asset_info.name, 40).trim()}
              </TextThumbnail>
            </Tooltip>
          );
        } else {
          const thumbnailUrl =
            asset_info.url || youtube.getThumbnail(asset_info.name);

          thumbnailElement = <ThumbnailImage src={thumbnailUrl} />;
        }
        break;
      case NotificationStatuses.NEW_TOP_CONVERSION:
      case NotificationStatuses.COMPLETED_TEST:
        const { top_page_url } = notification.data;
        thumbnailElement = (
          <Container>
            <Iframe url={top_page_url} frameBorder={0} display="block" />
            <IframeOverlay />
          </Container>
        );
        break;
      case NotificationStatuses.NEW_BCR_CALCULATION:
        const { control_page_url } = notification.data;
        thumbnailElement = (
          <Container>
            <Iframe url={control_page_url} frameBorder={0} display="block" />
            <IframeOverlay />
          </Container>
        );
        break;
      default:
        thumbnailElement = null;
        break;
    }

    return thumbnailElement;
  }, [cell.row.original]);

  return thumbnail;
}

// -- STYLED
const ThumbnailImage = styled.img`
  height: 100px;
  width: auto;
`;

const TextThumbnail = styled.div`
  height: 100px;
  width: 100px;
  font-style: italic;
`;

const Container = styled.div`
  width: 100px;
  height: 100px;

  padding: 0;

  overflow: hidden;

  position: relative;
`;

const Iframe = styled(UnStyledIframe)`
  width: 360px;
  height: 740px;

  border: 0;

  -ms-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  -webkit-transform: scale(0.4);
  transform: scale(0.4);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
`;

const IframeOverlay = styled.div`
  position: absolute;

  width: 100px;
  height: 100px;

  border-radius: 2px;
`;
