/**
 * Compare template url to real url with params. Works for url with /:id/
 * @param {string} templateUrl template url with params marked as ex. :id
 * @param {string } urlWithParams url path part
 * @returns {boolean} check if real url are based on template url
 */
export const compareTemplateUrl = (
  templateUrl: string,
  urlWithParams: string,
): boolean => {
  if (!templateUrl.includes(":")) {
    return templateUrl === urlWithParams;
  }

  return new RegExp(
    templateUrl.replace(/:([A-Za-z0-9]+)/gm, "(.*)") + "$",
  ).test(urlWithParams);
};

/**
 * Create url based on templateUrl
 * @param {string} templateUrl template url with params marked as ex. :id
 * @param {object} params object with url params
 * @returns {string} templateUrl with replaced params tags by value
 */
export const createUrlWithParams = (
  templateUrl: string,
  params: Record<string, unknown> = {},
): string => {
  Object.keys(params).forEach((param) => {
    templateUrl = templateUrl.replace(`:${param}`, String(params[param]));
  });

  return templateUrl;
};
