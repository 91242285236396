import validators from "utils/validators";

import { DetailsFormField } from "./detailsFormField";

export const detailsFormFieldList: DetailsFormField[] = [
  {
    fieldKey: "name",
    fieldLabel: "Legal Company Name",
    placeholder: "Enter Company Name",
    validator: validators.composeValidators(
      false,
      validators.required,
      validators.maxStringLength(100),
    ),
  },
  {
    fieldKey: "email",
    fieldLabel: "Email for Invoices",
    validator: validators.composeValidators(
      false,
      validators.required,
      validators.email,
      validators.maxStringLength(255),
    ),
    placeholder: "Enter Email Address",
  },
  {
    fieldKey: "line1",
    fieldLabel: "Address Line 1",
    placeholder: "Enter Street, PO Box",
    validator: validators.composeValidators(
      false,
      validators.required,
      validators.maxStringLength(255),
    ),
  },
  {
    fieldKey: "line2",
    fieldLabel: "Address Line 2",
    placeholder: "Enter Apartment, Suite, Unit or Building",
    validator: validators.composeValidators(
      true,
      validators.maxStringLength(255),
    ),
  },
  {
    fieldKey: "city",
    fieldLabel: "City",
    placeholder: "Enter City, District, Suburb, Town or Village",
    validator: validators.composeValidators(
      false,
      validators.required,
      validators.maxStringLength(255),
    ),
  },
  {
    fieldKey: "state",
    fieldLabel: "State",
    placeholder: "Enter State, County, Province or Region",
    validator: validators.composeValidators(
      false,
      validators.required,
      validators.maxStringLength(255),
    ),
  },
  {
    fieldKey: "postalCode",
    fieldLabel: "Postal Code",
    placeholder: "Enter ZIP or Postal Code",
    validator: validators.composeValidators(
      false,
      validators.required,
      validators.maxStringLength(10),
    ),
  },
  {
    fieldKey: "country",
    fieldLabel: "Country",
    placeholder: "Enter Country Name",
    validator: validators.composeValidators(
      false,
      validators.required,
      validators.maxStringLength(255),
    ),
  },
];
