import React, { useCallback, useMemo } from "react";
import Select, {
  default as ClearableSelect,
  components as selectComponents,
  OptionProps,
  StylesConfig,
} from "react-select";
import hexToRgba from "hex-to-rgba";

import { SelectDropdownOptions } from "./constants/selectDropdownOptions";

import theme from "components/themes";
import FormError from "../FormError";

// TODO: probably we can remove this but I don't want do a big refactor in other places so I leave this.
export interface Option {
  value: string;
  label: string;
}

interface Props {
  defaultValue?: any;
  value?: string | string[] | null;
  options: { [key: string]: any }[];
  onChange: (value: any) => void;
  valid?: boolean;
  error?: string;
  name?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  labelKey?: string;
  valueKey?: string;
  shouldReturnOption?: boolean;
  menuPosition?: SelectDropdownOptions;
  components?: { [key: string]: any };
  isDisabled?: boolean;
  light?: boolean | undefined;
}

interface SelectStyleMakerProps {
  valid: boolean;
  light: boolean | undefined;
}

const makeSelectStyles = ({
  valid,
  light,
}: SelectStyleMakerProps): StylesConfig<any, boolean> => {
  const controlBoxShadow = (isFocused: boolean) => {
    if (isFocused) {
      return `0 0 15px 0 ${theme.colors.infoBlueBoxShadow}`;
    }

    if (!valid) {
      return `0 0 15px 0 ${theme.colors.notificationRedBoxShadow}`;
    }

    return "none";
  };

  const controlBorder = (isFocused: boolean) => {
    if (isFocused) {
      return theme.colors.infoBlue;
    }

    if (!valid) {
      return theme.colors.notificationRed;
    }

    return theme.colors.background;
  };

  const singleOptionBackground = (isSelected: boolean, isFocused: boolean) => {
    if (isFocused) {
      return hexToRgba(theme.colors.purple, 0.5);
    }

    return isSelected
      ? hexToRgba(theme.colors.purple, 0.3)
      : theme.colors.inputs;
  };

  const disabledOpacity = (isDisabled: boolean) => {
    if (isDisabled) {
      return 0.5;
    }
    if (light) {
      return 0.8;
    }
    return 1.0;
  };

  const backgroundColorChoice = () => {
    if (light) {
      return theme.colors.inputsLight;
    }
    return theme.colors.inputs;
  };

  return {
    control: (styles: any, { menuIsOpen, isFocused, isDisabled }) => ({
      ...styles,
      "backgroundColor": backgroundColorChoice(),
      "color": theme.colors.white,
      "borderColor": controlBorder(isFocused),
      "borderRadius": "2px",
      "boxShadow": controlBoxShadow(isFocused),
      "opacity": disabledOpacity(isDisabled),
      ":hover": {
        borderColor: menuIsOpen
          ? theme.colors.infoBlue
          : valid
          ? theme.colors.background
          : theme.colors.notificationRed,
      },
    }),
    // @ts-ignore
    option: (styles, { isSelected, isFocused }) => {
      return {
        ...styles,
        "backgroundColor": singleOptionBackground(isSelected, isFocused),
        ":hover": {
          backgroundColor: hexToRgba(theme.colors.purple, 0.5),
        },
      };
    },
    placeholder: (styles: any) => ({
      ...styles,
      color: theme.colors.white,
      opacity: 0.4,
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: theme.colors.white,
    }),
    multiValue: (styles: any) => ({
      ...styles,
      color: theme.colors.white,
      backgroundColor: theme.colors.backgroundLight,
      border: `1px solid ${theme.colors.purple}`,
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: theme.colors.white,
      fontSize: "14px",
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      ":hover": {
        cursor: "pointer",
        color: theme.colors.white,
        backgroundColor: theme.colors.backgroundLight,
      },
    }),
    indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
    dropdownIndicator: (styles: any) => ({
      ...styles,
      "color": theme.colors.white,
      ":hover": {
        color: theme.colors.white,
        cursor: "pointer",
      },
    }),
    clearIndicator: (styles: any) => ({
      ...styles,
      "display": "inherit",
      "color": theme.colors.white,
      ":hover": {
        color: theme.colors.white,
        cursor: "pointer",
      },
    }),
    input: (styles: any) => ({ ...styles, color: theme.colors.white }),
    menu: (styles: any) => ({
      ...styles,
      backgroundColor: theme.colors.inputs,
      color: theme.colors.white,
      margin: 0,
    }),
    menuList: (styles: any) => ({
      ...styles,
      padding: 0,
      borderRadius: "0 0 4px 4px",
    }),
  };
};

function SelectInput({
  value,
  defaultValue,
  options = [],
  onChange,
  valid = true,
  error,
  name,
  isClearable = false,
  isSearchable = false,
  isMulti = false,
  labelKey = "label",
  valueKey = "value",
  shouldReturnOption = false,
  menuPosition = SelectDropdownOptions.auto,
  components = {},
  isDisabled,
  light,
}: Props) {
  const selectedOption = useMemo(() => {
    if (!isMulti) {
      const singleValue = options.find((o) => o[valueKey] === value);
      return singleValue || null;
    }

    return options.filter((singleOption) => {
      if (!value) {
        return false;
      }

      return value.includes(singleOption[valueKey]);
    });
  }, [value, options, isMulti, valueKey]);

  const styles = useMemo(() => makeSelectStyles({ valid, light }), [
    valid,
    light,
  ]);

  const handleChange = useCallback(
    (option: { [key: string]: any }) => {
      let valueToSet: any = "";
      if (option) {
        valueToSet = shouldReturnOption ? option : option[valueKey];
      } else if (shouldReturnOption) {
        valueToSet = {};
      }
      valueToSet !== value && onChange(valueToSet);
    },
    [onChange, valueKey, shouldReturnOption, value],
  );

  const handleMultiChange = useCallback(
    (optionsList: { [key: string]: any }) => {
      if (optionsList) {
        if (shouldReturnOption) {
          onChange(optionsList);
        } else {
          onChange(
            optionsList.map(
              (option: { [key: string]: any }) => option[valueKey],
            ),
          );
        }
      } else {
        onChange([]);
      }
    },
    [onChange, shouldReturnOption, valueKey],
  );

  const getLabel = (option: { [key: string]: any }) => option[labelKey];
  const getValue = (option: { [key: string]: any }) => option[valueKey];

  const OptionComponent = (
    props: OptionProps<{ [key: string]: any }, boolean>,
  ) => {
    if (selectedOption && selectedOption[valueKey] === props.data[valueKey]) {
      return null;
    }
    if (components && components.Option) {
      return <components.Option {...props} />;
    }
    return <selectComponents.Option {...props} />;
  };

  return (
    <>
      {isClearable ? (
        <ClearableSelect
          isClearable
          name={name}
          styles={styles}
          value={selectedOption}
          options={options}
          // @ts-ignore
          onChange={handleChange}
          isSearchable={false}
          placeholder="Select"
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          menuPlacement={menuPosition}
          components={{ ...components, Option: OptionComponent }}
          isDisabled={isDisabled}
          light={light}
        />
      ) : (
        <Select
          name={name}
          styles={styles}
          value={selectedOption}
          defaultValue={defaultValue}
          options={options}
          // @ts-ignore
          onChange={isMulti ? handleMultiChange : handleChange}
          isSearchable={isSearchable}
          placeholder="Select"
          isMulti={isMulti}
          noOptionsMessage={() => null}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          menuPlacement={menuPosition}
          components={{ ...components, Option: OptionComponent }}
          isDisabled={isDisabled}
          light={light}
        />
      )}
      {!valid ? <FormError>{error}</FormError> : null}
    </>
  );
}

export default SelectInput;
