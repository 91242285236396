import React, { useContext } from "react";
import styled from "styled-components";
import hexToRgba from "hex-to-rgba";
import { Flex } from "@rebass/grid";

import { HistoricalConversionData } from "api";
import { Icons, Tooltip } from "components";
import { ModalContext } from "contexts";
import { AssetHistoricalDataTable } from "components/AssetHistoricalDataTable";
import AssetCardActionIcon from "../../../AssetCardActionIcon";

import Button from "components/forms/Button";
import themes from "components/themes";

export interface Action {
  name: string;
  onClick: () => void;
}

interface Props {
  isHovered?: boolean;
  control?: boolean;
  actions?: Action[];
  variationPreview?: string;
  videoUrl?: string;
  onRemove?: (() => void) | null;
  onDeactivate?: (() => void) | null;
  onControl?: (() => void) | null;
  onEdit?: (() => void) | null;
  onSetTop?: (() => void) | null;
  historicalData?: HistoricalConversionData[];
}

function AssetActions({
  isHovered,
  control,
  actions = [],
  onControl,
  onEdit,
  onSetTop,
  onRemove,
  onDeactivate,
  variationPreview,
  videoUrl,
  historicalData,
}: Props) {
  const readonly =
    !onRemove &&
    !onControl &&
    !onEdit &&
    !onSetTop &&
    !onDeactivate &&
    actions.length === 0 &&
    !variationPreview &&
    !videoUrl &&
    (!historicalData || historicalData.length === 0);

  const setModal = useContext(ModalContext);

  const videoModalContent = (url: string): JSX.Element => {
    if (url.indexOf("youtube") >= 0 || url.indexOf("youtu.be") >= 0) {
      return (
        <iframe
          width="620"
          height="349"
          src={videoUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="asset-video"
        />
      );
    }
    return <Video src={url} controls />;
  };

  const handleVideoPreviewClick = (url: string) => () => {
    setModal({
      type: "set",
      props: {
        title: "Video Preview",
        content: videoModalContent(url),
      },
    });
  };

  const handleAssetHistoryClick = () => {
    setModal({
      type: "set",
      props: {
        title: "Historical Data",
        width: 1000,
        content: historicalData ? (
          <AssetHistoricalDataTable historicalData={historicalData} />
        ) : (
          "No data"
        ),
      },
    });
  };

  return (
    <>
      {!readonly && isHovered && (
        <Actions>
          {!control && onControl && (
            <ActionItem outline onClick={onControl}>
              Control
            </ActionItem>
          )}
          {onSetTop && (
            <ActionItem outline onClick={onSetTop}>
              Set as Winner
            </ActionItem>
          )}
          {onRemove && (
            <ActionItem outline onClick={onRemove}>
              Delete
            </ActionItem>
          )}
          {onDeactivate && (
            <ActionItem outline onClick={onDeactivate}>
              Remove From Test
            </ActionItem>
          )}
          {onEdit && (
            <ActionItem outline onClick={onEdit}>
              Edit
            </ActionItem>
          )}
          {actions.map((action) => {
            return (
              <ActionItem key={Math.random()} outline onClick={action.onClick}>
                {action.name}
              </ActionItem>
            );
          })}
          <ActionIcons>
            {variationPreview && (
              <a
                target="_blank"
                href={variationPreview}
                rel="noopener noreferrer"
              >
                <AssetCardActionIcon
                  icon={Icons.faEye}
                  color={themes.colors.white}
                />
              </a>
            )}
            {videoUrl && (
              <span onClick={handleVideoPreviewClick(videoUrl)}>
                <AssetCardActionIcon
                  icon={Icons.faPlay}
                  color={themes.colors.white}
                />
              </span>
            )}
            {historicalData && historicalData.length > 0 && (
              <Tooltip content={"View historical data"}>
                <AssetCardActionIcon
                  icon={Icons.faHistory}
                  color={themes.colors.white}
                  onClick={handleAssetHistoryClick}
                />
              </Tooltip>
            )}
          </ActionIcons>
        </Actions>
      )}
    </>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: ${hexToRgba(themes.colors.purple, 0.7)};
  border-radius: 8px;
  padding: 10px;
  pointer-events: none;
`;

const ActionItem = styled(Button)`
  width: 148px;
  padding: 0;
  min-height: 35px;
  max-height: 35px;
  min-width: 100%;
  max-width: 100%;
  font-size: 12px;
  border-radius: ${themes.borderRadius.sharp};
  margin: 5px auto;
  pointer-events: auto;

  &:hover {
    color: ${themes.colors.purple};
    background: #fff;
  }
`;

const ActionIcons = styled(Flex)`
  position: absolute;
  top: 2px;
  right: 7px;
  cursor: pointer;
  font-size: 14px;
  pointer-events: auto;
  column-gap: 5px;
  display: flex;
  align-items: center;
`;

const Video = styled.video`
  width: 100%;
`;

export default AssetActions;
