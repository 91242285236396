import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { ApplicationContext } from "contexts";

import { GET_APPLICATION_TITLE_AND_STATUS } from "api/queries";

import { Spinner, Title, Icon, Icons, Error } from "components";
import { Button } from "components/forms";
import { UserPermissions } from "v2/api/types/UserPermissions";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

interface ApplicationElementsContainerProps {
  children: React.ReactNode;
  history: any;
  uploadRoute: string;
  applicationId: string;
  uploadButtonContent?: string;
  permissionsForUpload?: UserPermissions[];
}

function ApplicationElementsContainer({
  children,
  history,
  uploadRoute,
  applicationId,
  uploadButtonContent = "Upload New Asset",
  permissionsForUpload = [],
}: ApplicationElementsContainerProps): JSX.Element {
  const applicationCtx = useContext(ApplicationContext);
  const { data, error, loading } = useQuery(GET_APPLICATION_TITLE_AND_STATUS, {
    variables: { id: applicationId },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  if (data.application === null) {
    history.replace(authRoutes.APPLICATION_LIST.getUrlWithParams());
  }

  applicationCtx.setCurrent(data.application);

  const handleRedirect = () => history.push(uploadRoute);

  return (
    <>
      <Title
        button={
          <PermissionRestriction requiredPermissions={permissionsForUpload}>
            <Button
              icon={<Icon icon={Icons.faPlus} />}
              onClick={handleRedirect}
            >
              {uploadButtonContent}
            </Button>
          </PermissionRestriction>
        }
      >
        {data.application.name}
      </Title>
      {children}
    </>
  );
}

export default ApplicationElementsContainer;
