import React, { useState, FC } from "react";
import { Flex, Box } from "@rebass/grid";
import { isArray } from "lodash";
import styled from "styled-components";

import { StatisticsCard } from "components";
import { StatisticsCardProps } from "components/StatisticsCard";

// -- TYPES
interface StyleProps {
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  pr?: number;
  pl?: number;
  pt?: number;
  pb?: number;
}

interface StatisticsCardsListProps {
  cardsList: StatisticsCardProps[];
  cardStyle?: StyleProps;
  wrapperStyle?: StyleProps;
  elementsInRow?: number;
}

// -- COMPONENT
const StatisticsCardsList: FC<StatisticsCardsListProps> = ({
  cardsList = [],
  cardStyle: globalCardBoxStyle = {},
  wrapperStyle = {},
  elementsInRow = 4,
}) => {
  const [activeCard, setActiveCard] = useState<number>(0);

  const setCardAsActive = (cardIndex: number) => (): void =>
    setActiveCard(cardIndex);

  const renderStatisticsCards = () => {
    return cardsList.map((singleCard, cardIndex) => {
      return (
        <Box
          key={`statistic-card-${cardIndex}`}
          onMouseEnter={setCardAsActive(cardIndex)}
          width={1 / elementsInRow}
          {...globalCardBoxStyle}
          pr={10}
          pl={10}
        >
          <StatisticsCard active={activeCard === cardIndex} {...singleCard} />
        </Box>
      );
    });
  };

  if (!isArray(cardsList)) {
    return null;
  }

  return (
    <StyledFlex
      justifyContent="space-between"
      flexWrap="wrap"
      {...wrapperStyle}
    >
      {renderStatisticsCards()}
    </StyledFlex>
  );
};

// -- STYLES
const StyledFlex = styled(Flex)`
  margin-left: -10px;
  margin-right: -10px;
`;

export default StatisticsCardsList;
