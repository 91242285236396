import * as React from "react";

import { GroupedImagesSection } from "../constants/sections";

import UISection from "components/Section";
import { ImageSection } from "./ImageSection";

export function GroupedImageSections({ title, groups }: GroupedImagesSection) {
  return (
    <UISection title={title} transparentHeader>
      {groups.map((singleImageSection) => (
        <ImageSection
          key={singleImageSection.title}
          {...singleImageSection}
          isSubSection
        />
      ))}
    </UISection>
  );
}
