import config from "config";
import { ModalContext } from "contexts";
import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";

import { bytesToMegaBytes } from "utils/numbers";

import AssetInput from "./AssetInput";

export interface UploadFile {
  preview: string;
  videoId?: string;
  file: File | null;
  id?: string;
  fileName?: string;
}

interface Props {
  placeholder?: string;
  onUpload: (data: UploadFile[]) => any;
  disabled?: boolean;
  video?: boolean;
  maxSingleFilesize?: number;
}

// -- COMPONENT
function UploadInput({
  placeholder = "Choose file",
  onUpload,
  disabled = false,
  video = false,
  maxSingleFilesize = config.maxSingleFilesize,
}: Props) {
  const setModal = useContext(ModalContext);

  const onDrop = useCallback(
    (acceptedFiles: any, rejectedFiles: any) => {
      const filteredAndFormatedFiles: UploadFile[] = acceptedFiles.map(
        (file: File) => {
          return {
            preview: URL.createObjectURL(file),
            file,
          };
        },
      );

      if (rejectedFiles.length) {
        setModal({
          type: "set",
          props: {
            title: "File Size Limit",
            content: `
                  Some of files exceeded the size limit. They can't be uploaded. 
                  Each file cannot exceed ${bytesToMegaBytes(
                    config.maxSingleFilesize,
                  )}MB in size.`,
          },
        });
      }
      if (filteredAndFormatedFiles.length) onUpload(filteredAndFormatedFiles);
    },
    [onUpload, setModal],
  );

  const accept = video
    ? [".png", ".jpg", ".jpeg", ".mov", ".m4v", ".mp4"]
    : [".png", ".jpg", ".jpeg"];

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop,
    maxSize: maxSingleFilesize,
  });

  return (
    <AssetInput
      title={placeholder}
      rootProps={getRootProps()}
      disabled={disabled}
    >
      {!disabled && <input {...getInputProps()} />}
    </AssetInput>
  );
}

export default UploadInput;
