import * as React from "react";
import styled from "styled-components";

import { convertToCurrency } from "utils/numbers";

import { Tooltip } from "components/Tooltip/Tooltip";
import Icon, { Icons } from "components/Icon";
import themes from "components/themes";

export const subscriptionsDescription = (
  <p>
    Purchase one or more Apps for unlimited testing (an App includes both iOS &
    Android tests)
  </p>
);

export const startForFreeDescription = (unitAmount?: number) => (
  <>
    <p>
      Select pay-as-you-go and start testing immediately for{" "}
      {unitAmount ? convertToCurrency(unitAmount / 100) : NaN}/test, billed
      monthly.
    </p>
    <p>You will not be charged until you start your first test.</p>
  </>
);

export const startForFreeForUsedDomainDescription = (unitAmount?: number) => (
  <>
    <p>Get access today with no charge.</p>
    <p>
      Pay-as-you-go testing charged at{" "}
      {unitAmount ? convertToCurrency(unitAmount / 100) : NaN}/test, billed
      monthly.
    </p>
  </>
);

export const contractRequired = () => (
  <ContractWrapper>
    <Tooltip
      content={`If we haven't already, our team will reach out to discuss ongoing contract terms. You may, however, start usage immediately.`}
    >
      <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
    </Tooltip>
    <p>Contract Required</p>
  </ContractWrapper>
);

const ContractWrapper = styled.div`
  margin-top: 20px;

  &&& {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    p {
      font-style: italic;
      text-align: left;
      font-size: 12px;
      margin: 0;
    }

    span {
      font-size: 12px;
    }

    svg {
      margin-right: 5px;
    }
  }
`;
