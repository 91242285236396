import React, { FC } from "react";
import styled from "styled-components";
import { Field, FieldInputProps, Form } from "react-final-form";
import { Flex, Box } from "@rebass/grid";

import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";
import { AuthSection } from "components/auth";
import { Button, FormLabel, TextInput, CheckboxInput } from "components/forms";
import validators from "utils/validators";
import formatters from "utils/formatters";

// -- TYPES
export interface FormData {
  firstName: string;
  lastName: string;
  company: string | null;
  emailAddress: string;
  terms: boolean;
}

interface Props {
  onSubmit: (data: FormData) => void;
  validationError?: string | null;
  setValidationError: React.Dispatch<React.SetStateAction<string | null>>;
  asIframe?: boolean;
}

// -- COMPONENT
const SignUpPage: FC<Props> = ({
  onSubmit,
  validationError,
  setValidationError,
  asIframe = false,
}) => {
  const defaultState: FormData = {
    firstName: "",
    lastName: "",
    company: null,
    emailAddress: "",
    terms: false,
  };

  const handleFormSubmit = (values: object) => onSubmit(values as FormData);

  const resetValidationErrorOnChange = (input: FieldInputProps<any>) => (
    event: React.ChangeEvent | any,
  ) => {
    setValidationError(null);
    input.onChange(event);
  };

  return (
    <Form onSubmit={handleFormSubmit} initialValues={defaultState}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <AuthSection
            title="Create a new account"
            hints={[
              {
                title: "Already have an account?",
                link: "Sign in",
                route: nonAuthRoutes.AUTH_SIGN_IN.getUrlWithParams(),
              },
            ]}
            asIframe={asIframe}
          >
            <Field
              name="firstName"
              validate={validators.required}
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="firstName">First Name</FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Your First Name"
                      maxLength={30}
                      light
                      error={meta.touched && meta.error}
                      {...input}
                    />
                  </div>
                );
              }}
            </Field>

            <Field
              name="lastName"
              validate={validators.required}
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="lastName">Last Name</FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Your Last Name"
                      maxLength={60}
                      light
                      error={meta.touched && meta.error}
                      {...input}
                    />
                  </div>
                );
              }}
            </Field>

            <Field
              name="company"
              validate={validators.required}
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="company">Company Name</FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Your Company Name"
                      maxLength={100}
                      light
                      error={meta.touched && meta.error}
                      {...input}
                    />
                  </div>
                );
              }}
            </Field>

            <Field
              name="emailAddress"
              validate={(value: any) =>
                validators.required(value) || validators.email(value)
              }
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="email">Email</FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Your Email Address"
                      maxLength={50}
                      light
                      {...input}
                      error={
                        validationError === "EMAIL_IN_USE"
                          ? "Email address has already been used"
                          : validationError === "EMAIL_FORBIDDEN"
                          ? "Please use your company email"
                          : meta.touched && meta.error
                      }
                      onChange={resetValidationErrorOnChange(input)}
                    />
                  </div>
                );
              }}
            </Field>

            {/* <Field name="inviteCode" validate={validators.required}>
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="Invite code">Invite code</FormLabel>
                    <TextInput
                      placeholder="Enter Your Invite code"
                      light
                      error={
                        validationError === "INCORRECT_CODE"
                          ? "Invitation Code has already been used or is invalid."
                          : meta.touched && meta.error
                      }
                      {...input}
                    />
                  </div>
                );
              }}
            </Field> */}

            <Flex justifyContent="space-between" alignItems="center" my={50}>
              <Box>
                <Field
                  name="terms"
                  type="checkbox"
                  validate={validators.required}
                >
                  {({ input, meta }) => {
                    return (
                      <div>
                        <CheckboxInput
                          label={
                            <span>
                              I agree to the{" "}
                              <Link
                                href="https://upptic.com/terms-of-use/"
                                target="_blank"
                              >
                                Terms
                              </Link>
                            </span>
                          }
                          light
                          error={meta.touched && meta.error}
                          {...input}
                        />
                      </div>
                    );
                  }}
                </Field>
              </Box>
              <Box>
                <SubmitButton disabled={submitting} type="submit">
                  Sign up
                </SubmitButton>
              </Box>
            </Flex>
          </AuthSection>
        </form>
      )}
    </Form>
  );
};

// -- STYLES
const SubmitButton = styled(Button)`
  min-width: 240px;
`;
const Link = styled.a`
  color: #fff;
  text-decoration: underline;

  :hover {
    color: inherit;
  }
`;

export default SignUpPage;
