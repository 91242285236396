import { gql } from "@apollo/client";

import { Variation } from "api";
import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const updateReferenceVariation = new ApiMutation({
  definition: gql`
    mutation updateReferenceVariation($assetId: ID!, $assetType: String!) {
      updateReferenceVariation(assetId: $assetId, assetType: $assetType) {
        referenceVariation {
          id
        }
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateASOTest],
});

export interface UpdateReferenceVariationResponse {
  updateReferenceVariation: {
    referenceVariation: Variation;
  };
}
