import { authRoutes } from "./routesGroups/authRoutes";
export const getInitalUrl = (
  modules: string[],
  clientCode: string,
  currentModule: string,
) => {
  const moduleCode = modules.includes(currentModule)
    ? currentModule
    : modules.filter((code) => code !== "client")[0];
  if (!moduleCode) return "/no-products";
  if (moduleCode === "aso") {
    return authRoutes.APPLICATION_LIST.getUrlWithParams();
  }
  return `/${clientCode}/${moduleCode}`;
};
