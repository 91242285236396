import config from "config";

export const idenfifyUser = (
  userId: string,
  additionalUserDetails = {},
): void => {
  config.isDev &&
    console.log(
      `Identify user with id ${userId} with additional data ${JSON.stringify(
        additionalUserDetails,
      )}. Was called: ${!!window.userpilot?.identify}`,
    );
  window.userpilot?.identify(userId, additionalUserDetails || {});
};

export const userpilotReloadUrl = (
  url: string = window.location.href,
): void => {
  config.isDev &&
    console.log(
      "Fire userpilot reload to address:",
      url,
      "Was called:",
      !!window.userpilot?.reload,
    );
  window.userpilot?.reload({ url });
};

export const trackCustomEvent = (eventName: string): void => {
  config.isDev &&
    console.log(
      "fire userpilot track event:",
      eventName,
      "Was called:",
      !!window.userpilot?.track,
    );
  window.userpilot?.track(eventName);
};
