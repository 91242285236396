import * as React from "react";
import { styled } from "@mui/system";
import hexToRgba from "hex-to-rgba";
import { Row } from "react-table";
import { useMutation } from "@apollo/client";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { MARK_AS_READ } from "api/mutations/notification";

import { ApplicationUserContext } from "contexts";

import { useStores } from "contexts/storeContext";

import { Notification } from "../consts/interfaces";

// -- TYPES
type NotificationRowProps = {
  children?: React.ReactElement | React.ReactElement[];
  rowObject?: Row<Notification>;
};

// -- COMPONENT
export function NotificationRow({
  children,
  rowObject,
}: NotificationRowProps): JSX.Element {
  const [isRead, setIsRead] = React.useState<boolean>(rowObject?.values.opened);

  const applicationUserCtx = React.useContext(ApplicationUserContext);

  const [markAsRead] = useMutation(MARK_AS_READ);

  const handleError = useRequestErrorHandler();

  const onMarkAsRead = React.useCallback(
    async (notificationId: number): Promise<boolean> => {
      const markAsReadResponse = await handleError(
        markAsRead({
          variables: {
            userId: applicationUserCtx.userId,
            notificationId,
          },
        }),
      );

      return Boolean(markAsReadResponse);
    },
    [markAsRead, handleError, applicationUserCtx.userId],
  );

  React.useEffect(() => {
    if (rowObject?.values.opened !== isRead) {
      setIsRead(rowObject?.values.opened);
    }
  }, [rowObject, isRead]);

  const { userStore } = useStores();

  const handleMarkAsRead = async () => {
    if (
      applicationUserCtx.userId === userStore.userId &&
      rowObject?.original.id &&
      !isRead
    ) {
      const isMarkedAsRead = await onMarkAsRead(rowObject?.original.id);
      setIsRead(isMarkedAsRead);
    }
  };

  return (
    <StyledRow read={isRead} onClick={handleMarkAsRead}>
      {children}
    </StyledRow>
  );
}

// -- STYLED
const StyledRow = styled("tr", {
  shouldForwardProp: (prop: string) => !["read"].includes(prop),
})<{ read?: boolean }>`
  border-top: 1px solid ${(props: any) => props.theme.table.colors.borderColor};

  &&& {
    cursor: pointer;

    &:hover {
      background-color: rgba(171, 104, 239, 0.2);
    }

    td {
      font-size: 14px;

      word-break: keep-all;

      &:nth-last-child(2) {
        white-space: nowrap;
      }

      ${(props: any) =>
        !props.read &&
        `
        background-color: ${hexToRgba(props.theme.colors.purple, 0.15)}
      `}
    }

    td:nth-child(-n + 2) {
      word-break: break-word;
    }
  }
`;
