import { isArray, isString, isPlainObject } from "lodash";

import { roundWithDecimalPlaces } from "utils/numbers";

import {
  AppConversionRates,
  ChartDataSetShape,
  ChartConfiguration,
  LegendElement,
  ReferenceLine,
  AxisDirection,
  ReferenceLinesTypes,
  PayloadContent,
  TrafficPayloadContent,
  TrafficContent,
} from "api/models";
import { UserActionTypes } from "components/Chart/constants/userActionTypes";
import themes from "components/themes";
import { convertDateToDisplayFormat, dateFormatWithTime } from "./dates";
import moment from "moment";
import { AvailableDisplayModes } from "../components/Chart/constants/types";

type ShipDatesData = {
  periodStartDate: string;
  dates: string[];
}[];

type UserActionsData = {
  periodStartDate: string;
  actions: {
    id: string;
    action: keyof typeof UserActionTypes;
    date: string;
  }[];
}[];

const chartDataColors: { [property: string]: string } = {
  control: themes.colors.androidGreen,
  traffic: themes.colors.brightCyan,
  frontrunner: themes.colors.lighterPurple,
};

export const prepareChartAreasData = (
  conversionRatesGroups: AppConversionRates[] | AppConversionRates,
): [ChartDataSetShape[], LegendElement[]] | null => {
  if (!isArray(conversionRatesGroups)) {
    if (isPlainObject(conversionRatesGroups)) {
      conversionRatesGroups = [conversionRatesGroups];
    } else {
      return null;
    }
  }

  const chartDataObject: any = {};
  const legendElements: LegendElement[] = [];

  conversionRatesGroups.forEach((singleRateGroup: AppConversionRates) => {
    const {
      label,
      conversionRates,
      legendOrderIndex,
      legendTooltip,
    } = singleRateGroup;

    if (!isString(label) || !isArray(conversionRates)) {
      return null;
    }

    const formattedLabel = label.toLowerCase();
    legendElements.push({
      label,
      dataIndex: `${formattedLabel}.cvr`,
      color: chartDataColors[formattedLabel],
      legendOrderIndex,
      legendTooltip,
    });

    conversionRates.forEach((singleConversion) => {
      const {
        date,
        cvr,
        conversions,
        visits,
        cumulativeConversions,
        cumulativeVisits,
      } = singleConversion;

      chartDataObject[date] = {
        ...(chartDataObject[date] || {}),
        [formattedLabel]: {
          cvr: roundWithDecimalPlaces(cvr, 1),
          tooltipCvr: {
            conversions: cumulativeConversions,
            visits: cumulativeVisits,
          },
          conversions,
          visits,
        },
      };
    });
  });

  const dataSet: ChartDataSetShape[] = Object.entries(chartDataObject).map(
    (entry: [string, any]) => {
      const [label, value] = entry;
      return {
        label,
        ...value,
      };
    },
  );

  return [dataSet, legendElements];
};

const prepareShipDatesLines = (shipDates: ShipDatesData): ReferenceLine[] => {
  if (!isArray(shipDates)) {
    return [];
  }

  return shipDates.map((singleShipDate) => {
    const { periodStartDate, dates } = singleShipDate;

    return {
      axisDirection: AxisDirection.vertical,
      axisValue: periodStartDate,
      tooltipValues: dates,
      type: ReferenceLinesTypes.shipDate,
    };
  });
};

const prepareUserActionLines = (
  userActions: UserActionsData,
): ReferenceLine[] => {
  if (!isArray(userActions)) {
    return [];
  }

  return userActions.map((singleUserAction) => {
    const { periodStartDate, actions } = singleUserAction;
    return {
      axisDirection: AxisDirection.vertical,
      axisValue: periodStartDate,
      tooltipValues: actions.map(
        (singleAction) =>
          `${convertDateToDisplayFormat(
            singleAction.date,
            dateFormatWithTime,
          )} - ${UserActionTypes[singleAction.action]}`,
      ),
      type: ReferenceLinesTypes.userAction,
      color: themes.colors.brightCyan,
    };
  });
};

const accumulateDataPoint = (
  aggregatedData: TrafficPayloadContent,
  pointToAdd: TrafficPayloadContent,
  legendElements: LegendElement[],
): PayloadContent => {
  legendElements.forEach((element) => {
    const keyName = element.label.toLowerCase();
    if (!element.isReferenceLine) {
      const data = aggregatedData[keyName] as TrafficContent;
      const point = pointToAdd[keyName] as TrafficContent;
      if (data.visits !== null || point.visits !== null) {
        data.visits += point.visits;
        data.conversions += point.conversions;
        data.tooltipCvr.conversions += point.tooltipCvr.conversions;
        data.tooltipCvr.visits += point.tooltipCvr.visits;
      }
    }
  });
  return aggregatedData;
};

function aggregateTooltipData(
  dataSet: ChartDataSetShape[],
  displayMode: AvailableDisplayModes,
  legendElements: LegendElement[],
): PayloadContent[] {
  return dataSet.reduce((aggregatedData: PayloadContent[], day) => {
    const periodStartDate = periodStartDateFormatter(
      day.label.toString(),
      displayMode,
    );

    const periodIndex = aggregatedData.findIndex(
      (chartElement) => chartElement.label === periodStartDate,
    );
    if (periodIndex === -1) {
      const dayTyped = day as TrafficPayloadContent;
      aggregatedData.push({
        label: periodStartDate,
        traffic: { ...dayTyped.traffic },
        control: { ...dayTyped.control },
        frontrunner: { ...dayTyped.frontrunner },
      });
    } else {
      aggregatedData[periodIndex] = accumulateDataPoint(
        aggregatedData[periodIndex] as TrafficPayloadContent,
        day as TrafficPayloadContent,
        legendElements,
      );
    }

    return aggregatedData;
  }, []);
}

function getAxisTicks(
  aggregatedTooltipData: PayloadContent[],
  dataSetBeginingDate: string,
  dataSetEndingDate: string,
): string[] {
  const tooltipData = aggregatedTooltipData.map((day) => {
    if (!dataSetBeginingDate) {
      return day.label.toString();
    }
    if (moment(day.label.toString()).isBefore(dataSetBeginingDate)) {
      return dataSetBeginingDate;
    }

    if (moment(day.label.toString()).isAfter(dataSetEndingDate)) {
      return dataSetEndingDate;
    }

    return day.label.toString();
  });

  const lastTwoLabelsDiff = moment(dataSetEndingDate).diff(
    tooltipData[tooltipData.length],
  );

  if (lastTwoLabelsDiff <= 5) {
    tooltipData.pop();
  }

  return tooltipData;
}

export const prepareChartConfiguration = (
  conversionRatesGroups: AppConversionRates[] | AppConversionRates,
  shipDates: ShipDatesData,
  userActions: UserActionsData,
  chartDisplayMode: AvailableDisplayModes,
): ChartConfiguration => {
  let dataSet: ChartDataSetShape[] = [];
  let xAxisTicks: string[] | undefined;
  let aggregatedTooltipData: PayloadContent[] = [];
  const legendElements: LegendElement[] = [];
  const chartData = prepareChartAreasData(conversionRatesGroups);
  const shipDatesLines = prepareShipDatesLines(shipDates);
  const userActionsLines = prepareUserActionLines(userActions);

  if (Array.isArray(userActionsLines) && userActionsLines.length) {
    legendElements.push({
      color: themes.colors.brightCyan,
      label: "User Actions",
      isReferenceLine: true,
      dataIndex: ReferenceLinesTypes.userAction,
      legendOrderIndex: 0,
      legendTooltip: "",
    });
  }

  if (isArray(shipDatesLines) && shipDatesLines.length) {
    legendElements.push({
      color: themes.colors.chartLightPurple,
      label: "Ship Dates",
      isReferenceLine: true,
      dataIndex: ReferenceLinesTypes.shipDate,
      legendOrderIndex: 0,
      legendTooltip: "",
    });
  }

  if (chartData) {
    const [dataSetFromChartData, legendElementsFromChartData] = chartData;
    dataSet = dataSetFromChartData;
    legendElements.push(...legendElementsFromChartData);
    if (chartDisplayMode !== "day") {
      aggregatedTooltipData = aggregateTooltipData(
        dataSet,
        chartDisplayMode,
        legendElements,
      );
      // TODO: in feature we should create correct type for ChartDataSetShape to avoid "as"
      const dataSetBeginingDate = dataSetFromChartData[0]?.label as string;
      const dataSetEndingDate = dataSetFromChartData[
        dataSetFromChartData.length - 1
      ]?.label as string;
      xAxisTicks = getAxisTicks(
        aggregatedTooltipData,
        dataSetBeginingDate,
        dataSetEndingDate,
      );
    }
  }

  const chartConfig: ChartConfiguration = {
    xAxisLabelByField: "label",
    dataSet,
    aggregatedTooltipData,
    legendElements,
    referenceLines: [...userActionsLines, ...shipDatesLines],
    xAxisTicks,
  };

  return chartConfig;
};

export const periodStartDateFormatter = (
  dateString: string,
  displayMode: AvailableDisplayModes,
) => {
  if (displayMode === "day") {
    return dateString;
  }

  return moment(dateString, "YYYY-MM-DD")
    .endOf(displayMode === "month" ? "month" : "isoWeek")
    .format("YYYY-MM-DD");
};
