import * as React from "react";
import styled from "styled-components";

import config from "config";

import { Platform } from "api/models";
import { SingleReview } from "api/models/reviews/singleReviews";

import {
  createReviewDomElements,
  generateInitialReviewsData,
} from "utils/reviews";

import { SingleReviewWizard } from "./components/SingleReviewWizard";
import { FormHeader, Switch } from "components/forms";
import FormSection from "components/forms/FormSection";

// -- TYPES
type ReviewsProps = {
  reviewsNumber?: number;
  platform: Platform;
  data: SingleReview[];
  onChange: (values: {
    [key: string]: string | number | null | object;
  }) => void;
};

// -- COMPONENT
export function ReviewsWizard({
  platform,
  data,
  onChange,
}: ReviewsProps): JSX.Element {
  const [useReviews, setUseReviews] = React.useState<boolean>(
    Boolean(data.length),
  );

  const handleReviewsUsage = React.useCallback(
    (useRev: boolean) => {
      onChange({
        reviews: useRev
          ? generateInitialReviewsData(config.defaultNumberOfReviews)
          : [],
      });

      setUseReviews(useRev);
    },
    [setUseReviews, onChange],
  );

  const reviewsElements = React.useMemo(() => {
    const elements = createReviewDomElements(config.defaultNumberOfReviews);

    return elements;
  }, []);

  const handleFieldChange = (reviewIndex: number) => (
    fieldName: string,
    fieldValue: string | number | null,
  ) => {
    onChange({
      reviews: data.map((singleReview, index) =>
        index !== reviewIndex
          ? singleReview
          : {
              ...singleReview,
              [fieldName]: fieldValue,
            },
      ),
    });
  };

  return (
    <FormSection>
      <HeaderWrapper isActive={useReviews}>
        <FormHeader>Reviews (Optional)</FormHeader>
        <Switch onChange={handleReviewsUsage} value={useReviews} />
      </HeaderWrapper>

      {useReviews &&
        data.length &&
        reviewsElements.map((singleReviewElement, index) => (
          <SingleReviewWizard
            key={`review-no-${index}`}
            platform={platform}
            onChange={handleFieldChange(index)}
            reviewElement={singleReviewElement}
            value={data[index]}
          />
        ))}
    </FormSection>
  );
}

// --UI
const HeaderWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: space-between;

  ${(props) =>
    !props.isActive &&
    `
    h3 {
      margin-bottom: 0;
    }
  `}
`;
