import { gql } from "@apollo/client";
import { Variation } from "api";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const cumulativeControlDataReset = new ApiMutation({
  definition: gql`
    mutation cumulativeControlDataReset(
      $applicationId: ID!
      $reinsertTopAssets: Boolean!
    ) {
      cumulativeControlDataReset(
        applicationId: $applicationId
        reinsertTopAssets: $reinsertTopAssets
      ) {
        restarted
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateASOTest],
});

export interface CumulativeControlDataResetResponse {
  cumulativeControlDataReset: {
    restarted: Variation;
  };
}
