import { SingleReview } from "api/models/reviews/singleReviews";
import { ReviewDomElement } from "api/models/reviews/reviewDomElement";

import validators from "utils/validators";

import { DatePicker, NumberInput, TextArea, TextInput } from "components/forms";

// TODO: add jsdoca

const requireValidator = (value: string | number | null) => {
  return validators.required(value);
};

const numberValidator = (value: string | number | null) => {
  return (
    validators.required(value) ||
    validators.minValue(1)(value) ||
    validators.maxValue(5)(value)
  );
};

export const createReviewDomElements = (
  reviewsNumber: number,
): ReviewDomElement[] => {
  const elements: ReviewDomElement[] = [];

  for (let i = 0; i < reviewsNumber; i++) {
    elements.push({
      label: `Review no. ${i + 1}`,
      fields: [
        {
          label: "Title",
          fieldName: `title`,
          placeholder: "Enter review title",
          width: 1,
          component: TextInput,
          validators: requireValidator,
        },
        {
          label: "User Name",
          fieldName: `username`,
          placeholder: "Enter author name ",
          width: 1 / 3,
          component: TextInput,
          validators: requireValidator,
        },
        {
          label: "Date",
          fieldName: `date`,
          placeholder: "Enter review date",
          width: 1 / 3,
          component: DatePicker,
          validators: requireValidator,
        },
        {
          label: "Rating",
          fieldName: `rating`,
          placeholder: "Enter review rating",
          width: 1 / 3,
          component: NumberInput,
          validators: numberValidator,
          maxLength: 1,
        },
        {
          label: "Description",
          fieldName: `description`,
          placeholder: "Enter review description",
          width: 1,
          component: TextArea,
          validators: requireValidator,
        },
      ],
    });
  }

  return elements;
};

export const generateInitialReviewsData = (numberOfReviews: number) => {
  const singleReviewTemplate: SingleReview = {
    date: "",
    description: "",
    rating: 1,
    username: "",
  };

  return new Array(numberOfReviews).fill(singleReviewTemplate);
};
