import React from "react";
import styled from "styled-components";
import { Field, Form } from "react-final-form";
import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import { Stack, Button, Box } from "@mui/material";

import { AuthSection, Info } from "components/auth";
import validators from "utils/validators";
import formatters from "utils/formatters";

export interface FormData {
  email: string;
  password: string;
  otpToken: string;
}

interface Props {
  onSubmit: (data: FormData) => void;
  initialValues: any;
}

function OtpPage({ onSubmit, initialValues }: Props) {
  const { t } = useTranslation("auth");
  const { t: tg } = useTranslation("general");
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, values }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <AuthSection
            title={
              <Info
                title={t<string>("otpTitle")}
                description={t<string>("enterCode")}
              />
            }
          >
            <Field
              name="otpToken"
              validate={validators.required}
              formatOnBlur
              format={formatters.trim}
            >
              {({ input }) => {
                return (
                  <OtpWrapper>
                    <OtpInput
                      {...input}
                      shouldAutoFocus
                      numInputs={6}
                      onChange={(value: string) => {
                        input.onChange(value);
                        if (value.length === 6) {
                          handleSubmit();
                        }
                      }}
                      isInputNum
                      containerStyle={{
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    />
                  </OtpWrapper>
                );
              }}
            </Field>

            <Box display="flex" flexDirection="column" alignItems="center">
              <Stack
                sx={{ width: "100%" }}
                spacing={2}
                pt={2}
                pr={2}
                direction="row"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  sx={{ minWidth: "130px" }}
                  type="submit"
                  disabled={submitting || values?.otpToken?.length !== 6}
                >
                  {tg<string>("signIn")}
                </Button>
              </Stack>
            </Box>
          </AuthSection>
        </form>
      )}
    </Form>
  );
}

const OtpWrapper = styled.div`
  input {
    width: 3rem !important;
    height: 3rem;
    margin: 0.5rem 1rem;
    font-size: 2rem;
    border-radius: 2px;
    border: 1px solid #23233a;
    background-color: rgba(7, 7, 30, 0.4);
    color: #ffffff;
    padding: 0;
  }

  input.error {
    box-shadow: rgb(255 79 90 / 50%) 0px 0px 15px 0px;
    border: 1px solid rgb(255, 79, 90);
  }
`;

export default OtpPage;
