import React, { useEffect } from "react";
import { Field } from "react-final-form";
import { Flex, Box } from "@rebass/grid";

import { Platform } from "api/models";

import validators from "utils/validators";
import formatters from "utils/formatters";
import { displayPlatformName } from "utils/enums";
import { userpilotReloadUrl } from "utils/userpilot";

import FormSection from "components/forms/FormSection";
import {
  FormLabel,
  ButtonToggle,
  TextInput,
  FormHeader,
} from "components/forms";
import styled from "styled-components";

export interface StepData {
  name: string;
  platform: Platform.ios | Platform.android;
  redirectUrl: string;
}

interface StepErrors {
  name?: string;
}

interface Props {
  readonly: string[];
  data: StepData;
  errors: StepErrors;
  onChange: (values: object) => void;
  isPlatformSelectActive?: boolean;
}

function AppDetailsStep({
  data,
  readonly = [],
  errors,
  onChange,
  isPlatformSelectActive = false,
}: Props) {
  useEffect(() => {
    userpilotReloadUrl(`${window.location.href}/app-details`);
  }, []);

  const platforms = Object.keys(Platform).map((key: any) => ({
    // @ts-ignore
    value: Platform[key],
    // @ts-ignore
    name: displayPlatformName(Platform[key] as Platform),
  }));

  const removeEmojiRegExp = /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]|[\uFE0F\u20E3]/g;

  return (
    <>
      <FormHeader>Enter App Details</FormHeader>
      <FormSection>
        <Flex flexWrap="wrap">
          <Box width={6 / 9}>
            <Field name="platform">
              {({ input }) => {
                return (
                  <div>
                    <FormLabel name="platform" spacing={false}>
                      Select Version
                    </FormLabel>
                    <ButtonToggle
                      name="platform"
                      value={data.platform}
                      items={platforms}
                      disabled={
                        readonly.indexOf("platform") !== -1 ||
                        isPlatformSelectActive
                      }
                      onChange={(value: any) => {
                        onChange({
                          platform: value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>

          <Box width={6 / 9}>
            <Field
              name="name"
              validate={validators.required}
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <StyledFormLabel name="name">
                      App Name
                      <AppNameTitleHint>
                        (For internal usage only)
                      </AppNameTitleHint>
                    </StyledFormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Name of the App"
                      maxLength={30}
                      error={(meta.touched && meta.error) || errors.name}
                      {...input}
                      disabled={readonly.indexOf("name") !== -1}
                      value={data.name}
                      onChange={(event: any) => {
                        const removeEmojis = event.target.value.replace(
                          removeEmojiRegExp,
                          "",
                        );
                        onChange({
                          name: removeEmojis,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>

          <Box width={6 / 9}>
            <Field
              name="redirectUrl"
              validate={(value: any) =>
                validators.required(value) || validators.url(value)
              }
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="redirectUrl">
                      Destination Address
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="E.g. https://app-store-install-page.com"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.redirectUrl}
                      onChange={(event: any) => {
                        onChange({
                          redirectUrl: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
        </Flex>
      </FormSection>
    </>
  );
}

export default AppDetailsStep;

// -- STYLED
const StyledFormLabel = styled(FormLabel)`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

const AppNameTitleHint = styled.span`
  font-size: 12px;

  margin-left: 10px;

  line-height: normal;
`;
