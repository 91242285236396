import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { ZendeskAPI } from "react-zendesk";

import { useStores } from "contexts/storeContext";
import { logoutMutation } from "v2/api/mutations/user/logout";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

function LogoutContainer() {
  const { userStore } = useStores();
  const [logout] = useMutation(logoutMutation);
  const history = useHistory();

  const handleError = useRequestErrorHandler();

  const handleLogout = async () => {
    await handleError(logout()).then((res) => {
      if (res?.data.logout) {
        ZendeskAPI("webWidget", "logout");
        userStore.clearStorage();
        history.replace("/");
      }
    });
  };
  useEffect(() => {
    handleLogout();
  });
  return null;
}

export default LogoutContainer;
