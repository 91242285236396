import React from "react";

import { getTitleLength } from "utils/enums";

import { TextAssetComponentsProps } from "../constants/textAssetComponentsProps";

import { ApplicationAssetsString } from "components/application";
import { Platform } from "api";

// -- COMPONENTS
class TextAssetStepTitles extends React.PureComponent<
  TextAssetComponentsProps
> {
  public render() {
    const {
      title = "Add App Titles",
      data,
      disableControl,
      onChange,
      maxUploadAssets,
      platform,
    } = this.props;

    return (
      <>
        <ApplicationAssetsString
          title={title}
          inputTitle="Enter App Title"
          listTitle="Added Titles"
          placeholder="Enter Title"
          isInputArea={false}
          maxLength={getTitleLength(platform)}
          data={data}
          onChange={onChange}
          disableControl={disableControl}
          maxUploadAssets={maxUploadAssets}
          validate={platform === Platform.android}
        />
      </>
    );
  }
}

export default TextAssetStepTitles;
