import * as React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

import { generateGradientString } from "./constants/generateGradientString";

// -- TYPES
type IframeGradientProps = {
  height: number;
};

type OverlayHeightProps = Pick<IframeGradientProps, "height">;

// -- COMPONENT
export function IframeGradient({ height }: IframeGradientProps): JSX.Element {
  const portal = document.querySelector("#heat-map-iframe-wrapper");

  return ReactDOM.createPortal(
    <Overlay height={height}>
      <OverlayGradient />
    </Overlay>,
    portal || document.body,
  );
}

// -- STYLES
const Overlay = styled.div.attrs<OverlayHeightProps>(({ height }) => ({
  style: {
    height: `${height}%`,
  },
}))<OverlayHeightProps>`
  position: absolute;
  top: 0;

  z-index: 9;

  margin-top: 15px;

  height: 5%;
  width: calc(100% + 2px);

  overflow: hidden;
`;

const OverlayGradient = styled.div`
  height: 500px;
  width: 100%;

  background: ${generateGradientString()};
`;
