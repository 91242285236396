import React from "react";

import { StripeProvider } from "components/billing/StripeProvider";
import { NarrowTemplate } from "templates";
import { EditPaymentMethodForm } from "./EditPaymentMethodForm";

// -- COMPONENT
function EditPaymentMethodPage(): JSX.Element {
  return (
    <StripeProvider>
      <NarrowTemplate>
        <EditPaymentMethodForm />
      </NarrowTemplate>
    </StripeProvider>
  );
}

export default EditPaymentMethodPage;
