import React, { useContext, useEffect, useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { Box, Stack, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MUIFormSectionWFields } from "@upptic/module-ui";

import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";
import { Info, AuthSection, SignInWithGoogleButton } from "components/auth";
import validators from "utils/validators";
import { ModalContext, useStores } from "contexts";
import { useLocation } from "react-router-dom";

export interface FormData {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (data: FormData) => void;
  isActivated?: boolean;
  email?: string;
}

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SignInPage({ onSubmit, isActivated = false, email = "" }: Props) {
  const { t } = useTranslation("auth");
  const { t: tg } = useTranslation("general");
  const setModal = useContext(ModalContext);
  const queryParams = useQueryParams();
  const defaultState: FormData = {
    email,
    password: "",
  };
  const [formData, setFormData] = useState(defaultState);

  const initialValues = {
    ...formData,
  };

  useEffect(() => {
    if (!queryParams.get("error")) return;
    setModal({
      type: "set",
      props: {
        content: t(`oauthErrors.${queryParams.get("error")}`),
      },
    });
  }, []);

  const fields = [
    {
      name: "email",
      label: t<string>("email"),
      validate: validators.required,
      xs: 12,
    },
    {
      name: "password",
      label: t<string>("password"),
      type: "password",
      validate: validators.required,
      xs: 12,
    },
  ];

  const hints = [
    /* {
      title: "Don’t have an account?",
      link: "Sign up",
      route: nonAuthRoutes.AUTH_SIGN_UP.getUrlWithParams(),
    }, */
    {
      title: t<string>("hints.passwordTitle"),
      link: t<string>("hints.passwordLink"),
      route: nonAuthRoutes.AUTH_FORGOT_PASSWORD.getUrlWithParams(),
      state: { userEmail: formData.email },
    },
  ];

  return (
    <Form onSubmit={() => onSubmit(formData)} initialValues={initialValues}>
      {({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormSpy
            subscription={{ values: true }}
            onChange={(data: any) => setFormData(data.values)}
          />
          <AuthSection
            title={
              isActivated ? (
                <Info
                  title={t<string>("invitationConfirmed")}
                  description={t<string>("activatedConfirm")}
                />
              ) : (
                t<string>("signInStart")
              )
            }
            hints={hints}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <SignInWithGoogleButton />
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                or
              </span>
              <MUIFormSectionWFields disablePadding fields={fields} />
              <Stack
                sx={{ width: "100%" }}
                spacing={2}
                pt={2}
                direction="row"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  sx={{ minWidth: "130px" }}
                  type="submit"
                  disabled={submitting}
                >
                  {tg<string>("submit")}
                </Button>
              </Stack>
            </Box>
          </AuthSection>
        </form>
      )}
    </Form>
  );
}

export default SignInPage;
