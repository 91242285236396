import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/client";

import {
  ApplicationListing,
  Platform,
  ScreensType,
  ClientApplication,
} from "api/models";
import { CHECK_APPLICATION_NAME } from "api/queries";

import { TextAssetStepData } from "components/application/forms/TextAssetStep/constants/textAssetStepData";
import { Step } from "components/wizard/WizardSteps";
import { FeatureGraphicData } from "components/application/forms/FeatureGraphic/constants/featureGraphicData";

import { truncate } from "utils/strings";
import validators from "utils/validators";

import AppDetailsStep, {
  StepData as Step1Data,
} from "components/application/forms/AppDetailsStep";
import IconStep, {
  StepData as Step2Data,
} from "components/application/forms/IconStep";
import PreviewScreen, {
  StepData as Step3Data,
  SetPreviewData,
  SinglePreviewData,
} from "components/application/forms/PreviewStep";
import { TextAssetStep } from "components/application/forms/TextAssetStep/TextAssetStep";
import StaticInfoStep, {
  StepData as Step5Data,
} from "components/application/forms/StaticInfoStep";
import PromoArtStep, {
  StepData as Step6Data,
} from "components/application/forms/PromoArtStep";
import FeatureGraphic from "components/application/forms/FeatureGraphic/FeatureGraphic";

import { NarrowTemplate } from "templates";
import { Wizard } from "components/wizard";
import { Title } from "components";
import { Button } from "components/forms";
import { ApplicationUserContext } from "../../../contexts";
import config from "../../../config";

export interface FormData {
  step1: Step1Data;
  step2: Step2Data;
  step3: Step6Data | FeatureGraphicData;
  step4: Step3Data;
  step5: TextAssetStepData;
  step6: Step5Data;
}

export const defaultFormDataSetsPreview: SetPreviewData = {
  screensType: ScreensType.set,
  sets: [
    {
      assets: [],
      isControl: true,
      name: "",
      tempId: 1, // temporary id for frontend
    },
  ],
};

export const defaultFormDataIndividualPreview: SinglePreviewData = {
  screensType: ScreensType.single,
  control1: null,
  control2: null,
  control3: null,
  assets1: [],
  assets2: [],
  assets3: [],
  assets4: [],
};

const getDefaultFormData = (
  application?: ApplicationListing,
  applicationGroup?: ClientApplication,
) => {
  const defaultFormData: FormData = {
    step1: {
      name: "",
      platform: Platform.ios,
      redirectUrl: "",
    },
    step2: {
      control: null,
      assets: [],
    },
    step3: {
      control: null,
      assets: [],
    },
    step4: {
      screensType: ScreensType.set,
      sets: [
        {
          assets: [],
          isControl: true,
          name: "",
          tempId: 1, // temporary id for frontend
        },
      ],
    },
    step5: {
      title: "",
      description: "",
      subtitle: "",
      controlTitle: "",
      controlDescription: "",
      controlSubtitle: "",
      assetsTitle: [],
      assetsDescription: [],
      assetsSubtitle: [],
    },
    step6: {
      publisher: "",
      rating: "",
      downloads: "",
      whatsNew: "",
      numberOfRatings: "",
      ageLimit: "",
      appVersion: "",
      releaseDate: "",
      releaseNote: "",
      category: "",
      ranking: "",
      tags: [],
      size: undefined,
      gameplayRating: "",
      controlRating: "",
      graphicRating: "",
      copyright: "",
      reviews: [],
      mdl: config.defaultMdl,
    },
  };

  if (application && applicationGroup) {
    defaultFormData.step1.name = applicationGroup.name;
    defaultFormData.step1.platform =
      application.platform === Platform.ios ? Platform.android : Platform.ios;
  }

  return defaultFormData;
};

interface Props {
  application?: ApplicationListing;
  applicationGroup?: ClientApplication;
  onCreate: (data: FormData) => void;
  onDiscard: () => void;
  isLoading: boolean;
}

function ApplicationCreatePage({
  application,
  applicationGroup,
  onCreate,
  onDiscard,
  isLoading,
}: Props) {
  const { refetch } = useQuery(CHECK_APPLICATION_NAME, {
    skip: true,
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const [formData, setFormData] = useState(
    getDefaultFormData(application, applicationGroup),
  );
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const applicationUserCtx = useContext(ApplicationUserContext);
  const updateFormData = (step: string) => (
    values: any,
    isNewObject?: boolean,
  ) => {
    // @ts-ignore
    const stepValue = isNewObject ? values : { ...formData[step], ...values };
    // @ts-ignore
    setFormData({ ...formData, [step]: stepValue });
  };

  const [isSelectPlatformActive, setIsPlatformSelectActive] = useState(false);

  const steps: Step[] = [
    {
      name: "App Details",
      component: (
        <AppDetailsStep
          key="step-1"
          data={formData.step1}
          readonly={application ? ["name", "platform"] : []}
          errors={{
            name: nameError,
          }}
          onChange={updateFormData("step1")}
          isPlatformSelectActive={isSelectPlatformActive}
        />
      ),
    },
    {
      name: "Icon",
      component: (
        <IconStep
          key="step-2"
          data={formData.step2}
          onChange={updateFormData("step2")}
        />
      ),
    },
    formData.step1.platform === Platform.ios
      ? {
          name: "Promo Art",
          component: (
            <PromoArtStep
              key="step-3"
              data={formData.step3}
              onChange={updateFormData("step3")}
              required={false}
            />
          ),
        }
      : {
          name: "Feature Graphic",
          component: (
            <FeatureGraphic
              key="feature-graphic"
              data={formData.step3}
              onChange={updateFormData("step3")}
              required={false}
            />
          ),
        },
    {
      name: "Screens",
      component: (
        <PreviewScreen
          key="step-4"
          data={formData.step4}
          onChange={updateFormData("step4")}
          platform={formData.step1.platform}
        />
      ),
    },
    {
      name: "Description",
      component: (
        <TextAssetStep
          key="step-5"
          data={formData.step5}
          onChange={updateFormData("step5")}
          platform={formData.step1.platform}
        />
      ),
    },
    {
      name: "Store Details",
      component: (
        <StaticInfoStep
          key="step-6"
          data={formData.step6}
          onChange={updateFormData("step6")}
          applicationPlatform={formData.step1.platform}
        />
      ),
    },
  ];

  // @ts-ignore
  const initialData = formData[`step${currentStep + 1}`];

  return (
    <NarrowTemplate>
      <Title
        button={
          <Button outline={true} onClick={onDiscard}>
            Discard
          </Button>
        }
      >
        {truncate(formData.step1.name, 35).trim() || "New App"}
      </Title>

      <Wizard
        step={currentStep}
        maxStep={maxStep}
        steps={steps}
        initValues={initialData}
        onStepChange={async (step) => {
          // Put any async validations in here
          if (step === 1) {
            setIsPlatformSelectActive(true);
          }
          const nameValidation = await validators.applicationName(
            formData.step1.name,
            formData.step1.platform,
            applicationUserCtx.userId,
            refetch,
          );
          setNameError(nameValidation);
          if (nameValidation) {
            return false;
          }

          const titleValidation = validators.androidTexts(
            formData.step5.assetsTitle,
            formData.step1.platform,
          );
          if (titleValidation) {
            setMaxStep(4);
            step > 4 ? setCurrentStep(4) : setCurrentStep(step);
            return false;
          }

          if (step > maxStep) {
            setMaxStep(step);
          }

          setCurrentStep(step);
          window.scrollTo(0, 0);
        }}
        onSubmit={() => onCreate(formData)}
        isLoading={isLoading}
      />
    </NarrowTemplate>
  );
}

export default ApplicationCreatePage;
