import React from "react";
import { styled } from "@mui/system";
import Spinner from "./Spinner";

// -- COMPONENT
const FullPageLoader: React.FC = () => (
  <Overlay>
    <Spinner style={{ marginTop: 0 }} />
  </Overlay>
);

// -- STYLES
const Overlay = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: ${(props: any) => props.theme.zIndexes.fullPageLoader};
  background-color: rgba(32, 32, 32, 0.5);
`;

export default FullPageLoader;
