import React, { FC, useEffect, useState } from "react";
import { styled } from "@mui/system";

import theme from "components/themes";
import FormError from "./FormError";

import d2lIntl from "d2l-intl";

interface AnyProps {
  [propName: string]: any;
}

interface NumberInputProps extends AnyProps {
  decimalSpaces?: number;
  error?: string;
  light?: boolean;
  name: string;
  onChange: (value: number | null) => void;
  value: string | number;
  inputSufix?: string;
  locale?: string;
}

const NumberInput: FC<NumberInputProps> = ({
  decimalSpaces = 0,
  error,
  light = false,
  name,
  onChange,
  value,
  inputSufix,
  locale = "en",
  ...rest
}) => {
  const [savedValue, setSavedValue] = useState<any>(value);

  useEffect(() => {
    setSavedValue(value);
  }, [value]);

  const parser = new d2lIntl.NumberParse(locale);

  const reformatOnBlur = () => {
    // This is necessary for when Value is already a Number ie. Clicking on/off of the field
    const stateToString = savedValue.toString();
    const testValue = parser.parse(stateToString);
    if (isNaN(testValue) || testValue === 0) {
      // parser resolves empty string to 0
      setSavedValue("");
    } else {
      onChange(testValue);
      setSavedValue(testValue);
    }
  };

  const handleChange = (event: any) => {
    const typedValue = event.target.value;
    const strippedValue = typedValue.replace(/[^0-9.,]/g, "");
    onChange(strippedValue);
    setSavedValue(strippedValue);
  };

  return (
    <Wrapper onBlur={reformatOnBlur}>
      <InputWrapper>
        <StyledInput
          {...rest}
          light={light}
          id={name}
          valid={!error}
          onChange={handleChange}
          value={savedValue}
          inputSufix={inputSufix}
        />
        {inputSufix && <Suffix>{inputSufix}</Suffix>}
      </InputWrapper>
      {error ? <FormError>{error}</FormError> : null}
    </Wrapper>
  );
};

// eslint-disable-next-line
const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Input = styled("input")<{
  light: boolean;
  valid: boolean;
  disabled?: boolean;
}>`
  outline: none;
  width: 100%;
  padding: 10px 15px;
  height: 38px;
  border-radius: 2px;
  border: 1px solid ${theme.colors.background};
  background-color: ${(props) =>
    props.light ? theme.colors.inputsLight : theme.colors.inputs};
  color: ${theme.colors.white};
  font-size: 16px;
  opacity: ${(props: any) => (props.disabled ? 0.5 : 0.8)};

  ${(props) =>
    !props.valid &&
    `
    box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
    border: 1px solid ${theme.colors.notificationRed};
  `};

  ::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;

const StyledInput = styled(Input as any)<Pick<NumberInputProps, "inputSufix">>`
  ${(props) =>
    props.inputSufix &&
    `
      padding-right: 30px;
      box-shadow: none;
  `};
`;

const Suffix = styled("div")`
  position: absolute;
  right: 15px;
  top: 2px;
  bottom: 2px;

  display: flex;

  background-color: ${(props: any) => props.theme.colors.inputs};

  opacity: 0.8;

  margin-left: -1px;

  align-items: center;
  color: ${(props: any) => props.theme.colors.white};
`;

const InputWrapper = styled("div")`
  display: flex;
  width: 100%;

  position: relative;
`;

export default NumberInput;
