// -- CORE
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
// -- CONTEXTS
import { useStores } from "contexts/storeContext";
// -- ROUTES
import { allRoutes } from "v2/routes/allRoutes";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";
import { UserPermissions } from "v2/api/types";

// -- TYPE
type RouteController = {
  children: React.ReactElement;
};

// -- COMPONENT
export const SideMenuController = observer(
  ({ children }: RouteController): JSX.Element => {
    const location = useLocation();
    const { layoutStore } = useStores();
    const history = useHistory();
    const platformStore = React.useContext<PlatformStore>(PlatformContext);

    const path = location?.pathname;

    React.useEffect(() => {
      const route = allRoutes.find(
        (singleRoute) => singleRoute.path === platformStore?.currentRoute?.path,
      );

      if (route) {
        const hasUserPermissions = route.hasUserAccess(
          platformStore.currentUserFrontendPermissions as UserPermissions[],
        );

        if (
          hasUserPermissions &&
          layoutStore.sideMenuMode !== route.routeMenuMode
        ) {
          layoutStore.setSideMenuMode(route.routeMenuMode);
        }
      }
    }, [
      path,
      layoutStore,
      history,
      platformStore.currentUserFrontendPermissions,
    ]);

    return children;
  },
);
