import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const createFeatureGraphics = new ApiMutation({
  definition: gql`
    mutation createFeatureGraphics(
      $applicationId: ID!
      $data: [ControlObjectType]
      $files: [Upload]
    ) {
      createFeatureGraphics(
        applicationId: $applicationId
        data: $data
        files: $files
      ) {
        isCreated
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASOAsset],
});

export interface CreateFeatureGraphicsResponse {
  createFeatureGraphics: {
    isCreated: boolean;
  };
}

export const deleteFeatureGraphic = new ApiMutation({
  definition: gql`
    mutation deleteFeatureGraphic($id: ID!) {
      deleteFeatureGraphic(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOAsset],
});

export interface DeleteFeatureGraphicResponse {
  deleteFeatureGraphic: {
    isDeleted: boolean;
  };
}

export const deactivateFeatureGraphic = new ApiMutation({
  definition: gql`
    mutation deactivateFeatureGraphic($id: ID!) {
      deactivateFeatureGraphic(id: $id) {
        isDeactivated
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOTest],
});

export interface DeactivateFeatureGraphicResponse {
  deactivateFeatureGraphic: {
    isDeactivated: boolean;
  };
}
