import * as React from "react";

import DatePicker from "components/forms/DatePicker";
import styled from "styled-components";
import { CheckboxInput } from "components/forms";

// -- TYPES
type ExportAssetsModalComponentProps = {
  exportText: string;
  isShipDateMarked: boolean;
  shipDate: string;
  onChange: (isChecked: boolean, date: string | null) => void;
};
// -- COMPONENT
export function ExportAssetsModalComponent({
  exportText,
  isShipDateMarked,
  shipDate,
  onChange,
}: ExportAssetsModalComponentProps): JSX.Element {
  const checkboxChange = (event: any) => {
    const newValue = event.target.checked;
    onChange(newValue, shipDate);
  };

  const handleShipDateChange = (date: string | null) => {
    onChange(isShipDateMarked, date);
  };

  return (
    <>
      {exportText}
      <ShipDateWrapper>
        <CheckboxInput
          label="Mark app store ship date"
          onChange={checkboxChange}
          value={isShipDateMarked}
        />
        <DatePicker
          disabled={!isShipDateMarked}
          initialDate={shipDate}
          onChange={handleShipDateChange}
          id="singleDatePicker"
          allowClear={false}
        />
      </ShipDateWrapper>
    </>
  );
}

const ShipDateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;

  label {
    margin: 0 20px 0 0;
  }
`;
