import React from "react";
import styled from "styled-components";

import { AuthSection } from "components/auth";

function SignUpConfirmPage() {
  return (
    <AuthSection title="Confirm your email address" hints={[]}>
      <Text>
        We have sent a confirmation message to this email address. Please check
        your inbox.
      </Text>
    </AuthSection>
  );
}

const Text = styled.p`
  margin: 60px 0;
`;

export default SignUpConfirmPage;
