import queryString from "query-string";

import validator from "validator";
import validators from "./validators";

const validateUrl = (value: string) =>
  validator.isURL(value, {
    ...validators.urlOptions,
    host_whitelist: [
      "youtube.com",
      "www.youtube.com",
      "youtu.be",
      "www.youtu.be",
    ],
  }) && getVideoId(value)
    ? undefined
    : "Invalid YouTube URL";

const getVideoId = (url: string): string => {
  let videoId = queryString.parseUrl(url).query.v as string;
  if (!videoId) {
    const re = /youtu.be\/([a-zA-Z_0-9.-]+)/;
    const match = url.match(re);
    videoId = match ? match[1] : "";
  }

  return videoId;
};

const getThumbnail = (videoId: string): string => {
  return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
};

export default { getVideoId, getThumbnail, validateUrl };
