const config = {
  env: process.env.NODE_ENV || "development",
  isDev: process.env.NODE_ENV !== "production",
  basename: process.env.PUBLIC_PATH,
  isBrowser: typeof window !== "undefined",
  apiUrl:
    process.env.REACT_APP_API_URL || "https://platform.dev.upptic.com/graphql",
  apiAuthHeader: process.env.API_AUTH_HEADER,
  maxCustomRoutes: 100,
  defaultMdl: Number(process.env.REACT_APP_DEFAULT_MDL) || 0.08,
  // assets count
  minApplicationAssets: 1,
  maxApplicationAssets: 8,
  maxAssetsCountForSets: 11,
  minAssetsCountForSets: 3,
  maxVideoUploadsIos: 3,
  maxCountOfSets: 8,
  // max file size in bytes
  maxSingleFilesize: 524288000,
  // STRIPE
  stripeKey: process.env.REACT_APP_STRIPE_PUBLIC_API_KEY,
  // zendesk
  zendeskSupportCenter: process.env.REACT_APP_ZENDESK_SUPPORT_LINK,
  pollInterval: 120000, // 2 minutes

  enableAnalyticsTracking: process.env.REACT_APP_ANALYTICS_TRACKING === "true",

  hubspotEnabled: process.env.REACT_APP_HUBSPOT_ENABLED === "true",
  hubspotPortalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
  hubspotFormId: process.env.REACT_APP_HUBSPOT_FORM_ID,
  hubspotFormUrl: process.env.REACT_APP_HUBSPOT_FORM_POST_URL,

  defaultNumberOfReviews: 2,

  lastLoginClientCookieName: "client",

  recaptchaSiteKey:
    process.env.REACT_APP_RECAPTCAH_SITE_KEY ||
    "6LdBQu4iAAAAAMJoigXq3wlN42GEA-LYD-dFqy4J",
  featureRequestLink: "https://requests.platform.upptic.com/",
};

export default config;
