import React from "react";

import { StripeProvider } from "components/billing/StripeProvider";
import { NarrowTemplate } from "templates";
import { FirstBillingPageContent } from "./FirstBillingContent";

// -- COMPONENT
function FirstBillingPage(): JSX.Element {
  return (
    <>
      <StripeProvider>
        <NarrowTemplate>
          <FirstBillingPageContent />
        </NarrowTemplate>
      </StripeProvider>
    </>
  );
}

export default FirstBillingPage;
