import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";

import { Step, Icon, Icons } from "components";

interface Props {
  step: number;
  title: string;
  current?: boolean;
  finished?: boolean;
  inProgress?: boolean;
  width?: number;
  onClick?: () => void;
}

function WizardStep({
  step,
  title,
  current = false,
  finished = false,
  inProgress = false,
  width,
  onClick,
}: Props) {
  const disabled = !current && !finished && !inProgress;

  return (
    <Flex
      flexDirection="column"
      width={width ? "100%" : width}
      onClick={onClick}
    >
      <Box mx={"auto"} my={3}>
        <Step glowing={current} bordered={inProgress} muted={disabled}>
          {finished && !current ? <Tick icon={Icons.faCheck} /> : step}
        </Step>
      </Box>
      <Box mx={"auto"}>
        <Subtitle>Step {step}</Subtitle>
        <Title opacity={disabled ? 0.6 : 1}>{title}</Title>
      </Box>
    </Flex>
  );
}

interface TitleProps {
  opacity: number;
}

const Tick = styled(Icon)`
  width: 0.6em !important;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  opacity: ${(props: TitleProps) => props.opacity};
  text-align: center;
  user-select: none;
`;

const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 600;
  opacity: 0.4;
  margin: auto 2px;
  color: #fff;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
`;

export default WizardStep;
