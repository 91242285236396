// -- CORE
import * as React from "react";
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  generatePath,
} from "react-router-dom";
import { observer } from "mobx-react";
// -- ROUTES
import { getRoutes } from "v2/routes/getRoutes";
// -- STORES
import { useStores } from "contexts/storeContext";
// -- TEMPLATES
import { AuthTemplate } from "templates";
import { DashboardTemplate } from "v2/templates/DashboardTemplate";
// -- COMPONENTS
import { Spinner } from "components";
import { SideMenuController } from "./SideMenuController";
import { Content } from "../templates/Content";
import { PlatformContext, CurrentRoute } from "@upptic/module-directory";
import { UserPermissions } from "v2/api/types";
import { History } from "history";
import { AnalyticsService } from "services";
import { updateZendeskPath } from "utils/zendesk";

// -- PROPS
type RoutingProps = {
  isUserLogged?: boolean;
  asIframe: boolean;
};

// -- COMPONENT
export const Routing = observer(
  ({ isUserLogged, asIframe }: RoutingProps): JSX.Element => {
    const { userStore, layoutStore } = useStores();
    const location = useLocation();
    const platformStore = React.useContext(PlatformContext);

    const TemplateComponent =
      isUserLogged && location.pathname !== "/logout"
        ? DashboardTemplate
        : AuthTemplate;

    const doSwitchClientIfNecessary = (
      history: History,
      currentRoute: CurrentRoute,
    ) => {
      const currentParams = { ...currentRoute.params };
      if (!currentParams.clientCode || !platformStore.currentClient?.code) {
        return;
      }
      if (
        currentParams.clientCode === "CURRENT" ||
        currentParams.applicationCode === "CURRENT"
      ) {
        if (currentParams.clientCode === "CURRENT")
          currentParams.clientCode = platformStore.currentClient?.code;
        if (currentParams.applicationCode === "CURRENT")
          currentParams.applicationCode =
            platformStore.selectedApplication?.code ||
            currentParams.applicationCode;
        history.replace(generatePath(currentRoute.path, currentParams));
        return true;
      }

      if (currentParams.clientCode === platformStore.currentClient.code) {
        return;
      }
      if (platformStore.processingSwitchClient) return;
      const url = `/switchClient?orgUrl=${encodeURIComponent(
        currentRoute.url,
      )}${encodeURIComponent(location.search)}&newClientCode=${
        currentParams.clientCode
      }`;
      history.replace(url);
      return true;
    };

    const templateContent = React.useMemo(() => {
      const selectedRoutesGroup = getRoutes(
        !!isUserLogged,
        userStore.validPaymentMethod,
        userStore.isOverdue,
      );
      const routes = selectedRoutesGroup.map((singleRoute, index) => {
        const hasUserPermissions = singleRoute.hasUserAccess(
          platformStore.currentUserFrontendPermissions as UserPermissions[],
        );
        const { component, render, exact, path } = singleRoute.reactRouterProps;
        const layout = singleRoute.layout;
        const RouteComponent: any = component;

        const key = (path as string) || `route-${index}`;

        if (!hasUserPermissions) {
          return (
            <Route
              key={key}
              exact={exact}
              path={path}
              render={({ location: lastLocation, history, match }) => {
                if (doSwitchClientIfNecessary(history, match as CurrentRoute)) {
                  return;
                }
                return (
                  <Redirect
                    to={{
                      pathname: "/access-denied",
                      state: { from: lastLocation },
                    }}
                  />
                );
              }}
            />
          );
        }

        return (
          <Route
            key={key}
            exact={exact}
            path={path}
            render={
              render && ((props) => render({ ...props, platformStore } as any))
            }
            children={
              RouteComponent &&
              ((routeProps) => {
                if (
                  doSwitchClientIfNecessary(
                    routeProps.history,
                    routeProps.match as CurrentRoute,
                  )
                )
                  return;
                const newUrl = platformStore.setParamsBasedOnUrl(
                  routeProps.match as CurrentRoute,
                  routeProps.location,
                );
                if (newUrl) routeProps.history.replace(newUrl);

                if (routeProps.match?.path) {
                  updateZendeskPath(routeProps.match?.path);
                  AnalyticsService.trackVisit(
                    routeProps.match?.path,
                    routeProps.match?.params || {},
                    { routeProps, singleRoute },
                  );
                }

                return (
                  <Content layout={layout}>
                    <RouteComponent {...routeProps} />
                  </Content>
                );
              })
            }
          />
        );
      });

      return (
        // todo: add MyErrorBoundary
        <React.Suspense fallback={<Spinner />}>
          <Switch>{routes}</Switch>
        </React.Suspense>
      );
    }, [
      isUserLogged,
      userStore.isOverdue,
      userStore.validPaymentMethod,
      platformStore.currentUserFrontendPermissions,
    ]);

    return (
      <SideMenuController>
        <TemplateComponent
          asIframe={asIframe}
          displaySideMenu={
            layoutStore?.sideMenuMode === "none"
              ? false
              : !userStore.isOverdue && userStore.validPaymentMethod
          }
          showFocusMode={layoutStore.sideMenuMode === "focus"}
        >
          {templateContent}
        </TemplateComponent>
      </SideMenuController>
    );
  },
);
