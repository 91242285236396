import {
  Description,
  Icon,
  Preview,
  PromoArt,
  Subtitle,
  Title,
} from "api/models";
import { UserPermissions } from "v2/api/types";

export enum SectionType {
  SINGLE_IMAGE = "single-image",
  GROUPED_IMAGE = "grouped-image",
  GROUPED_TEXTS = "grouped-texts",
}

interface MainSection {
  type: SectionType;
  editPermissions: UserPermissions[];
}

export type ImageSection = {
  title: string;
  assets: (Icon | PromoArt | Preview)[];
  url: string;
  editPermissions: UserPermissions[];
};

export type TextSection = {
  title: string;
  assets: (Title | Description | Subtitle)[];
  url: string;
  editPermissions: UserPermissions[];
};

export type SingleImagesSection = ImageSection &
  MainSection & {
    type: SectionType.SINGLE_IMAGE;
  };

export interface GroupedImagesSection {
  type: SectionType.GROUPED_IMAGE;
  title: string;
  groups: ImageSection[];
}

export interface GroupedTextsSection {
  type: SectionType.GROUPED_TEXTS;
  groups: TextSection[];
}

export type Sections =
  | GroupedTextsSection
  | GroupedImagesSection
  | SingleImagesSection;
