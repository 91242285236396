import * as React from "react";

import { TextAssetComponentsProps } from "../constants/textAssetComponentsProps";

import { ApplicationAssetsString } from "components/application";

// -- TYPES
type TextAssetStepSubtitlesProps = Omit<TextAssetComponentsProps, "platform">;

// -- COMPONENT
class TextAssetStepSubtitles extends React.PureComponent<
  TextAssetStepSubtitlesProps
> {
  public render() {
    const {
      title = "Add App Subtitles",
      data,
      disableControl,
      onChange,
      maxUploadAssets,
    } = this.props;

    return (
      <>
        <ApplicationAssetsString
          title={title}
          inputTitle="Enter App Subtitle"
          listTitle="Added Subtitles"
          placeholder="Enter Subtitle"
          isInputArea={false}
          maxLength={30}
          data={data}
          onChange={onChange}
          disableControl={disableControl}
          maxUploadAssets={maxUploadAssets}
        />
      </>
    );
  }
}

export default TextAssetStepSubtitles;
