import React, { FC } from "react";
import styled from "styled-components";
import { isFunction } from "lodash";

import { Tooltip, Icon, Icons } from "components";
import themes from "components/themes";
import { FormHeader, Toggle } from "components/forms";

import { ToggleOption } from "components/forms/Toggle";
import { ScreensType } from "api/models";

// -- TYPES
interface Props {
  title: string;
  toggleValue?: ScreensType;
  onToggleChange?: (value: ScreensType) => any;
}

// -- STATIC
const tooltipMessage =
  "Select whether you want to test screenshots individually or in Sets. Testing individually means one screenshot will be split tested at a time. Testing Sets of screenshots means all screenshots will be swapped out and split tested against entirely different sets of screenshots. This is particularly useful for testing screenshots that must be displayed together (i.e. screenshots that display a theme).";
const toggleOptions: [ToggleOption, ToggleOption] = [
  {
    label: "Sets of Screens",
    value: ScreensType.set,
  },
  {
    label: "Individual Screens",
    value: ScreensType.single,
  },
];

// -- COMPONENT
const HeaderWithTypeSelector: FC<Props> = ({
  title,
  toggleValue,
  onToggleChange,
}) => {
  const handleToggleChange = (value: string | number) => {
    if (isFunction(onToggleChange)) {
      toggleValue !== value && onToggleChange(value as ScreensType);
    }
  };

  return (
    <HeaderWrapper>
      <FormHeader>{title || "Upload App Preview Screens"}</FormHeader>
      {onToggleChange && (
        <TypeSelectorWrapper>
          <ToggleLabel>Test:</ToggleLabel>
          <Toggle
            options={toggleOptions}
            value={toggleValue}
            onChange={handleToggleChange}
          />
          <Tooltip content={tooltipMessage} placement={"bottom"}>
            <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
          </Tooltip>
        </TypeSelectorWrapper>
      )}
    </HeaderWrapper>
  );
};

// --STYLES
const HeaderWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  h3 {
    margin-bottom: 0;
  }
`;
const TypeSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;
const ToggleLabel = styled.h3`
  font-size: 14px;
  color: ${themes.colors.white};
  margin-bottom: 0;
  opacity: 0.4;
`;

export default HeaderWithTypeSelector;
