import * as React from "react";
import { styled } from "@mui/system";
import hexToRgba from "hex-to-rgba";
import { Switch } from "components/forms";
import { observer } from "mobx-react";
import { PlatformContext } from "@upptic/module-directory";

// --TYPES
type UserMenuItemProps = {
  disabled?: boolean;
  href?: string;
  inNewTab?: boolean;
  children: React.ReactNode;
  onClick?: () => any;
};

// -- COMPONENT
function DemoMenuItemComponent(): JSX.Element {
  const platformStore = React.useContext(PlatformContext);
  return (
    <MenuItem
      onClick={() =>
        platformStore.setIsDemoModeEnabled(!platformStore.isDemoModeEnabled)
      }
    >
      Demo Mode
      <Switch
        value={platformStore.isDemoModeEnabled}
        onChange={() => {}}
        handleDiameter={20}
        width={40}
        height={20}
      />
    </MenuItem>
  );
}

export const MenuItem = styled("span")<{ disabled?: boolean }>`
  display: flex;

  white-space: nowrap;

  width: auto;
  height: 60px;

  align-items: center;
  justify-content: space-between;

  padding: 20px;

  text-decoration: none;

  background-color: ${(props: any) => props.theme.colors.background};

  color: ${(props: any) => props.theme.colors.white};

  text-decoration: none;

  ${(props: any) =>
    !props.disabled &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${hexToRgba(props.theme.colors.purple, 0.5)};
      }
    `};
`;

export const DemoMenuItem = observer(DemoMenuItemComponent);
