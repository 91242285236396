import { gql } from "@apollo/client";

export const CHANGE_NOTIFICATION_EMAILS = gql`
  mutation changeNotificationEmails($enabled: Boolean) {
    changeNotificationEmails(enabled: $enabled) {
      enabled
    }
  }
`;

export const MARK_AS_READ = gql`
  mutation markAsRead($notificationId: Int!) {
    markAsRead(notificationId: $notificationId) {
      notificationId
    }
  }
`;

export const MARK_ALL_AS_READ = gql`
  mutation markAllAsRead {
    markAllAsRead {
      updated
    }
  }
`;
