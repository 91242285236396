import { DocumentNode } from "@apollo/client";

import { UserPermissions } from "v2/api/types/UserPermissions";

type ApiMutationContructorProps = {
  definition: DocumentNode;
  requiredPermissions?: UserPermissions[];
};

export class ApiMutation {
  private _definition: DocumentNode;
  private _requiredPermissions: UserPermissions[];

  constructor({ definition, requiredPermissions }: ApiMutationContructorProps) {
    this._definition = definition;
    this._requiredPermissions = requiredPermissions || [];
  }

  get definition(): DocumentNode {
    return this._definition;
  }

  get requiredPermissions(): UserPermissions[] {
    return this._requiredPermissions;
  }

  public hasUserPermissions(userPermissions: UserPermissions[]): boolean {
    if (!this._requiredPermissions || !this._requiredPermissions.length) {
      return true;
    }

    return this._requiredPermissions.every(
      (singleRequiredPermission) =>
        userPermissions.indexOf(singleRequiredPermission) >= 0,
    );
  }
}
