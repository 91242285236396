import React from "react";

import IconStep from "components/application/forms/IconStep";

interface Props {
  formData: any;
  setFormData: any;
}

function ApplicationControlIconsPage({ formData, setFormData }: Props) {
  return (
    <IconStep
      title="Upload New Control Icon"
      buttonText="Upload Icon"
      data={formData}
      disableControl={false}
      onChange={(data: any) =>
        setFormData({
          ...formData,
          ...data,
        })
      }
      maxUploadAssets={1}
    />
  );
}

export default ApplicationControlIconsPage;
