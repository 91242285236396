import * as React from "react";
import { styled } from "@mui/system";

import themes from "components/themes";

import { Title, Description, Subtitle, AssetStatus } from "api/models";

import { Icons, Icon } from "components";

// -- TYPES
type ImageAssetBox = {
  asset: Title | Description | Subtitle;
};

// -- COMPONENT
export function TextAssetBox({ asset }: ImageAssetBox): JSX.Element {
  const [isHovered, setHovered] = React.useState(false);
  const isTesting = React.useMemo(() => {
    return asset.status === AssetStatus.testing;
  }, [asset.status]);

  return (
    <Wrapper
      isControl={asset.isControl}
      isHovered={isHovered}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <TextValue>{asset.name}</TextValue>
      {(asset.isControl || isTesting) && (
        <DetailBox>
          {isTesting && <span>Testing</span>}
          {asset.isControl && <span>Control</span>}
        </DetailBox>
      )}
      {asset.previewLink && isHovered ? (
        <PreviewLink
          target="_blank"
          rel="noopener noreferrer"
          href={asset.previewLink}
        >
          <Icon icon={Icons.faEye} color={themes.colors.white} />
        </PreviewLink>
      ) : null}
    </Wrapper>
  );
}

// -- UI
const Wrapper = styled("div")<{ isControl?: boolean; isHovered: boolean }>`
  display: flex;
  justify-content: space-between;

  width: 100%;

  padding: 18px 15px;

  font-size: 16px;

  border-bottom: 1px solid #47475b;

  &:last-of-type {
    ${(props) =>
      !props.isControl &&
      `
      border-bottom-width: 0px;
    `}
  }

  ${(props: any) =>
    props.isControl &&
    `
    border: 1px solid ${props.theme.colors.purple};
  `}

  background-color: ${(props: any) =>
    props.isHovered ? `#231a42` : `#13132b`};

  position: relative;
`;

const DetailBox = styled("div")`
  margin-left: 20px;

  white-space: nowrap;

  span {
    font-size: 12px;
  }

  > *:nth-child(even) {
    position: relative;

    margin-left: 15px;

    &:before {
      position: absolute;
      top: -10%;
      left: -7px;

      content: "";

      height: 120%;

      border-left: 2px solid #47475b;
    }
  }
`;

const TextValue = styled("p")`
  margin: 0;

  width: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PreviewLink = styled("a")`
  padding-left: 20px;
  position: absolute;
  top: 2px;
  right: 8px;
  font-size: 12px;
`;
