import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const createSubtitles = new ApiMutation({
  definition: gql`
    mutation createSubtitles($applicationId: ID!, $texts: [TextInputType]) {
      createSubtitles(applicationId: $applicationId, texts: $texts) {
        isCreated
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASOAsset],
});

export interface CreateSubtitlesResponse {
  createSubtitles: {
    isCreated: boolean;
  };
}

export const deleteSubtitle = new ApiMutation({
  definition: gql`
    mutation deleteSubtitle($id: ID!) {
      deleteSubtitle(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOAsset],
});

export interface DeleteSubtitleResponse {
  deleteSubtitle: {
    isDeleted: boolean;
  };
}

export const deactivateSubtitle = new ApiMutation({
  definition: gql`
    mutation deactivateSubtitle($id: ID!) {
      deactivateSubtitle(id: $id) {
        isDeactivated
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOTest],
});

export interface DeactivateSubtitleResponse {
  deactivateSubtitle: {
    isDeactivated: boolean;
  };
}
