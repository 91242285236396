import React, { useState } from "react";
import styled from "styled-components";
import hexToRgba from "hex-to-rgba";

import { HistoricalConversionData } from "api";
import videoPlayImage from "assets/images/video-play.png";
import { Icon, Icons, Card, FloatingLabel } from "components";
import AssetActions, { Action } from "./components/AssetActions";
import themes from "components/themes";

export const BOX_SIZE = 160;
const BOX_RADIUS = 8;

export interface Props {
  label?: string | React.ReactNode;
  image?: string;
  size?: number;
  control?: boolean;
  actions?: Action[];
  onRemove?: (() => void) | null;
  onDeactivate?: (() => void) | null;
  onControl?: (() => void) | null;
  onEdit?: (() => void) | null;
  onSetTop?: (() => void) | null;
  isVideo?: boolean;
  isVideoPreview?: boolean;
  isStack?: boolean;
  previewUrl?: string;
  videoUrl?: string;
  historicalData?: HistoricalConversionData[];
}

function UploadImage({
  image = "",
  label = "",
  size = BOX_SIZE,
  control = false,
  actions = [],
  onRemove,
  onDeactivate,
  onControl,
  onEdit,
  onSetTop,
  isVideo,
  isVideoPreview,
  isStack = false,
  previewUrl,
  videoUrl,
  historicalData,
}: Props) {
  const [isHovered, setHovered] = useState(false);

  const videoPreview =
    image.indexOf("youtube.com") >= 0 ||
    image.indexOf("youtu.be") >= 0 ||
    isVideo ||
    isVideoPreview;

  const imagePreview =
    image.indexOf("youtube.com") >= 0 ||
    image.indexOf("youtu.be") >= 0 ||
    !image.startsWith("blob:");

  return (
    <Wrapper size={size}>
      <ImageWrapper
        size={size}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        isStack={isStack}
      >
        {image ? (
          <>
            {isVideo && !imagePreview ? (
              <Video
                src={image}
                control={control}
                size={size}
                draggable={false}
              />
            ) : (
              <Image
                src={image}
                alt=""
                control={control}
                size={size}
                draggable={false}
              />
            )}
            {videoPreview && <PlayImage src={videoPlayImage} alt="" />}
            <AssetActions
              isHovered={isHovered}
              control={control}
              actions={actions}
              onControl={onControl}
              onEdit={onEdit}
              onSetTop={onSetTop}
              onRemove={onRemove}
              onDeactivate={onDeactivate}
              variationPreview={previewUrl}
              videoUrl={videoUrl}
              historicalData={historicalData}
            />
            {control && <FloatingLabel>Current Control Creative</FloatingLabel>}
          </>
        ) : (
          <>
            <Card center>
              <PlaceholderIcon icon={Icons.faImage} size="4x" />
            </Card>
            <AssetActions
              isHovered={isHovered}
              onEdit={onEdit}
              onSetTop={onSetTop}
            />
          </>
        )}
      </ImageWrapper>

      {!!label && <Text>{label}</Text>}
    </Wrapper>
  );
}

const Wrapper = styled.div<{ size: number }>`
  width: ${(props: any) => `${props.size}px`};
  margin: 0 auto;

  position: relative;
`;
const ImageWrapper = styled.div<{ size: number; isStack: boolean }>`
  position: relative;
  width: ${(props: any) => `${props.size}px`};
  height: ${(props: any) => `${props.size}px`};
  z-index: 1;

  ${(props) =>
    props.isStack &&
    `
    left: -7px;
    top: 7px;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 8px;
      border: 2px solid #47475b;
      top: -7px;
      left: 7px;
      z-index: -1;
      background-color: ${themes.colors.backgroundDark};
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 8px;
      border: 2px solid #47475b;
      top: -14px;
      left: 14px;
      z-index: -2;
      background-color: ${themes.colors.backgroundDark};
    }
  `}
`;

const Image = styled.img<{ size: number; control: boolean }>`
  width: ${(props: any) => `${props.size}px`};
  height: ${(props: any) => `${props.size}px`};
  object-fit: contain;
  border-radius: ${BOX_RADIUS}px;
  background-color: ${themes.colors.backgroundLight};
  border: ${(props) =>
    props.control ? `1px solid ${themes.colors.purple}` : null};
`;

const Text = styled.div`
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  word-break: break-all;
`;

const PlaceholderIcon = styled(Icon)`
  opacity: 0.5;
`;

const PlayImage = styled.img`
  width: 44px;
  height: 44px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: ${hexToRgba(themes.colors.white, 0.2)} !important;
  border-radius: 100px;
`;

const Video = styled.video<{ size: number; control: boolean }>`
  width: ${(props: any) => `${props.size}px`};
  height: ${(props: any) => `${props.size}px`};
  object-fit: contain;
  border-radius: ${BOX_RADIUS}px;
  background-color: ${themes.colors.backgroundLight};
  border: ${(props) =>
    props.control ? `1px solid ${themes.colors.purple}` : null};
`;

export default UploadImage;
