import * as React from "react";
import { styled } from "@mui/system";

import { userpilotReloadUrl } from "utils/userpilot";

import { ChangeData } from "../ApplicationAssetsUpload/ApplicationAssetsUpload";
import { UploadFile } from "components/forms/UploadInput";

import themes from "components/themes";

import emptyPromoArt from "assets/images/empty-state-promo-art.svg";

import { Icon, Icons, MessageBox } from "components";
import { FormHeader, Button } from "components/forms";
import { ApplicationAssetsUpload } from "components/application";
import Text from "./components/Text";

export interface StepData {
  control: UploadFile | null;
  assets: UploadFile[];
  referenceVariationType?: string;
}

// -- PROPS
type Step6Props = {
  title?: string;
  data: StepData;
  disableControl?: boolean;
  onChange: (values: object) => void;
  isEdit?: boolean;
  maxUploadAssets?: number;
  autoControl?: boolean;
  required?: boolean;
  isUpdateControl?: boolean;
  allowAddBlank?: boolean;
};

// -- COMPONENT
function PromoArtStepComponent({
  title,
  data,
  onChange,
  disableControl,
  isEdit = false,
  maxUploadAssets,
  autoControl,
  required = true,
  isUpdateControl = false,
  allowAddBlank = true,
}: Step6Props): JSX.Element {
  const [displayAddPromo, setDisplayAddPromo] = React.useState<boolean>(true);

  React.useEffect(() => {
    userpilotReloadUrl(`${window.location.href}/promo-art`);
  }, []);

  const handleChange = (changes: ChangeData) => {
    const isEmptyPromoInAssets = changes.assets.find(
      (singleAsset) => !singleAsset.file,
    );

    !isEmptyPromoInAssets !== displayAddPromo &&
      setDisplayAddPromo(!isEmptyPromoInAssets);

    onChange({
      assets: changes.assets,
      control: disableControl ? null : changes.control,
    });
  };

  const handleControl = (asset: UploadFile) => {
    onChange({
      control: asset,
    });
  };

  const addNoPromo = () => {
    const emptyFile: UploadFile = {
      preview: emptyPromoArt,
      file: null,
      fileName: "No Promo Art",
    };

    setDisplayAddPromo(false);
    onChange({
      control: disableControl ? data.control : emptyFile,
      assets: [emptyFile, ...(data.assets || [])],
    });
  };

  const addNoPromoArtButton = (
    <AddPromoButton
      onClick={displayAddPromo ? addNoPromo : null}
      disabled={!displayAddPromo}
    >
      <Icon icon={Icons.faPlusCircle} color={themes.colors.purple} />
      Test "No Promo Art"
    </AddPromoButton>
  );

  return (
    <>
      <OptionalBox visible={!isEdit && !isUpdateControl} closeable={false}>
        Promo Art is not required to run any experiments. You can add later if
        you don’t add any now. You can also simply add one as part of the
        Control or add multiple if you want to test multiple Promo Art
        creatives.
      </OptionalBox>

      <FormHeader style={{ marginTop: 30 }}>
        {title || "Upload Promo Art (Optional)"}
      </FormHeader>

      <ApplicationAssetsUpload
        assets={data.assets}
        controlAsset={data.control}
        buttonText="Upload Promo Art"
        emptyTitle="Upload Promo Art"
        emptyDescription="Use the button above to upload files from your computer"
        emptyDescriptionFooter={
          <Text label="Required Dimensions: " value="4320 x 1080 px" />
        }
        emptyHintFooter={
          <HintFooter>
            Download Apple’s Promotional Art template, including safe area,{" "}
            <HintFooterLink
              href="https://itunespartner.apple.com/assets/downloads/AppStore_PromotionalArtTemplates.zip"
              target="_blank"
            >
              here
            </HintFooterLink>
            . Apple’s full list of guidelines for this artwork can be found{" "}
            <HintFooterLink
              href="https://help.apple.com/asc/appspromoart/#/itc613d6ad49"
              target="_blank"
            >
              here
            </HintFooterLink>
            .
          </HintFooter>
        }
        onChange={handleChange}
        onControl={disableControl ? null : handleControl}
        maxUploadAssets={maxUploadAssets}
        required={required}
        autoControl={autoControl}
        additionalButtons={allowAddBlank ? addNoPromoArtButton : null}
      />
    </>
  );
}

const OptionalBox = styled(MessageBox)`
  margin: 20px 0 60px;
`;

const HintFooter = styled("div")`
  opacity: 0.8;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #9d9da8;
`;

const HintFooterLink = styled("a")`
  font-weight: 600;
  text-decoration: underline;
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const AddPromoButton = styled(Button)<{ disabled?: boolean }>`
  padding: 0 15px;

  height: 44px;

  border-radius: 8px;
  border: 1px dashed ${(props: any) => props.theme.colors.borderGrey};

  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};

  cursor: ${(props: any) =>
    props.disabled ? "not-allowed !important" : "pointer"};

  background-color: transparent;

  :hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
  }

  :focus {
    outline: 0;
  }

  svg {
    margin: 0 10px 0 0;

    font-size: 18px;
  }
`;

const PromoArtStep = React.memo(PromoArtStepComponent);
export default PromoArtStep;
