import React from "react";
import styled from "styled-components";

import { Form, FormRenderProps } from "react-final-form";

import { Price } from "screens/Billing/UserBillingDetails/constants/types";
import { optionsFormData } from "./constants/optionsFormData";

import { FormFooter } from "components/forms";
import { SelectedOptionSummary } from "components/billing/SelectedOptionSummary";
import { TestingOptions } from "./TestingOptions";

// -- TYPES
type OptionsSelectSubPageProps = {
  prices: Price[];
  onSubmit: (formData: optionsFormData) => void;
  userDomainAlreadyExist: boolean;
  initialData: optionsFormData | null;
};

// -- COMPONENT
export function OptionsSelectSubPage({
  prices,
  onSubmit,
  userDomainAlreadyExist,
  initialData,
}: OptionsSelectSubPageProps): JSX.Element {
  const onFormSubmit = (value: object) => {
    onSubmit(value as optionsFormData);
  };

  const initialValues: optionsFormData = initialData || {
    subscriptionsNumber: 1,
    subscriptions: true,
    payAsYouGo: false,
  };

  return (
    <Form onSubmit={onFormSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }: FormRenderProps) => (
        <form onSubmit={handleSubmit}>
          <Wrapper>
            <TestingOptions
              prices={prices}
              userDomainAlreadyExist={userDomainAlreadyExist}
            />

            <SelectedOptionSummary
              appSeatsNumber={form?.getFieldState("subscriptionsNumber")?.value}
              isSubscriptionSelected={true}
              isPayAsYouGoSelected={false}
              prices={prices}
            />
          </Wrapper>

          <FormFooter submitLabel="Continue" />
        </form>
      )}
    </Form>
  );
}

// -- STYLED
const Wrapper = styled.div`
  padding: 40px;

  background-color: #1e1e36;

  border-radius: 8px;
`;
