import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import { useLocation } from "react-use";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

/* tslint:disable */
import "bootstrap/dist/css/bootstrap.css";
import "@upptic/react-dates/lib/css/_datepicker.css";
import "./app.css";
/* tslint:enable */

import App from "App";
import { client, externalClient } from "api";

import BaseStyle from "components/themes/BaseStyle";
import themes from "components/themes";
import "./locales/config";

LicenseInfo.setLicenseKey(
  "8edd9fce02d7c376c5469c124f1dff3dTz03Nzc2OCxFPTE3MzAzOTc2NDkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);
momentDurationFormatSetup(moment);
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
});

const theme = createTheme({
  ...themes,
  palette: {
    mode: "dark",
    primary: {
      main: "#9743eb",
      dark: "rgba(151, 67, 235, 0.5)",
    },
    secondary: {
      main: "#13132b",
      dark: "rgba(7, 7, 30, 0.4)",
      light: "#cacad0",
    },
    background: {
      default: "#23233a",
      paper: "#23233a",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
    },
    divider: "#ffffff",
  },
});

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const renderApp = () => (
  <ApolloProvider
    client={
      window.location.pathname.startsWith("/external") ? externalClient : client
    }
  >
    <ScrollToTop />
    <BaseStyle />
    <ThemeProvider theme={{ ...theme }}>
      <App />
    </ThemeProvider>
  </ApolloProvider>
);

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(renderApp());
}
