import React, { useState, FC } from "react";
import styled from "styled-components";
import moment from "moment";
import { Box, Flex } from "@rebass/grid";
import { Field, Form, FormRenderProps } from "react-final-form";

import { Platform } from "api/models";
import { SingleReview } from "api/models/reviews/singleReviews";
import { AndroidStaticInfoData } from "../constants/androidStaticInfoData";

import validators from "utils/validators";
import formatters from "utils/formatters";
import { dateFormat } from "utils/dates";
import { toLocaleNumber } from "utils/numbers";

import {
  ageLimitsAndroidOptions,
  amountOptions,
  ratingsOptions,
} from "components/forms/data/data";

import { UserPermissions } from "v2/api/types";

import theme from "components/themes";
import {
  DatePicker,
  SelectInput,
  TextArea,
  TextInput,
  NumberInput,
  TagInput,
} from "components/forms";
import FormSection from "components/forms/FormSection";
import { HeaderWithEdit } from "./HeaderWithEdit";
import { DetailCard } from "components/application/forms/components/DetailCard";
import { ApplicationReviewsStaticPage } from "./ApplicationReviewsStaticPage";
import { FormField, Label } from "./StyledComponents";

// -- TYPES
type ApplicationAndroidStaticInfoPageProps = {
  data: AndroidStaticInfoData;
  onAppDetailsSubmit: (data: any) => Promise<boolean>;
  onAppRatingSubmit: (data: any) => Promise<boolean>;
  onGameInfoSubmit: (data: any) => Promise<boolean>;
  onDeveloperInfoSubmit: (data: any) => Promise<boolean>;
  reviews: SingleReview[];
  applicationId: string;
};

// -- COMPONENT
const ApplicationAndroidStaticInfoPage: FC<ApplicationAndroidStaticInfoPageProps> = ({
  data,
  onAppDetailsSubmit,
  onAppRatingSubmit,
  onGameInfoSubmit,
  onDeveloperInfoSubmit,
  reviews,
  applicationId,
}) => {
  const [isDetailsEditing, setDetailsEditing] = useState(false);
  const [isAppRatingEditing, setAppRatingEditing] = useState(false);
  const [isGameInfoEditing, setGameInfoEditing] = useState(false);
  const [isDeveloperInfoEditing, setDeveloperInfoEditing] = useState(false);

  const handleFormSubmit = (submitFunc: any, setEditingStatus: any) => async (
    values: any,
  ) => {
    const isValid = await submitFunc(values);
    isValid && setEditingStatus(false);
  };

  return (
    <>
      <Form
        onSubmit={handleFormSubmit(onAppDetailsSubmit, setDetailsEditing)}
        initialValues={data}
      >
        {({ handleSubmit, form }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <FormSection>
              <HeaderWithEdit
                title="App Details"
                editStatus={isDetailsEditing}
                setEditStatus={setDetailsEditing}
                form={form}
                editRequiredPermissions={[
                  UserPermissions.updateAppPlatformProfile,
                ]}
              />
              <Flex my={30} justifyContent="space-between">
                <Box width={1}>
                  {isDetailsEditing ? (
                    <Field
                      name="publisher"
                      validate={validators.composeValidators(
                        false,
                        validators.required,
                        validators.androidText,
                      )}
                      formatOnBlur
                      format={formatters.trim}
                    >
                      {({ input, meta }) => {
                        return (
                          <StyledCard>
                            <Label>Publisher</Label>
                            <TextInput
                              type="text"
                              placeholder="Enter Developer Name"
                              maxLength={30}
                              error={meta.touched && meta.error}
                              {...input}
                            />
                          </StyledCard>
                        );
                      }}
                    </Field>
                  ) : (
                    <>
                      <Label>Publisher</Label>
                      <FormField>{data.publisher}</FormField>
                    </>
                  )}
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box width={1 / 2} mr={32}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field name="ageLimit">
                        {({ input }) => {
                          return (
                            <StyledCard>
                              <Label>Content Rating</Label>
                              <SelectInput
                                options={ageLimitsAndroidOptions}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Content Rating</Label>
                        <FormField>{data.ageLimit}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={1 / 2}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field name="downloads">
                        {({ input }) => {
                          return (
                            <StyledCard>
                              <Label>Select App Downloads</Label>
                              <SelectInput options={amountOptions} {...input} />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Select App Downloads</Label>
                        <FormField>{data.downloads}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
            </FormSection>
          </form>
        )}
      </Form>

      <Form
        onSubmit={handleFormSubmit(onAppRatingSubmit, setAppRatingEditing)}
        initialValues={data}
      >
        {({ handleSubmit, form }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <FormSection>
              <HeaderWithEdit
                title="App Rating"
                editStatus={isAppRatingEditing}
                setEditStatus={setAppRatingEditing}
                form={form}
                editRequiredPermissions={[
                  UserPermissions.updateAppPlatformProfile,
                ]}
              />

              <Flex my={30} justifyContent="space-between">
                <Box width={1 / 2} mr={32}>
                  <StyledCard>
                    {isAppRatingEditing ? (
                      <Field name="rating">
                        {({ input }) => {
                          return (
                            <StyledCard>
                              <Label>Rating</Label>
                              <SelectInput
                                options={ratingsOptions}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Rating</Label>
                        <FormField>{data.rating}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={1 / 2} mr={32}>
                  <StyledCard>
                    {isAppRatingEditing ? (
                      <Field
                        name="numberOfRatings"
                        validate={validators.composeValidators(
                          false,
                          validators.required,
                          validators.minValue(1),
                          validators.maxValue(99999999),
                        )}
                        formatOnBlur
                        format={formatters.trimNumber}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Number of Ratings</Label>
                              <TextInput
                                type="text"
                                placeholder="E.g. 12345"
                                maxLength={8}
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(event: any) => {
                                  const { value } = event.target;
                                  if (isNaN(value)) {
                                    return false;
                                  }

                                  input.onChange(value);
                                }}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Number of Ratings</Label>
                        {/* TODO: check why its typed as string */}
                        <FormField>
                          {toLocaleNumber(Number(data.numberOfRatings))}
                        </FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box width={3 / 10} mr={32}>
                  <StyledCard>
                    {isAppRatingEditing ? (
                      <Field name="gameplayRating">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Gameplay (Optional)</Label>
                              <SelectInput
                                isClearable
                                error={meta.error}
                                valid={!(meta.touched && meta.error)}
                                options={ratingsOptions}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Gameplay (Optional)</Label>
                        <FormField>{data.gameplayRating || "N/A"}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10} mr={32}>
                  <StyledCard>
                    {isAppRatingEditing ? (
                      <Field name="controlRating">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Control (Optional)</Label>
                              <SelectInput
                                isClearable
                                error={meta.error}
                                valid={!(meta.touched && meta.error)}
                                options={ratingsOptions}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Control (Optional)</Label>
                        <FormField>{data.controlRating || "N/A"}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10} mr={32}>
                  <StyledCard>
                    {isAppRatingEditing ? (
                      <Field name="graphicRating">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Graphic (Optional)</Label>
                              <SelectInput
                                isClearable
                                error={meta.error}
                                valid={!(meta.touched && meta.error)}
                                options={ratingsOptions}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Graphic (Optional)</Label>
                        <FormField>{data.graphicRating || "N/A"}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
            </FormSection>
          </form>
        )}
      </Form>

      <Form
        onSubmit={handleFormSubmit(onGameInfoSubmit, setGameInfoEditing)}
        initialValues={data}
      >
        {({ handleSubmit, form }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <FormSection>
              <HeaderWithEdit
                title="Game Info"
                editStatus={isGameInfoEditing}
                setEditStatus={setGameInfoEditing}
                form={form}
                editRequiredPermissions={[
                  UserPermissions.updateAppPlatformProfile,
                ]}
              />

              <Flex my={30} justifyContent="space-between">
                <Box width={3 / 10} mr={32}>
                  <StyledCard>
                    {isGameInfoEditing ? (
                      <Field
                        name="appVersion"
                        formatOnBlur
                        format={formatters.trim}
                        validate={(value) => validators.required(value)}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>App Version</Label>
                              <TextInput
                                type="text"
                                placeholder="E.g. 1.0.13"
                                maxLength={8}
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(event: any) =>
                                  input.onChange(event.target.value)
                                }
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>App Version</Label>
                        <FormField>{data.appVersion}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10} mr={32}>
                  <StyledCard>
                    {isGameInfoEditing ? (
                      <Field
                        name="size"
                        validate={validators.composeValidators(
                          false,
                          validators.required,
                          validators.minValue(1),
                          validators.maxValue(9999.9),
                        )}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Size (MB)</Label>
                              <NumberInput
                                decimalSpaces={1}
                                placeholder="E.g. 123.33"
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(size: number | null) =>
                                  input.onChange(size)
                                }
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Size (MB)</Label>
                        <FormField>{toLocaleNumber(data.size)}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10} mr={32}>
                  <StyledCard>
                    {isGameInfoEditing ? (
                      <Field
                        name="releaseDate"
                        validate={validators.composeValidators(
                          false,
                          validators.isDate,
                          validators.required,
                        )}
                        format={formatters.trim}
                      >
                        {({ input, meta }) => {
                          return (
                            <div>
                              <Label style={{ marginBottom: "21px" }}>
                                Release Date
                              </Label>
                              <DatePicker
                                id="singleDatePicker"
                                externalError={meta.touched && meta.error}
                                onFocusChange={(focused) =>
                                  focused ? input.onFocus() : input.onBlur()
                                }
                                initialDate={input.value}
                                {...input}
                                readOnly={false}
                                allowClear={false}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Release Date</Label>
                        <FormField>
                          {moment(data.releaseDate, "DD-MM-YYYY").format(
                            dateFormat,
                          )}
                        </FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box mr={32}>
                  <StyledCard>
                    {isGameInfoEditing ? (
                      <Field name="tags" formatOnBlur>
                        {({ input }) => {
                          return (
                            <StyledCard>
                              <Label>Tags (Optional, Max 5)</Label>
                              <TagInput
                                {...input}
                                maxElementsCount={5}
                                placeholder="Enter tags"
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Tags (Optional, Max 5)</Label>
                        {data.tags.length > 0 ? (
                          <Tags>
                            {data.tags.map((singleTag, index: number) => (
                              <Tag key={index}>{singleTag}</Tag>
                            ))}
                          </Tags>
                        ) : (
                          <FormField>N/A</FormField>
                        )}
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box width={1} mr={32}>
                  <StyledCard>
                    {isGameInfoEditing ? (
                      <Field
                        name="whatsNew"
                        validate={validators.maxStringLength(500)}
                        formatOnBlur
                        format={formatters.trim}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>What's New Description (Optional)</Label>
                              <TextArea
                                type="text"
                                valid={!(meta.touched && meta.error)}
                                placeholder="Start typing here…"
                                maxLength={500}
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(event: any) =>
                                  input.onChange(event.target.value)
                                }
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>What's New Description (Optional)</Label>
                        <TextField>{data.whatsNew || "N/A"}</TextField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
            </FormSection>
          </form>
        )}
      </Form>

      <Form
        onSubmit={handleFormSubmit(
          onDeveloperInfoSubmit,
          setDeveloperInfoEditing,
        )}
        initialValues={data}
      >
        {({ handleSubmit, form }: FormRenderProps) => (
          <form onSubmit={handleSubmit}>
            <FormSection>
              <HeaderWithEdit
                title="Developer Contact (Optional)"
                editStatus={isDeveloperInfoEditing}
                setEditStatus={setDeveloperInfoEditing}
                form={form}
                editRequiredPermissions={[
                  UserPermissions.updateAppPlatformProfile,
                ]}
              />

              <Flex my={30} justifyContent="space-between">
                <Box width={1}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerPage">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Page (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter Developer Page"
                                maxLength={30}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Page (Optional)</Label>
                        <FormField>{data.developerPage}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
              <Flex my={30} justifyContent="space-between">
                <Box width={1}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerEmail">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Email (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter Developer Email"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Email (Optional)</Label>
                        <FormField>{data.developerEmail}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
              <Flex my={30} justifyContent="space-between">
                <Box width={1 / 2} mr={34}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerAddress1">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Address Line 1 (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter Street, PO Box"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Address Line 1 (Optional)</Label>
                        <FormField>{data.developerAddress1}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
                <Box width={1 / 2}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerAddress2">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Address Line 2 (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter Apartment, Suite, Unit or Building"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Address Line 2 (Optional)</Label>
                        <FormField>{data.developerAddress2}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box width={1 / 2} mr={34}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerCity">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>City (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter City, District, Suburb, Town or Village"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>City (Optional)</Label>
                        <FormField>{data.developerCity}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
                <Box width={1 / 2}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerState">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>State (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter State, Country, Province or Region"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>State (Optional)</Label>
                        <FormField>{data.developerState}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box width={1 / 2} mr={34}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerPostalCode">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Postal Code (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter ZIP or Postal Code"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Postal Code (Optional)</Label>
                        <FormField>{data.developerPostalCode}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
                <Box width={1 / 2}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="developerCountry">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Country (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="Enter Country Name"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Country (Optional)</Label>
                        <FormField>{data.developerCountry}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box width={1}>
                  <StyledCard>
                    {isDeveloperInfoEditing ? (
                      <Field name="privacyPolicyUrl">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Privacy Policy (Optional)</Label>
                              <TextInput
                                type="text"
                                placeholder="E.g. https://company.com"
                                maxLength={200}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Privacy Policy (Optional)</Label>
                        <FormField>{data.privacyPolicyUrl}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
            </FormSection>
          </form>
        )}
      </Form>

      <ApplicationReviewsStaticPage
        reviews={reviews}
        platform={Platform.android}
        applicationId={applicationId}
      />
    </>
  );
};

// -- STYLED
const TextField = styled.p`
  white-space: pre-wrap;
  word-break: break-all;
`;

const StyledCard = styled(DetailCard)`
  color: ${theme.colors.white};

  font-size: 16px;
`;

const Tag = styled.p`
  border-radius: 4px;
  border: solid 1px rgba(255, 255, 255, 0.4);
  background-color: ${theme.colors.backgroundLight};
  padding: 5px 16px;
  margin-right: 15px;
`;

const Tags = styled.div`
  display: flex;
`;

export default ApplicationAndroidStaticInfoPage;
