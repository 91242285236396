import React, { memo, FC, useEffect } from "react";

import { StaticInfoAndroidForm } from "./components/StaticInfoStep/StaticInfoAndroidForm";
import { StaticInfoiOSForm } from "./components/StaticInfoStep/StaticInfoiOSForm";

import { userpilotReloadUrl } from "utils/userpilot";

import { Platform } from "api/models";
import { SingleReview } from "api/models/reviews/singleReviews";

// -- TYPES
export interface StepData {
  publisher: string;
  rating: string;
  downloads: string;
  whatsNew: string;
  numberOfRatings: string;
  ageLimit: string;
  appVersion: string;
  releaseDate: string;
  releaseNote: string;
  category: string;
  ranking: string;
  tags: string[];
  size?: string;
  gameplayRating: string;
  controlRating: string;
  graphicRating: string;
  copyright?: string;
  developerPage?: string;
  developerEmail?: string;
  developerAddress1?: string;
  developerAddress2?: string;
  developerCity?: string;
  developerState?: string;
  developerPostalCode?: string;
  developerCountry?: string;
  privacyPolicyUrl?: string;
  reviews: SingleReview[];
  mdl: number;
}

type Props = {
  data: StepData;
  onChange: (values: object) => void;
  applicationPlatform: Platform.ios | Platform.android;
  validate?: any;
};

// -- COMPONENT
const StaticInfoStepComponent: FC<Props> = ({
  data,
  onChange,
  applicationPlatform,
  validate,
}) => {
  useEffect(() => {
    userpilotReloadUrl(`${window.location.href}/store-details`);
  }, []);

  if (applicationPlatform === Platform.android) {
    return <StaticInfoAndroidForm data={data} onChange={onChange} />;
  } else {
    return (
      <StaticInfoiOSForm
        data={data}
        onChange={onChange}
        validate={() => validate}
      />
    );
  }
};

const StaticInfoStep = memo(StaticInfoStepComponent);
export default StaticInfoStep;
