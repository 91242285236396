import React, { useContext } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { Box } from "@rebass/grid";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import {
  ApplicationAsset,
  AssetType,
  ExperimentPhaseType,
  Status,
} from "api/models";

import {
  updateReferenceVariation as updateReferenceVariationMutation,
  UpdateReferenceVariationResponse,
} from "v2/api/mutations/application/updateReferenceVariation";
import {
  createFeatureGraphics,
  deactivateFeatureGraphic,
  DeactivateFeatureGraphicResponse,
  deleteFeatureGraphic,
  DeleteFeatureGraphicResponse,
} from "v2/api/mutations/application/featureGraphic";

import {
  getFeatureGraphicElementsPageData,
  GetFeatureGraphicElementsPageDataResponse,
} from "api/queries/featureGraphic";
import {
  updateControlFeatureGraphic,
  UpdateControlFeatureGraphicResponse,
} from "api/mutations/featureGraphic";

import { getTrafficIndicator } from "utils/statistics";

import { runAction } from "../helpers/runAction";
import { updateReferenceVariation } from "../helpers/updateReferenceVariation";

import { ApplicationContext, ModalContext } from "contexts";

import { Spinner, Error } from "components";
import ApplicationElements from "screens/Application/ApplicationElements/components/ApplicationElementsContainer";
import { HiddenFlex } from "../components/uiElement";
import { ApplicationImageAssets } from "../components/ApplicationImageAssets";
import { useHasUserPermission } from "v2/hooks/useHasUserPermission";

// -- COMPONENT
export default function ApplicationElementsFeatureGraphics(): JSX.Element {
  const hasUserPermissions = useHasUserPermission();

  const match = useRouteMatch<{ id: string }>();
  const history = useHistory();

  const applicationId = match.params.id;

  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const { data, error, loading } = useQuery<
    GetFeatureGraphicElementsPageDataResponse
  >(getFeatureGraphicElementsPageData, {
    variables: { applicationId },
    fetchPolicy: "network-only",
  });

  const [deleteMutation] = useMutation<DeleteFeatureGraphicResponse>(
    deleteFeatureGraphic.definition,
  );
  const [controlMutation] = useMutation<UpdateControlFeatureGraphicResponse>( // TODO: check if we need that
    updateControlFeatureGraphic,
  );
  const [updateReferenceMutation] = useMutation<
    UpdateReferenceVariationResponse
  >(updateReferenceVariationMutation.definition);
  const [deactivateMutation] = useMutation<DeactivateFeatureGraphicResponse>(
    deactivateFeatureGraphic.definition,
  );

  const setModal = useContext(ModalContext);
  const applicationCtx = useContext(ApplicationContext);

  const refetchQuery = {
    query: getFeatureGraphicElementsPageData,
    variables: { applicationId },
  };

  const trafficIndicator =
    applicationCtx.current &&
    data &&
    applicationCtx.current.status === Status.active
      ? getTrafficIndicator(data.phaseTraffic, data.trafficNeeded)
      : undefined;

  const removeAsset = React.useCallback(
    (asset: ApplicationAsset) => async () => {
      await runAction(
        applicationId,
        asset,
        deleteMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    },
    [applicationId, deleteMutation, setModal, refetchQuery, setFullPageLoader],
  );

  const displayRemoveModal = React.useMemo(() => {
    if (!hasUserPermissions(deleteFeatureGraphic.requiredPermissions)) {
      return null;
    }

    return (asset: ApplicationAsset) => {
      setModal({
        type: "set",
        props: {
          title: "Delete",
          content: "Are you sure you want to delete this asset?",
          onCancel: () => {},
          onConfirm: removeAsset(asset),
        },
      });
    };
  }, [hasUserPermissions, setModal, removeAsset]);

  const onDeactivateAsset = React.useCallback(
    (asset: ApplicationAsset) => async () => {
      await runAction(
        applicationId,
        asset,
        deactivateMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    },
    [
      applicationId,
      deactivateMutation,
      setModal,
      refetchQuery,
      setFullPageLoader,
    ],
  );

  const removeAssetFromCurrentTestModal = React.useMemo(() => {
    if (!hasUserPermissions(deactivateFeatureGraphic.requiredPermissions)) {
      return null;
    }

    return (asset: ApplicationAsset) => {
      setModal({
        type: "set",
        props: {
          title: "Remove Asset From Test",
          content:
            "Are you sure you want to remove this asset from current test?",
          onCancel: () => {},
          onConfirm: onDeactivateAsset(asset),
        },
      });
    };
  }, [hasUserPermissions, setModal, onDeactivateAsset]);

  const onControl = async (asset: ApplicationAsset) => {
    await runAction(
      applicationId,
      asset,
      controlMutation,
      setModal,
      refetchQuery,
      setFullPageLoader,
    );
  };

  const onSetTop = React.useMemo(() => {
    if (
      !hasUserPermissions(updateReferenceVariationMutation.requiredPermissions)
    ) {
      return null;
    }

    return async (asset: ApplicationAsset) => {
      await updateReferenceVariation(
        applicationId,
        asset,
        AssetType.featureGraphic,
        updateReferenceMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    };
  }, [
    refetchQuery,
    setFullPageLoader,
    setModal,
    updateReferenceMutation,
    applicationId,
    hasUserPermissions,
  ]);

  if (loading || !data) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <>
      {fullPageLoader}
      <ApplicationElements
        applicationId={applicationId}
        history={history}
        uploadRoute={authRoutes.APPLICATION_CREATE_FEATURE_GRAPHICS.getUrlWithParams(
          { id: applicationId },
        )}
        permissionsForUpload={createFeatureGraphics.requiredPermissions}
      >
        <HiddenFlex flexDirection="column">
          <Box>
            <ApplicationImageAssets
              title="Feature Graphics"
              applicationId={applicationId}
              phaseType={ExperimentPhaseType.featureGraphic}
              history={history}
              assets={data.featureGraphics}
              currentPhase={data.currentPhase}
              trafficIndicator={trafficIndicator}
              currentIteration={data.currentIteration}
              onRemove={displayRemoveModal}
              onControl={onControl}
              onDeactivate={removeAssetFromCurrentTestModal}
              onSetTop={onSetTop}
            />
          </Box>
        </HiddenFlex>
      </ApplicationElements>
    </>
  );
}
