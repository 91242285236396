import React from "react";

import { StepData } from "./Preview1";

import { ApplicationAssetsUpload } from "components/application";
import { Platform } from "api/models";

interface Props {
  data: StepData;
  onChange: (values: StepData) => void;
  onControl?: ((object: any) => void) | null;
  maxUploadAssets?: number;
  platform?: Platform.ios | Platform.android;
}

class Preview3 extends React.PureComponent<Props> {
  public render() {
    const {
      data,
      onChange,
      onControl = null,
      maxUploadAssets,
      platform,
    } = this.props;

    return (
      <ApplicationAssetsUpload
        header="3rd Position (Screen3)"
        assets={data.assets}
        controlAsset={data.control}
        buttonText="Upload Screens"
        emptyTitle="Upload Screens"
        emptyDescription="Use the button above to upload screens from your computer"
        onChange={onChange}
        onControl={onControl}
        video={platform !== Platform.android}
        maxUploadAssets={maxUploadAssets}
      />
    );
  }
}

export default Preview3;
