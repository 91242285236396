import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
  value: string;
}

function Text({ label, value }: Props) {
  return (
    <Label>
      {label}
      <Value>{value}</Value>
    </Label>
  );
}

const Label = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: #9d9da8;
`;

const Value = styled.span`
  font-weight: 600;
`;

export default Text;
