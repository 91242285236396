export enum UserPermissions {
  // FB Pixel
  readFacebookPixel = "PCRFP",
  // Application
  readApplication = "PCRAP",
  createApplication = "PCCAP",
  updateApplication = "PCUAP",
  // AppPlatformProfile
  readAppPlatformProfile = "PCRAPPP",
  createAppPlatformProfile = "PCCAPPP",
  updateAppPlatformProfile = "PCUAPPP",
  deleteAppPlatformProfile = "PCDAPPP",
  // ASO test
  readASOTest = "PART",
  createASOTest = "PACT",
  updateASOTest = "PAUT",
  deleteASOTest = "PADT",
  // ASO asset
  readASOAsset = "PARAS",
  createASOAsset = "PACAS",
  updateASOAsset = "PAUAS",
  deleteASOAsset = "PADAS",
  // ASO reporting
  readASOReporting = "PARAR",
  // Inpersonate
  inpersonateUser = "AAIUA",
  // Account
  readClient = "PCRC",
  updateClient = "PCUC",
  // Billing
  readAccountBilling = "PCRCB",
  updateAccountBilling = "PCUCB",
  // Client
  readClientBilling = "AARCB",
  // Page permissions
  applicationOverview = "PARO",
  // user
  readUser = "PCRU",
  createUser = "PCCU",
  updateUser = "PCUU",
  deleteUser = "PCDU",
  // contro assets
  updateASOControl = "PAUC",
  // routing
  readASORouting = "PARR",
  updateASORouting = "PAUR",
  createASORouting = "PACR",
  deleteASORouting = "PADR",

  // modules
  moduleASO = "PAR",
  moduleUA = "PUR",
  moduleCreative = "PCR",
  moduleUpptic = "PUPR",
  moduleDashboard = "PDR",
  readHubOverview = "PHHOR",
  moduleSocial = "PSR",

  readClientUppticEntitlementFilter = "PCRCUF",
}
