import { client } from "../api";

type TokenObserver = (token: string) => void;

class AuthService {
  // tslint:disable-next-line
  private _token = "";
  private tokenStorageKey = "AUTH_TOKEN_KEY";
  private observer?: TokenObserver;

  constructor() {
    this.getToken().then((token) => {
      this.token = token;
    });
  }

  get token(): string {
    return this._token;
  }

  set token(token: string) {
    if (token === this._token) {
      return;
    }

    this._token = token;
    if (this.observer) {
      this.observer(token);
    }
  }

  public listen(observer: TokenObserver) {
    this.observer = observer;
  }

  public async getToken(): Promise<string> {
    const token = await localStorage.getItem(this.tokenStorageKey);
    return token ? `JWT ${token}` : "";
  }

  public async setToken(token: string) {
    if (this.token !== "" && this.token !== token) {
      client.stop();
      await client.clearStore();
    }
    localStorage.setItem(this.tokenStorageKey, token);
    this.token = token;
  }

  public removeToken() {
    localStorage.removeItem(this.tokenStorageKey);
    this.token = "";
  }
}

export default AuthService;
