import * as React from "react";
import { Field, Form } from "react-final-form";
import { Box } from "@rebass/grid";
import styled from "styled-components";

import validators from "utils/validators";

import { PixelEventsTypes } from "../constants/pixelEventsTypes";
import { FacebookPixel } from "api/models";

import { TextWithTooltip } from "components";
import {
  FormFooter,
  FormLabel,
  Switch,
  FormSection,
  TextInput,
  SelectInput,
} from "components/forms";

// -- TYPES
type EditPixelFormProps = {
  onSubmit: (data: any) => void;
  initialValues: FacebookPixel;
  onDelete?: () => void;
};

// -- COMPONENT
export function EditPixelForm({
  onSubmit,
  initialValues,
  onDelete,
}: EditPixelFormProps): JSX.Element {
  const options: any[] = React.useMemo(() => {
    return Object.entries(PixelEventsTypes).map((enumEntry) => ({
      label: enumEntry[1],
      value: enumEntry[0],
    }));
  }, []);

  const zendeskUrlForPixelDomain =
    "https://upptic.zendesk.com/hc/en-us/articles/360058732072-Set-Up-Facebook-Pixel-Verify-Domain";

  const onZendeskIconClick = () => {
    window.open(zendeskUrlForPixelDomain, "_blank");
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormSection>
            <Box width={3 / 5}>
              <Field name="pixelId" validate={validators.required}>
                {({ input, meta }) => {
                  return (
                    <>
                      <FormLabel name="pixelId" spacing={false}>
                        Enter your Facebook Pixel ID
                      </FormLabel>
                      <TextInput
                        type="text"
                        maxLength={30}
                        placeholder="Enter Pixel ID"
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    </>
                  );
                }}
              </Field>
              <Field name="eventType" validate={validators.required}>
                {({ input, meta }) => {
                  return (
                    <>
                      <FormLabel name="eventType">
                        <TextWithTooltip
                          value="Select Facebook Standard Event to Use"
                          tooltipContent="Choose a Facebook Standard Event that your organization is not currently utilizing"
                        />
                      </FormLabel>
                      <SelectInput
                        options={options}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(value: any) => {
                          input.onChange(value);
                        }}
                      />
                    </>
                  );
                }}
              </Field>
              <Field
                name="domainTagContent"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                  validators.checkForSpecialCharacters,
                )}
              >
                {({ input, meta }) => {
                  return (
                    <>
                      <FormLabel name="domainTagContent">
                        <TextWithTooltip
                          value="Verify Domain in Facebook"
                          onIconClick={onZendeskIconClick}
                          tooltipContent={
                            <LinkToInstructions
                              href={zendeskUrlForPixelDomain}
                              target="_blank"
                            >
                              Click for implementation instructions
                            </LinkToInstructions>
                          }
                        />
                      </FormLabel>
                      <TextInput
                        type="text"
                        maxLength={30}
                        placeholder="Enter Domain Tag Content"
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          input.onChange(event.target.value);
                        }}
                      />
                    </>
                  );
                }}
              </Field>
              <Field name="enabled">
                {({ input }) => {
                  return (
                    <>
                      <FormLabel name="enabled">Pixel Status</FormLabel>
                      <Switch
                        displayLabels
                        labelOff={"Off"}
                        labelOn={"On"}
                        {...input}
                      />
                    </>
                  );
                }}
              </Field>
            </Box>
          </FormSection>
          <FormFooter
            onCancel={onDelete}
            submitLabel={onDelete ? "Save changes" : "Add Pixel"}
            cancelLabel="Delete Pixel"
          />
        </form>
      )}
    </Form>
  );
}

// -- STYLED
const LinkToInstructions = styled.a`
  color: #fff;

  cursor: pointer;

  &:hover {
    color: #fff;

    text-decoration: underline;
  }
`;
