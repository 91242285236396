import { gql } from "@apollo/client";

export const GET_PLATFORM_CATEGORIES = gql`
  query categories($platform: String!) {
    categories(platform: $platform) {
      id
      name
      children {
        id
        name
      }
    }
  }
`;

export const GET_APPLICATION_PROMO_ARTS_ONLY = gql`
  query applicationPromoArtsOnly($applicationId: ID!) {
    promoArts(applicationId: $applicationId) {
      id
      fileName
      isControl
      file {
        url
        name
      }
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
    }
  }
`;

export const GET_APPLICATION_BASE_DATA = gql`
  query application($applicationId: ID!) {
    application(id: $applicationId) {
      id
      name
      platform
      status
      createdAt
    }
  }
`;
