import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react";
import createPersistedState from "use-persisted-state";
import { changeZendeskChatDisplay, ZendeskScript } from "utils/zendesk";
import { SnackbarProvider } from "notistack";

import { PlatformContext, PlatformStore } from "@upptic/module-directory";

import { client, ApplicationListing, platformInstanceToUse } from "api";

import { AnalyticsService } from "services";

import { useStores } from "contexts/storeContext";

import { ApplicationContext, ApplicationUserContext } from "contexts";
import { io } from "socket.io-client";

import { Spinner, ModalWrapper } from "components";
import { AuthTemplate } from "templates";
import { Routing } from "v2/components/Routing";
import EntitySelectorWrapper from "./components/EntitySelectorWrapper";

import * as Sentry from "@sentry/react";
import { version } from "../package.json";
import config from "config";
import { useQuery } from "@apollo/client";
import { getSimpleCurrentUser } from "v2/api/queries/user/getCurrentUser";

const sentryIgnoreErrors = [
  "Unauthorized",
  "One-time password is required",
  "E-Mail or password wrong",
];

if (!config.isDev) {
  Sentry.init({
    dsn:
      "https://47a22fe93cbc43cab0b6640ab01833f2@o788311.ingest.sentry.io/5799862",
    release: version,
    tracesSampleRate: 1.0,
    beforeSend(event: Sentry.Event) {
      // Ignore E2E tests
      if (window.navigator.userAgent.includes("HeadlessChrome")) return null;
      // Ignore user errors
      if (event.message) {
        if (sentryIgnoreErrors.includes(event.message)) return null;
        if (event.message.startsWith("No access to client")) return null;
      }
      return event;
    },
  });
}

const history = createBrowserHistory();

const useApplicationUserState = createPersistedState("applicationUser");
let firstStart = true;

const platformStore = new PlatformStore(client as any);
platformInstanceToUse.instance = platformStore as any;

const socket = io("/", {
  withCredentials: true,
  autoConnect: false,
  path: "/api/notification-websocket/socket.io/",
});

socket.on("connect", () => {
  console.log("Websocket connected", socket.id);
});

socket.on("userNotificationReceived", async (data) => {
  await (window.navigator as any).locks.request(
    `userNotification_${data.code}`,
    { ifAvailable: true },
    async (lock: any) => {
      if (!lock) return;

      /* tslint:disable-next-line */
      new Notification(data.subject, { body: data.content });
    },
  );

  document.dispatchEvent(
    new CustomEvent("userNotificationReceived", {
      detail: data,
    } as any),
  );
});

const App: React.FC = () => {
  const { userStore } = useStores();
  const [loading, setLoading] = useState(true);
  userStore.platformStore = platformStore;
  const [application, setApplication] = useState<ApplicationListing | null>(
    null,
  );
  const [isUserBootstraping, setIsUserBootstraping] = useState(true);

  const [applicationUser, setApplicationUser] = useApplicationUserState(
    null,
  ) as any;

  // handle auto logout
  useQuery(getSimpleCurrentUser, {
    pollInterval: 5 * 60 * 1000,
    skip: !userStore?.isLoggedIn,
    onError: (err) => {
      if (err?.graphQLErrors?.[0]?.extensions?.code === 401) {
        const d = new Date();
        d.setTime(d.getTime() + 4 * 60 * 60 * 1000);
        document.cookie = `locationBeforeLogout=${
          window.location.pathname
        }; expires=${d.toUTCString()}; path=/;`;
        window.location.href = "/logout";
      }
    },
  });

  const onErrorCallback = useCallback(async () => {
    setIsUserBootstraping(true);
    userStore.clearStorage();
    if (!firstStart) await client.resetStore();
    firstStart = true;
    setApplication(null);
    AnalyticsService.removeUser();
    setApplicationUser(null);
    setIsUserBootstraping(false);
  }, [setApplicationUser, userStore]);

  useEffect(() => {
    const shouldShowHelpChat =
      userStore.isLoggedIn && userStore.validPaymentMethod;
    changeZendeskChatDisplay(shouldShowHelpChat);
  }, [
    userStore.isLoggedIn,
    userStore.validPaymentMethod,
    userStore.clientCode,
  ]);

  useEffect(() => {
    async function loadUser() {
      try {
        setLoading(true);
        await userStore.loadUser();
        if (!applicationUser && userStore.userId) {
          setApplicationUser({
            id: userStore.userId,
            username: userStore.userName,
          });
        }
        setIsUserBootstraping(false);
      } catch (err) {
        onErrorCallback();
      } finally {
        setLoading(false);
      }
    }

    loadUser();
  }, [applicationUser, onErrorCallback, setApplicationUser, userStore]);

  const asIframe = history.location.pathname.indexOf("iframes") !== -1;

  const applicationUserSetter = useCallback(
    (userId: number, username: string) => {
      setApplicationUser({ id: userId, username });
    },
    [setApplicationUser],
  );

  const applicationUserContextValue = useMemo(() => {
    return {
      userId: applicationUser?.id || null,
      username: applicationUser?.username || null,
      setCurrent: applicationUserSetter,
    };
  }, [applicationUser, applicationUserSetter]);

  const applicationContextValue = useMemo(() => {
    return {
      current: application,
      setCurrent: setApplication,
    };
  }, [application]);

  useEffect(() => {
    if (socket.connected && !userStore.isLoggedIn) {
      socket.disconnect();
      return;
    }
    if (socket.connected || window.location.origin.includes("localhost")) {
      return;
    }

    const doConnect = async () => {
      if (!userStore.isLoggedIn) return;
      socket.connect();
      await Notification.requestPermission();
    };

    doConnect().catch(console.error);
    return;
  }, [userStore?.isLoggedIn]);

  if (isUserBootstraping || loading || userStore.showPlatformSpinner) {
    return (
      <AuthTemplate asIframe={asIframe}>
        <ModalWrapper>
          <Spinner />
        </ModalWrapper>
      </AuthTemplate>
    );
  }

  return (
    <PlatformContext.Provider value={platformStore}>
      <ApplicationUserContext.Provider value={applicationUserContextValue}>
        <ApplicationContext.Provider value={applicationContextValue}>
          {userStore.validPaymentMethod && <ZendeskScript />}
          <ModalWrapper>
            <SnackbarProvider>
              <EntitySelectorWrapper>
                <Router history={history}>
                  <Routing
                    asIframe={asIframe}
                    isUserLogged={userStore.isLoggedIn}
                  />
                </Router>
              </EntitySelectorWrapper>
            </SnackbarProvider>
          </ModalWrapper>
        </ApplicationContext.Provider>
      </ApplicationUserContext.Provider>
    </PlatformContext.Provider>
  );
};

export default observer(App);
