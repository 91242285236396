import React from "react";
import styled from "styled-components";

import themes from "components/themes";
import { Icon, Icons } from "components";

interface Props {
  children: React.ReactNode;
}

function FormError({ children }: Props) {
  return (
    <Wrapper>
      {children} <ErrorIcon icon={Icons.faExclamationCircle} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: right;
  padding: 5px;
  color: ${themes.colors.notificationRed};
  font-size: 14px;
`;

const ErrorIcon = styled(Icon)`
  margin-left: 5px;
`;

export default FormError;
