import React, { memo } from "react";

import themes from "components/themes";

interface PayloadShape {
  value: string | number;
}

interface AxisTickProps {
  x?: number;
  y?: number;
  index?: number;
  payload?: PayloadShape;
  visibleTicksCount?: number;
}

const SimpleXAxis: React.FC<AxisTickProps> = ({
  x,
  y,
  index,
  payload,
  visibleTicksCount = 0,
}) => {
  const getAnchorPosition = (): string => {
    if (!index) {
      return "end";
    }

    if (index === visibleTicksCount - 1) {
      return "start";
    }

    return "middle";
  };

  const displayFormattedValue = (
    tickPayload: PayloadShape | undefined,
  ): React.ReactNode => {
    return tickPayload?.value;
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor={getAnchorPosition()}
        fill={themes.colors.lightGrey}
      >
        {displayFormattedValue(payload)}%
      </text>
    </g>
  );
};

export default memo(SimpleXAxis);
