import { SortingRule } from "react-table";

import { camelToSnakeCase } from "./strings";

/**
 * Cast sort object to string contains python markup for sort
 * @param {object} sortObject - object with sord details
 * @param {string} sortObject.id - field name in js notation containing dots
 * @param {bool} sortObject.desc - is sortidn descending
 * @returns undefined or sort strign formated for python markup
 */
export const getSortString = (
  sortObject?: SortingRule<object>,
): string | undefined => {
  if (!sortObject) {
    return undefined;
  }

  const { id, desc } = sortObject;

  let formatedFieldName = camelToSnakeCase(id.replace(".", "__"));

  if (desc) {
    formatedFieldName = `-${formatedFieldName}`;
  }

  return formatedFieldName;
};
