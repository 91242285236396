import React, { FC } from "react";
import { styled } from "@mui/system";
import { Flex } from "@rebass/grid";
import { Link } from "react-router-dom";

import { Section, Icons, Icon, TextWithTooltip } from "components";

import { StaticTable } from "v2/components/Table/StaticTable";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import theme from "components/themes";

import {
  TestCredits,
  UpcomingPayment,
  SubscriptionDetails,
} from "screens/Billing/UserBillingDetails/constants/types";

import { convertDateToDisplayFormat } from "utils/dates";
import { convertToCurrency } from "utils/numbers";
import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";
import { TdComponent } from "v2/components/Table/components/TdComponent";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- TYPES
interface Props {
  testCredits: TestCredits;
  upcomingPayment: UpcomingPayment;
  currentSubscriptions: SubscriptionDetails | null;
  currentBillingPeriod?: string[];
  isUnlimited: boolean;
  isFreeUser: boolean;
}

// -- COMPONENT
export const PricingSection: FC<Props> = ({
  testCredits,
  upcomingPayment,
  currentSubscriptions,
  isUnlimited,
  isFreeUser,
}) => {
  const renderInvoiceEstimate = React.useCallback(() => {
    const label = (
      <TextWithTooltip
        value="Upcoming Invoice Estimate:"
        tooltipContent="The billing period will begin when any application reaches the phase after calculation bcr"
      />
    );

    let content = (
      <TextWithTooltip
        value="No Upcoming Invoice Estimate"
        tooltipContent="Estimated cost based on current number of test run, app seats and free credits"
      />
    );

    if (upcomingPayment) {
      const {
        periodEnd,
        periodStart,
        total,
        payAsYouGo,
        subscription,
        unlimitedPlan,
        freeCredits,
      } = upcomingPayment;

      content = (
        <InvoiceDetails>
          {!!unlimitedPlan?.total && (
            <UpcomingInvoiceLine>
              Unlimited Plan: {convertToCurrency(unlimitedPlan.total / 100)}
            </UpcomingInvoiceLine>
          )}
          {!!subscription?.appNumber && (
            <UpcomingInvoiceLine>
              {subscription.appNumber} App Seats:{" "}
              {convertToCurrency(subscription.total / 100)}
            </UpcomingInvoiceLine>
          )}

          {!!payAsYouGo?.testsNumber && (
            <UpcomingInvoiceLine>
              {payAsYouGo.testsNumber} Pay As You Go Tests:{" "}
              {convertToCurrency(payAsYouGo.total / 100)}
            </UpcomingInvoiceLine>
          )}

          {!!freeCredits?.used && (
            <UpcomingInvoiceLine>
              {freeCredits.used} Free Test Credits
            </UpcomingInvoiceLine>
          )}

          <UpcomingInvoiceLine>
            Total: {convertToCurrency(total / 100)}
          </UpcomingInvoiceLine>

          <UpcomingInvoiceLine>
            {`Billing Period: ${convertDateToDisplayFormat(
              periodStart,
            )} - ${convertDateToDisplayFormat(periodEnd)}`}
          </UpcomingInvoiceLine>
        </InvoiceDetails>
      );
    }

    return {
      label,
      value: content,
    };
  }, [upcomingPayment]);

  const renderUsedAppSeatsSection = React.useCallback(() => {
    const tooltipLabel =
      "Number of subscribed apps seats for unlimited testing";
    const label = (
      <TextWithTooltip
        value="App Seats Subscribed:"
        tooltipContent={tooltipLabel}
      />
    );

    const downgradeNumber =
      !!currentSubscriptions?.currentAppSeatsNum &&
      (currentSubscriptions?.upcomingSubscription ||
        currentSubscriptions?.currentAppSeatsNum) <
        currentSubscriptions?.currentAppSeatsNum &&
      `(Number of App Seats in next billing period: ${currentSubscriptions?.upcomingSubscription})`;

    const content = isFreeUser ? (
      "Free user"
    ) : (
      <>
        {currentSubscriptions?.currentAppSeatsNum || "None - Pay As You Go"}{" "}
        {downgradeNumber}
        <PermissionRestriction
          requiredPermissions={authRoutes.EDIT_SUBSCRIPTIONS.routePermissions}
        >
          <ChangeAppSeats to={authRoutes.EDIT_SUBSCRIPTIONS.getUrlWithParams()}>
            <Icon icon={Icons.faPen} />
            <span>Change</span>
          </ChangeAppSeats>
        </PermissionRestriction>
      </>
    );

    const value = (
      <Flex flexDirection="row" justifyContent={"space-between"}>
        {content}
      </Flex>
    );

    return {
      label,
      value,
    };
  }, [currentSubscriptions, isFreeUser]);

  const renderFreeCreditsSection = React.useCallback(
    (freeTestCredits: TestCredits) => {
      const { testCreditsExpirationDate, testCreditsNumber } = freeTestCredits;

      if (!testCreditsNumber) {
        return {};
      }

      const content = (
        <Flex flexDirection="row" justifyContent={"space-between"}>
          {testCreditsNumber}
          {testCreditsExpirationDate && (
            <ExpirationWrapper>
              Expiration date:{" "}
              {convertDateToDisplayFormat(testCreditsExpirationDate)}
            </ExpirationWrapper>
          )}
        </Flex>
      );

      const label = (
        <TextWithTooltip
          value="Free Test Credits:"
          tooltipContent="Free test credits to usage for tests without subscription"
        />
      );

      return {
        label,
        value: content,
      };
    },
    [],
  );

  const data = React.useMemo(() => {
    const invoiceEstimate = renderInvoiceEstimate();

    let dataRows: object[] = [invoiceEstimate];

    if (!isUnlimited) {
      dataRows = [renderUsedAppSeatsSection(), invoiceEstimate];

      testCredits.testCreditsNumber &&
        dataRows.push(renderFreeCreditsSection(testCredits));
    }

    return dataRows;
  }, [
    isUnlimited,
    testCredits,
    renderUsedAppSeatsSection,
    renderInvoiceEstimate,
    renderFreeCreditsSection,
  ]);

  const columns = React.useMemo<SingleTableColumn<unknown>[]>(
    () => [
      {
        Header: "label",
        accessor: "label",
        tdElement: CustomLabelTd as any,
      },
      {
        Header: "value",
        accessor: "value",
        tdElement: CustomValueTd as any,
      },
    ],
    [],
  );

  return (
    <Section transparent>
      <StaticTable
        columns={columns}
        highlightFirstCell
        displayHeader={false}
        data={data}
      />
    </Section>
  );
};

// -- STYLED
const ExpirationWrapper = styled("span")`
  font-weight: bold;
`;
const ChangeAppSeats = styled(Link)`
  color: ${theme.colors.white};

  text-decoration: underline;

  svg {
    margin-right: 3px;
  }

  &:hover {
    color: ${theme.colors.white};
  }
`;
const UpcomingInvoiceLine = styled("div")``;
const InvoiceDetails = styled("div")`
  margin: 5px 0;

  div:nth-last-child(2) {
    margin-top: 10px;
  }
`;

const CustomLabelTd = styled(TdComponent)`
  width: 250px;
  font-size: 14px;
  padding-right: 0px;
`;

const CustomValueTd = styled(TdComponent)`
  font-size: 14px;
  background-color: ${(props: any) => props.theme.colors.backgroundLight};
`;
