import * as React from "react";
import { Box } from "@rebass/grid";
import { Field, FieldMetaState } from "react-final-form";
import { styled } from "@mui/system";
import moment from "moment";

import { Platform } from "api/models";
import { SingleReview } from "api/models/reviews/singleReviews";
import { ReviewDomElement } from "api/models/reviews/reviewDomElement";
import { ReviewField } from "api/models/reviews/reviewField";

import {
  DatePicker,
  NumberInput,
  TextInput,
  TextArea,
  FormLabel,
} from "components/forms";
import { DetailCard } from "../../../DetailCard";

// -- TYPES
type SingleReviewProps = {
  onChange: (fieldName: string, fieldValue: string | number | null) => void;
  reviewElement: ReviewDomElement;
  platform: Platform;
  value: SingleReview;
};

export function SingleReviewWizard({
  onChange,
  reviewElement,
  value,
  platform,
}: SingleReviewProps): JSX.Element {
  const handleChange = React.useCallback(
    (fieldName: string) => (
      newValue: React.ChangeEvent<HTMLInputElement> | string | number | null,
    ) => {
      if (typeof newValue === "object") {
        newValue = newValue?.target.value || "";
      }

      onChange(fieldName, newValue);
    },
    [onChange],
  );

  const renderField = React.useCallback(
    (
      singleField: ReviewField,
      fieldValue: any,
      errorMessage: string | undefined,
      meta: FieldMetaState<any>,
    ) => {
      let Component = null;

      const errorToDisplay =
        meta.touched && meta.error ? errorMessage : undefined;

      if (singleField.component === DatePicker) {
        Component = (
          <DatePicker
            id="datepicker"
            onChange={handleChange(singleField.fieldName)}
            externalError={errorToDisplay}
            maxDay={moment()}
          />
        );
      } else if (singleField.component === NumberInput) {
        Component = (
          <NumberInput
            name="numberInput"
            placeholder={singleField.placeholder}
            value={fieldValue}
            onChange={handleChange(singleField.fieldName)}
            error={errorToDisplay}
            maxLength={singleField.maxLength}
          />
        );
      } else if (singleField.component === TextInput) {
        Component =
          platform === Platform.android &&
          singleField.fieldName === "title" ? undefined : (
            <TextInput
              placeholder={singleField.placeholder}
              value={fieldValue}
              onChange={handleChange(singleField.fieldName)}
              error={errorToDisplay}
            />
          );
      } else if (singleField.component === TextArea) {
        Component = (
          <TextArea
            placeholder={singleField.placeholder}
            value={fieldValue}
            onChange={handleChange(singleField.fieldName)}
            error={errorToDisplay}
          />
        );
      } else {
        return null;
      }

      return (
        <FieldBox width={singleField.width} key={singleField.fieldName}>
          <DetailCard>
            <FormLabel name={singleField.fieldName} spacing={false}>
              {singleField.label}
            </FormLabel>
            {Component}
          </DetailCard>
        </FieldBox>
      );
    },
    [handleChange, platform],
  );

  return (
    <>
      <ReviewBox key={reviewElement.label}>
        <ReviewNumber>{reviewElement.label}</ReviewNumber>
        <Content>
          {reviewElement.fields.map((singleField) => {
            const fieldValue = value[singleField.fieldName] || null;
            const errorMessage = singleField.validators(fieldValue);

            if (
              platform === Platform.android &&
              singleField.fieldName === "title"
            ) {
              return null;
            }

            return (
              <Field
                key={singleField.fieldName}
                name={singleField.fieldName}
                validate={() => errorMessage}
              >
                {({ meta }) =>
                  renderField(singleField, fieldValue, errorMessage, meta)
                }
              </Field>
            );
          })}
        </Content>
      </ReviewBox>
    </>
  );
}

// -- STYLED
const Content = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 15px -16px 30px;
`;

const FieldBox = styled(Box)`
  padding: 0 16px;

  margin-bottom: 30px;
`;

const ReviewBox = styled("div")``;

const ReviewNumber = styled("div")`
  color: ${(props: any) => props.theme.colors.white};

  font-weight: bold;
`;
