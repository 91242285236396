import React, { FC, useState } from "react";
import styled from "styled-components";

import { UploadFile } from "components/forms/UploadInput";
import { ChangeData, getControlFallback } from "./ApplicationAssetsUpload";

import { trimExtension, truncate } from "utils/strings";

import { Box, Flex } from "@rebass/grid";
import { UploadImage } from "components/forms";
import { BOX_SIZE } from "components/forms/UploadImage/UploadImage";
import { isVideo } from "utils/assets";

// -- TYPES
interface Props {
  assets: UploadFile[];
  onControl: ((data: UploadFile) => void) | null;
  onChange: (data: ChangeData) => void;
  allowDragAndDrop?: boolean;
  controlAsset: UploadFile | null;
  autoControl?: boolean;
}

// -- COMPONENT
export const ApplicationAssetsList: FC<Props> = ({
  assets,
  onControl,
  allowDragAndDrop = false,
  controlAsset,
  onChange,
  autoControl,
}) => {
  const [draggedOverIndex, setDraggedOverIndex] = useState<number | null>(null);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
  const [dragOverSide, setDragOverSide] = useState<"l" | "r" | null>(null);

  const handleOnControl = (asset: UploadFile) =>
    onControl == null ? null : () => onControl(asset);

  const handleOnRemove = (assetIndex: number, isControl: boolean) => () => {
    const newAssets = assets.filter(
      (a: UploadFile, i: number) => i !== assetIndex,
    );

    onChange({
      assets: newAssets,
      control: isControl
        ? getControlFallback(newAssets, autoControl)
        : controlAsset,
    });
  };

  const formatFileName = (asset: UploadFile, isAssetVideo: boolean): string => {
    const { file, fileName } = asset;
    const nameString = isAssetVideo
      ? fileName || (file && file.name) || "YouTube Video"
      : fileName || (file && file.name) || "";

    return truncate(trimExtension(nameString), 20);
  };

  const onDragStart = (elementIndex: number) => () =>
    setDraggedIndex(elementIndex);

  const onDragOver = (index: number) => (e: any) => {
    const hoverXPosition = e.nativeEvent.offsetX;
    draggedOverIndex !== index && setDraggedOverIndex(index);

    if (hoverXPosition < (BOX_SIZE + 20) / 2) {
      return dragOverSide !== "l" && setDragOverSide("l");
    }

    return dragOverSide !== "r" && setDragOverSide("r");
  };

  const onDragEnd = (): void => {
    if (draggedOverIndex === null || draggedIndex === null) {
      return;
    }

    const elementToMove = assets[draggedIndex];
    const newAssetsList = assets.filter(
      (elem, elemeIndex) => elemeIndex !== draggedIndex,
    );

    let insertIndex =
      dragOverSide === "r" ? draggedOverIndex : draggedOverIndex - 1;
    if (draggedIndex > draggedOverIndex) {
      insertIndex =
        dragOverSide === "l" ? draggedOverIndex : draggedOverIndex + 1;
    }

    if (insertIndex < 0) {
      insertIndex = 0;
    }

    newAssetsList.splice(insertIndex, 0, elementToMove);

    setDraggedOverIndex(null);
    setDragOverSide(null);
    setDraggedIndex(null);
    onChange({
      assets: newAssetsList,
      control: null,
    });
  };

  const getDragableProps = (index: number, isAssetVideo: boolean) =>
    allowDragAndDrop
      ? {
          onDragStart: onDragStart(index),
          onDragOver: index !== draggedIndex ? onDragOver(index) : undefined,
          onDragEnd,
          draggable: true,
          dragOverSide: draggedOverIndex === index ? dragOverSide : null,
        }
      : {
          draggable: false,
        };

  const assetsArray = assets.map((asset, index: number) => {
    const isControl = onControl == null ? false : asset === controlAsset;
    const isAssetVideo = isVideo(asset);

    return (
      <DraggableBox
        key={`icon-${index}`}
        pr={10}
        pl={10}
        {...getDragableProps(index, isAssetVideo)}
      >
        <UploadImage
          label={formatFileName(asset, isAssetVideo)}
          image={(asset && asset.preview) || undefined}
          control={isControl}
          onControl={handleOnControl(asset)}
          onRemove={handleOnRemove(index, isControl)}
          isVideo={isAssetVideo}
        />
      </DraggableBox>
    );
  });

  return (
    <ScrollFlex width="100%" justifyContent="start">
      {assetsArray}
    </ScrollFlex>
  );
};

// -- STYLED
const ScrollFlex = styled(Flex)`
  overflow-x: auto;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px 0;
`;
const DraggableBox = styled(Box)<{ dragOverSide?: "l" | "r" | null }>`
  ${(props) =>
    props.dragOverSide &&
    `
    position: relative;

    &::before {
      content: "";
      width: ${BOX_SIZE}px;
      height: ${BOX_SIZE}px;
      position: absolute;
      top: 0;
      border: 2px dashed #454558;
      border-radius: 10px;
    }
  `}

  ${(props) =>
    props.dragOverSide === "l" &&
    `
    margin-left: ${BOX_SIZE + 20}px;
    &::before {
      left: -${BOX_SIZE + 20}px;
    }
  `}

  ${(props) =>
    props.dragOverSide === "r" &&
    `
    margin-right: ${BOX_SIZE + 20}px;
    &::before {
      left: ${BOX_SIZE + 20}px;
    }
  `}
`;
