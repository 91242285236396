import { createContext, useContext } from "react";

import UserStore, { TUserStore } from "stores/userStore";
import layouStore, { TLayoutStore } from "stores/layoutStore";

// -- TYPES
type TStoresContest = {
  userStore: TUserStore;
  layoutStore: TLayoutStore;
};

export const userStore = new UserStore();

// -- CONTEXT
export const storesContext = createContext<TStoresContest>({
  userStore,
  layoutStore: new layouStore(),
});

// -- CUSTOM HOOK
export const useStores = () => useContext(storesContext);
