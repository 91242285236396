import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const changeMDL = new ApiMutation({
  definition: gql`
    mutation changeApplicationMDL(
      $applicationId: ID!
      $minimumDetectableLift: Float!
    ) {
      updateMinimumDetectableLift(
        applicationId: $applicationId
        minimumDetectableLift: $minimumDetectableLift
      ) {
        isUpdated
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateAppPlatformProfile],
});

export interface ChangeMDLResponse {
  updateMinimumDetectableLift: {
    isUpdated: boolean;
  };
}
