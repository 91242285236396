import React from "react";
import styled from "styled-components";

import { MessageBox as BaseBox } from "components";

interface Props {
  content?: string;
}

function MessageBox({
  content = "Please note that if you add assets to a phase that has already started they will be queued and tested during the next test cycle.",
}: Props) {
  return <InfoBox>{content}</InfoBox>;
}

const InfoBox = styled(BaseBox)`
  margin-bottom: 60px;
`;

export default MessageBox;
