import React, { useContext } from "react";
import { RouteComponentProps } from "react-router";
import { useMutation, useQuery } from "@apollo/client";

import { Status, AppStatus, UPDATE_SIGNIFICANCE } from "api";

import {
  GET_APPLICATION_OVERVIEW,
  GET_APPLICATION_TITLE_AND_STATUS,
  GET_CLIENT_APPLICATIONS,
} from "api/queries";
import { UPDATE_REDIRECT_URL } from "api/mutations";
import { refreshApplicationBCR } from "v2/api/mutations/application/refreshApplicationBCR";

import ApplicationOverviewPage from "./ApplicationOverviewPage";
import { Spinner, Error } from "components";
import ApplicationDetail from "screens/Application/ApplicationDetail/ApplicationDetailContainer";

import { conversionRateLift } from "utils/statistics";
import getConfidence from "utils/getConfidence";
import { valueOrNA } from "utils/valueOrNA";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { ApplicationContext, ModalContext, useStores } from "contexts";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { updateAppPlatform as updateAppPlatformMutation } from "v2/api/mutations/application/updateAppPlatform";

interface Props extends RouteComponentProps<{ id: string }> {}

function ApplicationOverviewContainer({ history, match }: Props) {
  const applicationId = match.params.id;
  const { userStore } = useStores();
  const { data, error, loading, refetch } = useQuery(GET_APPLICATION_OVERVIEW, {
    variables: { applicationId, clientCode: userStore.clientCode },
    fetchPolicy: "no-cache",
  });
  const [updateRedirectUrl] = useMutation(UPDATE_REDIRECT_URL);
  const [updateApplicationStatus] = useMutation(
    updateAppPlatformMutation.definition,
  );

  const [refreshBCR] = useMutation(refreshApplicationBCR.definition);
  const [updateSignificance] = useMutation(UPDATE_SIGNIFICANCE);

  const handleError = useRequestErrorHandler();
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const applicationCtx = useContext(ApplicationContext);
  const setModal = useContext(ModalContext);

  if (error) {
    return <Error errorElement={error} />;
  }

  if (loading || !data?.application) {
    return <Spinner />;
  }

  applicationCtx.setCurrent(data.application);

  const onCancel = async () => {
    setFullPageLoader(true);

    const options = {
      variables: {
        clientCode: userStore.clientCode,
        appPlatformCode: data.application.code,
        appPlatform: {
          status:
            data.application.status === Status.inactive
              ? AppStatus.active
              : AppStatus.inactive,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_CLIENT_APPLICATIONS,
          variables: { clientCode: userStore.clientCode },
        },
        {
          query: GET_APPLICATION_TITLE_AND_STATUS,
          variables: { id: applicationId },
        },
      ],
    };

    const response = await handleError(updateApplicationStatus(options));

    response && history.push(authRoutes.APPLICATION_LIST.getUrlWithParams());
    setFullPageLoader(false);
  };

  const onRecalculateBcr = async () => {
    const options = {
      variables: { applicationId },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_CLIENT_APPLICATIONS,
          variables: { clientCode: userStore.clientCode },
        },
        {
          query: GET_APPLICATION_TITLE_AND_STATUS,
          variables: { id: applicationId },
        },
        {
          query: GET_APPLICATION_OVERVIEW,
          variables: { applicationId },
        },
      ],
    };

    const onConfirm = async () => {
      setFullPageLoader(true);
      await handleError(refreshBCR(options));
      setFullPageLoader(false);
    };

    setModal({
      type: "set",
      props: {
        title: "Refresh Baseline Conversion Rate",
        content:
          "Establish a New Baseline Conversion Rate. This function will temporarily stop all testing for this app and send traffic to the current Control page until a new baseline conversion rate (BCR) is established. The new BCR would be used for tests going forward. Any assets in the middle of testing would start over after BCR recalculation.",
        confirmText: "Continue",
        onConfirm,
      },
    });
  };

  const onSignificanceChange = async (value: string | number) => {
    if (typeof value === "string") {
      value = parseFloat(value);
    }

    setModal({
      type: "set",
      props: {
        title: "",
        content:
          "Changing the target confidence level will only affect future tests and not tests currently running. Are you sure you want to update the target confidence?",
        onConfirm: async () => {
          await updateSignificance({
            variables: {
              applicationId,
              significance: value,
            },
            awaitRefetchQueries: true,
            refetchQueries: ["application"],
          });
        },
      },
    });
  };

  const onRedirectUrlChange = async (value: string | number) => {
    try {
      await updateRedirectUrl({
        variables: {
          applicationId,
          redirectUrl: value,
        },
        awaitRefetchQueries: true,
        refetchQueries: ["application"],
      });
    } catch (err) {
      setModal({
        type: "set",
        props: {
          title: "Error",
          content: "Something went wrong",
        },
      });
    }
  };

  return (
    <>
      {fullPageLoader}
      <ApplicationDetail applicationId={data.application.id} history={history}>
        <ApplicationOverviewPage
          setModal={setModal}
          onCancel={onCancel}
          refetchData={refetch}
          loading={loading}
          application={data.application}
          currentPhaseType={data.currentPhase ? data.currentPhase.type : null}
          testsWeight={data.newTestsRoute.weight}
          experimentStatistics={data.experimentStatistics}
          topPageConversionLift={conversionRateLift(
            data.controlVariation?.conversionRate,
            data.topVariation?.conversionRate,
          )}
          controlPageUrl={data.controlVariation.url}
          topPageUrl={data.topVariation ? data.topVariation.url : ""}
          topPageConversionRate={valueOrNA(data.topVariation?.conversionRate)}
          zipFileUrl={data.topVariationZipFile}
          onRedirectUrlChange={onRedirectUrlChange}
          onSignificanceChange={onSignificanceChange}
          testUrl={data.testUrl}
          liveUrls={data.liveUrls}
          topPageDate={data.application.topPageDate}
          hasTopPage={data.application.hasTopPage}
          history={history}
          onRecalculateBcr={onRecalculateBcr}
          applicationGroup={data.applicationGroup}
          phaseTraffic={data.phaseTraffic}
          visitsToNewLearning={valueOrNA(data.visitsToNewLearning).toString()}
          trafficNeeded={data.trafficNeeded}
          controlConversionRate={data.controlVariation.conversionRate}
          topPageConfidence={getConfidence(data.topVariation)}
          currentIteration={data.currentIteration}
          facebookPixel={data.facebookPixel}
        />
      </ApplicationDetail>
    </>
  );
}

export default ApplicationOverviewContainer;
