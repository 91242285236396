import { UploadFile } from "components/forms/UploadInput";
import { Preview } from "api";
import { isArray } from "lodash";

export const isVideo = (asset?: UploadFile): boolean => {
  if (!asset) {
    return false;
  }

  return !!(
    asset.videoId ||
    (asset.file && asset.file.type.indexOf("video/") !== -1) ||
    asset.preview.includes("youtube")
  );
};

export const assetsArrayWithMaxVideosAllowed = (
  videoCount?: number,
  assets?: UploadFile[],
  maxVideos?: number,
) => {
  if (!assets) return null;

  const filteredArray = [];
  let videoCounter = videoCount || 0;
  for (const item of assets) {
    if (isVideo(item) && maxVideos && videoCounter < maxVideos) {
      videoCounter++;
      filteredArray.push(item);
    }
    if (!isVideo(item)) {
      filteredArray.push(item);
    }
  }

  return filteredArray;
};

export const convertPreviewToUploadFile = (
  previews?: Preview[],
): UploadFile[] => {
  if (!isArray(previews)) {
    return [];
  }

  return previews.map((singlePreview) => ({
    file: null,
    preview: singlePreview.url,
    id: singlePreview.id,
    fileName: singlePreview.fileName,
    videoId:
      singlePreview.fileName && singlePreview.isVideo
        ? "youtubevideo"
        : undefined,
  }));
};
