import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const createDescriptions = new ApiMutation({
  definition: gql`
    mutation createDescriptions($applicationId: ID!, $texts: [TextInputType]) {
      createDescriptions(applicationId: $applicationId, texts: $texts) {
        isCreated
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASOAsset],
});

export interface CreateDescriptionsResponse {
  createDescriptions: {
    isCreated: boolean;
  };
}

export const deleteDescription = new ApiMutation({
  definition: gql`
    mutation deleteDescription($id: ID!) {
      deleteDescription(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOAsset],
});

export interface DeleteDescriptionResponse {
  deleteDescription: {
    isDeleted: boolean;
  };
}

export const deactivateDescription = new ApiMutation({
  definition: gql`
    mutation deactivateDescription($id: ID!) {
      deactivateDescription(id: $id) {
        isDeactivated
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOTest],
});

export interface DeactivateDescriptionResponse {
  deactivateDescription: {
    isDeactivated: boolean;
  };
}
