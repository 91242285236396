/**
 * Scrolls to give coordinates
 * @param {ScrollBehavior} behavior of scroll
 * @param {number} px from top edge of document
 * @param {number} px from left edge of document
 */
export const scrollToCoordinates = (
  behavior: ScrollBehavior,
  top: number,
  left: number,
): void => {
  window.scrollBy({
    top,
    left,
    behavior,
  });
};
