import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PlatformContext } from "@upptic/module-directory";
import logoImage from "assets/images/logo.svg";

function NoEntitlementsPage(): JSX.Element {
  const platformStore = React.useContext(PlatformContext);
  const currentClientCode = platformStore?.currentClient?.code;
  return (
    <Wrapper>
      <Logo />
      <Message>
        It appears your account does not have access to an Upptic Product.
        <br />
        Please contact an Upptic Team Member for assistance.
        <br />
        <br />
        {`Visit your Account Page `}
        <StyledLink to={`/${currentClientCode}/settings/general`}>
          HERE{" "}
        </StyledLink>
        {`or your  User Profile `}
        <StyledLink to={`/${currentClientCode}/user/profile`}>HERE.</StyledLink>
      </Message>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  height: 150px;
  width: 173px;

  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Message = styled.p`
  margin-top: 20px;
  color: #fff;
  text-align: center;
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export default NoEntitlementsPage;
