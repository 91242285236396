import * as React from "react";
import { styled } from "@mui/system";
import { useMutation } from "@apollo/client";

import { ApplicationUserContext } from "contexts";
import { useStores } from "contexts/storeContext";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { MARK_ALL_AS_READ } from "api/mutations/notification";

import Icon, { Icons } from "components/Icon";
import { UserMenuItem } from "v2/components/TopBar/components/UserMenuItem";

// -- COMPONENT
export function MarkAllAsReadElement(): JSX.Element {
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const [markAllAsRead] = useMutation(MARK_ALL_AS_READ);

  const { userStore } = useStores();

  const handleError = useRequestErrorHandler();

  const applicationUserCtx = React.useContext(ApplicationUserContext);

  const displayMenu = (menuStatus: boolean) => () => setOpenMenu(menuStatus);

  const onMarkAllAsRead = async () => {
    if (applicationUserCtx.userId === userStore.userId) {
      await handleError(
        markAllAsRead({
          variables: {
            userId: applicationUserCtx.userId,
          },
          refetchQueries: ["application", "notifications"],
        }),
      );
      setOpenMenu(false);
    }
  };

  return (
    <MenuWrapper
      onMouseEnter={displayMenu(true)}
      onMouseLeave={displayMenu(false)}
    >
      <Icon icon={Icons.faEllipsisH} />
      {openMenu && (
        <Menu>
          <UserMenuItem onClick={onMarkAllAsRead}>
            Mark All As Read
          </UserMenuItem>
        </Menu>
      )}
    </MenuWrapper>
  );
}

// -- STYLED
const Menu = styled("div")<{ active?: boolean }>`
  position: absolute;
  right: 18px;

  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;

  color: ${(props: any) => props.theme.colors.white};

  width: 200px;

  overflow: hidden;

  background-color: #23233a;
`;

const MenuWrapper = styled("div")``;
