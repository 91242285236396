import i18n from "i18next";
import { locales } from "@upptic/module-directory";
import { initReactI18next } from "react-i18next";
import enLocale from "./en";
// tslint:disable-next-line:no-var-requires
const deepmerge = require("deepmerge");

export const resources = {
  en: enLocale,
} as const;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    lng: "en",
    resources: deepmerge(resources, locales),

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
