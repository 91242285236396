import React from "react";
import styled from "styled-components";

import { UploadFile } from "components/forms/UploadInput";

import { ApplicationAssetsUpload } from "components/application";

export interface StepData {
  assets: UploadFile[];
}

interface Props {
  data: StepData;
  onChange: (values: StepData) => void;
  maxUploadAssets?: number;
  title?: string;
}

class AdditionalPreview extends React.PureComponent<Props> {
  public render() {
    const { data, onChange, maxUploadAssets, title } = this.props;

    return (
      <ApplicationAssetsUpload
        header={title || "Additional Screens (Optional)"}
        assets={data.assets}
        controlAsset={null}
        buttonText="Upload Screens"
        emptyTitle="Upload Screens"
        emptyDescription="Use the button above to upload screens from your computer"
        onChange={onChange}
        onControl={null}
        maxUploadAssets={maxUploadAssets}
        autoControl={false}
        required={false}
        emptyHintFooter={
          <HintFooter>
            These screenshots are for static display only and will not be
            swapped as&nbsp;a&nbsp;part of the multivariate testing.
          </HintFooter>
        }
        allowDragAndDrop
      />
    );
  }
}

const HintFooter = styled.div`
  opacity: 0.8;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #9d9da8;
`;

export default AdditionalPreview;
