import gql from "graphql-tag";

export const UPDATE_CONTROL_ICON = gql`
  mutation updateControlIcon($applicationId: ID!, $control: Upload!) {
    updateControlIcon(applicationId: $applicationId, file: $control) {
      isUpdated
    }
  }
`;

export const UPDATE_CONTROL_PROMO_ART = gql`
  mutation updateControlPromoArt($applicationId: ID!, $control: Upload!) {
    updateControlPromoArt(applicationId: $applicationId, file: $control) {
      isUpdated
    }
  }
`;

export const UPDATE_CONTROL_PREVIEW = gql`
  mutation updateControlPreview(
    $applicationId: ID!
    $setFiles: [Upload]
    $setInfo: [PreviewInputType]
    $previewsFiles: [Upload]
    $previewsInfo: [PreviewInputType]
  ) {
    updateControlPreview(
      applicationId: $applicationId
      setFiles: $setFiles
      setInfo: $setInfo
      previewsFiles: $previewsFiles
      previewsInfo: $previewsInfo
    ) {
      isUpdated
    }
  }
`;

export const UPDATE_CONTROL_TITLE = gql`
  mutation updateControlTitle($applicationId: ID!, $control: String) {
    updateControlTitle(applicationId: $applicationId, text: $control) {
      isUpdated
    }
  }
`;

export const UPDATE_CONTROL_SUBTITLE = gql`
  mutation updateControlSubtitle($applicationId: ID!, $control: String) {
    updateControlSubtitle(applicationId: $applicationId, text: $control) {
      isUpdated
    }
  }
`;

export const UPDATE_CONTROL_DESCRIPTION = gql`
  mutation updateControlDescription($applicationId: ID!, $control: String) {
    updateControlDescription(applicationId: $applicationId, text: $control) {
      isUpdated
    }
  }
`;

export const UPDATE_CONTROL_ADDITIONAL_PREVIEWS = gql`
  mutation updateControlAdditionalPreviews(
    $applicationId: ID!
    $additionalPreviewsFiles: [Upload]
    $additionalPreviewsInfo: [PreviewInputType]
  ) {
    updateControlAdditionalPreviews(
      applicationId: $applicationId
      additionalPreviewsFiles: $additionalPreviewsFiles
      additionalPreviewsInfo: $additionalPreviewsInfo
    ) {
      isUpdated
    }
  }
`;

export const UPDATE_REVIEWS = gql`
  mutation updateApplicationReviews(
    $applicationId: ID!
    $reviews: [ReviewInputType]!
  ) {
    updateApplicationReviews(applicationId: $applicationId, reviews: $reviews) {
      application {
        id
      }
    }
  }
`;

export const DELETE_REVIEWS = gql`
  mutation deleteApplicationReviews($applicationId: ID!) {
    deleteApplicationReviews(applicationId: $applicationId) {
      application {
        id
      }
    }
  }
`;
