import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";

import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";

import { RESET_PASSWORD } from "api/mutations/auth";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import ForgotPasswordPage, { FormData } from "./ForgotPasswordPage";

function ForgotPasswordContainer() {
  const history = useHistory();
  const { state } = useLocation<{ userEmail?: string } | undefined>();

  const [forgotPassword] = useMutation(RESET_PASSWORD);

  const handleError = useRequestErrorHandler();

  const handleSubmit = async (data: FormData) => {
    const forgotPasswordResponse = await handleError(
      forgotPassword({
        variables: data,
      }),
    );

    forgotPasswordResponse &&
      history.push({
        pathname: nonAuthRoutes.AUTH_FORGOT_PASSWORD_CONFIRM.getUrlWithParams(),
        state: {
          userEmail: data.email,
        },
      });
  };

  return (
    <ForgotPasswordPage onSubmit={handleSubmit} userEmail={state?.userEmail} />
  );
}

export default ForgotPasswordContainer;
