import React from "react";
import styled from "styled-components";
import SignInWithGoogle from "assets/images/sign-in-with-google.svg";
import { ModalContext, useStores } from "contexts";
import { useSearchParam } from "react-use";

export default function SignInWithGoogleButton() {
  const { userStore } = useStores();
  const redirectUrl = useSearchParam('redirectUrl');

  return (
    <SignInWithGoogleWrapper>
      <div
        onClick={(e) => {
          e.preventDefault();
          userStore.setShowPlatformSpinner(true);
          let queryString = redirectUrl ? `?redirectUrl=${redirectUrl}` : '';
          // eslint-disable-next-line no-restricted-globals
          location.href = `/user/oauth/handleAuth/google${queryString}`;
        }}
        className="gsi-material-button"
        style={{ width: "100%" }}
      >
        <div className="gsi-material-button-state"></div>
        <div className="gsi-material-button-content-wrapper">
          <div className="gsi-material-button-icon">
            <img src={SignInWithGoogle} alt="Sign In With Google"/>
          </div>
          <span className="gsi-material-button-contents">
            Sign in with Google
          </span>
          <span style={{ display: "none" }}>Sign in with Google</span>
        </div>
      </div>
    </SignInWithGoogleWrapper>
  );
}


const SignInWithGoogleWrapper = styled.div`
  width: 100%;

  .gsi-material-button {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -webkit-appearance: none;
    background-color: #131314;
    background-image: none;
    border: 1px solid #747775;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #e3e3e3;
    cursor: pointer;
    font-family: "Roboto", arial, sans-serif;
    font-size: 14px;
    height: 40px;
    letter-spacing: 0.25px;
    outline: none;
    overflow: hidden;
    padding: 0 12px;
    position: relative;
    text-align: center;
    -webkit-transition: background-color 0.218s, border-color 0.218s,
      box-shadow 0.218s;
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
    min-width: min-content;
    border-color: #8e918f;
  }

  .gsi-material-button .gsi-material-button-icon {
    height: 20px;
    margin-right: 12px;
    min-width: 20px;
    width: 20px;
  }

  .gsi-material-button .gsi-material-button-content-wrapper {
    -webkit-align-items: center;
    align-items: center;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  .gsi-material-button .gsi-material-button-contents {
    -webkit-flex-grow: 0;
    flex-grow: 0;
    font-family: "Roboto", arial, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
  }

  .gsi-material-button .gsi-material-button-state {
    -webkit-transition: opacity 0.218s;
    transition: opacity 0.218s;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .gsi-material-button:disabled {
    cursor: default;
    background-color: #13131461;
    border-color: #8e918f1f;
  }

  .gsi-material-button:disabled .gsi-material-button-state {
    background-color: #e3e3e31f;
  }

  .gsi-material-button:disabled .gsi-material-button-contents {
    opacity: 38%;
  }

  .gsi-material-button:disabled .gsi-material-button-icon {
    opacity: 38%;
  }

  .gsi-material-button:not(:disabled):active .gsi-material-button-state,
  .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
    background-color: white;
    opacity: 12%;
  }

  .gsi-material-button:not(:disabled):hover {
    -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }

  .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
    background-color: white;
    opacity: 8%;
  }
`;
