import React, { useMemo, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import { MUIFormHeader, MUIConfirmationDialog } from "@upptic/module-ui";
import { useTranslation } from "react-i18next";
import { NotificationV2 } from "screens/Notification/consts/interfaces";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SingleNotificationCard from "./SingleNotificationCard";

type NotificationsPanel = {
  loading: boolean;
  notifications: NotificationV2[];
  onNotificationClick: (entryCode: string) => void;
  onDismissClick: (entryCode: string, isRead: boolean) => void;
  offset: number;
  setOffset: any;
  totalNotifications: number;
  onDismissAll: () => void;
  limit: number;
};

const NotificationPanel = ({
  notifications,
  loading,
  onNotificationClick,
  onDismissClick,
  offset,
  setOffset,
  totalNotifications,
  onDismissAll,
  limit,
}: NotificationsPanel) => {
  const { t } = useTranslation("miniHub");
  const { t: tg } = useTranslation("general");
  const [dismissModalOpen, setDismissModalOpen] = useState(false);
  const renderNotificationCards = useMemo(() => {
    if (notifications && notifications?.length !== 0) {
      return notifications.map((notification: NotificationV2) => (
        <SingleNotificationCard
          key={notification.code}
          notification={notification}
          onClick={() => onNotificationClick(notification.code)}
          onDismissClick={(isRead) => onDismissClick(notification.code, isRead)}
        />
      ));
    }
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography>{t<string>("notifications.none")}</Typography>
      </Box>
    );
  }, [notifications]);

  const handleScroll = (e: any) => {
    const isMoreNotifications = offset + limit < totalNotifications;
    const bottom =
      Math.abs(
        e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight),
      ) <= 1;
    if (bottom && isMoreNotifications) {
      setOffset((prevState: any) => prevState + limit);
    }
  };

  const handleDismissAllConfirm = () => {
    setDismissModalOpen(false);
    if (offset !== 0) setOffset(0);
    onDismissAll();
  };

  return (
    <Grid
      container
      height="100%"
      sx={{
        border: "1px solid rgba(255, 255, 255, 0.23)",
      }}
    >
      <Grid item xs={12}>
        <MUIFormHeader
          title={t("notifications.title")}
          buttons={[
            {
              icon: <DoneAllIcon />,
              onClick: () => setDismissModalOpen(true),
              tooltip: {
                title: t("notifications.dismissAll"),
              },
            },
          ]}
          sx={{
            border: "1px solid rgba(255, 255, 255, 0.23)",
            padding: "8px",
            minHeight: "45px",
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          minHeight: 64,
          height: "auto",
          maxHeight: 436,
        }}
        p={1}
      >
        <Box
          onScroll={(e) => handleScroll(e)}
          sx={{
            height: "100%",
            overflowY: "auto",
          }}
        >
          {renderNotificationCards}
          {loading ? (
            <Card
              sx={{
                "height": "56px",
                "display": "flex",
                "justifyContent": "center",
                "alignItems": "center",
                "&:not(last-of-type)": {
                  marginBottom: "8px",
                },
                "padding": "8px 8px 8px 8px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Typography>{t<string>("notifications.loading")}</Typography>
            </Card>
          ) : null}
        </Box>
      </Grid>
      <MUIConfirmationDialog
        open={dismissModalOpen}
        onClose={() => setDismissModalOpen(false)}
        title={t("notifications.dismissAll")}
        text={t("notifications.dismissText")}
        secondaryText={t("notifications.effect")}
        buttons={[
          {
            text: tg("cancel"),
            onClick: () => setDismissModalOpen(false),
          },
          {
            text: tg("confirm"),
            onClick: () => handleDismissAllConfirm(),
          },
        ]}
      />
    </Grid>
  );
};

export default NotificationPanel;
