import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useMutation } from "@apollo/client";

import { AcceptInviteMutation } from "v2/api/mutations/membership/activateMembership";
import { useStores } from "contexts/storeContext";

import { useSignIn } from "v2/hooks/useSignIn";
import { Spinner } from "components";
import ActivateAccountErrorPage from "./ActivateAccountErrorPage";
import SignInPage from "../SignIn/SignInPage";
import { Redirect } from "react-router-dom";

interface Props extends RouteComponentProps<{ token: string }> {}

function SignUpActivateContainer({ match }: Props) {
  const variables = { token: match.params.token };
  const { userStore } = useStores();
  const [loading, setLoading] = useState(true);

  const [signUpActivate, { data }] = useMutation(AcceptInviteMutation, {
    variables,
  });
  const [activationError, setActivationError] = useState(false);

  const handleSubmit = useSignIn();

  useEffect(() => {
    const activate = async () => {
      try {
        setLoading(true);
        await signUpActivate();
        if (userStore.isLoggedIn) await userStore.loadUser();
      } catch (err) {
        setActivationError(true);
      } finally {
        setLoading(false);
      }
    };

    activate();
  }, [signUpActivate, userStore]);

  if (loading) {
    return <Spinner />;
  }

  if (activationError) {
    return <ActivateAccountErrorPage />;
  }

  if (userStore.isLoggedIn) {
    return <Redirect to={"/"} />;
  }

  return <SignInPage email="" isActivated={!!data} onSubmit={handleSubmit} />;
}

export default SignUpActivateContainer;
