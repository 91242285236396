import React, { FC, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { GET_OVERDUE_INVOICE_DETAILS } from "api/queries/billing";
import { REFRESH_INVOICE } from "api/mutations/billing";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { ApplicationContext, ModalContext } from "contexts";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { convertToCurrency } from "utils/numbers";
import { convertDateToDisplayFormat } from "utils/dates";

import theme from "components/themes";

import { Title, Spinner, Error } from "components";
import { FailedPaymentsForm } from "./FailedPaymentsForm";

// -- TYPES
type TResponse = {
  overdueInvoiceDetails: {
    clientSecret?: string;
    wasPaid: boolean;
    invoice?: {
      id: string;
      total: number;
      invoiceNo: string;
      billingStartDate: string;
      billingEndDate: string;
    };
  };
};

// -- COMPONENT
const FailedPayments: FC<RouteComponentProps> = ({ history }) => {
  const stripe = useStripe();
  const elements = useElements();
  const setModal = useContext(ModalContext);
  const applicationCtx = useContext(ApplicationContext);

  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const { data, error, loading } = useQuery<TResponse>(
    GET_OVERDUE_INVOICE_DETAILS,
    {
      fetchPolicy: "no-cache",
    },
  );

  const [refreshInvoice] = useMutation(REFRESH_INVOICE);

  const setTokenAndRedirect = useCallback(async () => {
    history.push(authRoutes.APPLICATION_LIST.getUrlWithParams());
  }, [history]);

  useEffect(() => {
    data?.overdueInvoiceDetails.wasPaid &&
      setModal({
        type: "set",
        props: {
          title: "The invoice has already been paid",
          content: "You will be redirected to your applications list",
          onHide: setTokenAndRedirect,
          confirmText: "Ok",
        },
      });
  }, [data, setModal, setTokenAndRedirect]);

  useEffect(() => {
    applicationCtx.current && applicationCtx.setCurrent(null);
  }, [applicationCtx]);

  const invoice = data?.overdueInvoiceDetails.invoice;

  const onSubmit = async () => {
    setFullPageLoader(true);
    const clientSecret = data?.overdueInvoiceDetails.clientSecret;

    if (!stripe || !elements || !clientSecret) {
      setModal({
        type: "set",
        props: {
          content:
            "Unable to initialize stripe payments. Please contact the administrator.",
        },
      });

      return setFullPageLoader(false);
    }

    const card = elements.getElement(CardElement);

    if (!card) {
      setModal({
        type: "set",
        props: {
          content:
            "Unable to collect stripe card. Please contact the administrator.",
        },
      });

      return setFullPageLoader(false);
    }

    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card,
      },
    });

    if (paymentResult.error) {
      setFullPageLoader(false);
      setModal({
        type: "set",
        props: {
          title: "There was a problem with payment",
          content: paymentResult.error.message,
        },
      });
    }

    try {
      await refreshInvoice({ variables: { invoiceId: invoice?.id } });
    } catch (e) {
      setFullPageLoader(false);
      setModal({
        type: "set",
        props: {
          content:
            "Unable to refresh invoice status. Please do not repeat the payment and please contact the administrator",
        },
      });
      return;
    }

    setFullPageLoader(false);
    setModal({
      type: "set",
      props: {
        title: "Your payment has been accepted",
        content: "You will be redirected to your applications list",
        onHide: setTokenAndRedirect,
        confirmText: "Ok",
      },
    });
  };

  const renderInvoiceDetails = () => {
    const billingStartDate = invoice?.billingStartDate
      ? convertDateToDisplayFormat(invoice.billingStartDate)
      : "-";
    const billingEndDate = invoice?.billingEndDate
      ? convertDateToDisplayFormat(invoice.billingEndDate)
      : "-";
    const invoiceTotal = convertToCurrency((invoice?.total || 0) / 100);

    return (
      <Text bold>
        Invoice no. {invoice?.invoiceNo} for period {billingStartDate} -{" "}
        {billingEndDate} <br />
        Your arrears are {invoiceTotal}
      </Text>
    );
  };

  if (loading) {
    return <Spinner />;
  }

  if (error || !data?.overdueInvoiceDetails) {
    return <Error errorElement={error?.message || ""} />;
  }

  return (
    <>
      {fullPageLoader}
      <StyledTitle>Enter New Card To Pay Your Overdued Invoice</StyledTitle>
      <Text>
        Unfortunately, your payment for the last invoice is overdue. Please
        input the new card number to pay for arrears.
        <br /> After that, you will regain access to your apps.
      </Text>
      {renderInvoiceDetails()}
      <FailedPaymentsForm onSubmit={onSubmit} />
    </>
  );
};

// -- STYLES
const Text = styled.p<{ bold?: boolean }>`
  color: ${theme.colors.white};

  text-align: center;

  margin-bottom: 30px;

  ${(props: any) => props.bold && `font-weight: bold;`}
`;
const StyledTitle = styled(Title)`
  justify-content: center;
`;

export const FailedPaymentsPage = withRouter(FailedPayments);
