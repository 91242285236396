import * as React from "react";
import styled from "styled-components";

import logoImage from "assets/images/logo.svg";

// -- COMPONENT
function AccessDenied(): JSX.Element {
  return (
    <Wrapper>
      <Logo />
      <Message>
        Access to this page was denied.
        <br />
        Contact your administrator to get privileges.
      </Message>
    </Wrapper>
  );
}

// -- STYLED
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const Logo = styled.div`
  height: 150px;
  width: 173px;

  background-image: url(${logoImage});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Message = styled.p`
  margin-top: 20px;
  color: #fff;
  text-align: center;
`;

export default AccessDenied;
