import React, { useContext, useState } from "react";

import { useSignIn } from "v2/hooks/useSignIn";
import { ApplicationContext } from "contexts";

import SignInPage from "./SignInPage";
import OtpPage from "./OtpPage";

function SignInContainer() {
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const [otpInitalValues, setOtpInitalValues] = useState(false);
  const applicationCtx = useContext(ApplicationContext);
  applicationCtx.setCurrent(null);

  const handleSubmit = useSignIn({
    onOtpRequired: (data) => {
      setOtpInitalValues(data);
      setShowOtpDialog(true);
    },
  });

  if (showOtpDialog) {
    return <OtpPage onSubmit={handleSubmit} initialValues={otpInitalValues} />;
  }
  return <SignInPage onSubmit={handleSubmit} />;
}

export default SignInContainer;
