export const truncate = (value: string, length: number) => {
  const dots = value.length > length ? "..." : "";

  return `${value.substring(0, length - dots.length)}${dots}`;
};

export const trimExtension = (value: string) => {
  return value.replace(/\.[^/.]+$/, "");
};

export const getExtension = (value: string) => {
  return value.substring(value.lastIndexOf("."), value.length) || value;
};

export const camelToSnakeCase = (stringToConvert: string) => {
  return (
    stringToConvert[0].toLowerCase() +
    stringToConvert
      .slice(1, stringToConvert.length)
      .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
  );
};

export default { truncate, trimExtension, getExtension };
