import React from "react";
import { Box, Flex } from "@rebass/grid";

import { Modal } from "components";
import { Button } from "components/forms";

interface Props {
  title: string;
  content: React.ReactNode;
  confirmText?: string;
  show: boolean;
  onHide: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  width?: number;
  disableCancel?: boolean;
}

function ConfirmationModal({
  title,
  content,
  confirmText = "OK",
  show,
  onHide,
  onConfirm,
  onCancel,
  width,
  disableCancel = false,
}: Props) {
  const cancel = () => {
    if (disableCancel) return;
    onHide();
    if (onCancel) {
      onCancel();
    }
  };

  const confirm = () => {
    onHide();
    onConfirm && onConfirm();
  };

  return (
    <Modal
      show={show}
      onHide={cancel}
      title={title}
      width={width}
      footer={
        <Flex alignItems="center" justifyContent="flex-end">
          {onConfirm ? (
            <>
              <Box mr={20}>
                <Button link onClick={cancel}>
                  Cancel
                </Button>
              </Box>
              <Box>
                <Button onClick={confirm}>{confirmText}</Button>
              </Box>
            </>
          ) : (
            <Box>
              <Button onClick={onHide}>{confirmText}</Button>
            </Box>
          )}
        </Flex>
      }
    >
      {content}
    </Modal>
  );
}

export default ConfirmationModal;
