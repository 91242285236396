import React, { useContext, useMemo, useState } from "react";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { setCookie } from "react-use-cookie";
import { observer } from "mobx-react";

import { getInitalUrl } from "v2/routes/routingHelper";

import { switchClientMutation } from "v2/api/mutations/membership/switchClient";
import {
  getCurrentUserClients,
  GetCurrentUserClientsResponse,
} from "v2/api/queries/user/getCurrentUserClients";
import { CurrentUserClient } from "v2/api/queries/user/getCurrentUser";

import config from "config";
import { client } from "api";
import { UserPermissions } from "v2/api/types/UserPermissions";
import { SelectInput, Switch } from "components/forms";
import { useStores } from "contexts/storeContext";
import { useRequestErrorHandler } from "../../hooks/useRequestErrorHandler";
import { components, OptionProps } from "react-select";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";
import { Box, Typography } from "@mui/material";
import { useIsMobile } from "../SideMenuV2/helper";

// -- COMPONENT
const ClientSwitcher: React.FC = () => {
  const { t } = useTranslation("navigation");
  const isMobile = useIsMobile();
  const { userStore } = useStores();
  const platformStore = useContext<PlatformStore>(PlatformContext);
  const canFilterClients = platformStore.currentUserFrontendPermissions.includes(
    UserPermissions.readClientUppticEntitlementFilter,
  );
  const handleError = useRequestErrorHandler();
  const history = useHistory();
  const prevFilterClients = localStorage.getItem("filterClients");
  const [filterClients, setFilterClients] = useState(
    prevFilterClients ? prevFilterClients === "true" : canFilterClients,
  );

  const { data: filteredClients, refetch } = useQuery<
    GetCurrentUserClientsResponse
  >(getCurrentUserClients, {
    fetchPolicy: "no-cache",
    skip: !canFilterClients,
    variables: {
      uppticManagedOnly: true,
      filter: { "entitlement.filterGroup": { $eq: "uppticMgt" } },
    },
  });

  const [switchClient] = useMutation(switchClientMutation);

  const onChangeMembership = async (clientCode: string) => {
    platformStore.setProcessingSwitchClient(true);
    await handleError(switchClient({ variables: { clientCode } }));
    setCookie(config.lastLoginClientCookieName, clientCode, { days: 365 });
    platformStore.setCurrentClientCode(clientCode);
    await userStore.loadUser(true);

    let appPlatformASO = platformStore.appPlatforms as any[];
    appPlatformASO = appPlatformASO.filter(({ asoAppId }) => asoAppId);
    platformStore.setSelectedAppPlatform(appPlatformASO[0] as any);
    platformStore.setSelectedApplication(platformStore.applications[0] as any);
    history.replace(
      getInitalUrl(
        platformStore.modules,
        clientCode,
        platformStore.currentModule,
      ),
    );

    await client.resetStore();
    platformStore.setProcessingSwitchClient(false);
  };

  const handleClientFilterToggle = (val: boolean) => {
    setFilterClients(val);
    if (val) refetch();
    localStorage.setItem("filterClients", String(val));
  };

  const Option = (props: OptionProps<{ [key: string]: any }, boolean>) => {
    return (
      <components.Option {...props}>
        <OptionContenWrapper>{props.data.name}</OptionContenWrapper>
      </components.Option>
    );
  };

  let clients = platformStore.clients;
  if (filterClients && filteredClients) {
    clients = filteredClients.currentUserClients;
    // ensure current client in arr
    if (
      !clients.some(({ code }) => code === platformStore.currentClient?.code)
    ) {
      clients = [platformStore.currentClient as CurrentUserClient, ...clients];
    }
  }

  clients = useMemo(() => {
    if (!platformStore.isDemoModeEnabled) return clients;
    console.log("userStore.demoClientCode", platformStore.demoClientCode);
    return clients.filter(({ code }) =>
      [
        "c8fabyo", // prod demo shop
        "ctth5vg", // dev demo shop
        platformStore.demoClientCode,
      ].includes(code),
    );
  }, [clients, platformStore.isDemoModeEnabled, platformStore.demoClientCode]);

  if (!clients?.length) {
    return null;
  }

  if (clients?.length === 1) {
    return <CompanyNameWrapper>{clients[0].name}</CompanyNameWrapper>;
  }

  return (
    <>
      <Wrapper>
        <SelectInput
          labelKey={"name"}
          valueKey={"id"}
          value={userStore.clientCode}
          options={clients
            ?.filter(
              ({ status, code }) =>
                status === "active" ||
                platformStore.currentClient?.code === code,
            )
            .map(({ name, code }) => ({
              id: code,
              name,
            }))}
          onChange={onChangeMembership}
          components={{ Option }}
          isSearchable={true}
        />
      </Wrapper>

      {canFilterClients && !platformStore.isDemoModeEnabled && (
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "row" : "column",
            alignItems: isMobile ? "center" : "flex-start",
            width: "fit-content",
          }}
        >
          <Switch value={filterClients} onChange={handleClientFilterToggle} />
          {isMobile ? (
            <Typography sx={{ marginLeft: "8px" }}>
              {t<string>("switchTitle")}
            </Typography>
          ) : null}
        </Box>
      )}
    </>
  );
};

// -- STYLED
const Wrapper = styled("div")`
  position: relative;
  width: 200px;
`;

const CompanyNameWrapper = styled("div")`
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props: any) => props.theme.colors.lightGrey};
`;

const OptionContenWrapper = styled("div")`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default observer(ClientSwitcher);
