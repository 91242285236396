import gql from "graphql-tag";
import {
  CURRENT_ITERATION_FRAG,
  CONTROL_VARIATION_PREVIEWS_FRAG,
  CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG,
} from "api/fragments";

export const GET_APPLICATION_ICONS = gql`
  query applicationIcons($applicationId: ID!) {
    icons(applicationId: $applicationId) {
      id
      fileName
      isControl
      file {
        url
        name
      }
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
      historicalConversionData {
        startDate
        endDate
        visits
        installs
        cvr
        action
      }
      previewLink
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
      startedAt
      finishedAt
    }
    application(id: $applicationId) {
      id
      baselineConversionRate
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;

export const GET_APPLICATION_PROMO_ARTS = gql`
  query applicationPromoArts($applicationId: ID!) {
    promoArts(applicationId: $applicationId) {
      id
      fileName
      isControl
      file {
        url
        name
      }
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
      historicalConversionData {
        startDate
        endDate
        visits
        installs
        cvr
        action
      }
      previewLink
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
      startedAt
      finishedAt
    }
    application(id: $applicationId) {
      id
      baselineConversionRate
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;

export const GET_APPLICATION_PREVIEWS = gql`
  query applicationPreviews($applicationId: ID!, $position: Int) {
    previews(applicationId: $applicationId, position: $position) {
      id
      fileName
      isControl
      position
      url
      file {
        name
      }
      conversionRate
      conversion
      confidence
      visits
      status
      isVideo
      isSet
      isTop
      set {
        id
        name
      }
      previewLink
      embeddedVideoUrl
      historicalConversionData {
        startDate
        endDate
        visits
        installs
        cvr
        action
      }
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
      startedAt
      finishedAt
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;

export const GET_APPLICATION_TITLES = gql`
  query applicationTitles($applicationId: ID!) {
    titles(applicationId: $applicationId) {
      id
      isControl
      name
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
      historicalConversionData {
        startDate
        endDate
        visits
        installs
        cvr
        action
      }
      previewLink
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
      startedAt
      finishedAt
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;

export const GET_APPLICATION_SUBTITLES = gql`
  query applicationSubtitles($applicationId: ID!) {
    subtitles(applicationId: $applicationId) {
      id
      isControl
      name
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
      historicalConversionData {
        startDate
        endDate
        visits
        installs
        cvr
        action
      }
      previewLink
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
      startedAt
      finishedAt
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;

export const GET_APPLICATION_DESCRIPTIONS = gql`
  query applicationDescriptions($applicationId: ID!) {
    descriptions(applicationId: $applicationId) {
      id
      isControl
      name
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
      historicalConversionData {
        startDate
        endDate
        visits
        installs
        cvr
        action
      }
      previewLink
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
      startedAt
      finishedAt
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;

export const GET_APPLICATION_CONTROL_SCREENS = gql`
  query controlVariation($id: ID!) {
    controlVariation(applicationId: $id) {
      id
      ...CONTROL_VARIATION_PREVIEWS_FRAG
    }
  }
  ${CONTROL_VARIATION_PREVIEWS_FRAG}
`;

export const GET_ADDITIONAL_PREVIEWS = gql`
  query additionalPreviews($id: ID!) {
    controlVariation(applicationId: $id) {
      id
      ...CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG
    }
  }
  ${CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG}
`;

// TODO: przemyśleć jak ogrywać fragmentami to lub zostawiać query pod jedno
export const GET_TESTING_AND_QUEUED_ASSETS_PAGE_DATA = gql`
  query getTestingAndQueuedAssetsPageData(
    $applicationId: ID!
    $statuses: [String]!
  ) {
    application(id: $applicationId) {
      id
      name
      platform
      status
      createdAt
    }
    assetsByStatus(applicationId: $applicationId, statuses: $statuses) {
      icons {
        id
        fileName
        isControl
        file {
          url
          name
        }
        status
        previewLink
      }
      promoArts {
        id
        fileName
        isControl
        file {
          url
          name
        }
        status
        previewLink
      }
      featureGraphics {
        id
        fileName
        isControl
        file {
          url
          name
        }
        status
        previewLink
      }
      previews {
        id
        fileName
        isControl
        position
        url
        file {
          url
          name
        }
        status
        isVideo
        isSet
        set {
          id
          name
        }
        previewLink
        embeddedVideoUrl
      }
      titles {
        id
        isControl
        name
        status
        previewLink
      }
      subtitles {
        id
        isControl
        name
        status
        previewLink
      }
      descriptions {
        id
        isControl
        name
        status
        previewLink
      }
    }
  }
`;

export const GET_CURRENT_ITERATION = gql`
  query currentIteration($applicationId: ID!) {
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;
