import React, { useState, useEffect, useCallback, memo } from "react";
import { useLazyQuery } from "@apollo/client";
import styled from "styled-components";

import { prepareChartConfiguration } from "utils/charts";

import { ChartConfiguration } from "api/models";

import { Spinner } from "components";
import Chart from "./Chart";
import ChartTooltip from "./ChartTooltip";
import ChartFilters from "./ChartFilters";
import ChartXAxisTick from "./ChartXAxisTick";
import ChartStatistics from "./ChartStatistics";

import { AvailableDisplayModes } from "./constants/types";

// -- TYPES
interface ChartWithControlsProps {
  applicationId: string;
  query: any;
  conversionDataKey: string;
}

// -- COMPONENT
const ChartWithControls: React.FC<ChartWithControlsProps> = ({
  applicationId,
  query,
  conversionDataKey,
}) => {
  const [
    chartConfiguration,
    setChartConfiguration,
  ] = useState<ChartConfiguration | null>(null);

  const [chartDisplayMode, setChartDisplayMode] = useState<
    AvailableDisplayModes
  >("day");

  const [experimentStatistics, setExperimentStatistics] = useState<{
    totalVisits: number;
    totalConversions: number;
    conversionRate: number;
  }>({ totalVisits: 0, totalConversions: 0, conversionRate: 0 });

  const [requestForConversionRates, { loading, data, error }] = useLazyQuery(
    query,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
    },
  );

  useEffect(() => {
    if (data) {
      setChartConfiguration(
        prepareChartConfiguration(
          data[conversionDataKey],
          data.applicationShipDates,
          data.applicationUserActions,
          chartDisplayMode,
        ),
      );
      setExperimentStatistics(data.graphStatistics);
    }
  }, [chartDisplayMode, data, conversionDataKey]);

  const getConversionRates = useCallback(
    (from: string, to: string, displayMode: AvailableDisplayModes): void => {
      setChartDisplayMode(displayMode);
      requestForConversionRates({
        variables: {
          applicationId,
          dateFrom: from,
          dateTo: to,
        },
      });
    },
    [applicationId, requestForConversionRates],
  );

  return (
    <>
      <ChartFilters onFilterChange={getConversionRates} />
      {loading && <Spinner style={{ marginTop: 0 }} />}
      {error && (
        <ErrorWrapper>
          An error occured.
          <br />
          Please select other date range.
          <br />
          If the problem continues, contact with administrator.
        </ErrorWrapper>
      )}
      {chartConfiguration && !loading && !error && (
        <>
          <Chart
            xAxisComponent={<ChartXAxisTick displayMode={chartDisplayMode} />}
            tooltipComponent={<ChartTooltip displayMode={chartDisplayMode} />}
            // by default we turn off traffic line
            initialDisabledDataIndexes={["traffic.cvr"]}
            {...chartConfiguration}
          />
          <ChartStatistics {...experimentStatistics} />
        </>
      )}
    </>
  );
};

// --STYLES
const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default memo(ChartWithControls);
