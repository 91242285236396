import * as React from "react";
import { Flex, Box } from "@rebass/grid";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";
import { Application, Route, Status } from "api/models";
import {
  cumulativeControlDataReset,
  CumulativeControlDataResetResponse,
} from "v2/api/mutations/application/cumulativeControlDataReset";
import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { ApplicationContext, ModalContext } from "contexts";

import { Section, HoverLink, ExternalLink } from "components";

import { Button, Switch } from "components/forms";
import { TrafficVolumeSection } from "./components/TrafficVolumeSection";
import { StaticTable } from "v2/components/Table/StaticTable";
import { ResetControlContent } from "./components/ResetControlSection";
import { MinimumDetectableLiftSection } from "./components/MinimumDetectableLiftSection";
import { TdComponent } from "v2/components/Table/components/TdComponent";
import { TrComponent } from "v2/components/Table/components/TrComponent";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

interface Props {
  liveUrls: string[];
  controlRoute: Route;
  newTestsRoute: Route;
  actualStorePageRoute: Route;
  customRoutes: Route[];
  onChange: ((route: Route) => void) | null;
  onDelete: ((route: Route) => void) | null;
  onCreate: ((route: Route) => void) | null;
  redirectToAppStoreStatus: boolean;
  onRedirectChange: ((isRedirect: boolean) => void) | null;
  application: Application;
}

function ApplicationRoutingPage({
  liveUrls,
  controlRoute,
  newTestsRoute,
  actualStorePageRoute,
  customRoutes,
  onChange,
  onDelete,
  onCreate,
  redirectToAppStoreStatus,
  onRedirectChange,
  application,
}: Props) {
  const setModal = React.useContext(ModalContext);
  const applicationCtx = React.useContext(ApplicationContext);
  const history = useHistory();
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const liveUrlColumns = React.useMemo<SingleTableColumn<unknown>[]>(
    () => [
      {
        Header: "URL Link",
        accessor: "link",
      },
    ],
    [],
  );

  const [cumulativeControlDataResetFunction] = useMutation<
    CumulativeControlDataResetResponse
  >(cumulativeControlDataReset.definition);

  const liveUrlRows = React.useMemo(() => {
    const url = liveUrls[0];
    return [
      {
        link: (
          <HoverLinkWrapper>
            <HoverLink value={url} copyable={true} />
          </HoverLinkWrapper>
        ),
      },
    ];
  }, [liveUrls]);

  const postTestSectionRows = React.useMemo(() => {
    const onChangeRedirect = onRedirectChange
      ? onRedirectChange
      : (val: boolean) => {};

    return [
      {
        label:
          "Once all testing finishes, redirect traffic directly to the destination App Store URL",
        value: (
          <Switch
            onChange={onChangeRedirect}
            value={redirectToAppStoreStatus}
            displayLabels
            disabled={!onRedirectChange}
          />
        ),
      },
    ];
  }, [onRedirectChange, redirectToAppStoreStatus]);

  const editable = application.status !== Status.inactive;

  const handleModalConfirm = React.useCallback(
    (reinsertTopAssets: boolean) => async () => {
      if (applicationCtx.current) {
        setFullPageLoader(true);
        await cumulativeControlDataResetFunction({
          variables: {
            applicationId: applicationCtx.current.id,
            reinsertTopAssets,
          },
        });

        history.push(
          authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({
            id: application.id,
          }),
        );
        setFullPageLoader(false);
      }
    },
    [
      applicationCtx,
      application.id,
      cumulativeControlDataResetFunction,
      history,
      setFullPageLoader,
    ],
  );

  const handleChange = React.useCallback(
    (value: boolean) => {
      setModal({
        type: "update",
        props: {
          title: "Reset Control Data & Top Page",
          content: (
            <ResetControlContent value={value} onChange={handleChange} />
          ),
          onConfirm: handleModalConfirm(value),
          confirmText: "Yes",
        },
      });
    },
    [setModal, handleModalConfirm],
  );

  const resetDataOnClick = React.useCallback(() => {
    setModal({
      type: "set",
      props: {
        title: "Reset Control Data & Top Page",
        content: <ResetControlContent value={false} onChange={handleChange} />,
        onConfirm: handleModalConfirm(false),
        confirmText: "Yes",
      },
    });
  }, [handleChange, handleModalConfirm, setModal]);

  const resetControlTooltip = () => {
    return (
      <div>
        <span>
          As CVR calculation for the Control page is based on cumulative data,
          It is advisable to periodically reset Control data to refresh testing
          from the Control page again from 0 data.
        </span>
        <br />
        <br />
        <span>
          Historical data and performance will not be lost in this process, but
          you may want to screen capture anything important since the data
          presentation will change.
        </span>
      </div>
    );
  };

  const postTestCompletionTooltip = () => {
    return (
      <div>
        By default, once all testing finishes, all additional traffic will be
        redirected to the destination App Store URL. But you can override this
        by having traffic continue to go to the Upptic landing page.
      </div>
    );
  };

  const columns = React.useMemo<
    SingleTableColumn<{ label: unknown; value: unknown }>[]
  >(
    () => [
      {
        Header: "label",
        accessor: "label",
        tdElement: CustomLabelTd as any,
      },
      {
        Header: "value",
        accessor: "value",
        tdElement: CustomValueTd as any,
      },
    ],
    [],
  );

  const controlDataResetRows = React.useMemo(() => {
    return [
      {
        label: (
          <>
            Reset Control Data & Top Page.{" "}
            <ExternalLink
              value={
                "https://upptic.zendesk.com/hc/en-us/articles/360061016011-Periodically-Resetting-Control-Data"
              }
            >
              See Help article
            </ExternalLink>
            .
          </>
        ),
        value: (
          <SmallButton onClick={resetDataOnClick} link={true}>
            Reset Data
          </SmallButton>
        ),
      },
    ];
  }, [resetDataOnClick]);

  return (
    <>
      {fullPageLoader}
      <Flex flexDirection="column">
        <Box>
          <Section title="Live URLs" transparent style={{ marginBottom: 50 }}>
            <StaticTable columns={liveUrlColumns} data={liveUrlRows} />
          </Section>

          {application.currentBcr === null &&
            application.status !== Status.calculatingBcr && (
              <MinimumDetectableLiftSection
                mdl={application.minimumDetectableLift}
                applicationId={application.id}
              />
            )}

          <Section
            title="Post-Test Completion Redirection"
            titleTooltip={postTestCompletionTooltip()}
            transparent
            style={{ marginBottom: 50 }}
          >
            <StaticTable
              columns={columns}
              data={postTestSectionRows}
              displayHeader={false}
              trElement={CustomTr}
            />
          </Section>

          <PermissionRestriction
            requiredPermissions={cumulativeControlDataReset.requiredPermissions}
          >
            <Section
              title="Control Data Reset"
              titleTooltip={resetControlTooltip()}
              transparent
              style={{ marginBottom: 50 }}
            >
              <StaticTable
                columns={columns}
                data={controlDataResetRows}
                displayHeader={false}
                trElement={CustomTr}
              />
            </Section>
          </PermissionRestriction>

          <TrafficVolumeSection
            controlRoute={controlRoute}
            newTestsRoute={newTestsRoute}
            actualStorePageRoute={actualStorePageRoute}
            customRoutes={customRoutes}
            editable={editable}
            onChange={onChange}
            onDelete={onDelete}
            onCreate={onCreate}
          />
        </Box>
      </Flex>
    </>
  );
}

// -- STYLED
const HoverLinkWrapper = styled("span")`
  color: ${(props: any) => props.theme.colors.lightGrey};
`;

const SmallButton = styled(Button)`
  float: right;
  min-width: 0px;
  height: 0px;
  font-size: 12px;
  padding: 12px 10px;
`;

const CustomLabelTd = styled(TdComponent)`
  color: ${(props: any) => props.theme.colors.lightGrey};
  text-align: left;
`;

const CustomValueTd = styled(TdComponent)`
  color: ${(props: any) => props.theme.colors.lightGrey};
  text-align: right;
`;

const CustomTr = styled(TrComponent)`
  background-color: #181831;
`;

export default ApplicationRoutingPage;
