import React, { useState, useEffect, memo } from "react";
import styled from "styled-components";
import { get, isEqual } from "lodash";
import moment from "moment";

import { generateRangeOptions, getRangeDisplayOptions } from "./helpers";
import { dateFormat } from "utils/dates";

import {
  CustomDateRange,
  DisplayRange,
  AvailableDisplayModes,
} from "./constants/types";

import SelectInput, { Option } from "components/forms/SelectInput/SelectInput";
import { DateRangePicker } from "components/forms";

// -- TYPES
interface ChartFiltersProps {
  onFilterChange: (
    from: string,
    to: string,
    displayMode: AvailableDisplayModes,
  ) => void;
}

const rangeSelectOptions: Option[] = generateRangeOptions([
  7,
  14,
  30,
  60,
  90,
  180,
  "Custom",
]);

const rangeDisplayOptions: DisplayRange[] = [
  {
    from: 0,
    to: 45,
    label: "Day",
  },
  {
    from: 28,
    to: 210,
    label: "Week",
  },
  {
    from: 150,
    to: Infinity,
    label: "Month",
  },
];

// -- COMPONENT
const ChartFilters: React.FC<ChartFiltersProps> = ({ onFilterChange }) => {
  const [dateRange, setDateRange] = useState<string>(
    rangeSelectOptions[0].value,
  );

  const [customDates, setCustomDates] = useState<CustomDateRange>({
    dateFrom: "",
    dateTo: "",
  });

  const [displayRange, setDisplayRange] = useState<string>(
    rangeDisplayOptions[0].label.toLowerCase(),
  );

  const [availableDisplaysModes, setAvailableDisplaysModes] = useState<
    null | Option[]
  >(getRangeDisplayOptions(dateRange, customDates, rangeDisplayOptions));

  useEffect(() => {
    const initialDateFrom = moment().subtract(6, "days").format("DD-MM-YYYY");
    const initialDateTo = moment().format("DD-MM-YYYY");

    onFilterChange(initialDateFrom, initialDateTo, "day");
  }, [onFilterChange]);

  // TODO: later
  // poza tym to jest useEffect na fetch na początku może warto by było zrobić jeden dodatkowy na zmianę statów i
  // content jego wywołać jako getDatataForFilterSet

  const getDataForFilterSet = ({
    dateRangeFilter,
    displayRangeFilter,
    selectedCustomDates,
  }: {
    dateRangeFilter: string;
    displayRangeFilter: AvailableDisplayModes;
    selectedCustomDates: CustomDateRange;
  }): void => {
    const rangeInDays = Number(dateRangeFilter.replace("last-", ""));

    const dateFrom = isNaN(rangeInDays)
      ? selectedCustomDates.dateFrom
      : moment()
          .subtract(rangeInDays - 1, "days")
          .format("DD-MM-YYYY");

    const dateTo = isNaN(rangeInDays)
      ? selectedCustomDates.dateTo
      : moment().format("DD-MM-YYYY");

    onFilterChange(dateFrom, dateTo, displayRangeFilter);
  };

  const onDateRangeChange = (value: string | string[]): void => {
    setDateRange(value as string);

    if (value === "custom") {
      setAvailableDisplaysModes(null);
      return;
    }

    const displayModes = getRangeDisplayOptions(
      value as string,
      customDates,
      rangeDisplayOptions,
    );
    const selectedDisplayMode = get(
      displayModes,
      "[0].label",
      "",
    ).toLowerCase();

    setAvailableDisplaysModes(displayModes);
    setDisplayRange(selectedDisplayMode);

    getDataForFilterSet({
      dateRangeFilter: value as string,
      displayRangeFilter: selectedDisplayMode as AvailableDisplayModes,
      selectedCustomDates: customDates,
    });
  };

  const onCustomDatesChange = (from: string, to: string): void => {
    const customDateObject = { dateFrom: from, dateTo: to };

    const displayModes = getRangeDisplayOptions(
      dateRange,
      customDateObject,
      rangeDisplayOptions,
    );
    const selectedDisplayMode = get(
      displayModes,
      "[0].label",
      "",
    ).toLowerCase();

    setCustomDates(customDateObject);
    setAvailableDisplaysModes(displayModes);
    setDisplayRange(selectedDisplayMode);

    if (!isEqual(customDateObject, customDates)) {
      getDataForFilterSet({
        dateRangeFilter: dateRange,
        displayRangeFilter: selectedDisplayMode as AvailableDisplayModes,
        selectedCustomDates: customDateObject,
      });
    }
  };

  const onDisplayModeChange = (value: string | string[]): void => {
    setDisplayRange(value as string);

    getDataForFilterSet({
      dateRangeFilter: dateRange,
      displayRangeFilter: value as AvailableDisplayModes,
      selectedCustomDates: customDates,
    });
  };

  const getOptionLabelByValue = (value: string): string | null => {
    const displayedOption = availableDisplaysModes
      ? availableDisplaysModes.find((elem) => elem.value === value)
      : null;

    if (!displayedOption) {
      return null;
    }

    return displayedOption.label;
  };

  return (
    <FiltersWrapper>
      <FilterGroup>
        <DateRangeLabel>Date range:</DateRangeLabel>
        <SelectInput
          options={rangeSelectOptions}
          onChange={onDateRangeChange}
          value={dateRange}
        />
        {dateRange === "custom" && (
          <DateRangePicker
            onChange={onCustomDatesChange}
            displayFormat={dateFormat}
            maxDay={moment()}
          />
        )}
      </FilterGroup>
      {availableDisplaysModes && (
        <FilterGroup>
          <DateRangeLabel>Display:</DateRangeLabel>
          {availableDisplaysModes.length === 1 ? (
            <DateRangeSingleOption>
              {getOptionLabelByValue(displayRange)}
            </DateRangeSingleOption>
          ) : (
            <SelectInput
              options={availableDisplaysModes}
              onChange={onDisplayModeChange}
              value={displayRange}
            />
          )}
        </FilterGroup>
      )}
    </FiltersWrapper>
  );
};

// -- STYLED
const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 34px;
`;
const FilterGroup = styled.div`
  display: flex;
  align-items: center;

  > div:first-of-type {
    width: 155px;
  }

  > div:nth-child(3) {
    margin-left: 25px;
  }
`;
const DateRangeLabel = styled.span`
  margin-right: 20px;
  opacity: 0.4;
`;
const DateRangeSingleOption = styled.span`
  opacity: 0.4;
`;

export default memo(ChartFilters);
