import React from "react";
import {
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-use";

interface NestedSideMenuProps {
  menuItems: any[];
  parentTitle?: string;
  parentIcon?: any;
  onMenuItemClick: (event: any, route: any) => void;
}

export const NestedMenuList = ({
  menuItems,
  parentTitle,
  parentIcon,
  onMenuItemClick,
}: NestedSideMenuProps) => {
  const theme = useTheme();
  const location = useLocation();
  return (
    <List
      sx={{
        "padding": "0px",
        "backgroundColor": "#23233a",
        "width": "250px",
        "border": "1px solid",
        "& .isActive": {
          boxShadow: `inset 10px 0px 0px -4px ${theme.palette.primary.main}`,
        },
      }}
    >
      {parentTitle ? (
        <MenuItem
          sx={{
            "height": "35px",
            "padding": "0px 0px 0px 0px",
            "&:hover": {
              cursor: "unset",
              backgroundColor: "unset",
            },
          }}
          disableRipple
          disableTouchRipple
        >
          {parentIcon ? (
            <ListItemIcon
              sx={{
                justifyContent: "center",
                width: "35px",
              }}
            >
              {parentIcon}
            </ListItemIcon>
          ) : null}
          <ListItemText
            sx={{
              "& .MuiListItemText-primary": {
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightRegular,
                textTransform: "uppercase",
              },
            }}
            primary={parentTitle}
          />
        </MenuItem>
      ) : null}
      {menuItems?.map((item: any) => {
        const isRowActive = (location.pathname as string | "").includes(
          item.route,
        );
        return (
          <MenuItem
            key={item.title}
            selected={isRowActive}
            onClick={(e) => onMenuItemClick(e, item.route)}
            className={isRowActive ? "isActive" : undefined}
            sx={{
              height: "35px",
              padding: "0px 0px 0px 35px",
            }}
          >
            <ListItemText
              sx={{
                "& .MuiListItemText-primary": {
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontSize: theme.typography.fontSize,
                  fontWeight: theme.typography.fontWeightRegular,
                },
              }}
              primary={item.title}
            />
          </MenuItem>
        );
      })}
    </List>
  );
};
