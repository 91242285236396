import hexToRgba from "hex-to-rgba";

import { heatmapGradientColorsDefinition } from "./heatmapGradientColorsDefinition";

// -- STATIC
const opacity = 0.6;
const gradientType = "linear-gradient";
const angle = 180;

export const generateGradientString = (): string => {
  const colorString = Object.entries(heatmapGradientColorsDefinition)
    .map((singleEntry) => {
      const [percent, color] = singleEntry;

      return `${hexToRgba(color, opacity)} ${percent}%`;
    })
    .join(", ");

  return `${gradientType}(${angle}deg, ${colorString})`;
};
