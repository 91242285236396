// -- CORE
import { RouteProps } from "react-router-dom";
// -- CONST
import { MenuMode } from "v2/api/types/menuMode";
import { UserPermissions } from "v2/api/types/UserPermissions";
// -- UTILS
import { createUrlWithParams, compareTemplateUrl } from "v2/utils/url";

// -- TYPES
export type RouteConstructorProps = Pick<
  RouteProps,
  "exact" | "component" | "render" | "path"
> & {
  permissions?: UserPermissions[];
  menuMode: MenuMode;
  layout?: string;
  supportsMobile?: boolean;
  formatTrackingInfo?: (info: any) => any;
};

export class Route<T extends Record<string, string | number> | never> {
  public path: RouteConstructorProps["path"] = undefined;
  private exact: RouteConstructorProps["exact"] = false;
  private component: RouteConstructorProps["component"] = undefined;
  private permissions: RouteConstructorProps["permissions"] = [];
  private menuMode: RouteConstructorProps["menuMode"] = MenuMode.NONE;
  private render: RouteConstructorProps["render"] = undefined;
  public layout: RouteConstructorProps["layout"] = "";
  public supportsMobile: RouteConstructorProps["supportsMobile"] = undefined;
  public formatTrackingInfo?: (info: any) => any = () => {};

  constructor(initialData: RouteConstructorProps) {
    this.path = initialData.path;
    this.exact = Boolean(initialData.exact);
    this.render = initialData.render;
    this.component = initialData.component;

    this.permissions = initialData.permissions;
    this.menuMode = initialData.menuMode;
    this.layout = initialData.layout;
    this.supportsMobile = initialData.supportsMobile;
    this.formatTrackingInfo = initialData.formatTrackingInfo;
  }

  get routeMenuMode(): RouteConstructorProps["menuMode"] {
    return this.menuMode;
  }

  get reactRouterProps(): Pick<
    RouteProps,
    "exact" | "component" | "render" | "path"
  > {
    return {
      exact: this.exact,
      component: this.component,
      render: this.render,
      path: this.path,
    };
  }

  get routePermissions() {
    return this.permissions;
  }

  public hasUserAccess(userPermissions: UserPermissions[] = []): boolean {
    if (!this.permissions) {
      return true;
    }

    if (!Array.isArray(userPermissions) || !userPermissions.length) {
      return false;
    }

    return this.permissions.every((singleRouteRequiredPermission) => {
      if (Array.isArray(singleRouteRequiredPermission)) {
        return singleRouteRequiredPermission.some((perm) =>
          userPermissions.includes(perm),
        );
      }
      return userPermissions.includes(singleRouteRequiredPermission);
    });
  }

  public getUrlWithParams(params?: T): string {
    if (!params) {
      return this.path as string;
    }

    return createUrlWithParams(this.path as string, params);
  }

  public isRouteUrl(route: string): boolean {
    return compareTemplateUrl((this.path as string) || "", route);
  }
}
