import React from "react";
import { RouteComponentProps } from "react-router";

import { ReferenceVariationType } from "api/models";
import { StepData } from "components/application/forms/PromoArtStep";
import ApplicationUpdateContainer from "../ApplicationUpdateContainer";

import { createFeatureGraphics } from "v2/api/mutations/application/featureGraphic";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
import { FeatureGraphicUpdateForm } from "./components/FeatureGraphicUpdateForm";

interface Props extends RouteComponentProps<{ id: string }> {}

export default function ApplicationUpdateFeatureGraphics(props: Props) {
  const [fullPageLoader, setIsLoading] = useFullPageLoader();

  const handleOnSubmit = (onSubmitFunc: any) => async (formData: StepData) => {
    setIsLoading(true);
    await onSubmitFunc({
      data: formData.assets.map((d) => ({
        isControl: d === formData.control,
      })),
      files: formData.assets.map((d) => d.file),
      referenceVariationType:
        formData.referenceVariationType || ReferenceVariationType.top,
    });
    setIsLoading(false);
  };

  return (
    <>
      {fullPageLoader}
      <ApplicationUpdateContainer
        mutation={createFeatureGraphics.definition}
        {...props}
      >
        {(application: any, onDiscard: () => void, onSubmit: () => void) => (
          <FeatureGraphicUpdateForm
            onDiscard={onDiscard}
            onSubmit={handleOnSubmit(onSubmit)}
            isEdit={true}
            application={application}
          />
        )}
      </ApplicationUpdateContainer>
    </>
  );
}
