import React, { useContext } from "react";
import { RouteComponentProps } from "react-router";
import { useQuery, useMutation } from "@apollo/client";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import {
  GET_APPLICATION_TITLE_AND_STATUS,
  GET_APPLICATION_OVERVIEW,
} from "api";

import { ApplicationContext } from "contexts";

import { truncate } from "utils/strings";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Spinner, Title, Error } from "components";
import { NarrowTemplate } from "templates";

interface Props extends RouteComponentProps<{ id: string }> {
  children: any;
  mutation: any;
}

function ApplicationUpdateContainer({
  children,
  mutation,
  match,
  history,
}: Props) {
  const applicationCtx = useContext(ApplicationContext);

  const [createAssets] = useMutation(mutation);

  const handleError = useRequestErrorHandler();

  const applicationId = match.params.id;
  const { data, error, loading } = useQuery(GET_APPLICATION_TITLE_AND_STATUS, {
    variables: { id: applicationId },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  if (data.application === null) {
    history.replace(authRoutes.APPLICATION_LIST.getUrlWithParams());
  }

  applicationCtx.setCurrent(data.application);

  return (
    <NarrowTemplate>
      <Title>{truncate(data.application.name, 35).trim()}</Title>
      {children(
        data.application,
        () => history.goBack(),
        async (variables: object) => {
          const createAssetsResponse = await handleError(
            createAssets({
              variables: {
                applicationId,
                ...variables,
              },
              refetchQueries: [
                {
                  query: GET_APPLICATION_OVERVIEW,
                  variables: { applicationId },
                },
              ],
            }),
          );

          createAssetsResponse && history.goBack();
        },
      )}
    </NarrowTemplate>
  );
}

export default ApplicationUpdateContainer;
