import React from "react";
import styled from "styled-components";
import { Box, Flex } from "@rebass/grid";
import { RouteComponentProps, withRouter } from "react-router";

import {
  Platform,
  AppStatus,
  ClientApplication,
  AppPlatformListing,
} from "api/models";
import { Action as AssetAction } from "components/forms/UploadImage/components/AssetActions";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";
import themes from "components/themes";

import { useHasUserPermission } from "v2/hooks/useHasUserPermission";

import { NarrowTemplate } from "templates";

import { convertDateToDisplayFormat } from "utils/dates";
import { displayPlatformName } from "utils/enums";

import {
  DividerHorizontal,
  Icon,
  Icons,
  Title as Header,
  Text,
} from "components";
import { Button, UploadImage } from "components/forms";
import { CvrSection } from "./components/CvrSection";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

interface ApplicationListPageProps extends RouteComponentProps {
  applicationGroups?: ClientApplication[];
}

function ApplicationListPage({
  applicationGroups,
  history,
}: ApplicationListPageProps) {
  const hasUserPermission = useHasUserPermission();

  const isPlatformActive = (element: any) =>
    element.status === AppStatus.active;

  const activeApplicationGroups = applicationGroups?.filter((app) => {
    const isApplicationActive = app.appPlatform.some(isPlatformActive);
    return isApplicationActive;
  });

  const inactiveApplicationGroups = applicationGroups?.filter((app) => {
    const isApplicationInactive = app.appPlatform.every(
      (element: any) => element.status === AppStatus.inactive,
    );
    return isApplicationInactive;
  });

  const onCreate = (
    application?: AppPlatformListing,
    applicationGroup?: ClientApplication,
  ) => () => {
    history.push(authRoutes.APPLICATION_CREATE.getUrlWithParams(), {
      application,
      applicationGroup,
    });
  };

  const onDetail = (applicationId: string) => () => {
    history.push(
      authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({ id: applicationId }),
    );
  };

  const getAction = (
    platform: Platform,
    applicationGroup: ClientApplication,
  ): AssetAction | null => {
    const platformName = displayPlatformName(platform);
    const application = applicationGroup.appPlatform?.find(
      (a) => a.platform === platform && a.asoAppId,
    );

    const actionWithPermissions = application
      ? {
          action: {
            name: `View ${
              application.status === AppStatus.inactive ? "Inactive" : ""
            } ${platformName}`,
            onClick: onDetail(application.asoAppId),
          },
          requiredPerms: authRoutes.APPLICATION_OVERVIEW.routePermissions || [],
        }
      : {
          action: {
            name: `Create ${platformName}`,
            onClick: onCreate(
              applicationGroup.appPlatform?.[0],
              applicationGroup,
            ),
          },
          requiredPerms: authRoutes.APPLICATION_CREATE.routePermissions || [],
        };

    return hasUserPermission(actionWithPermissions.requiredPerms)
      ? actionWithPermissions.action
      : null;
  };

  const displayCvr = (applicationGroup: ClientApplication) => {
    return applicationGroup.appPlatform?.some(
      (singleApp) => !singleApp.asoInfo?.redirectToStoreUrl,
    );
  };

  const getApplicationActions = (
    applicationGroup: ClientApplication,
  ): AssetAction[] => {
    const iosAction: AssetAction | null = getAction(
      Platform.ios,
      applicationGroup,
    );
    const androidAction: AssetAction | null = getAction(
      Platform.android,
      applicationGroup,
    );

    return [iosAction, androidAction].filter(Boolean) as AssetAction[];
  };

  const renderSection = (
    sectionElements: ClientApplication[],
    title: string,
    activeBox?: boolean,
  ) => {
    if (!Array.isArray(sectionElements) || !sectionElements.length) {
      return null;
    }

    const BoxComponent = activeBox ? AppBox : InactiveBox;

    return (
      <>
        <Box>
          <Title>{title}</Title>
        </Box>
        <Flex flexWrap="wrap">
          {sectionElements.map((applicationGroup) => {
            const actions = getApplicationActions(applicationGroup);
            const shouldDisplayCvr = displayCvr(applicationGroup);

            return (
              <BoxComponent
                key={`application-group-${applicationGroup.asoGroupId}`}
                width={1 / 4}
              >
                <BackgroundFill width={1}>
                  <ImageBox control={false} displayCvr={shouldDisplayCvr}>
                    <UploadImage
                      image={applicationGroup.image}
                      label={
                        <Wrapper>
                          <Name
                            value={applicationGroup.name}
                            displayedLength={25}
                          />
                          <Date>
                            {convertDateToDisplayFormat(
                              applicationGroup.audit.createDate,
                            )}
                          </Date>
                          {shouldDisplayCvr && (
                            <CvrSection applicationGroup={applicationGroup} />
                          )}
                        </Wrapper>
                      }
                      actions={actions}
                      size={220}
                    />
                  </ImageBox>
                </BackgroundFill>
              </BoxComponent>
            );
          })}
        </Flex>
      </>
    );
  };

  const createNewAppButton = (
    <PermissionRestriction
      requiredPermissions={authRoutes.APPLICATION_CREATE.routePermissions}
    >
      <NewAppButton
        icon={<Icon icon={Icons.faPlusCircle} />}
        onClick={onCreate()}
      >
        Set Up New App
      </NewAppButton>
    </PermissionRestriction>
  );

  return (
    <NarrowTemplate>
      <Header button={createNewAppButton} />

      <DividerHorizontal />

      {activeApplicationGroups &&
        renderSection(activeApplicationGroups, "Active Apps", true)}
      {inactiveApplicationGroups &&
        renderSection(inactiveApplicationGroups, "Inactive Apps")}
    </NarrowTemplate>
  );
}

const Title = styled.div`
  font-size: 21px;
  font-weight: 300;
  padding-top: 25px;
  padding-bottom: 30px;
  color: #fff;
`;

const ImageBox = styled(Flex)<{ control: boolean; displayCvr: boolean }>`
  flex-direction: column;
  position: relative;
  padding: 15px 5px ${(props: any) => (props.displayCvr ? `10px` : `30px`)};
  border-radius: 8px;
  border: ${(props) =>
    props.control ? `1px solid ${themes.colors.purple}` : null};
  align-items: center;
  text-align: center;
`;

const Name = styled(Text)`
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
`;

const Date = styled.div`
  padding-top: 8px;
  opacity: 0.6;
  font-size: 11px;
  font-weight: 300;
  color: #fff;
`;

const Wrapper = styled.div`
  margin-top: 18px;
`;

const AppBox = styled(Flex)`
  flex-direction: row;
  justify-content: center;
`;

const BackgroundFill = styled(Flex)`
  border-radius: 8px;
  background-color: ${themes.colors.card};
  flex-direction: row;
  justify-content: center;
  margin: 10px;
`;

const InactiveBox = styled(Box)`
  opacity: 0.4;
`;

const NewAppButton = styled(Button)`
  margin-bottom: 35px;
`;

export default withRouter(ApplicationListPage);
