import React, { useState, FC, ReactNode } from "react";
import styled from "styled-components";

// -- TYPES
type Trigger = "onHover" | "onClick";

interface PopoverProps {
  children: ReactNode;
  trigger?: Trigger;
  content: ReactNode;
  hideDelay?: number;
}

// -- COMPONENT
const Popover: FC<PopoverProps> = ({
  children,
  content,
  trigger = "onClick",
  hideDelay = 3,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  let hideTimeout = 0;

  const setVisibility = () => {
    clearTimeout(hideTimeout);
    setIsVisible(true);
    hideTimeout = setTimeout(() => {
      setIsVisible(false);
    }, hideDelay * 1000);
  };

  const triggerProps = {
    [trigger]: setVisibility,
  };

  return (
    <Wrapper {...triggerProps}>
      {isVisible && <PopoverContent>{content}</PopoverContent>}
      {children}
    </Wrapper>
  );
};

// -- STYLES
const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
const PopoverContent = styled.div`
  border: 2px solid #5d3393;
  border-radius: 4px;
  background-color: #3c3c51;
  color: #fff;
  padding: 4px 8px;
  top: -45px;
  position: absolute;
  word-break: normal;
`;

export default Popover;
