import React, { useContext } from "react";
import { isFunction } from "lodash";
import { RouteComponentProps } from "react-router";
import { useQuery, useMutation, DocumentNode } from "@apollo/client";
import { Form } from "react-final-form";

// import { Field } from "react-final-form";
// import { Box, Flex } from "@rebass/grid";
// import styled from "styled-components";
import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import {
  GET_APPLICATION_CONTROL,
  GET_APPLICATION_OVERVIEW,
  GET_APPLICATION_TITLE_AND_STATUS,
} from "api";
// import { Status } from "api"
import { Spinner, Title, Error } from "components";
// import { MessageBox } from "components";
import { NarrowTemplate } from "templates";
import { truncate } from "utils/strings";
import { ApplicationContext, ModalContext } from "contexts";
import { FormFooter } from "components/forms";
// import { FormHeader, FormLabel, TextInput } from "components/forms";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
// import formatters from "utils/formatters";
// import theme from "components/themes";

// -- TYPES
type AdditionalRefetchQueries = {
  query: DocumentNode;
  variables: object;
};

interface Props extends RouteComponentProps<{ id: string }> {
  children: any;
  mutation: any;
  formData: any;
  parseFormDataOnSubmit?: (param: any) => object;
  submitDisabled?: boolean;
  position?: string;
  displayConfirmModal?: boolean;
  additionalRefetchQueries?: AdditionalRefetchQueries[];
}

// -- COMPONENT
function ApplicationControlUpdateContainer({
  children,
  mutation,
  formData,
  match,
  history,
  submitDisabled = false,
  position,
  parseFormDataOnSubmit,
  displayConfirmModal = true,
  additionalRefetchQueries = [],
}: Props) {
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const applicationCtx = useContext(ApplicationContext);
  const setModal = useContext(ModalContext);

  const handleError = useRequestErrorHandler();

  const applicationId = match.params.id;

  const { data, error, loading } = useQuery(GET_APPLICATION_TITLE_AND_STATUS, {
    variables: { id: applicationId },
    onCompleted: ({ application }) => {
      applicationCtx.setCurrent(application);
    },
  });

  const [updateControlAsset] = useMutation(mutation);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  if (data.application === null) {
    history.replace(authRoutes.APPLICATION_LIST.getUrlWithParams());
  }

  const onSubmit = (
    variables: object,
    parsVariablesOnSubmit?: (param: any) => object,
  ) => async () => {
    const parsedVariables = isFunction(parsVariablesOnSubmit)
      ? parsVariablesOnSubmit(variables)
      : variables;

    const updateControl = async () => {
      setFullPageLoader(true);

      const options = {
        variables: {
          applicationId,
          position,
          ...parsedVariables,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_APPLICATION_CONTROL,
            variables: { applicationId },
          },
          {
            query: GET_APPLICATION_OVERVIEW,
            variables: { applicationId },
          },
          ...additionalRefetchQueries,
        ],
      };

      const updateControlAssetResponse = await handleError(
        updateControlAsset(options),
      );

      if (updateControlAssetResponse) {
        history.replace(
          authRoutes.APPLICATION_CONTROL.getUrlWithParams({
            id: applicationId,
          }),
        );
      }

      setFullPageLoader(false);
    };

    displayConfirmModal
      ? await new Promise<void>((resolve) => {
          setModal({
            type: "set",
            props: {
              title: "Are you sure you want to change this control asset?",
              content:
                "Please note that manually updating the control asset will discard associated test data for the current running tests that were performed with the uploaded original asset(s). I.e. if there is a current ongoing BCR calculation or ongoing icon tests, this manual update would start these tests over.",
              confirmText: "Change Control Asset",
              onConfirm: async () => {
                await updateControl();
                resolve();
              },
              onCancel: () => {
                resolve();
              },
            },
          });
        })
      : await updateControl();
  };

  return (
    <NarrowTemplate>
      {fullPageLoader}
      <Title>{truncate(data.application.name, 35).trim()}</Title>
      <Form
        onSubmit={onSubmit(formData, parseFormDataOnSubmit)}
        validate={() => {
          const errors: any = {};
          return errors;
        }}
        initialValues={formData}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            {children}
            <FormFooter
              onCancel={() => history.goBack()}
              disabled={submitting}
              submitDisabled={submitDisabled}
            />
          </form>
        )}
      </Form>
    </NarrowTemplate>
  );
}

// const Container = styled(Box)`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   min-width: 454px;
//   min-height: 140px;
//   border-radius: 8px;
//   background-color: ${theme.colors.card};
//   padding: 30px;
// `;

export default ApplicationControlUpdateContainer;
