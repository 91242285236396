import { MenuMode } from "v2/api/types/menuMode";
import { SingleSideMenuItem } from "./sideMenuItem";
import { ApplicationListing } from "api/models";
// -- MENU ELEMENTS
import { applicationMenuElement } from "./menus/applicationMenuElements";

import {
  appsMenuElement,
  costPlannerMenuElement,
} from "./menus/basicMenuElements";
import { UserPermissions } from "v2/api/types";

const applicationListingMenu = (
  isMenuCollapsed: boolean,
  application?: ApplicationListing | null,
  permissions?: UserPermissions[] | null,
): SingleSideMenuItem[] => [
  appsMenuElement(permissions),
  costPlannerMenuElement(isMenuCollapsed),
];

const applicationDetailsMenu = (
  isMenuCollapsed: boolean,
  application?: ApplicationListing | null,
  permissions?: UserPermissions[] | null,
): SingleSideMenuItem[] => {
  if (!application) {
    return [
      appsMenuElement(permissions),
      costPlannerMenuElement(isMenuCollapsed),
    ];
  }

  return [
    appsMenuElement(permissions),
    ...applicationMenuElement(isMenuCollapsed, application, permissions),
    costPlannerMenuElement(isMenuCollapsed),
  ];
};

export const menuTypes: Record<
  MenuMode,
  (
    collapsedStatus: boolean,
    application?: ApplicationListing | null,
    permissions?: UserPermissions[] | null,
    clientCode?: string | null | undefined,
  ) => SingleSideMenuItem[]
> = {
  [MenuMode.APPLICATION_LISTING]: applicationListingMenu,
  [MenuMode.APPLICATION_DETAILS]: applicationDetailsMenu,
  [MenuMode.NONE]: () => [],
  [MenuMode.FOCUS]: () => [],
};
