import { observable, action, makeObservable } from "mobx";
import { MenuMode } from "v2/api/types/menuMode";

class LayoutStore implements TLayoutStore {
  public sideMenuMode: MenuMode = MenuMode.NONE;
  public isMenuCallapsed: boolean = true;

  constructor() {
    this.isMenuCallapsed =
      localStorage.getItem("menuCollapsed") !== null
        ? localStorage.getItem("menuCollapsed") === "true"
        : this.isMenuCallapsed;
    makeObservable(this, {
      sideMenuMode: observable,
      isMenuCallapsed: observable,
      setSideMenuMode: action,
      setMenuCallapsedStatus: action,
    });
  }

  setSideMenuMode(mode: MenuMode) {
    this.sideMenuMode = mode;
  }

  setMenuCallapsedStatus(isCallapsed: boolean) {
    localStorage.setItem("menuCollapsed", String(isCallapsed));
    this.isMenuCallapsed = isCallapsed;
  }
}

export default LayoutStore;

export interface TLayoutStore {
  sideMenuMode: MenuMode;
  setSideMenuMode: (mode: MenuMode) => void;
  isMenuCallapsed: boolean;
  setMenuCallapsedStatus: (isCallapsed: boolean) => void;
}
