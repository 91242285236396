import React from "react";
import styled from "styled-components";
import { Box, Flex } from "@rebass/grid";
import { Field } from "react-final-form";

import { ReferenceVariationType } from "api/models";
import { ButtonToggle, FormHeader } from "components/forms";
import { MessageBox } from "components";

interface Props {
  visible: boolean;
  onChange: (variationType: string) => void;
}

function ReferenceVariation({ visible, onChange }: Props) {
  return (
    <>
      {visible && (
        <Container>
          <Flex alignItems="center">
            <Box width={1 / 2}>
              <FormHeader>Test Newly Added Creatives Against:</FormHeader>
              <Field name="referenceVariation">
                {({ input }) => {
                  return (
                    <ButtonToggle
                      name="referenceVariation"
                      value={input.value || ReferenceVariationType.top}
                      items={[
                        { name: "Top Page", value: ReferenceVariationType.top },
                        {
                          name: "Control Page",
                          value: ReferenceVariationType.control,
                        },
                      ]}
                      onChange={(variation) => {
                        input.onChange(variation);
                        onChange(variation);
                      }}
                    />
                  );
                }}
              </Field>
            </Box>
            <Box width={1 / 2}>
              <MessageBox transparent closeable={false}>
                Testing against the Top Page is recommended. It means the Top
                Page will be iterated upon continuously for improvement. You
                can, however, choose to always only test against the Control.
              </MessageBox>
            </Box>
          </Flex>
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  background-color: #13132b;
  border-radius: 8px;
  height: 162px;
  margin-top: 30px;
  padding: 0 15px;
  overflow: hidden;
  width: 100%;
`;

export default ReferenceVariation;
