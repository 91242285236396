import * as React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import { Flex } from "@rebass/grid";

import { UserPermissions } from "v2/api/types";

import UISection from "components/Section";
import { ImageAssetBox } from "./ImageAssetBox";
import Button from "components/forms/Button";
import MessageBox from "components/MessageBox";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- TYPES
export type ImageSectionProps = {
  isSubSection?: boolean;
  title: string;
  assets: any[];
  url: string;
  editPermissions: UserPermissions[];
};

// -- COMPONENT
export function ImageSection({
  isSubSection,
  title,
  assets,
  url,
  editPermissions,
}: ImageSectionProps): JSX.Element {
  const titleToRender = React.useMemo(() => {
    if (isSubSection) {
      return <SmallTitle>{title}</SmallTitle>;
    }

    return title;
  }, [title, isSubSection]);

  return (
    <UISection
      title={titleToRender}
      transparentHeader={!isSubSection}
      transparent={isSubSection}
    >
      <Wrapper>
        <Scrollable justifyContent="start">
          {!assets.length && (
            <MessageBox closeable={false}>
              There are no assets in this section yet.
            </MessageBox>
          )}

          {!!assets.length &&
            assets.map((asset) => {
              return <ImageAssetBox asset={asset} key={asset.id} />;
            })}
        </Scrollable>
        <PermissionRestriction requiredPermissions={editPermissions}>
          <StyledLink to={url}>
            <Button>Edit</Button>
          </StyledLink>
        </PermissionRestriction>
      </Wrapper>
    </UISection>
  );
}

// -- UI
const Wrapper = styled("div")`
  display: flex;

  align-items: center;
`;

const Scrollable = styled(Flex)`
  overflow-x: auto;

  margin-right: 30px;

  flex-grow: 1;
`;

const SmallTitle = styled("div")`
  font-size: 14px;

  color: ${(props: any) => props.theme.colors.lightGrey};
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
