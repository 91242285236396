import React, { useEffect, useMemo } from "react";
import { isFunction } from "lodash";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { useLazyQuery } from "@apollo/client";
import { CURRENT_USER_ZENDESK_WIDGET_TOKEN } from "api";
import { useStores } from "contexts";

Zendesk.prototype.componentWillUnmount = () => {};

export const ZendeskScript = () => {
  const { userStore } = useStores();

  const [fetchAuthToken] = useLazyQuery(CURRENT_USER_ZENDESK_WIDGET_TOKEN);

  const jwtFn = async (cb: (token: string) => void) => {
    if (!userStore.platformStore?.currentUser) return;
    const { data } = await fetchAuthToken();
    cb(data.currentUserZendeskWidgetToken);
  };

  const onLoaded = () => {
    ZendeskAPI("webWidget:on", "open", () => {
      ZendeskAPI("webWidget", "prefill", {
        name: {
          value: `${userStore.platformStore?.currentUser.firstName} ${userStore.platformStore?.currentUser.lastName}`,
          readOnly: true, // optional
        },
        email: {
          value: userStore.platformStore?.currentUser.emailAddress,
          readOnly: true, // optional
        },
      });
    });
  };

  const zendesk = useMemo(
    () => (
      <Zendesk
        defer
        zendeskKey="79f93080-f27f-4afc-8bfe-2cf45804f6b1"
        authenticate={{ jwtFn }}
        onLoaded={onLoaded}
      />
    ),
    [],
  );
  return zendesk;
};

export const updateZendeskPath = (path: string) => {
  if (!(window as any).zE) return;
  ZendeskAPI("webWidget", "updatePath", {
    url: window.location.origin + path,
  });
};

export const changeZendeskChatDisplay = (show: boolean) => {
  const event = show ? "show" : "hide";

  const action = window.$zopim?.livechat?.button?.[event];

  isFunction(action) && action();
};
