import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const createPromoArts = new ApiMutation({
  definition: gql`
    mutation createPromoArts(
      $applicationId: ID!
      $data: [ControlObjectType]
      $files: [Upload]
    ) {
      createPromoArts(
        applicationId: $applicationId
        data: $data
        files: $files
      ) {
        isCreated
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASOAsset],
});

export interface CreatePromoArtsResponse {
  createPromoArts: {
    isCreated: boolean;
  };
}

export const deletePromoArt = new ApiMutation({
  definition: gql`
    mutation deletePromoArt($id: ID!) {
      deletePromoArt(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOAsset],
});

export interface DeletePromoArtResponse {
  deletePromoArt: {
    isDeleted: boolean;
  };
}

export const deactivatePromoArt = new ApiMutation({
  definition: gql`
    mutation deactivatePromoArt($id: ID!) {
      deactivatePromoArt(id: $id) {
        isDeactivated
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOTest],
});

export interface DeactivatePromoArtResponse {
  deactivatePromoArt: {
    isDeactivated: boolean;
  };
}
