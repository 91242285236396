import moment from "moment";
import { isArray, isString } from "lodash";

import {
  CustomDateRange,
  DisplayRange,
  TooltipRefLineGroup,
} from "./constants/types";
import { ReferenceLine, ReferenceLinesTypes } from "api/models";

import { Option } from "components/forms/SelectInput/SelectInput";

export const generateRangeOptions = (
  inputOptions: (number | string)[],
): Option[] => {
  if (!isArray(inputOptions)) {
    return [];
  }

  return inputOptions.map((singleOption) => {
    if (isString(singleOption)) {
      return {
        value: singleOption.toLowerCase(),
        label: singleOption,
      };
    }

    return {
      value: `last-${singleOption}`,
      label: `Last ${singleOption} Days`,
    };
  });
};

export const getRangeDisplayOptions = (
  dateRangeValue: string,
  customDates: CustomDateRange,
  rangeDisplayOptions: DisplayRange[],
): Option[] => {
  let rangeInDays = Number(dateRangeValue.replace("last-", ""));

  if (isNaN(rangeInDays) && dateRangeValue === "custom") {
    const { dateFrom, dateTo } = customDates;
    const momentFrom = moment(dateFrom, "DD-MM-YYYY");
    const momentTo = moment(dateTo, "DD-MM-YYYY");

    rangeInDays = Math.abs(momentFrom.diff(momentTo, "days")) + 1;
  }

  const availableDisplayOptions = rangeDisplayOptions.filter(
    (displayOption) =>
      displayOption.from <= rangeInDays && displayOption.to >= rangeInDays,
  );

  return availableDisplayOptions.map((displayOption) => ({
    value: displayOption.label.toLowerCase(),
    label: displayOption.label,
  }));
};

export const percentAxisFormat = (tickValue: string | number): string =>
  `${tickValue}%`;

/**
 * Creates reference group based on type
 * @param {ReferenceLine} referenceLines - list of reference lines
 * @param {ReferenceLinesTypes} type - type of ref line which will be in this group only
 * @param {string} label - label of groups
 * @returns {TooltipRefLineGroup} - group of ref lines values
 */
export const createTooltipReferenceLineGroup = (
  referenceLines: ReferenceLine[],
  type: ReferenceLinesTypes,
  label: string,
): TooltipRefLineGroup => ({
  label,
  values: referenceLines
    .filter((singleRefLine) => singleRefLine.type === type)
    .reduce((prev, current) => {
      if (!current.tooltipValues) {
        return prev;
      }

      return [...prev, ...current.tooltipValues];
    }, [] as string[]),
});
