import React, { useContext, useEffect, useMemo, useState } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";
import { setCookie } from "react-use-cookie";

import config from "config";
import { useStores } from "contexts/storeContext";
import { switchClientMutation } from "v2/api/mutations/membership/switchClient";
import { client } from "api";

function SwitchClientComponent() {
  const { userStore } = useStores();
  const history = useHistory();
  const { search } = useLocation();
  const [switchClient] = useMutation(switchClientMutation);

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const platformStore = useContext<PlatformStore>(PlatformContext);

  const onChangeMembership = async (clientCode: string) => {
    userStore.showPlatformSpinner = true;
    platformStore.setProcessingSwitchClient(true);
    try {
      await switchClient({
        variables: { clientCode },
        context: { handleError: false },
      });
    } catch (e) {
      const { code } = (e as any)?.graphQLErrors?.[0]?.extensions || {};
      if (code === 403 || code === 404) {
        platformStore.setProcessingSwitchClient(false);
        userStore.showPlatformSpinner = false;
        history.replace("/");
        return;
      }

      document.dispatchEvent(
        new CustomEvent("openModal", {
          detail: {
            content:
              "An error occured while loading the page. Please try again.",
            confirmText: "Reload",
            onHide: () => window.location.reload(),
            disableCancel: true,
          },
        }),
      );
      return;
    }

    setCookie(config.lastLoginClientCookieName, clientCode, { days: 365 });
    platformStore.setCurrentClientCode(clientCode);

    let url = query.get("orgUrl") || "";
    if (query.get("orgUrl") !== null) url = decodeURIComponent(url);
    history.replace(url);
    await userStore.loadUser(true);

    let appPlatformASO = platformStore.appPlatforms as any[];
    appPlatformASO = appPlatformASO.filter(({ asoAppId }) => asoAppId);
    platformStore.setSelectedAppPlatform(appPlatformASO[0] as any);
    platformStore.setSelectedApplication(platformStore.applications[0] as any);
    await client.resetStore();
    platformStore.setProcessingSwitchClient(false);
    userStore.showPlatformSpinner = false;
  };

  useEffect(() => {
    onChangeMembership(query.get("newClientCode") || "");
  }, []);

  return null;
}

export default SwitchClientComponent;
