import * as React from "react";
import { Flex } from "@rebass/grid";
import { styled } from "@mui/system";

import emptyPromoArt from "assets/images/empty-state-promo-art.svg";

import { AssetStatus, Icon, Preview, PromoArt } from "api/models";

import UploadImage from "components/forms/UploadImage/UploadImage";
import { Text } from "components";

// -- TYPES
type ImageAssetBoxProps = {
  asset: Icon & Preview & PromoArt;
};

export function ImageAssetBox({ asset }: ImageAssetBoxProps) {
  let imageUrl = asset.url || asset.file!.url;

  if (!imageUrl && asset.fileName === "No Promo Art") {
    imageUrl = emptyPromoArt;
  }

  return (
    <ImageBox key={`elements-${asset.id}`} mr={25} control={asset.isControl}>
      {asset.status === AssetStatus.testing && (
        <TestingLabel>Currently testing</TestingLabel>
      )}

      <AssetWrapper>
        <AssetName
          value={
            asset.isSet
              ? `Set: ${asset?.set?.name || "Unnamed"}`
              : asset.fileName || ""
          }
          displayedLength={25}
        />
        <ImageBoxContent>
          <UploadImage
            image={imageUrl}
            control={false}
            onControl={null}
            isVideoPreview={asset.isVideo}
            isStack={asset.isSet}
            previewUrl={asset.previewLink}
            videoUrl={asset.embeddedVideoUrl}
          />
        </ImageBoxContent>
      </AssetWrapper>

      {asset.isControl && <ControlLabel>Current Control Creative</ControlLabel>}
    </ImageBox>
  );
}

// -- STYLED
const ImageBox = styled(Flex)<{ control: boolean }>`
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;

  padding: 24px 30px;

  background-color: ${(props: any) => props.theme.colors.card};

  border-radius: 8px;
  border: 1px solid
    ${(props: any) =>
      props.control ? props.theme.colors.purple : props.theme.colors.card};
`;

const ImageBoxContent = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

const ControlLabel = styled("div")`
  padding: 3px 5px;

  color: ${(props: any) => props.theme.colors.white};

  background-color: ${(props: any) => props.theme.colors.purple};

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  font-size: 12px;

  margin-bottom: -25px;
`;

const TestingLabel = styled(ControlLabel)`
  border-radius: 0 0 4px 4px;

  margin-top: -25px;
  margin-bottom: 0;
`;

const AssetName = styled(Text)`
  text-align: center;
  margin-bottom: 10px;
`;

const AssetWrapper = styled("div")`
  margin: 10px 0 36px;
`;
