import React from "react";
import styled from "styled-components";

import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";
import { AuthSection } from "components/auth";
import { Link } from "components";

function UpdateForgottenPasswordErrorPage() {
  return (
    <AuthSection title="Password reset" hints={[]}>
      <Text>
        The reset password link is invalid or it has expired. You can resend new
        email message by clicking{" "}
        <TextLink
          to={nonAuthRoutes.AUTH_FORGOT_PASSWORD.getUrlWithParams()}
          replace
        >
          here
        </TextLink>
        .
      </Text>
    </AuthSection>
  );
}

const Text = styled.p`
  margin: 60px 0;
`;

const TextLink = styled(Link)`
  text-decoration: underline;
  font-weight: bold;
`;

export default UpdateForgottenPasswordErrorPage;
