import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const updateAppPlatform = new ApiMutation({
  definition: gql`
    mutation updateAppPlatform(
      $clientCode: String!
      $appPlatformCode: String!
      $appPlatform: UpdateAppPlatform!
    ) {
      updateAppPlatform(
        clientCode: $clientCode
        appPlatformCode: $appPlatformCode
        appPlatform: $appPlatform
      ) {
        status
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateAppPlatformProfile],
});
