import gql from "graphql-tag";

export const getUserDetailsByEmailQuery = gql`
  query userByEmail($email: String!) {
    userByEmail(email: $email) {
      id
      username
    }
  }
`;

export type UserDetailsByEmailQueryResponse = {
  userByEmail: {
    id: number;
    username: string;
  };
};
