import * as React from "react";
import { styled } from "@mui/system";
import { Flex } from "@rebass/grid";

import {
  Application,
  CurrentIteration,
  ExperimentPhaseType,
  ExperimentStatistics,
  Status,
} from "api/models";
import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";

import { refreshApplicationBCR } from "v2/api/mutations/application/refreshApplicationBCR";

import { convertDateToDisplayFormat, dateFormatWithTime } from "utils/dates";
import { phaseTypeToLink } from "utils/enums";
import { percentFormat } from "utils/numbers";
import { getTrafficIndicator } from "utils/statistics";

import { TextWithTooltip } from "components/TextWithTooltip";
import { StaticTable } from "v2/components/Table/StaticTable";
import { ActionableItem, Header } from "./styled";
import { TdComponent } from "v2/components/Table/components/TdComponent";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- TYPES
type OverviewStatusSectionProps = {
  application: Application;
  phaseTraffic: number;
  trafficNeeded: string;
  testsWeight: number;
  currentIteration?: CurrentIteration;
  currentPhaseType: ExperimentPhaseType;
  history: any;
  onRecalculateBcr: () => void;
  topPageDate: string;
  experimentStatistics: ExperimentStatistics;
};

// -- COMPONENT
export function OverviewStatusSection({
  application,
  phaseTraffic,
  trafficNeeded,
  testsWeight,
  currentIteration,
  currentPhaseType,
  history,
  onRecalculateBcr,
  topPageDate,
  experimentStatistics,
}: OverviewStatusSectionProps) {
  const currentlyTestingValue = `${
    experimentStatistics.creativesCurrentlyTesting
  }/${
    experimentStatistics.creativesCurrentlyTesting +
    experimentStatistics.creativesQueuedInPhase
  }`;

  const currentlyTesting = React.useCallback((): React.ReactNode => {
    const trafficIndicator = getTrafficIndicator(phaseTraffic, trafficNeeded);
    if (application.status === Status.calculatingBcr) {
      return (
        <Flex flexDirection="row" justifyContent={"space-between"}>
          <TextWithTooltip
            value="Establishing BCR"
            tooltipContent="Currently sending traffic to the Control until a new Baseline Conversion Rate (BCR) is established before continuing with tests"
          />
          {trafficIndicator}
        </Flex>
      );
    } else if (application.status === Status.active && testsWeight > 0) {
      const isSetTested = !!(currentIteration && currentIteration.testingSets);
      return (
        <Flex
          flexDirection="row"
          justifyContent={"space-between"}
          flexWrap="wrap"
        >
          <TestingProgressWrapper>
            {phaseTypeToLink(
              history,
              currentPhaseType,
              application.id,
              isSetTested,
            )}
            &nbsp;
            {currentlyTestingValue}
          </TestingProgressWrapper>
          {trafficIndicator}
        </Flex>
      );
    } else if (application.status === Status.inactive) {
      return "Application deactivated";
    } else {
      return "Nothing";
    }
  }, [
    application,
    history,
    currentPhaseType,
    currentIteration,
    phaseTraffic,
    trafficNeeded,
    testsWeight,
    currentlyTestingValue,
  ]);

  const recalculateBcrButton = React.useMemo<React.ReactNode>(() => {
    return (
      <ActionableItem onClick={onRecalculateBcr}>
        Recalculate BCR
      </ActionableItem>
    );
  }, [onRecalculateBcr]);

  const currentBcr = React.useCallback(() => {
    return application.status === Status.calculatingBcr ? (
      <TextWithTooltip
        value="Calculating"
        tooltipContent="Currently calculating the BCR, which is the conversion rate of the Control that will be used as a baseline for subsequent testing and optimization. The more traffic you send, the faster the BCR will be established."
      />
    ) : (
      <Flex flexDirection="row" justifyContent={"space-between"}>
        <TextWithTooltip
          value={percentFormat(application.currentBcr || 0)}
          tooltipContent="This is the initially established Baseline Conversion Rate."
        />
        <PermissionRestriction
          requiredPermissions={refreshApplicationBCR.requiredPermissions}
        >
          {recalculateBcrButton}
        </PermissionRestriction>
      </Flex>
    );
  }, [application, recalculateBcrButton]);

  const lastTopPageDiscovery = React.useMemo(() => {
    const text = topPageDate
      ? convertDateToDisplayFormat(topPageDate, dateFormatWithTime)
      : "N/A";
    const tooltipValue = `${
      topPageDate
        ? "The last time a new Top Converting Page has"
        : "A new Top Converting Page has not yet"
    } been discovered that performs better than the Control.`;

    return <TextWithTooltip value={text} tooltipContent={tooltipValue} />;
  }, [topPageDate]);

  const columns = React.useMemo<
    SingleTableColumn<{ label: unknown; value: unknown }>[]
  >(
    () => [
      {
        Header: "label",
        accessor: "label",
        tdElement: CustomLabelTd as any,
      },
      {
        Header: "value",
        accessor: "value",
        tdElement: CustomValueTd as any,
      },
    ],
    [],
  );

  const tableRows = React.useMemo(() => {
    const rowsList = [
      {
        label: "Currently Testing:",
        value: currentlyTesting(),
      },
      {
        label: "Last Top Page Discovery:",
        value: lastTopPageDiscovery,
      },
    ];

    if (
      !(
        application.currentBcr === null &&
        application.status !== Status.calculatingBcr
      )
    ) {
      rowsList.splice(1, 0, {
        label: "Current BCR:",
        value: currentBcr(),
      });
    }

    return rowsList;
  }, [
    currentlyTesting,
    currentBcr,
    lastTopPageDiscovery,
    application.currentBcr,
    application.status,
  ]);

  return (
    <>
      <Header>Status</Header>
      <StaticTable
        columns={columns}
        data={tableRows}
        displayHeader={false}
        highlightFirstCell
      />
    </>
  );
}

// -- STYLED
const TestingProgressWrapper = styled("span")`
  margin-right: 5px;
`;

const CustomLabelTd = styled(TdComponent)`
  width: 220px;
  font-size: 14px;
  padding-right: 0px;
`;

const CustomValueTd = styled(TdComponent)`
  font-size: 14px;
  background-color: ${(props: any) => props.theme.colors.backgroundLight};
`;
