import { isArray, isString, isPlainObject } from "lodash";

import { roundWithDecimalPlaces } from "utils/numbers";

import {
  AppConversionRates,
  ChartDataSetShape,
  ChartConfiguration,
  LegendElement,
  ReferenceLine,
  AxisDirection,
  ReferenceLinesTypes,
} from "api/models";
import { UserActionTypes } from "components/Chart/constants/userActionTypes";
import themes from "components/themes";
import { convertDateToDisplayFormat, dateFormatWithTime } from "./dates";

type ShipDatesData = {
  periodStartDate: string;
  dates: string[];
}[];

type UserActionsData = {
  periodStartDate: string;
  actions: {
    id: string;
    action: keyof typeof UserActionTypes;
    date: string;
  }[];
}[];

const chartDataColors: { [property: string]: string } = {
  "control": themes.colors.androidGreen,
  "traffic": themes.colors.brightCyan,
  "front-runner": themes.colors.lighterPurple,
};

export const prepareChartAreasData = (
  conversionRatesGroups: AppConversionRates[] | AppConversionRates,
): [ChartDataSetShape[], LegendElement[]] | null => {
  if (!isArray(conversionRatesGroups)) {
    if (isPlainObject(conversionRatesGroups)) {
      conversionRatesGroups = [conversionRatesGroups];
    } else {
      return null;
    }
  }

  const chartDataObject: any = {};
  const legendElements: LegendElement[] = [];

  conversionRatesGroups.forEach((singleRateGroup: AppConversionRates) => {
    const {
      label,
      conversionRates,
      legendOrderIndex,
      legendTooltip,
    } = singleRateGroup;

    if (!isString(label) || !isArray(conversionRates)) {
      return null;
    }

    const formattedLabel = label.toLowerCase();
    legendElements.push({
      label,
      dataIndex: `${formattedLabel}.cvr`,
      color: chartDataColors[formattedLabel],
      legendOrderIndex,
      legendTooltip,
    });

    conversionRates.forEach((singleConversion) => {
      const { date, cvr, conversions, visits } = singleConversion;

      chartDataObject[date] = {
        ...(chartDataObject[date] || {}),
        [formattedLabel]: {
          cvr: roundWithDecimalPlaces(cvr, 1),
          conversions,
          visits,
        },
      };
    });
  });

  const dataSet: ChartDataSetShape[] = Object.entries(chartDataObject).map(
    (entry: [string, any]) => {
      const [label, value] = entry;
      return {
        label,
        ...value,
      };
    },
  );

  return [dataSet, legendElements];
};

const prepareShipDatesLines = (shipDates: ShipDatesData): ReferenceLine[] => {
  if (!isArray(shipDates)) {
    return [];
  }

  return shipDates.map((singleShipDate) => {
    const { periodStartDate, dates } = singleShipDate;

    return {
      axisDirection: AxisDirection.vertical,
      axisValue: periodStartDate,
      tooltipValues: dates,
      type: ReferenceLinesTypes.shipDate,
    };
  });
};

const prepareUserActionLines = (
  userActions: UserActionsData,
): ReferenceLine[] => {
  if (!isArray(userActions)) {
    return [];
  }

  return userActions.map((singleUserAction) => {
    const { periodStartDate, actions } = singleUserAction;
    return {
      axisDirection: AxisDirection.vertical,
      axisValue: periodStartDate,
      tooltipValues: actions.map(
        (singleAction) =>
          `${convertDateToDisplayFormat(
            singleAction.date,
            dateFormatWithTime,
          )} - ${UserActionTypes[singleAction.action]}`,
      ),
      type: ReferenceLinesTypes.userAction,
      color: themes.colors.brightCyan,
    };
  });
};

export const prepareChartConfiguration = (
  conversionRatesGroups: AppConversionRates[] | AppConversionRates,
  shipDates: ShipDatesData,
  userActions: UserActionsData,
): ChartConfiguration => {
  let dataSet: ChartDataSetShape[] = [];
  const legendElements: LegendElement[] = [];
  const chartData = prepareChartAreasData(conversionRatesGroups);
  const shipDatesLines = prepareShipDatesLines(shipDates);
  const userActionsLines = prepareUserActionLines(userActions);

  if (Array.isArray(userActionsLines) && userActionsLines.length) {
    legendElements.push({
      color: themes.colors.brightCyan,
      label: "User Actions",
      isReferenceLine: true,
      dataIndex: ReferenceLinesTypes.userAction,
      legendOrderIndex: 0,
      legendTooltip: "",
    });
  }

  if (isArray(shipDatesLines) && shipDatesLines.length) {
    legendElements.push({
      color: themes.colors.chartLightPurple,
      label: "Ship Dates",
      isReferenceLine: true,
      dataIndex: ReferenceLinesTypes.shipDate,
      legendOrderIndex: 0,
      legendTooltip: "",
    });
  }

  if (chartData) {
    const [dataSetFromChartData, legendElementsFromChartData] = chartData;
    dataSet = dataSetFromChartData;
    legendElements.push(...legendElementsFromChartData);
  }

  const chartConfig: ChartConfiguration = {
    xAxisLabelByField: "label",
    dataSet,
    legendElements,
    referenceLines: [...userActionsLines, ...shipDatesLines],
  };

  return chartConfig;
};
