import React, { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { components, OptionProps, SingleValueProps } from "react-select";
import { PlatformContext } from "@upptic/module-directory";
import { observer } from "mobx-react";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";
import iosImage from "assets/images/ios_color.svg";
import androidImage from "assets/images/android_color.svg";

import { Platform, ClientApplication, AppPlatformListing } from "api";

import { ApplicationContext } from "contexts";

import { SelectInput } from "components/forms";
import { createUrlWithParams } from "v2/utils/url";

// -- TYPES
interface AppsList extends AppPlatformListing {
  name?: string;
  asoGroupId: string;
}

// -- COMPONENT
const ApplicationPlatformSwitcher: React.FC = () => {
  const applicationCtx = useContext(ApplicationContext);
  const platformStore = useContext(PlatformContext);
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    setSelectedOption(platformStore.selectedAppPlatform?.code || null);
  }, [setSelectedOption, platformStore.selectedAppPlatform]);

  const apps: AppsList[] = useMemo(() => {
    const applicationList = [...platformStore.applications]
      .sort((a: ClientApplication, b: ClientApplication) => {
        const aName = a.name?.toLowerCase();
        const bName = b.name?.toLowerCase();

        if (aName > bName) {
          return 1;
        }

        if (aName < bName) {
          return -1;
        }

        return 0;
      })
      .reduce((acc, application: ClientApplication) => {
        if (!application.appPlatform) return acc;

        const appPlatforms = application.appPlatform
          .filter(({ asoAppId }) => asoAppId)
          .map((platform) => ({
            ...platform,
            name: application.name,
            asoGroupId: application.asoGroupId,
          }));
        acc.push(...appPlatforms);
        return acc;
      }, [] as AppsList[]);

    if (!selectedOption) {
      return applicationList;
    }

    const selectedOptionFromList = applicationList.find(
      (singleApplication) => singleApplication.code === selectedOption,
    );

    if (!selectedOptionFromList) {
      return applicationList;
    }

    const correctApplicationList = applicationList
      .filter((singleApp) => singleApp.asoAppId !== selectedOption)
      .sort((a, b) => {
        if (a.asoGroupId === selectedOptionFromList.asoGroupId) {
          return -1;
        }

        if (b.asoGroupId === selectedOptionFromList.asoGroupId) {
          return 1;
        }

        return 0;
      });

    return [selectedOptionFromList, ...correctApplicationList];
  }, [platformStore.applications, selectedOption]);

  const onChangeApplication = (code: string) => {
    const selectedNewApp = apps.find(
      (singleAppGroup) => singleAppGroup.code === code,
    );
    const pathname = history.location.pathname;

    if (!selectedNewApp) {
      return false;
    }

    const parentApplication = platformStore.applications.filter(
      (app) => app.asoGroupId === selectedNewApp.asoGroupId,
    );

    platformStore.setSelectedAppPlatform(selectedNewApp);
    platformStore.setSelectedApplication(parentApplication[0]);
    setSelectedOption(selectedNewApp.code);

    if (platformStore.currentRoute.path.includes(":applicationCode")) {
      return history.push(
        createUrlWithParams(platformStore.currentRoute.path, {
          ...platformStore.currentRoute.params,
          applicationCode: parentApplication[0].code,
        }),
      );
    }

    if (
      (selectedNewApp.platform === Platform.android &&
        pathname?.includes("promo-art")) ||
      (selectedNewApp.platform === Platform.ios &&
        pathname?.includes("feature-graphic")) ||
      (selectedNewApp.platform === Platform.android &&
        pathname?.includes("subtitle")) ||
      !pathname?.includes(`/${applicationCtx.current?.id}/`)
    ) {
      history.push(
        authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({
          id: selectedNewApp.asoAppId,
        }),
      );
    }

    const path = history.location.pathname.replace(
      `/${applicationCtx.current?.id}/`,
      `/${selectedNewApp.asoAppId}/`,
    );
    history.push(path);
  };

  const SingleValue = (props: SingleValueProps<{ [key: string]: any }>) => {
    return (
      <components.SingleValue {...props}>
        <BrandIcon brandType={props.data.platform} />
        {props.data.name}
      </components.SingleValue>
    );
  };

  const Option = (props: OptionProps<{ [key: string]: any }, boolean>) => {
    return (
      <components.Option {...props}>
        <OptionContenWrapper>
          <BrandIcon brandType={props.data.platform} />
          {props.data.name}
        </OptionContenWrapper>
      </components.Option>
    );
  };

  if (apps.length < 2) {
    return null;
  }

  return (
    <Wrapper>
      <SelectInput
        labelKey={"name"}
        valueKey={"code"}
        value={selectedOption}
        options={apps}
        onChange={onChangeApplication}
        components={{ Option, SingleValue }}
      />
    </Wrapper>
  );
};

// -- STYLED
const Wrapper = styled.div`
  position: relative;
  width: 200px;
`;

const BrandIcon = styled.div<{ brandType: string }>`
  background-image: url(${(props) =>
    props.brandType === "ios" ? iosImage : androidImage});
  background-size: ${(props: any) =>
    props.brandType === "ios" ? "30px" : "25px"};
  height: 25px;
  width: 25px;
  float: left;
  margin-right: 5px;
  margin-left: 2px;
`;

const OptionContenWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default observer(ApplicationPlatformSwitcher);
