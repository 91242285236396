import styled from "styled-components";

import themes from "components/themes";

const DividerHorizontal = styled.div<{ opacity?: number; dark?: boolean }>`
  width: 100%;
  height: 1px;
  background: ${(props) =>
    props.dark ? themes.colors.backgroundDark : "#1e1e36"};
  margin: 0 auto;
  opacity: ${(props: any) => props.opacity || 1};
`;

export default DividerHorizontal;
