import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { SingleSideMenuItem } from "../sideMenuItem";

import { Icons } from "components/Icon";

import { UserPermissions } from "v2/api/types";

export const appsMenuElement = (
  permissions?: UserPermissions[] | null,
): SingleSideMenuItem => {
  const items = [
    {
      title: "App List",
      route: authRoutes.APPLICATION_LIST.getUrlWithParams(),
    },
  ];

  if (permissions?.includes(UserPermissions.createAppPlatformProfile)) {
    items.push({
      title: "Set Up New App",
      route: authRoutes.APPLICATION_CREATE.getUrlWithParams(),
    });
  }
  return {
    title: "Apps",
    icon: Icons.faImages,
    route: authRoutes.APPLICATION_LIST.getUrlWithParams(),
    additionalRoutes: [authRoutes.APPLICATION_CREATE.getUrlWithParams()],
    items,
  };
};

export const costPlannerMenuElement = (
  isMenuCollapsed: boolean,
): SingleSideMenuItem => {
  const costPlanerMenu: SingleSideMenuItem = {
    title: "Cost Planner",
    icon: Icons.faCoins,
    route: authRoutes.COST_PLANNER.getUrlWithParams(),
    items: [],
  };

  if (isMenuCollapsed) {
    costPlanerMenu.items = [
      {
        title: "Cost Planner",
        route: authRoutes.COST_PLANNER.getUrlWithParams(),
      },
    ];
  }

  return costPlanerMenu;
};
