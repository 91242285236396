import { useContext } from "react";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";

import { UserPermissions } from "v2/api/types/UserPermissions";

export function useHasUserPermission() {
  const platformStore = useContext<PlatformStore>(PlatformContext);

  const hasUserPerms = (requiredPermissions: UserPermissions[]) => {
    return requiredPermissions.length
      ? requiredPermissions.every((singlePerm) =>
          platformStore.currentUserFrontendPermissions.includes(singlePerm),
        )
      : true;
  };

  return hasUserPerms;
}
