import React from "react";
import styled from "styled-components";

import themes from "components/themes";
import NarrowTemplate from "./NarrowTemplate";
import logoImage from "assets/images/logo.svg";
import { Box } from "@rebass/grid";
import { TopBar } from "components";

interface Props {
  children: React.ReactNode;
  asIframe?: boolean;
}

function AuthTemplate({ children, asIframe = false }: Props) {
  return (
    <FullFlex>
      <Content>
        {window.location.pathname?.includes("/external/") ? (
          <>
            <TopBar collapsed={true} onMenuToggle={() => {}} showFocusMode />
            <Box flex={1}>{children}</Box>
          </>
        ) : (
          <>
            {!asIframe && (
              <Header>
                <Logo src={logoImage} alt="" />
              </Header>
            )}
            <NarrowTemplate>{children}</NarrowTemplate>
          </>
        )}
      </Content>
    </FullFlex>
  );
}

const FullFlex = styled.div`
  min-height: calc(100% - 60px); /* substract header height */
`;

const Header = styled.div`
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 4px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${themes.colors.white};
  margin-top: 30px;
  margin-bottom: 45px;
`;

const Logo = styled.img`
  width: 180px;
  height: 43px;
`;

const Content = styled.div`
  background-color: ${themes.colors.backgroundDark} !important;
`;

export default AuthTemplate;
