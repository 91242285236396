const primary = {
  codeName: "Dark_nova",
  colors: {
    purple: "#9743eb",
    lighterPurple: "#ac68ee",
    lightishPurple: "#e076fe",
    inputs: "#07071e",
    inputsLight: "rgba(7, 7, 30, 0.4)",
    backgroundDark: "#0c0c26",
    background: "#23233a",
    card: "#13132b",
    backgroundLight: "#212139",
    transparent: "transparent",
    white: "#ffffff",
    lightGrey: "#cacad0",
    grey: "rgba(255,255,255,.6)",
    darkGrey: "rgba(255,255,255,.4)",
    notificationRed: "#ff4f5a",
    notificationRedBoxShadow: "rgba(255, 79, 90, 0.5)",
    aquaMarine: "#47edb9",
    brightCyan: "#3bd0ff",
    androidGreen: "#008a5c",
    appleBlue: "#007aff",
    infoBlue: "#579ff5",
    infoBlueBoxShadow: "rgba(87, 159, 245, 0.5)",
    seaweed: "#1fdb6f",
    chartLightPurple: "#7b31c4",
    chartDarkPurple: "#44299f",
    borderGrey: "#454558",
    lightBlue: "#0090FF",
    yellow: "#f7ff00",
  },
  borderRadius: {
    sharp: "0px",
    lightCurve: "4px",
    curved: ".5em",
    round: "2em",
  },
  borderWidth: {
    small: "1px",
    medium: ".2em",
    large: ".5em",
  },
  borderStyle: {
    placeholder: "dashed 1px",
    normal: "solid 1px",
  },
  table: {
    colors: {
      tHeadBackgroundColor: "#212139",
      tFootBackgroundColor: "#212139",
      tHeadFontColor: "rgba(255,255,255,0.4)",
      borderColor: "#0c0c26",
    },
  },
  zIndexes: {
    fullPageLoader: 1000,
    tooltip: 999,
    modal: 998,
    sideMenu: 997,
  },
};

export default primary;
