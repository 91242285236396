import * as React from "react";
import { styled } from "@mui/system";
import { Box, Flex } from "@rebass/grid";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";
import { Application, FacebookPixel, Platform } from "api/models";

import themes from "components/themes";

import { displayPlatformName } from "utils/enums";
import validators from "utils/validators";
import { convertDateToDisplayFormat } from "utils/dates";
import { checkIsNumber } from "utils/numbers";

import HoverInput from "components/forms/HoverInput";
import Icon, { Icons } from "components/Icon";
import { StaticTable } from "v2/components/Table/StaticTable";
import { ActionableItem, Header, IconBox } from "./styled";
import { TextWithTooltip } from "components/TextWithTooltip";
import { HoverLink } from "components";
import { EditOnHoverWithRedirect } from "components/forms/EditOnHoverWithRedirect";
import { TdComponent } from "v2/components/Table/components/TdComponent";
import { UserPermissions } from "v2/api/types";
import { useHasUserPermission } from "v2/hooks/useHasUserPermission";

// -- TYPES
type OverviewDetailsSectionProps = {
  application: Application;
  onRedirectUrlChange: (value: string | number) => void;
  applicationGroup: {
    id: string;
    applications: {
      id: string;
    }[];
  };
  history: any;
  testUrl: string;
  liveUrls: string[];
  onSignificanceChange: (value: string | number) => void;
  facebookPixel?: FacebookPixel;
};

// -- COMPONENT
export function OverviewDetailsSection({
  application,
  onRedirectUrlChange,
  applicationGroup,
  history,
  testUrl,
  liveUrls,
  onSignificanceChange,
  facebookPixel,
}: OverviewDetailsSectionProps): JSX.Element {
  let editPixelLabel = "None";

  const hasUserPermissions = useHasUserPermission();

  if (facebookPixel) {
    editPixelLabel = facebookPixel.enabled ? "Enabled" : "Disabled";
  }

  const redirectUrl = React.useMemo(() => {
    return (
      <RedirectUrl
        clickable
        value={application.redirectUrl}
        onSave={onRedirectUrlChange}
        inputProps={{
          placeholder: "Enter custom path URL",
          size: 50,
        }}
        isEditAvailable={hasUserPermissions([
          UserPermissions.updateAppPlatformProfile,
        ])}
      />
    );
  }, [onRedirectUrlChange, application, hasUserPermissions]);

  const renderAppStoreValue = React.useCallback((): React.ReactNode => {
    const { platform } = application;
    const applications = applicationGroup && applicationGroup.applications;
    const otherVersion =
      applications && applications.find((app) => app.id !== application.id);
    const platformName = displayPlatformName(platform);

    if (!otherVersion) {
      return displayPlatformName(platform);
    }

    const handleVersionChange = () =>
      history.push(
        authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({
          id: otherVersion.id,
        }),
      );

    const buttonLabel = (
      <Flex alignItems="center">
        <IconBox>
          <Icon icon={Icons.faExchangeAlt} color={themes.colors.white} />
        </IconBox>
        <ActionableItem onClick={handleVersionChange}>
          {`View ${platform === Platform.ios ? "Android" : "iOS"} version`}
        </ActionableItem>
      </Flex>
    );

    return (
      <Flex justifyContent="space-between" alignItems="baseline">
        {platformName}
        {buttonLabel}
      </Flex>
    );
  }, [application, applicationGroup, history]);

  const previeLink = React.useMemo(() => {
    const label = (
      <TextWithTooltip
        value="Preview Link"
        tooltipContent="This link enables you to preview landing pages that Upptic created for your experiment. Please remember that views from this link are NOT accounted in the statistic."
      />
    );

    const value = (
      <HoverLink value={testUrl} clickable={true} copyable={true} />
    );

    return {
      label,
      value,
    };
  }, [testUrl]);

  const handleSignificanceChange = React.useCallback(
    (value: number | string) => {
      onSignificanceChange(Number(value) / 100);
    },
    [onSignificanceChange],
  );

  const significanceItem = React.useCallback(() => {
    const value = checkIsNumber(application.significance)
      ? application.significance * 100
      : 0;

    return {
      label: (
        <TextWithTooltip
          value="Confidence Level"
          tooltipContent="Target level of confidence in the results of a test. Lowering the confidence results in less visitors required for a test but also lowers the reliability of a test."
        />
      ),
      value: (
        <SignificanceInput
          clickable
          value={value}
          onSave={handleSignificanceChange}
          inputProps={{
            type: "number",
            size: 60,
            min: 10,
            max: 99,
          }}
          inputSufix="%"
          valueValidators={validators.composeValidators(
            false,
            validators.minValue(10),
            validators.maxValue(99),
          )}
          maxLength={2}
          isEditAvailable={hasUserPermissions([
            UserPermissions.updateAppPlatformProfile,
          ])}
        />
      ),
    };
  }, [application, handleSignificanceChange, hasUserPermissions]);

  const pixelItem = React.useMemo(() => {
    const label = (
      <TextWithTooltip
        value="Tracking Pixel"
        tooltipContent="You can add a tracking pixel for optimizations (i.e. from Facebook)"
      />
    );

    const value = (
      <EditOnHoverWithRedirect
        editLabel="Edit Pixel"
        editUrl={authRoutes.APPLICATION_PIXEL.getUrlWithParams({
          id: application.id,
        })}
        label={editPixelLabel}
        editPermissions={[UserPermissions.updateAppPlatformProfile]}
      />
    );

    return {
      label,
      value,
    };
  }, [application, editPixelLabel]);

  const columns = React.useMemo<
    SingleTableColumn<{ label: unknown; value: unknown }>[]
  >(
    () => [
      {
        Header: "label",
        accessor: "label",
        tdElement: CustomLabelTd as any,
        setRowSpan: true,
      },
      {
        Header: "value",
        accessor: "value",
        tdElement: CustomValueTd as any,
      },
    ],
    [],
  );

  const tableRow = React.useMemo(() => {
    return [
      {
        label: "App Store URL",
        value: redirectUrl,
      },
      {
        label: "ID",
        value: application.identifier,
      },
      {
        label: "App Store",
        value: renderAppStoreValue(),
      },
      {
        label: "Start Date",
        value: convertDateToDisplayFormat(application.createdAt),
      },
      previeLink,
      {
        label: "Live URL",
        value: <HoverLink value={liveUrls[0]} copyable={true} />,
      },
      significanceItem(),
      pixelItem,
    ];
  }, [
    redirectUrl,
    application,
    renderAppStoreValue,
    liveUrls,
    previeLink,
    significanceItem,
    pixelItem,
  ]);

  return (
    <>
      <Flex mt={30}>
        <Box>
          <Header>Details</Header>
        </Box>
      </Flex>

      <StaticTable
        columns={columns}
        data={tableRow}
        displayHeader={false}
        highlightFirstCell
      />
    </>
  );
}

// -- STYLED
const RedirectUrl = styled(HoverInput)`
  height: 20px;
`;

const CustomLabelTd = styled(TdComponent)`
  width: 220px;
  font-size: 14px;
  padding-right: 0px;
`;

const CustomValueTd = styled(TdComponent)`
  font-size: 14px;
  background-color: ${(props: any) => props.theme.colors.backgroundLight};
`;

const SignificanceInput = styled(HoverInput)`
  position: relative;

  height: 20px;

  > div:first-of-type {
    span::before {
      content: "%";

      position: absolute;
      left: 20px;
    }

    input {
      -moz-appearance: textfield;
      -webkit-appearance: none;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
`;
