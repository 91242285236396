import * as React from "react";
import { RouteComponentProps } from "react-router";

import {
  CurrentIteration,
  ReferenceVariationType,
  ScreensType,
} from "api/models";
import { StepData } from "components/application/forms/components/PreviewStep/Preview1";
import { UploadFile } from "components/forms/UploadInput";
import { SetPreviewData } from "components/application/forms/PreviewStep";

import { createPreviews } from "v2/api/mutations/application/previews";

import ApplicationUpdateContainer from "../ApplicationUpdateContainer";
import ApplicationUpdatePreviewsPage from "./ApplicationUpdatePreviewsPage";

import { transformSetsData } from "screens/Application/ApplicationCreate/ApplicationCreateContainer";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { GET_CURRENT_ITERATION } from "api/queries/assets";
import { useQuery } from "@apollo/client";
import { Spinner, Error } from "components";

interface Props extends RouteComponentProps<{ id: string; position: string }> {}

function transformData(data: UploadFile[], position: string) {
  return data.map((d) => ({
    isControl: false,
    position: parseInt(position, 10),
    videoId: d.videoId || null,
  }));
}

function ApplicationUpdatePreviewsContainer(props: Props) {
  const [fullPageLoader, setIsLoading] = useFullPageLoader();

  const { position, id: applicationId } = props.match.params;

  const { data, loading, error } = useQuery<{
    currentIteration: CurrentIteration;
  }>(GET_CURRENT_ITERATION, {
    skip: Number(position) !== 1,
    variables: { applicationId },
  });

  const handleOnSubmit = (onSubmitFunc: any) => async (
    formData: StepData | SetPreviewData,
  ) => {
    setIsLoading(true);

    let requestData = {};
    if (formData.screensType === ScreensType.set) {
      const { setsFiles, setsInfo } = transformSetsData(formData.sets);

      requestData = {
        setsInfo,
        files: setsFiles,
        previewsInfo: [],
      };
    } else {
      requestData = {
        previewsInfo: [...transformData(formData.assets, position)],
        files: [...formData.assets.map((d) => d.file)],
        referenceVariationType:
          formData.referenceVariationType || ReferenceVariationType.top,
        setsInfo: [],
      };
    }

    await onSubmitFunc(requestData);
    setIsLoading(false);
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <>
      {fullPageLoader}
      <ApplicationUpdateContainer
        mutation={createPreviews.definition}
        {...props}
      >
        {(application: any, onDiscard: () => void, onSubmit: () => void) => (
          <ApplicationUpdatePreviewsPage
            testingSets={data?.currentIteration.testingSets}
            position={position}
            onDiscard={onDiscard}
            onSubmit={handleOnSubmit(onSubmit)}
            platform={application.platform}
          />
        )}
      </ApplicationUpdateContainer>
    </>
  );
}

export default ApplicationUpdatePreviewsContainer;
