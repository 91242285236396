import * as React from "react";
import { Box } from "@rebass/grid";

import { ApplicationAsset, AssetStatus } from "api/models";
import { AssetProps } from "../constants/assetProps";

import { getStatisticsItems } from "../helpers/getStatisticsItems";

import { MessageBox, Section, StatsTable, TextAssetCard } from "components";
import { TrafficIndicator, ScrollFlex } from "./uiElement";

// -- COMPONENT
export function ApplicationTextAssetsSection({
  title,
  assets,
  onRemove,
  onDeactivate,
  canAbort = false,
  onSetTop,
  displayConversions = false,
  displayConversionRate = false,
  displayLift = false,
  displayVisits = false,
  controlConversionRate = 0,
  assetType,
  message = "There are no assets in this section yet.",
  titleTooltip = "",
  canRemove = false,
  trafficIndicator,
}: AssetProps) {
  const handleRemove = (asset: ApplicationAsset) => () =>
    onRemove && onRemove(asset);

  const handleDeactivate = (asset: ApplicationAsset) => () =>
    onDeactivate && onDeactivate(asset);

  const handleSetTop = (asset: ApplicationAsset) => () =>
    onSetTop && onSetTop(asset);

  return (
    <Section title={title} transparent titleTooltip={titleTooltip}>
      {canAbort && <TrafficIndicator>{trafficIndicator}</TrafficIndicator>}
      <ScrollFlex justifyContent="start">
        {assets.length ? (
          assets.map((asset: any) => {
            return (
              <Box key={`elements-${asset.id}`} mr={25}>
                <TextAssetCard
                  type={assetType}
                  title={asset.name}
                  control={asset.isControl}
                  onDelete={
                    asset.isControl || !onRemove || !canRemove
                      ? null
                      : handleRemove(asset)
                  }
                  onSetTop={
                    onSetTop && canAbort && asset.status === AssetStatus.testing
                      ? handleSetTop(asset)
                      : null
                  }
                  onDeactivate={
                    !asset.isControl &&
                    onDeactivate &&
                    asset.status === AssetStatus.testing
                      ? handleDeactivate(asset)
                      : null
                  }
                  historicalData={asset.historicalConversionData}
                  previewUrl={asset.previewLink}
                >
                  <StatsTable
                    items={getStatisticsItems(
                      asset,
                      canAbort,
                      displayConversions,
                      displayConversionRate,
                      displayLift,
                      displayVisits,
                      controlConversionRate,
                    )}
                  />
                </TextAssetCard>
              </Box>
            );
          })
        ) : (
          <MessageBox closeable={false}>{message}</MessageBox>
        )}
      </ScrollFlex>
    </Section>
  );
}
