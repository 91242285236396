import React from "react";
import { Box } from "@rebass/grid";

import { ExperimentPhase, ExperimentPhaseType, PromoArt } from "api/models";
import { ElementsProps } from "../constants/elementsProps";

import { HiddenFlex } from "../components/uiElement";
import { ApplicationImageAssets } from "../components/ApplicationImageAssets";

interface Props extends ElementsProps {
  applicationId: string;
  currentPhase?: ExperimentPhase | null;
  assets: PromoArt[];
  history: any;
  trafficIndicator?: string;
}

function ApplicationElementsPage({
  applicationId,
  history,
  currentPhase = null,
  assets,
  onControl,
  onRemove,
  onSetTop,
  trafficIndicator,
  currentIteration,
  onDeactivate,
}: Props) {
  return (
    <HiddenFlex flexDirection="column">
      <Box>
        <ApplicationImageAssets
          applicationId={applicationId}
          title="Promo Arts"
          assets={assets}
          onControl={onControl}
          onRemove={onRemove}
          onDeactivate={onDeactivate}
          onSetTop={onSetTop}
          phaseType={ExperimentPhaseType.promoArt}
          currentPhase={currentPhase}
          history={history}
          trafficIndicator={trafficIndicator}
          currentIteration={currentIteration}
        />
      </Box>
    </HiddenFlex>
  );
}

export default ApplicationElementsPage;
