import React from "react";
import styled from "styled-components";

import themes from "components/themes";

interface ToggleItem {
  value: any;
  name: string;
  disabled?: boolean;
}

interface Props {
  name: string;
  disabled?: boolean;
  value?: any;
  items: ToggleItem[];
  onChange: (value: any) => void;
}

function ButtonToggle({
  name,
  disabled = false,
  value = "",
  items,
  onChange,
}: Props) {
  return (
    <Container disabled={disabled}>
      {items.map((option) => {
        const active = option.value === value;

        return (
          <Option
            key={`${name}-${option.value}`}
            onClick={() => !disabled && onChange(option.value)}
            active={active}
          >
            <OptionText>{option.name}</OptionText>
          </Option>
        );
      })}
    </Container>
  );
}

const Container = styled.div<{ disabled: boolean }>`
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events:none;
  `};
`;

const Option = styled.a<{ active: boolean }>`
  cursor: pointer;
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-width: 160px;
  height: 38px;
  background-color: ${(props) =>
    props.active ? themes.colors.lighterPurple : "transparent"};
  border: 1px solid ${themes.colors.background};
  border-radius: 19px;

  &:hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
    text-decoration: none;
  }
`;

const OptionText = styled.span`
  font-size: 14px;
  text-align: center;
  color: ${themes.colors.white};
`;

export default ButtonToggle;
