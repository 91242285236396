import React, { FC, useState } from "react";
import styled from "styled-components";

import { CardElement } from "@stripe/react-stripe-js";
import { FormError } from "components/forms";

import theme from "components/themes";

// -- TYPES
interface Props {
  onChange: (isValid: boolean) => void;
  externalError?: string | null;
}

// -- COMPONENT
export const CreditCardInput: FC<Props> = ({ onChange, externalError }) => {
  const [cardError, setCardError] = useState<string | null>(null);

  const onInputChange = (e: any) => {
    const { error, complete } = e;
    const isValid = !error && complete;

    setCardError(error ? error.message : null);
    onChange(isValid);
  };

  return (
    <>
      <Wrapper error={!!cardError || !!externalError}>
        <CardElement options={CARD_OPTIONS} onChange={onInputChange} />
      </Wrapper>
      {cardError || externalError ? (
        <FormError>{cardError || externalError}</FormError>
      ) : null}
    </>
  );
};

// -- STYLED
const Wrapper = styled.div<{ error: boolean }>`
  padding: 0 15px;

  background-color: ${theme.colors.inputs};

  border: 1px solid ${theme.colors.borderGrey};
  border-radius: 2px;

  ${(props) =>
    props.error &&
    `
    box-shadow: 0 0 15px 0 rgba(255,79,90,0.5);
    border: 1px solid ${theme.colors.notificationRed};
  `}
`;

// -- STYLE CONFIG
const CARD_OPTIONS: any = {
  iconStyle: "solid",
  style: {
    base: {
      "backgroundColor": theme.colors.inputs,
      "iconColor": `rgba(255, 255, 255, 0.3)`,
      "color": `rgba(255, 255, 255, 0.8)`,
      "borderRadius": "2px",
      "fontWeight": 300,
      "fontSize": "16px",
      "lineHeight": "36px",
      "::placeholder": {
        fontSize: "16px",
        fontWeight: 300,
        color: "rgba(255, 255, 255, 0.3)",
      },
      "fontFamily": "'Muli', sans-serif",
      ":-webkit-autofill": {
        color: `rgba(255, 255, 255, 0.8)`,
      },
    },
    invalid: {
      color: theme.colors.notificationRed,
      iconColor: theme.colors.notificationRed,
    },
  },
};
