import { gql } from "@apollo/client";

export const CALCULATE_COST = gql`
  mutation calculateCost(
    $estimateConversionRate: Float!
    $costPerClick: Float!
    $numberOfVariationsPerAssetType: [Int]!
  ) {
    calculateCost(
      estimateConversionRate: $estimateConversionRate
      costPerClick: $costPerClick
      numberOfVariationsPerAssetType: $numberOfVariationsPerAssetType
    ) {
      cost {
        lowerPrice
        higherPrice
      }
    }
  }
`;
