import * as React from "react";
import { useQuery } from "@apollo/client";
import { ProgressBar, Table } from "react-bootstrap";
import { styled } from "@mui/system";

import { toLocaleNumber } from "utils/numbers";

import { TIMESPENT_CHART_DATA } from "api/queries/analytics";

import { Spinner, Error } from "components";
import ChartLegend from "components/Chart/ChartLegend";
import { TimeSpentChartDataResponse } from "./constants/timespentChartDataResponse";

// -- TYPES
type HeatmapChartProps = {
  applicationId: string;
};

// -- COMPONENT
export function TimeSpentChart({ applicationId }: HeatmapChartProps) {
  const { loading, data, error } = useQuery<{
    timespentChartData: TimeSpentChartDataResponse;
  }>(TIMESPENT_CHART_DATA, {
    variables: {
      applicationId,
    },
  });

  const renderRows = () => {
    return data?.timespentChartData.dataSet.map((row: any, idx: number) => (
      <React.Fragment key={`${row.name}-${idx}-first-row`}>
        <tr>
          <Cell rowSpan={2} style={{ borderBottom: "solid 4px #454d55" }}>
            <span style={{ fontWeight: "bold" }}>{row.name}</span> <br />{" "}
            seconds
          </Cell>
          <Cell>
            {row.visits_for_current
              ? toLocaleNumber(Number(row.visits_for_current))
              : null}
          </Cell>
          <Cell style={{ width: "10%" }}>
            {toLocaleNumber(row.control_variation || 0, {
              numberOfDecimalPlaces: 2,
            })}{" "}
            %
          </Cell>
          <Cell>
            <CustomProgressBar
              type={"control"}
              color={"test"}
              now={Number.parseFloat(row.control_variation)}
            />
          </Cell>
        </tr>
        <tr style={{ borderBottom: "solid 4px #454d55" }}>
          <Cell>{row.visits_for_top}</Cell>
          <Cell style={{ width: "10%" }}>
            {toLocaleNumber(row.top_variation || 0, {
              numberOfDecimalPlaces: 2,
            })}{" "}
            %
          </Cell>
          <Cell>
            <CustomProgressBar
              type={"top"}
              color={"test"}
              now={Number.parseFloat(row.top_variation)}
            />
          </Cell>
        </tr>
      </React.Fragment>
    ));
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <>
      <TimeSpentTable bordered variant="dark" size={"md"}>
        <thead>
          <tr>
            <ColumnHeader style={{ width: "15%" }}>Time on Page</ColumnHeader>
            <ColumnHeader style={{ width: "15%" }}>
              {toLocaleNumber(data?.timespentChartData.totalVisits || 0)} <br />{" "}
              total sessions
            </ColumnHeader>
            <ColumnHeader style={{ width: "10%" }}>%</ColumnHeader>
            <ColumnHeader style={{ width: "70%" }} />
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </TimeSpentTable>
      <ChartLegend
        onDataVisibleChange={() => {}}
        elements={data?.timespentChartData.legendElements || []}
        disabledElements={[]}
      />
    </>
  );
}

// -- STYLES
const TimeSpentTable = styled(Table)`
  background: None;
  text-align: center;
`;

const Cell = styled("td")`
  vertical-align: middle !important;
`;

const ColumnHeader = styled("th")`
  vertical-align: middle !important;
`;

const CustomProgressBar = styled(ProgressBar)<{ type: string }>`
  background-color: #46494c;

  & > div {
    background-color: ${(props: any) =>
      props.type === "control"
        ? props.theme.colors.lightBlue
        : props.theme.colors.lighterPurple};
  }
`;

export default React.memo(TimeSpentChart);
