import queryString from "query-string";
import { getCookie, setCookie } from "react-use-cookie";

export function useDailyPlotCookie() {
  const changeDailyPlot = queryString.parse(window.location.search, {
    parseBooleans: true,
  }).dailyPlot;
  let useDailyPlot;
  if (changeDailyPlot === undefined) {
    useDailyPlot = !!getCookie("dailyPlot");
  } else {
    setCookie("dailyPlot", changeDailyPlot ? "true" : "");
    useDailyPlot = changeDailyPlot;
  }
  return useDailyPlot;
}
