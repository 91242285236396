import React, { useState } from "react";
import styled from "styled-components";
import { Field, Form, FormSpy } from "react-final-form";
import { Flex, Box } from "@rebass/grid";

import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";

import { AuthSection } from "components/auth";
import { Button, FormLabel, TextInput } from "components/forms";
import validators from "utils/validators";
import formatters from "utils/formatters";

export interface FormData {
  email: string;
}

interface ForgotPasswordPageProps {
  onSubmit: (data: FormData) => void;
  userEmail?: string;
}

function ForgotPasswordPage({ onSubmit, userEmail }: ForgotPasswordPageProps) {
  const defaultState: FormData = {
    email: userEmail || "",
  };
  const [formData, setFormData] = useState(defaultState);

  return (
    <Form onSubmit={() => onSubmit(formData)} initialValues={defaultState}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormSpy
            subscription={{ values: true }}
            onChange={(data: any) => setFormData(data.values)}
          />

          <AuthSection
            title="Reset Password"
            /* hints={[
              {
                title: "Don’t have an account?",
                link: "Sign up",
                route: nonAuthRoutes.AUTH_SIGN_UP.getUrlWithParams(),
              },
            ]} */
          >
            <Field
              name="email"
              initialValue={formData.email}
              validate={(value: any) =>
                validators.required(value) || validators.email(value)
              }
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="email">Email</FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Your Email"
                      maxLength={50}
                      light
                      {...input}
                      error={meta.touched && meta.error}
                    />
                  </div>
                );
              }}
            </Field>

            <Flex justifyContent="flex-end" alignItems="center" my={50}>
              <Box>
                <SubmitButton disabled={submitting} type="submit">
                  Send
                </SubmitButton>
              </Box>
            </Flex>
          </AuthSection>
        </form>
      )}
    </Form>
  );
}

const SubmitButton = styled(Button)`
  min-width: 240px;
`;

export default ForgotPasswordPage;
