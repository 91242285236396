import { styled } from "@mui/system";

export const Label = styled("label")`
  opacity: 0.4;
  font-size: 14px;
  margin-bottom: 24px;
  color: ${(props: any) => props.theme.colors.white};
`;

export const FormField = styled("p")`
  font-size: 16px;
  font-weight: 300;
  color: ${(props: any) => props.theme.colors.white};
`;
