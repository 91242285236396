import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const setTopPageAsNewControl = new ApiMutation({
  definition: gql`
    mutation setTopPageAsNewControl($applicationId: ID!) {
      setTopPageAsNewControl(applicationId: $applicationId) {
        isUpdated
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateAppPlatformProfile],
});

export interface SetTopPageAsNewControlResponse {
  setTopPageAsNewControl: {
    isUpdated: boolean;
  };
}
