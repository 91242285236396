import validator from "validator";
import { isEmpty } from "lodash";
import { Platform } from "api";

const urlOptions = {
  require_valid_protocol: true,
  require_protocol: true,
  protocols: ["http", "https"],
};

const PASSWORD_LENGTH = 8;
const MIN_PASSWORD_DIFFERENTIAL = 3;
const AGE_LIMIT_REGEXP = /^[0-9]{1,2}\+?$/;

const isPasswordDifferential = (value: string) => {
  const chars = value.split("");
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const lowercase = "abcdefghijklmnopqrstuvwxyz".split("");
  const digits = "0123456789".split("");
  const special = "!\"#$%&\\'()*+,-./:;<=>?@[\\\\]^_`{|}~".split("");

  const validGroups = [uppercase, lowercase, digits, special].filter((group) =>
    chars.some((ch) => group.includes(ch)),
  );

  return validGroups.length >= MIN_PASSWORD_DIFFERENTIAL;
};

const required = (value: any) => {
  if (Array.isArray(value)) {
    value = value.length;
  }

  return value ? undefined : "Required";
};

const requiredDependOnOtherFields = (requiredFields: string[]) => (
  value: any,
  allValues: { [property: string]: any },
): "Required" | undefined => {
  // TODO: check working with compose validators
  if (!Array.isArray(requiredFields)) {
    return undefined;
  }

  const areOtherFieldsHaveValue = requiredFields.some(
    (fieldName) => allValues[fieldName],
  );

  return areOtherFieldsHaveValue && !value ? "Required" : undefined;
};

const requiredOneOf = (fieldsNames: string[]) => (
  value: any,
  allValues: { [property: string]: any },
): string | undefined => {
  // TODO: check working with compose validators
  if (!Array.isArray(fieldsNames)) {
    return undefined;
  }

  const anyFieldHasValue = fieldsNames.some(
    (fieldName) => allValues[fieldName],
  );

  return !anyFieldHasValue ? "One of this fields is required" : undefined;
};

const url = (value: string) =>
  validator.isURL(value, urlOptions) ? undefined : "Invalid URL";
const email = (value: string) =>
  validator.isEmail(value) ? undefined : "Invalid email address";
const passwordLength = (value: any) =>
  value.length >= PASSWORD_LENGTH
    ? undefined
    : "At least 8 characters required";
const passwordStrength = (value: any) =>
  isPasswordDifferential(value)
    ? undefined
    : "At least 1 uppercase letter, 1 lowercase letter, and 1 number required";
const passwordMatch = (value1: any, value2: any) =>
  value1 === value2 ? undefined : "Passwords do not match";
const ageLimitChars = (value: any) => {
  return AGE_LIMIT_REGEXP.test(value)
    ? undefined
    : "Only 2 digits and an optional '+' are allowed";
};
const minValue = (min: number) => (value: any) =>
  value === undefined || value === "" || parseInt(value, 10) >= min
    ? undefined
    : `Value is too low. Should be greater than or equal to ${min}`;

const maxValue = (max: number) => (value: any) =>
  value === undefined || value === "" || parseInt(value, 10) <= max
    ? undefined
    : `Value is too high. Should be less than or equal to ${max}`;

const minFloatValue = (min: number) => (value: any) =>
  parseFloat(value) >= min
    ? undefined
    : `Value is too low. Should be greater than or equal to ${min}`;
const maxFloatValue = (max: number) => (value: any) =>
  parseFloat(value) <= max
    ? undefined
    : `Value is too high. Should be less than or equal to ${max}`;

const maxStringLength = (maxLength: number) => (text: string) => {
  return text && text.length > maxLength
    ? `The field exceeds the maximum ${maxLength} characters limit`
    : undefined;
};

const applicationName = async (
  name: string,
  platform: string,
  userId: null | number,
  query: any,
) => {
  if (!name || !platform) {
    return undefined;
  }

  const { data } = await query({
    name,
    platform,
  });

  if (!data || !data.checkApplicationName) {
    return undefined;
  }

  return "Application with given name already exists";
};

const androidText = (text: string) => {
  if (text && Boolean(text.match(/[^\p{L}\p{N}\p{P}\p{Z}]/gu))) {
    return "No emojis allowed";
  }
  return undefined;
};

const androidTexts = (textList: string[], platform: Platform) => {
  if (platform === Platform.android) {
    for (const text of textList) {
      const androidTextValidation = androidText(text);
      if (androidTextValidation) {
        return androidTextValidation;
      }
    }
  }
  return undefined;
};

const composeValidators = (valueCanBeEmpty: boolean, ...validators: any) => (
  value: any,
  allValues: any,
) => {
  if (valueCanBeEmpty) {
    if (typeof value !== "object" && !value) {
      return undefined;
    } else if (typeof value === "object" && isEmpty(value)) {
      return undefined;
    }
  }

  return validators.reduce(
    (error: string | undefined, validate: any) =>
      error || validate(value, allValues),
    undefined,
  );
};

const applicationAssetsCount = (
  assets: any[],
  min: number,
  max: number,
): string | undefined => {
  const assetsLength = assets.length;
  if (assetsLength < min) {
    const msg =
      min === 1
        ? "Required"
        : `At least ${min} asset${min > 1 ? "s are" : " is"} required`;
    return msg;
  }

  if (assetsLength > max) {
    return `The number of assets allowed is ${max}`;
  }

  return undefined;
};

const checkForSpecialCharacters = (text: string) => {
  if (typeof text !== "string") {
    return undefined;
  }

  const reg = new RegExp("^[a-zA-Z0-9]*$");

  const isStringContainsSpecials = !reg.test(text);

  if (isStringContainsSpecials) {
    return "The field should not contain special characters";
  }

  return undefined;
};

const isDate = (date: string) => {
  if (date === undefined) {
    return "Please enter a valid date";
  }
  if (!date) return;
};

export default {
  urlOptions,
  required,
  url,
  email,
  passwordLength,
  passwordStrength,
  passwordMatch,
  ageLimitChars,
  minValue,
  maxValue,
  applicationName,
  androidText,
  androidTexts,
  composeValidators,
  maxStringLength,
  minFloatValue,
  maxFloatValue,
  applicationAssetsCount,
  requiredDependOnOtherFields,
  requiredOneOf,
  checkForSpecialCharacters,
  isDate,
};
