import React from "react";
import styled from "styled-components";
import themes from "./themes";

interface Props {
  children: React.ReactNode;
}

function FloatingLabel({ children }: Props) {
  return (
    <Container>
      <Label>{children}</Label>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
`;

const Label = styled.div`
  display: inline-block;
  height: 24px;
  text-align: center;
  padding: 3px 5px;
  color: ${themes.colors.white};
  background-color: ${themes.colors.purple};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 12px;
`;

export default FloatingLabel;
