import { gql } from "@apollo/client";

import { Route } from "api/models";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const updateApplicationRoute = new ApiMutation({
  definition: gql`
    mutation updateRoute($id: ID!, $url: String, $weight: Int!) {
      updateRoute(id: $id, url: $url, weight: $weight) {
        isUpdated
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateASORouting],
});

export interface UpdateApplicationRouteResponse {
  updateRoute: {
    isUpdated: boolean;
  };
}

export const deleteApplicationRoute = new ApiMutation({
  definition: gql`
    mutation deleteRoute($id: ID!) {
      deleteRoute(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASORouting],
});

export interface DeleteApplicationRouteResponse {
  deleteRoute: {
    isDeleted: boolean;
  };
}

export const createApplicationRoute = new ApiMutation({
  definition: gql`
    mutation createRoute($applicationId: ID!, $url: String!, $weight: Int!) {
      createRoute(applicationId: $applicationId, url: $url, weight: $weight) {
        isCreated
        route {
          id
          type
          weight
          url
          visits
        }
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASORouting],
});

export interface CreateApplicationRouteResponse {
  createRoute: {
    isCreated: boolean;
    route: Route;
  };
}
