import React, { FC, memo } from "react";
import { Box, Flex } from "@rebass/grid";
import { Field } from "react-final-form";
import styled from "styled-components";

// import { MessageBox } from "components";
import {
  DatePicker,
  FormHeader,
  FormLabel,
  SelectInput,
  TextArea,
  TextInput,
  NumberInput,
} from "components/forms";
import validators from "utils/validators";
import formatters from "utils/formatters";
import FormSection from "components/forms/FormSection";
import {
  ageLimitsAndroidOptions,
  amountOptions,
  ratingsOptions,
} from "components/forms/data/data";

import { Platform } from "api/models";
import { SingleReview } from "api/models/reviews/singleReviews";

import { DetailCard } from "../DetailCard";
import { ReviewsWizard } from "./Reviews/ReviewsWizard";
import { TagInput } from "components/forms/TagInput/TagInput";
import { StaticInfoMDL } from "./StaticInfoMDL";

// -- TYPES
export interface StepData {
  publisher: string;
  rating: string;
  downloads: string;
  whatsNew: string;
  numberOfRatings: string;
  ageLimit: string;
  tags: string[];
  releaseDate: string;
  appVersion: string;
  gameplayRating: string;
  controlRating: string;
  graphicRating: string;
  developerPage?: string;
  developerEmail?: string;
  developerAddress1?: string;
  developerAddress2?: string;
  developerCity?: string;
  developerState?: string;
  developerPostalCode?: string;
  developerCountry?: string;
  privacyPolicyUrl?: string;
  reviews: SingleReview[];
  mdl: number;
}

type Props = {
  data: StepData;
  onChange: (values: {
    [key: string]: string | number | null | object;
  }) => void;
};

// -- COMPONENT
const StaticInfoAndroid: FC<Props> = ({ data, onChange }) => {
  const onDatepickerChange = (input: any) => (date: any) => {
    onChange({
      releaseDate: date,
    });

    input.onChange(date);
  };

  return (
    <>
      <FormHeader>Static Info</FormHeader>

      <FormSection>
        <FormHeader>App Details</FormHeader>
        <Flex justifyContent="space-between">
          <Box width={1}>
            <StyledCard>
              <Field
                name="publisher"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                  validators.androidText,
                )}
                formatOnBlur
                format={formatters.trim}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="publisher" spacing={false}>
                        Publisher
                      </FormLabel>
                      <TextInput
                        type="text"
                        placeholder="Enter Developer Name"
                        maxLength={30}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          onChange({
                            publisher: event.target.value,
                          });
                          input.onChange(event.target.value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1 / 2}>
            <StyledCard>
              <Field name="ageLimit" validate={validators.required}>
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="ageLimit" spacing={false}>
                        Content Rating
                      </FormLabel>
                      <SelectInput
                        value={data.ageLimit}
                        options={ageLimitsAndroidOptions}
                        valid={!(meta.touched && meta.error)}
                        error={meta.touched && meta.error}
                        onChange={(value: any) => {
                          onChange({
                            ageLimit: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
          <Box width={1 / 2}>
            <StyledCard>
              <Field name="downloads" validate={validators.required}>
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="downloads" spacing={false}>
                        Select App Downloads
                      </FormLabel>
                      <SelectInput
                        value={data.downloads}
                        options={amountOptions}
                        valid={!(meta.touched && meta.error)}
                        error={meta.touched && meta.error}
                        onChange={(value: any) => {
                          onChange({
                            downloads: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
        </Flex>
      </FormSection>

      <FormSection>
        <FormHeader>App Rating</FormHeader>
        <Flex justifyContent="space-between">
          <Box width={1 / 2}>
            <StyledCard>
              <Field name="rating" validate={validators.required}>
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="rating" spacing={false}>
                        Rating
                      </FormLabel>
                      <SelectInput
                        value={data.rating}
                        options={ratingsOptions}
                        valid={!(meta.touched && meta.error)}
                        error={meta.touched && meta.error}
                        onChange={(value: any) => {
                          onChange({
                            rating: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
          <Box width={1 / 2}>
            <StyledCard>
              <Field
                name="numberOfRatings"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                  validators.minValue(1),
                  validators.maxValue(99999999),
                )}
                formatOnBlur
                format={formatters.trimNumber}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="numberOfRatings" spacing={false}>
                        Number of Ratings
                      </FormLabel>
                      <TextInput
                        type="text"
                        placeholder="E.g. 12345"
                        maxLength={8}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          if (isNaN(value)) {
                            return false;
                          }

                          onChange({
                            numberOfRatings: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1 / 3}>
            <StyledCard>
              <Field name="gameplayRating">
                {({ meta, input }) => {
                  return (
                    <div>
                      <FormLabel name="gameplayRating" spacing={false}>
                        Gameplay (Optional)
                      </FormLabel>
                      <SelectInput
                        isClearable
                        error={meta.error}
                        valid={!(meta.touched && meta.error)}
                        value={data.gameplayRating}
                        options={ratingsOptions}
                        onChange={(value: any) => {
                          onChange({
                            gameplayRating: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
          <Box width={1 / 3}>
            <StyledCard>
              <Field name="controlRating">
                {({ meta, input }) => {
                  return (
                    <div>
                      <FormLabel name="controlRating" spacing={false}>
                        Control (Optional)
                      </FormLabel>
                      <SelectInput
                        isClearable
                        error={meta.error}
                        valid={!(meta.touched && meta.error)}
                        value={data.controlRating}
                        options={ratingsOptions}
                        onChange={(value: any) => {
                          onChange({
                            controlRating: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
          <Box width={1 / 3}>
            <StyledCard>
              <Field name="graphicRating">
                {({ meta, input }) => {
                  return (
                    <div>
                      <FormLabel name="graphicRating" spacing={false}>
                        Graphic (Optional)
                      </FormLabel>
                      <SelectInput
                        isClearable
                        error={meta.error}
                        valid={!(meta.touched && meta.error)}
                        value={data.graphicRating}
                        options={ratingsOptions}
                        onChange={(value: any) => {
                          onChange({
                            graphicRating: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
        </Flex>
      </FormSection>

      <FormSection>
        <FormHeader>Game Info</FormHeader>
        <Flex justifyContent="space-between">
          <Box width={3 / 10} mr={32}>
            <StyledCard>
              <Field
                name="appVersion"
                value={data.appVersion}
                formatOnBlur
                format={formatters.trim}
                validate={(value) => validators.required(value)}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="appVersion" spacing={false}>
                        App Version
                      </FormLabel>
                      <TextInput
                        type="text"
                        placeholder="E.g. 1.0.13"
                        maxLength={8}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          onChange({
                            appVersion: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>

          <Box width={3 / 10} mr={32}>
            <StyledCard>
              <Field
                name="size"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                  validators.minValue(1),
                  validators.maxValue(9999.9),
                )}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="size" spacing={false}>
                        Size (MB)
                      </FormLabel>
                      <NumberInput
                        decimalSpaces={1}
                        placeholder="E.g. 123.33"
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(size: number | null) => onChange({ size })}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>

          <Box width={3 / 10} mr={32}>
            <StyledCard>
              <Field
                name="releaseDate"
                value={data.releaseDate}
                validate={validators.composeValidators(
                  false,
                  validators.isDate,
                  validators.required,
                )}
                format={formatters.trim}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="releaseDate" spacing={false}>
                        Release Date
                      </FormLabel>
                      <DatePicker
                        onChange={onDatepickerChange(input)}
                        id="singleDatePicker"
                        onFocusChange={(focused) =>
                          focused ? input.onFocus() : input.onBlur()
                        }
                        externalError={meta.touched && meta.error}
                        readOnly={false}
                        allowClear={false}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1 / 2} mr={32}>
            <StyledCard>
              <FormLabel name="tags" spacing={false}>
                Tags (Optional, Max 5)
              </FormLabel>
              <Flex justifyContent="space-between" alignItems="center">
                <Box width={1}>
                  <Field name="tags" formatOnBlur>
                    {() => {
                      return (
                        <TagInput
                          onChange={(newTags: string) =>
                            onChange({
                              tags: newTags,
                            })
                          }
                          value={data.tags}
                          maxElementsCount={5}
                          placeholder="Enter tags"
                        />
                      );
                    }}
                  </Field>
                </Box>
              </Flex>
            </StyledCard>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1}>
            <StyledCard>
              <Field
                name="whatsNew"
                value={data.whatsNew}
                validate={validators.maxStringLength(500)}
                formatOnBlur
                format={formatters.trim}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="whatsNew" spacing={false}>
                        What's New (Optional)
                      </FormLabel>
                      <TextArea
                        type="text"
                        valid={!(meta.touched && meta.error)}
                        placeholder="Start typing here…"
                        maxLength={500}
                        error={meta.touched && meta.error}
                        {...input}
                        onChange={(event: any) => {
                          const { value } = event.target;
                          onChange({
                            whatsNew: value,
                          });
                          input.onChange(value);
                        }}
                      />
                    </div>
                  );
                }}
              </Field>
            </StyledCard>
          </Box>
        </Flex>
      </FormSection>

      <FormSection>
        <FormHeader>Developer Contact (Optional)</FormHeader>
        <Flex justifyContent="space-between">
          <Box width={1}>
            <Field name="developerPage" formatOnBlur format={formatters.trim}>
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerPage">Page (Optional)</FormLabel>
                    <TextInput
                      type="text"
                      placeholder="E.g. https://company.com"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerPage}
                      onChange={(event: any) => {
                        onChange({
                          developerPage: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1}>
            <Field name="developerEmail" formatOnBlur format={formatters.trim}>
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerEmail">
                      Email Address (Optional)
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="john.doe@example.com"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerEmail}
                      onChange={(event: any) => {
                        onChange({
                          developerEmail: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1 / 2} mr={32}>
            <Field
              name="developerAddress1"
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerAddress1">
                      Address Line 1 (Optional)
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Street, PO Box"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerAddress1}
                      onChange={(event: any) => {
                        onChange({
                          developerAddress1: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
          <Box width={1 / 2}>
            <Field
              name="developerAddress2"
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerAddress2">
                      Address Line 2 (Optional)
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Apartment, Suite, Unit or Building"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerAddress2}
                      onChange={(event: any) => {
                        onChange({
                          developerAddress2: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1 / 2} mr={32}>
            <Field name="developerCity" formatOnBlur format={formatters.trim}>
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerCity">City (Optional)</FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter City, District, Suburb, Town or Village"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerCity}
                      onChange={(event: any) => {
                        onChange({
                          developerCity: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
          <Box width={1 / 2}>
            <Field name="developerState" formatOnBlur format={formatters.trim}>
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerState">
                      State (Optional)
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter State, Country, Province or Region"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerState}
                      onChange={(event: any) => {
                        onChange({
                          developerState: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1 / 2} mr={32}>
            <Field
              name="developerPostalCode"
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerPostalCode">
                      Postal Code (Optional)
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter ZIP or Postal Code"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerPostalCode}
                      onChange={(event: any) => {
                        onChange({
                          developerPostalCode: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
          <Box width={1 / 2}>
            <Field
              name="developerCountry"
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="developerCountry">
                      Country (Optional)
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="Enter Country Name"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.developerCountry}
                      onChange={(event: any) => {
                        onChange({
                          developerCountry: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
        </Flex>
        <Flex justifyContent="space-between">
          <Box width={1}>
            <Field
              name="privacyPolicyUrl"
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="privacyPolicyUrl">
                      Privacy Policy (Optional)
                    </FormLabel>
                    <TextInput
                      type="text"
                      placeholder="E.g. https://company.com"
                      maxLength={200}
                      error={meta.touched && meta.error}
                      {...input}
                      value={data.privacyPolicyUrl}
                      onChange={(event: any) => {
                        onChange({
                          privacyPolicyUrl: event.target.value,
                        });
                      }}
                    />
                  </div>
                );
              }}
            </Field>
          </Box>
        </Flex>
      </FormSection>

      <ReviewsWizard
        onChange={onChange}
        data={data.reviews}
        platform={Platform.android}
      />

      <StaticInfoMDL mdlValue={data.mdl} onChange={onChange} />
    </>
  );
};

const StyledCard = styled(DetailCard as any)`
  min-height: 140px;

  padding: 30px;
`;

export const StaticInfoAndroidForm = memo(StaticInfoAndroid);
