import React, { useContext } from "react";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { Form, Field, FormRenderProps } from "react-final-form";
import { Flex, Box } from "@rebass/grid";

import { ModalContext } from "contexts";
import { AuthSection } from "components/auth";
import { RESET_PASSWORD } from "../../../../api/mutations/auth";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { FormLabel, TextInput, Button } from "components/forms";
import validators from "utils/validators";
import formatters from "utils/formatters";
import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";

function ResetPasswordErrorPage() {
  const [resetPassword] = useMutation(RESET_PASSWORD);

  const setModal = useContext(ModalContext);

  const handleError = useRequestErrorHandler();

  const resendResetPasswordEmail = async (values: any) => {
    const response = await handleError(
      resetPassword({
        variables: {
          email: values.emailAddress,
        },
      }),
    );

    if (response) {
      setModal({
        type: "set",
        props: {
          title: "Done",
          content: `If there was an account associated with the email you provided, the reset password email has been sent to ${values.emailAddress}.`,
        },
      });
    }
  };

  return (
    <>
      <Form onSubmit={resendResetPasswordEmail}>
        {({ handleSubmit, submitting }: FormRenderProps) => (
          <form onSubmit={handleSubmit} autoComplete={"off"}>
            <AuthSection
              title="Reset Password"
              hints={[
                {
                  title: "Already have an account?",
                  link: "Sign in",
                  route: nonAuthRoutes.AUTH_SIGN_IN.getUrlWithParams(),
                },
              ]}
            >
              <Text>
                The reset password link has expired or is invalid. Please enter
                your email address and click "Reset Password" below to receive a
                new link.
              </Text>
              <Field
                name="emailAddress"
                validate={validators.composeValidators(
                  false,
                  validators.required,
                  validators.email,
                )}
                formatOnBlur
                format={formatters.trim}
              >
                {({ input, meta }) => {
                  return (
                    <div>
                      <FormLabel name="emailAddress">Email Address</FormLabel>
                      <TextInput
                        type="text"
                        placeholder="Enter Email Address"
                        maxLength={50}
                        light
                        {...input}
                        error={meta.touched && meta.error}
                      />
                    </div>
                  );
                }}
              </Field>

              <Flex justifyContent="flex-end" alignItems="center" my={50}>
                <Box>
                  <SubmitButton disabled={submitting} type="submit">
                    Reset Password
                  </SubmitButton>
                </Box>
              </Flex>
            </AuthSection>
          </form>
        )}
      </Form>
    </>
  );
}

const Text = styled.p`
  margin: 20px 0;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  min-width: 240px;
`;

export default ResetPasswordErrorPage;
