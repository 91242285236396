import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const createIcons = new ApiMutation({
  definition: gql`
    mutation createIcons(
      $applicationId: ID!
      $data: [ControlObjectType]
      $files: [Upload]
    ) {
      createIcons(applicationId: $applicationId, data: $data, files: $files) {
        isCreated
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASOAsset],
});

export interface CreateIconsResponse {
  createIcons: {
    isCreated: boolean;
  };
}

export const deleteIcon = new ApiMutation({
  definition: gql`
    mutation deleteIcon($id: ID!) {
      deleteIcon(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOAsset],
});

export interface DeleteIconResponse {
  deleteIcon: {
    isDeleted: boolean;
  };
}

export const deactivateIcon = new ApiMutation({
  definition: gql`
    mutation deactivateIcon($id: ID!) {
      deactivateIcon(id: $id) {
        isDeactivated
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOTest],
});

export interface DeactivateIconResponse {
  deactivateIcon: {
    isDeactivated: boolean;
  };
}
