import * as React from "react";
import {
  useSortBy,
  usePagination,
  useTable,
  PluginHook,
  SortingRule,
} from "react-table";

import { SingleTableColumn } from "./constants/singleTableColumn";

import { Table } from "./components/TableComponent";
import { TableFooterProps } from "./components/TableFooter";

// -- PROPS
type StaticTableProps = {
  columns: any;
  data: object[];
  displayHeader?: boolean;
  highlightFirstCell?: boolean;
  paginate?: boolean;
  trElement?: React.ComponentType<any>;
  defaultItemsPerPage?: number;
  initialSortBy?: SortingRule<object>;
};

// -- COMPONENT
function StaticTableComponent({
  columns,
  data,
  paginate,
  defaultItemsPerPage = 10,
  initialSortBy,
  ...rest
}: StaticTableProps): JSX.Element {
  const tableConfig = React.useMemo(() => {
    const usedHooks: PluginHook<{}>[] = [useSortBy];
    const params = {
      disableMultiSort: true,
      initialState: {},
    };

    if (paginate) {
      usedHooks.push(usePagination);
      params.initialState = {
        pageSize: defaultItemsPerPage,
      };
    }

    if (initialSortBy) {
      params.initialState = {
        ...params.initialState,
        sortBy: [initialSortBy], // puts initialSortBy element in array because react-table requires this
      };
    }

    return {
      usedHooks,
      params,
    };
  }, [paginate, defaultItemsPerPage, initialSortBy]);

  const {
    page,
    rows,
    state,
    pageCount,
    setPageSize,
    gotoPage,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      ...tableConfig.params,
    },
    ...tableConfig.usedHooks,
  );

  const footerProps: TableFooterProps | undefined = React.useMemo(() => {
    if (!paginate) {
      return undefined;
    }

    return {
      displayItemsCountSelector: true,
      initialItemsCount: defaultItemsPerPage,
      columnsNumber: columns.length,
      pagesCount: pageCount,
      currentPage: state.pageIndex + 1,
      onPaginationChange: (pageNumber: number, itemsPerPage: number) => {
        if (pageNumber !== state.pageIndex - 1) {
          gotoPage(pageNumber - 1);
        }

        if (itemsPerPage !== state.pageSize) {
          setPageSize(itemsPerPage);
        }
      },
    };
  }, [
    paginate,
    pageCount,
    state,
    setPageSize,
    gotoPage,
    columns.length,
    defaultItemsPerPage,
  ]);

  return (
    <Table
      rows={page || rows}
      columnsNumber={columns.length}
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      prepareRow={prepareRow}
      footerProps={footerProps}
      {...rest}
    />
  );
}

export const StaticTable = React.memo(StaticTableComponent);
