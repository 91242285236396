import * as React from "react";
import styled from "styled-components";

import { HistoricalConversionData } from "api/models";

import { convertDateToDisplayFormat, dateFormat } from "utils/dates";
import { percentFormat, toLocaleNumber } from "utils/numbers";

import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";
import { SingleCellProps } from "v2/components/Table/constants/singleCellProps";
import { StaticTable } from "v2/components/Table/StaticTable";

// -- PROPS
type AssetHistoricalDataTableProps = {
  historicalData: HistoricalConversionData[];
};

// -- COMPONENT
export function AssetHistoricalDataTable({
  historicalData,
}: AssetHistoricalDataTableProps): JSX.Element {
  const columns = React.useMemo<
    SingleTableColumn<HistoricalConversionData>[]
  >(() => {
    return [
      {
        Header: "Reason",
        accessor: "action",
        Cell: ({ value }: SingleCellProps<HistoricalConversionData>) => {
          return <NoWrapElement>{value}</NoWrapElement>;
        },
      },
      {
        Header: "Date From",
        accessor: "startDate",
        Cell: ({ value }: SingleCellProps<HistoricalConversionData>) => {
          return (
            <NoWrapElement>
              {convertDateToDisplayFormat(value, dateFormat)}
            </NoWrapElement>
          );
        },
      },
      {
        Header: "Date To",
        accessor: "endDate",
        Cell: ({ value }: SingleCellProps<HistoricalConversionData>) => {
          return (
            <NoWrapElement>
              {convertDateToDisplayFormat(value, dateFormat)}
            </NoWrapElement>
          );
        },
      },
      {
        Header: "Visits",
        accessor: "visits",
        Cell: ({ value }: SingleCellProps<HistoricalConversionData>) => {
          return <NoWrapElement>{toLocaleNumber(value)}</NoWrapElement>;
        },
      },
      {
        Header: "Conversions",
        accessor: "installs",
        Cell: ({ value }: SingleCellProps<HistoricalConversionData>) => {
          return <NoWrapElement>{toLocaleNumber(value)}</NoWrapElement>;
        },
      },
      {
        Header: "Conversion Rate",
        accessor: "cvr",
        Cell: ({ value }: SingleCellProps<HistoricalConversionData>) => {
          return percentFormat(value);
        },
      },
    ];
  }, []);

  return <StaticTable columns={columns} data={historicalData || []} paginate />;
}

// -- STYLED
const NoWrapElement = styled.span`
  white-space: nowrap;
`;
