import * as React from "react";
import { styled } from "@mui/system";
import hexToRgba from "hex-to-rgba";
import { Link } from "react-router-dom";

// --TYPES
type UserMenuItemProps = {
  disabled?: boolean;
  href?: string;
  inNewTab?: boolean;
  children: React.ReactNode;
  onClick?: () => any;
};

// -- COMPONENT
function UserMenuItemComponent({
  disabled,
  href,
  inNewTab,
  children,
  onClick,
}: UserMenuItemProps): JSX.Element {
  // TODO: dodać zachowanie na środkowy przecisk myszy
  if (disabled || (!href && !onClick)) {
    return <MenuItem disabled={disabled}>{children}</MenuItem>;
  }

  const isOutside = href ? href.startsWith("http") : false;

  if (isOutside || onClick) {
    return (
      <StyledA
        href={onClick ? undefined : href}
        target={inNewTab ? "_blank" : undefined}
        onClick={onClick}
      >
        <MenuItem>{children}</MenuItem>
      </StyledA>
    );
  }

  return (
    <StyledLink to={href || ""} target={inNewTab ? "_blank" : undefined}>
      <MenuItem>{children}</MenuItem>
    </StyledLink>
  );
}

// -- STYLES
const StyledA = styled("a")`
  text-decoration: none;

  &:hover {
    text-decoration: none;

    color: ${(props: any) => props.theme.colors.white};
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;

    color: ${(props: any) => props.theme.colors.white};
  }
`;

export const MenuItem = styled("span")<{ disabled?: boolean }>`
  display: flex;

  white-space: nowrap;

  width: auto;
  height: 60px;

  align-items: center;
  justify-content: flex-end;

  padding: 20px;

  text-decoration: none;

  background-color: ${(props: any) => props.theme.colors.background};

  color: ${(props: any) => props.theme.colors.white};

  text-decoration: none;

  ${(props: any) =>
    !props.disabled &&
    `
      cursor: pointer;
      &:hover {
        background-color: ${hexToRgba(props.theme.colors.purple, 0.5)};
      }
    `};
`;

export const UserMenuItem = React.memo(UserMenuItemComponent);
