import * as React from "react";
import { Cell } from "react-table";

import { getNotificationDescription } from "utils/notifications";

import { Notification } from "../consts/interfaces";

// -- TYPES
type DescriptionCellProps = {
  cell: Cell<Notification>;
};

// -- COMPONENT
export function DescriptionCell({
  cell,
}: DescriptionCellProps): JSX.Element | string | null {
  const description = React.useMemo(() => {
    return getNotificationDescription(cell.row.original);
  }, [cell.row.original]);

  return description;
}
