export enum ErrorMap {
  OBJECT_NOT_FOUND = "Element not found",
  CANNOT_EDIT_APPLICATION = "Cannot edit application",
  NOT_APPLICATION_OWNER = "You are not application owner",
  UNKNOW_ERROR = "Something went wrong",
  SHIP_DATE_EXIST = "Ship date already exists",
  DONT_HAVE_ACCESS = "You don't have permission to access",
  INVALID_USER = "Invalid user",
  NO_NEW_PREVIEWS = "No new previews provided",
  INVALID_CREDENTIALS = "The credentials you supplied were not correct",
  INVALID_OTP = "Invalid code! Please try again.",
  USER_DOES_NOT_EXIST = "User doesn't exist",
  INACTIVE_ACCOUNT = "User is not active",
  AUTH_UNAUTHORIZED = "AUTH_UNAUTHORIZED",
  INCORRECT_CODE = "INCORRECT_CODE",
  EMAIL_IN_USE = "This email is already in use",
  EMAIL_FORBIDDEN = "This email is forbidden",
  INVALID_TOKEN = "Invalid token",
  INVALID_PAYMENT = "Invalid payment",
  ACTION_UNAVAILABLE = "This action is currently unavailable",
  PAYMENT_METHOD_ERROR = "An error occurred with your payment method",
  PIXEL_EXIST = "Facebook pixel already exists for this app",
  USER_ALREADY_ADDED = "User already invited to this account",
  "CODE-addClientUser-409" = "User has already been invited to this account",
  "CODE-409" = "Entity already exists",
}
