import React from "react";

export interface ContextValue {
  userId: number | null;
  username: string | null;
  setCurrent: (userId: number, username: string) => void;
}

const defaultValue: ContextValue = {
  userId: null,
  username: null,
  setCurrent: (userId: number, username: string) => {},
};

export default React.createContext(defaultValue);
