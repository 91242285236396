import * as React from "react";
import styled from "styled-components";
import IframeResizer from "iframe-resizer-react";

import { IframeCoordinates } from "./constants/iframeCoordinates";
import { IframeHeight } from "./constants/iframeHeight";
import { IframeInitialWidth } from "./constants/iframeInitialWitdth";

import Spinner from "components/Spinner";

// -- TYPES
type HeatMapIframeProps = {
  url: string;
};

// -- STYLED
export function HeatMapIframe({ url }: HeatMapIframeProps): JSX.Element {
  const [scale, setScale] = React.useState<number | null>(null);
  const [containerWidth, setContainerWidth] = React.useState<number>(150);
  const [
    iframeCoordinates,
    setIframeCoordinates,
  ] = React.useState<IframeCoordinates | null>(null);

  const onResized = (data: any): void => {
    if (!scale) {
      const { height } = data;
      const calculatedScale = IframeHeight / height;

      setIframeCoordinates({
        x: (IframeInitialWidth - IframeInitialWidth * calculatedScale) / -2,
        y: (height - height * calculatedScale) / -2,
      });
      setScale(calculatedScale);
      setContainerWidth(calculatedScale * IframeInitialWidth);
    }
  };

  const iframeStyle = React.useMemo((): React.CSSProperties => {
    return {
      width: "360px",
      position: "absolute",
      visibility: scale ? "visible" : "hidden",
      transform: `scale(${scale || 1})`,
      left: iframeCoordinates?.x || "unset",
      top: iframeCoordinates?.y || "unset",
      borderWidth: 0,
      marginTop: 15,
    };
  }, [scale, iframeCoordinates]);

  return (
    <Wrapper containerWidth={containerWidth} id="heat-map-iframe-wrapper">
      {!scale && <Spinner style={{ marginTop: 0 }} />}

      <IframeResizer
        heightCalculationMethod="bodyScroll"
        onResized={onResized}
        src={url}
        style={iframeStyle}
      />
    </Wrapper>
  );
}

// -- STYLED
const Wrapper = styled.div<{ containerWidth: number }>`
  position: relative;

  flex-shrink: 0;

  width: ${(props: any) => props.containerWidth}px;
  height: ${IframeHeight}px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
