import * as React from "react";
import { styled } from "@mui/system";

import { ApplicationContext } from "contexts";

import Title from "components/Title/Title";
import { CostPlannerForm } from "./components/CostPlannerForm";

// -- COMPONENT
function CostPlannerPage(): JSX.Element {
  const applicationCtx = React.useContext(ApplicationContext);
  applicationCtx.setCurrent(null);

  return (
    <>
      <Title>Cost Planner</Title>
      <Description>
        Input the number of variations you would like to test for each element,
        as well as an estimated page conversion rate and cost per click of the
        visitors (CPC), to get an estimated range of how much the tests will
        cost.
        <br />
        <br />
        E.g. if testing a control icon and 2 other variations, then enter a
        value of 3 for Icon Variations below. Leave any element field blank, if
        you are not testing it.
      </Description>

      <CostPlannerForm />
    </>
  );
}

// -- STYLES
const Description = styled("p")`
  color: ${(props: any) => props.theme.colors.white};

  padding: 20px;
`;

export default CostPlannerPage;
