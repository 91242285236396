import React from "react";
import styled from "styled-components";

import themes from "components/themes";

function CheckboxInput(props: any) {
  const valid = !props.error;

  return (
    <Label htmlFor={props.name}>
      <Input light={props.light} {...props} id={props.name} type="checkbox" />
      <StyledInput checked={!!props.value || props.checked} valid={valid} />
      {props.label}
    </Label>
  );
}

const Input = styled.input<{ light: boolean }>`
  visibility: hidden;
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
`;

const StyledInput = styled.span<{ checked: boolean; valid: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px ${themes.colors.white};
  display: inline-block;
  background-color: ${(props) =>
    props.checked ? themes.colors.purple : "transparent"};
  margin-right: 12px;

  ${(props) =>
    !props.valid &&
    `
    box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow};
    border: 1px solid ${themes.colors.notificationRed};
  `};
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin-left: 15px;
  font-size: 16px;
  color: ${themes.colors.white};
`;

export default CheckboxInput;
