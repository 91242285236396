import React, { useContext, useMemo } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";

import { ApplicationContext, useStores } from "contexts";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import {
  GET_ANDROID_APPLICATION_STATIC_INFO,
  GET_CLIENT_APPLICATIONS,
  GET_APPLICATION_TITLE_AND_STATUS,
  GET_IOS_APPLICATION_STATIC_INFO,
} from "api/queries";
import {
  Platform,
  UPDATE_APPLICATION,
  UPDATE_ANDROID_APP_DETAILS,
  UPDATE_ANDROID_APP_RATING,
  UPDATE_ANDROID_GAME_INFO,
  UPDATE_IOS_APP_DETAILS,
  UPDATE_IOS_WHATS_NEW,
  GET_PLATFORM_CATEGORIES,
  UPDATE_ANDROID_DEVELOPER_INFO,
  IosCategory,
} from "api";
import { SingleReview } from "api/models/reviews/singleReviews";

import { IosStaticInfoData } from "./constants/iosStaticInfoData";
import { AndroidStaticInfoData } from "./constants/androidStaticInfoData";
import { AppDataResponse } from "./constants/appDataResponse";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Spinner, Title, Error } from "components";
import ApplicationNamePage from "./components/ApplicationNamePage";
import ApplicationIosStaticInfoPage from "./components/ApplicationIosStaticInfoPage";
import ApplicationAndroidStaticInfoPage from "./components/ApplicationAndroidStaticInfoPage";
import { convertCategoriesToOptions } from "components/forms/data/data";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

interface ApplicationStaticInfoContainerProps
  extends RouteComponentProps<{ id: string; onChange: any }> {}

function ApplicationStaticInfoContainer({
  history,
  match,
}: ApplicationStaticInfoContainerProps): JSX.Element | null {
  const [fullPageLoader, setIsLoading] = useFullPageLoader();

  const { id: applicationId } = match.params;
  const applicationCtx = useContext(ApplicationContext);
  const platformStore = useContext<PlatformStore>(PlatformContext);
  const { userStore } = useStores();

  const handleError = useRequestErrorHandler();

  const [updateApplication] = useMutation(UPDATE_APPLICATION);

  const [updateAndroidAppDetails] = useMutation(UPDATE_ANDROID_APP_DETAILS);
  const [updateAndroidAppRating] = useMutation(UPDATE_ANDROID_APP_RATING);
  const [updateAndroidGameInfo] = useMutation(UPDATE_ANDROID_GAME_INFO);
  const [updateAndroidDeveloperInfo] = useMutation(
    UPDATE_ANDROID_DEVELOPER_INFO,
  );

  const [updateIosAppDetails] = useMutation(UPDATE_IOS_APP_DETAILS);
  const [updateIosWhatsNew] = useMutation(UPDATE_IOS_WHATS_NEW);

  // -- query for categories
  const [requestForCategories, categories] = useLazyQuery<{
    categories: IosCategory[];
  }>(GET_PLATFORM_CATEGORIES);

  // -- queries for platform details
  const [requestForIosStaticInfo, iosStaticInfo] = useLazyQuery<{
    application: IosStaticInfoData;
    reviews: SingleReview[];
  }>(GET_IOS_APPLICATION_STATIC_INFO, {
    variables: { id: applicationId },
  });

  const [requestForAndroidStaticInfo, androidStaticInfo] = useLazyQuery<{
    application: AndroidStaticInfoData;
    reviews: SingleReview[];
  }>(GET_ANDROID_APPLICATION_STATIC_INFO, {
    variables: { id: applicationId },
  });

  // -- main request
  const appData = useQuery<AppDataResponse>(GET_APPLICATION_TITLE_AND_STATUS, {
    variables: { id: applicationId },
    onCompleted: (response) => {
      if (response.application.platform === Platform.ios) {
        requestForCategories({
          variables: {
            platform: Platform.ios,
          },
        });
        requestForIosStaticInfo();
      } else {
        requestForAndroidStaticInfo();
      }
    },
  });

  const availableCategories = useMemo(
    () => convertCategoriesToOptions(categories.data?.categories),
    [categories],
  );

  if (
    iosStaticInfo.loading ||
    androidStaticInfo.loading ||
    appData.loading ||
    categories.loading
  ) {
    return <Spinner />;
  }

  if (
    iosStaticInfo.error ||
    androidStaticInfo.error ||
    appData.error ||
    categories.error
  ) {
    const errObj =
      iosStaticInfo.error ||
      androidStaticInfo.error ||
      appData.error ||
      categories.error;
    return errObj ? <Error errorElement={errObj.message} /> : null;
  }

  const app = appData.data?.application;

  if (!app || app.platform === null) {
    history.replace(authRoutes.APPLICATION_LIST.getUrlWithParams());
    return null;
  }

  applicationCtx.setCurrent(app);

  const onNameChange = async (data: object) => {
    setIsLoading(true);
    const updateFuncResponse = await handleError(
      updateApplication({
        variables: {
          clientCode: userStore.clientCode,
          applicationCode: app.groupCode,
          application: data,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_APPLICATION_TITLE_AND_STATUS,
            variables: { id: applicationId },
          },
          {
            query: GET_CLIENT_APPLICATIONS,
            variables: { clientCode: userStore.clientCode },
          },
        ],
      }),
    );
    await platformStore.loadApplications();
    setIsLoading(false);

    return Boolean(updateFuncResponse);
  };

  const onSubmit = (
    updateFunc: any,
    refetchQuery: any,
    additionalVariables: object = {},
    additionalQueries: any[] = [],
  ) => async (data: object) => {
    setIsLoading(true);

    const updateFuncResponse = await handleError(
      updateFunc({
        variables: {
          id: applicationId,
          ...additionalVariables,
          ...data,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: refetchQuery,
            variables: { id: applicationId },
          },
          ...additionalQueries,
        ],
      }),
    );

    setIsLoading(false);

    return Boolean(updateFuncResponse);
  };

  return (
    <>
      {fullPageLoader}
      <Title>Static Info</Title>
      <ApplicationNamePage onNameSubmit={onNameChange} appName={app.name} />

      {app.platform === Platform.ios && iosStaticInfo.data?.application && (
        <ApplicationIosStaticInfoPage
          applicationId={applicationId}
          data={iosStaticInfo.data.application}
          onAppDetailsSubmit={onSubmit(
            updateIosAppDetails,
            GET_IOS_APPLICATION_STATIC_INFO,
          )}
          onWhatsNewSubmit={onSubmit(
            updateIosWhatsNew,
            GET_IOS_APPLICATION_STATIC_INFO,
          )}
          categories={availableCategories}
          reviews={iosStaticInfo.data.reviews}
        />
      )}

      {app.platform === Platform.android &&
        androidStaticInfo.data?.application && (
          <ApplicationAndroidStaticInfoPage
            applicationId={applicationId}
            onAppDetailsSubmit={onSubmit(
              updateAndroidAppDetails,
              GET_ANDROID_APPLICATION_STATIC_INFO,
            )}
            onAppRatingSubmit={onSubmit(
              updateAndroidAppRating,
              GET_ANDROID_APPLICATION_STATIC_INFO,
            )}
            onGameInfoSubmit={onSubmit(
              updateAndroidGameInfo,
              GET_ANDROID_APPLICATION_STATIC_INFO,
            )}
            onDeveloperInfoSubmit={onSubmit(
              updateAndroidDeveloperInfo,
              GET_ANDROID_APPLICATION_STATIC_INFO,
            )}
            data={androidStaticInfo.data.application}
            reviews={androidStaticInfo.data.reviews}
          />
        )}
    </>
  );
}

export default ApplicationStaticInfoContainer;
