import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { useMutation, useQuery } from "@apollo/client";

import { client } from "api";
import { GET_ROUTES } from "api/queries";
import { Route, RouteType } from "api/models";
import {
  updateApplicationRoute,
  UpdateApplicationRouteResponse,
  deleteApplicationRoute,
  DeleteApplicationRouteResponse,
  createApplicationRoute,
  CreateApplicationRouteResponse,
} from "v2/api/mutations/application/routes";

import {
  changeRedirectAfterTests,
  ChangeRedirectAfterTestsResponse,
} from "v2/api/mutations/application/changeRedirectAfterTests";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Spinner, Error } from "components";
import ApplicationRoutingPage from "./ApplicationRoutingPage";
import ApplicationDetail from "screens/Application/ApplicationDetail/ApplicationDetailContainer";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
import { useHasUserPermission } from "v2/hooks/useHasUserPermission";

interface Props extends RouteComponentProps<{ id: string }> {}

function ApplicationRoutingContainer({ history, match }: Props) {
  const [customRoutes, setCustomRoutes] = useState<Route[] | null>(null);

  const [updateRoute] = useMutation<UpdateApplicationRouteResponse>(
    updateApplicationRoute.definition,
  );
  const [deleteRoute] = useMutation<DeleteApplicationRouteResponse>(
    deleteApplicationRoute.definition,
  );
  const [createRoute] = useMutation<CreateApplicationRouteResponse>(
    createApplicationRoute.definition,
  );
  const [changeRedirect, changeRedirectResponse] = useMutation<
    ChangeRedirectAfterTestsResponse
  >(changeRedirectAfterTests.definition);

  const { data, error, loading } = useQuery(GET_ROUTES, {
    variables: { applicationId: match.params.id },
    fetchPolicy: "network-only",
  });

  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();
  const handleError = useRequestErrorHandler();
  const hasUserPermissions = useHasUserPermission();

  if (!loading && error) {
    return <Error errorElement={error.message} />;
  }

  if (
    loading ||
    (!data.controlRoute &&
      !data.newTestsRoute &&
      !data.customRoutes &&
      !data.actualStorePageRoute)
  ) {
    return <Spinner />;
  }

  if (customRoutes === null) {
    setCustomRoutes(data.customRoutes);
  }

  const onRedirectChange = async (shouldRedirect: boolean) => {
    setFullPageLoader(true);

    const shouldRedirectResponse = await handleError(
      changeRedirect({
        variables: {
          shouldRedirect,
          applicationId: match.params.id,
        },
      }),
    );

    if (shouldRedirectResponse) {
      await client.resetStore();
    }

    setFullPageLoader(false);
  };

  const handleDelete = async (route: Route) => {
    const deleteRouteResponse = await handleError(
      deleteRoute({
        variables: {
          id: route.id,
        },
      }),
    );

    if (deleteRouteResponse && route.type === RouteType.custom) {
      const routes = customRoutes || [];
      setCustomRoutes([
        ...routes.filter((r) => {
          return r.id !== route.id;
        }),
      ]);
    }
  };

  const handleChange = async (route: Route) => {
    // TODO: in feature handle error. It should't on error disable edit
    const updateRouteResponse = await handleError(
      updateRoute({
        variables: {
          id: route.id,
          url: route.url,
          weight: route.weight,
        },
      }),
    );

    if (updateRouteResponse && route.type === RouteType.custom) {
      const routes = customRoutes || [];
      setCustomRoutes([
        ...routes.map((r) => {
          if (r.id === route.id) {
            return route;
          }

          return r;
        }),
      ]);
    }
  };

  const handleCreate = async (route: Route) => {
    // TODO: in feature handle error. It should't on error delete creater row
    const routeDataResponse = await handleError(
      createRoute({
        variables: {
          applicationId: match.params.id,
          url: route.url,
          weight: route.weight,
        },
      }),
    );

    if (routeDataResponse?.data.createRoute.isCreated) {
      setCustomRoutes([
        ...(customRoutes || []),
        routeDataResponse.data.createRoute.route,
      ]);
    }
  };

  const mutationRedirectStatus =
    changeRedirectResponse.data?.updateRedirectToAppStoreUrl
      .isRedirectToAppStore;
  const redirectToAppStoreStatus =
    mutationRedirectStatus !== undefined
      ? mutationRedirectStatus
      : data.application?.redirectToStoreUrl;

  return (
    <>
      {fullPageLoader}
      <ApplicationDetail applicationId={data.application.id} history={history}>
        <ApplicationRoutingPage
          liveUrls={data.liveUrls}
          controlRoute={data.controlRoute}
          newTestsRoute={data.newTestsRoute}
          actualStorePageRoute={data.actualStorePageRoute}
          customRoutes={customRoutes || []}
          onDelete={
            hasUserPermissions(deleteApplicationRoute.requiredPermissions)
              ? handleDelete
              : null
          }
          onChange={
            hasUserPermissions(updateApplicationRoute.requiredPermissions)
              ? handleChange
              : null
          }
          onCreate={
            hasUserPermissions(createApplicationRoute.requiredPermissions)
              ? handleCreate
              : null
          }
          redirectToAppStoreStatus={redirectToAppStoreStatus}
          onRedirectChange={
            hasUserPermissions(changeRedirectAfterTests.requiredPermissions)
              ? onRedirectChange
              : null
          }
          application={data.application}
        />
      </ApplicationDetail>
    </>
  );
}

export default ApplicationRoutingContainer;
