import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache, ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import * as Sentry from "@sentry/react";
import config from "config";
import { PlatformStore } from "@upptic/module-directory";
export let platformInstanceToUse = { instance: null };

const httpLink = createUploadLink({
  uri: config.apiUrl,
  credentials: config.isDev ? "include" : "same-origin",
});

const externalHttpLink = createUploadLink({
  uri: config.apiUrl.replace("/graphql", "/external/graphql"),
  credentials: config.isDev ? "include" : "same-origin",
});

/* istanbul ignore next */
const onErrorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        // tslint:disable-next-line:no-console
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );

        if (
          !operation?.getContext()?.dontHandleAuthError &&
          extensions?.code === 401 &&
          operation.operationName !== "currentUser"
        ) {
          const d = new Date();
          d.setTime(d.getTime() + 4 * 60 * 60 * 1000);
          document.cookie = `locationBeforeLogout=${
            window.location.pathname
          }; expires=${d.toUTCString()}; path=/;`;
          window.location.href = "/logout";
          return;
        }

        if (message === "INVALID_CREDENTIALS") return;
        Sentry.captureMessage(message, {
          extra: {
            locations,
            path,
            operation: operation.operationName,
            variables: operation.variables,
            errors: JSON.stringify(response?.errors),
          },
        });
      });
      document.dispatchEvent(
        new CustomEvent("apolloGraphQlError", {
          detail: { operation, graphQLErrors },
        } as any),
      );
    }
    if (networkError) {
      // tslint:disable-next-line:no-console
      console.error(`[Network error]: ${networkError}`);
      document.dispatchEvent(
        new CustomEvent("apolloNetworkError", {
          detail: { networkError, operation },
        } as any),
      );
      Sentry.captureException(networkError, {
        extra: {
          operation: operation.operationName,
          variables: operation.variables,
        },
      });
    }
  },
);

const cache = new InMemoryCache();

const headerLink = setContext((_, { headers }) => {
  const headersToSend = { ...headers };
  const platformStore = platformInstanceToUse.instance as PlatformStore | null;
  if (platformStore?.currentClientCode && !headersToSend["client-code"])
    headersToSend["client-code"] = platformStore?.currentClientCode;
  return { headers: headersToSend };
});

const link = ApolloLink.from([
  (onErrorLink as unknown) as ApolloLink,
  (headerLink as unknown) as ApolloLink,
  (httpLink as unknown) as ApolloLink,
]);

export const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});

export const externalClient = new ApolloClient({
  cache,
  link: ApolloLink.from([
    (onErrorLink as unknown) as ApolloLink,
    (externalHttpLink as unknown) as ApolloLink,
  ]),
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
  },
});
