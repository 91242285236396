import React, { useState, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { useMutation } from "@apollo/client";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { client } from "api";
import { CREATE_APPPLATFORM } from "api/mutations/application";
import {
  ApplicationListing,
  Platform,
  ScreensType,
  ClientApplication,
} from "api/models";
import { Set } from "components/application/forms/PreviewStep";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import {
  ApplicationContext,
  ApplicationUserContext,
  ModalContext,
} from "contexts";
import { ContextValue as UserContextValue } from "contexts/applicationUser";
import { useStores } from "contexts/storeContext";

import ApplicationCreatePage, { FormData } from "./ApplicationCreatePage";

interface PreviewInfo {
  position: number | null;
  videoId?: string | null;
  isControl: boolean;
}

interface SetsInfo {
  name: string;
  isControl: boolean;
  previews: {
    position: number | null;
    videoId?: string | null;
  }[];
}

interface SetsConvertedData {
  setsFiles: any[];
  setsInfo: SetsInfo[];
}

type IsAssetControlInfo = {
  isControl: boolean;
};

export const transformSetsData = (sets: Set[]): SetsConvertedData => {
  const setsFiles: any[] = [];
  const setsInfo: SetsInfo[] = [];

  sets.forEach((singleSet) => {
    setsFiles.push(singleSet.assets.map((d) => d.file));
    setsInfo.push({
      isControl: singleSet.isControl,
      name: singleSet.name,
      previews: singleSet.assets.map((singleAsset, index) => {
        const incrementIndex = ++index;
        return {
          position: incrementIndex,
          videoId: incrementIndex > 3 ? null : singleAsset.videoId,
        };
      }),
    });
  });

  return {
    setsFiles,
    setsInfo,
  };
};

function transformData(
  data: FormData,
  applicationUserContext: UserContextValue,
) {
  const detailsStep = data.step1;
  const iconStep = data.step2;
  const previewsStep = data.step4;
  const TextAssetsStep = data.step5;
  const metaStep = data.step6;

  const featureGraphicStep = data.step3;
  const promoArtStep = data.step3;

  const platform = detailsStep.platform;

  const titles = TextAssetsStep.assetsTitle.map((singleTitle) => ({
    isControl: singleTitle === TextAssetsStep.controlTitle,
    name: singleTitle,
  }));

  const descriptions = TextAssetsStep.assetsDescription.map(
    (singleDescription) => ({
      isControl: singleDescription === TextAssetsStep.controlDescription,
      name: singleDescription,
    }),
  );

  const subtitles = TextAssetsStep.assetsSubtitle.map((singleSubtitle) => ({
    isControl: singleSubtitle === TextAssetsStep.controlSubtitle,
    name: singleSubtitle,
  }));

  const iconFiles = iconStep.assets.map((d) => d.file);
  const iconInfo = iconStep.assets.map((d) => ({
    isControl: d === iconStep.control,
  }));

  let assetFiles: any[] = [];
  let assetInfo: PreviewInfo[] = [];
  let setsData: SetsConvertedData = {
    setsFiles: [],
    setsInfo: [],
  };

  if (previewsStep.screensType === ScreensType.single) {
    assetFiles = previewsStep.assets1.map((d) => d.file);
    assetFiles.push(...previewsStep.assets2.map((d) => d.file));
    assetFiles.push(...previewsStep.assets3.map((d) => d.file));
    assetFiles.push(...previewsStep.assets4.map((d) => d.file));

    assetInfo = previewsStep.assets1.map((d) => ({
      position: 1,
      videoId: d.file ? null : d.videoId,
      isControl: d === previewsStep.control1,
    }));
    assetInfo.push(
      ...previewsStep.assets2.map((d) => ({
        position: 2,
        videoId: null,
        isControl: d === previewsStep.control2,
      })),
    );
    assetInfo.push(
      ...previewsStep.assets3.map((d) => ({
        position: 3,
        videoId: null,
        isControl: d === previewsStep.control3,
      })),
    );
    assetInfo.push(
      ...previewsStep.assets4.map((elem, index) => ({
        position: index + 4,
        videoId: null,
        isControl: false,
      })),
    );
  } else {
    setsData = transformSetsData(previewsStep.sets);
  }

  const ranking = metaStep.ranking ? Number(metaStep.ranking) : null;

  let promoArtFiles: (File | null)[] = [];
  let promoArtsInfo: IsAssetControlInfo[] = [];
  let featureGraphicFiles: (File | null)[] = [];
  let featureGraphicsInfo: IsAssetControlInfo[] = [];

  if (platform === Platform.ios) {
    promoArtFiles = promoArtStep.assets.map((d) => d.file);
    promoArtsInfo = promoArtStep.assets.map((d) => ({
      isControl: d === promoArtStep.control,
    }));
  } else {
    featureGraphicFiles = featureGraphicStep.assets.map((d) => d.file);
    featureGraphicsInfo = featureGraphicStep.assets.map((d) => ({
      isControl: d === featureGraphicStep.control,
    }));
  }

  return {
    iconFiles,
    iconsInfo: iconInfo,
    promoArtFiles,
    promoArtsInfo,
    previewFiles: assetFiles,
    previewsInfo: assetInfo,
    name: detailsStep.name,
    platform,
    redirectUrl: detailsStep.redirectUrl,
    titles,
    descriptions,
    subtitles,
    featureGraphicsInfo,
    featureGraphicFiles,

    ...metaStep,
    tags: metaStep.tags,
    numberOfRatings: parseInt(metaStep.numberOfRatings, 10),
    category: Number(metaStep.category),
    ranking,
    size: metaStep.size,
    gameplayRating: metaStep.gameplayRating || null,
    controlRating: metaStep.controlRating || null,
    graphicRating: metaStep.graphicRating || null,
    copyright: metaStep.copyright || null,
    reviews: metaStep.reviews,
    minimumDetectableLift: Number(metaStep.mdl),
    ...setsData,
  };
}

interface Props extends RouteComponentProps<{}> {}

function ApplicationCreateContainer({ history, location }: Props) {
  const [createApplication] = useMutation(CREATE_APPPLATFORM);
  const { userStore } = useStores();

  const [isLoading, setLoading] = useState(false);

  const applicationCtx = useContext(ApplicationContext);
  const setModal = useContext(ModalContext);
  const applicationUserCtx = useContext(ApplicationUserContext);

  const handleError = useRequestErrorHandler();

  const application = (
    (location.state as { application: ApplicationListing }) || {}
  ).application;

  const applicationGroup = (
    (location.state as { applicationGroup: ClientApplication }) || {}
  ).applicationGroup;

  applicationCtx.setCurrent(null);

  const handleDiscard = () =>
    setModal({
      type: "set",
      props: {
        title: "Discard",
        content: "Are you sure you want to discard current progress?",
        onConfirm: () => {
          history.replace(authRoutes.APPLICATION_LIST.getUrlWithParams());
        },
      },
    });

  const handleCreate = async (data: FormData) => {
    setLoading(true);

    const variables = transformData(data, applicationUserCtx);
    const createApplicationResponse = await handleError(
      createApplication({
        variables: {
          appPlatform: {
            aso: {
              ...variables,
              name: undefined,
              platform: undefined,
              mdl: undefined,
            },
            name: variables.name,
            platform: variables.platform,
          },
          clientCode: userStore.clientCode,
        },
      }),
    );

    if (createApplicationResponse) {
      await userStore.loadUser();
      await client.resetStore();
      const appId = createApplicationResponse.data?.createAppPlatform?.asoAppId;
      history.replace(
        authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({ id: appId }),
      );
    }

    setLoading(false);
  };

  return (
    <ApplicationCreatePage
      application={application}
      applicationGroup={applicationGroup}
      onDiscard={handleDiscard}
      onCreate={handleCreate}
      isLoading={isLoading}
    />
  );
}

export default ApplicationCreateContainer;
