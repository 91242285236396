import { createGlobalStyle } from 'styled-components'
import DatePickerStyle from './DatePickerStyle'
import themes from './primary'

const BaseStyle = createGlobalStyle`
  body {
    background-color: #0c0c26;
  }

  * {
    scrollbar-color: rgba(111, 66, 193, .5) rgba(0, 0, 0, 0);
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 10px;
    background-color: rgba(111, 66, 193, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  h3, h4 {
    color: ${themes.colors.white};
    margin: 0;
  }

  ${DatePickerStyle}
`

export default BaseStyle
