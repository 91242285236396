import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const createPreviews = new ApiMutation({
  definition: gql`
    mutation createPreviews(
      $applicationId: ID!
      $previewsInfo: [PreviewInputType]
      $setsInfo: [SetsInputType]
      $files: [Upload]
    ) {
      createPreviews(
        applicationId: $applicationId
        previewsInfo: $previewsInfo
        setsInfo: $setsInfo
        files: $files
      ) {
        isCreated
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASOAsset],
});

export interface CreatePreviewsResponse {
  createPreviews: {
    isCreated: boolean;
  };
}

export const deletePreview = new ApiMutation({
  definition: gql`
    mutation deletePreview($id: ID!) {
      deletePreview(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOAsset],
});

export interface DeletePreviewResponse {
  deletePreview: {
    isDeleted: boolean;
  };
}

export const deactivatePreview = new ApiMutation({
  definition: gql`
    mutation deactivatePreview($id: ID!) {
      deactivatePreview(id: $id) {
        isDeactivated
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOTest],
});

export interface DeactivatePreviewResponse {
  deactivatePreview: {
    isDeactivated: boolean;
  };
}
