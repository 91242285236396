import React from "react";
import styled from "styled-components";
import { Grid, styled as muiStyled } from "@mui/material";

interface ContentInterface {
  layout?: string;
  children: React.ReactNode;
}

export const ContentWrapperMui = muiStyled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  minHeight: "calc(100vh - 60px)",
  height: "100%",
  width: "100%",
}));

export const ContentWrapperMuiNoBorder = muiStyled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(0),
  color: theme.palette.text.primary,
  minHeight: "calc(100vh - 60px)",
  height: "100%",
}));

export const Content = (props: ContentInterface) => {
  const { layout, children } = props;

  const renderContent = () => {
    switch (layout) {
      case "auth":
        return <ContentWrapperAuth>{children}</ContentWrapperAuth>;
      case "noBorder":
        return <ContentWrapperNoBorder>{children}</ContentWrapperNoBorder>;
      case "default":
        return <ContentWrapperDefault>{children}</ContentWrapperDefault>;
      case "narrow":
        return <ContentWrapperNarrow>{children}</ContentWrapperNarrow>;
      case "mui":
        return (
          <ContentWrapperMui flexDirection="column" container>
            {children}
          </ContentWrapperMui>
        );
      case "muiNoBorder":
        return (
          <ContentWrapperMuiNoBorder flexDirection="column" container>
            {children}
          </ContentWrapperMuiNoBorder>
        );
      default:
        return <ContentWrapperAso>{children}</ContentWrapperAso>;
    }
  };

  return <>{renderContent()}</>;
};

const ContentWrapperAuth = styled.div`
  padding: 3em 3em 4.375em;
  height: 100%;
`;

const ContentWrapperAso = styled.div`
  padding: 3em 3em 4.375em;
  min-height: calc(100vh - 60px); /* subtract header height */
  height: 100%;
`;

const ContentWrapperNoBorder = styled.div`
  min-height: calc(100vh - 60px); /* subtract header height */
  height: 100%;
`;

const ContentWrapperDefault = styled.div`
  min-height: calc(100vh - 60px); /* subtract header height */
  height: 100%;
  width: 100%;
  padding: 1em 1.5em 4em 1.5em;
  display: flex;
  flex-direction: column;
`;

const ContentWrapperNarrow = styled.div`
  min-height: calc(100vh - 60px); /* subtract header height */
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
`;
