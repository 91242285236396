import React from "react";

import { userpilotReloadUrl } from "utils/userpilot";

import { FormHeader } from "components/forms";
import { UploadFile } from "components/forms/UploadInput";
import { ApplicationAssetsUpload } from "components/application";
import Text from "./components/Text";
import { ChangeData } from "../ApplicationAssetsUpload/ApplicationAssetsUpload";

export interface StepData {
  control: UploadFile | null;
  assets: UploadFile[];
  referenceVariationType?: string;
}

interface Props {
  title?: string;
  buttonText?: string;
  data: StepData;
  disableControl?: boolean;
  onChange: (values: object) => void;
  maxUploadAssets?: number;
}

class IconStep extends React.PureComponent<Props> {
  componentDidMount() {
    userpilotReloadUrl(`${window.location.href}/icon`);
  }

  handleAssetsChange = (changes: ChangeData) => {
    const { onChange, disableControl, data } = this.props;
    const { assets, control } = changes;

    const fireCustomEvent =
      Array.isArray(data.assets) &&
      !data.assets.length &&
      Array.isArray(assets) &&
      assets.length;

    if (fireCustomEvent) {
      userpilotReloadUrl(`${window.location.href}/icon-uploaded`);
    }

    onChange({
      assets,
      control: disableControl ? null : control,
    });
  };

  public render() {
    const {
      title,
      buttonText = "Upload Icons",
      data,
      onChange,
      disableControl,
      maxUploadAssets,
    } = this.props;

    return (
      <>
        <FormHeader style={{ marginTop: 30 }}>
          {title || "Upload Icons and Select Control Version"}
        </FormHeader>
        <ApplicationAssetsUpload
          assets={data.assets}
          controlAsset={data.control}
          buttonText={buttonText}
          emptyTitle={buttonText}
          emptyDescription={`Use the button above to ${buttonText.toLowerCase()} from your computer`}
          emptyDescriptionFooter={
            <Text label="Required Dimensions: " value="512 x 512 px" />
          }
          onChange={this.handleAssetsChange}
          onControl={
            disableControl
              ? null
              : (asset) => {
                  onChange({
                    control: disableControl ? null : asset,
                  });
                }
          }
          maxUploadAssets={maxUploadAssets}
        />
      </>
    );
  }
}

export default IconStep;
