import React, { useContext, useState } from "react";
import { styled } from "@mui/system";
import hexToRgba from "hex-to-rgba";
import { observer } from "mobx-react";
import { ZendeskAPI } from "react-zendesk";

import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import config from "config";

import { ApplicationUserContext } from "contexts";
import { useStores } from "contexts/storeContext";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";
import themes from "components/themes";

import { UserPermissions } from "v2/api/types/UserPermissions";

import Icon, { Icons } from "components/Icon";
import { ChangeUserMenuItem } from "./ChangeUserMenuItem";
import { UserMenuItem } from "./UserMenuItem";
import { DemoMenuItem } from "./DemoMenuItem";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

import { logoutMutation } from "v2/api/mutations/user/logout";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useIsMobile } from "v2/components/SideMenuV2/helper";

// -- PERMS
const permissionsForInpersonateUser = [UserPermissions.inpersonateUser];

// -- COMPONENT
function UserMenu() {
  const { t } = useTranslation("userMenu");
  const isMobile = useIsMobile();
  const [sectionHovered, setSectionHovered] = useState(false);

  const { userStore } = useStores();
  const applicationUserCtx = useContext(ApplicationUserContext);
  const platformStore = useContext<PlatformStore>(PlatformContext);
  const currentUserPermissions = platformStore.currentUserFrontendPermissions;
  const isInfluencer = platformStore.currentUserFrontendPermissions?.includes(
    "PSOSIPR",
  );

  const [logout] = useMutation(logoutMutation);

  const handleError = useRequestErrorHandler();

  const handleLogout = async () => {
    await handleError(logout()).then((res) => {
      if (res?.data.logout) {
        ZendeskAPI("webWidget", "logout");
        userStore.clearStorage();
      }
    });
  };

  const loggedAsOtherUser =
    String(applicationUserCtx.userId) !== String(userStore.userId);

  return (
    <LogoutWrapper
      active={sectionHovered ? 1 : 0}
      onMouseEnter={() => setSectionHovered(true)}
      onMouseLeave={() => setSectionHovered(false)}
      isMobile={isMobile}
    >
      <LogoutCircle
        active={sectionHovered ? 1 : 0}
        loggedAsOtherUser={loggedAsOtherUser}
      >
        <Icon icon={Icons.faUser} />
      </LogoutCircle>

      <Menu active={sectionHovered ? 1 : 0}>
        <UserMenuItem disabled={true}>{userStore.userName}</UserMenuItem>
        {currentUserPermissions.includes("AADMU") && <DemoMenuItem />}
        {!isInfluencer && (
          <UserMenuItem
            href={`/${platformStore?.currentClient?.code}/user/profile`}
          >
            {t<string>("profile")}
          </UserMenuItem>
        )}

        <UserMenuItem inNewTab href={config.zendeskSupportCenter}>
          {t<string>("support")}
        </UserMenuItem>

        <UserMenuItem inNewTab href={config.featureRequestLink}>
          {t<string>("feature")}
        </UserMenuItem>

        <UserMenuItem onClick={handleLogout}>
          {t<string>("logout")}
        </UserMenuItem>

        <PermissionRestriction
          requiredPermissions={permissionsForInpersonateUser}
        >
          <ChangeUserMenuItem />
        </PermissionRestriction>
      </Menu>
    </LogoutWrapper>
  );
}

const Menu = styled("div", {
  shouldForwardProp: (prop: string) => !["active"].includes(prop),
})<{ active: boolean }>`
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #fff;
  top: 60px;
  right: 0;
  overflow: hidden;
  transition: height 0.4s;
  height: ${(props: any) => (props.active ? "auto" : 0)};
  background-color: ${themes.colors.background};
` as any;

const LogoutWrapper = styled("div", {
  shouldForwardProp: (prop: string) => !["active"].includes(prop),
})<{ active: boolean; isMobile: boolean }>`
  width: 54px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid transparent;
  position: relative;
  margin-right: ${(props) => (props.isMobile ? "16px" : "30px")};
  ${(props) =>
    props.active &&
    `
      background-color: ${hexToRgba(themes.colors.purple, 0.3)};
      border-color: ${themes.colors.purple};
  `};
` as any;

const LogoutCircle = styled("div", {
  shouldForwardProp: (prop: string) =>
    !["active", "loggedAsOtherUser"].includes(prop),
})<{
  active: boolean;
  loggedAsOtherUser: boolean;
}>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 19px;
  background-color: ${(props) =>
    props.active ? "#452077" : themes.colors.background};

  ${(props: any) =>
    props.loggedAsOtherUser &&
    `
    background-color: ${props.theme.colors.chartLightPurple};
  `}
` as any;

export default observer(UserMenu);
