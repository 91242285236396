import * as React from "react";
import CreatableSelect from "react-select/creatable";
import { OptionTypeBase, ValueType } from "react-select";

import { tagInputStyles } from "./constants/tagInputStyles";

// -- PROPS
type TagInputProps = {
  value: string[];
  maxElementsCount?: number;
  onChange: any;
  placeholder?: string;
};

// -- COMPONENT
export function TagInput({
  value,
  maxElementsCount,
  onChange,
  placeholder,
}: TagInputProps): JSX.Element {
  const handleChange = React.useCallback(
    (newValue: ValueType<OptionTypeBase, boolean>) => {
      if (newValue && maxElementsCount && newValue.length > maxElementsCount) {
        return false;
      }

      const valuesArray =
        newValue?.map((singleValue: OptionTypeBase) => singleValue.value) || [];

      onChange(valuesArray);
    },
    [maxElementsCount, onChange],
  );

  const formatedValues: OptionTypeBase[] = React.useMemo(() => {
    return value.map((singleValue) => ({
      label: singleValue,
      value: singleValue,
    }));
  }, [value]);

  return (
    <CreatableSelect
      isMulti
      value={formatedValues}
      onChange={handleChange}
      styles={tagInputStyles}
      menuIsOpen
      placeholder={placeholder}
    />
  );
}
