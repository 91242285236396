import React from "react";
import { Form } from "react-final-form";
import { Flex, Box } from "@rebass/grid";
import styled from "styled-components";

import { Button } from "components/forms";
import { DividerHorizontal, Icon, Icons } from "components";
import themes from "components/themes";
import WizardSteps, { Step } from "./WizardSteps";
import AppCreationLoader from "components/AppCreationLoader";

interface Props {
  initValues: object;
  step: number;
  maxStep: number;
  steps: Step[];
  onStepChange: (step: number) => void;
  onSubmit: () => void;
  isLoading: boolean;
}

export default class Wizard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.getActivePage = this.getActivePage.bind(this);
    this.isLastPage = this.isLastPage.bind(this);
    this.next = this.next.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public getActivePage(): any {
    return this.props.steps[this.props.step].component;
  }

  public isLastPage(): boolean {
    const { step, steps } = this.props;

    return step === steps.length - 1;
  }

  public next() {
    this.props.onStepChange(this.props.step + 1);
  }

  public validate(values: object) {
    const activePage = this.getActivePage();

    if (!activePage) {
      return null;
    }

    return activePage.props.validate ? activePage.props.validate(values) : {};
  }

  public handleSubmit() {
    const { onSubmit } = this.props;

    if (this.isLastPage()) {
      return onSubmit();
    } else {
      this.next();
    }
  }

  public mutateValue([name]: any, state: any, { changeValue }: any) {
    // change the value to the same value, thus
    // triggering a revalidation of the same value
    changeValue(state, name, (value: any) => value);
  }

  public renderStepContent(form: any, submitting: any, valid: any) {
    const { step, maxStep, isLoading, onStepChange } = this.props;
    const isLastPage = this.isLastPage();

    if (isLoading) {
      return <AppCreationLoader />;
    }

    const activePageContent = this.getActivePage();

    const handlePreviousClick = () => {
      if (maxStep > step && !valid) {
        return form.submit();
      }

      onStepChange(step - 1);
    };

    const formControlsButtons = (
      <Flex justifyContent="flex-end" mt={60}>
        <Box mr={20}>
          {step > 0 && (
            <ButtonPrevious
              outline={true}
              onClick={handlePreviousClick}
              disabled={submitting}
            >
              <Icon verticalCenter icon={Icons.faArrowLeft} size="1x" />
              <span>Prev Step</span>
            </ButtonPrevious>
          )}
        </Box>

        <Box>
          <Button disabled={submitting} type="submit">
            <span>{`${isLastPage ? "Submit" : "Next Step"}`}</span>
            {!isLastPage && (
              <Icon verticalCenter icon={Icons.faArrowRight} size="1x" />
            )}
          </Button>
        </Box>
      </Flex>
    );

    return (
      <>
        {activePageContent}
        {formControlsButtons}
      </>
    );
  }

  blockSubmitOnEnter = (event: any) => {
    const targetTagName = event.target?.tagName;

    if (event.which === 13 && targetTagName !== "TEXTAREA") {
      event.preventDefault();
    }
  };

  public render() {
    const { step, maxStep, steps, onStepChange, initValues } = this.props;

    return (
      <Form
        initialValues={initValues}
        mutators={{ mutateValue: this.mutateValue }}
        validate={this.validate}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting, valid, form }) => {
          const mutateBeforeSubmit = (event: any) => {
            // supply the name of the relevant form field
            form.mutators.mutateValue("revalidate");
            // submit handler gets called if revalidation still passes
            handleSubmit(event);
          };
          return (
            <div>
              <WizardSteps
                currentStep={step}
                maxStep={maxStep}
                steps={steps}
                onChange={(newStep: number) => {
                  if (maxStep < newStep) {
                    return;
                  }

                  if (maxStep > step && !valid) {
                    return form.submit();
                  }

                  onStepChange(newStep);
                }}
              />

              <DividerHorizontal />

              <form
                style={{ padding: "30px" }}
                onSubmit={mutateBeforeSubmit}
                autoComplete="off"
                onKeyPress={this.blockSubmitOnEnter}
              >
                {this.renderStepContent(form, submitting, valid)}
              </form>
            </div>
          );
        }}
      </Form>
    );
  }
}

const ButtonPrevious = styled(Button)`
  border-color: ${themes.colors.purple};

  &:hover {
    background-color: rgba(171, 104, 239, 0.2);
    border-color: #ab68ef;
  }
`;
