// -- CORE
import * as React from "react";
import { Box } from "@rebass/grid";
import { useField } from "react-final-form";
// -- COMPONENTS
import { FormHeader, FormSection, SelectInput } from "components/forms";
import { ExternalLink, MessageBox } from "components";
import validators from "utils/validators";
import { minimumDetectableLift } from "components/forms/data/data";

// -- PROPS
type StaticInfoMDLProps = {
  mdlValue: number;
  onChange: (values: {
    [key: string]: string | number | null | object;
  }) => void;
};

// -- COMPONENT
export function StaticInfoMDL({ mdlValue, onChange }: StaticInfoMDLProps) {
  const fieldName = "mdl";

  const { meta, input } = useField(fieldName, {
    validate: validators.required,
  });

  return (
    <FormSection>
      <FormHeader>Minimum Detectable Lift</FormHeader>
      <Box mb={30}>
        <MessageBox closeable={false} transparent={false}>
          This is the minimum % lift from your Control Page that you are willing
          to accept. E.g. if you set this to 9%, and the algorithm discovers a
          new asset that converts 8% better than the Control, it won't be good
          enough, and it will not be deemed a winner over the Control asset.
          <br />A lower MDL also means when assets perform very similarly, it
          will require more traffic for our algorithm to automatically determine
          a winner. If unsure, set this to 8% default value.{" "}
          <ExternalLink
            value={
              "https://upptic.zendesk.com/hc/en-us/articles/360060618432-Minimum-Detectable-Lift"
            }
          >
            See Help article
          </ExternalLink>
          .
        </MessageBox>
      </Box>
      <Box width={1 / 3}>
        <SelectInput
          name={fieldName}
          error={meta.error}
          valid={!meta.error}
          value={String(mdlValue)}
          options={minimumDetectableLift}
          onChange={(value: any) => {
            onChange({
              [fieldName]: value,
            });
            input.onChange(value);
          }}
        />
      </Box>
    </FormSection>
  );
}
