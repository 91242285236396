import React, { useState, FC } from "react";
import styled from "styled-components";
import { Box, Flex } from "@rebass/grid";
import { Field, Form, FormRenderProps } from "react-final-form";

import validators from "utils/validators";
import formatters from "utils/formatters";

import theme from "components/themes";
import { TextInput } from "components/forms";
import FormSection from "components/forms/FormSection";
import { HeaderWithEdit } from "./HeaderWithEdit";
import { DetailCard } from "components/application/forms/components/DetailCard";
import { UserPermissions } from "v2/api/types";

// -- TYPES
interface Props {
  appName: string;
  onNameSubmit: any;
}

// -- COMPONENT
const ApplicationNamePage: FC<Props> = ({ appName, onNameSubmit }) => {
  const [isAppNameEditing, setAppNameEditing] = useState(false);

  const handleNameSubmit = async (values: object) => {
    const isValid = await onNameSubmit(values);
    isValid && setAppNameEditing(false);
  };

  return (
    <Form onSubmit={handleNameSubmit} initialValues={{ name: appName }}>
      {({ handleSubmit, form }: FormRenderProps) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormSection>
            <HeaderWithEdit
              title="App Name"
              editStatus={isAppNameEditing}
              setEditStatus={setAppNameEditing}
              form={form}
              editRequiredPermissions={[
                UserPermissions.updateAppPlatformProfile,
              ]}
            />
            <Flex my={30} justifyContent="space-between">
              <Box width={1} mr={32}>
                {isAppNameEditing ? (
                  <Field
                    name="name"
                    validate={validators.required}
                    formatOnBlur
                    format={formatters.trim}
                  >
                    {({ input, meta }) => {
                      return (
                        <StyledCard>
                          <TextInput
                            type="text"
                            maxLength={30}
                            error={meta.touched && meta.error}
                            {...input}
                          />
                        </StyledCard>
                      );
                    }}
                  </Field>
                ) : (
                  <FormField>{appName}</FormField>
                )}
              </Box>
            </Flex>
          </FormSection>
        </form>
      )}
    </Form>
  );
};

// -- STYLED
const FormField = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: ${theme.colors.white};
`;

const StyledCard = styled(DetailCard)`
  color: ${theme.colors.white};

  font-size: 16px;
`;

export default ApplicationNamePage;
