import React, { useContext } from "react";
import { styled } from "@mui/system";
import { useQuery, useMutation } from "@apollo/client";
import { Flex } from "@rebass/grid";

import { CHANGE_NOTIFICATION_EMAILS } from "api/mutations/notification";
import {
  GET_NOTIFICATIONS,
  HAS_NOTIFICATION_EMAILS_ENABLED,
} from "api/queries/notifications";

import { Notification } from "./consts/interfaces";

import { ApplicationContext, useStores } from "contexts";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { convertDateToDisplayFormat, dateFormatWithTime } from "utils/dates";

import { Spinner, Title as Header } from "components";
import { Switch } from "components/forms";
import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";
import { ThumbnailCell } from "./components/ThumbnailCell";
import { SingleCellProps } from "v2/components/Table/constants/singleCellProps";
import { DescriptionCell } from "./components/DescriptionCell";
import { DynamicTable } from "v2/components/Table/DynamicTable";
import { NotificationRow } from "./components/NotificationRow";
import { Dot } from "components/Dot";
import { MarkAllAsReadElement } from "./components/MarkAllAsReadElement";
import { TdComponent } from "v2/components/Table/components/TdComponent";

// -- COMPONENT
export function NotificationList(): JSX.Element {
  const handleError = useRequestErrorHandler();
  const { userStore } = useStores();

  const applicationCtx = useContext(ApplicationContext);

  const emailQuerySettings = useQuery(HAS_NOTIFICATION_EMAILS_ENABLED);

  const [changeNotificationEmails, emailMutationSettings] = useMutation(
    CHANGE_NOTIFICATION_EMAILS,
  );

  applicationCtx.current && applicationCtx.setCurrent(null);

  const onEmailNotificationsChange = async (value: boolean) => {
    await handleError(
      changeNotificationEmails({
        variables: { enabled: value },
      }),
    );
  };

  const columns = React.useMemo<SingleTableColumn<Notification>[]>(
    () => [
      {
        Header: "Thumbnail",
        accessor: "thumbnail",
        Cell: ThumbnailCell,
        tdElement: CustomThumbnailTd as any,
      },
      {
        Header: "Description",
        accessor: "type",
        Cell: DescriptionCell,
      },
      {
        Header: "App name",
        accessor: "application.name",
        Cell: ({ value }: SingleCellProps<Notification>) => {
          return <NoWrapElement>{value}</NoWrapElement>;
        },
      },
      {
        Header: "Time",
        accessor: "createdAt",
        Cell: ({ value }: SingleCellProps<Notification>) => {
          return (
            <NoWrapElement>
              {convertDateToDisplayFormat(
                value,
                dateFormatWithTime,
                "YYYY-MM-DDTHH:mm:ss.SSS",
              )}
            </NoWrapElement>
          );
        },
      },
      {
        Header: <MarkAllAsReadElement />,
        accessor: "opened",
        tdElement: CustomStatusTd as any,
        Cell: ({ value }: SingleCellProps<Notification>) => {
          if (value) {
            return false;
          }

          return <StyledDot />;
        },
      },
    ],
    [],
  );

  if (emailQuerySettings.loading) {
    return <Spinner style={{ marginTop: 0 }} />;
  }

  return (
    <>
      <Header
        button={
          <Flex>
            {/* TODO: hidden for now, we don't have profile page where probably we move this */}
            {/* <ToggleLabel>Receive Notifications via Email</ToggleLabel>*/}
            {userStore.currentClient?.owner?.emailAddress ===
              userStore.userName && (
              <Switch
                value={
                  emailMutationSettings.data
                    ? emailMutationSettings.data.changeNotificationEmails
                        .enabled
                    : emailQuerySettings.data.hasNotificationEmailsEnabled
                }
                onChange={onEmailNotificationsChange}
              />
            )}
          </Flex>
        }
      >
        Notifications
      </Header>
      <DynamicTable
        columns={columns}
        query={GET_NOTIFICATIONS}
        responseDataFieldName="notifications"
        trElement={NotificationRow}
      />
    </>
  );
}

// -- STYLED
// const ToggleLabel = styled(Box)`
//   color: ${(props: any) => props.theme.colors.white};
//
//   font-size: 14px;
//
//   margin-right: 16px;
//
//   display: flex;
//   align-items: center;
// `;

const NoWrapElement = styled("span")`
  white-space: nowrap;
`;

const StyledDot = styled(Dot)`
  background-color: ${(props: any) => props.theme.colors.purple};
`;

const CustomThumbnailTd = styled(TdComponent)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomStatusTd = styled(TdComponent)`
  width: 50px;
`;

export default NotificationList;
