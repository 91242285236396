import React, { useContext } from "react";
import { ActionItemPanel } from "@upptic/module-ui";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PlatformContext } from "@upptic/module-directory";

type ActionItemsSection = {
  toggledButton: string;
};

const ActionItems = ({ toggledButton }: ActionItemsSection) => {
  const { t } = useTranslation("miniHub");
  const platformStore = useContext(PlatformContext);

  return (
    <>
      {toggledButton !== "other" ? (
        <>
          <Box width="100%" mt={2}>
            <ActionItemPanel
              title={
                toggledButton === "actions"
                  ? t("myAssignedActionItems")
                  : t("myWatcherActionItems")
              }
              contentSx={
                toggledButton === "actions"
                  ? {
                      minHeight: 64,
                      height: "auto",
                      maxHeight: 436,
                    }
                  : {
                      height: "calc(100% - 59px)",
                    }
              }
              externalFilter={
                toggledButton === "actions"
                  ? [
                      {
                        "members.user.type": {
                          $eq: "assignee",
                        },
                      },
                      {
                        "members.user.emailAddress": {
                          $eq: platformStore.currentUser?.emailAddress,
                        },
                      },
                      {
                        "application.code": {
                          $eq: platformStore.selectedApplication?.code,
                        },
                      },
                    ]
                  : [
                      {
                        "members.user.type": {
                          $eq: "watcher",
                        },
                      },
                      {
                        "members.user.emailAddress": {
                          $eq: platformStore.currentUser?.emailAddress,
                        },
                      },
                      {
                        "members.user.notAssignedAsAssignee": {
                          $eq: platformStore.currentUser?.emailAddress,
                        },
                      },
                      {
                        "application.code": {
                          $eq: platformStore.selectedApplication?.code,
                        },
                      },
                    ]
              }
              showEntityLink
            />
          </Box>
          {toggledButton === "actions" ? (
            <Box width="100%" mt={2}>
              <ActionItemPanel
                title={t("allClientActionItems")}
                isCollapse
                contentSx={{
                  minHeight: 64,
                  height: "auto",
                  maxHeight: 436,
                }}
                showEntityLink
                externalFilter={[
                  {
                    $or: [
                      {
                        $and: [
                          {
                            "members.user.type": {
                              $eq: "watcher",
                            },
                          },
                          {
                            "members.user.emailAddress": {
                              $eq: platformStore.currentUser?.emailAddress,
                            },
                          },
                          {
                            "members.user.notAssignedAsAssignee": {
                              $eq: platformStore.currentUser?.emailAddress,
                            },
                          },
                        ],
                      },
                      {
                        "members.user.notAssigned": {
                          $eq: platformStore.currentUser?.emailAddress,
                        },
                      },
                    ],
                  },
                  {
                    "application.code": {
                      $eq: platformStore.selectedApplication?.code,
                    },
                  },
                ]}
              />
            </Box>
          ) : null}
        </>
      ) : (
        <Box width="100%" mt={2}>
          <ActionItemPanel
            title={t("userSpecificActionItems")}
            contentSx={{
              height: "calc(100% - 59px)",
            }}
            isUserSpecific
            showEntityLink
          />
        </Box>
      )}
    </>
  );
};

export default ActionItems;
