import React, { ReactNode, FC } from "react";
import styled from "styled-components";
import { Box, Flex } from "@rebass/grid";
import { isNumber } from "lodash";

import { BarChart } from "components";

export interface Item {
  key: string;
  value: string;
  barWidth?: number;
  barValue?: number | string;
}

interface Props {
  items: Item[];
}

const StatsTable: FC<Props> = ({ items, ...props }) => {
  const renderBarChart = (item: Item): ReactNode => {
    const { barValue, barWidth } = item;
    if (!barWidth || !isNumber(barValue)) {
      return null;
    }

    return <BarChart value={barValue} />;
  };

  return (
    <Container {...props}>
      {items.map((item) => {
        return [
          <Wrapper key={`${item.key}-${item.value}`}>
            <Key>{item.key}</Key>
            <Value>{item.value}</Value>
          </Wrapper>,
          renderBarChart(item),
        ];
      })}
    </Container>
  );
};

const Container = styled(Flex)`
  color: #ffffff;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding: 0 10px;
  margin-top: 15px;
`;

const Wrapper = styled(Flex)`
  justify-content: space-between;
  line-height: 2;
  width: 100%;
`;

const Key = styled(Box)`
  font-size: 11px;
  font-weight: 300;
  opacity: 0.75;
`;

const Value = styled(Box)`
  font-size: 14px;
  font-weight: 600;
`;

export default StatsTable;
