import React, { FC, useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useMutation, useQuery } from "@apollo/client";

import {
  UPDATE_BILLING_DETAILS,
  GET_USER_BILLING_INFO,
  GET_CUSTOMER_DETAILS,
} from "api";

import { Customer } from "screens/Billing/UserBillingDetails/constants/types";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { NarrowTemplate } from "templates";
import { BillingDetailsForm } from "screens/Billing/EditBillingDetails/BillingDetailsForm";
import { Title, Spinner, Error } from "components";
import {
  ApplicationContext,
  ApplicationUserContext,
  useStores,
} from "contexts";

// -- COMPONENT
const EditBillingDetailsPage: FC<RouteComponentProps> = ({ history }) => {
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const applicationUserCtx = useContext(ApplicationUserContext);
  const applicationCtx = useContext(ApplicationContext);
  const { userStore } = useStores();

  useEffect(() => {
    applicationCtx.current && applicationCtx.setCurrent(null);
  }, [applicationCtx]);

  const { data, error, loading } = useQuery<{ customer: Customer }>(
    GET_CUSTOMER_DETAILS,
    { variables: { userId: applicationUserCtx.userId } },
  );
  const [updateBillingDetails] = useMutation(UPDATE_BILLING_DETAILS);

  const handleError = useRequestErrorHandler();

  const redirectToBillingDetails = () => {
    history && history.push(authRoutes.USER_BILLING_DETAILS.getUrlWithParams());
  };

  const onFormCorrectlySubmit = async (formData: Customer) => {
    setFullPageLoader(true);

    const updateBillingDetailsResponse = await handleError(
      updateBillingDetails({
        variables: { ...formData, userId: applicationUserCtx.userId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_BILLING_INFO,
            variables: { clientCode: userStore.clientCode },
          },
        ],
      }),
    );

    updateBillingDetailsResponse && redirectToBillingDetails();

    setFullPageLoader(false);
  };

  if (loading) {
    return <Spinner />;
  }

  if (!data || error) {
    return <Error />;
  }

  return (
    <>
      {fullPageLoader}
      <NarrowTemplate>
        <Title>Update Billings Details</Title>
        <BillingDetailsForm
          onSubmit={onFormCorrectlySubmit}
          onDiscard={redirectToBillingDetails}
          initialData={data.customer}
        />
      </NarrowTemplate>
    </>
  );
};

export default withRouter(EditBillingDetailsPage);
