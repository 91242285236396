import { gql } from "@apollo/client";

export const CREATE_APP_PIXEL = gql`
  mutation createAppPixel(
    $applicationId: ID!
    $eventType: String!
    $pixelId: String!
    $enabled: Boolean!
    $domainTagContent: String!
  ) {
    createFacebookPixel(
      applicationId: $applicationId
      eventType: $eventType
      pixelId: $pixelId
      enabled: $enabled
      domainTagContent: $domainTagContent
    ) {
      isCreated
    }
  }
`;

export const EDIT_APP_PIXEL = gql`
  mutation editAppPixel(
    $applicationId: ID!
    $eventType: String!
    $pixelId: String!
    $enabled: Boolean!
    $domainTagContent: String!
  ) {
    updateFacebookPixel(
      applicationId: $applicationId
      eventType: $eventType
      pixelId: $pixelId
      enabled: $enabled
      domainTagContent: $domainTagContent
    ) {
      isUpdated
    }
  }
`;

export const DELETE_APP_PIXEL = gql`
  mutation deleteAppPixel($applicationId: ID!) {
    deleteFacebookPixel(applicationId: $applicationId) {
      isDeleted
    }
  }
`;
