import * as React from "react";
import styled from "styled-components";

import { SetTopPageAction } from "./components/SetTopPageAction";
import { MarkShipDateAction } from "./components/MarkShipDateAction";
import { ExportAssetAction } from "./components/ExportAssetsAction";
import { Application } from "api";

// -- TYPES
interface ShipSectionProps {
  application: Application;
  downloadUrl: string;
  refetchPage: () => Promise<any>;
}

// -- COMPONENT
function ShipSection({
  application,
  downloadUrl,
  refetchPage,
}: ShipSectionProps): JSX.Element {
  return (
    <Container>
      <Header>Actionable Items</Header>
      {application!.firstIterationEnds && (
        <>
          <Content>
            Set the current Top Converting Page as the new Control for tests
            going forward. Using Export Assets button below you can simply
            download Current Top Page Assets
          </Content>

          <SetTopPageAction applicationId={application.id} />
        </>
      )}

      <ExportAssetAction
        applicationId={application.id}
        refetchPage={refetchPage}
        downloadUrl={downloadUrl}
      />
      {application!.firstIterationEnds && (
        <MarkShipDateAction
          applicationId={application.id}
          refetchPage={refetchPage}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 200px 16px 0;
  text-align: left;

  max-width: 380px;
`;

const Content = styled.div`
  font-size: 14px;
  padding: 13px 0 0;
  margin-bottom: 30px;
`;

const Header = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export default ShipSection;
