import React from "react";
import styled from "styled-components";

import themes from "components/themes";

interface Props {
  className?: string;
  title: string | React.ReactNode;
  children: string | React.ReactNode;
  scrollable?: boolean;
}

function DescriptionCard({
  title,
  children,
  className = "",
  scrollable = false,
}: Props) {
  return (
    <Container className={className}>
      <Title>{title}</Title>
      <Description scrollable={scrollable}>{children}</Description>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${themes.colors.card};
  padding: 20px 30px;
  height: 100%;
  position: relative;
`;

const Title = styled.div`
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
  padding-bottom: 15px;
  margin-bottom: 0;
`;

const Description = styled.div<{ scrollable: boolean }>`
  font-size: 16px;
  line-height: 1.63;
  color: #ffffff;
  overflow-y: ${(props: any) => (props.scrollable ? "auto" : "initial")};
  max-height: ${(props: any) => (props.scrollable ? "160px" : "inherit")};
`;

export default DescriptionCard;
