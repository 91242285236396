import React from "react";
import styled from "styled-components";

type ExternalLinkProps = {
  value: string;
  children?: any;
};

function ExternalLink({ value, children, ...rest }: ExternalLinkProps) {
  return (
    <Link href={value} target="_blank" {...rest}>
      {children || value}
    </Link>
  );
}

const Link = styled.a`
  color: inherit;

  cursor: pointer;

  text-decoration: underline;

  &:hover {
    color: inherit;
  }
`;

export default ExternalLink;
