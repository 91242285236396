import { gql } from "@apollo/client";
export const REGISTER = gql`
  mutation register($user: RegisterUser!) {
    register(user: $user)
  }
`;

export const SIGN_UP_ACTIVATE = gql`
  mutation signUpActivate($membershipId: String!, $token: String!) {
    signUpActivate(membershipId: $membershipId, token: $token) {
      token
      isActivated
    }
  }
`;

export const GENERATE_ACTIVATION_TOKEN = gql`
  mutation generateActivationToken($membershipId: String!) {
    generateActivationToken(membershipId: $membershipId) {
      membershipId
    }
  }
`;

export const SIGN_IN = gql`
  mutation login(
    $email: String!
    $password: String!
    $recaptchaToken: String!
    $otpToken: String
  ) {
    login(
      emailAddress: $email
      password: $password
      recaptchaToken: $recaptchaToken
      otpToken: $otpToken
    ) {
      token
      user {
        emailAddress
        firstName
        lastName
        isUppticUser
      }
    }
  }
`;

export const LOGIN_EXTERNAL_OTP = gql`
  mutation loginExternalOTP($token: String!, $otpToken: String!) {
    loginExternalOTP(token: $token, otpToken: $otpToken) {
      token
      user {
        emailAddress
        firstName
        lastName
        isUppticUser
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetPassword(emailAddress: $email)
  }
`;

export const SetUserPasswordMutation = gql`
  mutation setUserPassword($token: String!, $password: String!) {
    setUserPassword(token: $token, password: $password) {
      emailAddress
    }
  }
`;

export const SET_USER_PASSWORD = gql`
  mutation setUserPassword($token: String!, $password: String!) {
    setUserPassword(token: $token, password: $password) {
      emailAddress
    }
  }
`;
