import React, { useContext, useState } from "react";
import {
  EntitySelectorContext,
  PlatformContext,
  entitySelectors,
} from "@upptic/module-directory";

export default function EntitySelectorWrapper(props: any) {
  const [state, setState] = useState<any>({ open: false });
  const platformStore = useContext(PlatformContext);
  const selectedClientCode = platformStore.currentClient?.code;
  const selectedApplicationCode = platformStore.selectedApplication?.code;
  const contextState = {
    openEntitySelector: (entity: string, params: any) => {
      if (!entitySelectors[entity]) {
        console.error(`No entity selector compoent found for ${entity}`);
        return;
      }

      setState({
        open: true,
        Component: entitySelectors[entity],
        onSelect: params.onSelect,
        onClose: params.onClose,
        selectedValue: params.selectedValue,
        confirmationOpen: params.confirmationOpen,
        showUnlink: params.showUnlink,
        onUnlink: params.onUnlink,
      });
    },
  };

  const onClose = () => {
    if (state.onClose) state.onClose();
    setState({ open: false });
  };

  const onSelect = async (data: any) => {
    if (state.onSelect) await state.onSelect(data);
    setState({ open: false });
  };

  const onUnlink = async () => {
    if (state.onUnlink) await state.onUnlink();
    setState({ open: false });
  };

  const setConfirmationOpen = (open: boolean) => {
    setState((prevState: any) => ({ ...prevState, confirmationOpen: open }));
  };

  return (
    <>
      <EntitySelectorContext.Provider value={contextState}>
        {props.children}
      </EntitySelectorContext.Provider>
      {state.open ? (
        <state.Component
          open={state.open}
          onClose={onClose}
          onSelect={onSelect}
          clientCode={selectedClientCode}
          applicationCode={selectedApplicationCode}
          selectedValue={state.selectedValue}
          setConfirmationOpen={setConfirmationOpen}
          confirmationOpen={state.confirmationOpen}
          showUnlink={state.showUnlink}
          onUnlink={onUnlink}
        />
      ) : null}
    </>
  );
}
