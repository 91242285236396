import React from "react";
import { Box } from "@rebass/grid";

import { ExperimentPhase, ExperimentPhaseType, Preview } from "api/models";

import { ordinalFormat } from "utils/numbers";

import { ElementsProps } from "../constants/elementsProps";

import { HiddenFlex } from "../components/uiElement";
import { ApplicationImageAssets } from "../components/ApplicationImageAssets";

interface Props extends ElementsProps {
  applicationId: string;
  currentPhase?: ExperimentPhase | null;
  assets: Preview[];
  position: string;
  history: any;
  trafficIndicator?: string;
}

function ApplicationElementsPage({
  applicationId,
  history,
  currentPhase = null,
  assets,
  onControl,
  onRemove,
  onDeactivate,
  onSetTop,
  position,
  trafficIndicator,
  currentIteration,
}: Props) {
  const phases = {
    1: ExperimentPhaseType.preview_1,
    2: ExperimentPhaseType.preview_2,
    3: ExperimentPhaseType.preview_3,
  };
  // @ts-ignore
  const phase: ExperimentPhaseType = phases[position];

  return (
    <HiddenFlex flexDirection="column">
      <Box>
        <ApplicationImageAssets
          applicationId={applicationId}
          title={`${ordinalFormat(parseInt(position, 10))} Screens`}
          assets={assets}
          onControl={onControl}
          onRemove={onRemove}
          onDeactivate={onDeactivate}
          onSetTop={onSetTop}
          phaseType={phase}
          currentPhase={currentPhase}
          history={history}
          trafficIndicator={trafficIndicator}
          currentIteration={currentIteration}
        />
      </Box>
    </HiddenFlex>
  );
}

export default ApplicationElementsPage;
