// -- CORE
import * as React from "react";
import { ApolloError } from "@apollo/client";
import { isFunction } from "lodash";
// -- CONTEXT
import { ModalContext } from "contexts";
// -- UTILS
import { errorMessageHandler } from "utils/errorHandlers/errorMessageHandler";

// -- CUSTOM HOOK
export const useRequestErrorHandler = () => {
  const setModal = React.useContext(ModalContext);

  const handleErrorWithModal = async (
    promise: Promise<any>,
    errorCallback?: () => any,
  ) =>
    promise.catch((error: ApolloError) => {
      console.error(error);
      const errorMessage = errorMessageHandler(error);

      setModal({
        type: "set",
        props: {
          content: errorMessage,
        },
      });
      isFunction(errorCallback) && errorCallback();
    });

  return handleErrorWithModal;
};
