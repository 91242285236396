import * as React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

import { Icons, Icon } from "components";
import { PermissionRestriction } from "v2/components/PermissionRestriction";
import { UserPermissions } from "v2/api/types/UserPermissions";

// -- TYPES
type EditOnHoverWithRedirectProps = {
  label: string | React.ReactNode;
  editUrl: string;
  editLabel: string;
  editPermissions: UserPermissions[];
};

// -- COMPONENT
export function EditOnHoverWithRedirect({
  label,
  editLabel,
  editUrl,
  editPermissions,
}: EditOnHoverWithRedirectProps): JSX.Element {
  return (
    <Wrapper>
      {label}
      <PermissionRestriction requiredPermissions={editPermissions}>
        <EditSection to={editUrl}>
          <ActionIcon icon={Icons.faPen} />
          {editLabel}
        </EditSection>
      </PermissionRestriction>
    </Wrapper>
  );
}

// -- STYLED
const Wrapper = styled("div")`
  display: inline-block;

  &:hover {
    a:last-of-type {
      visibility: visible;
    }
  }
`;
const EditSection = styled(Link)`
  display: inline-block;

  visibility: hidden;

  color: ${(props: any) => props.theme.colors.white};

  text-decoration: underline;

  padding: 0 8px;

  &:hover {
    color: ${(props: any) => props.theme.colors.white};

    background-color: rgba(171, 104, 239, 0.2);

    transition: 0.5s;

    border-radius: 2px;
  }
`;
const ActionIcon = styled(Icon)`
  margin-right: 5px;
`;
