import * as React from "react";
import { useMutation } from "@apollo/client";
import moment from "moment";

import { SET_SHIP_DATE } from "api/mutations";

import { ModalContext } from "contexts";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Icons } from "components/Icon";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ButtonBox, ShipButton, TooltipIcon } from "./UiElements";
import { ExportAssetsModalComponent } from "./ExportAssetsModalComponent";
import { PermissionRestriction } from "v2/components/PermissionRestriction";
import { UserPermissions } from "v2/api/types";

// -- TYPES
type ExportAssetActionProps = {
  applicationId: string;
  refetchPage: () => Promise<any>;
  downloadUrl: string;
};

// -- COMPONENT
export function ExportAssetAction({
  applicationId,
  refetchPage,
  downloadUrl,
}: ExportAssetActionProps) {
  const [markShipDate, setMarShipDate] = React.useState<boolean>(false);
  const [shipDate, setShipDate] = React.useState<string>(
    moment().format("DD-MM-YYYY"),
  );
  const setModal = React.useContext(ModalContext);

  const [createShipDate] = useMutation(SET_SHIP_DATE);

  const handleError = useRequestErrorHandler();

  const exportText = `Export the creative assets for the current Top 
    Converting Page to a .zip file to easily share within your 
    organization.`;

  const handleChange = (isChecked: boolean, date: string | null) => {
    if (date) {
      setShipDate(date);
      setMarShipDate(isChecked);

      setModal({
        type: "update",
        props: {
          content: (
            <ExportAssetsModalComponent
              exportText={exportText}
              isShipDateMarked={isChecked}
              shipDate={date}
              onChange={handleChange}
            />
          ),
          onConfirm: handleModalConfirm(isChecked, date),
        },
      });
    }
  };

  const handleModalConfirm = (markDate: boolean, date: string) => async () => {
    if (markDate) {
      const options = {
        variables: { applicationId, date },
      };

      const createShipDateResult = await handleError(createShipDate(options));
      createShipDateResult && (await handleError(refetchPage()));
    }

    window.location.href = downloadUrl;
  };

  const resetModalInputs = () => {
    setMarShipDate(false);
    setShipDate(moment().format("DD-MM-YYYY"));
  };

  const handleExportAssets = () => {
    setModal({
      type: "set",
      props: {
        title: "Export Assets",
        content: (
          <ExportAssetsModalComponent
            exportText={exportText}
            isShipDateMarked={markShipDate}
            shipDate={shipDate}
            onChange={handleChange}
          />
        ),
        onConfirm: handleModalConfirm(markShipDate, shipDate),
        onHide: resetModalInputs,
        confirmText: "Export",
      },
    });
  };

  return (
    <ButtonBox>
      <PermissionRestriction
        requiredPermissions={[UserPermissions.readASOAsset]}
      >
        <ShipButton onClick={handleExportAssets}>Export Assets</ShipButton>
        <Tooltip content={exportText} placement={"bottom"}>
          <TooltipIcon icon={Icons.faQuestionCircle} />
        </Tooltip>
      </PermissionRestriction>
    </ButtonBox>
  );
}
