import React, { useContext, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useQuery } from "@apollo/client";

import { AssetType } from "api/models";
import { GET_APPLICATION_TITLE_AND_STATUS } from "api/queries";
import {
  UPDATE_CONTROL_DESCRIPTION,
  UPDATE_CONTROL_SUBTITLE,
  UPDATE_CONTROL_TITLE,
} from "api/mutations/assets";
import { TextAssetData } from "components/application/forms/TextAssetStep/constants/textAssetData";

import { ApplicationContext } from "contexts";

import ApplicationControlUpdateContainer from "../components/ApplicationControlUpdateContainer";
import { Spinner, Error } from "components";
import TextAssetStepDescriptions from "components/application/forms/TextAssetStep/components/TextAssetStepDescriptions";
import TextAssetStepTitles from "components/application/forms/TextAssetStep/components/TextAssetStepTitles";
import TextAssetStepSubtitles from "components/application/forms/TextAssetStep/components/TextAssetStepSubtitles";

// -- TYPES
interface Props extends RouteComponentProps<{ id: string; type: string }> {}

// -- COMPONENT
function EditControlTextsPage(props: Props) {
  const defaultState: TextAssetData = {
    value: "",
    control: "",
    values: [],
  };
  const applicationId = props.match.params.id;

  const [formData, setFormData] = useState(defaultState);
  const applicationCtx = useContext(ApplicationContext);

  const { data, error, loading } = useQuery(GET_APPLICATION_TITLE_AND_STATUS, {
    variables: { id: applicationId },
  });

  const assetTypeConfig = React.useMemo(() => {
    const assetType = props.match.params.type;

    let mutationToUse = UPDATE_CONTROL_TITLE;
    let title = "Upload New Control Title";
    let FormComponent:
      | typeof TextAssetStepTitles
      | typeof TextAssetStepSubtitles
      | typeof TextAssetStepDescriptions = TextAssetStepTitles;

    if (assetType === AssetType.subtitle) {
      mutationToUse = UPDATE_CONTROL_SUBTITLE;
      title = "Upload New Control Subtitle";
      FormComponent = TextAssetStepSubtitles;
    }

    if (assetType === AssetType.description) {
      mutationToUse = UPDATE_CONTROL_DESCRIPTION;
      title = "Upload New Control Description";
      FormComponent = TextAssetStepDescriptions;
    }

    return {
      mutationToUse,
      title,
      FormComponent,
    };
  }, [props.match.params.type]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  applicationCtx.setCurrent(data.application);

  const platform = data.application.platform;

  const handleChange = (changedFormData: TextAssetData) => {
    setFormData({
      ...formData,
      ...changedFormData,
    });
  };

  const renderFormElement = () => {
    const { title, FormComponent } = assetTypeConfig;

    return (
      <FormComponent
        title={title}
        data={formData}
        disableControl={false}
        onChange={handleChange}
        maxUploadAssets={1}
        platform={platform}
      />
    );
  };

  return (
    <ApplicationControlUpdateContainer
      mutation={assetTypeConfig.mutationToUse}
      formData={formData}
      {...props}
    >
      {renderFormElement()}
    </ApplicationControlUpdateContainer>
  );
}

export default EditControlTextsPage;
