import * as React from "react";
import styled from "styled-components";
import { Box, Flex } from "@rebass/grid";

import { Application } from "api/models";

import { Section } from "components";
import { ApplicationPreview, ShipSection } from "components/application";

// -- TYPES
type ApplicationPagePreviewProps = {
  controlPageUrl: string;
  hasTopPage: boolean;
  topPageUrl: string;
  application: Application;
  zipFileUrl: string;
  refetchPage: () => Promise<any>;
};

// -- COMPONENT
export const ApplicationPagePreview = React.forwardRef(
  (
    {
      controlPageUrl,
      hasTopPage,
      topPageUrl,
      application,
      zipFileUrl,
      refetchPage,
    }: ApplicationPagePreviewProps,
    ref,
  ): JSX.Element => {
    return (
      <Box id={"#app-preview"} mb={30} ref={ref}>
        <Section title="App Page Preview" transparentHeader>
          <PagePreviewContent>
            <ApplicationPreviews>
              <ApplicationPreview url={controlPageUrl} title="Control Page" />
              {hasTopPage && (
                <ApplicationPreview url={topPageUrl} title="Current Top Page" />
              )}
            </ApplicationPreviews>
            {hasTopPage && (
              <ShipSection
                application={application}
                downloadUrl={zipFileUrl}
                refetchPage={refetchPage}
              />
            )}
          </PagePreviewContent>
        </Section>
      </Box>
    );
  },
);

// -- STYLED
const PagePreviewContent = styled.div`
  display: flex;
`;

const ApplicationPreviews = styled(Flex)`
  overflow-y: auto;

  padding-bottom: 15px;
`;
