import * as React from "react";
import { styled } from "@mui/system";

import { Application, ExperimentStatistics, Status } from "api/models";
import { SingleActionableItem } from "../constants/SingleActionableItem";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { scrollToCoordinates } from "utils/scrollToCoordinates";

import { Icon, Icons, Tooltip } from "components";
import themes from "components/themes";
import {
  ActionableItem,
  ActionableItemWithoutLink,
  Header,
  IconBox,
} from "./styled";
import { UserPermissions } from "v2/api/types/UserPermissions";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- TYPES
type ActionableItemsSectionProps = {
  application: Application;
  history: any;
  experimentStatistics: ExperimentStatistics;
  hasTopPage: boolean;
  appPrevieRef: React.RefObject<unknown>;
};

export function ActionableItemsSection({
  application,
  history,
  experimentStatistics,
  hasTopPage,
  appPrevieRef,
}: ActionableItemsSectionProps): JSX.Element {
  const smoothScrool = (ref: any): void => {
    if (ref && ref.current) {
      scrollToCoordinates("smooth", ref.current.offsetTop - 130, 0);
    }
  };

  const actionableItems: SingleActionableItem[] = [
    {
      title: "Send More Traffic for Testing",
      tooltipMessage:
        "Creatives are currently in testing. The more traffic you send, the faster the tests will perform.",
      disabled:
        application.status === Status.inactive ||
        application.status === Status.finished,
      onClick: () =>
        history.push(
          authRoutes.APPLICATION_ROUTING.getUrlWithParams({
            id: application.id,
          }),
        ),
      itemPermissions: [UserPermissions.readASORouting] || [],
    },
    {
      title: "Upload More Creatives for Testing",
      tooltipMessage:
        "There are no more creatives queued for testing. Upload more creatives to continue testing.",
      disabled: !!experimentStatistics.creativesQueued,
      onClick: null,
      itemPermissions: [UserPermissions.createASOAsset],
    },
    {
      title: "Set Top Page as New Control",
      tooltipMessage:
        "A new Top Converting Page has been discovered that performs better than the Control. You may want to review and set this as the new Control so further tests uses this as the new baseline. You may also want to export the creatives and ship it to your actual app store for use.",
      disabled: !(hasTopPage && application.firstIterationEnds),
      onClick: () => smoothScrool(appPrevieRef),
      itemPermissions: [UserPermissions.updateAppPlatformProfile],
    },
  ];

  const renderItemList = (): React.ReactNode =>
    actionableItems.map((singleItem) => {
      const {
        title,
        tooltipMessage,
        disabled,
        onClick = () => null,
        itemPermissions,
      } = singleItem;

      if (disabled) {
        return null;
      }

      return (
        <PermissionRestriction
          requiredPermissions={itemPermissions}
          key={title}
        >
          <ActionableItemWrapper>
            {onClick ? (
              <ActionableItem onClick={onClick}>{title}</ActionableItem>
            ) : (
              <ActionableItemWithoutLink>{title}</ActionableItemWithoutLink>
            )}
            <IconBox>
              <Tooltip content={tooltipMessage}>
                <Icon
                  icon={Icons.faQuestionCircle}
                  color={themes.colors.white}
                />
              </Tooltip>
            </IconBox>
          </ActionableItemWrapper>
        </PermissionRestriction>
      );
    });

  return (
    <>
      <Header>Actionable Items</Header>
      <ActionableItemList>{renderItemList()}</ActionableItemList>
    </>
  );
}

// -- STYLED
const ActionableItemList = styled("div")`
  background-color: rgb(24, 24, 49);

  border-radius: 8px;
`;

const ActionableItemWrapper = styled("div")`
  padding: 13px 25px;

  border-bottom: 1px solid ${(props: any) => props.theme.colors.backgroundDark};

  display: flex;
  justify-content: space-between;
`;
