import React, { useState } from "react";
import { Flex, Box } from "@rebass/grid";
import { styled } from "@mui/system";

import { TextInput } from "components/forms";
import { ExternalLink, Icon, Icons } from "components";

// -- TYPES
interface HoverInputProps {
  value: string | number;
  onSave: (value: string | number) => void;
  onDiscard?: ((value: string | number) => void) | null;
  inputProps: any;
  className?: string;
  clickable?: boolean;
  inputSufix?: string;
  maxLength?: number;
  isEditAvailable?: boolean;
  valueValidators?:
    | ((value: any, allValues: any) => any)
    | ((value: any) => any);
}

// -- COMPONENT
function HoverInput({
  value,
  onSave,
  onDiscard = null,
  className = "",
  clickable = false,
  inputProps,
  inputSufix,
  valueValidators,
  maxLength,
  isEditAvailable = true,
}: HoverInputProps) {
  const [isHovered, setHovered] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [localValue, setLocalValue] = useState<string | number>(value);
  const [error, setError] = useState<string>("");

  const renderClickable = () => {
    if (typeof value === "string") {
      return <ExternalLink value={value} />;
    } else {
      return <span>{value}</span>;
    }
  };

  const onSaveAction = () => {
    if (typeof value === "string" && typeof localValue === "string") {
      if (valueValidators) {
        const validatorError = valueValidators(localValue, {});
        setError(validatorError || "");

        if (validatorError) {
          return false;
        }
      }
    } else {
      if (valueValidators) {
        const validatorError = valueValidators(Number(localValue), {});
        setError(validatorError || "");

        if (validatorError) {
          return false;
        }
      }
    }

    onSave(localValue);
    setEditing(false);
  };

  const onDiscardAction = () => {
    setEditing(false);
    setLocalValue(value);
    setError("");

    if (onDiscard) {
      onDiscard(localValue);
    }
  };

  const changeHoveredStatus = (status: boolean) => () => setHovered(status);

  const setEditingOn = () => {
    setEditing(true);
    setLocalValue(value);
  };

  const handleChange = (event: any) => {
    if (
      maxLength !== undefined &&
      String(event.target.value).length > maxLength
    ) {
      return null;
    }

    setLocalValue(event.target.value);
  };

  const renderContent = () => {
    if (isEditing) {
      const input = (
        <StyledInput
          name="value"
          value={localValue}
          error={error}
          showError={false}
          onChange={handleChange}
          {...inputProps}
          inputSufix={inputSufix}
        />
      );

      if (!inputSufix) {
        return input;
      }

      return (
        <InputWrapper error={Boolean(error)}>
          {input}
          <Suffix>{inputSufix}</Suffix>
        </InputWrapper>
      );
    }

    return clickable ? renderClickable() : <span>{value}</span>;
  };

  const renderControls = () => {
    const controlButtons = !isEditing ? (
      <Action onClick={setEditingOn}>
        <ActionIcon icon={Icons.faPen} /> Edit
      </Action>
    ) : (
      <>
        <Action onClick={onSaveAction}>
          <ActionIcon icon={Icons.faCheck} />
          Save
        </Action>

        <Action onClick={onDiscardAction}>
          <ActionIcon icon={Icons.faWindowClose} />
          Discard
        </Action>
      </>
    );

    return <Actions>{controlButtons}</Actions>;
  };

  return (
    <Flex
      alignItems="center"
      onMouseEnter={changeHoveredStatus(true)}
      onMouseLeave={changeHoveredStatus(false)}
      className={className}
    >
      <Box>{renderContent()}</Box>
      {isHovered && isEditAvailable && renderControls()}
    </Flex>
  );
}

const Actions = styled(Box)`
  padding-left: 15px;

  display: flex;
`;

const Action = styled("span")`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.6px;
  margin-right: 15px;
  cursor: pointer;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
  text-decoration: underline;
  white-space: nowrap;

  :hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
  }
`;

const ActionIcon = styled(Icon)`
  margin-right: 5px;
`;

const InputWrapper = styled("div")<{ error: boolean }>`
  display: flex;

  ${(props: any) =>
    props.error &&
    `
    box-shadow: 0 0 15px 0 ${props.theme.colors.notificationRedBoxShadow};
    border: 1px solid ${props.theme.colors.notificationRed};
  `};
`;

const StyledInput = styled(TextInput)<Pick<HoverInputProps, "inputSufix">>`
  ${(props) =>
    props.inputSufix &&
    `
    padding-right: 0px;
    box-shadow: none;
    border: 1px solid ${props.theme.colors.background};
  `};
`;

const Suffix = styled("div")`
  display: flex;

  border: 1px solid ${(props: any) => props.theme.colors.background};
  border-left-width: 0px;

  background-color: ${(props: any) => props.theme.colors.inputs};

  opacity: 0.8;

  margin-left: -1px;
  padding-right: 15px;

  align-items: center;
`;

export default HoverInput;
