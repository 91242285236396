import React from "react";
import styled from "styled-components";
import { Form, FormRenderProps, Field } from "react-final-form";

import validators from "utils/validators";

import { CreditCardInput } from "components/billing/CreditCardInput";
import { Button } from "components/forms";

// -- TYPES
type FailedPaymentsFormProps = {
  onSubmit: (values: object) => void;
};

// -- COMPONENT
export function FailedPaymentsForm({
  onSubmit,
}: FailedPaymentsFormProps): JSX.Element {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }: FormRenderProps) => (
        <form onSubmit={handleSubmit}>
          <Wrapper>
            <Field name="cardValid" validate={validators.required}>
              {({ input, meta }) => (
                <CreditCardInput
                  onChange={input.onChange}
                  externalError={meta.touched && meta.error}
                />
              )}
            </Field>
          </Wrapper>

          <Footer>
            <Button type="submit">Pay now</Button>
          </Footer>
        </form>
      )}
    </Form>
  );
}

// -- STYLED
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 40px;
`;
const Wrapper = styled.div`
  padding: 40px;
  margin: 30px 0;

  background-color: #1e1e36;

  border-radius: 8px;
`;
