import * as React from "react";
import { RouteComponentProps } from "react-router";

import { createIcons } from "v2/api/mutations/application/icons";
import { ReferenceVariationType } from "api/models";

import { StepData } from "components/application/forms/IconStep";
import ApplicationUpdateContainer from "../ApplicationUpdateContainer";
import ApplicationUpdateIconsPage from "./ApplicationUpdateIconsPage";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

interface Props extends RouteComponentProps<{ id: string }> {}

function ApplicationUpdateIconsContainer(props: Props) {
  const [fullPageLoader, setIsLoading] = useFullPageLoader();

  const handleOnSubmit = (onSubmitFunc: any) => async (formData: StepData) => {
    setIsLoading(true);
    // TODO: zweryfikować czy nie potrzeba try catcha
    await onSubmitFunc({
      data: formData.assets.map(() => ({
        isControl: false,
      })),
      files: formData.assets.map((d) => d.file),
      referenceVariationType:
        formData.referenceVariationType || ReferenceVariationType.top,
    });
    setIsLoading(false);
  };

  return (
    <>
      {fullPageLoader}
      <ApplicationUpdateContainer {...props} mutation={createIcons.definition}>
        {(application: any, onDiscard: () => void, onSubmit: () => void) => (
          <ApplicationUpdateIconsPage
            onDiscard={onDiscard}
            onSubmit={handleOnSubmit(onSubmit)}
          />
        )}
      </ApplicationUpdateContainer>
    </>
  );
}

export default ApplicationUpdateIconsContainer;
