import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

import { NotificationV2 } from "screens/Notification/consts/interfaces";
import { Link } from "react-router-dom";

type SingleNotificationCardProps = {
  notification: NotificationV2;
  onClick: () => void;
  onDismissClick: (isRead: boolean) => void;
};

const linkRegex = /^(.*)<(https:\/\/.*)\|(.*)>$/m;

const SingleNotificationCard = ({
  notification,
  onClick,
  onDismissClick,
}: SingleNotificationCardProps) => {
  const { subject, status, content } = notification;
  const createDate = notification?.audit?.createDate;
  const [expanded, setExpanded] = useState(false);
  const [isRead, setIsRead] = useState(false);
  useEffect(() => {
    if (status === "read") setIsRead(true);
  }, [status]);

  const contentToRender = useMemo(() => {
    const linkRegexResult = linkRegex.exec(content);
    if (linkRegexResult) {
      const url = new URL(linkRegexResult[2]);
      return (
        <>
          {linkRegexResult[1]}
          <Link to={`${url.pathname}${url.search}`}>{linkRegexResult[3]}</Link>
        </>
      );
    }
    return content;
  }, [content]);

  return (
    <Card
      sx={{
        "&:not(last-of-type)": {
          marginBottom: "8px",
        },
        "padding": "8px 8px 8px 8px",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <CardActions
        sx={{
          padding: 0,
        }}
        onClick={() => {
          if (!isRead) onClick();
          setExpanded((prevState) => !prevState);
          if (!isRead) setIsRead(true);
        }}
      >
        <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
          <Grid item xs display="flex" flexDirection="row" alignItems="center">
            <Box
              width={30}
              height={30}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircleIcon color={!isRead ? "primary" : "disabled"} />
            </Box>
            <Box pl={0.5}>
              <Typography>{subject}</Typography>
            </Box>
          </Grid>
          <Grid
            item
            width={70}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box mr={1}>
              <Typography>
                {createDate && moment(createDate).format("L")}
              </Typography>
            </Box>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDismissClick(isRead);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto">
        <CardContent
          sx={{
            padding: "8px 0 0 0",
            paddingBottom: "0px !important",
          }}
        >
          <Typography>{contentToRender}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SingleNotificationCard;
