import * as React from "react";
import { useMutation } from "@apollo/client";

import { GET_APPLICATION_OVERVIEW } from "api/queries";
import { setTopPageAsNewControl } from "v2/api/mutations/application/setTopPageAsNewControl";

import { ModalContext } from "contexts";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Icons } from "components/Icon";
import { Tooltip } from "components/Tooltip/Tooltip";
import { ButtonBox, ShipButton, TooltipIcon } from "./UiElements";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- TYPES
type SetTopPageActionProps = {
  applicationId: string;
};

// -- COMPONENT
export function SetTopPageAction({ applicationId }: SetTopPageActionProps) {
  const setModal = React.useContext(ModalContext);

  const [setAsControl] = useMutation(setTopPageAsNewControl.definition);

  const handleError = useRequestErrorHandler();

  const setAsControlContent =
    "Set the current Top Converting Page as the new Control for tests going forward. It is highly recommended that you export the assets and update the actual app store with the new assets as well.";

  const onSetTopPageAsNewControl = async () => {
    const options = {
      variables: { applicationId },
      awaitRefetchQueries: true,
      refetchQueries: [
        "application",
        {
          query: GET_APPLICATION_OVERVIEW,
          variables: { applicationId },
        },
      ],
    };

    const onConfirm = async () => {
      await handleError(setAsControl(options));
    };

    setModal({
      type: "set",
      props: {
        title: "Set Top Page as New Control",
        content: setAsControlContent,
        onConfirm,
        confirmText: "Confirm",
      },
    });
  };

  return (
    <ButtonBox>
      <PermissionRestriction
        requiredPermissions={setTopPageAsNewControl.requiredPermissions}
      >
        <ShipButton onClick={onSetTopPageAsNewControl}>
          Set Top Page as New Control
        </ShipButton>
        <Tooltip content={setAsControlContent} placement={"bottom"}>
          <TooltipIcon icon={Icons.faQuestionCircle} />
        </Tooltip>
      </PermissionRestriction>
    </ButtonBox>
  );
}
