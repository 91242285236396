import React, { useContext } from "react";
import { RouteComponentProps } from "react-router";
import { useMutation, useQuery } from "@apollo/client";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import {
  ApplicationAsset,
  AssetType,
  ExperimentPhaseType,
  Status,
} from "api/models";
import { updateReferenceVariation as updateReferenceVariationMutation } from "v2/api/mutations/application/updateReferenceVariation";
import {
  UPDATE_CONTROL_TITLE,
  UPDATE_CONTROL_SUBTITLE,
  UPDATE_CONTROL_DESCRIPTION,
} from "api/mutations/assets";

import {
  createDescriptions,
  deactivateDescription,
  deleteDescription,
} from "v2/api/mutations/application/descriptions";
import {
  createSubtitles,
  deactivateSubtitle,
  deleteSubtitle,
} from "v2/api/mutations/application/subtitles";
import {
  createTitles,
  deactivateTitle,
  deleteTitle,
} from "v2/api/mutations/application/titles";

import {
  GET_APPLICATION_TITLES,
  GET_APPLICATION_SUBTITLES,
  GET_APPLICATION_DESCRIPTIONS,
} from "api/queries/assets";

import { ApplicationContext, ModalContext } from "contexts";

import { getTrafficIndicator } from "utils/statistics";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
import { useHasUserPermission } from "v2/hooks/useHasUserPermission";

import { runAction } from "../helpers/runAction";
import { updateReferenceVariation } from "../helpers/updateReferenceVariation";

import Page from "./ApplicationElementsTextsPage";
import { Spinner, Error } from "components";
import ApplicationElementsContainer from "screens/Application/ApplicationElements/components/ApplicationElementsContainer";

interface Props extends RouteComponentProps<{ id: string; type: string }> {}

function ApplicationElementsTextsContainer({ history, match }: Props) {
  const hasUserPermission = useHasUserPermission();

  const { id: applicationId, type } = match.params;

  let typeConfig: any;
  if (match.params.type === "descriptions") {
    typeConfig = {
      title: "Descriptions",
      query: GET_APPLICATION_DESCRIPTIONS,
      deleteMutation: deleteDescription,
      controlMutation: UPDATE_CONTROL_DESCRIPTION, // TODO: do we need this
      deactivateMutation: deactivateDescription,
      getAssets: (a: any) => a.descriptions,
      type: AssetType.description,
      phase: ExperimentPhaseType.description,
      uploadButtonContent: "Add New Description",
      permissionsForUpload: createDescriptions.requiredPermissions,
    };
  } else if (match.params.type === "titles") {
    typeConfig = {
      title: "Titles",
      query: GET_APPLICATION_TITLES,
      deleteMutation: deleteTitle,
      controlMutation: UPDATE_CONTROL_TITLE, // TODO: do we need this
      deactivateMutation: deactivateTitle,
      getAssets: (a: any) => a.titles,
      type: AssetType.title,
      phase: ExperimentPhaseType.title,
      uploadButtonContent: "Add New Title",
      permissionsForUpload: createTitles.requiredPermissions,
    };
  } else {
    typeConfig = {
      title: "Subtitles",
      query: GET_APPLICATION_SUBTITLES,
      deleteMutation: deleteSubtitle,
      controlMutation: UPDATE_CONTROL_SUBTITLE, // TODO: do we need this
      deactivateMutation: deactivateSubtitle,
      getAssets: (a: any) => a.subtitles,
      type: AssetType.subtitle,
      phase: ExperimentPhaseType.subtitle,
      uploadButtonContent: "Add New Subtitle",
      permissionsForUpload: createSubtitles.requiredPermissions,
    };
  }

  const { data, error, loading } = useQuery(typeConfig.query, {
    variables: { applicationId },
    fetchPolicy: "network-only",
  });
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const [deleteMutation] = useMutation(typeConfig.deleteMutation.definition);
  const [controlMutation] = useMutation(typeConfig.controlMutation);
  const [updateReferenceMutation] = useMutation(
    updateReferenceVariationMutation.definition,
  );
  const [deactivateMutation] = useMutation(
    typeConfig.deactivateMutation.definition,
  );

  const setModal = useContext(ModalContext);
  const applicationCtx = useContext(ApplicationContext);

  const canAbort =
    data?.currentPhase != null && data?.currentPhase.type === typeConfig.phase;

  const refetchQuery = {
    query: typeConfig.query,
    variables: { applicationId },
  };

  const trafficIndicator =
    applicationCtx.current &&
    data &&
    applicationCtx.current.status === Status.active
      ? getTrafficIndicator(data.phaseTraffic, data.trafficNeeded)
      : undefined;

  const removeAsset = React.useCallback(
    (asset: ApplicationAsset) => async () => {
      await runAction(
        applicationId,
        asset,
        deleteMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    },
    [applicationId, deleteMutation, setModal, refetchQuery, setFullPageLoader],
  );

  const displayRemoveModal = React.useMemo(() => {
    if (!hasUserPermission(typeConfig.deleteMutation.requiredPermissions)) {
      return null;
    }

    return (asset: ApplicationAsset) => {
      setModal({
        type: "set",
        props: {
          title: "Delete",
          content: "Are you sure you want to delete this asset?",
          onCancel: () => {},
          onConfirm: removeAsset(asset),
        },
      });
    };
  }, [typeConfig.deleteMutation, hasUserPermission, setModal, removeAsset]);

  const deactivateAsset = React.useCallback(
    (asset: ApplicationAsset) => async () => {
      await runAction(
        applicationId,
        asset,
        deactivateMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    },
    [
      applicationId,
      deactivateMutation,
      setModal,
      refetchQuery,
      setFullPageLoader,
    ],
  );

  const displayDeactivateModal = React.useMemo(() => {
    if (!hasUserPermission(typeConfig.deactivateMutation.requiredPermissions)) {
      return null;
    }

    return (asset: ApplicationAsset) => {
      setModal({
        type: "set",
        props: {
          title: "Remove Asset From Test",
          content:
            "Are you sure you want to remove this asset from current test?",
          onCancel: () => {},
          onConfirm: deactivateAsset(asset),
        },
      });
    };
  }, [
    typeConfig.deactivateMutation,
    hasUserPermission,
    setModal,
    deactivateAsset,
  ]);

  const onControl = async (asset: ApplicationAsset) => {
    await runAction(
      applicationId,
      asset,
      controlMutation,
      setModal,
      refetchQuery,
      setFullPageLoader,
    );
  };

  const onSetTop = React.useMemo(() => {
    if (
      !hasUserPermission(updateReferenceVariationMutation.requiredPermissions)
    ) {
      return null;
    }

    return async (asset: ApplicationAsset) => {
      await updateReferenceVariation(
        applicationId,
        asset,
        typeConfig.type,
        updateReferenceMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    };
  }, [
    refetchQuery,
    setFullPageLoader,
    setModal,
    updateReferenceMutation,
    applicationId,
    hasUserPermission,
    typeConfig.type,
  ]);

  if (loading || !data) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <ApplicationElementsContainer
      applicationId={applicationId}
      history={history}
      uploadRoute={authRoutes.APPLICATION_CREATE_TEXTS.getUrlWithParams({
        id: applicationId,
        type,
      })}
      uploadButtonContent={typeConfig.uploadButtonContent}
      permissionsForUpload={typeConfig.permissionsForUpload}
    >
      {fullPageLoader}
      <Page
        applicationId={applicationId}
        currentPhase={data.currentPhase}
        assetType={typeConfig.type}
        title={typeConfig.title}
        canAbort={canAbort}
        phase={typeConfig.phase}
        assets={typeConfig.getAssets(data) || []}
        onControl={onControl}
        onRemove={displayRemoveModal}
        onDeactivate={displayDeactivateModal}
        onSetTop={onSetTop}
        history={history}
        trafficIndicator={trafficIndicator}
        currentIteration={data.currentIteration}
      />
    </ApplicationElementsContainer>
  );
}

export default ApplicationElementsTextsContainer;
