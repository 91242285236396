import { gql } from "@apollo/client";

export const CREATE_APPLICATION = gql`
  mutation createApplication(
    $name: String!
    $platform: String!
    $redirectUrl: String!
    $iconFiles: [Upload]
    $iconsInfo: [ControlObjectType]
    $promoArtFiles: [Upload]
    $promoArtsInfo: [ControlObjectType]
    $previewFiles: [Upload]
    $previewsInfo: [PreviewInputType]
    $setsFiles: [Upload]
    $setsInfo: [SetsInputType]
    $titles: [TextInputType]
    $descriptions: [TextInputType]
    $subtitles: [TextInputType]
    $publisher: String
    $rating: Decimal
    $downloads: String
    $tags: [String]
    $whatsNew: String
    $numberOfRatings: Int
    $ageLimit: String
    $appVersion: String!
    $releaseDate: String!
    $releaseNote: String!
    $category: Int
    $ranking: Int
    $size: Float!
    $gameplayRating: Decimal
    $controlRating: Decimal
    $graphicRating: Decimal
    $copyright: String
    $developerPage: String
    $developerEmail: String
    $developerAddress1: String
    $developerAddress2: String
    $developerCity: String
    $developerState: String
    $developerPostalCode: String
    $developerCountry: String
    $privacyPolicyUrl: String
    $reviews: [ReviewInputType]
    $featureGraphicsInfo: [ControlObjectType]
    $featureGraphicFiles: [Upload]
    $minimumDetectableLift: Float!
  ) {
    createApplication(
      name: $name
      platform: $platform
      redirectUrl: $redirectUrl
      iconFiles: $iconFiles
      iconsInfo: $iconsInfo
      promoArtFiles: $promoArtFiles
      promoArtsInfo: $promoArtsInfo
      previewFiles: $previewFiles
      previewsInfo: $previewsInfo
      setsFiles: $setsFiles
      setsInfo: $setsInfo
      titles: $titles
      descriptions: $descriptions
      subtitles: $subtitles
      publisher: $publisher
      rating: $rating
      downloads: $downloads
      tags: $tags
      whatsNew: $whatsNew
      numberOfRatings: $numberOfRatings
      ageLimit: $ageLimit
      appVersion: $appVersion
      releaseDate: $releaseDate
      releaseNote: $releaseNote
      category: $category
      ranking: $ranking
      size: $size
      gameplayRating: $gameplayRating
      controlRating: $controlRating
      graphicRating: $graphicRating
      copyright: $copyright
      developerPage: $developerPage
      developerEmail: $developerEmail
      developerAddress1: $developerAddress1
      developerAddress2: $developerAddress2
      developerCity: $developerCity
      developerState: $developerState
      developerPostalCode: $developerPostalCode
      developerCountry: $developerCountry
      privacyPolicyUrl: $privacyPolicyUrl
      reviews: $reviews
      featureGraphicsInfo: $featureGraphicsInfo
      featureGraphicFiles: $featureGraphicFiles
      minimumDetectableLift: $minimumDetectableLift
    ) {
      application {
        id
      }
    }
  }
`;

export const CREATE_APPPLATFORM = gql`
  mutation createAppPlatform(
    $clientCode: String!
    $appPlatform: CreateAppPlatform!
  ) {
    createAppPlatform(clientCode: $clientCode, appPlatform: $appPlatform) {
      code
      asoAppId
    }
  }
`;

export const DUPLICATE_APPLICATION = gql`
  mutation duplicateApplication(
    $clientCode: String!
    $sourceAppPlatformCode: String!
  ) {
    duplicateAppPlatform(
      clientCode: $clientCode
      sourceAppPlatformCode: $sourceAppPlatformCode
    ) {
      asoAppId
    }
  }
`;
