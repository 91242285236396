import { gql } from "@apollo/client";

export const GET_APPLICATION_PIXEL = gql`
  query getApplicationPixel($applicationId: ID!) {
    facebookPixel(applicationId: $applicationId) {
      eventType
      pixelId
      enabled
      domainTagContent
    }
  }
`;
