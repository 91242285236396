import * as React from "react";
import { RouteComponentProps } from "react-router";

import { createTitles } from "v2/api/mutations/application/titles";
import { createSubtitles } from "v2/api/mutations/application/subtitles";
import { createDescriptions } from "v2/api/mutations/application/descriptions";

import { ReferenceVariationType } from "api/models";
import { TextAssetData } from "components/application/forms/TextAssetStep/constants/textAssetData";
import ApplicationUpdateContainer from "../ApplicationUpdateContainer";
import ApplicationUpdateTextsPage from "./ApplicationUpdateTextsPage";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

interface Props extends RouteComponentProps<{ id: string; type: string }> {}

function transformData(data: string[]) {
  return data.map((d) => ({
    isControl: false,
    name: d,
  }));
}

function ApplicationUpdateTextsContainer(props: Props) {
  const [fullPageLoader, setIsLoading] = useFullPageLoader();

  const type = props.match.params.type;
  let mutation = createSubtitles.definition;
  if (type === "descriptions") {
    mutation = createDescriptions.definition;
  } else if (type === "titles") {
    mutation = createTitles.definition;
  }

  const handleOnSubmit = (onSubmitFunc: any) => async (
    formData: TextAssetData,
  ) => {
    setIsLoading(true);
    await onSubmitFunc({
      texts: transformData(formData.values),
      referenceVariationType:
        formData.referenceVariationType || ReferenceVariationType.top,
    });
    setIsLoading(false);
  };

  return (
    <>
      {fullPageLoader}
      <ApplicationUpdateContainer mutation={mutation} {...props}>
        {(application: any, onDiscard: () => void, onSubmit: () => void) => (
          <ApplicationUpdateTextsPage
            type={type}
            onDiscard={onDiscard}
            onSubmit={handleOnSubmit(onSubmit)}
            platform={application.platform}
          />
        )}
      </ApplicationUpdateContainer>
    </>
  );
}

export default ApplicationUpdateTextsContainer;
