// -- CONST
import { Route } from "v2/api/classes/route";
import { authRoutes } from "./routesGroups/authRoutes";
import { failedPaymentsRoutes } from "./routesGroups/failedPaymentsRoutes";
import { nonAuthRoutes } from "./routesGroups/nonAuthRoutes";
import { userWithoutCardRoutes } from "./routesGroups/userWithoutCardRoutes";

export const getRoutes = (
  token: boolean,
  validPaymentMethod: boolean,
  isOverdue: boolean,
): Route<any>[] => {
  let routes: Record<string, Route<any>> = authRoutes;

  if (!token) {
    routes = nonAuthRoutes;
  } else if (!validPaymentMethod) {
    routes = userWithoutCardRoutes;
  } else if (isOverdue) {
    routes = failedPaymentsRoutes;
  }

  return Object.values(routes);
};
