import * as React from "react";

import { ExperimentPhase, CurrentIteration } from "api/models";

import { phaseTypeToLink } from "utils/enums";

export function getCurrentlyTestedSectionMessage(
  isCurrentPhase: boolean,
  history: any,
  applicationId: string,
  currentPhase?: ExperimentPhase | null,
  currentIteration?: CurrentIteration,
): undefined | React.ReactNode {
  const isSetTested = !!(currentIteration && currentIteration.testingSets);

  const basicMessage =
    "There is no asset currently being tested for this element.";
  // for case when all phases ended
  if (!!currentPhase && !!currentPhase.finishedAt) {
    return basicMessage;
  }

  // for case when other phase is active
  if (!isCurrentPhase && currentPhase) {
    return (
      <>
        {`${basicMessage} `}
        {phaseTypeToLink(
          history,
          currentPhase.type,
          applicationId,
          isSetTested,
        )}{" "}
        assets are currently being tested.
      </>
    );
  }

  return undefined;
}
