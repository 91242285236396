import React from "react";
import { Box } from "@rebass/grid";

import {
  AssetType,
  ExperimentPhase,
  ExperimentPhaseType,
  Title,
} from "api/models";
import { ElementsProps } from "../constants/elementsProps";

import { HiddenFlex } from "../components/uiElement";
import { ApplicationTextAssets } from "../components/ApplicationTextAssets";

interface Props extends ElementsProps {
  applicationId: string;
  assetType: AssetType;
  title: string;
  canAbort: boolean;
  assets: Title[];
  phase: ExperimentPhase;
  currentPhase?: ExperimentPhase | null;
  history: any;
  trafficIndicator?: string;
}

function ApplicationElementsPage({
  applicationId,
  history,
  currentPhase = null,
  assetType,
  title,
  canAbort,
  assets,
  onControl,
  onRemove,
  onDeactivate,
  onSetTop,
  trafficIndicator,
  currentIteration,
}: Props) {
  let phaseType = ExperimentPhaseType.subtitle;
  if (assetType === AssetType.title) {
    phaseType = ExperimentPhaseType.title;
  }
  if (assetType === AssetType.description) {
    phaseType = ExperimentPhaseType.description;
  }

  return (
    <HiddenFlex flexDirection="column">
      <Box>
        <ApplicationTextAssets
          applicationId={applicationId}
          assetType={assetType}
          title={title}
          assets={assets}
          onControl={onControl}
          onRemove={onRemove}
          onDeactivate={onDeactivate}
          canAbort={canAbort}
          onSetTop={onSetTop}
          currentPhase={currentPhase}
          phaseType={phaseType}
          history={history}
          trafficIndicator={trafficIndicator}
          currentIteration={currentIteration}
        />
      </Box>
    </HiddenFlex>
  );
}

export default ApplicationElementsPage;
