import gql from "graphql-tag";

export const CONTROL_VARIATION_PREVIEWS_FRAG = gql`
  fragment CONTROL_VARIATION_PREVIEWS_FRAG on ControlVariationType {
    previews {
      id
      url
      isVideo
      position
      isControlSet
      fileName
      previewLink
    }
  }
`;

export const CURRENT_ITERATION_FRAG = gql`
  fragment CURRENT_ITERATION_FRAG on IterationType {
    id
    startedAt
    finishedAt
    testingSets
  }
`;

export const CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG = gql`
  fragment CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG on ControlVariationType {
    additionalPreviews {
      id
      url
      isVideo
      position
      isSet
      fileName
    }
  }
`;
