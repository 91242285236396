import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import ListGroupItem from "react-bootstrap/ListGroupItem";

import { default as Icon, Icons } from "./Icon";
import DividerVertical from "./DividerVertical";
import themes from "components/themes";
import FormError from "components/forms/FormError";

interface Props {
  counter?: number | null;
  showCounter?: boolean;
  children: React.ReactNode;
  onRemove?: (() => void) | null;
  active?: boolean;
  activateText?: string | React.ReactNode;
  onActivate?: (() => void) | null;
  onEdit?: (() => void) | null;
  invalidMsg?: string;
}

function ListItem({
  children,
  counter = null,
  showCounter = true,
  active = false,
  activateText = "",
  onRemove = null,
  onActivate = null,
  onEdit = null,
  invalidMsg = "",
}: Props) {
  const editable = !!onActivate || !!onRemove || !!onEdit;
  const [isHovered, setHovered] = useState(false);

  return (
    <Wrapper
      counter={counter}
      current={active ? 1 : 0}
      editable={editable ? 1 : 0}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      invalid={invalidMsg}
    >
      <Flex alignItems="center">
        <Flex width={editable ? 6 / 9 : 1}>
          {showCounter && <Counter>{counter}.</Counter>}
          <Text active={active}>{children}</Text>
        </Flex>
        {editable && isHovered && (
          <MetaBox width={3 / 9}>
            <Actions>
              {!active && onActivate && (
                <MarkAsActive onClick={onActivate}>{activateText}</MarkAsActive>
              )}
              {active && "Control"}
              {onRemove && (
                <>
                  {!!onActivate && <StyledDividerVertical />}
                  <ActionIcon onClick={onRemove}>
                    <Icon icon={Icons.faTrash} />
                  </ActionIcon>
                </>
              )}
              {onEdit && (
                <>
                  <StyledDividerVertical />
                  <ActionIcon onClick={onEdit}>
                    <Icon icon={Icons.faPen} />
                  </ActionIcon>
                </>
              )}
            </Actions>
          </MetaBox>
        )}
        {(!editable || !isHovered) && active && (
          <MetaBox width={3 / 9}>
            <Control>Control</Control>
          </MetaBox>
        )}
      </Flex>
      {invalidMsg && <FormError>{invalidMsg}</FormError>}
    </Wrapper>
  );
}

interface WrapperProps {
  current: boolean;
  editable: boolean;
  counter?: number;
  invalid?: boolean;
}

const Wrapper = styled(ListGroupItem)<WrapperProps>`
  ${(props) =>
    props.current && props.editable && !props.invalid
      ? `border: 1px solid ${themes.colors.purple};`
      : props.invalid &&
        `box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow}; border: 1px solid ${themes.colors.notificationRed};`};

  margin-bottom: 5px !important;
  border-radius: 8px !important;
  background-color: ${themes.colors.card} !important;
  color: white;
  overflow-y: auto;
  max-height: 160px;

  ${(props) =>
    props.editable &&
    `
      :hover {
        background: #1a1a32 !important;
    }
  `};

  ${(props) =>
    !props.editable &&
    props.counter &&
    props.counter > 1 &&
    `
    border-top: 1px solid #47475b;
  `};
`;

const Text = styled.span<{ active: boolean }>`
  background: inherit;
  padding-right: 40px;
  word-break: break-all;
  white-space: pre-wrap;
`;

const Counter = styled.span`
  background: inherit;
  opacity: 0.4;
  width: 24px;
  font-size: 16px;
  margin-right: 15px;
  text-decoration: none;
  color: #bebec5;
`;

const MetaBox = styled(Box)`
  align-self: flex-start;
  margin-top: 5px;
`;

const Actions = styled.span`
  background: inherit;
  float: right;
  display: inline-flex;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-align: right;
`;

const ActionIcon = styled.a`
  background: inherit;
  display: block;
  cursor: pointer;
  :hover {
    text-decoration: underline !important;
  }
`;

const MarkAsActive = styled.a`
  background: inherit;
  cursor: pointer;
  display: block;
  text-decoration: underline !important;
  border-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
  :hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
  }
`;

const StyledDividerVertical = styled(DividerVertical as any)`
  margin: 0 20px;
  width: 1px;
  height: 20px;
  opacity: 0.5;
  border-radius: 2px;
  background-color: #a7a7b0;
`;

const Control = styled.div`
  color: ${themes.colors.white};
  font-size: 12px;
  text-align: right;
`;

export default ListItem;
