import React from "react";
import { RouteComponentProps } from "react-router";
import { useQuery } from "@apollo/client";

import { GET_APPLICATION_CONTROL } from "api/queries";
import ApplicationControlPage from "./ApplicationControlPage";
import { Spinner, Error } from "components";
import { conversionRateLift } from "utils/statistics";
import { valueOrNA } from "utils/valueOrNA";
import ApplicationDetail from "screens/Application/ApplicationDetail/ApplicationDetailContainer";

interface Props extends RouteComponentProps<{ id: string }> {}

function ApplicationControlContainer({ history, match }: Props) {
  const { data, error, loading, refetch } = useQuery(GET_APPLICATION_CONTROL, {
    variables: { applicationId: match.params.id },
  });

  if (loading || !data.controlVariation) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <ApplicationDetail
      applicationId={data.application.id}
      history={history}
      customPreviewLabel="Preview Control"
      customPreviewUrl={data.controlVariation.url}
    >
      <ApplicationControlPage
        history={history}
        application={data.application}
        controlVariation={data.controlVariation}
        applicationControlAssets={data.applicationControlAssets}
        topVariation={data.topVariation}
        topPageConversionRate={valueOrNA(data.topVariation?.conversionRate)}
        topPageConversionLift={
          data.topVariation
            ? conversionRateLift(
                data.controlVariation.conversionRate,
                data.topVariation.conversionRate,
              )
            : valueOrNA(null)
        }
        zipFileUrl={data.topVariationZipFile}
        hasTopPage={data.application.hasTopPage}
        platform={data.application.platform}
        refetchData={refetch}
      />
    </ApplicationDetail>
  );
}

export default ApplicationControlContainer;
