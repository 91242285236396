import React, { FC, ReactElement } from "react";
import styled from "styled-components";
import { Box, Flex } from "@rebass/grid";

import { Icon, Icons, Tooltip } from "components";

import themes from "./themes";

// -- TYPES
interface Props {
  value: string;
  tooltipContent: React.ReactNode;
  onIconClick?: (event: any) => void;
}

// -- COMPONENT
export const TextWithTooltip: FC<Props> = ({
  value,
  tooltipContent,
  onIconClick,
}): ReactElement => (
  <Flex alignItems="center">
    <Box>{value}</Box>
    <IconBox onClick={onIconClick}>
      <Tooltip content={tooltipContent}>
        <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
      </Tooltip>
    </IconBox>
  </Flex>
);

// -- STYLED
const IconBox = styled(Box)`
  padding: 0 10px;
  cursor: pointer;
`;
