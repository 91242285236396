import React, { useReducer, ReactNode, FC, useEffect, useContext } from "react";
import { isFunction } from "lodash";

import ConfirmationModal from "./ConfirmationModal";

import { Modal, ModalReducerActionType } from "api/models";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";

import { ModalContext } from "contexts";
import { errorMessageHandler } from "utils/errorHandlers/errorMessageHandler";
import { ApolloError } from "@apollo/client";

// -- TYPES
interface ModalWrapperProps {
  children: ReactNode;
}

interface ActionWithProps {
  type: ModalReducerActionType;
  props?: Modal | { [propId: string]: any };
}

const initialData: Modal = {
  title: "Error",
  content: "Something went wrong",
  confirmText: "Ok",
  onConfirm: undefined,
  onCancel: undefined,
  show: false,
  onHide: undefined,
};

const modalReducer = (
  state: Modal,
  actionWithProps: ActionWithProps,
): Modal => {
  const { type, props = {} } = actionWithProps;

  switch (type) {
    case "set": {
      return {
        ...initialData,
        show: true,
        ...props,
      };
    }
    case "update": {
      return { ...state, ...props };
    }
    case "reset": {
      return {
        ...state,
        show: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

// -- COMPONENT
const ModalWrapper: FC<ModalWrapperProps> = ({ children }) => {
  const [modalProps, setModalProps] = useReducer(modalReducer, initialData);
  const platformStore = useContext<PlatformStore>(PlatformContext);

  const onHide = () => {
    isFunction(modalProps.onHide) && modalProps.onHide();
    setModalProps({ type: "reset" });
  };

  useEffect(() => {
    const listener = (event: any) => {
      // skip all errors till swith client is done
      if (
        event.detail?.operation?.operationName !== "switchClient" &&
        platformStore?.processingSwitchClient
      )
        return;

      if (
        event.detail?.networkError &&
        event.detail?.operation?.getContext()?.handleError !== false
      ) {
        if (event.detail?.operation?.getContext()?.handleNetworkError) return;
        const errorMessage = errorMessageHandler({} as ApolloError);
        setModalProps({
          type: "set",
          props: {
            content: errorMessage,
          },
        });
      }
      if (event.detail?.operation?.getContext()?.handleError) {
        const errorMessage = errorMessageHandler(
          {
            graphQLErrors: event.detail.graphQLErrors,
          } as ApolloError,
          { request: { variables: event.detail.operation.variables } },
        );
        setModalProps({
          type: "set",
          props: {
            content: errorMessage,
          },
        });
      }
    };

    const openModalEvent = ({ detail }: any) => {
      setModalProps({
        type: "set",
        props: detail,
      });
    };

    document.addEventListener("apolloGraphQlError", listener);
    document.addEventListener("apolloNetworkError", listener);
    document.addEventListener("openModal", openModalEvent);
    return () => {
      document.removeEventListener("apolloGraphQlError", listener);
      document.removeEventListener("apolloNetworkError", listener);
      document.removeEventListener("openModal", openModalEvent);
    };
  });

  return (
    <ModalContext.Provider value={setModalProps}>
      {children}
      <ConfirmationModal
        key="confirmation-modal"
        {...modalProps}
        onHide={onHide}
      />
    </ModalContext.Provider>
  );
};

export default ModalWrapper;
