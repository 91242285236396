import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router";
import { SetUserPasswordMutation } from "v2/api/mutations/user/password";
import { ValidateUserToken } from "v2/api/queries/membership/validateMembershipActivationToken";
import { setCookie } from "react-use-cookie";

import config from "config";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Error, Spinner } from "components";
import UpdateForgottenPasswordPage, {
  FormData,
} from "screens/Auth/UpdateForgottenPassword/UpdateForgottenPasswordPage";
import ActivateAccountErrorPage from "screens/Auth/SignUpActivate/ActivateAccountErrorPage";
import { useSignIn } from "../../../hooks/useSignIn";
import OtpPage from "screens/Auth/SignIn/OtpPage";

interface Props
  extends RouteComponentProps<{
    token: string;
    isNewUser?: string;
  }> {}

function SignUpSetPasswordContainer({ match }: Props) {
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const [otpInitalValues, setOtpInitalValues] = useState(false);
  const variables = {
    token: match.params.token,
    title: "Set password",
    submitButtonText: "Submit",
    isNewUser: !!match.params.isNewUser,
  };
  const [updatePassword] = useMutation(SetUserPasswordMutation);
  const [activationError, setActivationError] = useState(false);

  const signIn = useSignIn({
    // tslint:disable-next-line:no-shadowed-variable
    onOtpRequired: (data) => {
      setOtpInitalValues(data);
      setShowOtpDialog(true);
    },
  });

  const handleError = useRequestErrorHandler();

  const { data, error, loading } = useQuery(ValidateUserToken, {
    variables: { token: variables.token },
  });

  const setPassword = async (password: string) => {
    const updatePasswordResponse = await handleError(
      updatePassword({
        variables: {
          token: variables.token,
          password,
        },
      }),
    );

    if (!updatePasswordResponse) {
      setActivationError(true);
      return;
    }
    return updatePasswordResponse.data?.setUserPassword;
  };

  const handleSubmit = async (formData: FormData) => {
    const setPasswordResponse = await setPassword(formData.password);

    if (setPasswordResponse.emailAddress) {
      setCookie(config.lastLoginClientCookieName, "", { days: -1 });
      await signIn({
        email: setPasswordResponse?.emailAddress,
        password: formData.password,
      });
    }
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  if (!data || data.validateUserToken !== "valid" || activationError) {
    return <ActivateAccountErrorPage />;
  }

  if (showOtpDialog) {
    return <OtpPage onSubmit={signIn} initialValues={otpInitalValues} />;
  }

  return (
    <UpdateForgottenPasswordPage
      onSubmit={handleSubmit}
      title={variables.title}
      submitButtonText={variables.submitButtonText}
    />
  );
}

export default SignUpSetPasswordContainer;
