import * as React from "react";
import styled from "styled-components";
import ReactSwitch from "react-switch";

import themes from "components/themes";

// -- TYPES
type SwitchProps = {
  value?: boolean;
  onChange: (value: boolean) => any;
  disabled?: boolean;
  labelOn?: string;
  labelOff?: string;
  handleDiameter?: number;
  width?: number;
  height?: number;
  displayLabels?: boolean;
};

// -- COMPONENT
export function Switch({
  value = false,
  onChange,
  disabled = false,
  labelOn = "Yes",
  labelOff = "No",
  displayLabels = false,
  handleDiameter = 22,
  width,
  height,
}: SwitchProps) {
  const checkedIcon =
    labelOn && displayLabels ? (
      <LabelWrapper ml>{labelOn}</LabelWrapper>
    ) : (
      false
    );
  const uncheckedIcon =
    labelOff && displayLabels ? <LabelWrapper>{labelOff}</LabelWrapper> : false;

  return (
    <ReactSwitch
      onChange={onChange}
      checked={value}
      disabled={disabled}
      onColor={themes.colors.purple}
      offColor="#5f5f6f"
      handleDiameter={handleDiameter}
      checkedIcon={checkedIcon}
      uncheckedIcon={uncheckedIcon}
      width={width}
      height={height}
    />
  );
}

// -- STYLED
const LabelWrapper = styled.div<{ ml?: boolean }>`
  color: ${themes.colors.white};

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  font-size: 12px;
  font-weight: bold;

  ${(props: any) => props.ml && `margin-left: 3px;`};
`;
