import React, { useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";

import {
  getUserDetailsByEmailQuery,
  UserDetailsByEmailQueryResponse,
} from "v2/api/queries/user/getUserDetailsByEmail";

import { ModalContext, ApplicationUserContext } from "contexts";

import { UserMenuItem } from "./UserMenuItem";
import { TextInput } from "components/forms";

// -- COMPONENT
export function ChangeUserMenuItem(): JSX.Element {
  const [selectedEmail, setSelectedEmail] = useState<null | string>(null);
  const setModal = useContext(ModalContext);
  const { setCurrent: setCurrentApplicationUser } = useContext(
    ApplicationUserContext,
  ); // TODO: Tego kontekstu można się pozbyć i wrzucić to w user stora

  const [requestForUserDetails, { data, error }] = useLazyQuery<
    UserDetailsByEmailQueryResponse
  >(getUserDetailsByEmailQuery, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    selectedEmail &&
      requestForUserDetails({
        variables: {
          email: selectedEmail,
        },
      });
  }, [selectedEmail, requestForUserDetails]);

  useEffect(() => {
    if (data?.userByEmail) {
      const { id, username } = data.userByEmail;
      setCurrentApplicationUser(id, username);
    }

    if (error) {
      setModal({
        type: "set",
        props: {
          content: error.message,
        },
      });
    }
  }, [data, error, requestForUserDetails, setModal, setCurrentApplicationUser]);

  const handleModalConfirm = (email?: string) => () =>
    setSelectedEmail(email || "");

  const handleSelectUser = (e: any) => {
    setModal({
      type: "update",
      props: {
        onConfirm: handleModalConfirm(e.target.value),
      },
    });
  };

  const handleMenuOnClick = () => {
    const userListContent = (
      <TextInput placeholder="Enter user email" onChange={handleSelectUser} />
    );
    setModal({
      type: "set",
      props: {
        title: "Change user which you want to be logged in",
        content: userListContent,
        onCancel: () => {},
        onConfirm: handleModalConfirm(),
      },
    });
  };

  return <UserMenuItem onClick={handleMenuOnClick}>View as user</UserMenuItem>;
}
