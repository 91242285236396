import { gql } from "@apollo/client";

import {
  Application,
  CurrentIteration,
  ExperimentPhase,
  FeatureGraphic,
} from "api/models";

export const getApplicationFeatureGraphic = gql`
  query getApplicationFeatureGraphic($applicationId: ID!) {
    featureGraphics(applicationId: $applicationId) {
      id
      fileName
      isControl
      file {
        url
        name
      }
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
    }
  }
`;

export interface GetApplicationFeatureGraphicResponse {
  featureGraphics: FeatureGraphic[];
}

export const getFeatureGraphicElementsPageData = gql`
  query getFeatureGraphicElementsPageData($applicationId: ID!) {
    featureGraphics(applicationId: $applicationId) {
      id
      fileName
      isControl
      file {
        url
        name
      }
      conversionRate
      conversion
      confidence
      visits
      status
      isTop
      previewLink
      historicalConversionData {
        startDate
        endDate
        visits
        installs
        cvr
        action
      }
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
      startedAt
      finishedAt
    }
    application(id: $applicationId) {
      id
      baselineConversionRate
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      id
      startedAt
      finishedAt
      testingSets
    }
  }
`;

export interface GetFeatureGraphicElementsPageDataResponse {
  featureGraphics: FeatureGraphic[];
  phaseTraffic: number;
  trafficNeeded: string;
  currentPhase: ExperimentPhase | null;
  currentIteration: CurrentIteration;
  application: Application;
}
