import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import { observer } from "mobx-react";
import { useLocation } from "react-use";

import { ApplicationContext } from "contexts";
import { useStores } from "contexts/storeContext";

import { TopBar } from "components";
import { SideMenu } from "components/menu/SideMenu";
import themes from "components/themes";

type DashboardTemplateProps = {
  displaySideMenu?: boolean;
  showFocusMode?: boolean;
  children: React.ReactNode;
  asIframe?: boolean;
};

function DashboardTemplateComponent({
  displaySideMenu = true,
  showFocusMode = false,
  children,
}: DashboardTemplateProps) {
  const applicationCtx = useContext(ApplicationContext);
  const location = useLocation();

  const { layoutStore } = useStores();

  const changMenuCallapsed = useCallback(() => {
    layoutStore.setMenuCallapsedStatus(!layoutStore.isMenuCallapsed);
  }, [layoutStore]);

  return (
    <>
      <TopBar
        showFocusMode={showFocusMode}
        collapsed={layoutStore.isMenuCallapsed}
        onMenuToggle={changMenuCallapsed}
      />

      <FullFlex flex={1}>
        {displaySideMenu && !showFocusMode && (
          <Box>
            <SideMenu
              application={applicationCtx.current}
              collapsed={layoutStore.isMenuCallapsed}
              onMenuToggle={changMenuCallapsed}
            />
          </Box>
        )}
        {location.pathname?.includes("/ua/") ? (
          <Box flex={1}>{children}</Box>
        ) : (
          <ContentBox flex={1}>{children}</ContentBox>
        )}
      </FullFlex>
    </>
  );
}

const FullFlex = styled(Flex)`
  min-height: calc(100% - 60px); /* substract header height */
`;

const ContentBox = styled(Box)`
  overflow-x: hidden;

  background-color: ${themes.colors.backgroundDark} !important;
`;

export const DashboardTemplate = observer(DashboardTemplateComponent);
