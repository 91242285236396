// -- CORE
import React from "react";
import { Redirect } from "react-router-dom";
// -- CONST
import { MenuMode } from "v2/api/types/menuMode";
import { Route } from "v2/api/classes/route";
// -- PAGES
const FirstBillingPage = React.lazy(() =>
  import("screens/Billing/FirstBilling/FirstBillingPage"),
);

// -- ROUTES DEFINITION
export const userWithoutCardRoutes = {
  USER_FIRST_BILLING: new Route<never>({
    path: "/add-payments",
    component: FirstBillingPage,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  PAGE_NOT_FOUND: new Route<never>({
    render: () => <Redirect to={"/add-payments"} />,
    menuMode: MenuMode.NONE,
  }),
};
