import React, { useState } from "react";
import { Form } from "react-final-form";
import { useQuery } from "@apollo/client";

import {
  getApplicationFeatureGraphic,
  GetApplicationFeatureGraphicResponse,
} from "api/queries/featureGraphic";

import FeatureGraphic from "components/application/forms/FeatureGraphic/FeatureGraphic";
import { FormFooter } from "components/forms";
import { Spinner, Error } from "components";
import { MessageBox } from "components/application";
import { FeatureGraphicData } from "components/application/forms/FeatureGraphic/constants/featureGraphicData";

type FeatureGraphicUpdateFormProps = {
  onDiscard: () => void;
  onSubmit: (data: FeatureGraphicData) => void;
  isEdit?: boolean;
  application: any;
};

export function FeatureGraphicUpdateForm({
  onDiscard,
  onSubmit,
  isEdit,
  application,
}: FeatureGraphicUpdateFormProps) {
  const defaultState: FeatureGraphicData = {
    control: null,
    assets: [],
  };

  const [formData, setFormData] = useState<FeatureGraphicData>(defaultState);

  const { data, error, loading } = useQuery<
    GetApplicationFeatureGraphicResponse
  >(getApplicationFeatureGraphic, {
    variables: { applicationId: application.id },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  const hasControlFG = (data?.featureGraphics.length || 0) > 0;

  const applicationAlreadyHasBlankFG = Boolean(
    data?.featureGraphics.find((singleFG) => !singleFG.file.url),
  );

  const handleOnChange = (stepData: any) =>
    setFormData({
      ...formData,
      ...stepData,
    });

  return (
    <Form onSubmit={() => onSubmit(formData)} initialValues={formData}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <MessageBox
            content={
              !hasControlFG
                ? "Please note that if you add a Feature Graphic asset during a test phase that has already started it will stop the tests and testing will restart with the new asset included in the control version."
                : undefined
            }
          />
          <FeatureGraphic
            title="Upload New Feature Graphics"
            data={formData}
            disableControl={hasControlFG}
            onChange={handleOnChange}
            isEdit={isEdit}
            autoControl={!hasControlFG}
            allowAddBlank={!applicationAlreadyHasBlankFG}
          />

          <FormFooter onCancel={onDiscard} disabled={submitting} />
        </form>
      )}
    </Form>
  );
}
