import * as React from "react";
import { styled } from "@mui/system";

import { userpilotReloadUrl } from "utils/userpilot";

import emptyImage from "assets/images/empty-state-single.png";

import { UploadFile } from "components/forms/UploadInput";
import { FeatureGraphicData } from "./constants/featureGraphicData";

import themes from "components/themes";

import FormHeader from "components/forms/FormHeader";
import Button from "components/forms/Button";
import Icon, { Icons } from "components/Icon";
import ApplicationAssetsUpload, {
  ChangeData,
} from "../../ApplicationAssetsUpload/ApplicationAssetsUpload";
import Text from "../components/Text";
import MessageBox from "components/MessageBox";

// -- PROPS
type FeatureGraphicProps = {
  title?: string;
  buttonText?: string;
  data: FeatureGraphicData;
  disableControl?: boolean;
  onChange: (values: object) => void;
  maxUploadAssets?: number;
  required?: boolean;
  allowAddBlank?: boolean;
  isEdit?: boolean;
  isUpdateControl?: boolean;
  autoControl?: boolean;
};

// -- COMPONENT
function FeatureGraphicComponent({
  title,
  data,
  buttonText = "Upload Feature Graphics",
  disableControl,
  onChange,
  maxUploadAssets,
  required = false,
  allowAddBlank = true,
  isEdit = false,
  isUpdateControl = false,
  autoControl,
}: FeatureGraphicProps): JSX.Element {
  const [displayAddBlank, setDisplayAddBlank] = React.useState<boolean>(true);

  React.useEffect(() => {
    userpilotReloadUrl(`${window.location.href}/feature-graphic`);
  }, []);

  const handleAssetsChange = React.useCallback(
    (changes: ChangeData) => {
      const { assets, control } = changes;

      const isBlankFGInAssets = assets.find((singleAsset) => !singleAsset.file);

      !isBlankFGInAssets !== displayAddBlank &&
        setDisplayAddBlank(!isBlankFGInAssets);

      onChange({
        assets,
        control: disableControl ? null : control,
      });
    },
    [onChange, disableControl, displayAddBlank, setDisplayAddBlank],
  );

  const onControl = React.useCallback(
    (asset: any) => {
      onChange({
        control: asset,
      });
    },
    [onChange],
  );

  const addBlankFG = () => {
    const emptyFile: UploadFile = {
      preview: emptyImage,
      file: null,
      fileName: "No Feature Graphic",
    };

    setDisplayAddBlank(false);
    onChange({
      control: disableControl ? data.control : emptyFile,
      assets: [emptyFile, ...(data.assets || [])],
    });
  };

  const addNoFeatureGraphicButton = (
    <AddFGButton
      onClick={displayAddBlank ? addBlankFG : null}
      disabled={!displayAddBlank}
    >
      <Icon icon={Icons.faPlusCircle} color={themes.colors.purple} />
      Test "No Feature Graphic"
    </AddFGButton>
  );

  return (
    <>
      <OptionalBox visible={!isEdit && !isUpdateControl} closeable={false}>
        Feature Graphic is not required to run any experiments. You can add
        later if you don’t add any now. You can also simply add one as part of
        the Control or add multiple if you want to test multiple Feature Graphic
        creatives.
      </OptionalBox>

      <FormHeader style={{ marginTop: 30 }}>
        {title || "Upload Feature Graphic and Select Control Version"}
      </FormHeader>
      <ApplicationAssetsUpload
        assets={data.assets}
        controlAsset={data.control}
        buttonText={buttonText}
        emptyTitle={buttonText}
        onChange={handleAssetsChange}
        onControl={disableControl ? null : onControl}
        maxUploadAssets={maxUploadAssets}
        required={required}
        additionalButtons={allowAddBlank ? addNoFeatureGraphicButton : null}
        emptyDescription={`Use the button above to ${buttonText.toLowerCase()} from your computer`}
        emptyDescriptionFooter={
          <Text label="Required Dimensions: " value="Min: 320px. Max: 3840px" />
        }
        emptyHintFooter={
          <HintFooter>
            <HintHeader>Image Requirements</HintHeader>
            <div>Required File Extension: JPEG or 24-bit PNG (no alpha)</div>
          </HintFooter>
        }
        autoControl={autoControl}
      />
    </>
  );
}

const FeatureGraphic = React.memo(FeatureGraphicComponent);
export default FeatureGraphic;

// -- STYLE
const AddFGButton = styled(Button)<{ disabled?: boolean }>`
  padding: 0 15px;

  height: 44px;

  border-radius: 8px;
  border: 1px dashed ${(props: any) => props.theme.colors.borderGrey};

  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};

  cursor: ${(props: any) =>
    props.disabled ? "not-allowed !important" : "pointer"};

  background-color: transparent;

  :hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
  }

  :focus {
    outline: 0;
  }

  svg {
    margin: 0 10px 0 0;

    font-size: 18px;
  }
`;

const HintHeader = styled("a")`
  font-weight: 600;
  color: inherit;
  opacity: 1;
`;

const HintFooter = styled("div")`
  opacity: 0.8;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #9d9da8;
`;

const OptionalBox = styled(MessageBox)`
  margin: 20px 0 60px;
`;
