import * as React from "react";
import styled from "styled-components";

import { GroupedTextsSection } from "../constants/sections";

import UISection from "components/Section";
import { TextSection } from "./TextSection";

export function GroupedTextsSections({ groups }: GroupedTextsSection) {
  return (
    <UISection transparentHeader style={{ marginTop: "77px" }}>
      <Wrapper>
        {groups.map((singleImageSection) => (
          <TextSection key={singleImageSection.title} {...singleImageSection} />
        ))}
      </Wrapper>
    </UISection>
  );
}

// -- UI
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
