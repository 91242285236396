import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const refreshApplicationBCR = new ApiMutation({
  definition: gql`
    mutation refreshApplicationBcr($applicationId: ID!) {
      refreshApplicationBcr(applicationId: $applicationId) {
        isCalculatingBcr
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateASOTest],
});

export interface RefresApplicationBCRResponse {
  refreshApplicationBcr: {
    isCalculatingBcr: boolean;
  };
}
