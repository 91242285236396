import { gql } from "@apollo/client";

export const GET_PROFILE_PERMISSIONS = gql`
  query profilePermissions {
    profilePermissions {
      validPaymentMethod
      isOverdue
    }
  }
`;

export const GET_USER_IP = gql`
  query userIp {
    userIp
  }
`;

export const CURRENT_USER_ZENDESK_WIDGET_TOKEN = gql`
  query currentUserZendeskWidgetToken {
    currentUserZendeskWidgetToken
  }
`;
