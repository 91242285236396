import React, { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { getCookie, setCookie } from "react-use-cookie";
import { ZendeskAPI } from "react-zendesk";

import config from "config";
import { LOGIN_EXTERNAL_OTP, SIGN_IN, client } from "api";
import { useRequestErrorHandler } from "./useRequestErrorHandler";

import { FormData } from "screens/Auth/SignIn/SignInPage";

import {
  getCurrentUserClients,
  GetCurrentUserClientsResponse,
} from "v2/api/queries/user/getCurrentUserClients";

import {
  switchClientMutation,
  SwitchClientMutationResponse,
} from "v2/api/mutations/user/switchClient";

import { useStores } from "contexts/storeContext";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";

function useQueryParams() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useSignIn({
  onOtpRequired = (data: any) => {},
  useExternalOtp = false,
} = {}) {
  const history = useHistory();
  const [signIn] = useMutation(SIGN_IN);
  const [loginExternalOtp] = useMutation(LOGIN_EXTERNAL_OTP);
  const handleError = useRequestErrorHandler();
  const { userStore } = useStores();
  const queryParams = useQueryParams();
  const platformStore = useContext<PlatformStore>(PlatformContext);
  const redirectUrl = queryParams.get("redirectUrl");

  const currentUserClients = async (isUppticUser: boolean) => {
    return client.query<GetCurrentUserClientsResponse>({
      query: getCurrentUserClients,
      fetchPolicy: "no-cache",
      variables: isUppticUser
        ? {
            uppticManagedOnly: true,
            filter: { "entitlement.filterGroup": { $eq: "uppticMgt" } },
          }
        : {},
    });
  };

  const switchClient = async (lastClient: string) => {
    return client.mutate<SwitchClientMutationResponse>({
      mutation: switchClientMutation,
      variables: {
        clientCode: lastClient,
      },
    });
  };

  const handleSwitchClient = async (isUppticUser: boolean) => {
    const currentUserClientsResponse = await currentUserClients(isUppticUser);
    setCookie(
      config.lastLoginClientCookieName,
      currentUserClientsResponse?.data.currentUserClients[0].code,
      { days: 365 },
    );
    await switchClient(
      currentUserClientsResponse?.data.currentUserClients[0].code,
    );
    platformStore.setCurrentClientCode(
      currentUserClientsResponse?.data.currentUserClients[0].code,
    );
  };

  return async (data: FormData) => {
    let recaptchaToken = null;

    if (!useExternalOtp) {
      await new Promise((resolve) =>
        (window as any).grecaptcha.enterprise.ready(resolve),
      );
      recaptchaToken = await (window as any).grecaptcha.enterprise.execute(
        config.recaptchaSiteKey,
        { action: "login" },
      );
    }

    let signInResponse;
    try {
      if (!useExternalOtp)
        signInResponse = await signIn({
          variables: { ...data, recaptchaToken },
        });
      if (useExternalOtp)
        signInResponse = await loginExternalOtp({ variables: data });
      if (signInResponse && redirectUrl) window.location.href = redirectUrl;
      ZendeskAPI("webWidget", "helpCenter:reauthenticate");
    } catch (err) {
      const isOTPError =
        (err as any).graphQLErrors?.[0].extensions?.code === "OTP_REQUIRED";
      if (isOTPError && onOtpRequired) return onOtpRequired(data);
      await handleError(Promise.reject(err));
      return;
    }

    const token =
      signInResponse?.data.login?.token ||
      signInResponse?.data.loginExternalOTP?.token;
    if (!token) return userStore.clearStorage();
    const { isUppticUser }: any =
      signInResponse?.data.login?.user ||
      signInResponse?.data.loginExternalOTP?.user;

    const lastClient = getCookie(config.lastLoginClientCookieName);
    platformStore.refetchCurrentModule();
    if (!lastClient) {
      await handleSwitchClient(isUppticUser);
      await userStore.loadUser(true);
      return;
    }

    try {
      await switchClient(lastClient);
    } catch (error) {
      console.log(error);
      document.cookie =
        "locationBeforeLogout=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      await handleSwitchClient(isUppticUser);
      history.replace("/");
    }
    await userStore.loadUser(true);
  };
}
