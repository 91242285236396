import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import UnStyledIframe from "react-iframe";

import frameImage from "assets/images/phone-frame.png";
import themes from "../themes";

interface Props {
  url: string;
  title: string;
}

function ApplicationPreview({ url, title }: Props) {
  return (
    <Wrapper>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Container>
          <Iframe url={url} frameBorder={0} display="block" />
          <IframeOverlay />
        </Container>
      </a>

      <Flex alignItems="center" justifyContent="center" mt={20}>
        <Box>
          <Title href={url} target="_blank">
            {title}
          </Title>
        </Box>
      </Flex>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-right: 20px;
`;

const Container = styled.div`
  width: 360px;
  height: 740px;
  padding: 80px 16px 0;
  background-image: url(${frameImage});
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;

  position: relative;
`;

const Title = styled.a`
  font-size: 16px;
  text-decoration: underline !important;
  color: ${themes.colors.white};
  cursor: pointer;

  :hover {
    color: inherit;
  }
`;

const Iframe = styled(UnStyledIframe as any)`
  width: 325px;
  height: 570px;
  position: absolute;
  border-radius: 2px;
`;

const IframeOverlay = styled.div`
  position: absolute;
  width: 318px;
  height: 570px;
  border-radius: 2px;
`;

export default ApplicationPreview;
