import React from "react";
import styled from "styled-components";

import themes from "components/themes";

export interface StatisticsCardProps {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  reverse?: boolean;
  fontSize?: number;
  active?: boolean;
}

function StatisticsCard({
  title,
  description,
  reverse = false,
  fontSize = 46,
  active = false,
}: StatisticsCardProps) {
  return (
    <CenterBox active={active}>
      {reverse ? (
        <>
          <Description>{description}</Description>
          <Title style={{ fontSize: `${fontSize}px` }}>{title}</Title>
        </>
      ) : (
        <>
          <Title style={{ fontSize: `${fontSize}px` }}>{title}</Title>
          <Description>{description}</Description>
        </>
      )}
    </CenterBox>
  );
}

const CenterBox = styled.span<{ active: boolean }>`
  width: 100%;
  min-width: 200px;
  height: 160px;
  padding: 15px;
  background-color: ${themes.colors.backgroundLight};
  border-radius: 8px;
  box-shadow: 0 20px 40px 0 rgba(2, 2, 19, 0.4);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.active &&
    `
    background: linear-gradient(242deg, ${themes.colors.chartDarkPurple}, ${themes.colors.chartLightPurple}),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.09),
        rgba(255, 255, 255, 0.09)
      );

      svg {
        circle:first-of-type {
          stroke: ${themes.colors.lightGrey};
        }
        circle:nth-of-type(2) {
          stroke: ${themes.colors.lightBlue};
        }
      }
  `}
`;

const Title = styled.div`
  width: 100%;
  font-weight: bold;
  text-align: center;
  color: ${themes.colors.white};
`;

const Description = styled.div`
  width: 100%;
  font-size: 14px;
  color: ${themes.colors.lightGrey};
  text-align: center;
`;

export default StatisticsCard;
