import React, { FC, useEffect } from "react";

import { userpilotReloadUrl } from "utils/userpilot";

import Preview1 from "./Preview1";
import Preview2 from "./Preview2";
import Preview3 from "./Preview3";
import AdditionalPreview from "./AdditionalPreview";

import { SinglePreviewData } from "components/application/forms/PreviewStep";
import { Platform } from "api/models";

// -- TYPES
interface Props {
  data: SinglePreviewData;
  onChange: (values: object) => void;
  maxUploadAssets?: number;
  platform?: Platform.ios | Platform.android;
}

// -- COMPONENT
const PreviewIdividual: FC<Props> = ({
  data,
  onChange,
  maxUploadAssets,
  platform,
}) => {
  useEffect(() => {
    userpilotReloadUrl(`${window.location.href}/screens`);
  }, []);

  return (
    <>
      <Preview1
        maxUploadAssets={maxUploadAssets}
        data={{
          control: data.control1,
          assets: data.assets1,
        }}
        onChange={(changes) => {
          onChange({
            assets1: changes.assets,
            control1: changes.control,
          });
        }}
        onControl={(asset) => {
          onChange({
            control1: asset,
          });
        }}
        platform={platform}
      />

      <Preview2
        maxUploadAssets={maxUploadAssets}
        data={{
          control: data.control2,
          assets: data.assets2,
        }}
        onChange={(changes) => {
          onChange({
            assets2: changes.assets,
            control2: changes.control,
          });
        }}
        onControl={(asset) => {
          onChange({
            control2: asset,
          });
        }}
        platform={platform}
      />

      <Preview3
        maxUploadAssets={maxUploadAssets}
        data={{
          control: data.control3,
          assets: data.assets3,
        }}
        onChange={(changes) => {
          onChange({
            assets3: changes.assets,
            control3: changes.control,
          });
        }}
        onControl={(asset) => {
          onChange({
            control3: asset,
          });
        }}
        platform={platform}
      />

      <AdditionalPreview
        maxUploadAssets={maxUploadAssets}
        data={{
          assets: data.assets4,
        }}
        onChange={(changes) => {
          onChange({
            assets4: changes.assets,
          });
        }}
      />
    </>
  );
};

export default PreviewIdividual;
