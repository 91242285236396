import * as React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import { toLocaleNumber, percentFormat } from "utils/numbers";

import iosImage from "assets/images/ios_color.svg";
import androidImage from "assets/images/android_color.svg";

import { Platform, ClientApplication, AppPlatformListing } from "api/models";
import { Tooltip } from "components/Tooltip/Tooltip";

// -- PROPS
type CvrSectionProps = {
  applicationGroup: ClientApplication;
};

// -- COMPONENT
export function CvrSection({ applicationGroup }: CvrSectionProps): JSX.Element {
  const [app1, app2] = applicationGroup.appPlatform;

  const cumulativeCvrScore = (application: AppPlatformListing) => {
    if (!application || application?.asoInfo?.redirectToStoreUrl) {
      return null;
    }

    const formatedCvr = percentFormat(
      application.asoInfo?.controlData.cvr ?? 0,
    );

    const tooltipContent = (
      <ControlDetails>
        Visits: {toLocaleNumber(application.asoInfo?.controlData.visits ?? 0)}
        <br />
        Conversions:{" "}
        {toLocaleNumber(application.asoInfo?.controlData.conversions ?? 0)}
        <br />
        CVR: {formatedCvr}
        <br />
      </ControlDetails>
    );

    return (
      <Tooltip content={tooltipContent} placement="top">
        <CvrAppIcon>
          <Icon platformType={application.platform} />
          {formatedCvr}
        </CvrAppIcon>
      </Tooltip>
    );
  };

  return (
    <Cvr>
      <div>CVR</div>
      {app1 && cumulativeCvrScore(app1)}
      {app2 && cumulativeCvrScore(app2)}
    </Cvr>
  );
}

// -- STYLED
const Cvr = styled(Flex)`
  padding-top: 30px;

  flex-direction: row;
  justify-content: center;

  font-size: 16px;
  font-weight: bold;
  color: #fff;
`;

const CvrAppIcon = styled.div`
  margin-left: 20px;

  display: flex;
  align-items: center;
`;

const Icon = styled.div<{ platformType: Platform }>`
  ${(props) =>
    props.platformType === Platform.ios
      ? `
      background-image: url(${iosImage});
      background-size: 30px;
    `
      : `
    background-image: url(${androidImage});
    background-size: 25px;
    `}

  height: 25px;
  width: 25px;
  float: left;
  margin-right: 5px;
`;

const ControlDetails = styled.div`
  text-align: left;
`;
