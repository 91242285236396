import React from "react";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";

interface Props extends FontAwesomeIconProps {
  verticalCenter?: boolean;
  onClick?: (...args: any[]) => any;
  icon: any;
}

function Icon(props: Props) {
  const { verticalCenter, ...base } = props;

  return verticalCenter ? (
    <FontAwesomeIcon style={{ alignSelf: "center" }} {...base} />
  ) : (
    <FontAwesomeIcon {...base} />
  );
}

export { Icons };
export default Icon;
