import React, { useState, useMemo, useContext } from "react";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  useTheme,
  Popper,
  Typography,
} from "@mui/material";
import { useLocation } from "react-use";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Icon from "../../../components/Icon";
import { NestedMenuList } from "./NestedMenuList";

interface SingleSideMenuItemProps {
  item: any;
  parentSx?: any;
  disableNesting?: boolean;
  subItem?: boolean;
  collapsed?: boolean;
  onMenuItemClick: (event: any, route: any) => void;
}

export const SingleMenuItem = ({
  item,
  parentSx,
  disableNesting,
  subItem,
  collapsed,
  onMenuItemClick,
}: SingleSideMenuItemProps) => {
  const theme = useTheme();
  const location = useLocation();
  const platformStore = useContext<PlatformStore>(PlatformContext);
  const currentModule = platformStore.currentModule;
  const [isHovered, setHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const renderIcon = () => {
    if (item?.muiIcon) {
      return item.muiIcon;
    }
    if (item?.iconComponent) {
      const IconComponent = item.iconComponent;
      return <IconComponent style={{ height: "20px", fill: "#fff" }} />;
    }
    return <Icon icon={item.icon} />;
  };
  const isRowActive = useMemo(() => {
    if (
      !item.route.exact &&
      ((location.pathname as string) || "").startsWith(item.route)
    ) {
      return true;
    }
    if (((location.pathname as string) || "") === item.route) return true;
    if (item.titleElement && item.route.endsWith(currentModule)) {
      return true;
    }
    return false;
  }, [location, currentModule, item.route, item.titleElement]);
  const hasSubItems = !disableNesting && item.items && item.items?.length !== 0;
  return (
    <MenuItem
      id={item.title}
      key={item.title}
      selected={isRowActive}
      onClick={
        !item.titleElement ? (e) => onMenuItemClick(e, item.route) : () => {}
      }
      className={isRowActive ? "isActive" : undefined}
      onMouseEnter={(e) => {
        e.stopPropagation();
        const currentMenuItemElement = document.getElementById(item.title);
        setAnchorEl(currentMenuItemElement);
        setHovered(true);
      }}
      onMouseLeave={(e) => {
        e.stopPropagation();
        setAnchorEl(null);
        setHovered(false);
      }}
      sx={{
        "height": "35px",
        "padding": "0px",
        "&:hover": {
          cursor: item?.titleElement ? "unset" : "pointer",
        },
        ...parentSx,
      }}
      disableRipple={item.titleElement}
      disableTouchRipple={item.titleElement}
    >
      <ListItemIcon
        sx={{
          justifyContent: "center",
          width: "80px",
        }}
      >
        {!subItem ? renderIcon() : null}
      </ListItemIcon>
      <ListItemText
        sx={{
          "& .MuiListItemText-primary": {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: theme.typography.fontSize,
            textTransform: item.titleElement ? "uppercase" : "none",
            fontWeight: theme.typography.fontWeightRegular,
          },
        }}
        primary={item.title}
      />
      {hasSubItems ? (
        <>
          <Popper
            open={isHovered}
            anchorEl={anchorEl}
            placement="right-start"
            sx={{ zIndex: 1200 }}
          >
            <NestedMenuList
              menuItems={item.items}
              parentTitle={item.title}
              parentIcon={renderIcon()}
              onMenuItemClick={onMenuItemClick}
            />
          </Popper>
          {!collapsed ? (
            <Typography>
              <ChevronRightIcon />
            </Typography>
          ) : null}
        </>
      ) : null}
    </MenuItem>
  );
};
