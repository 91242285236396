import React from "react";
import styled from "styled-components";

import themes from "./themes";
import { Icon, Icons, Tooltip } from "components";

interface Props {
  title?: string | React.ReactNode;
  titleTooltip?: string | React.ReactNode;
  description?: string | React.ReactNode;
  style?: React.CSSProperties;
  error?: boolean;
  unified?: boolean;
  transparent?: boolean;
  transparentHeader?: boolean;
  children: React.ReactNode;
}

function Section({
  title,
  description,
  children,
  unified = false,
  transparent = false,
  transparentHeader = false,
  error = false,
  style,
  titleTooltip = "",
  ...props
}: Props) {
  const renderToolip = (): React.ReactNode => (
    <Tooltip content={titleTooltip}>
      <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
    </Tooltip>
  );

  return (
    <Container error={!transparentHeader && error} style={style} {...props}>
      {!!title && (
        <Header
          transparent={transparentHeader || transparent}
          unified={unified}
        >
          {title}
          {titleTooltip && renderToolip()}
          {!!description && <Description>{description}</Description>}
        </Header>
      )}
      <Content
        transparent={transparent}
        transparenHeader={transparentHeader}
        error={error}
        unified={unified}
        headerTitle={title}
      >
        {children}
      </Content>
    </Container>
  );
}

// -- STYLED
const Container = styled.div<{ error?: boolean; ref?: any }>`
  border-radius: 8px;

  ${(props) =>
    props.error &&
    `
    box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow};
    border: 1px solid ${themes.colors.notificationRed};
  `};
`;
const Description = styled.span`
  float: right;
  font-size: 16px;
  font-weight: 300;
`;
const Header = styled.div<{ unified?: boolean; transparent?: boolean }>`
  font-size: 18px;
  font-weight: bold;

  color: ${themes.colors.white};

  min-height: 66px;
  padding: 20px 0 30px 20px;

  ${(props) =>
    !props.transparent &&
    `
    border-bottom: 1px solid ${themes.colors.backgroundDark};
  `}
  border-radius: 8px 8px 0 0;

  background-color: ${(props: any) => {
    if (props.transparent) {
      return "transparent";
    } else if (props.unified) {
      return themes.colors.card;
    }

    return "#1e1e36";
  }};

  svg {
    vertical-align: middle;
    margin-left: 10px;
  }
`;
const Content = styled.div<{
  transparent?: boolean;
  transparenHeader?: boolean;
  unified?: boolean;
  error?: boolean;
  headerTitle?: string | React.ReactNode;
}>`
  font-size: 14px;
  font-weight: 300;

  padding: ${(props: any) => (props.transparent ? null : "40px")};

  color: ${themes.colors.white};

  background-color: ${(props: any) => {
    if (props.transparent) {
      return "transparent";
    } else if (props.unified) {
      return themes.colors.card;
    }

    return "#1e1e36";
  }};

  border-radius: ${(props) =>
    props.transparenHeader || !props.headerTitle ? "8px" : "0 0 8px 8px"};

  ${(props) =>
    props.error &&
    props.transparenHeader &&
    `
    box-shadow: 0 0 15px 0 ${themes.colors.notificationRedBoxShadow};
    border: 1px solid ${themes.colors.notificationRed};
  `};
`;

export default Section;
