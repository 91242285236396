import { FullPageLoader } from "components";
import * as React from "react";

// -- HOOK
export function useFullPageLoader(): [
  null | React.ReactNode,
  React.Dispatch<React.SetStateAction<boolean>>,
] {
  const [display, setDisplay] = React.useState<boolean>(false);

  const fullPageComponent = display ? <FullPageLoader /> : null;

  return [fullPageComponent, setDisplay];
}
