import React, { FC } from "react";

import { FormDataShape } from "./ApplicationControlAdditionalPreviewsPage";
import AdditionalPreview, {
  StepData,
} from "components/application/forms/components/PreviewStep/AdditionalPreview";
import { FormHeader } from "components/forms";

// -- TYPES
interface Props {
  formData: FormDataShape;
  onChange: (prop: FormDataShape) => void;
}

// -- COMPONENT
const ApplicationControlEditAdditionalPreviews: FC<Props> = ({
  formData,
  onChange,
}) => {
  const handleAssetChange = (values: StepData) => onChange(values);

  return (
    <>
      <FormHeader style={{ marginTop: 30 }}>Edit Additional Screens</FormHeader>
      <AdditionalPreview
        data={formData}
        title=" "
        onChange={handleAssetChange}
        maxUploadAssets={8}
      />
    </>
  );
};

export default ApplicationControlEditAdditionalPreviews;
