import React, { FC, useEffect } from "react";
import styled from "styled-components";

import { userpilotReloadUrl } from "utils/userpilot";
import { isVideo } from "utils/assets";

import { ApplicationAssetsUpload } from "components/application";

import { Platform } from "api/models";
import { SetPreviewData } from "components/application/forms/PreviewStep";
import { ChangeData } from "components/application/ApplicationAssetsUpload/ApplicationAssetsUpload";

import SetUploadTitle from "./SetUploadTitle";
import themes from "components/themes";
import { Icon, Icons } from "components";

import config from "config";

// -- HELPERS VARIABLES
/**
 * Frontend temporary id for sets to avoid duplications
 */
let lastPreviewTempId = 1;

// -- COMPONENT
interface Props {
  data: SetPreviewData;
  onChange: (values: object) => void;
  platform?: Platform.ios | Platform.android;
  maxCountOfSets?: number;
  maxAssetsCountForSets?: number;
  minAssetsCountForSets?: number;
  allowSetControl?: boolean;
}

const Sets: FC<Props> = ({
  data,
  onChange,
  platform,
  maxCountOfSets,
  maxAssetsCountForSets = config.maxAssetsCountForSets,
  minAssetsCountForSets = config.minAssetsCountForSets,
  allowSetControl = true,
}) => {
  useEffect(() => {
    userpilotReloadUrl(`${window.location.href}/sets`);
  }, []);

  const handleAddNewSet = (event: any) => {
    event.preventDefault();
    onChange({
      sets: [
        ...data.sets,
        {
          name: "",
          assets: [],
          isControl: false,
          tempId: ++lastPreviewTempId,
        },
      ],
    });
  };

  const onSetDelete = (deletedSetTempId: number) => (): void =>
    onChange({
      sets: data.sets.filter((elem) => deletedSetTempId !== elem.tempId),
    });

  const changeControlSet = (newControlTempId: number) => (): void =>
    onChange({
      sets: data.sets.filter((singleSet) => ({
        ...singleSet,
        isControl: singleSet.isControl = singleSet.tempId === newControlTempId,
      })),
    });

  const onSetNameChange = (setTempId: number) => (event: any): void => {
    const name = event.target.value;
    onChange({
      sets: data.sets.map((singleSet) => {
        if (singleSet.tempId !== setTempId) {
          return singleSet;
        }

        return { ...singleSet, name };
      }),
    });
  };

  const onAssetsChange = (setTempId: number) => (change: ChangeData) => {
    const assets = change.assets;

    onChange({
      sets: data.sets.map((singleSet) =>
        singleSet.tempId !== setTempId
          ? singleSet
          : {
              ...singleSet,
              assets,
            },
      ),
    });
  };

  const renderSetsFields = () =>
    data.sets.map((singleSet, index) => {
      const { name, isControl, assets, tempId } = singleSet;
      const isVideoLimitReached =
        assets.filter((asset) => isVideo(asset)).length >= 3 ||
        (platform === Platform.android && isVideo(assets[0]));

      return (
        <ApplicationAssetsUpload
          key={tempId}
          header={
            <SetUploadTitle
              title={name}
              isControl={isControl}
              changeControlSet={changeControlSet(tempId)}
              onSetDelete={onSetDelete(tempId)}
              onSetNameChange={onSetNameChange(tempId)}
              formFieldName={`sets[${index}].name`}
              allowControlAction={allowSetControl}
              allowDelete={!isControl && data.sets.length > 1}
            />
          }
          formFieldName={`sets[${index}].assets`}
          assets={assets}
          controlAsset={null}
          buttonText="Upload Screens"
          emptyTitle="Upload Screens"
          emptyDescription="Use the button above to upload screens from your computer"
          onChange={onAssetsChange(tempId)}
          onControl={null}
          video={!isVideoLimitReached}
          maxUploadAssets={maxAssetsCountForSets}
          minUploadAssets={minAssetsCountForSets}
          platform={platform}
          allowDragAndDrop
          typeOfScreen="set"
        />
      );
    });

  return (
    <>
      {renderSetsFields()}
      {(!maxCountOfSets || data.sets.length < maxCountOfSets) && (
        <AddNewButton onClick={handleAddNewSet}>
          <Icon icon={Icons.faPlusCircle} color={themes.colors.purple} /> New
          Set of Screens
        </AddNewButton>
      )}
    </>
  );
};

// -- STYLED
const AddNewButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 124px;
  width: 100%;

  border: ${themes.borderStyle.placeholder} ${themes.colors.borderGrey};
  border-radius: 8px;

  background-color: rgba(0, 0, 0, 0);

  color: ${themes.colors.white};
  font-size: 14px;
  font-weight: 600;

  svg {
    font-size: 18px;
    margin-right: 10px;
  }

  &:focus {
    outline: none;
  }
`;

export default Sets;
