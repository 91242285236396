import * as React from "react";

import { UserPermissions } from "v2/api/types/UserPermissions";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";

// -- PROPS
type PermissionRestrictionProps = {
  requiredPermissions?: UserPermissions[];
  children: any;
};

// -- COMPONENT
export function PermissionRestriction({
  requiredPermissions, // should be memorized
  children,
}: PermissionRestrictionProps): JSX.Element | null {
  const platformStore = React.useContext<PlatformStore>(PlatformContext);

  const shouldDisplayComponent = React.useMemo(() => {
    if (!requiredPermissions) {
      return children;
    }

    return requiredPermissions.every(
      (singlePermission) =>
        platformStore.currentUserFrontendPermissions.indexOf(
          singlePermission,
        ) >= 0,
    );
  }, [
    platformStore.currentUserFrontendPermissions,
    requiredPermissions,
    children,
  ]);

  return shouldDisplayComponent ? children : null;
}
