import gql from "graphql-tag";

export const updateControlFeatureGraphic = gql`
  mutation updateControlFeatureGraphic($applicationId: ID!, $control: Upload!) {
    updateControlFeatureGraphic(applicationId: $applicationId, file: $control) {
      isUpdated
    }
  }
`;

export interface UpdateControlFeatureGraphicResponse {
  updateControlFeatureGraphic: {
    isUpdated: boolean;
  };
}

export interface CreateFeatureGraphicsResponse {
  createFeatureGraphics: {
    isCreated: boolean;
  };
}
