import React from "react";
import { PixelEventsTypes } from "screens/ApplicationPixelPage/constants/pixelEventsTypes";

export enum Platform {
  ios = "ios",
  android = "android",
}

export enum Status {
  active = "ACTIVE",
  inactive = "INACTIVE",
  finished = "FINISHED",
  calculatingBcr = "CALCULATING_BCR",
}

export enum AppStatus {
  active = "active",
  inactive = "inactive",
}

export enum ClientStatus {
  active = "active",
  inactive = "inactive",
}

export enum AssetType {
  icon = "icon",
  promoArt = "promo_art",
  preview = "preview",
  title = "title",
  subtitle = "subtitle",
  description = "description",
  featureGraphic = "feature_graphic",
}

export enum ExperimentPhaseType {
  icon,
  promoArt,
  featureGraphic,
  preview_1,
  preview_2,
  preview_3,
  title,
  subtitle,
  description,
}

export enum AssetStatus {
  new = "NEW",
  queued = "QUEUED",
  testing = "TESTING",
  tested = "TESTED",
  discarded = "DISCARDED",
}

export enum ReferenceVariationType {
  top = "top",
  control = "control",
}

export enum AxisDirection {
  vertical = "x",
  horizontal = "y",
}

type ApplicationControlData = {
  cvr: number;
  visits: number;
  conversions: number;
};

export enum ReferenceLinesTypes {
  shipDate = "ship_date",
  userAction = "user_action",
}

export interface ApplicationListing {
  id: string;
  code: string;
  name: string;
  platform: Platform;
  controlIcon?: string;
  createdAt: string;
  status: Status;
  redirectToStoreUrl?: boolean;
  controlData?: ApplicationControlData;
  groupCode: string;
}

export interface ApplicationGroup {
  id: string;
  name: string;
  createdAt: string;
  image: string;
  applications: ApplicationListing[];
}

export interface AppPlatformListing {
  appPublicId?: string;
  asoAppId: string;
  code: string;
  platform: Platform;
  status: AppStatus;
  verifiedDataDate?: boolean;
  asoInfo?: {
    controlData: ApplicationControlData;
    redirectToStoreUrl: boolean;
  };
}

export interface ClientApplication {
  asoGroupId: string;
  code: string;
  createDate: string;
  image: string;
  name: string;
  status: AppStatus;
  sequenceId: number;
  audit: {
    createDate: string;
  };
  appPlatform: AppPlatformListing[];
}

export enum RouteType {
  control = "control",
  newTests = "new_tests",
  actualStorePage = "actual_store_page",
  custom = "custom",
}

export interface Route {
  id: string;
  url: string;
  weight: number;
  visits: number;
  discardedVisits?: number;
  redirectUrl?: string;
  type: RouteType;
}

export interface Application extends ApplicationListing {
  redirectUrl: string;
  identifier: string;
  currentBcr: number | null;
  significance: number;
  firstIterationEnds?: boolean;
  baselineConversionRate?: number | null;
  minimumDetectableLift: number | null;
}

export interface ExperimentStatistics {
  totalTestsRun: number;
  totalTraffic: number;
  creativesCurrentlyTesting: number;
  creativesQueued: number;
  creativesDiscarded: number;
  creativesQueuedInPhase: number;
}

interface File {
  url?: string;
}

export interface HistoricalConversionData {
  startDate: string;
  endDate: string;
  visits: number;
  installs: number;
  cvr: number;
  action: string;
}

export interface ApplicationAsset {
  id: string;
  isControl: boolean;
  status?: AssetStatus;
  conversionRate?: number;
  conversion?: number;
  confidence?: number;
  visits?: number;
  isSet?: boolean;
  isControlSet?: boolean;
  isTop?: boolean;
  historicalConversionData?: HistoricalConversionData[];
  previewLink?: string;
}

export interface Icon extends ApplicationAsset {
  file: File;
}

export interface Preview extends ApplicationAsset {
  url: string;
  file?: File;
  isVideo?: boolean;
  position: number;
  fileName?: string;
  previewLink?: string;
  embeddedVideoUrl?: string;
  set?: {
    id: string;
    name: string;
  };
}

export interface PromoArt extends ApplicationAsset {
  file: File;
}

export interface FeatureGraphic extends ApplicationAsset {
  file: File;
}

export interface Title extends ApplicationAsset {
  name: string;
}

export interface Subtitle extends ApplicationAsset {
  name: string;
}

export interface Description extends ApplicationAsset {
  name: string;
}

export interface Variation {
  id?: bigint;
  icon: Icon;
  promoArt: PromoArt | null;
  featureGraphic: FeatureGraphic | null;
  previews: Preview[];
  additionalPreviews: Preview[];
  title: Title;
  subtitle: Subtitle;
  description: Description;
  conversionRate: number;
  confidence: number;
  url: string;
}

export interface ConversionRate {
  date: string;
  cvr: number;
  visits: number;
  conversions: number;
  cumulativeConversions: number;
  cumulativeVisits: number;
}

export interface AppConversionRates {
  label: string;
  legendOrderIndex: number;
  legendTooltip: string;
  conversionRates: ConversionRate[];
}

export interface ExperimentPhase {
  id: string;
  type: ExperimentPhaseType;
  startedAt: string | null;
  finishedAt: string | null;
}

export interface ChartDataSetShape {
  [propertyName: string]:
    | string
    | number
    | {
        cvr: number;
        visits: number;
        conversions: number;
      };
}

export interface LegendElement {
  label: string;
  legendOrderIndex: number;
  legendTooltip?: string | null;
  dataIndex: string;
  color: string;
  isReferenceLine?: boolean;
}

export interface ReferenceLine {
  axisDirection: AxisDirection;
  label?: string;
  axisValue: string | number;
  tooltipValues?: string[];
  type: ReferenceLinesTypes;
  color?: string;
}

export interface TrafficContent {
  cvr: number;
  conversions: number;
  visits: number;
  tooltipCvr: { conversions: number; visits: number };
}

export interface PayloadContent {
  [property: string]: string | TrafficContent;
}

export interface TrafficPayloadContent extends PayloadContent {
  traffic: TrafficContent;
  control: TrafficContent;
  frontrunner: TrafficContent;
}

export interface ChartConfiguration {
  dataSet: ChartDataSetShape[];
  aggregatedTooltipData?: PayloadContent[];
  referenceLines?: ReferenceLine[];
  legendElements: LegendElement[];
  xAxisLabelByField?: string;
  xAxisComponent?: React.ReactElement;
  tooltipComponent?: React.ReactElement;
  yAxisFormatter?: (tickValue: string | number) => string;
  yAxisDomain?: [number, number];
  xAxisDomain?: [number, number];
  showGradient?: boolean;
  reversedX?: boolean;
  reversedY?: boolean;
  allowEscapeViewBox?: boolean;
  height?: number;
  width?: string;
  xAxisTicks?: string[];
  yAxisTicks?: string[];
  layout?: "horizontal" | "vertical";
  yAxisLabelByField?: string;
  xAxisType?: "category" | "number";
  yAxisType?: "category" | "number";
  xAxisFormatter?: (tickValue: string | number) => string;
  xAxisLabel?: string | React.ReactElement;
  yAxisLabel?: string | React.ReactElement;
  initialDisabledDataIndexes?: string[];
}

export interface Modal {
  title: string;
  content: React.ReactNode;
  confirmText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onHide?: () => void;
  show: boolean;
}

export type ModalReducerActionType = "set" | "update" | "reset";

export enum ChartTooltipRowLabels {
  cvr = "CVR",
  conversions = "Conversions",
  visits = "Visits",
}

export enum ScreensType {
  single = "single",
  set = "set",
}

export interface CurrentIteration {
  id: bigint;
  startedAt: string;
  finishedAt?: string;
  testingSets: boolean;
}

export interface User {
  id: number;
  username: string;
}

export interface FacebookPixel {
  eventType: keyof typeof PixelEventsTypes;
  pixelId: string;
  enabled: boolean;
  domainTagContent?: string;
}

export interface IosCategory {
  id: number;
  name: string;
  platform: Platform;
  children: IosCategory[];
}

export interface ApplicationControlAssets {
  icon: Icon;
  promoArt: PromoArt | null;
  previews: Preview[];
  title: Title;
  subtitle: Subtitle;
  description: Description;
  featureGraphic: FeatureGraphic | null;
}

declare global {
  interface Window {
    userpilot: {
      identify: (userId: string | number, additionalDetails?: object) => void;
      reload: (param?: { url: string }) => void;
      track: (pageEvent: string, additionalDetails?: object) => void;
    };
    $zopim?: {
      livechat?: {
        button?: {
          hide?: () => void;
          show?: () => void;
        };
      };
    };
  }
}
