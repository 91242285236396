import { Stripe, StripeElements } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js";
import { isFunction } from "lodash";

import { ModalSetter } from "contexts/modalContext";

export const createPaymentMethod = async (
  stripe: Stripe | null,
  elements: StripeElements | null,
  setModalFunc?: ModalSetter,
): Promise<string | null> => {
  if (!isFunction(setModalFunc)) {
    return null;
  }

  if (!stripe || !elements) {
    setModalFunc({
      type: "set",
      props: {
        content: "Unable to initialize stripe payments",
      },
    });

    return null;
  }

  const cardElement = elements.getElement(CardElement);

  if (!cardElement) {
    setModalFunc({
      type: "set",
      props: {
        content: "Unable to initialize stripe payments",
      },
    });

    return null;
  }

  const { error, paymentMethod } = await stripe.createPaymentMethod({
    type: "card",
    card: cardElement,
  });

  if (error || !paymentMethod) {
    setModalFunc({
      type: "set",
      props: {
        content: error?.message || "Something went wrong",
      },
    });

    return null;
  }

  return paymentMethod.id;
};
