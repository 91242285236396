import React from "react";
import styled from "styled-components";
import ListGroup from "react-bootstrap/ListGroup";

import themes from "components/themes";

interface Props {
  children: React.ReactNode;
}

function List({ children }: Props) {
  return <StyledListGroup>{children}</StyledListGroup>;
}

const StyledListGroup = styled(ListGroup)`
  font-size: 15px;
  font-weight: 600;
  color: ${themes.colors.white};
`;

export default List;
