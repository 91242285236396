import React from "react";

import HeaderWithTypeSelector from "./components/PreviewStep/HeaderWithTypeSelector";
import PreviewSets from "./components/PreviewStep/PreviewSets";
import PreviewIdividual from "./components/PreviewStep/PreviewIndividual";

import { UploadFile } from "components/forms/UploadInput";
import { Platform, ScreensType } from "api/models";

import {
  defaultFormDataSetsPreview,
  defaultFormDataIndividualPreview,
} from "screens/Application/ApplicationCreate/ApplicationCreatePage";
import config from "../../../config";

// -- TYPES
export interface Set {
  name: string;
  isControl: boolean;
  assets: UploadFile[];
  tempId?: any;
}

export interface SinglePreviewData {
  screensType: ScreensType.single;
  control1: UploadFile | null;
  control2: UploadFile | null;
  control3: UploadFile | null;
  assets1: UploadFile[];
  assets2: UploadFile[];
  assets3: UploadFile[];
  assets4: UploadFile[];
}

export interface SetPreviewData {
  screensType: ScreensType.set;
  sets: Set[];
}

export type StepData = SinglePreviewData | SetPreviewData;

interface Props {
  data: StepData;
  onChange: (values: object, isNewObject?: boolean) => void;
  maxUploadAssets?: number;
  platform?: Platform.ios | Platform.android;
}

// -- COMPONENT
class PreviewStep extends React.PureComponent<Props> {
  public onPreviewTypeChange = (value: ScreensType) => {
    this.props.onChange(
      value === ScreensType.single
        ? defaultFormDataIndividualPreview
        : defaultFormDataSetsPreview,
      true,
    );
  };

  public render() {
    const { data, onChange, maxUploadAssets, platform } = this.props;

    const renderPreviewComponent = () => {
      if (data.screensType === ScreensType.single) {
        return (
          <PreviewIdividual
            data={data}
            onChange={onChange}
            maxUploadAssets={maxUploadAssets}
            platform={platform}
          />
        );
      }

      return (
        <PreviewSets
          data={data}
          onChange={onChange}
          platform={platform}
          maxCountOfSets={config.maxCountOfSets}
        />
      );
    };

    return (
      <>
        <HeaderWithTypeSelector
          title="Upload App Preview Screens"
          toggleValue={data.screensType}
          onToggleChange={this.onPreviewTypeChange}
        />
        {renderPreviewComponent()}
      </>
    );
  }
}

export default PreviewStep;
