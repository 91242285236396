import styled from "styled-components";

const FormHeader = styled.h3`
  font-size: 21px;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 600;
`;

export default FormHeader;
