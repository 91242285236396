// -- CORE
import * as React from "react";
import { styled } from "@mui/system";
import { useMutation } from "@apollo/client";
// -- CONTEXTS
import { ModalContext } from "contexts";
// -- HOOKS
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useHasUserPermission } from "v2/hooks/useHasUserPermission";
// -- API
import {
  changeMDL,
  ChangeMDLResponse,
} from "v2/api/mutations/application/changeMDL";
// -- STATIC
import { minimumDetectableLift } from "components/forms/data/data";
// -- UTILS
import { percentFormat } from "utils/numbers";
// -- COMPONENTS
import SelectInput from "components/forms/SelectInput/SelectInput";
import Section from "components/Section";
import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";
import { StaticTable } from "v2/components/Table/StaticTable";
import { TdComponent } from "v2/components/Table/components/TdComponent";
import { TrComponent } from "v2/components/Table/components/TrComponent";
import { ExternalLink } from "components";

// -- PROPS
type MinimumDetectableLiftSectionProps = {
  mdl: number | null;
  applicationId: string;
};

// -- COMPONENT
export function MinimumDetectableLiftSection({
  mdl,
  applicationId,
}: MinimumDetectableLiftSectionProps): JSX.Element {
  const [MDLValue, setMDLValues] = React.useState<string>(String(mdl));

  const setModal = React.useContext(ModalContext);

  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();
  const handleError = useRequestErrorHandler();

  const [saveNewMDL] = useMutation<ChangeMDLResponse>(changeMDL.definition);

  const hasUserPermissions = useHasUserPermission();

  const handleChangeMDL = React.useCallback(
    (newMDLValue: string) => async () => {
      setFullPageLoader(true);

      const options = {
        variables: {
          applicationId,
          minimumDetectableLift: Number(newMDLValue),
        },
      };

      const result = await handleError(saveNewMDL(options));

      if (result) {
        setMDLValues(newMDLValue);
      }

      setFullPageLoader(false);
    },
    [setFullPageLoader, applicationId, saveNewMDL, handleError],
  );

  const handleChange = React.useCallback(
    (value: string) => {
      const changeFrom = Number(MDLValue) * 100;
      const changeTo = Number(value) * 100;

      setModal({
        type: "set",
        props: {
          title: "Change of Minimum Detectable Lift",
          content: `Are you sure you want to update the Minimum Detectable Lift from ${changeFrom}% to ${changeTo}%? This change will not be applied to a currently running test but to future tests.`,
          onConfirm: handleChangeMDL(value),
        },
      });
    },
    [handleChangeMDL, setModal, MDLValue],
  );

  const columns = React.useMemo<
    SingleTableColumn<{ label: unknown; value: unknown }>[]
  >(
    () => [
      {
        Header: "label",
        accessor: "label",
        tdElement: CustomLabelTd as any,
      },
      {
        Header: "value",
        accessor: "value",
        tdElement: CustomValueTd as any,
      },
    ],
    [],
  );

  const rows = React.useMemo(() => {
    return [
      {
        label: (
          <>
            This is the minimum % lift from your Control Page that you are
            willing to accept. E.g. if you set this to 9%, and the algorithm
            discovers a new asset that converts 8% better than the Control, it
            won't be good enough, and it will not be deemed a winner over the
            Control asset.
            <br />A lower MDL also means when assets perform very similarly, it
            will require more traffic for our algorithm to automatically
            determine a winner. If unsure, set this to 8% default value.
            <ExternalLink
              value={
                "https://upptic.zendesk.com/hc/en-us/articles/360060618432-Minimum-Detectable-Lift"
              }
            >
              See Help article
            </ExternalLink>
            .
          </>
        ),
        value: hasUserPermissions(changeMDL.requiredPermissions) ? (
          <SelectInput
            value={MDLValue}
            options={minimumDetectableLift}
            onChange={handleChange}
          />
        ) : (
          percentFormat(Number(MDLValue))
        ),
      },
    ];
  }, [MDLValue, handleChange, hasUserPermissions]);

  return (
    <>
      {fullPageLoader}
      <Section
        title="Minimum Detectable Lift"
        transparent
        style={{ marginBottom: 50 }}
      >
        <StaticTable
          columns={columns}
          data={rows}
          displayHeader={false}
          trElement={CustomTr}
        />
      </Section>
    </>
  );
}

// -- STYLED
const CustomLabelTd = styled(TdComponent)`
  color: ${(props: any) => props.theme.colors.lightGrey};
  text-align: left;
  word-break: break-word;
`;

const CustomValueTd = styled(TdComponent)`
  color: ${(props: any) => props.theme.colors.lightGrey};
  text-align: right;
  width: 200px;
`;

const CustomTr = styled(TrComponent)`
  background-color: "#181831";
`;
