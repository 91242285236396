import * as React from "react";
import { Form, FormRenderProps } from "react-final-form";
import { omit } from "lodash";
import { useMutation } from "@apollo/client";

import config from "config";

import { SingleReview } from "api/models/reviews/singleReviews";
import {
  GET_ANDROID_APPLICATION_STATIC_INFO,
  GET_IOS_APPLICATION_STATIC_INFO,
  Platform,
} from "api";
import { UPDATE_REVIEWS, DELETE_REVIEWS } from "api/mutations/assets";

import { ModalContext } from "contexts";

import { createReviewDomElements } from "utils/reviews";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { UserPermissions } from "v2/api/types";

import FormSection from "components/forms/FormSection";
import { HeaderWithEdit } from "./HeaderWithEdit";
import MessageBox from "components/MessageBox";
import { ApplicationSingleReviewStaticPage } from "./ApplicationSingleReviewStaticPage";
import Button from "components/forms/Button";

// -- TYPES
type ApplicationReviewsProps = {
  reviews: SingleReview[];
  platform: Platform;
  applicationId: string;
};

// -- COMPONENT
export function ApplicationReviewsStaticPage({
  reviews,
  platform,
  applicationId,
}: ApplicationReviewsProps): JSX.Element {
  const [edit, setIsEdit] = React.useState<boolean>(false);

  const setModal = React.useContext(ModalContext);

  const [fullPageLoader, setIsLoading] = useFullPageLoader();
  const handleError = useRequestErrorHandler();

  const [updateReviews] = useMutation(UPDATE_REVIEWS);
  const [deleteReviews] = useMutation(DELETE_REVIEWS);

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);

    const revs: SingleReview[] = values.reviews?.map((singleReview: object) =>
      omit(singleReview, ["__typename"]),
    );

    const updateResponse = await handleError(
      updateReviews({
        variables: {
          applicationId,
          reviews: revs,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: Platform.ios
              ? GET_IOS_APPLICATION_STATIC_INFO
              : GET_ANDROID_APPLICATION_STATIC_INFO,
            variables: { id: applicationId },
          },
        ],
      }),
    );

    updateResponse && setIsEdit(false);

    setIsLoading(false);
  };

  const handleReviewsDelete = React.useCallback(async () => {
    setIsLoading(true);

    const deleteResponse = await handleError(
      deleteReviews({
        variables: {
          applicationId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query:
              platform === Platform.ios
                ? GET_IOS_APPLICATION_STATIC_INFO
                : GET_ANDROID_APPLICATION_STATIC_INFO,
            variables: { id: applicationId },
          },
        ],
      }),
    );

    deleteResponse && setIsEdit(false);

    setIsLoading(false);
  }, [deleteReviews, platform, applicationId, handleError, setIsLoading]);

  const onDelete = React.useCallback(() => {
    setModal({
      type: "set",
      props: {
        title: "Warning",
        content: "Are you sure you want to remove these reviews?",
        onConfirm: () => handleReviewsDelete(),
        confirmText: "Delete",
      },
    });
  }, [setModal, handleReviewsDelete]);

  const initialData = React.useMemo(() => {
    return {
      reviews,
    };
  }, [reviews]);

  const reviewsDomElements = React.useMemo(() => {
    const elements = createReviewDomElements(config.defaultNumberOfReviews);

    return elements;
  }, []);

  const additionalActions = React.useMemo(() => {
    if (!reviews.length) {
      return [];
    }

    return [
      <Button key={"delete-reviews"} outline onClick={onDelete}>
        Delete reviews
      </Button>,
    ];
  }, [reviews, onDelete]);

  const renderReviews = () => {
    if (!reviews.length && !edit) {
      return (
        <MessageBox closeable={false}>
          There are no reviews added yet.
        </MessageBox>
      );
    }

    return reviewsDomElements.map((singleReviewDomElement, index) => (
      <ApplicationSingleReviewStaticPage
        reviewElement={singleReviewDomElement}
        key={singleReviewDomElement.label}
        fieldPrefix={`reviews[${index}]`}
        platform={platform}
        isEditOn={edit}
      />
    ));
  };

  return (
    <>
      {fullPageLoader}
      <FormSection>
        <Form onSubmit={handleFormSubmit} initialValues={initialData}>
          {({ handleSubmit, form }: FormRenderProps) => (
            <form onSubmit={handleSubmit}>
              <HeaderWithEdit
                title="Reviews (Optional)"
                editStatus={edit}
                setEditStatus={setIsEdit}
                form={form}
                additionalActions={additionalActions}
                editRequiredPermissions={[
                  UserPermissions.updateAppPlatformProfile,
                ]}
              />
              {renderReviews()}
            </form>
          )}
        </Form>
      </FormSection>
    </>
  );
}
