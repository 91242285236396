import { gql } from "@apollo/client";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const getCurrentUser = gql`
  query currentUser {
    currentUser {
      emailAddress
      firstName
      lastName
      clientCode
      accessToAllApps
      client {
        code
        name
        owner {
          emailAddress
        }
        asoAccountId
        isUnlimited
        isFree
        hasValidPaymentMethod
        hasOverdueInvoice
      }
      isUppticUser
    }
    currentUserFrontendPermissions
    currentUserClients {
      code
      name
      status
    }
  }
`;

export const getSimpleCurrentUser = gql`
  query currentUser {
    currentUser {
      emailAddress
    }
  }
`;

export type CurrentClientOwner = {
  emailAddress: string;
};

export type CurrentClient = {
  code: string;
  name: string;
  status: string;
  asoAccountId: string;
  isUnlimited: boolean;
  isFree: boolean;
  hasValidPaymentMethod: boolean;
  hasOverdueInvoice: boolean;
  owner?: CurrentClientOwner;
};

export type CurrentUser = {
  clientCode: string | null;
  emailAddress: string;
  firstName: string;
  lastName: string;
  client: CurrentClient | null;
  isUppticUser: boolean;
  accessToAllApps: boolean;
};

export type CurrentUserClient = {
  code: string;
  name: string;
  status: string;
};

export type GetCurrentUserResponse = {
  currentUser: CurrentUser;
  currentUserFrontendPermissions: UserPermissions[];
  currentUserClients: CurrentUserClient[];
};
