import React from "react";
import { isString } from "lodash";

import themes from "components/themes";

import moment from "moment";

// -- TYPES
interface PayloadShape {
  value: string | number;
}

interface AxisTickProps {
  x?: number;
  y?: number;
  index?: number;
  visibleTicksCount?: number;
  payload?: PayloadShape;
  displayMode: string;
}

// -- COMPONENT
const ChartXAxisTick: React.FC<AxisTickProps> = ({
  x,
  y,
  index,
  visibleTicksCount = 0,
  payload,
  displayMode,
}) => {
  const getAnchorPosition = (): string => {
    if (!index || index === visibleTicksCount - 1) {
      return "start";
    }

    return "middle";
  };

  const displayFormattedValue = (
    tickPayload: PayloadShape | undefined,
  ): React.ReactNode => {
    if (!tickPayload || !isString(tickPayload.value)) {
      return null;
    }

    const date = moment(tickPayload.value);

    if (displayMode === "week") {
      return `${date.isoWeek()}w`;
    }

    if (displayMode === "month") {
      return date.format("MMM");
    }

    return date.format("MMM DD");
  };

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor={getAnchorPosition()}
        fill={themes.colors.lightGrey}
      >
        {displayFormattedValue(payload)}
      </text>
    </g>
  );
};

export default ChartXAxisTick;
