import React from "react";
import {
  Tooltip as MUITooltip,
  Box,
  TooltipProps,
  tooltipClasses,
  styled,
} from "@mui/material";
import { Placement } from "./constants/placement";

type TooltipPassedProps = {
  children: React.ReactElement<any, any>;
  content: string | number | boolean | React.ReactFragment | React.ReactNode;
  placement?: Placement;
};

export function Tooltip({
  children,
  content,
  placement = "right",
}: TooltipPassedProps): JSX.Element {
  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <MUITooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      "&:before": {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: "#3c3c51",
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      border: `1px solid ${theme.palette.primary.main}`,
      fontSize: theme.typography.fontSize,
      maxWidth: 500,
      backgroundColor: "#3c3c51",
    },
  }));

  return (
    <StyledTooltip
      arrow
      title={<Box p={0.5}>{content}</Box>}
      placement={placement}
    >
      <div style={{ display: "inline-block" }}>{children}</div>
    </StyledTooltip>
  );
}
