import { StylesConfig } from "react-select";

import theme from "components/themes";

const controlBorder = (isFocused: boolean) => {
  if (isFocused) {
    return theme.colors.infoBlue;
  }

  return theme.colors.background;
};

const controlBoxShadow = (isFocused: boolean) => {
  if (isFocused) {
    return `0 0 15px 0 ${theme.colors.infoBlueBoxShadow}`;
  }

  return "none";
};

export const tagInputStyles: StylesConfig<any, boolean> = {
  control: (styles: any, { menuIsOpen, isFocused }) => ({
    ...styles,
    "backgroundColor": theme.colors.inputs,
    "color": theme.colors.white,
    "borderColor": controlBorder(isFocused),
    "borderRadius": "2px",
    "boxShadow": controlBoxShadow(isFocused),
    ":hover": {
      borderColor: menuIsOpen ? theme.colors.infoBlue : theme.colors.background,
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: theme.colors.white,
    opacity: 0.4,
  }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: "none" }),
  dropdownIndicator: (styles: any) => ({ ...styles, display: "none" }),
  multiValue: (styles: any) => ({
    ...styles,
    color: theme.colors.white,
    backgroundColor: theme.colors.backgroundLight,
    border: `1px solid ${theme.colors.purple}`,
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: theme.colors.white,
    fontSize: "14px",
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    ":hover": {
      cursor: "pointer",
      color: theme.colors.white,
      backgroundColor: theme.colors.backgroundLight,
    },
  }),
  input: (styles: any) => ({ ...styles, color: theme.colors.white }),
};
