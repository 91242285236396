import React, { useContext } from "react";
import { useQuery } from "@apollo/client";

import { ClientApplication, GET_CLIENT_APPLICATIONS } from "api";

import { ApplicationContext, useStores } from "contexts";

import { GetClientApplicationsResponse } from "./constants/getApplicationResponse";

import { Spinner, Error } from "components";
import ApplicationListPage from "./ApplicationListPage";

// -- COMPONENT
function ApplicationListContainer() {
  const applicationCtx = useContext(ApplicationContext);
  applicationCtx.setCurrent(null);
  const { userStore } = useStores();

  const { data, error, loading } = useQuery<GetClientApplicationsResponse>(
    GET_CLIENT_APPLICATIONS,
    {
      fetchPolicy: "no-cache",
      variables: { clientCode: userStore.clientCode },
    },
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error} />;
  }

  let clientApplications: ClientApplication[] = [];
  if (data?.clientApplications) {
    clientApplications = data?.clientApplications.filter((app) => {
      const appPlatforms = app.appPlatform.filter(({ asoAppId }) => asoAppId);
      return !!appPlatforms.length;
    });
  }

  return <ApplicationListPage applicationGroups={clientApplications} />;
}

export default ApplicationListContainer;
