import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const changeRedirectAfterTests = new ApiMutation({
  definition: gql`
    mutation redirectToAppStoreUrl(
      $applicationId: ID!
      $shouldRedirect: Boolean!
    ) {
      updateRedirectToAppStoreUrl(
        applicationId: $applicationId
        shouldRedirect: $shouldRedirect
      ) {
        isRedirectToAppStore
      }
    }
  `,
  requiredPermissions: [UserPermissions.updateAppPlatformProfile],
});

export interface ChangeRedirectAfterTestsResponse {
  updateRedirectToAppStoreUrl: {
    isRedirectToAppStore: boolean;
  };
}
