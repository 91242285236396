import { styled } from "@mui/system";
import { Flex } from "@rebass/grid";

import { Button } from "components/forms";
import Icon from "components/Icon";

export const ShipButton = styled(Button)`
  font-size: 14px;
  min-width: 240px;
`;

export const ButtonBox = styled(Flex)`
  margin-top: 20px;
`;

export const TooltipIcon = styled(Icon)`
  color: ${(props: any) => props.theme.colors.white};
  font-size: 20px;
  height: 44px;
  margin-left: 14px;
`;
