import React, { useState, useEffect, ReactNode, memo } from "react";
import styled from "styled-components";
import "@upptic/react-dates/initialize";
import moment, { Moment } from "moment";
import { DateRangePicker } from "@upptic/react-dates";

import FormError from "./FormError";
import theme from "components/themes";

interface RangePickerDates {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

interface DateRangePickerPropsType {
  initialStartDate?: string;
  initialEndDate?: string;
  onChange: (dateFrom: string, dateTo: string) => void;
  onFocusChange?: (focused: boolean | null) => void;
  externalError?: string;
  displayFormat?: string;
  minDay?: Moment;
  maxDay?: Moment;
}

const RangePicker: React.FC<DateRangePickerPropsType> = ({
  initialStartDate,
  initialEndDate,
  onChange,
  externalError,
  displayFormat = "DD-MM-YYYY",
  minDay,
  maxDay,
}) => {
  const [dates, setDates] = useState<RangePickerDates>({
    startDate: null,
    endDate: null,
  });
  const [pickerConfig, setPickerConfig] = useState<{
    error: boolean;
    focusedOn: "startDate" | "endDate" | null;
  }>({
    error: false,
    focusedOn: null,
  });

  const getInitialDates = (
    initialFrom: string | undefined,
    initialTo: string | undefined,
  ): RangePickerDates => {
    const [startDate, endDate] = [initialFrom, initialTo].map((singleDate) => {
      let date: moment.Moment | null = moment(singleDate || "");

      if (!date.isValid()) {
        date = null;
      }

      return date;
    });

    return {
      startDate,
      endDate,
    };
  };

  useEffect(() => {
    setDates(getInitialDates(initialStartDate, initialEndDate));
  }, [initialEndDate, initialStartDate]);

  const renderCustomButton = (buttonDirection: "left" | "right") => (
    buttonProps: any,
  ) => {
    const buttonsType = {
      left: LeftArrow,
      right: RightArrow,
    };

    const Component = buttonsType[buttonDirection];

    return <Component {...buttonProps} />;
  };

  const onDateChange = (selectedDates: RangePickerDates): void =>
    setDates({ ...selectedDates });

  const changeVisibility = (focusInput: "startDate" | "endDate" | null): void =>
    setPickerConfig({ ...pickerConfig, focusedOn: focusInput });

  const onClose = (selectedDates: RangePickerDates): void => {
    const { startDate, endDate } = selectedDates;
    if (startDate && endDate) {
      onChange(startDate.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY"));
    }
  };

  const isDayBlocked = React.useCallback(
    (day: Moment): boolean => {
      const isBefore = minDay && day.isBefore(minDay, "day");
      const isAfter = maxDay && day.isAfter(maxDay, "day");

      return Boolean(isBefore || isAfter);
    },
    [minDay, maxDay],
  );

  const defaultInputProps = {
    onDatesChange: onDateChange,
    onFocusChange: changeVisibility,
    hideKeyboardShortcutsPanel: true,
    displayFormat,
    numberOfMonths: 2,
    renderNavPrevButton: renderCustomButton("left"),
    renderNavNextButton: renderCustomButton("right"),
    keepOpenOnDateSelect: false,
    isOutsideRange: () => false,
    readOnly: true,
    customArrowIcon: "-",
    onClose,
    isDayBlocked: minDay || maxDay ? isDayBlocked : undefined,
  };

  const renderError = (): ReactNode | void => {
    const { error } = pickerConfig;
    const errorList = [];

    if (externalError) {
      errorList.push(externalError);
    }
    if (error) {
      errorList.push("Invalid date");
    }

    const errorMessage = errorList.filter(Boolean).join(". ");

    if (error || externalError) {
      return <FormError>{`${errorMessage}.`}</FormError>;
    }
  };

  return (
    <>
      <DatepickerWrapper error={pickerConfig.error || !!externalError}>
        <DateRangePicker
          {...defaultInputProps}
          startDate={dates.startDate}
          endDate={dates.endDate}
          focusedInput={pickerConfig.focusedOn}
          startDateId="start-id"
          endDateId="end-id"
        />
      </DatepickerWrapper>
      {renderError()}
    </>
  );
};

// -- STYLES
const DatepickerWrapper = styled.div<{ error: boolean }>`
  .SingleDatePickerInput {
    ${(props) =>
      props.error &&
      `
          box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
          border: 1px solid ${theme.colors.notificationRed};
    `};
  }
  input::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;
const Arrow = styled.div`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  top: 31px;
  cursor: pointer;
`;
const LeftArrow = styled(Arrow as any)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 35px;
`;
const RightArrow = styled(Arrow as any)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 35px;
`;

export default memo(RangePicker);
