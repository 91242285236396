import React, { useState, FC, useMemo } from "react";
import styled from "styled-components";
import moment from "moment";
import { Box, Flex } from "@rebass/grid";
import { Field, Form, FormRenderProps } from "react-final-form";

import validators from "utils/validators";
import formatters from "utils/formatters";
import { dateFormat } from "utils/dates";
import { toLocaleNumber } from "utils/numbers";
import { valueOrNA } from "utils/valueOrNA";

import {
  ageLimitsIosOptions,
  ratingsOptions,
} from "components/forms/data/data";

import { IosStaticInfoData } from "../constants/iosStaticInfoData";
import { Option } from "components/forms/SelectInput/SelectInput";
import { SingleReview } from "api/models/reviews/singleReviews";
import { Platform } from "api/models";

import theme from "components/themes";

import { UserPermissions } from "v2/api/types";

import {
  DatePicker,
  SelectInput,
  TextArea,
  TextInput,
  NumberInput,
} from "components/forms";
import FormSection from "components/forms/FormSection";
import { DividerHorizontal } from "components";
import { HeaderWithEdit } from "./HeaderWithEdit";
import { DetailCard } from "components/application/forms/components/DetailCard";
import { FormField, Label } from "./StyledComponents";
import { ApplicationReviewsStaticPage } from "./ApplicationReviewsStaticPage";

// -- TYPES
interface ApplicationIosStaticInfoPageProps {
  data: IosStaticInfoData;
  onAppDetailsSubmit: (values: object) => any;
  onWhatsNewSubmit: (values: object) => any;
  categories: Option[];
  reviews: SingleReview[];
  applicationId: string;
}

// -- COMPONENT
const ApplicationIosStaticInfoPage: FC<ApplicationIosStaticInfoPageProps> = ({
  data,
  onAppDetailsSubmit,
  onWhatsNewSubmit,
  categories,
  reviews,
  applicationId,
}) => {
  const [isDetailsEditing, setDetailsEditing] = useState(false);
  const [isWhatsNewEditing, setWhatsNewEditing] = useState(false);

  const handleFormSubmit = (
    submitFunc: (values: object) => boolean,
    setIsEditedSection: any,
  ) => async (values: any) => {
    const formatedValues = {
      ...values,
      category: values.category ? Number(values.category) : null,
    };

    const isValid = await submitFunc(formatedValues);
    isValid && setIsEditedSection(false);
  };

  const initialValue = useMemo(
    () => ({
      ...data,
      category: data.category?.id ? String(data.category.id) : null,
    }),
    [data],
  );

  return (
    <>
      <Form
        onSubmit={handleFormSubmit(onAppDetailsSubmit, setDetailsEditing)}
        validate={(values: any) => {
          const errors: any = {};
          if (values.ranking) {
            if (values.ranking < 1) {
              errors.ranking = "Value is too low";
            } else if (values.ranking > 999) {
              errors.ranking = "Value is too high";
            }
          }

          return errors;
        }}
        initialValues={initialValue}
      >
        {({ handleSubmit, form }: FormRenderProps) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormSection>
              <HeaderWithEdit
                title="App Details"
                editStatus={isDetailsEditing}
                setEditStatus={setDetailsEditing}
                form={form}
                editRequiredPermissions={[
                  UserPermissions.updateAppPlatformProfile,
                ]}
              />
              <Flex my={30} justifyContent="space-between">
                <Box width={1 / 2}>
                  {isDetailsEditing ? (
                    <Field
                      name="publisher"
                      validate={validators.required}
                      formatOnBlur
                      format={formatters.trim}
                    >
                      {({ input, meta }) => {
                        return (
                          <StyledCard>
                            <Label>Publisher</Label>
                            <TextInput
                              type="text"
                              placeholder="Enter Developer Name"
                              maxLength={30}
                              error={meta.touched && meta.error}
                              {...input}
                              onChange={(event: any) =>
                                input.onChange(event.target.value)
                              }
                            />
                          </StyledCard>
                        );
                      }}
                    </Field>
                  ) : (
                    <>
                      <Label>Publisher</Label>
                      <FormField>{data.publisher}</FormField>
                    </>
                  )}
                </Box>
              </Flex>

              <DividerHorizontal />

              <Flex my={30} justifyContent="space-between">
                <Box width={3 / 10}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field
                        name="rating"
                        validate={validators.composeValidators(
                          false,
                          validators.required,
                        )}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Rating</Label>
                              <SelectInput
                                options={ratingsOptions}
                                valid={!(meta.touched && meta.error)}
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(value: any) => input.onChange(value)}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Rating</Label>
                        <FormField>{data.rating}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field
                        name="numberOfRatings"
                        validate={validators.composeValidators(
                          false,
                          validators.required,
                          validators.minValue(1),
                          validators.maxValue(99999999),
                        )}
                        formatOnBlur
                        format={formatters.trimNumber}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Number of Ratings</Label>
                              <TextInput
                                type="text"
                                placeholder="E.g. 12345"
                                maxLength={8}
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(event: any) => {
                                  const { value } = event.target;
                                  if (isNaN(value)) {
                                    return false;
                                  }

                                  input.onChange(value);
                                }}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Number of Ratings</Label>
                        {/* TODO:check why its types as string */}
                        <FormField>
                          {toLocaleNumber(Number(data.numberOfRatings))}
                        </FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field
                        name="ageLimit"
                        validate={validators.composeValidators(
                          false,
                          validators.required,
                        )}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Age Rating</Label>
                              <SelectInput
                                options={ageLimitsIosOptions}
                                valid={!(meta.touched && meta.error)}
                                error={meta.touched && meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Age Rating</Label>
                        <FormField>{data.ageLimit}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex mb={30} justifyContent="space-between">
                <Box width={3 / 10}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field
                        name="size"
                        validate={validators.composeValidators(
                          false,
                          validators.required,
                          validators.minValue(1),
                          validators.maxValue(9999.9),
                        )}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Size (MB)</Label>
                              <NumberInput
                                decimalSpaces={1}
                                placeholder="E.g. 123.33"
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(size: number | null) =>
                                  input.onChange(size)
                                }
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Size (MB)</Label>
                        {/* TODO: check why number of ratings are string typed */}
                        <FormField>
                          {toLocaleNumber(Number(data.size))}
                        </FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field
                        name="category"
                        formatOnBlur
                        format={formatters.trim}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Category (Optional)</Label>
                              <SelectInput
                                isClearable
                                error={meta.error}
                                valid={!meta.error}
                                options={categories}
                                {...input}
                                onChange={(val: any) => {
                                  if (val) {
                                    return input.onChange(val);
                                  }

                                  form.change("category", null);
                                  form.change("ranking", null);
                                }}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Category (Optional)</Label>
                        <FormField>{valueOrNA(data.category?.name)}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={3 / 10}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field name="ranking">
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Ranking (Optional)</Label>
                              <NumberInput
                                disabled={!form.getState().values.category}
                                placeholder="Enter Ranking"
                                error={meta.error}
                                {...input}
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Ranking (Optional)</Label>
                        {/* TODO: check why ranking is typed as string */}
                        <FormField>
                          {valueOrNA(
                            data.ranking
                              ? toLocaleNumber(Number(data.ranking))
                              : undefined,
                          )}
                        </FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex mb={30} justifyContent="space-between">
                <Box width={3 / 10}>
                  <StyledCard>
                    {isDetailsEditing ? (
                      <Field
                        name="copyright"
                        formatOnBlur
                        format={formatters.trim}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>Copyright (Optional)</Label>
                              <TextInput
                                type="text"
                                maxLength={30}
                                placeholder="E.g. 2020 Company Name"
                                error={meta.error}
                                {...input}
                                onChange={(event: any) =>
                                  input.onChange(event.target.value || null)
                                }
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Copyright (Optional)</Label>
                        <FormField>{valueOrNA(data.copyright)}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
            </FormSection>
          </form>
        )}
      </Form>

      <Form
        onSubmit={handleFormSubmit(onWhatsNewSubmit, setWhatsNewEditing)}
        validate={(values: any) => {
          const errors: any = {};
          if (!!values.releaseNote && values.releaseNote.length > 4000) {
            errors.releaseNote =
              "App field exceeds the maximum 4000 characters limit";
          }

          return errors;
        }}
        initialValues={data}
      >
        {({ handleSubmit, form }: FormRenderProps) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormSection>
              <HeaderWithEdit
                title="What's New (Optional)"
                editStatus={isWhatsNewEditing}
                setEditStatus={setWhatsNewEditing}
                form={form}
                editRequiredPermissions={[
                  UserPermissions.updateAppPlatformProfile,
                ]}
              />

              <Flex my={30} justifyContent="space-between">
                <Box width={1 / 2} mr={32}>
                  <StyledCard>
                    {isWhatsNewEditing ? (
                      <Field
                        name="appVersion"
                        formatOnBlur
                        format={formatters.trim}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>App Version (Optional)</Label>
                              <TextInput
                                type="text"
                                error={meta.touched && meta.error}
                                placeholder="E.g. 1.0.13"
                                maxLength={8}
                                {...input}
                                onChange={(event: any) =>
                                  input.onChange(event.target.value)
                                }
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>App Version (Optional)</Label>
                        <FormField>{valueOrNA(data.appVersion)}</FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>

                <Box width={1 / 2}>
                  <StyledCard>
                    {isWhatsNewEditing ? (
                      <Field
                        name="releaseDate"
                        formatOnBlur
                        format={formatters.trim}
                        validate={validators.isDate}
                      >
                        {({ input, meta }) => {
                          return (
                            <div>
                              <Label style={{ marginBottom: "21px" }}>
                                Release Date (Optional)
                              </Label>
                              <DatePicker
                                id="singleDatePicker"
                                onFocusChange={(focused) =>
                                  focused ? input.onFocus() : input.onBlur()
                                }
                                initialDate={input.value}
                                externalError={meta.touched && meta.error}
                                {...input}
                                readOnly={false}
                                allowClear={false}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>Release Date (Optional)</Label>
                        <FormField>
                          {valueOrNA(
                            data.releaseDate
                              ? moment(data.releaseDate, "DD-MM-YYYY").format(
                                  dateFormat,
                                )
                              : undefined,
                          )}
                        </FormField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>

              <Flex my={30} justifyContent="space-between">
                <Box width={1}>
                  <StyledCard>
                    {isWhatsNewEditing ? (
                      <Field
                        name="releaseNote"
                        formatOnBlur
                        format={formatters.trim}
                      >
                        {({ input, meta }) => {
                          return (
                            <StyledCard>
                              <Label>What's New Description (Optional)</Label>
                              <TextArea
                                type="text"
                                placeholder="Start typing here…"
                                maxLength={4000}
                                error={meta.touched && meta.error}
                                {...input}
                                onChange={(event: any) =>
                                  input.onChange(event.target.value)
                                }
                              />
                            </StyledCard>
                          );
                        }}
                      </Field>
                    ) : (
                      <>
                        <Label>What's New Description (Optional)</Label>
                        <TextField>{valueOrNA(data.releaseNote)}</TextField>
                      </>
                    )}
                  </StyledCard>
                </Box>
              </Flex>
            </FormSection>
          </form>
        )}
      </Form>

      <ApplicationReviewsStaticPage
        reviews={reviews}
        platform={Platform.ios}
        applicationId={applicationId}
      />
    </>
  );
};

// -- STYLED
const TextField = styled.p`
  white-space: pre-wrap;
  word-break: break-all;
`;

const StyledCard = styled(DetailCard)`
  color: ${theme.colors.white};

  font-size: 16px;
`;

export default ApplicationIosStaticInfoPage;
