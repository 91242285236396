import { toLocaleNumber } from "./numbers";
import { valueOrNA } from "./valueOrNA";

export function conversionRateLift(
  controlConversionRate: number,
  conversionRate: number,
) {
  if (!conversionRate) {
    return valueOrNA(null);
  }
  if (controlConversionRate === 0) {
    return 0;
  }
  return Math.max(
    0,
    (conversionRate - controlConversionRate) / controlConversionRate,
  );
}

export function getTrafficIndicator(
  phaseTraffic: number,
  trafficNeeded: string,
) {
  if (!trafficNeeded) {
    return `Traffic: ${toLocaleNumber(phaseTraffic)}`;
  }

  const [min, max] = trafficNeeded
    .split("-")
    .map((stringNumber) => toLocaleNumber(Number(stringNumber)));

  return `Traffic: ${toLocaleNumber(
    phaseTraffic,
  )} received out of ~${min}-${max} visits needed`;
}

export const getCreativesTestingQueuedFormat = (
  creativesCurrentlyTesting: number,
  creativesQueued: number,
): string | null => {
  if (!creativesQueued && !creativesCurrentlyTesting) {
    return null;
  }
  return `${toLocaleNumber(creativesCurrentlyTesting)} / ${toLocaleNumber(
    creativesQueued,
  )}`;
};
