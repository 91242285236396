import React from "react";

import { ApplicationListing } from "api";

type Nullable<T> = T | null;

interface ContextValue {
  current: Nullable<ApplicationListing> | null;
  setCurrent: (application: Nullable<ApplicationListing>) => void;
}

const defaultValue: ContextValue = {
  current: null,
  setCurrent: (application: Nullable<ApplicationListing>) => {},
};

export default React.createContext(defaultValue);
