import React from "react";
import styled from "styled-components";

import themes from "components/themes";
import { LegendElement } from "api/models";

import { isArray, isFunction } from "lodash";
import { Tooltip } from "components";

// -- TYPES
interface ChartLegendProps {
  onDataVisibleChange: (dataIndex: string) => void;
  elements: LegendElement[];
  disabledElements: string[];
}

interface LegendMarkerProps {
  color: string;
  isDisabled: boolean;
  isReferenceLine?: boolean;
}

// -- COMPONENT
const ChartLegend: React.FC<ChartLegendProps> = ({
  onDataVisibleChange,
  elements,
  disabledElements = [],
}) => {
  if (!isArray(elements)) {
    return null;
  }

  const handleOnClick = (dataIndex: string) => () =>
    isFunction(onDataVisibleChange) && onDataVisibleChange(dataIndex);

  const renderLegendItems = () =>
    [...elements]
      .sort((a, b) => a.legendOrderIndex - b.legendOrderIndex)
      .map(({ color, label, dataIndex, isReferenceLine, legendTooltip }) => {
        const isDisabled = disabledElements.includes(dataIndex);
        const key = `legend-element-${label}-${color}`;

        const itemElement = (
          <LegendItem onClick={handleOnClick(dataIndex)} key={key}>
            <LegendMarker
              color={color}
              isDisabled={isDisabled}
              isReferenceLine={isReferenceLine}
            />
            {label}
          </LegendItem>
        );

        return legendTooltip ? (
          <Tooltip
            key={key}
            children={itemElement}
            content={legendTooltip}
            placement="top"
          />
        ) : (
          itemElement
        );
      });

  return <LegendWrapper>{renderLegendItems()}</LegendWrapper>;
};

// -- STYlES
const LegendWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 21px;

  > span:not(:first-of-type) {
    margin-left: 15px;
  }
`;
const LegendItem = styled.span`
  display: flex;
  align-items: center;
  color: ${themes.colors.lightGrey};
  cursor: pointer;
`;
const LegendMarker = styled.div<LegendMarkerProps>`
  width: 13px;
  height: 13px;

  margin-right: 8px;

  background-color: ${(props) =>
    props.isDisabled ? "rgba(0, 0, 0, 0)" : props.color};

  border: 2px solid ${(props: any) => props.color};
  border-radius: 50%;

  ${(props) =>
    props.isReferenceLine &&
    `
    width: 2px;
    height: 13px;
    border-radius: 0;
  `}
`;

export default ChartLegend;
