import { styled } from "@mui/system";

export const TdComponent = styled("td")`
  background-color: #1a1a32;

  font-size: 16px;
  letter-spacing: 0.6px;
  word-break: break-all;

  color: ${(props: any) => props.theme.colors.white};

  padding: 15px 20px 15px 20px;
`;
