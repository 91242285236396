import * as React from "react";
import { useMutation } from "@apollo/client";
import {
  Field,
  FieldInputProps,
  Form,
  FormRenderProps,
} from "react-final-form";
import { styled } from "@mui/system";
import validators from "utils/validators";

import { CALCULATE_COST } from "api/mutations/calculateCost";

import {
  FormFooter,
  FormLabel,
  FormSection,
  NumberInput,
} from "components/forms";
import { CostPlannerFormData } from "../constants/costPlannerFormData";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
import { Box, Flex } from "@rebass/grid";
import { convertToCurrency } from "utils/numbers";

// -- COMPONENT
export function CostPlannerForm(): JSX.Element {
  const [estimatedCost, setEstimatedCost] = React.useState<null | {
    higherPrice: number;
    lowerPrice: number;
  }>(null);
  const [isFormEdited, setIsFormEdited] = React.useState<boolean>(false);

  const [calculateCost] = useMutation(CALCULATE_COST);

  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();
  const handleError = useRequestErrorHandler();

  const initialValues: CostPlannerFormData = {
    cpc: 0.25,
    ecr: 15,
  };

  const variationFieldsList = [
    {
      fieldLabel: "Icon Variations",
      fieldName: "variations.icons",
    },
    {
      fieldLabel: "Feature Graphic Variations",
      fieldName: "variations.featureGraphics",
    },
    {
      fieldLabel: "Promo Art Variations",
      fieldName: "variations.promoArts",
    },
    {
      fieldLabel: "Video Variations",
      fieldName: "variations.videos",
    },
    {
      fieldLabel: "Screenshot Variations",
      fieldName: "variations.screenshots",
    },
    {
      fieldLabel: "Title Variations",
      fieldName: "variations.titles",
    },
    {
      fieldLabel: "Subtitle Variations",
      fieldName: "variations.subtitles",
    },
    {
      fieldLabel: "Description Variations",
      fieldName: "variations.descriptions",
    },
  ];

  const onSubmit = async (values: any) => {
    setFullPageLoader(true);

    const { ecr, cpc, variations } = values as CostPlannerFormData;

    const numberOfVariations = variations
      ? Object.values(variations).reduce((prev: number[], current) => {
          if (!current || current < 2) {
            return prev;
          }

          return [...prev, current];
        }, [])
      : [];

    const options = {
      variables: {
        estimateConversionRate: ecr / 100,
        costPerClick: cpc,
        numberOfVariationsPerAssetType: numberOfVariations,
      },
    };

    const responseResult = await handleError(calculateCost(options));
    setEstimatedCost(responseResult?.data.calculateCost.cost || null);
    setIsFormEdited(false);
    setFullPageLoader(false);
  };

  const renderVariationFields = () =>
    variationFieldsList.map((singleVariationField) => (
      <Box width={2 / 5} key={singleVariationField.fieldName}>
        <Field
          name={singleVariationField.fieldName}
          validate={validators.composeValidators(
            true,
            validators.minValue(0),
            validators.maxValue(9),
          )}
        >
          {({ input, meta }) => {
            return (
              <div>
                <FormLabel
                  name={singleVariationField.fieldName}
                  spacing={false}
                >
                  {singleVariationField.fieldLabel}
                </FormLabel>
                <NumberInput
                  error={meta.error}
                  {...input}
                  onChange={handleEdit(input)}
                />
              </div>
            );
          }}
        </Field>
      </Box>
    ));

  const renderEstimatedCost = (): string | null => {
    if (isFormEdited || !estimatedCost) {
      return null;
    }

    const { higherPrice, lowerPrice } = estimatedCost;

    return higherPrice && lowerPrice
      ? `${convertToCurrency(lowerPrice)} - ${convertToCurrency(higherPrice)}`
      : "N/A";
  };

  const handleEdit = (input: FieldInputProps<any>) => (value: any) => {
    !isFormEdited && setIsFormEdited(true);
    input.onChange(value);
  };

  return (
    <>
      {fullPageLoader}
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, form }: FormRenderProps) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormSection>
              <Flex>
                <ControlsBox mr={32}>
                  <Field
                    name="ecr"
                    validate={validators.composeValidators(
                      false,
                      validators.required,
                      validators.minValue(1),
                      validators.maxValue(99),
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div>
                          <ControlLabel name="ecr" spacing={false}>
                            Estimated Conversion Rate
                          </ControlLabel>
                          <StyledNumberInput
                            placeholder="E.g. 123"
                            error={meta.touched && meta.error}
                            {...input}
                            onChange={handleEdit(input)}
                            inputSufix={"%"}
                          />
                        </div>
                      );
                    }}
                  </Field>
                </ControlsBox>
                <ControlsBox>
                  <Field
                    name="cpc"
                    validate={validators.composeValidators(
                      false,
                      validators.required,
                      validators.minFloatValue(0.01),
                      validators.maxFloatValue(9.99),
                    )}
                  >
                    {({ input, meta }) => {
                      return (
                        <div>
                          <ControlLabel name="cpc" spacing={false}>
                            Estimated CPC
                          </ControlLabel>
                          <StyledNumberInput
                            decimalSpaces={2}
                            placeholder="E.g. 123"
                            error={meta.touched && meta.error}
                            {...input}
                            onChange={handleEdit(input)}
                            width="100px"
                            inputSufix={"$"}
                          />
                        </div>
                      );
                    }}
                  </Field>
                </ControlsBox>
              </Flex>

              <VariationWrapper>{renderVariationFields()}</VariationWrapper>

              <EstimatedCost>
                Estimated Cost to Complete All Testing (80-90% Confidence):{" "}
                {renderEstimatedCost()}
              </EstimatedCost>
            </FormSection>

            <StyledFormFooter
              cancelLabel="Reset"
              submitLabel="Calculate"
              onCancel={form.reset}
            />
          </form>
        )}
      </Form>
    </>
  );
}

// -- STYLED
const VariationWrapper = styled(Flex)`
  flex-wrap: wrap;

  > div {
    padding-top: 10px;
  }

  > div:nth-child(odd) {
    padding-right: 16px;
  }

  > div:nth-child(even) {
    padding-left: 16px;
  }
`;

const StyledFormFooter = styled(FormFooter)`
  margin-top: 0;
`;

const EstimatedCost = styled("div")`
  margin-top: 20px;

  color: ${(props: any) => props.theme.colors.white};
`;

const StyledNumberInput = styled(NumberInput)`
  width: 7.3125rem;
`;

const ControlsBox = styled(Box)`
  width: min-content;

  div {
    text-align: left;
  }

  &:last-of-type {
    width: unset;
  }
`;

const ControlLabel = styled(FormLabel)`
  white-space: nowrap;
`;
