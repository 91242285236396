import React from "react";
import { PercentageChart } from "./index";
import { isNumber } from "lodash";

// -- TYPES
interface Props {
  value: number | string;
}

// -- COMPONENT
function PercentageChartWithData({ value }: Props) {
  return (
    <PercentageChart
      value={isNumber(value) ? value : 0}
      label={!isNumber(value) ? value : undefined}
    />
  );
}

export default PercentageChartWithData;
