import React, { CSSProperties } from "react";
import styled, { keyframes } from "styled-components";

interface Props {
  className?: string;
  style?: CSSProperties;
  theme?: "normal" | "optional";
}

function Spinner({ className = "", style = {}, theme = "normal" }: Props) {
  return (
    <Container className={className} style={style}>
      <CSSSpinner>
        <circle cx="50" cy="50" r="30" />
        <circle cx="50" cy="50" r="30" />
      </CSSSpinner>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const strokeAnimation = keyframes`
  0% {
    stroke-dashoffset: 185px;
  }

  25% {
    opacity: 1;
  }

  50% {
    stroke-dashoffset: 92.5px;
  }

  95% {
    stroke-dashoffset: 185px;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 185px;
  }
`;

const svgAnimation = keyframes`
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(270deg);
  }
`;

const CSSSpinner = styled.svg`
  overflow: visible;
  width: 100px;
  height: 100px;
  animation: ${svgAnimation} 0.6s linear infinite;

  circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #06001b;
    stroke-width: 6;
    stroke-linecap: round;
  }

  circle:last-of-type {
    stroke-dasharray: 185px;
    stroke-dashoffset: 185px;
    stroke: #b100ec;
    opacity: 0;
    animation: ${strokeAnimation} 1.2s linear infinite;
    stroke-width: 7;
  }
`;

export default Spinner;
