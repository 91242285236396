import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router";

import { SET_USER_PASSWORD, CHECK_PASSWORD_TOKEN } from "api";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { Spinner, Error } from "components";
import UpdateForgottenPasswordPage, {
  FormData,
} from "./UpdateForgottenPasswordPage";
import UpdateForgottenPasswordErrorPage from "./UpdateForgottenPasswordErrorPage";
import { useSignIn } from "v2/hooks/useSignIn";

interface Props extends RouteComponentProps<{ token: string; email: string }> {}

function UpdateForgottenPasswordContainer({ match }: Props) {
  const variables = {
    token: match.params.token,
    title: "Reset password",
    submitButtonText: "Sign In",
  };

  const [updateForgottenPassword] = useMutation(SET_USER_PASSWORD);
  const { data, error, loading } = useQuery(CHECK_PASSWORD_TOKEN, {
    variables,
  });

  const handleError = useRequestErrorHandler();
  const signIn = useSignIn();

  const handleSubmit = async (formData: FormData) => {
    const { password } = formData;

    const updateForgottenPasswordResponse = await handleError(
      updateForgottenPassword({
        variables: {
          ...variables,
          password,
        },
      }),
    );

    if (!updateForgottenPasswordResponse.setUserPassword) {
      return;
    }

    await signIn({
      email: updateForgottenPasswordResponse.setUserPassword?.emailAddress,
      password,
    });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  if (!data.validateUserToken) {
    return <UpdateForgottenPasswordErrorPage />;
  }

  return (
    <UpdateForgottenPasswordPage
      onSubmit={handleSubmit}
      title={variables.title}
      submitButtonText={variables.submitButtonText}
    />
  );
}

export default UpdateForgottenPasswordContainer;
