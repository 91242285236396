import React from "react";
import styled from "styled-components";
import { Flex } from "@rebass/grid";

import themes from "components/themes";
import { Icon, Icons } from "components";

interface Props {
  title: string;
  children?: React.ReactNode;
  disabled?: boolean;
  rootProps?: any;
}

function AssetInput({
  title,
  children,
  disabled = false,
  rootProps = {},
}: Props) {
  return (
    <EmptyBox {...rootProps} disabled={disabled}>
      {!!children && children}
      <Flex alignItems="center">
        <Icon icon={Icons.faPlusCircle} color={themes.colors.purple} />
        <Placeholder>{title}</Placeholder>
      </Flex>
    </EmptyBox>
  );
}

const EmptyBox = styled.div<{ disabled: boolean }>`
  padding: 0 15px;
  height: 44px;
  border-radius: 8px;
  border: 1px dashed ${themes.colors.borderGrey};
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props: any) =>
    props.disabled ? "not-allowed !important" : "pointer"};

  :hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
  }

  :focus {
    outline: 0;
  }

  svg {
    margin: 0 10px 0 0;
  }
`;

const Placeholder = styled.div`
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.53px;
  user-select: none;
`;

export default AssetInput;
