import { ApolloError } from "@apollo/client";
import { ErrorMap } from "./constants/errorMap";
import i18n from "../../locales/config";

export const errorMessageHandler = (
  err: ApolloError | string,
  additionalErrorProps: any = {},
): string => {
  if (typeof err === "string") {
    return err;
  }

  const { graphQLErrors } = err;

  if (!graphQLErrors || !graphQLErrors.length) {
    return "Something went wrong";
  }

  const errorsMessagesList = graphQLErrors.map((singleGraphqlError) => {
    const { extensions, message } = singleGraphqlError;
    let code: string | number | undefined = extensions?.code;
    let code2: string | null = null;

    if (!isNaN(Number(code))) {
      if (extensions?.exception?.path?.[0]) {
        const translation = i18n.t(
          `${extensions?.exception?.path?.[0]}.${code}`,
          { ns: "graphQLErrors", defaultValue: "", ...additionalErrorProps },
        );
        if (translation) return translation;

        code2 = `CODE-${extensions?.exception?.path?.[0]}-${code}`;
      }
      code = `CODE-${code}`;
    }

    return (
      ErrorMap[code2 as keyof typeof ErrorMap] ||
      ErrorMap[code as keyof typeof ErrorMap] ||
      ErrorMap[message as keyof typeof ErrorMap] ||
      ErrorMap.UNKNOW_ERROR
    );
  });

  return Array.from(new Set(errorsMessagesList)).join(". ");
};
