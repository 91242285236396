import { assets } from "@upptic/module-ui";
import HubIcon from "@mui/icons-material/Hub";

import { UserPermissions } from "../../../v2/api/types";
export const moduleNavigationElements = [
  {
    title: "Hub",
    route: "/:clientCode/hub",
    iconComponent: HubIcon,
    permissions: [UserPermissions.readHubOverview],
    menuType: "hubMenu",
    titleElement: true,
    supportsMobile: true,
    rowIndex: 0,
  },
  {
    title: "Growth",
    route: "/:clientCode/ua",
    iconComponent: assets.GrowthLogo,
    permissions: [UserPermissions.moduleUA],
    menuType: "uaMenu",
    titleElement: true,
    supportsMobile: true,
    rowIndex: 1,
  },
  {
    title: "Creative",
    route: "/:clientCode/creative",
    iconComponent: assets.CreativeLogo,
    permissions: [UserPermissions.moduleCreative],
    menuType: "creativeMenu",
    titleElement: true,
    rowIndex: 2,
  },
  {
    title: "Social",
    route: "/:clientCode/social",
    iconComponent: assets.SocialLogo,
    permissions: [UserPermissions.moduleSocial],
    menuType: "socialMenu",
    titleElement: true,
    supportsMobile: true,
    rowIndex: 3,
  },
  {
    title: "Upptic Admin",
    route: "/:clientCode/upptic",
    iconComponent: assets.AdminLogo,
    permissions: [UserPermissions.moduleUpptic],
    menuType: "uppticMenu",
    titleElement: true,
    supportsMobile: true,
    rowIndex: 4,
  },
  {
    title: "ASO",
    route: "/applications",
    iconComponent: assets.ASOLogo,
    permissions: [UserPermissions.moduleASO],
    menuType: "applicationListing",
    rowIndex: 5,
  },
];
