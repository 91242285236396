import React, { useState } from "react";
import { RouteComponentProps } from "react-router";

import ApplicationControlUpdateContainer from "../components/ApplicationControlUpdateContainer";
import { UPDATE_CONTROL_PROMO_ART } from "api/mutations/assets";
import { GET_APPLICATION_PROMO_ARTS_ONLY } from "api/queries/application";
import { PromoArt } from "api/models";

import PromoArtStep, {
  StepData,
} from "components/application/forms/PromoArtStep";
import { useQuery } from "@apollo/client";
import { Spinner, Error } from "components";

interface Props extends RouteComponentProps<{ id: string }> {}

function ApplicationControlPromoArtsContainer(props: Props): JSX.Element {
  const defaultState: StepData = {
    control: null,
    assets: [],
  };
  const [formData, setFormData] = useState(defaultState);

  const applicationId = props.match.params.id;

  const { data, error, loading } = useQuery<{ promoArts: PromoArt[] }>(
    GET_APPLICATION_PROMO_ARTS_ONLY,
    {
      variables: { applicationId },
    },
  );

  const isControlPromoArtBlank = Boolean(
    data?.promoArts.find(
      (singlePromo) => singlePromo.isControl && !singlePromo.file.url,
    ),
  );

  const handleOnChange = (changeData: any) =>
    setFormData({
      ...formData,
      ...changeData,
    });

  const additionalRefetchQueries = React.useMemo(
    () => [
      {
        query: GET_APPLICATION_PROMO_ARTS_ONLY,
        variables: { applicationId },
      },
    ],
    [applicationId],
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <ApplicationControlUpdateContainer
      mutation={UPDATE_CONTROL_PROMO_ART}
      formData={formData}
      additionalRefetchQueries={additionalRefetchQueries}
      {...props}
    >
      <PromoArtStep
        title="Upload New Control Promo Art"
        data={formData}
        disableControl={false}
        onChange={handleOnChange}
        isEdit={false}
        autoControl={true}
        maxUploadAssets={1}
        isUpdateControl={true}
        allowAddBlank={!isControlPromoArtBlank}
      />
    </ApplicationControlUpdateContainer>
  );
}

export default ApplicationControlPromoArtsContainer;
