import React from "react";
import { Box, Flex } from "@rebass/grid";

import Button from "./Button";

interface Props {
  onCancel?: () => void;
  disabled?: boolean;
  submitDisabled?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
}

function FormFooter({
  onCancel,
  disabled,
  submitDisabled,
  submitLabel = "Submit",
  cancelLabel = "Cancel",
  ...rest
}: Props) {
  return (
    <Flex justifyContent="flex-end" mt={60} {...rest}>
      {onCancel && (
        <Box mr={20}>
          <Button disabled={disabled} onClick={onCancel} outline>
            {cancelLabel}
          </Button>
        </Box>
      )}
      <Box>
        <Button disabled={disabled || submitDisabled} type="submit">
          {submitLabel}
        </Button>
      </Box>
    </Flex>
  );
}

export default FormFooter;
