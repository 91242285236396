import React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";

import emptyImage from "assets/images/empty-state-multiple.png";
import arrowImage from "assets/images/arrow.png";

interface Props {
  title: string;
  description: string;
  descriptionFooter?: string | React.ReactNode;
  hintFooter?: any;
}

function UploadHint({
  title,
  description,
  descriptionFooter,
  hintFooter,
}: Props) {
  return (
    <Flex flexDirection="column" alignItems="center" mt={25}>
      <Flex>
        <Box mr={25}>
          <EmptyImage src={emptyImage} alt="" />
        </Box>
        <Box>
          <Flex flexDirection="column" justifyContent="space-evenly">
            <Title>
              {title}
              <ArrowImage src={arrowImage} alt="" />
            </Title>
            <Description>{description}</Description>
            {descriptionFooter && <div>{descriptionFooter}</div>}
          </Flex>
        </Box>
      </Flex>
      <Flex width="400px" mt={35}>
        <HintWrapper>{hintFooter}</HintWrapper>
      </Flex>
    </Flex>
  );
}

const EmptyImage = styled.img`
  width: 125px;
  height: 125px;
`;

const ArrowImage = styled.img`
  position: absolute;
  top: -73px;
  right: -20px;
  width: 16px;
  height: 90px;
`;

const Title = styled.div`
  position: relative;
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.88;
  color: #9d9da8;
`;

const Description = styled.div`
  opacity: 0.6;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: #9d9da8;
  width: 220px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const HintWrapper = styled(Box)`
  width: 100%;
`;

export default UploadHint;
