import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import { useQuery } from "@apollo/client";

import {
  getApplicationFeatureGraphic,
  GetApplicationFeatureGraphicResponse,
} from "api/queries/featureGraphic";
import { updateControlFeatureGraphic } from "api/mutations/featureGraphic";
import { FeatureGraphicData } from "components/application/forms/FeatureGraphic/constants/featureGraphicData";

import FeatureGraphic from "components/application/forms/FeatureGraphic/FeatureGraphic";
import ApplicationControlUpdateContainer from "../components/ApplicationControlUpdateContainer";
import { Spinner, Error } from "components";

interface Props extends RouteComponentProps<{ id: string }> {}

export default function EditControlFeatureGraphic(props: Props): JSX.Element {
  const defaultState: FeatureGraphicData = {
    assets: [],
    control: null,
  };
  const [formData, setFormData] = useState(defaultState);

  const applicationId = props.match.params.id;

  const { data, error, loading } = useQuery<
    GetApplicationFeatureGraphicResponse
  >(getApplicationFeatureGraphic, {
    variables: { applicationId },
  });

  const isControlPromoArtBlank = Boolean(
    data?.featureGraphics.find(
      (singeFG) => singeFG.isControl && !singeFG.file.url,
    ),
  );

  const handleOnChange = (changeData: any) =>
    setFormData({
      ...formData,
      ...changeData,
    });

  const additionalRefetchQueries = React.useMemo(
    () => [
      {
        query: getApplicationFeatureGraphic,
        variables: { applicationId },
      },
    ],
    [applicationId],
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <ApplicationControlUpdateContainer
      mutation={updateControlFeatureGraphic}
      formData={formData}
      additionalRefetchQueries={additionalRefetchQueries}
      {...props}
    >
      <FeatureGraphic
        title="Upload New Control Feature Graphic"
        data={formData}
        disableControl={false}
        onChange={handleOnChange}
        isEdit={false}
        autoControl={true}
        maxUploadAssets={1}
        isUpdateControl={true}
        allowAddBlank={!isControlPromoArtBlank}
      />
    </ApplicationControlUpdateContainer>
  );
}
