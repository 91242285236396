import React, { useState, memo } from "react";
import styled from "styled-components";

import { percentFormat, toLocaleNumber } from "utils/numbers";
import themes from "components/themes";

// --TYPES
interface Props {
  totalVisits: number;
  totalConversions: number;
  conversionRate: number;
}

// -- COMPONENT
const ChartStatistics: React.FC<Props> = ({
  totalVisits,
  totalConversions,
  conversionRate,
}) => {
  const [lastHovered, setLastHovered] = useState(0);

  const handleOnHover = (index: number) => () => setLastHovered(index);

  const statisticsItems = [
    {
      label: "Total Visits",
      value: toLocaleNumber(totalVisits),
    },
    {
      label: "Total Conversions",
      value: toLocaleNumber(totalConversions),
    },
    {
      label: "Conversion Rate",
      value: percentFormat(conversionRate),
    },
  ];

  return (
    <ExperimentResultsWrapper>
      {statisticsItems.map((singleItem, index) => {
        const { label, value } = singleItem;
        return (
          <ExperimentStatisticItem
            key={`experiment-item-${label}`}
            onMouseEnter={handleOnHover(index)}
            isActive={index === lastHovered}
          >
            <p>{value}</p>
            <h3>{label}</h3>
          </ExperimentStatisticItem>
        );
      })}
    </ExperimentResultsWrapper>
  );
};

// -- STYLES
const ExperimentResultsWrapper = styled.div`
  display: flex;
  margin-top: 10px;

  > div:not(:last-child) {
    margin-right: 20px;
  }
`;
const ExperimentStatisticItem = styled.div<{ isActive: boolean }>`
  display: flex;
  width: 16.6%;
  min-width: 180px;
  border-radius: 8px;
  background-color: #26263d;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 30px;

  p {
    font-size: 30px;
    font-weight: bold;
    margin: 0 0 8px 0;
    line-height: 1;
  }

  h3 {
    font-size: 14px;
    color: ${themes.colors.lightGrey};
    margin: 0;
  }

  ${(props) =>
    props.isActive &&
    `
    background:
      linear-gradient(
        242deg,
        ${themes.colors.chartDarkPurple},
        ${themes.colors.chartLightPurple}
      ),
      linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.09),
        rgba(255, 255, 255, 0.09)
      );
  `}
`;

export default memo(ChartStatistics);
