import React, { FC, useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { RouteComponentProps, withRouter } from "react-router";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { createPaymentMethod } from "utils/stripe";

import { ADD_PAYMENT_METHOD, GET_USER_BILLING_INFO } from "api";

import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import {
  ApplicationContext,
  ApplicationUserContext,
  ModalContext,
  useStores,
} from "contexts";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";

import { CreditCardInput } from "components/billing/CreditCardInput";
import { FormFooter } from "components/forms";
import { Title, Section } from "components";

// -- COMPONENT
const PaymentMethod: FC<RouteComponentProps> = ({ history }) => {
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const applicationUserCtx = useContext(ApplicationUserContext);
  const applicationCtx = useContext(ApplicationContext);
  const { userStore } = useStores();

  useEffect(() => {
    applicationCtx.current && applicationCtx.setCurrent(null);
  }, [applicationCtx]);

  const [savePaymentMethod] = useMutation(ADD_PAYMENT_METHOD);

  const setModal = useContext(ModalContext);

  const handleError = useRequestErrorHandler();

  const stripe = useStripe();
  const elements = useElements();

  const onCardInputChange = (isValid: boolean): void => {
    isValid !== canSubmit && setCanSubmit(isValid);
  };

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    setFullPageLoader(true);

    const paymentMethodId = await createPaymentMethod(
      stripe,
      elements,
      setModal,
    );

    const savePaymentMethodResponse = await handleError(
      savePaymentMethod({
        variables: {
          paymentMethodId,
          userId: applicationUserCtx.userId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_BILLING_INFO,
            variables: { clientCode: userStore.clientCode },
          },
        ],
      }),
    );

    savePaymentMethodResponse &&
      history &&
      history.push(authRoutes.USER_BILLING_DETAILS.getUrlWithParams());

    setFullPageLoader(false);
  };

  const redirectToBillingDetails = () => {
    history && history.push(authRoutes.USER_BILLING_DETAILS.getUrlWithParams());
  };

  return (
    <>
      {fullPageLoader}
      <form onSubmit={onFormSubmit}>
        <Title>Add new credit card</Title>
        <Section transparentHeader>
          <CreditCardInput onChange={onCardInputChange} />
        </Section>
        <FormFooter
          onCancel={redirectToBillingDetails}
          submitDisabled={!canSubmit}
        />
      </form>
    </>
  );
};

export const EditPaymentMethodForm = withRouter(PaymentMethod);
