import { flatten } from "lodash";

import { IosCategory } from "api/models";

import { range } from "utils/numbers";

import { Option } from "components/forms/SelectInput/SelectInput";

function transformOptions(optionValue: string) {
  const option: Option = { value: optionValue, label: optionValue };
  return option;
}

const ratings = range(5.0, 1.0, 0.1);

const ageLimitsAndroid = [
  "4+",
  "9+",
  "12+",
  "17+",
  "ESRB Everyone",
  "ESRB Everyone 10+",
  "ESRB Teen",
  "ESRB Mature",
  "ESRB Adults only",
];

const ageLimitsIos = ["4+", "9+", "12+", "17+"];

const amounts = [
  "10K+",
  "50K+",
  "100K+",
  "500K+",
  "1M+",
  "5M+",
  "10M+",
  "50M+",
  "100M+",
];

const androidTags: string[] = [
  "Action",
  "Adventure",
  "Arcade",
  "Board",
  "Card",
  "Casino",
  "Casual",
  "Educational",
  "Music",
  "Puzzle",
  "Racing",
  "Role Playing",
  "Simulation",
  "Sports",
  "Shopping",
  "Strategy",
  "Trivia",
  "Word",
  "Editors' Choice",
  "#1 Top games",
  "#2 Top games",
  "#3 Top games",
  "#4 Top games",
  "#5 Top games",
  "#6 Top games",
  "#7 Top games",
  "#8 Top games",
  "#9 Top games",
  "#1 Top grossing",
  "#2 Top grossing",
  "#3 Top grossing",
  "#4 Top grossing",
  "#5 Top grossing",
  "#6 Top grossing",
  "#7 Top grossing",
  "#8 Top grossing",
  "#9 Top grossing",
  "#1 Top selling",
  "#2 Top selling",
  "#3 Top selling",
  "#4 Top selling",
  "#5 Top selling",
  "#6 Top selling",
  "#7 Top selling",
  "#8 Top selling",
  "#9 Top selling",
  "Trending",
  "#1 Trending",
  "#2 Trending",
  "#3 Trending",
  "#4 Trending",
  "#5 Trending",
  "#6 Trending",
  "#7 Trending",
  "#8 Trending",
  "#9 Trending",
];

const iosCategories = [
  "Action",
  "Adventure",
  "Arcade",
  "Board",
  "Card",
  "Casino",
  "Dice",
  "Educational",
  "Family",
  "Kids",
  "Lifestyle",
  "Music",
  "Puzzle",
  "Racing",
  "Role Playing",
  "Simulation",
  "Sports",
  "Shopping",
  "Strategy",
  "Trivia",
  "Word",
];

export const ratingsOptions = ratings.map((rating) =>
  transformOptions(rating.toString()),
);

export const ageLimitsIosOptions = ageLimitsIos.map((ageLimit) =>
  transformOptions(ageLimit),
);

export const ageLimitsAndroidOptions = ageLimitsAndroid.map((ageLimit) =>
  transformOptions(ageLimit),
);

export const amountOptions = amounts.map((amount) => transformOptions(amount));

export const tagsAndroidOptions = androidTags.map((tag) =>
  transformOptions(tag),
);

export const iosCategoriesOptionsMock = iosCategories.map((category) =>
  transformOptions(category),
);

export const minimumDetectableLift: Option[] = new Array(10)
  .fill("")
  .map((_, index) => {
    const labelAsPercent = index + 1;
    const valueAsNumber = labelAsPercent / 100;

    return { value: String(valueAsNumber), label: `${labelAsPercent} %` };
  });

export const convertCategoriesToOptions = (
  categories: IosCategory[] | undefined,
  parentName?: string,
): Option[] => {
  if (!categories) {
    return [];
  }

  const categoriesList = categories.map((singleCategory) => {
    const { id, name, children } = singleCategory;

    if (Array.isArray(children) && children.length) {
      return convertCategoriesToOptions(children, name);
    }

    return {
      label: parentName ? `${parentName}: ${name}` : name,
      value: String(id),
    };
  });

  return flatten(categoriesList);
};
