import React from "react";
import styled from "styled-components";
import { Field } from "react-final-form";
import { Flex, Box } from "@rebass/grid";

import config from "config";
import { Button, TextInput, TextArea, FormHeader } from "components/forms";
import {
  Icon,
  Icons,
  List,
  ListItem,
  DescriptionCard,
  Tooltip,
} from "components";
import validators from "utils/validators";
import themes from "components/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface StepData {
  value: string;
  control: string;
  values: string[];
}

interface Props {
  title: string;
  inputTitle: string;
  listTitle: string;
  placeholder: string;
  isInputArea: boolean;
  maxLength: number;
  data: StepData;
  disableControl?: boolean;
  onChange: (values: StepData) => void;
  maxUploadAssets?: number;
  titleTooltip?: string;
  validate?: boolean;
}

class ApplicationAssetsString extends React.PureComponent<Props> {
  public render() {
    const {
      title,
      inputTitle,
      maxLength,
      isInputArea,
      listTitle,
      placeholder,
      data,
      onChange,
      disableControl = false,
      maxUploadAssets,
      titleTooltip = "",
      validate = false,
    } = this.props;

    const maxAssets = maxUploadAssets
      ? maxUploadAssets
      : config.maxApplicationAssets;

    const canAdd =
      !!data.value &&
      data.values.length < maxAssets &&
      !data.values.includes(data.value.trim()) &&
      !(validate && validators.androidText(data.value));
    const renderAssetCreate = () => {
      return (
        <NarrowFlex flexWrap="wrap" alignItems="start">
          <Header>
            {inputTitle}
            {titleTooltip && renderTooltip()}
          </Header>
          <Box width={6 / 10}>
            <Field name="name" height="44px">
              {({ input, meta }) => {
                const inputProps = {
                  ...input,
                  maxLength,
                  placeholder,
                  value: data.value,
                  error: validate
                    ? validators.androidText(data.value)
                    : undefined,
                  onChange: (event: any) => {
                    onChange({
                      value: event.target.value,
                      values: data.values,
                      control: data.control,
                    });
                  },
                  onBlur: (event: any) => {
                    onChange({
                      value: data.value.trim(),
                      values: data.values,
                      control: data.control,
                    });
                  },
                } as any;

                const renderInput = () => {
                  if (isInputArea) {
                    return (
                      <div>
                        <TextArea {...inputProps} />
                      </div>
                    );
                  } else {
                    return (
                      <div>
                        <TextInput {...inputProps} />
                      </div>
                    );
                  }
                };

                return data.values.length ? (
                  renderInput()
                ) : (
                  <Field name={title} validate={validators.required}>
                    {(props) => {
                      if (props.meta.touched && props.meta.error) {
                        inputProps.error = "At least one value is required";
                        return renderInput();
                      } else {
                        return renderInput();
                      }
                    }}
                  </Field>
                );
              }}
            </Field>
          </Box>
          <Box width={3 / 10} ml={30}>
            <Button
              onClick={() => {
                onChange({
                  value: "",
                  values: [...data.values, data.value],
                  control: disableControl ? "" : data.control || data.value,
                });
              }}
              disabled={!canAdd}
            >
              <Icon verticalCenter icon={Icons.faPlusCircle} />
              Add Variant
            </Button>
          </Box>
        </NarrowFlex>
      );
    };

    const renderAssetList = () => {
      return (
        <Flex flexWrap="wrap">
          {!!data.values.length && (
            <ListCard title={listTitle}>
              <List>
                {data.values.map((name: string, index: number) => (
                  <ListItem
                    key={`${title}-${index}`}
                    onRemove={() => {
                      const newValues = data.values.filter(
                        (n: string, i: number) => i !== index,
                      );

                      onChange({
                        value: data.value,
                        values: newValues,
                        control:
                          name === data.control ? newValues[0] : data.control,
                      });
                    }}
                    active={name === data.control}
                    activateText="Mark as control"
                    counter={index + 1}
                    onActivate={
                      disableControl
                        ? null
                        : () => {
                            onChange({
                              value: data.value,
                              values: data.values,
                              control: name,
                            });
                          }
                    }
                  >
                    {name}
                  </ListItem>
                ))}
              </List>
            </ListCard>
          )}
        </Flex>
      );
    };

    const renderTooltip = (): React.ReactNode => (
      <Tooltip content={titleTooltip}>
        <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
      </Tooltip>
    );

    return (
      <div>
        <Flex>
          <Box>
            <FormHeader style={{ marginTop: 30 }}>{title}</FormHeader>
          </Box>
        </Flex>
        <Container>
          {renderAssetCreate()}
          {renderAssetList()}
        </Container>
      </div>
    );
  }
}

const Header = styled.h4`
  font-size: 16px;
  color: ${themes.colors.white};
  width: 100%;

  svg {
    margin-left: 10px;
  }
`;

const ListCard = styled(DescriptionCard)`
  margin-top: 60px;
  padding: 20px 15px;
  background: none;
`;

const NarrowFlex = styled(Flex)`
  margin: 0 auto;
  width: 550px;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 454px;
  min-height: 140px;
  border-radius: 8px;
  background-color: ${themes.colors.card};
  padding: 30px;
  margin-bottom: 50px;
`;

export default ApplicationAssetsString;
