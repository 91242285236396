import React, { useContext, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";
import { components, OptionProps, SingleValueProps } from "react-select";
import { PlatformContext } from "@upptic/module-directory";
import { observer } from "mobx-react";
import { SelectInput } from "components/forms";
import { createUrlWithParams } from "v2/utils/url";

const ApplicationSwitcher: React.FC = () => {
  const platformStore = useContext(PlatformContext);
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  useEffect(() => {
    setSelectedOption(platformStore.selectedApplication?.code || null);
  }, [setSelectedOption, platformStore.selectedApplication]);

  const apps: any[] = useMemo(() => {
    const applicationList = [...platformStore.applications];

    if (!selectedOption) return applicationList;

    const selectedOptionFromList = applicationList.find(
      (singleApplication) => singleApplication.code === selectedOption,
    );

    if (!selectedOptionFromList) return applicationList;

    return [
      selectedOptionFromList,
      ...applicationList.filter(({ code }) => code !== selectedOption),
    ];
  }, [platformStore.applications, selectedOption]);

  const onChangeApplication = (code: string) => {
    if (!platformStore.switchApplication(code)) return;

    if (platformStore.currentRoute.path.includes(":applicationCode")) {
      return history.push(
        createUrlWithParams(platformStore.currentRoute.path, {
          ...platformStore.currentRoute.params,
          applicationCode: code,
        }),
      );
    }
  };

  const SingleValue = (props: SingleValueProps<{ [key: string]: any }>) => {
    return (
      <components.SingleValue {...props}>
        {props.data.name}
      </components.SingleValue>
    );
  };

  const Option = (props: OptionProps<{ [key: string]: any }, boolean>) => {
    return (
      <components.Option {...props}>
        <OptionContenWrapper>{props.data.name}</OptionContenWrapper>
      </components.Option>
    );
  };

  if (apps.length === 1) {
    return <ApplicationNameWrapper>{apps[0].name}</ApplicationNameWrapper>;
  }

  if (!apps.length) {
    return null;
  }

  return (
    <Wrapper>
      <SelectInput
        labelKey={"name"}
        valueKey={"code"}
        value={selectedOption}
        options={apps}
        onChange={onChangeApplication}
        components={{ Option, SingleValue }}
      />
    </Wrapper>
  );
};

// -- STYLED
const Wrapper = styled("div")`
  position: relative;
  width: 200px;
`;

const OptionContenWrapper = styled("div")`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ApplicationNameWrapper = styled("div")`
  overflow: hidden;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props: any) => props.theme.colors.lightGrey};
`;

export default observer(ApplicationSwitcher);
