import React, { PureComponent, ReactNode } from "react";
import styled from "styled-components";
import "@upptic/react-dates/initialize";
import moment from "moment";
import { isBoolean } from "lodash";
import { SingleDatePicker } from "@upptic/react-dates";

import { dateFormat } from "utils/dates";

import FormError from "./FormError";
import theme from "components/themes";

interface DatePickerStateType {
  currentDate: moment.Moment | null;
  isVisible: boolean;
  error: boolean;
}

interface DatePickerPropsType {
  initialDate?: string;
  onChange: (date: string | null) => void;
  onFocusChange?: (focused: boolean | null) => void;
  id: string;
  externalError?: string;
  allowClear?: boolean;
  disabled?: boolean;
  minDay?: moment.Moment;
  maxDay?: moment.Moment;
  readOnly?: boolean;
}

class DatePicker extends PureComponent<
  DatePickerPropsType,
  DatePickerStateType
> {
  constructor(props: DatePickerPropsType) {
    super(props);
    let currentDate: moment.Moment | null = moment(
      props.initialDate || "",
      "DD-MM-YYYY",
    );

    if (!currentDate.isValid()) {
      currentDate = null;
    }

    this.state = {
      currentDate,
      isVisible: false,
      error: false,
    };
  }
  private changeVisibility = ({
    focused,
  }: {
    focused: boolean | null;
  }): void => {
    const { currentDate } = this.state;
    const isValid = !currentDate || currentDate.isValid();
    if (!focused && currentDate === null) {
      this.setState({ error: !currentDate });
    }
    this.setState({
      isVisible: !!focused,
      error: !isValid,
    });
    if (this.props.onFocusChange) {
      this.props.onFocusChange(focused);
    }
  };

  private onDateChange = (
    selectedDate: moment.Moment | null | undefined,
  ): void => {
    if (selectedDate) {
      return this.setState({ currentDate: selectedDate }, () => {
        const { currentDate } = this.state;
        this.props.onChange(
          (currentDate as moment.Moment).format("DD-MM-YYYY"),
        );
        this.setState({
          isVisible: false,
        });
      });
    }
    if (selectedDate === undefined) {
      this.setState({ currentDate: null, error: false }, () => {
        this.props.onChange(null);
      });
    } else {
      this.setState({ currentDate: null, error: false }, () => {
        this.props.onChange("");
      });
    }
  };

  private renderCustomButton = (buttonDirection: "left" | "right") => (
    buttonProps: any,
  ) => {
    const buttonsType = {
      left: LeftArrow,
      right: RightArrow,
    };

    const Component = buttonsType[buttonDirection];

    return <Component {...buttonProps} />;
  };

  private isDayBlocked = (day: moment.Moment): boolean => {
    const { minDay, maxDay } = this.props;

    const isBefore = minDay && day.isBefore(minDay, "day");
    const isAfter = maxDay && day.isAfter(maxDay, "day");

    return Boolean(isBefore || isAfter);
  };

  private defaultInputProps = {
    onFocusChange: this.changeVisibility,
    onDateChange: this.onDateChange,
    hideKeyboardShortcutsPanel: true,
    displayFormat: dateFormat,
    numberOfMonths: 1,
    renderNavPrevButton: this.renderCustomButton("left"),
    renderNavNextButton: this.renderCustomButton("right"),
    keepOpenOnDateSelect: true,
    placeholder: dateFormat,
    isOutsideRange: () => false,
    readOnly: isBoolean(this.props.readOnly) ? this.props.readOnly : true,
    showClearDate: isBoolean(this.props.allowClear)
      ? this.props.allowClear
      : true,
    isDayBlocked:
      this.props.minDay || this.props.maxDay ? this.isDayBlocked : undefined,
  };

  private renderError = (): ReactNode | void => {
    const { error } = this.state;
    const { externalError } = this.props;
    const errorList = [];

    if (externalError) {
      errorList.push(externalError);
    }
    if (error) {
      errorList.push("Invalid date");
    }

    const errorMessage = errorList.filter(Boolean).join(", ");

    if (error || externalError) {
      return <FormError>{`${errorMessage}`}</FormError>;
    }
  };

  public render() {
    const { isVisible, currentDate, error } = this.state;
    const { id, externalError, disabled } = this.props;
    return (
      <>
        <DatepickerWrapper error={error || !!externalError}>
          <SingleDatePicker
            {...this.defaultInputProps}
            id={id}
            date={currentDate}
            focused={isVisible}
            disabled={disabled}
          />
        </DatepickerWrapper>
        {this.renderError()}
      </>
    );
  }
}

const DatepickerWrapper = styled.div<{ error: boolean }>`
  .SingleDatePickerInput {
    ${(props) =>
      props.error &&
      `
          box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
          border: 1px solid ${theme.colors.notificationRed};
    `};
  }
  input::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;
const Arrow = styled.div`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  top: 31px;
  cursor: pointer;
`;
const LeftArrow = styled(Arrow as any)`
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  left: 35px;
`;
const RightArrow = styled(Arrow as any)`
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  right: 35px;
`;

export default DatePicker;
