import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

function NarrowTemplate({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
`;

export default NarrowTemplate;
