import React, { FC } from "react";

import { ApplicationAssetsUpload } from "components/application";

import { Platform } from "api";
import { ChangeData } from "components/application/ApplicationAssetsUpload/ApplicationAssetsUpload";

import { isVideo } from "utils/assets";
import { SetsPreviews } from "./ApplicationControlPreviewsContainer";

import config from "config";

// -- TYPES
interface Props {
  platform: Platform;
  onChange: (data: object) => void;
  formData: SetsPreviews;
}

// -- COMPONENT
export const ApplicationControlSetsEdit: FC<Props> = ({
  platform,
  formData,
  onChange,
}) => {
  const onAssetsChange = (change: ChangeData) =>
    onChange({ assets: change.assets });
  const isVideoLimitReached =
    formData.assets.filter((asset) => isVideo(asset)).length >= 3 ||
    (platform === Platform.android && isVideo(formData.assets[0]));

  return (
    <ApplicationAssetsUpload
      assets={formData.assets}
      controlAsset={null}
      buttonText="Upload Screens"
      emptyTitle="Upload Screens"
      emptyDescription="Use the button above to upload screens from your computer"
      onChange={onAssetsChange}
      onControl={null}
      video={!isVideoLimitReached}
      maxUploadAssets={config.maxAssetsCountForSets}
      minUploadAssets={config.minAssetsCountForSets}
      platform={platform}
      allowDragAndDrop
      typeOfScreen="set"
    />
  );
};
