import React from "react";
import styled from "styled-components";
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import growverLogo from "assets/images/growver.png";

interface Props {
  title: string | React.ReactNode;
  children: React.ReactNode;
  footer: React.ReactNode;
  show: boolean;
  onHide: () => void;
  width?: number;
  type?: string;
}

function Modal({
  title,
  children,
  footer,
  show,
  onHide,
  type = "error",
}: Props) {
  const smDown = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const muiTheme = useTheme();
  return (
    <Dialog fullWidth open={show} onClose={onHide} maxWidth="sm">
      <Card
        sx={{
          border: "1px solid",
        }}
      >
        {type === "error" ? (
          <Grid
            container
            sx={{ backgroundColor: muiTheme.palette.secondary.main }}
          >
            <Grid
              item
              xs={smDown ? 12 : undefined}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Logo />
            </Grid>
            <Grid item xs sx={smDown ? {} : { marginLeft: "-24px" }}>
              <DialogTitle
                sx={{ backgroundColor: muiTheme.palette.secondary.main }}
              >
                {title}
              </DialogTitle>
              <DialogContent
                sx={{ backgroundColor: muiTheme.palette.secondary.main }}
              >
                <Box display="flex" alignItems="center">
                  {children}
                </Box>
                <Box pt={2}>{footer}</Box>
              </DialogContent>
            </Grid>
          </Grid>
        ) : (
          <>
            <DialogTitle
              sx={{ backgroundColor: muiTheme.palette.secondary.main }}
            >
              {title}
            </DialogTitle>
            <DialogContent
              sx={{ backgroundColor: muiTheme.palette.secondary.main }}
            >
              <Box display="flex" alignItems="center">
                {children}
              </Box>
              <Box pt={2}>{footer}</Box>
            </DialogContent>
          </>
        )}
      </Card>
    </Dialog>
  );
}

export default Modal;

const Logo = styled.div`
  height: 150px;
  width: 150px;
  margin: 8px;

  background-image: url(${growverLogo});
  background-repeat: no-repeat;
  background-size: contain;
`;
