import React, { useState } from "react";
import { Form } from "react-final-form";
import { useQuery } from "@apollo/client";

import PromoArtStep, {
  StepData,
} from "components/application/forms/PromoArtStep";
import { FormFooter } from "components/forms";
import { GET_APPLICATION_PROMO_ARTS_ONLY } from "api/queries/application";
import { Spinner, Error } from "components";
import { MessageBox } from "components/application";
import { PromoArt } from "api/models";

interface Props {
  onDiscard: () => void;
  onSubmit: (data: StepData) => void;
  isEdit?: boolean;
  application: any;
}

function ApplicationUpdatePromoArtsPage({
  onDiscard,
  onSubmit,
  isEdit,
  application,
}: Props) {
  const defaultState: StepData = {
    control: null,
    assets: [],
  };
  const [formData, setFormData] = useState(defaultState);
  const { data, error, loading } = useQuery<{ promoArts: PromoArt[] }>(
    GET_APPLICATION_PROMO_ARTS_ONLY,
    {
      variables: { applicationId: application.id },
    },
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  const hasControlPromoArt = (data?.promoArts.length || 0) > 0;

  const applicationAlreadyHasBlankPromoArt = Boolean(
    data?.promoArts.find((singlePromo) => !singlePromo.file.url),
  );

  const handleOnChange = (stepData: any) =>
    setFormData({
      ...formData,
      ...stepData,
    });

  return (
    <Form onSubmit={() => onSubmit(formData)} initialValues={formData}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <MessageBox
            content={
              !hasControlPromoArt
                ? "Please note that if you add a Promo Art asset during a test phase that has already started it will stop the tests and testing will restart with the new asset included in the control version."
                : undefined
            }
          />
          <PromoArtStep
            title="Upload New Promo Arts"
            data={formData}
            disableControl={hasControlPromoArt}
            onChange={handleOnChange}
            isEdit={isEdit}
            autoControl={!hasControlPromoArt}
            allowAddBlank={!applicationAlreadyHasBlankPromoArt}
          />

          <FormFooter onCancel={onDiscard} disabled={submitting} />
        </form>
      )}
    </Form>
  );
}

export default ApplicationUpdatePromoArtsPage;
