import { gql } from "@apollo/client";

export const ADD_PAYMENT_METHOD = gql`
  mutation addCustomerPaymentMethod($paymentMethodId: String!) {
    addCustomerPaymentMethod(paymentMethodStripeId: $paymentMethodId) {
      paymentMethod {
        last4
      }
    }
  }
`;

export const UPDATE_BILLING_DETAILS = gql`
  mutation updateCustomer(
    $city: String!
    $country: String!
    $line1: String!
    $line2: String
    $postalCode: String!
    $state: String!
    $email: String!
    $phone: String
    $name: String!
  ) {
    updateCustomer(
      city: $city
      country: $country
      line1: $line1
      line2: $line2
      postalCode: $postalCode
      state: $state
      email: $email
      phone: $phone
      name: $name
    ) {
      customer {
        email
      }
    }
  }
`;

export const EDIT_NUMBER_OF_SUBSCRIPTIONS = gql`
  mutation editNumberOfSubscriptions($numberOfItems: Int) {
    subscribe(numberOfItems: $numberOfItems) {
      subscription {
        id
      }
    }
  }
`;

export const ADD_FIRST_BILLING_DETAILS = gql`
  mutation addFirstBillingDetails(
    $accountId: String!
    $city: String!
    $country: String!
    $line1: String!
    $line2: String
    $postalCode: String!
    $state: String!
    $email: String!
    $name: String!
    $numberOfItems: Int!
    $paymentMethodId: String!
  ) {
    addFirstBillingDetails(
      accountId: $accountId
      city: $city
      country: $country
      line1: $line1
      line2: $line2
      postalCode: $postalCode
      state: $state
      email: $email
      name: $name
      numberOfItems: $numberOfItems
      paymentMethodStripeId: $paymentMethodId
    ) {
      token
    }
  }
`;

export const REFRESH_INVOICE = gql`
  mutation refreshInvoice($invoiceId: Int!) {
    refreshInvoice(invoiceId: $invoiceId) {
      isRefreshed
    }
  }
`;
