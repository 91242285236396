import React from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  description: string;
}

function Info({ title, description }: Props) {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      pb={1}
      px={1}
    >
      <Typography
        component="div"
        variant="body2"
        fontSize={21}
        fontWeight={600}
        color="#fff"
      >
        {title}
      </Typography>
      <Typography
        component="div"
        variant="body2"
        fontSize={14}
        fontWeight={300}
        lineHeight={1.71}
        color="#fff"
        pt={2}
      >
        {description}
      </Typography>
    </Box>
  );
}

export default Info;
