import React, { useState, useRef, useContext, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import ReactMarkdown from "react-markdown";

import {
  DISMISS_ALL_NOTIFICATIONS,
  DISMISS_CURRENT_USER_HISTORY_ENTRY,
  GET_CURRENT_USER_HISTORY,
  UPDATE_CURRENT_USER_HISTORY_ENTRY,
} from "api/queries/notifications";

import themes from "components/themes";
// import { useOutsideClick } from "v2/hooks/useOutsideClick";
import {
  Badge,
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { MUIFormHeader } from "@upptic/module-ui";
import CloseIcon from "@mui/icons-material/Close";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { PlatformContext } from "@upptic/module-directory";
import { NotificationV2 } from "screens/Notification/consts/interfaces";

import NotificationPanel from "../../v2/components/Notifications/NotificationPanel";
import ActionItems from "../../v2/components/ActionItems/ActionItems";

// -- COMPONENT
function NotificationElement(): JSX.Element {
  const { t } = useTranslation("miniHub");
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const platformStore = useContext(PlatformContext);
  const [notifications, setNotifications] = useState<NotificationV2[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [offset, setOffset] = useState(0);
  const [toggledButton, setToggledButton] = useState<string>("actions");
  const [totalNotifications, setTotalNotifications] = useState(0);
  const notiSnackbar = useSnackbar();
  const limit = 10;

  const emitNotificationStatusChangedEvent = (type: string, status: string) => {
    document.dispatchEvent(
      new CustomEvent("notificationStatusChanged" as any, {
        detail: {
          type,
          status,
          origin: "aso",
        },
      }),
    );
  };

  const menuRef = useRef(null);

  const { loading, refetch } = useQuery(GET_CURRENT_USER_HISTORY, {
    skip: !platformStore.currentClient?.code,
    fetchPolicy: "no-cache",
    onError: () => {},
    context: { handleNetworkError: true },
    onCompleted: ({ currentUserNotificationHistory }) => {
      if (offset === 0) {
        setNotifications(currentUserNotificationHistory.items);
        setUnreadCount(currentUserNotificationHistory.unreadCount);
        setTotalNotifications(currentUserNotificationHistory.totalItemCount);
        return;
      }
      setNotifications((prevState) => [
        ...prevState,
        ...currentUserNotificationHistory.items,
      ]);
      setUnreadCount(currentUserNotificationHistory.unreadCount);
      setTotalNotifications(currentUserNotificationHistory.totalItemCount);
    },
    variables: {
      clientCode: platformStore.currentClient?.code,
      applicationCode: platformStore.selectedApplication?.code,
      limit,
      offset,
    },
  });

  const { refetch: refetchImportant } = useQuery(GET_CURRENT_USER_HISTORY, {
    skip: !platformStore.currentClient?.code,
    fetchPolicy: "no-cache",
    onError: () => {},
    context: { handleNetworkError: true },
    onCompleted: ({ currentUserNotificationHistory }) => {
      if (!currentUserNotificationHistory.items.length) return;

      const onDismiss = async (code: any) => {
        notiSnackbar.closeSnackbar(code);
        await updateHistoryEntry({
          variables: {
            clientCode: platformStore.currentClient?.code,
            historyEntryCode: code,
            status: "read",
          },
        });
        emitNotificationStatusChangedEvent("dismiss", "unread");
        await refetchImportant();
        refetch();
      };

      for (const entry of currentUserNotificationHistory.items) {
        notiSnackbar.enqueueSnackbar({
          key: entry.code,
          message: (
            <SnackbarWrapper>
              <ReactMarkdown>
                {entry.content.replaceAll("*", "**")}
              </ReactMarkdown>
            </SnackbarWrapper>
          ),
          persist: true,
          variant: entry.event.level,
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          action: (snackbarId: any) => (
            <>
              {entry.link && (
                <Button
                  href={entry.link}
                  size="small"
                  sx={{ color: "white" }}
                  target="_blank"
                >
                  See more
                </Button>
              )}
              <Button
                onClick={() => onDismiss(snackbarId)}
                size="small"
                sx={{ color: "white" }}
              >
                Close
              </Button>
            </>
          ),
        });
      }
    },
    variables: {
      clientCode: platformStore.currentClient?.code,
      applicationCode: platformStore.selectedApplication?.code,
      filter: { "event.priority": "important", "status": "unread" },
      limit: 3,
    },
  });

  useEffect(() => {
    if (menuVisible) refetch();
  }, [menuVisible]);

  const onClose = () => {
    setMenuVisible(false);
    setOffset(0);
  };

  const [updateHistoryEntry] = useMutation(UPDATE_CURRENT_USER_HISTORY_ENTRY, {
    onError: () => {},
    context: { handleError: true },
  });
  const [dismissHistoryEntry] = useMutation(
    DISMISS_CURRENT_USER_HISTORY_ENTRY,
    {
      onError: () => {},
      context: { handleError: true },
    },
  );
  const [dismissAllHistoryEntries] = useMutation(DISMISS_ALL_NOTIFICATIONS, {
    onError: () => {},
    context: { handleError: true },
    refetchQueries: ["currentUserNotificationHistory"],
    awaitRefetchQueries: true,
  });

  // disable outside click close for now
  // useOutsideClick(menuRef, onClose);

  useEffect(() => {
    const listener = (e: any) => {
      // skip message if its for a different client
      if (
        e.detail.client?.code &&
        e.detail.client?.code !== platformStore.currentClient?.code
      )
        return;

      setNotifications((notificationsList) => [e.detail, ...notificationsList]);
      setUnreadCount((count) => count + 1);
      refetchImportant();
    };
    document.addEventListener("userNotificationReceived", listener);
    return () =>
      document.removeEventListener("userNotificationReceived", listener);
  }, [platformStore.currentClient?.code]);

  useEffect(() => {
    const listener = (e: any) => {
      if (
        (e?.detail?.type === "dismiss" && e?.detail?.status === "unread") ||
        e?.detail?.type === "read"
      ) {
        setUnreadCount((count) => (count >= 1 ? count - 1 : 0));
      }
    };
    document.addEventListener("notificationStatusChanged", listener);
    return () =>
      document.removeEventListener("notificationStatusChanged", listener);
  }, []);

  const handleToggle = (event: any, value: string) => {
    if (value !== null) setToggledButton(value);
  };

  return (
    <Wrapper ref={menuRef}>
      <Badge
        color="primary"
        badgeContent={unreadCount}
        style={{ cursor: "pointer" }}
        onClick={() => setMenuVisible((val) => !val)}
      >
        <ListAltIcon />
      </Badge>
      {menuVisible && (
        <MenuWrapper>
          <Box mb={1.5}>
            <MUIFormHeader
              title="Mini Hub"
              buttons={[{ icon: <CloseIcon />, onClick: () => onClose() }]}
              sx={{ padding: "4px 16px", height: 48 }}
            />
            <Box
              sx={{
                height: "40px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 16px",
              }}
            >
              <ToggleButtonGroup
                value={toggledButton}
                onChange={handleToggle}
                exclusive
                color="primary"
                fullWidth
                size="small"
                sx={{
                  button: {
                    border: "none",
                  },
                }}
              >
                <ToggleButton value="actions">
                  <span>{t<string>("buttons.actions")}</span>
                </ToggleButton>
                <ToggleButton value="watching">
                  <span>{t<string>("buttons.watching")}</span>
                </ToggleButton>
                <ToggleButton value="other">
                  <span>{t<string>("buttons.other")}</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          <Box
            px={2}
            pb={2}
            sx={{
              overflowY: "auto",
              height: "calc(100% - 88px)",
              maxHeight: "calc(100vh - 265px)",
              marginBottom: "32px",
            }}
          >
            <Box>
              <ActionItems toggledButton={toggledButton} />
            </Box>
            {toggledButton === "actions" ? (
              <Box mt={2}>
                <NotificationPanel
                  notifications={notifications}
                  loading={loading}
                  onNotificationClick={(code: string) => {
                    updateHistoryEntry({
                      variables: {
                        clientCode: platformStore.currentClient?.code,
                        historyEntryCode: code,
                        status: "read",
                      },
                    });
                    emitNotificationStatusChangedEvent("read", "unread");
                  }}
                  onDismissClick={(code: string, isRead: boolean) => {
                    const newNotificationArray = notifications;
                    const findIndexOfCode = newNotificationArray.findIndex(
                      (notification) => notification.code === code,
                    );
                    newNotificationArray.splice(findIndexOfCode, 1);
                    setNotifications([...newNotificationArray]);
                    dismissHistoryEntry({
                      variables: {
                        clientCode: platformStore.currentClient?.code,
                        historyEntryCode: code,
                      },
                    });
                    if (!isRead) {
                      emitNotificationStatusChangedEvent("dismiss", "unread");
                      return;
                    }
                    emitNotificationStatusChangedEvent("dismiss", "read");
                  }}
                  offset={offset}
                  setOffset={setOffset}
                  totalNotifications={totalNotifications}
                  onDismissAll={() => {
                    dismissAllHistoryEntries({
                      variables: {
                        clientCode: platformStore.currentClient?.code,
                        applicationCode:
                          platformStore.selectedApplication?.code,
                      },
                    });
                  }}
                  limit={limit}
                />
              </Box>
            ) : null}
          </Box>
        </MenuWrapper>
      )}
    </Wrapper>
  );
}

// -- STYLED
const Wrapper = styled.div`
  position: relative;
`;

const MenuWrapper = styled.div`
  max-height: calc(100vh - 130px);
  width: 700px;
  position: absolute;
  left: -14px;

  background-color: ${themes.colors.backgroundLight};

  transform: translate(-550px, 17px);

  border-radius: 8px;
`;

const SnackbarWrapper = styled.div`
  display: block;
  p,
  ul {
    margin-bottom: 0;
  }
  ul {
    padding-left: 15px;
  }
`;

export const NotificationBarElement = observer(NotificationElement);
