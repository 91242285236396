import gql from "graphql-tag";
import {
  CURRENT_ITERATION_FRAG,
  CONTROL_VARIATION_PREVIEWS_FRAG,
  CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG,
} from "./fragments";

export const GET_APPLICATION_OVERVIEW = gql`
  query application($applicationId: ID!) {
    application(id: $applicationId) {
      id
      code
      identifier
      name
      platform
      redirectUrl
      createdAt
      hasTopPage
      topPageDate
      currentBcr
      status
      significance
      firstIterationEnds
    }
    currentPhase(applicationId: $applicationId) {
      id
      type
    }
    newTestsRoute(applicationId: $applicationId) {
      id
      weight
    }
    experimentStatistics(applicationId: $applicationId) {
      totalTestsRun
      totalTraffic
      creativesCurrentlyTesting
      creativesQueued
      creativesQueuedInPhase
      creativesDiscarded
    }
    controlVariation(applicationId: $applicationId) {
      id
      conversionRate
      url
    }
    topVariation(applicationId: $applicationId) {
      id
      conversionRate
      url
      confidence
    }
    topVariationZipFile(applicationId: $applicationId)
    testUrl(applicationId: $applicationId)
    liveUrls(applicationId: $applicationId)
    applicationGroup(applicationId: $applicationId) {
      id
      applications {
        id
      }
    }
    phaseTraffic(applicationId: $applicationId)
    trafficNeeded(applicationId: $applicationId)
    visitsToNewLearning(applicationId: $applicationId)
    currentIteration(applicationId: $applicationId) {
      ...CURRENT_ITERATION_FRAG
    }
    facebookPixel(applicationId: $applicationId) {
      eventType
      pixelId
      enabled
    }
  }
  ${CURRENT_ITERATION_FRAG}
`;

export const GET_APPLICATION_TITLE_AND_STATUS = gql`
  query application($id: ID!) {
    application(id: $id) {
      id
      name
      platform
      status
      groupCode
    }
    testUrl(applicationId: $id)
    liveUrl(applicationId: $id)
  }
`;

export const GET_IOS_APPLICATION_STATIC_INFO = gql`
  query application($id: ID!) {
    application(id: $id) {
      id
      platform
      publisher
      rating
      numberOfRatings
      category {
        name
        id
      }
      ranking
      ageLimit
      size
      appVersion
      releaseDate
      releaseNote
      copyright
    }
    reviews(applicationId: $id) {
      id
      title
      date
      username
      rating
      description
    }
  }
`;

export const GET_ANDROID_APPLICATION_STATIC_INFO = gql`
  query application($id: ID!) {
    application(id: $id) {
      id
      platform
      publisher
      rating
      downloads
      whatsNew
      numberOfRatings
      ageLimit
      tags
      releaseDate
      appVersion
      gameplayRating
      controlRating
      graphicRating
      size
      developerPage
      developerEmail
      developerAddress1
      developerAddress2
      developerCity
      developerState
      developerPostalCode
      developerCountry
      privacyPolicyUrl
    }
    reviews(applicationId: $id) {
      id
      date
      username
      rating
      description
    }
  }
`;

export const GET_CLIENT_APPLICATIONS = gql`
  query clientApplications($clientCode: String!) {
    clientApplications(clientCode: $clientCode) {
      code
      name
      asoGroupId
      status
      image
      audit {
        createDate
      }
      appPlatform {
        code
        platform
        status
        appPublicId
        asoAppId
        verifiedDataDate
        asoInfo {
          controlData {
            cvr
            conversions
            visits
          }
          redirectToStoreUrl
        }
      }
    }
  }
`;

export const GET_APPLICATION_CONTROL = gql`
  query controlVariation($applicationId: ID!) {
    application(id: $applicationId) {
      id
      name
      platform
      hasTopPage
      status
      firstIterationEnds
    }
    controlVariation(applicationId: $applicationId) {
      id
      icon {
        file {
          url
        }
      }
      promoArt {
        file {
          url
        }
      }
      ...CONTROL_VARIATION_PREVIEWS_FRAG
      ...CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG
      title {
        name
      }
      subtitle {
        name
      }
      description {
        name
      }
      featureGraphic {
        file {
          url
        }
      }
      conversionRate
      url
    }
    topVariation(applicationId: $applicationId) {
      id
      conversionRate
      confidence
      url
    }
    topVariationZipFile(applicationId: $applicationId)
    applicationControlAssets(applicationId: $applicationId) {
      icon {
        file {
          url
        }
      }
      promoArt {
        file {
          url
        }
      }
      previews {
        id
        url
        isVideo
        position
        isControlSet
        fileName
        previewLink
      }
      title {
        name
      }
      subtitle {
        name
      }
      description {
        name
      }
      featureGraphic {
        file {
          url
        }
      }
    }
  }
  ${CONTROL_VARIATION_PREVIEWS_FRAG}
  ${CONTROL_VARIATION_ADDITIONAL_PREVIEWS_FRAG}
`;

export const GET_PROFILE = gql`
  query profile {
    profile {
      id
      email
      fullName
    }
  }
`;

export const GET_ROUTES = gql`
  query controlRoute($applicationId: ID!) {
    application(id: $applicationId) {
      id
      name
      status
      redirectToStoreUrl
      minimumDetectableLift
      currentBcr
    }
    liveUrls(applicationId: $applicationId)
    controlRoute(applicationId: $applicationId) {
      id
      type
      weight
      url
      visits
    }
    newTestsRoute(applicationId: $applicationId) {
      id
      type
      weight
      url
      visits
      discardedVisits
    }
    actualStorePageRoute(applicationId: $applicationId) {
      id
      type
      weight
      url
      visits
      discardedVisits
      redirectUrl
    }
    customRoutes(applicationId: $applicationId) {
      id
      type
      weight
      url
      visits
    }
  }
`;

export const CHECK_PASSWORD_TOKEN = gql`
  query validateUserToken($token: String!) {
    validateUserToken(token: $token)
  }
`;

export const CHECK_APPLICATION_NAME = gql`
  query checkApplicationName($name: String!, $platform: String!) {
    checkApplicationName(name: $name, platform: $platform)
  }
`;

export const GET_APPLICATION_VIEWS_AND_CONVERSIONS = gql`
  query getApplicationConversionRates(
    $applicationId: ID!
    $dateFrom: String!
    $dateTo: String!
  ) {
    appConversionRates(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      label
      legendTooltip
      legendOrderIndex
      conversionRates {
        date
        conversions
        visits
        cvr
        cumulativeVisits
        cumulativeConversions
      }
    }
    applicationShipDates(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      periodStartDate
      dates
    }
    graphStatistics(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      totalVisits
      totalConversions
      conversionRate
    }
    applicationUserActions(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      periodStartDate
      actions {
        id
        action
        date
      }
    }
  }
`;

export const GET_CONTROL_VIEWS_AND_CONVERSIONS = gql`
  query getControlConversionRates(
    $applicationId: ID!
    $dateFrom: String!
    $dateTo: String!
  ) {
    controlConversionRates(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      label
      conversionRates {
        date
        conversions
        visits
        cvr
        cumulativeVisits
        cumulativeConversions
      }
    }
    applicationShipDates(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      periodStartDate
      dates
    }
    graphStatistics(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      totalVisits
      totalConversions
      conversionRate
    }
    applicationUserActions(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      periodStartDate
      actions {
        id
        action
        date
      }
    }
  }
`;

export const GET_APPLICATION_VIEWS_AND_CONVERSIONS_OLD = gql`
  query getApplicationConversionRatesOld(
    $applicationId: ID!
    $dateFrom: String!
    $dateTo: String!
    $displayMode: String!
  ) {
    appConversionRatesOld(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
      displayMode: $displayMode
    ) {
      label
      legendTooltip
      legendOrderIndex
      conversionRates {
        date
        conversions
        visits
        cvr
      }
    }
    applicationShipDatesOld(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
      displayMode: $displayMode
    ) {
      periodStartDate
      dates
    }
    graphStatistics(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      totalVisits
      totalConversions
      conversionRate
    }
    applicationUserActionsOld(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
      displayMode: $displayMode
    ) {
      periodStartDate
      actions {
        id
        action
        date
      }
    }
  }
`;

export const GET_CONTROL_VIEWS_AND_CONVERSIONS_OLD = gql`
  query getControlConversionRates(
    $applicationId: ID!
    $dateFrom: String!
    $dateTo: String!
    $displayMode: String!
  ) {
    controlConversionRatesOld(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
      displayMode: $displayMode
    ) {
      label
      conversionRates {
        date
        conversions
        visits
        cvr
      }
    }
    applicationShipDatesOld(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
      displayMode: $displayMode
    ) {
      periodStartDate
      dates
    }
    graphStatistics(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      totalVisits
      totalConversions
      conversionRate
    }
    applicationUserActionsOld(
      applicationId: $applicationId
      dateFrom: $dateFrom
      dateTo: $dateTo
      displayMode: $displayMode
    ) {
      periodStartDate
      actions {
        id
        action
        date
      }
    }
  }
`;
