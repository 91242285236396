import React, { FC, ReactElement } from "react";
import styled from "styled-components";

import { Tooltip } from "components";

import { truncate } from "../utils/strings";

// -- TYPES
interface Props {
  value: string;
  displayedLength: number;
}

// -- COMPONENT
export const Text: FC<Props> = ({
  value,
  displayedLength,
  ...rest
}): ReactElement => {
  return (
    <TextContent {...rest}>
      {value.length > displayedLength ? (
        <Tooltip placement={"top"} content={value}>
          <div>{truncate(value, displayedLength)}</div>
        </Tooltip>
      ) : (
        <div color={"inherit"}>{value}</div>
      )}
    </TextContent>
  );
};

const TextContent = styled.div``;
