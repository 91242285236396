// -- CORE
import React from "react";
import { Redirect } from "react-router-dom";
import { nonAuthRoutes as moduleRoutes } from "@upptic/module-directory";
// -- CONST
import { Route } from "v2/api/classes/route";
import { MenuMode } from "v2/api/types/menuMode";
// -- PAGES
const IFrameSignUpPage = React.lazy(() => import("forms/IframeSignUpPage"));
const SignIn = React.lazy(() => import("screens/Auth/SignIn/SignInContainer"));
const OtpExternalContainer = React.lazy(() =>
  import("screens/Auth/SignIn/OtpExternalContainer"),
);
const SignUp = React.lazy(() => import("screens/Auth/SignUp/SignUpContainer"));
const SignUpConfirm = React.lazy(() =>
  import("screens/Auth/SignUpConfirm/SignUpConfirmPage"),
);
const SignUpSetPassword = React.lazy(() =>
  import("v2/pages/Auth/SignUpActivate/SignUpSetPasswordContainer"),
);
const ResetPasswordSetPassword = React.lazy(() =>
  import("v2/pages/Auth/ResetPassword/ResetPasswordSetPasswordContainer"),
);
const SignUpActivate = React.lazy(() =>
  import("screens/Auth/SignUpActivate/SignUpActivateContainer"),
);
const ForgotPassword = React.lazy(() =>
  import("screens/Auth/ForgotPassword/ForgotPasswordContainer"),
);
const ForgotPasswordConfirm = React.lazy(() =>
  import("screens/Auth/ForgotPasswordConfirm/ForgotPasswordConfirmPage"),
);
const UpdateForgottenPassword = React.lazy(() =>
  import(
    "screens/Auth/UpdateForgottenPassword/UpdateForgottenPasswordContainer"
  ),
);

const additionalRoutes = moduleRoutes.reduce<any>((acc, route, index) => {
  acc[index.toString()] = new Route<never>({
    path: route.path,
    component: route.component,
    exact: route.exact === undefined ? true : route.exact,
    menuMode: (route.menuMode as MenuMode) || MenuMode.NONE,
    layout: route.layout,
  });
  return acc;
}, {});

// -- ROUTES DEFINITION
export const nonAuthRoutes = {
  AUTH_SIGN_IN: new Route<never>({
    path: "/sign-in",
    component: SignIn,
    exact: true,
    layout: "auth",
    menuMode: MenuMode.NONE,
  }),
  AUTH_SIGN_IN_OTP: new Route<never>({
    path: "/sign-in/external-otp/:token",
    component: OtpExternalContainer,
    exact: true,
    layout: "auth",
    menuMode: MenuMode.NONE,
  }),
  AUTH_SIGN_UP: new Route<never>({
    path: "/sign-up",
    component: SignUp,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  AUTH_REST_PASSWORD_SET_PASSWORD: new Route<{
    token: string;
    membershipId: string;
    isNewUser: string;
  }>({
    path: `/sign-up/set-password/:token`,
    component: ResetPasswordSetPassword,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  AUTH_SIGN_UP_SET_PASSWORD: new Route<{
    token: string;
    membershipId: string;
    isNewUser: string;
  }>({
    path: `/sign-up/activate-set-password/:token`,
    component: SignUpSetPassword,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  AUTH_SIGN_UP_ACTIVATE: new Route<{ token: string; membershipId: string }>({
    path: `/sign-up/accept-invite/:token`,
    component: SignUpActivate,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  AUTH_SIGN_UP_CONFIRM: new Route<never>({
    path: "/sign-up-confirm",
    component: SignUpConfirm,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  AUTH_FORGOT_PASSWORD: new Route<never>({
    path: "/forgot-password",
    component: ForgotPassword,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  AUTH_FORGOT_PASSWORD_CONFIRM: new Route<never>({
    path: "/forgot-password-confirm",
    component: ForgotPasswordConfirm,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  AUTH_UPDATE_FORGOTTEN_PASSWORD: new Route<{ email: string; token: string }>({
    path: "/update-password/:token",
    component: UpdateForgottenPassword,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  IFRAME_SIGN_UP_PAGE: new Route<never>({
    path: "/iframes/forms/sign-up",
    component: IFrameSignUpPage,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  ...additionalRoutes,
  PAGE_NOT_FOUND: new Route<never>({
    render: (props) => {
      const { location } = props;
      if (location?.pathname !== "/" && location?.pathname !== "/logout") {
        const d = new Date();
        d.setTime(d.getTime() + 4 * 60 * 60 * 1000);
        document.cookie = `locationBeforeLogout=${
          location.pathname
        }; expires=${d.toUTCString()}; path=/;`;
      }
      return <Redirect to={"/sign-in"} />;
    },
    menuMode: MenuMode.NONE,
  }),
};
