import React, { FC } from "react";
import styled from "styled-components";

import { UserPermissions } from "v2/api/types";

import { Button, FormHeader } from "components/forms";
import { Icon, Icons } from "components";

import { FormApi } from "final-form";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- TYPES
interface HeaderWithEditProps {
  title: string;
  editStatus: boolean;
  form: FormApi;
  setEditStatus: (status: boolean) => any;
  additionalActions?: React.ReactElement[];
  editRequiredPermissions?: UserPermissions[];
}

// -- COMPONENT
export const HeaderWithEdit: FC<HeaderWithEditProps> = ({
  title,
  editStatus,
  form,
  setEditStatus,
  additionalActions,
  editRequiredPermissions = [],
}) => {
  const handleDiscard = (): void => {
    form.reset();
    setEditStatus(false);
  };

  const handleEdit = () => setEditStatus(true);

  return (
    <SectionHeader>
      {title}
      <PermissionRestriction requiredPermissions={editRequiredPermissions}>
        <ButtonsWrapper>
          {!editStatus ? (
            <Action onClick={handleEdit}>
              <ActionIcon icon={Icons.faPen} />
            </Action>
          ) : (
            <>
              {additionalActions}
              <Button onClick={handleDiscard} outline={true}>
                Discard
              </Button>
              <Button type="submit">Save</Button>
            </>
          )}
        </ButtonsWrapper>
      </PermissionRestriction>
    </SectionHeader>
  );
};

// -- STYLED
const SectionHeader = styled(FormHeader as any)`
  display: flex;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  display: flex;

  button {
    margin-left: 20px;
  }
`;

const Action = styled.div`
  display: flex;
  cursor: pointer;
`;

const ActionIcon = styled(Icon)`
  margin-right: 8px;
  height: 2em;
`;
