import { gql } from "@apollo/client";

export const HEATMAP_DATA = gql`
  query heatmapData($applicationId: ID!) {
    heatmapChartData(applicationId: $applicationId) {
      dataSet
      legendElements {
        label
        legendOrderIndex
        legendTooltip
        dataIndex
        color
      }
    }
    controlVariation(applicationId: $applicationId) {
      url
    }
  }
`;

export const TIMESPENT_CHART_DATA = gql`
  query timespentChartData($applicationId: ID!) {
    timespentChartData(applicationId: $applicationId) {
      totalVisits
      dataSet
      legendElements {
        label
        legendOrderIndex
        legendTooltip
        dataIndex
        color
      }
    }
  }
`;
