import React, { ReactNode, useState } from "react";
import { Form } from "react-final-form";

import { TextAssetData } from "components/application/forms/TextAssetStep/constants/textAssetData";

import TextAssetStepTitles from "components/application/forms/TextAssetStep/components/TextAssetStepTitles";
import TextAssetStepDescriptions from "components/application/forms/TextAssetStep/components/TextAssetStepDescriptions";
import TextAssetStepSubtitles from "components/application/forms/TextAssetStep/components/TextAssetStepSubtitles";
import { FormFooter } from "components/forms";
import { Platform } from "api";
import { MessageBox } from "components/application";

interface Props {
  type: string;
  onDiscard: () => void;
  onSubmit: (data: TextAssetData) => void;
  platform: Platform;
}

function ApplicationUpdateTextsPage({
  type,
  onDiscard,
  onSubmit,
  platform,
}: Props) {
  const defaultState: TextAssetData = {
    value: "",
    control: "",
    values: [],
  };
  const [formData, setFormData] = useState(defaultState);

  const onChange = (data: any) =>
    setFormData({
      ...formData,
      ...data,
    });

  let step: ReactNode;
  if (type === "descriptions") {
    step = (
      <TextAssetStepDescriptions
        data={formData}
        disableControl
        onChange={onChange}
        platform={platform}
      />
    );
  } else if (type === "titles") {
    step = (
      <TextAssetStepTitles
        data={formData}
        disableControl
        onChange={onChange}
        platform={platform}
      />
    );
  } else {
    step = (
      <TextAssetStepSubtitles
        data={formData}
        disableControl
        onChange={onChange}
      />
    );
  }

  return (
    <Form onSubmit={() => onSubmit(formData)}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <MessageBox />
          {step}

          <FormFooter onCancel={onDiscard} disabled={submitting} />
        </form>
      )}
    </Form>
  );
}

export default ApplicationUpdateTextsPage;
