import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const createTitles = new ApiMutation({
  definition: gql`
    mutation createTitles($applicationId: ID!, $texts: [TextInputType]) {
      createTitles(applicationId: $applicationId, texts: $texts) {
        isCreated
      }
    }
  `,
  requiredPermissions: [UserPermissions.createASOAsset],
});

export interface CreateTitlesResponse {
  createTitles: {
    isCreated: boolean;
  };
}

export const deleteTitle = new ApiMutation({
  definition: gql`
    mutation deleteTitle($id: ID!) {
      deleteTitle(id: $id) {
        isDeleted
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOAsset],
});

export interface DeleteTitleResponse {
  deleteTitle: {
    isDeleted: boolean;
  };
}

export const deactivateTitle = new ApiMutation({
  definition: gql`
    mutation deactivateTitle($id: ID!) {
      deactivateTitle(id: $id) {
        isDeactivated
      }
    }
  `,
  requiredPermissions: [UserPermissions.deleteASOTest],
});

export interface DeactivateTitleResponse {
  deleteTitle: {
    isDeleted: boolean;
  };
}
