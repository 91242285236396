import { gql } from "@apollo/client";

export const getCurrentUserClients = gql`
  query currentUserClients($uppticManagedOnly: Boolean, $filter: Filter) {
    currentUserClients(uppticManagedOnly: $uppticManagedOnly, filter: $filter) {
      code
      name
      status
    }
  }
`;

export type GetCurrentUserClientsResponse = {
  currentUserClients: {
    code: string;
    name: string;
    status: string;
  }[];
};
