import React, { useMemo } from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { SingleMenuItem } from "./SingleMenuItem";
import ContextsSwitcher from "../ContextsSwitcher/ContextsSwitcher";
import { useIsMobile } from "./helper";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";

const drawerWidth = 250;

interface SideMenuProps {
  collapsed: boolean;
  menuItems: any[];
  bottomMenuItems?: any[];
  asoMenuItems?: any[];
  currentModule: string;
  onMenuToggle: () => void;
}

export default function SideMenuComponentV2({
  menuItems,
  asoMenuItems,
  bottomMenuItems,
  collapsed,
  currentModule,
  onMenuToggle,
}: SideMenuProps) {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useIsMobile();
  const platformStore = React.useContext<PlatformStore>(PlatformContext);
  const isInfluencer = platformStore?.currentUserFrontendPermissions?.includes(
    "PSOSIPR",
  );

  const rows: any = {};

  for (const item of menuItems) {
    const rowIndex = item.rowIndex || 0;
    if (!rows[rowIndex as keyof typeof rows]) rows[rowIndex] = [];
    rows[rowIndex].push(item);
  }
  const rowArray = Object.values(rows);

  const onMenuItemClick = (event: any, route: any) => {
    event.preventDefault();
    event.stopPropagation();
    history.replace(route);
    if (isMobile) onMenuToggle();
  };

  const mainMenu = useMemo(() => {
    return rowArray.map((row: any, index) => (
      <List
        key={index}
        sx={{
          "padding": "0px",
          "& .isActive": {
            boxShadow: `inset 10px 0px 0px -4px ${theme.palette.primary.main}`,
          },
          "position": "unset",
        }}
      >
        {row?.map((item: any, rowIndex: number) => {
          if (collapsed)
            return (
              <SingleMenuItem
                key={rowIndex}
                item={item}
                collapsed={collapsed}
                onMenuItemClick={onMenuItemClick}
              />
            );
          return (
            <>
              <SingleMenuItem
                key={rowIndex}
                item={item}
                disableNesting
                collapsed={collapsed}
                onMenuItemClick={onMenuItemClick}
              />
              {item.items &&
                item?.items?.map((subItem: any, subIndex: number) => (
                  <SingleMenuItem
                    key={subIndex}
                    item={subItem}
                    disableNesting
                    subItem
                    collapsed={collapsed}
                    onMenuItemClick={onMenuItemClick}
                  />
                ))}
            </>
          );
        })}
      </List>
    ));
  }, [collapsed, menuItems]);

  const asoMenu = useMemo(() => {
    if (currentModule !== "aso") return null;
    return (
      <List
        sx={{
          "padding": "0px",
          "& .isActive": {
            boxShadow: `inset 10px 0px 0px -4px ${theme.palette.primary.main}`,
          },
          "position": "unset",
        }}
      >
        {asoMenuItems?.map((item: any) => {
          if (item?.route) {
            return (
              <SingleMenuItem
                item={item}
                collapsed={collapsed}
                onMenuItemClick={onMenuItemClick}
              />
            );
          }
          return (
            <>
              {!collapsed ? (
                <Typography sx={{ padding: "8px" }}>{item.title}</Typography>
              ) : null}

              {item.items &&
                item.items.map((nested: any, index: number) => {
                  return (
                    <SingleMenuItem
                      item={nested}
                      key={index}
                      collapsed={collapsed}
                      onMenuItemClick={onMenuItemClick}
                    />
                  );
                })}
            </>
          );
        })}
      </List>
    );
  }, [collapsed, asoMenuItems, currentModule]);

  const bottomMenu = useMemo(
    () => (
      <Box>
        <Divider />
        <List
          sx={{
            "padding": "0px",
            "& .isActive": {
              boxShadow: `inset 10px 0px 0px -4px ${theme.palette.primary.main}`,
            },
            "position": "unset",
          }}
        >
          {bottomMenuItems?.map((item: any, index: number) => {
            return (
              <SingleMenuItem
                key={index}
                item={item}
                collapsed={collapsed}
                onMenuItemClick={onMenuItemClick}
              />
            );
          })}
        </List>
      </Box>
    ),
    [collapsed, bottomMenuItems],
  );

  return (
    <Box
      sx={{
        minWidth: isMobile ? 0 : 80,
        position: "relative",
      }}
    >
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? !collapsed : false}
        sx={{
          "position": collapsed || isMobile ? "absolute" : "default",
          "width": collapsed ? 80 : drawerWidth,
          "height": "calc(100% - 60px)",
          "transition": theme.transitions.create("width", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          "& .MuiDrawer-paper": {
            width: collapsed ? 80 : drawerWidth,
            boxSizing: "border-box",
            marginTop: "60px",
            height: "100%",
            overflowY: "hidden",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
            border: "none",
          },
        }}
      >
        {isMobile ? (
          <>
            {isInfluencer ? null : (
              <ContextsSwitcher
                parentStyles={{
                  "padding": "8px",
                  "flexDirection": "column-reverse",
                  "alignItems": "flex-start",
                  "> div": {
                    "marginRight": "0px",
                    "marginBottom": "8px",
                    "&:last-of-type": {
                      marginRight: "0px",
                    },
                  },
                }}
              />
            )}
            <Divider />
          </>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={collapsed ? "flex-start" : "flex-end"}
          >
            <Box
              sx={{
                height: "50px",
                width: "80px",
                userSelect: "none",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: collapsed ? "center" : "flex-end",
                paddingRight: collapsed ? "0px" : "8px",
              }}
            >
              <IconButton onClick={onMenuToggle}>
                {collapsed ? <MenuIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Box>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            overflowY: "auto",
            height: isMobile ? "calc(100vh - 60px)" : "100%",
          }}
        >
          <Box>
            {mainMenu}
            {asoMenu}
          </Box>
          {bottomMenuItems && bottomMenuItems?.length !== 0 ? (
            <Box mb={12}>{bottomMenu}</Box>
          ) : null}
        </Box>
      </Drawer>
    </Box>
  );
}
