import React, { FC } from "react";
import styled, { keyframes } from "styled-components";

import { percentFormat } from "utils/numbers";
import themes from "./themes";

// -- TYPES
interface Props {
  value: number;
  label?: string;
}

// -- COMPONENT
const PercentageChart: FC<Props> = ({ value, label }) => (
  <Container>
    <CSSPercentageChart>
      <BackgroundCircle cx="-45" cy="45" r="41" />
      <ProgressCircle
        cx="-45"
        cy="45"
        r="41"
        width={value * 255} // count percentage progress, 255 is circumference
      />
    </CSSPercentageChart>

    <Text>{label || percentFormat(value)}</Text>
  </Container>
);

const genericFillAnimation = (width: number) => keyframes`
   0% {
    stroke-dashoffset: 255px;
  }

  100% {
    stroke-dashoffset: ${width}px;
  }
`;

// -- STYLED
const Container = styled.div`
  display: flex;
  justify-content: center;

  position: relative;

  margin-bottom: 10px;
`;

const CSSPercentageChart = styled.svg`
  overflow: visible;

  width: 91px;
  height: 91px;
`;

const Text = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: ${themes.colors.white};
`;

const BackgroundCircle = styled.circle`
  width: 100%;
  height: 100%;

  fill: none;

  stroke: #47465b;
  stroke-width: 7;
  stroke-linecap: square;

  stroke-dasharray: 255px;
  stroke-dashoffset: 0px;

  transform: rotate(-90deg);

  animation: ${genericFillAnimation(0)} 0.5s linear;
`;

const ProgressCircle = styled.circle<{ width: number }>`
  ${(props) =>
    props.width <= 0 &&
    `
    display: none;
  `}

  width: 100%;
  height: 100%;

  fill: none;

  stroke: #00baf3;
  stroke-width: 7;
  stroke-linecap: square;

  stroke-dasharray: 255px;
  stroke-dashoffset: ${(props) =>
    props.width > 255 ? 0 : 255 - props.width}px;

  transform: rotate(-90deg);

  animation: ${(props) =>
      genericFillAnimation(props.width > 255 ? 0 : 255 - props.width)}
    0.5s linear;
`;

export default PercentageChart;
