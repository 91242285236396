// -- CORE
import React, { useContext } from "react";
// -- COMPONENTS
import ApplicationPlatformSwitcher from "components/application/ApplicationPlatformSwitcher";
import ApplicationSwitcher from "components/application/ApplicationSwitcher";
import ClientSwitcher from "v2/components/ClientSwitcher/ClientSwitcher";
import { useStores } from "contexts/storeContext";
import { PlatformContext, routes } from "@upptic/module-directory";
import { observer } from "mobx-react";
import { Box } from "@mui/material";

interface ContextSwitcherProps {
  parentStyles?: any;
}

const ContextsSwitcher = ({ parentStyles }: ContextSwitcherProps) => {
  const { userStore } = useStores();
  const platformStore = useContext(PlatformContext);
  const currentModule = platformStore.currentModule;

  const getFallbackSwitchers = () => {
    if (userStore.clientCode && currentModule === "aso")
      return ["client", "appPlatform"];
    if (["ua", "creative", "social"].includes(currentModule))
      return ["client", "application"];
    return ["client"];
  };

  const currentRoute =
    platformStore.currentRoute &&
    routes.find(({ path }) => path === platformStore.currentRoute.path);
  const switchers = (currentRoute?.contextSwitchers ||
    getFallbackSwitchers()) as string[];

  const renderSwitcher = (type: string) => {
    switch (type) {
      case "client":
        return <ClientSwitcher />;
      case "application":
        return <ApplicationSwitcher />;
      case "appPlatform":
        return <ApplicationPlatformSwitcher />;
    }
  };
  return (
    <Box
      sx={{
        "display": "flex",
        "alignItems": "center",
        "> div": {
          "marginRight": "15px",
          "&:last-of-type": {
            marginRight: "15px",
          },
        },
        ...parentStyles,
      }}
    >
      {[...switchers].reverse().map((type) => renderSwitcher(type))}
    </Box>
  );
};

export default observer(ContextsSwitcher);
