// -- CORE
import React from "react";
import { Redirect } from "react-router-dom";
// -- STATIC
import { Route } from "v2/api/classes/route";
import { MenuMode } from "v2/api/types/menuMode";
import { UserPermissions } from "v2/api/types/UserPermissions";
import {
  routes as moduleRoutes,
  defaultRoutes,
} from "@upptic/module-directory";
import { getCookie } from "react-use-cookie";
import { getInitalUrl } from "../routingHelper";

// -- PAGES
const ApplicationCreate = React.lazy(() =>
  import("screens/Application/ApplicationCreate/ApplicationCreateContainer"),
);
const ApplicationOverview = React.lazy(() =>
  import(
    "screens/Application/ApplicationDetail/ApplicationOverview/ApplicationOverviewContainer"
  ),
);
const ApplicationControl = React.lazy(() =>
  import("screens/Application/ApplicationControl/ApplicationControlContainer"),
);
const ApplicationElementsIcons = React.lazy(() =>
  import(
    "screens/Application/ApplicationElements/ApplicationElementsIcons/ApplicationElementsIconsContainer"
  ),
);
const ApplicationElementsPreviews = React.lazy(() =>
  import(
    "screens/Application/ApplicationElements/ApplicationElementsPreviews/ApplicationElementsPreviewsContainer"
  ),
);
const ApplicationElementsPromoArts = React.lazy(() =>
  import(
    "screens/Application/ApplicationElements/ApplicationElementsPromoArts/ApplicationElementsPromoArtsContainer"
  ),
);
const ApplicationElementsFeatureGraphics = React.lazy(() =>
  import(
    "screens/Application/ApplicationElements/ApplicationElementsFeatureGraphic/ApplicationElementsFeatureGraphic"
  ),
);
const ApplicationElementsTexts = React.lazy(() =>
  import(
    "screens/Application/ApplicationElements/ApplicationElementsTexts/ApplicationElementsTextsContainer"
  ),
);
const ApplicationStaticInfo = React.lazy(() =>
  import(
    "screens/Application/ApplicationElements/ApplicationStaticInfo/ApplicationStaticInfoContainer"
  ),
);
const ApplicationRouting = React.lazy(() =>
  import(
    "screens/Application/ApplicationDetail/ApplicationRouting/ApplicationRoutingContainer"
  ),
);
const ApplicationUpdateIcons = React.lazy(() =>
  import(
    "screens/Application/ApplicationUpdate/ApplicationUpdateIcons/ApplicationUpdateIconsContainer"
  ),
);
const ApplicationUpdatePromoArts = React.lazy(() =>
  import(
    "screens/Application/ApplicationUpdate/ApplicationUpdatePromoArts/ApplicationUpdatePromoArtsContainer"
  ),
);
const ApplicationUpdateFeatureGraphics = React.lazy(() =>
  import(
    "screens/Application/ApplicationUpdate/ApplicationUpdateFeatureGraphics/ApplicationUpdateFeatureGraphics"
  ),
);
const ApplicationUpdatePreviews = React.lazy(() =>
  import(
    "screens/Application/ApplicationUpdate/ApplicationUpdatePreviews/ApplicationUpdatePreviewsContainer"
  ),
);
const ApplicationUpdateTexts = React.lazy(() =>
  import(
    "screens/Application/ApplicationUpdate/ApplicationUpdateTexts/ApplicationUpdateTextsContainer"
  ),
);
const ApplicationList = React.lazy(() =>
  import("screens/Application/ApplicationList/ApplicationListContainer"),
);
const ApplicationControlIcons = React.lazy(() =>
  import(
    "screens/Application/EditControlElements/EditControlIcons/ApplicationControlIconsContainer"
  ),
);
const ApplicationControlPromoArts = React.lazy(() =>
  import(
    "screens/Application/EditControlElements/EditControlPromoArts/ApplicationControlPromoArtsContainer"
  ),
);
const ApplicationControlFeatureGraphics = React.lazy(() =>
  import(
    "screens/Application/EditControlElements/EditControlFeatureGraphic/EditControlFeatureGraphic"
  ),
);
const ApplicationControlPreviews = React.lazy(() =>
  import(
    "screens/Application/EditControlElements/EditControlPreviews/ApplicationControlPreviewsContainer"
  ),
);
const ApplicationControlAdditionalPreviews = React.lazy(() =>
  import(
    "screens/Application/EditControlElements/EditControlAdditionalPreviews/ApplicationControlAdditionalPreviewsPage"
  ),
);
const EditControlTextsPage = React.lazy(() =>
  import(
    "screens/Application/EditControlElements/EditControlTexts/EditControlTextsPage"
  ),
);
const EditPaymentMethodPage = React.lazy(() =>
  import("screens/Billing/EditPaymentMethod/EditPaymentMethodPage"),
);
const EditBillingDetailsPage = React.lazy(() =>
  import("screens/Billing/EditBillingDetails/EditBillingDetailsPage"),
);
const UserBillingDetailsPage = React.lazy(() =>
  import("screens/Billing/UserBillingDetails/UserBillingDetailsPage"),
);
const EditSubscriptionsPage = React.lazy(() =>
  import("screens/Billing/EditSubscriptions/EditSubscriptionsPage"),
);
const NotificationList = React.lazy(() =>
  import("screens/Notification/NotificationList"),
);
const ApplicationPixelPage = React.lazy(() =>
  import("screens/ApplicationPixelPage/ApplicationPixelPage"),
);
const CostPlannerPage = React.lazy(() =>
  import("screens/CostPlanner/CostPlannerPage"),
);
const TestingAndQueuedAssetsPage = React.lazy(() =>
  import("screens/TestingAndQueuedAssets/TestingAndQueuedAssetsPage"),
);
const ApplicationAnalyticsPage = React.lazy(() =>
  import("screens/Analytics/AnalyticsPage"),
);
const SignUpActivate = React.lazy(() =>
  import("screens/Auth/SignUpActivate/SignUpActivateContainer"),
);

const Logout = React.lazy(() => import("screens/Auth/Logout/LogoutContainer"));
const SwitchClient = React.lazy(() =>
  import("screens/Auth/SwitchClientComponent"),
);

const AccessDenied = React.lazy(() => import("v2/pages/Auth/AccessDenied"));

const NoEntitlementsPage = React.lazy(() =>
  import("v2/pages/Auth/NoEntitlementsPage"),
);

const additionalRoutes = moduleRoutes.reduce<any>((acc, route, index) => {
  acc[index.toString()] = new Route<never>({
    path: route.path,
    component: route.component,
    exact: route.exact === undefined ? true : route.exact,
    menuMode: (route.menuMode as MenuMode) || MenuMode.APPLICATION_LISTING,
    permissions: route.permissions as UserPermissions[],
    layout: route.layout,
    supportsMobile: route.supportsMobile,
    formatTrackingInfo: route.formatTrackingInfo,
  });
  return acc;
}, {});

const moduleDefaultRoutes = defaultRoutes.reduce<any>((acc, route, index) => {
  acc[`default${index.toString()}`] = new Route<never>({
    path: route.path,
    component: route.component,
    exact: route.exact === undefined ? true : route.exact,
    menuMode: (route.menuMode as MenuMode) || MenuMode.APPLICATION_LISTING,
    permissions: route.permissions as UserPermissions[],
    layout: route.layout,
    formatTrackingInfo: route.formatTrackingInfo,
  });
  return acc;
}, {});

// -- ROUTES DEFINITION
export const authRoutes: Record<string, Route<any>> = {
  HOME: new Route<never>({
    path: "/",
    exact: true,
    render: ({ platformStore }: any) => {
      const locationBeforeLogout = getCookie("locationBeforeLogout");
      if (locationBeforeLogout) {
        document.cookie =
          "locationBeforeLogout=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        return <Redirect to={locationBeforeLogout} />;
      }
      return (
        <Redirect
          to={getInitalUrl(
            platformStore.modules,
            platformStore.currentClient?.code,
            platformStore.currentModule,
          )}
        />
      );
    },
    menuMode: MenuMode.APPLICATION_LISTING,
  }),
  APPLICATION_LIST: new Route<never>({
    path: "/applications",
    component: ApplicationList,
    exact: true,
    permissions: [UserPermissions.applicationOverview],
    menuMode: MenuMode.APPLICATION_LISTING,
  }),
  APPLICATION_CREATE: new Route<never>({
    path: "/applications/create",
    component: ApplicationCreate,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
    permissions: [UserPermissions.createAppPlatformProfile],
  }),
  APPLICATION_OVERVIEW: new Route<{ id: number | string }>({
    path: "/applications/:id/overview",
    component: ApplicationOverview,
    exact: true,
    permissions: [UserPermissions.readAppPlatformProfile],
    menuMode: MenuMode.APPLICATION_DETAILS,
  }),
  APPLICATION_CONTROL: new Route<{ id: number | string }>({
    path: "/applications/:id/control",
    component: ApplicationControl,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readAppPlatformProfile],
  }),
  APPLICATION_CONTROL_ICONS: new Route<{ id: number | string }>({
    path: `/applications/:id/control/icons`,
    component: ApplicationControlIcons,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  APPLICATION_CONTROL_PROMO_ARTS: new Route<{ id: number | string }>({
    path: `/applications/:id/control/promo-arts`,
    component: ApplicationControlPromoArts,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  APPLICATION_CONTROL_FEATURE_GRAPHIC: new Route<{
    id: number | string;
  }>({
    path: `/applications/:id/control/feature-graphic`,
    component: ApplicationControlFeatureGraphics,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  APPLICATION_CONTROL_PREVIEWS: new Route<{
    id: number | string;
    position: number;
  }>({
    path: `/applications/:id/control/previews/:position`,
    component: ApplicationControlPreviews,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  APPLICATION_CONTROL_ADDITIONALS_PREVIEWS: new Route<{
    id: number | string;
  }>({
    path: `/applications/:id/control/additionals`,
    component: ApplicationControlAdditionalPreviews,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  EDIT_CONTROL_TEXTS: new Route<{ id: number | string; type: string }>({
    path: `/applications/:id/control/:type`,
    component: EditControlTextsPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  APPLICATION_ELEMENTS_ICONS: new Route<{ id: string | number }>({
    path: `/applications/:id/elements/icons`,
    component: ApplicationElementsIcons,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readASOAsset],
  }),
  APPLICATION_ELEMENTS_PROMO_ARTS: new Route<{ id: string | number }>({
    path: `/applications/:id/elements/promo-arts`,
    component: ApplicationElementsPromoArts,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readASOAsset],
  }),
  APPLICATION_ELEMENTS_FEATURE_GRAPHICS: new Route<{
    id: string | number;
  }>({
    path: `/applications/:id/elements/feature-graphics`,
    component: ApplicationElementsFeatureGraphics,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readASOAsset],
  }),
  APPLICATION_ELEMENTS_PREVIEWS: new Route<{
    id: string | number;
    position: number;
  }>({
    path: `/applications/:id/elements/previews/:position`,
    component: ApplicationElementsPreviews,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readASOAsset],
  }),
  APPLICATION_ELEMENTS_TEXTS: new Route<{
    id: number | string;
    type: string;
  }>({
    path: `/applications/:id/elements/:type`,
    component: ApplicationElementsTexts,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readASOAsset],
  }),
  APPLICATION_STATIC_INFO: new Route<{ id: number | string }>({
    path: `/applications/:id/static-info`,
    component: ApplicationStaticInfo,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  APPLICATION_ROUTING: new Route<{ id: number | string }>({
    path: "/applications/:id/routing",
    component: ApplicationRouting,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readASORouting],
  }),
  APPLICATION_CREATE_ICONS: new Route<{ id: number | string }>({
    path: "/applications/:id/elements/icons/create",
    component: ApplicationUpdateIcons,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.createASOAsset],
  }),

  APPLICATION_CREATE_PROMO_ARTS: new Route<{ id: number | string }>({
    path: `/applications/:id/elements/promo-arts/create`,
    component: ApplicationUpdatePromoArts,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.createASOAsset],
  }),
  APPLICATION_CREATE_FEATURE_GRAPHICS: new Route<{
    id: number | string;
  }>({
    path: `/applications/:id/elements/feature-graphics/create`,
    component: ApplicationUpdateFeatureGraphics,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.createASOAsset],
  }),
  APPLICATION_CREATE_PREVIEWS: new Route<{
    id: number | string;
    position: number;
  }>({
    path: `/applications/:id/elements/previews/:position/create`,
    component: ApplicationUpdatePreviews,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.createASOAsset],
  }),
  APPLICATION_CREATE_TEXTS: new Route<{
    id: number | string;
    type: string;
  }>({
    path: `/applications/:id/elements/:type/create`,
    component: ApplicationUpdateTexts,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.createASOAsset],
  }),
  EDIT_PAYMENT_METHOD: new Route<never>({
    path: "/user/edit-payment-method",
    render: () => <EditPaymentMethodPage />,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
    permissions: [UserPermissions.updateAccountBilling],
  }),
  EDIT_BILLING_DETAILS: new Route<never>({
    path: "/user/edit-billings-details",
    component: EditBillingDetailsPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
    permissions: [UserPermissions.updateAccountBilling],
  }),
  USER_BILLING_DETAILS: new Route<never>({
    path: "/user/billing-details",
    component: UserBillingDetailsPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
    permissions: [UserPermissions.readAccountBilling],
  }),
  EDIT_SUBSCRIPTIONS: new Route<never>({
    path: "/user/edit-subscriptions",
    component: EditSubscriptionsPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
    permissions: [UserPermissions.updateAccountBilling],
  }),

  AUTH_SIGN_UP_ACTIVATE: new Route<{
    token: string;
    membershipId: string;
  }>({
    path: `/sign-up/accept-invite/:token`,
    component: SignUpActivate,
    exact: true,
    menuMode: MenuMode.NONE,
  }),

  NOTIFICATIONS: new Route<never>({
    path: "/notifications",
    component: NotificationList,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
  }),
  APPLICATION_PIXEL: new Route<{ id: number | string }>({
    path: `/applications/:id/pixel`,
    component: ApplicationPixelPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.updateAppPlatformProfile],
  }),
  APPLICATION_ANALYTICS: new Route<{ id: number | string }>({
    path: `/applications/:id/analytics`,
    component: ApplicationAnalyticsPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_DETAILS,
    permissions: [UserPermissions.readASOReporting],
  }),
  COST_PLANNER: new Route<never>({
    path: "/cost-planner",
    component: CostPlannerPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
  }),
  APPLICATION_TESTING_AND_QUEUED_ELEMENTS: new Route<{
    id: string | number;
  }>({
    path: `/applications/:id/testing-and-queued-elements`,
    component: TestingAndQueuedAssetsPage,
    exact: true,
    permissions: [UserPermissions.readASOTest],
    menuMode: MenuMode.APPLICATION_DETAILS,
  }),
  ACCESS_DENIED: new Route<never>({
    path: "/access-denied",
    component: AccessDenied,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
  }),
  NO_ENTITLEMENTS: new Route<never>({
    path: "/no-products",
    component: NoEntitlementsPage,
    exact: true,
    menuMode: MenuMode.APPLICATION_LISTING,
  }),
  ...additionalRoutes,
  ...moduleDefaultRoutes,

  LOGOUT: new Route<never>({
    path: "/logout",
    component: Logout,
    menuMode: MenuMode.APPLICATION_LISTING,
  }),

  SWITCH_CLIENT: new Route<never>({
    path: "/switchClient",
    component: SwitchClient,
    menuMode: MenuMode.APPLICATION_LISTING,
  }),

  PAGE_NOT_FOUND: new Route<never>({
    render: () => <Redirect to={"/"} />,
    menuMode: MenuMode.APPLICATION_LISTING,
  }),
};
