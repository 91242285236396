// -- CONST
import { Route } from "v2/api/classes/route";
import { authRoutes } from "./routesGroups/authRoutes";
import { failedPaymentsRoutes } from "./routesGroups/failedPaymentsRoutes";
import { nonAuthRoutes } from "./routesGroups/nonAuthRoutes";
import { userWithoutCardRoutes } from "./routesGroups/userWithoutCardRoutes";

export const allRoutes = [
  ...Object.values(nonAuthRoutes),
  ...Object.values(failedPaymentsRoutes),
  ...Object.values(userWithoutCardRoutes),
  ...Object.values(authRoutes),
] as Route<any>[];
