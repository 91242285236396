import React, { FC, memo } from "react";
import styled from "styled-components";
import { Field } from "react-final-form";

import validators from "utils/validators";
import formatters from "utils/formatters";

import themes from "components/themes";

import { TextInput, FormLabel, Switch } from "components/forms";
import { Icon, Icons } from "components";

// -- TYPES
interface Props {
  title: string;
  isControl: boolean;
  onSetDelete: () => any;
  changeControlSet: () => any;
  onSetNameChange: (event: any) => any;
  formFieldName: string;
  allowControlAction?: boolean;
  allowDelete?: boolean;
}

// -- COMPONENT
const SetUploadTitle: FC<Props> = ({
  title,
  isControl,
  onSetDelete,
  changeControlSet,
  onSetNameChange,
  formFieldName,
  allowControlAction = true,
  allowDelete = true,
}) => (
  <Wrapper>
    <Header>
      <Field
        name={formFieldName}
        validate={validators.required}
        formatOnBlur
        format={formatters.trim}
      >
        {({ input, meta }) => {
          return (
            <>
              <FormLabel spacing={false} name={input.name}>
                Set of Screens Name:
              </FormLabel>
              <TextInput
                {...input}
                placeholder={"Enter the Name"}
                value={title}
                error={meta.touched && meta.error}
                onChange={onSetNameChange}
                maxLength={30}
              />
            </>
          );
        }}
      </Field>
    </Header>

    <SetControls>
      {allowControlAction && (
        <SwitchWrapper>
          Mark as Control
          <Switch onChange={changeControlSet} value={isControl} />
        </SwitchWrapper>
      )}
      {allowControlAction && allowDelete && <Divider />}
      {allowDelete && (
        <Icon
          icon={Icons.faTrashAlt}
          color={themes.colors.white}
          onClick={onSetDelete}
        />
      )}
    </SetControls>
  </Wrapper>
);

// -- STYLED
const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin-bottom: 15px;

  input:first-of-type {
    width: 200px;
    margin-right: 10px;
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    font-size: 14px;
    margin: 0 5px 0 0;
    color: ${themes.colors.lightGrey};
  }

  input + div {
    font-weight: 400;
  }
`;

const SetControls = styled.div`
  display: flex;
  align-items: center;

  margin-right: 24px;

  label {
    margin-left: 16px;
  }

  svg {
    cursor: pointer;
  }
`;
const Divider = styled.span`
  border-left: 1px solid rgba(167, 167, 176, 0.5);

  height: 20px;

  margin: 0 10px 0 20px;
`;
const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    margin-left: 10px;
  }
`;

export default memo(SetUploadTitle);
