import * as React from "react";
import { Flex, Box } from "@rebass/grid";

import getConfidence from "utils/getConfidence";

import {
  GET_CONTROL_VIEWS_AND_CONVERSIONS_OLD,
  GET_CONTROL_VIEWS_AND_CONVERSIONS,
} from "api/queries";
import {
  Platform,
  Variation,
  Application,
  ApplicationControlAssets,
} from "api/models";

import {
  ChartWithControls,
  Section,
  PercentageChartWithData,
  StatisticsCardsList,
  ChartWithControlsOld,
} from "components";
import CurrentControlCreativesSection from "./components/CurrentControlCreativesSection";
import { ApplicationPagePreview } from "components/application/ApplicationPagePreview";
import { useDailyPlotCookie } from "../../../hooks/useDailyPlotCookie";

// -- TYPES
type ApplicationControlPageProps = {
  application: Application;
  history?: any;
  controlVariation: Variation;
  applicationControlAssets: ApplicationControlAssets;
  topVariation: Variation;
  topPageConversionRate: number | string;
  topPageConversionLift: number | string;
  zipFileUrl: string;
  hasTopPage: boolean;
  platform: Platform;
  refetchData: () => Promise<any>;
};

// -- COMPONENT
function ApplicationControlPage({
  application,
  history,
  controlVariation,
  applicationControlAssets,
  topVariation,
  topPageConversionRate,
  topPageConversionLift,
  zipFileUrl,
  hasTopPage,
  platform,
  refetchData,
}: ApplicationControlPageProps): JSX.Element {
  const useDailyPlot = useDailyPlotCookie();
  const renderControlPerformance = (): React.ReactNode => {
    const cardStyle = {
      pr: 10,
      pl: 10,
    };

    const statisticsCards = [
      {
        title: (
          <PercentageChartWithData value={controlVariation.conversionRate} />
        ),
        description: "Current Control Conversion Rate",
      },
      {
        title: <PercentageChartWithData value={topPageConversionRate} />,
        description: "Top Page Conversion Rate",
      },
      {
        title: <PercentageChartWithData value={topPageConversionLift} />,
        description: "Top Page Conversion Lift",
      },
      {
        title: <PercentageChartWithData value={getConfidence(topVariation)} />,
        description: "Top Page Confidence",
      },
    ];

    return (
      <StatisticsCardsList cardsList={statisticsCards} cardStyle={cardStyle} />
    );
  };

  return (
    <Flex flexDirection="column">
      <Box>
        <Section title="Control Performance" transparent>
          {renderControlPerformance()}
        </Section>
      </Box>

      <Box mt={20}>
        <Section title="Views and Conversion" transparentHeader>
          {useDailyPlot ? (
            <ChartWithControls
              applicationId={application.id}
              query={GET_CONTROL_VIEWS_AND_CONVERSIONS}
              conversionDataKey="controlConversionRates"
            />
          ) : (
            <ChartWithControlsOld
              applicationId={application.id}
              query={GET_CONTROL_VIEWS_AND_CONVERSIONS_OLD}
              conversionDataKey="controlConversionRatesOld"
            />
          )}
        </Section>
      </Box>

      <CurrentControlCreativesSection
        applicationId={application.id}
        applicationControlAssets={applicationControlAssets}
        controlVariation={controlVariation}
        platform={platform}
        history={history}
      />

      <ApplicationPagePreview
        controlPageUrl={controlVariation.url}
        hasTopPage={hasTopPage}
        topPageUrl={topVariation ? topVariation.url : ""}
        application={application}
        zipFileUrl={zipFileUrl}
        refetchPage={refetchData}
      />
    </Flex>
  );
}

export default ApplicationControlPage;
