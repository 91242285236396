import { round, isNumber } from "lodash";

export const percentFormat = (value: number) => {
  const percent = value * 100;

  return `${toLocaleNumber(percent, {
    numberOfDecimalPlaces: percent % 1 !== 0 ? 1 : 0,
  })}%`;
};

export const range = (start: number, stop: number, step: number) => {
  const rangeArray = [];
  for (let i = start; step > 0 ? i > stop : i < stop; i -= step) {
    rangeArray.push(i.toFixed(1));
  }
  return rangeArray;
};

export function ordinalFormat(value: number) {
  const variants = ["th", "st", "nd", "rd"];
  const i = value % 100;

  return value + (variants[(i - 20) % 10] || variants[i] || variants[0]);
}

export function roundWithDecimalPlaces(
  value: number,
  numberOfDecimalPlaces: number = 2,
): number | string {
  if (!isNumber(value)) {
    return NaN;
  }

  return round(value, 2).toFixed(numberOfDecimalPlaces);
}

export const convertToCurrency = (
  price: number = NaN,
  priceCurrency: string = "usd",
) =>
  price.toLocaleString(undefined, {
    style: "currency",
    currency: priceCurrency,
  });

export const bytesToMegaBytes = (bytes: number): number =>
  bytes / (1024 * 1024);

export const checkIsNumber = (value: unknown): boolean => {
  return typeof value === "number" && !isNaN(value);
};

export const toLocaleNumber = (
  numberToFormat: number,
  params?: { numberOfDecimalPlaces?: number },
): string =>
  numberToFormat.toLocaleString(undefined, {
    minimumFractionDigits: params?.numberOfDecimalPlaces,
    maximumFractionDigits: params?.numberOfDecimalPlaces,
  });

export default {
  percentFormat,
  range,
  ordinalFormat,
  roundWithDecimalPlaces,
  convertToCurrency,
  bytesToMegaBytes,
  checkIsNumber,
  toLocaleNumber,
};
