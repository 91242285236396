import gql from "graphql-tag";

export const switchClientMutation = gql`
  mutation switchClientMutation($clientCode: String!) {
    switchClient(clientCode: $clientCode) {
      token
    }
  }
`;

export type SwitchClientMutationResponse = {
  switchClient: {
    token: string;
  };
};
