import * as React from "react";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";

import { TextSection as TextSectionProp } from "../constants/sections";

import UISection from "components/Section";
import { TextAssetBox } from "./TextAssetBox";
import Button from "components/forms/Button";
import { PermissionRestriction } from "v2/components/PermissionRestriction";

// -- COMPONENT
export function TextSection({
  title,
  assets,
  url,
  editPermissions,
}: TextSectionProp): JSX.Element {
  return (
    <StyledSection title={title} transparent>
      <Wrapper>
        {assets.length &&
          assets.map((asset) => {
            return (
              <TextAssetBox
                asset={asset}
                key={`${asset.name}-${asset.status}`}
              />
            );
          })}
        <PermissionRestriction requiredPermissions={editPermissions}>
          <StyledLink to={url}>
            <Button>Edit</Button>
          </StyledLink>
        </PermissionRestriction>
      </Wrapper>
    </StyledSection>
  );
}

// -- UI
const StyledSection = styled(UISection)`
  width: 49%;
`;

const Wrapper = styled("div")`
  display: flex;

  flex-direction: column;

  padding: 15px 20px;

  background-color: ${(props: any) => props.theme.colors.card};

  align-items: flex-end;

  border-radius: 8px;
`;

const StyledLink = styled(Link)`
  margin-top: 25px;

  &:hover {
    text-decoration: none;
  }
`;
