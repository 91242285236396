import React from "react";
import styled from "styled-components";
import { Box } from "@rebass/grid";

import { Platform } from "api";

import { userpilotReloadUrl } from "utils/userpilot";

import { TextAssetStepData } from "./constants/textAssetStepData";
import { TextAssetData } from "./constants/textAssetData";

import { DividerVertical } from "components";
import TextAssetStepTitles from "./components/TextAssetStepTitles";
import TextAssetStepDescriptions from "./components/TextAssetStepDescriptions";
import TextAssetStepSubtitles from "./components/TextAssetStepSubtitles";

// -- TYPES
type Step4Props = {
  data: TextAssetStepData;
  onChange: (values: object) => void;
  platform: Platform;
};

// -- COMPONENT
function TextAssetStepComponent({
  data,
  platform,
  onChange,
}: Step4Props): JSX.Element {
  React.useEffect(() => {
    userpilotReloadUrl(`${window.location.href}/title-and-description`);
  }, []);

  const handleTitlesChanges = (changes: TextAssetData) => {
    onChange({
      title: changes.value,
      controlTitle: changes.control,
      assetsTitle: changes.values,
    });
  };

  const handleDescriptionsChanges = (changes: TextAssetData) => {
    onChange({
      description: changes.value,
      controlDescription: changes.control,
      assetsDescription: changes.values,
    });
  };

  const handleSubtitlesChanges = (changes: TextAssetData) => {
    onChange({
      subtitle: changes.value,
      controlSubtitle: changes.control,
      assetsSubtitle: changes.values,
    });
  };

  const titlesData = React.useMemo(
    () => ({
      value: data.title,
      control: data.controlTitle,
      values: data.assetsTitle,
    }),
    [data.title, data.controlTitle, data.assetsTitle],
  );

  const subtitlesData = React.useMemo(
    () => ({
      value: data.subtitle,
      control: data.controlSubtitle,
      values: data.assetsSubtitle,
    }),
    [data.subtitle, data.controlSubtitle, data.assetsSubtitle],
  );

  const descriptionsData = React.useMemo(
    () => ({
      value: data.description,
      control: data.controlDescription,
      values: data.assetsDescription,
    }),
    [data.description, data.controlDescription, data.assetsDescription],
  );

  return (
    <>
      <TextAssetStepTitles
        data={titlesData}
        onChange={handleTitlesChanges}
        platform={platform}
      />

      <Box alignSelf="stretch">
        <SeparateVertical />
      </Box>

      {platform === Platform.ios && (
        <>
          <TextAssetStepSubtitles
            data={subtitlesData}
            onChange={handleSubtitlesChanges}
          />
          <Box alignSelf="stretch">
            <SeparateVertical />
          </Box>
        </>
      )}

      <TextAssetStepDescriptions
        data={descriptionsData}
        onChange={handleDescriptionsChanges}
        platform={platform}
      />
    </>
  );
}

// -- STYLED
const SeparateVertical = styled(DividerVertical as any)`
  margin: 30px 0;
`;

export const TextAssetStep = React.memo(TextAssetStepComponent);
