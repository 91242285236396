import React, { useState, memo } from "react";
import { isArray, get, max } from "lodash";
import styled from "styled-components";

import themes from "components/themes";

import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ReferenceLine,
} from "recharts";

import { ChartConfiguration as ChartProps } from "api/models";

import ChartLegend from "./ChartLegend";
import { percentAxisFormat } from "./helpers";

// -- COMPONENT
const Chart: React.FC<ChartProps> = ({
  dataSet,
  aggregatedTooltipData,
  legendElements,
  xAxisLabelByField,
  xAxisComponent,
  tooltipComponent,
  referenceLines,
  yAxisDomain,
  showGradient = true,
  yAxisFormatter = percentAxisFormat,
  xAxisFormatter,
  reversedX = false,
  reversedY = false,
  allowEscapeViewBox = false,
  height = 450,
  width = "100%", // Library bug, to use responsive container you need to use 99%
  xAxisTicks,
  layout = "horizontal",
  yAxisLabelByField,
  yAxisTicks,
  xAxisType,
  yAxisType,
  xAxisLabel,
  yAxisLabel,
  xAxisDomain,
  initialDisabledDataIndexes = [],
}) => {
  const [disabledAreas, setDisabledAreas] = useState<string[]>(
    initialDisabledDataIndexes,
  );

  const defGradients = (): React.ReactNode => {
    if (!isArray(legendElements)) {
      return null;
    }

    return (
      <defs>
        {legendElements.map(({ color, dataIndex }) => (
          <linearGradient
            id={dataIndex}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
            key={`gradient-${color}-${dataIndex}`}
          >
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.1} />
          </linearGradient>
        ))}
      </defs>
    );
  };

  const renderDataArea = (): React.ReactNode => {
    if (!isArray(legendElements)) {
      return null;
    }

    const onlyEnabledElements = legendElements.filter(
      (elem) => !disabledAreas.includes(elem.dataIndex),
    );

    return onlyEnabledElements.map(({ color, dataIndex }) => (
      <Area
        type="monotone"
        key={dataIndex}
        dataKey={dataIndex}
        stroke={color}
        strokeWidth={2}
        fillOpacity={0.8}
        fill={`url(#${dataIndex})`}
      />
    ));
  };

  const changeAreaVisibility = (dataIndex: string): void => {
    const newDisabledAreasList: string[] = disabledAreas.includes(dataIndex)
      ? disabledAreas.filter((elem) => elem !== dataIndex)
      : [...disabledAreas, dataIndex];

    setDisabledAreas(newDisabledAreasList);
  };

  const tooltipAvailableLegendElements = isArray(legendElements)
    ? legendElements.filter((elem) => !disabledAreas.includes(elem.dataIndex))
    : null;

  const chartTooltip = tooltipComponent
    ? React.cloneElement(tooltipComponent, {
        legendElements: tooltipAvailableLegendElements,
        aggregatedData: aggregatedTooltipData,
        referenceLines,
      })
    : undefined;

  const renderReferenceLines = (): React.ReactNode => {
    if (!Array.isArray(referenceLines)) {
      return null;
    }

    return referenceLines
      .filter((singleRefLine) => !disabledAreas.includes(singleRefLine.type))
      .map((singleReferencLine) => {
        const { axisDirection, axisValue, color, type } = singleReferencLine;
        return (
          <ReferenceLine
            key={`reference-line-${axisDirection}-${axisValue}-${type}`}
            {...{ [axisDirection]: axisValue }}
            stroke={color || themes.colors.chartLightPurple}
            label={undefined}
            strokeWidth={2}
          />
        );
      });
  };

  const getGraphDomain = (): [number, number] => {
    if (!isArray(legendElements)) {
      return [0, 100];
    }

    const onlyEnabledLines = legendElements.filter(
      (elem) => !disabledAreas.includes(elem.dataIndex),
    );

    let highestElement = 0;

    onlyEnabledLines.forEach((singleLegendElement) => {
      const lineDataIndex = singleLegendElement.dataIndex;
      const linesValues = dataSet.map((dataSetElem) =>
        parseFloat(get(dataSetElem, lineDataIndex) as string),
      );
      const lineHighestElement = max(linesValues);

      if (lineHighestElement && lineHighestElement > highestElement) {
        highestElement = lineHighestElement;
      }
    });

    highestElement = Math.round(highestElement * 1.2);
    if (highestElement > 100) {
      highestElement = 100;
    }

    return [0, highestElement || 100];
  };

  if (!yAxisDomain) {
    yAxisDomain = getGraphDomain();
  }

  let allowEscapeViewBoxValue;
  if (allowEscapeViewBox) {
    allowEscapeViewBoxValue = { x: true, y: true };
  }

  return (
    <ChartWrapper>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          data={dataSet}
          margin={{
            top: 15,
            right: yAxisLabel ? 20 : 0,
            bottom: 5,
            left: 0,
          }}
          layout={layout}
        >
          {showGradient && defGradients()}
          <XAxis
            dataKey={xAxisLabelByField}
            tickLine={false}
            allowDuplicatedCategory={false}
            tick={xAxisComponent}
            ticks={xAxisTicks}
            reversed={reversedX}
            type={xAxisType}
            tickFormatter={!xAxisTicks ? xAxisFormatter : undefined}
            interval={0}
            domain={xAxisDomain}
          />
          <YAxis
            dataKey={yAxisLabelByField}
            ticks={yAxisTicks}
            tickLine={false}
            tick={{ fill: themes.colors.lightGrey }}
            tickFormatter={!yAxisTicks ? yAxisFormatter : undefined}
            domain={yAxisDomain}
            interval={"preserveStartEnd"}
            reversed={reversedY}
            type={yAxisType}
          />
          <CartesianGrid stroke={"#47475b"} />
          <Tooltip
            isAnimationActive={false}
            content={chartTooltip}
            allowEscapeViewBox={allowEscapeViewBoxValue}
          />
          {renderDataArea()}
          {referenceLines && renderReferenceLines()}
        </AreaChart>
      </ResponsiveContainer>

      {yAxisLabel && (
        <LabelY chartHeight={height}>
          <LabelYContent>{yAxisLabel}</LabelYContent>
        </LabelY>
      )}

      {xAxisLabel && <LabelX>{xAxisLabel}</LabelX>}

      <ChartLegend
        onDataVisibleChange={changeAreaVisibility}
        elements={legendElements}
        disabledElements={disabledAreas}
      />
    </ChartWrapper>
  );
};

// -- STYLED
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;

  .recharts-tooltip-wrapper {
    z-index: 10;
  }
`;

const LabelY = styled.div<{ chartHeight: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;

  white-space: nowrap;

  font-weight: bold;

  width: 21px;

  position: absolute;
  right: 0;
  /* chart height - labelY width */
  top: ${(props: any) => (props.chartHeight - 21) / 2}px;
`;

const LabelYContent = styled.span`
  transform: rotate(-90deg);
`;

const LabelX = styled.span`
  display: flex;

  width: 100%;

  margin-left: 60px;

  text-transform: uppercase;

  font-weight: bold;
`;

export default memo(Chart);
