import * as React from "react";
import { Box } from "@rebass/grid";
import { Field, FieldMetaState, FieldInputProps } from "react-final-form";
import { styled } from "@mui/system";
import moment from "moment";

import { Platform } from "api/models";
import { ReviewDomElement } from "api/models/reviews/reviewDomElement";
import { ReviewField } from "api/models/reviews/reviewField";

import { convertDateToDisplayFormat, dateFormat } from "utils/dates";

import { DatePicker, NumberInput, TextInput, TextArea } from "components/forms";
import { DetailCard } from "components/application/forms/components/DetailCard";
import { FormField, Label } from "./StyledComponents";

// -- TYPES
type ApplicationSingleReviewStaticPageProps = {
  reviewElement: ReviewDomElement;
  platform: Platform;
  fieldPrefix: string;
  isEditOn: boolean;
};

export function ApplicationSingleReviewStaticPage({
  reviewElement,
  platform,
  fieldPrefix,
  isEditOn,
}: ApplicationSingleReviewStaticPageProps): JSX.Element {
  const renderField = React.useCallback(
    (
      singleField: ReviewField,
      input: FieldInputProps<any>,
      meta: FieldMetaState<any>,
    ) => {
      let Component = null;
      const { value, onChange } = input;
      const textAssetOnChange = (e: React.BaseSyntheticEvent) => {
        onChange(e.target.value);
      };

      if (singleField.component === DatePicker) {
        Component = (
          <DatePicker
            id="datepicker"
            initialDate={value}
            {...input}
            externalError={meta.touched && meta.error}
            maxDay={moment()}
          />
        );
      } else if (singleField.component === NumberInput) {
        Component = (
          <NumberInput
            placeholder={singleField.placeholder}
            maxLength={singleField.maxLength}
            {...input}
            error={meta.touched && meta.error}
          />
        );
      } else if (singleField.component === TextInput) {
        Component =
          platform === Platform.android &&
          singleField.fieldName === "title" ? undefined : (
            <TextInput
              placeholder={singleField.placeholder}
              {...input}
              onChange={textAssetOnChange}
              error={meta.touched && meta.error}
            />
          );
      } else if (singleField.component === TextArea) {
        Component = (
          <TextArea
            placeholder={singleField.placeholder}
            {...input}
            onChange={textAssetOnChange}
            error={meta.touched && meta.error}
          />
        );
      } else {
        return null;
      }

      return Component;
    },
    [platform],
  );

  const renderNoEditFieldValue = (value: any, fieldName: string) => {
    if (fieldName !== "date" || !value) {
      return value || "N/A";
    }

    return convertDateToDisplayFormat(value, dateFormat, "DD-MM-YYYY");
  };

  return (
    <>
      <ReviewBox key={reviewElement.label}>
        <ReviewNumber>{reviewElement.label}</ReviewNumber>
        <Content>
          {reviewElement.fields.map((singleField) => {
            const fieldName = `${fieldPrefix}.${singleField.fieldName}`;

            if (
              platform === Platform.android &&
              singleField.fieldName === "title"
            ) {
              return null;
            }

            return (
              <FieldBox width={singleField.width} key={singleField.fieldName}>
                <DetailCard>
                  <Label>{singleField.label}</Label>
                  <Field
                    key={fieldName}
                    name={fieldName}
                    validate={singleField.validators}
                  >
                    {({ input, meta }) =>
                      isEditOn ? (
                        renderField(singleField, input, meta)
                      ) : (
                        <FormField>
                          {renderNoEditFieldValue(
                            input.value,
                            singleField.fieldName,
                          )}
                        </FormField>
                      )
                    }
                  </Field>
                </DetailCard>
              </FieldBox>
            );
          })}
        </Content>
      </ReviewBox>
    </>
  );
}

// -- STYLED
const Content = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin: 15px -16px 30px;
`;

const FieldBox = styled(Box)`
  padding: 0 16px;

  margin-bottom: 30px;
`;

const ReviewBox = styled("div")``;

const ReviewNumber = styled("div")`
  color: ${(props: any) => props.theme.colors.white};

  font-weight: bold;
`;
