import { styled } from "@mui/system";
import { Box } from "@rebass/grid";

export const DetailCard = styled(Box)`
  display: flex;
  flex-direction: column;

  width: 100%;

  border-radius: 8px;

  background-color: ${(props: any) => props.theme.colors.card};
`;
