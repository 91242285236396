import React from "react";
import styled from "styled-components";

import theme from "components/themes";
import FormError from "./FormError";

function TextArea(props: any) {
  const valid = !props.error;

  return (
    <>
      <Input {...props} id={props.name} valid={valid} />
      {!valid && <FormError>{props.error}</FormError>}
    </>
  );
}

const Input = styled.textarea<{ valid: boolean }>`
  outline: none;
  width: 100%;
  padding: 12px 15px;
  height: 88px;
  border-radius: 2px;
  border: solid 1px ${theme.colors.background};
  background-color: ${theme.colors.inputs};
  color: ${theme.colors.white};
  font-size: 16px;
  opacity: 0.8;

  ${(props) =>
    !props.valid &&
    `
    box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
    border: 1px solid ${theme.colors.notificationRed};
  `};

  ::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;

export default TextArea;
