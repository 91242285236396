import { ApplicationListing, Platform } from "api/models";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { SingleSideMenuItem } from "../sideMenuItem";

import { Icons } from "components/Icon";
import { UserPermissions } from "v2/api/types";

const appOverviewMenuElement = (
  isMenuCollapsed: boolean,
  application: ApplicationListing,
  permissions?: UserPermissions[] | null,
): SingleSideMenuItem => {
  const items = [];

  if (permissions?.includes(UserPermissions.readASOTest)) {
    items.push({
      title: "Testing & Queued Assets",
      route: authRoutes.APPLICATION_TESTING_AND_QUEUED_ELEMENTS.getUrlWithParams(
        {
          id: application.id,
        },
      ),
    });
  }

  if (permissions?.includes(UserPermissions.readApplication)) {
    items.push(
      {
        title: "Static Info",
        route: authRoutes.APPLICATION_STATIC_INFO.getUrlWithParams({
          id: application.id,
        }),
      },
      {
        title: "User Behavior",
        route: authRoutes.APPLICATION_ANALYTICS.getUrlWithParams({
          id: application.id,
        }),
      },
    );
  }

  if (isMenuCollapsed) {
    items.unshift({
      title: "App Overview",
      route: authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({
        id: application.id,
      }),
    });
  }

  const additionalRoutes = items.map((item) => item.route);

  if (authRoutes.APPLICATION_PIXEL.hasUserAccess(permissions || [])) {
    additionalRoutes.push(
      authRoutes.APPLICATION_PIXEL.getUrlWithParams({
        id: application.id,
      }),
    );
  }

  return {
    title: "App Overview",
    icon: Icons.faRocket,
    route: authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({
      id: application.id,
    }),
    additionalRoutes,
    items,
  };
};

const appControlMenuElement = (
  isMenuCollapsed: boolean,
  application: ApplicationListing,
): SingleSideMenuItem => {
  const controlMenu: SingleSideMenuItem = {
    title: "Control",
    icon: Icons.faShapes,
    route: authRoutes.APPLICATION_CONTROL.getUrlWithParams({
      id: application.id,
    }),
  };

  if (isMenuCollapsed) {
    controlMenu.items = [
      {
        title: "Control",
        route: authRoutes.APPLICATION_CONTROL.getUrlWithParams({
          id: application.id,
        }),
      },
    ];
  }

  return controlMenu;
};

const appElementsMenuElement = (
  application: ApplicationListing,
): SingleSideMenuItem => {
  const items = [
    {
      title: "Icon",
      route: authRoutes.APPLICATION_ELEMENTS_ICONS.getUrlWithParams({
        id: application.id,
      }),
      additionalRoutes: [
        authRoutes.APPLICATION_CREATE_ICONS.getUrlWithParams({
          id: application.id,
        }),
      ],
    },
    application.platform === Platform.ios
      ? {
          title: "Promo Art",
          route: authRoutes.APPLICATION_ELEMENTS_PROMO_ARTS.getUrlWithParams({
            id: application.id,
          }),
          additionalRoutes: [
            authRoutes.APPLICATION_CREATE_PROMO_ARTS.getUrlWithParams({
              id: application.id,
            }),
          ],
        }
      : {
          title: "Feature Graphic",
          route: authRoutes.APPLICATION_ELEMENTS_FEATURE_GRAPHICS.getUrlWithParams(
            { id: application.id },
          ),
          additionalRoutes: [
            authRoutes.APPLICATION_CREATE_FEATURE_GRAPHICS.getUrlWithParams({
              id: application.id,
            }),
          ],
        },
    {
      title: "1st Screen / Screen Sets",
      route: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
        id: application.id,
        position: 1,
      }),
      additionalRoutes: [
        authRoutes.APPLICATION_CREATE_PREVIEWS.getUrlWithParams({
          id: application.id,
          position: 1,
        }),
      ],
    },
    {
      title: "2nd Screen",
      route: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
        id: application.id,
        position: 2,
      }),
      additionalRoutes: [
        authRoutes.APPLICATION_CREATE_PREVIEWS.getUrlWithParams({
          id: application.id,
          position: 2,
        }),
      ],
    },
    {
      title: "3rd Screen",
      route: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
        id: application.id,
        position: 3,
      }),
      additionalRoutes: [
        authRoutes.APPLICATION_CREATE_PREVIEWS.getUrlWithParams({
          id: application.id,
          position: 3,
        }),
      ],
    },
    {
      title: "Title",
      route: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
        id: application.id,
        type: "titles",
      }),
      additionalRoutes: [
        authRoutes.APPLICATION_CREATE_TEXTS.getUrlWithParams({
          id: application.id,
          type: "titles",
        }),
      ],
    },
    {
      title: "Description",
      route: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
        id: application.id,
        type: "descriptions",
      }),
      additionalRoutes: [
        authRoutes.APPLICATION_CREATE_TEXTS.getUrlWithParams({
          id: application.id,
          type: "descriptions",
        }),
      ],
    },
  ];

  if (application.platform === Platform.ios) {
    items.splice(6, 0, {
      title: "Subtitle",
      route: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
        id: application.id,
        type: "subtitles",
      }),
      additionalRoutes: [
        authRoutes.APPLICATION_CREATE_TEXTS.getUrlWithParams({
          id: application.id,
          type: "subtitles",
        }),
      ],
    });
  }

  return {
    title: "Elements",
    icon: Icons.faClipboardList,
    route: authRoutes.APPLICATION_ELEMENTS_ICONS.getUrlWithParams({
      id: application.id,
    }),
    additionalRoutes: items
      .map((item) => item.route)
      .concat(items.map((item) => item.additionalRoutes).flat(1)),
    items,
  };
};

const appRoutingMenuElement = (
  isMenuCollapsed: boolean,
  application: ApplicationListing,
): SingleSideMenuItem => {
  const routingMenu: SingleSideMenuItem = {
    title: "Routing & Options",
    icon: Icons.faCompass,
    route: authRoutes.APPLICATION_ROUTING.getUrlWithParams({
      id: application.id,
    }),
  };

  if (isMenuCollapsed) {
    routingMenu.items = [
      {
        title: "Routing & Options",
        route: authRoutes.APPLICATION_ROUTING.getUrlWithParams({
          id: application.id,
        }),
      },
    ];
  }

  return routingMenu;
};

export const applicationMenuElement = (
  isMenuCollapsed: boolean,
  application: ApplicationListing,
  permissions?: UserPermissions[] | null,
): SingleSideMenuItem[] => {
  const items = [];

  if (
    permissions?.includes(UserPermissions.readApplication) ||
    permissions?.includes(UserPermissions.readASOTest)
  ) {
    items.push(
      appOverviewMenuElement(isMenuCollapsed, application, permissions),
    );
  }

  if (permissions?.includes(UserPermissions.readApplication)) {
    items.push(appControlMenuElement(isMenuCollapsed, application));
  }

  if (permissions?.includes(UserPermissions.readASOAsset)) {
    items.push(appElementsMenuElement(application));
  }

  if (permissions?.includes(UserPermissions.readASORouting)) {
    items.push(appRoutingMenuElement(isMenuCollapsed, application));
  }

  if (isMenuCollapsed) {
    return items;
  }

  return [
    {
      title: application.name,
      items,
    },
  ];
};
