import { observable, action, makeObservable } from "mobx";
import { PlatformStore } from "@upptic/module-directory";

import { AnalyticsService } from "services";

import { client } from "api/client";

import { idenfifyUser } from "utils/userpilot";

import {
  CurrentClient,
  getCurrentUser,
  GetCurrentUserResponse,
} from "v2/api/queries/user/getCurrentUser";

class UserStore implements TUserStore {
  public validPaymentMethod: boolean = false;
  public isOverdue: boolean = false;
  public permissionError: boolean = false;
  public userName: string = "";
  public userId: number | null = null;
  public isUserMenuCallapsed: boolean = false;
  public isLoggedIn: boolean = false;
  public clientCode: string | null = null;
  public asoAccountId: string | null = null;
  public currentClient: CurrentClient | null = null;
  public platformStore?: PlatformStore;
  public showPlatformSpinner?: boolean = false;

  constructor() {
    makeObservable(this, {
      validPaymentMethod: observable,
      isOverdue: observable,
      permissionError: observable,
      userName: observable,
      userId: observable,
      isUserMenuCallapsed: observable,
      isLoggedIn: observable,
      clientCode: observable,
      asoAccountId: observable,
      currentClient: observable,
      showPlatformSpinner: observable,
      setShowPlatformSpinner: observable,
      clearStorage: action,
      setValidPaymentMethod: action,
      setIsOverdue: action,
      loadUser: action,
    });
  }

  setValidPaymentMethod(isFailed: boolean) {
    this.validPaymentMethod = isFailed;
  }

  setIsOverdue(isOverdue: boolean) {
    this.isOverdue = isOverdue;
  }

  setIsLoggedIn(isLoggedIn: boolean) {
    this.isLoggedIn = isLoggedIn;
  }

  setShowPlatformSpinner(showPlatformSpinner: boolean) {
    this.showPlatformSpinner = showPlatformSpinner;
  }

  async loadUser(showLoadingSpinner: boolean = false) {
    if (showLoadingSpinner) this.showPlatformSpinner = true;
    try {
      const result = await client.query<GetCurrentUserResponse>({
        query: getCurrentUser,
        fetchPolicy: "no-cache",
      });

      const {
        currentUser,
        currentUserFrontendPermissions,
        currentUserClients,
      } = result.data;

      if (
        currentUser.emailAddress &&
        window.location.pathname.startsWith("/external")
      ) {
        window.location.href = window.location.href.replace("/external", "");
        return;
      }

      this.userName = currentUser.emailAddress;
      this.clientCode = currentUser.clientCode;

      this.validPaymentMethod =
        currentUser.client?.hasValidPaymentMethod || false;
      this.isOverdue = currentUser.client?.hasOverdueInvoice || false;

      this.asoAccountId = currentUser.client?.asoAccountId || null;
      this.currentClient = currentUser.client || null;

      AnalyticsService.setUser(
        currentUser.emailAddress,
        currentUser.clientCode,
      );

      idenfifyUser(currentUser.emailAddress, {
        name: currentUser.emailAddress,
      });

      if (this.platformStore) {
        this.platformStore.setStateFromUserStore(
          currentUser,
          this.currentClient,
          currentUserFrontendPermissions,
          currentUserClients,
        );
        await this.platformStore.loadApplications();
      }

      this.isLoggedIn = true;
    } catch (e) {
      console.error(e);
      this.permissionError = true;
    } finally {
      if (showLoadingSpinner) this.showPlatformSpinner = false;
    }
  }

  clearStorage() {
    this.isLoggedIn = false;
    this.validPaymentMethod = false;
    this.isOverdue = false;
    this.permissionError = false;
    this.userName = "";
    this.userId = null;
    this.isUserMenuCallapsed = false;
    this.clientCode = null;
  }
}

export default UserStore;

export interface TUserStore {
  platformStore?: PlatformStore;
  validPaymentMethod: boolean;
  setValidPaymentMethod: (isFailed: boolean) => void;
  isOverdue: boolean;
  setIsOverdue: (isOverdue: boolean) => void;
  permissionError: boolean;
  userName: string;
  userId: number | null;
  clientCode: string | null;
  asoAccountId: string | null;
  currentClient: CurrentClient | null;
  loadUser: (showLoadingSpinner?: boolean) => Promise<void>;
  isLoggedIn: boolean;
  showPlatformSpinner?: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  setShowPlatformSpinner: (showPlatformSpinner: boolean) => void;
  clearStorage: () => void;
}
