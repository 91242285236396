import * as React from "react";
import { HeaderGroup } from "react-table";
import { styled } from "@mui/system";

import { SingleTableColumn } from "../constants/singleTableColumn";

import { ThComponent } from "./ThComponent";
import { TrComponent } from "./TrComponent";
import { Icon, Icons } from "components";

// -- TYPES
type TableHeaderProps = {
  headerGroups: HeaderGroup<object>[];
};

// -- COMPONENT
export function TableHeader({ headerGroups }: TableHeaderProps): JSX.Element {
  const headerProps = (column: SingleTableColumn<unknown> & any) => {
    let headerProperties: any = {};

    if (
      column.sortAvailable &&
      typeof column.getSortByToggleProps === "function"
    ) {
      headerProperties = {
        ...headerProperties,
        ...column.getSortByToggleProps(),
      };
    }

    return column.getHeaderProps(headerProperties);
  };

  const renderFilterControls = (
    column: SingleTableColumn<unknown> & any,
  ): JSX.Element | null => {
    if (!column.sortAvailable) {
      return null;
    }

    let sortIcon = Icons.faSort;

    if (column.isSorted) {
      sortIcon = column.isSortedDesc ? Icons.faSortDown : Icons.faSortUp;
    }

    return <SortIcon icon={sortIcon} />;
  };

  return (
    <Thead>
      {headerGroups.map((headerGroup, index) => (
        <TrComponent
          {...headerGroup.getHeaderGroupProps()}
          key={`header-group-${index}`}
        >
          {headerGroup.headers.map((column) => (
            <ThComponent {...headerProps(column)} key={column.id}>
              {column.render("Header")}
              {renderFilterControls(column)}
            </ThComponent>
          ))}
        </TrComponent>
      ))}
    </Thead>
  );
}

// -- STYLED
const Thead = styled("thead")`
  background-color: ${(props: any) =>
    props.theme.table.colors.tHeadBackgroundColor};

  tr {
    border-width: 0px;
  }

  & > tr:first-of-type {
    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }
  }

  & > tr:first-of-type {
    td:first-child {
      border-top-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
    }
  }
`;

const SortIcon = styled(Icon)`
  margin-left: 10px;
`;
