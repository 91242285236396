import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

function FormSection({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled.div`
  background-color: #13132b;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 8px;
`;

export default FormSection;
