import React, { FC, useState, useContext } from "react";
import styled from "styled-components";
import { isArray } from "lodash";

import { Section, Icons, Icon, MessageBox } from "components";
import { FormLabel, Button, FormFooter } from "components/forms";

import { Price } from "screens/Billing/UserBillingDetails/constants/types";

import { convertToCurrency } from "utils/numbers";

import { ModalContext } from "contexts";

import theme from "components/themes";

// -- TYPES
interface Props {
  onSubmit: (subscribedAppsNumber: number) => void;
  onDiscard: () => void;
  initialSubscriptionsNumber: number;
  pricing: Price[];
}

// -- COMPONENT
export const EditSubscriptionsForm: FC<Props> = ({
  onSubmit,
  onDiscard,
  initialSubscriptionsNumber,
  pricing,
}) => {
  const [subscribedAppsNumber, setSubscribedAppsNumber] = useState<number>(
    initialSubscriptionsNumber,
  );

  const setModal = useContext(ModalContext);

  const changeSubscriptionNumber = (
    action: "increase" | "decrease",
  ) => (): void => {
    const newValue =
      action === "increase"
        ? subscribedAppsNumber + 1
        : subscribedAppsNumber - 1;
    setSubscribedAppsNumber(newValue);
  };

  const onFormSubmit = (e: any): void => {
    e.preventDefault();
    setModal({
      type: "set",
      props: {
        title: "Are you sure?",
        content:
          "These changes may result in additional payments on your monthly invoice and may be undone in the next billing period",
        onConfirm: onModalSubmit,
        confirmText: "Confirm",
      },
    });
  };

  const renderSubscriptionOptions = () => {
    if (!isArray(pricing)) {
      return "No data.";
    }

    const subsOptions = pricing
      .filter((singlePriceType) => singlePriceType?.priceType === "RECURRING")
      .sort((a, b) => b.unitAmount - a.unitAmount);

    return !subsOptions.length
      ? "No data."
      : subsOptions.map((singleOption) => (
          <p key={singleOption?.product?.name}>
            {`${
              singleOption?.product?.name || "Unnamed Subscription"
            } ${convertToCurrency(
              singleOption.unitAmount / 100,
              singleOption.currency,
            )} / month`}
          </p>
        ));
  };

  const onModalSubmit = () => onSubmit(subscribedAppsNumber);

  let message = "";

  if (subscribedAppsNumber < initialSubscriptionsNumber) {
    message = "This change will take effect from next billing period";
  } else if (subscribedAppsNumber > initialSubscriptionsNumber) {
    message =
      "This change will take effect immediately and payment for it will be reduced by the time elapsed in the current billing period";
  }

  return (
    <form onSubmit={onFormSubmit}>
      <Section transparentHeader>
        <Column>
          <FormLabel spacing={false}>
            Number of Subscribed Apps Seats:
          </FormLabel>
          <ControlsWrapper>
            <Button
              onClick={changeSubscriptionNumber("decrease")}
              disabled={subscribedAppsNumber === 0}
            >
              <StyledIcon icon={Icons.faMinus} />
            </Button>
            {subscribedAppsNumber}
            <Button onClick={changeSubscriptionNumber("increase")}>
              <StyledIcon icon={Icons.faPlus} />
            </Button>
          </ControlsWrapper>
        </Column>
        <Column>
          <FormLabel spacing={false}>Pricing:</FormLabel>
          {renderSubscriptionOptions()}
        </Column>
        {message && <MessageBox closeable={false}>{message}</MessageBox>}
      </Section>
      <FormFooter
        onCancel={onDiscard}
        submitDisabled={initialSubscriptionsNumber === subscribedAppsNumber}
      />
    </form>
  );
};

// -- STYLED
const ControlsWrapper = styled.div`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  button {
    min-width: 44px;

    padding: 0 13px;
    margin: 0 20px;
  }

  button:first-of-type {
    margin-left: 0px;
  }
`;
const StyledIcon = styled(Icon)`
  font-size: 18px;

  color: ${theme.colors.white};
`;
const Column = styled.div`
  display: inline-flex;

  flex-direction: column;

  padding: 0 10px;
  margin-bottom: 15px;

  width: 50%;

  label {
    font-weight: bold;
  }

  p {
    color: #9d9da8;

    line-height: 1.43;

    margin: 0;
  }
`;
