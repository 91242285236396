import * as React from "react";
import { styled } from "@mui/system";
import { Flex } from "@rebass/grid";
import hexToRgba from "hex-to-rgba";

import config from "config";
import { Route, RouteType } from "api/models";
import themes from "components/themes";

import { toLocaleNumber } from "utils/numbers";

import Section from "components/Section";
import { RoutingRow } from "components/application";
import Icon, { Icons } from "components/Icon";
import { Tooltip } from "components";
import { Button } from "components/forms";

// -- PROPS
type TrafficVolumeSectionProps = {
  controlRoute: Route;
  newTestsRoute: Route;
  actualStorePageRoute: Route;
  customRoutes: Route[];
  editable: boolean;
  onChange: ((route: Route) => void) | null;
  onDelete: ((route: Route) => void) | null;
  onCreate: ((route: Route) => void) | null;
};

// -- COMPONENT
export function TrafficVolumeSection({
  controlRoute,
  newTestsRoute,
  actualStorePageRoute,
  customRoutes,
  editable,
  onChange,
  onDelete,
  onCreate,
}: TrafficVolumeSectionProps): JSX.Element {
  const [routes, setRoutes] = React.useState({
    controlRoute,
    newTestsRoute,
    actualStorePageRoute,
  });
  const [newRoutes, setNewRoutes] = React.useState<Route[]>([]);

  let totalWeight = routes.controlRoute.weight;
  totalWeight += routes.newTestsRoute.weight;
  totalWeight += routes.actualStorePageRoute.weight;
  totalWeight += customRoutes.map((r) => r.weight).reduce((a, b) => a + b, 0);

  const totalTestTraffic =
    routes.controlRoute.visits + routes.newTestsRoute.visits;

  const totalTestAndExternalTraffic =
    totalTestTraffic +
    customRoutes.map((r) => r.visits).reduce((a, b) => a + b, 0) +
    (routes.newTestsRoute.discardedVisits || 0) +
    (routes.actualStorePageRoute.visits || 0);

  const [deletedRoutes, deleteRoute] = React.useState<number[]>([]);
  const [isAdded, setIsAdded] = React.useState(false);

  return (
    <Section title="Traffic Volume" transparent>
      <CustomHTMLTable>
        <thead>
          <tr>
            <th style={{ width: "30%" }} />
            <th>Traffic Volume Percentage</th>
            <th>Weight</th>
            <th>Visits</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <RoutingRow
            route={routes.newTestsRoute}
            totalWeight={totalWeight}
            onSave={
              onChange
                ? (route: Route) => {
                    setRoutes({ ...routes, newTestsRoute: route });
                    onChange(route);
                  }
                : null
            }
            editable={editable}
          />

          <RoutingRow
            route={routes.controlRoute}
            totalWeight={totalWeight}
            onSave={
              onChange
                ? (route: Route) => {
                    setRoutes({ ...routes, controlRoute: route });
                    onChange(route);
                  }
                : null
            }
            editable={editable}
          />

          <tr>
            <td style={{ textAlign: "right" }}>Total Test Traffic</td>
            <td rowSpan={1} />
            <td rowSpan={1} />
            <td>{toLocaleNumber(totalTestTraffic)}</td>
            <td rowSpan={1} />
          </tr>

          <tr>
            <td>
              Discarded Visits
              <IconBox>
                <Tooltip content="Traffic generated during aborted and restarted Tests which is not included in Total Test Traffic.">
                  <Icon
                    icon={Icons.faQuestionCircle}
                    color={themes.colors.white}
                  />
                </Tooltip>
              </IconBox>
            </td>
            <td rowSpan={1} />
            <td rowSpan={1} />
            <td>
              {routes.newTestsRoute.discardedVisits
                ? toLocaleNumber(routes.newTestsRoute.discardedVisits)
                : null}
            </td>
            <td rowSpan={1} />
          </tr>

          <RoutingRow
            route={routes.actualStorePageRoute}
            totalWeight={totalWeight}
            onSave={
              onChange
                ? (route: Route) => {
                    setRoutes({ ...routes, actualStorePageRoute: route });
                    onChange(route);
                  }
                : null
            }
            editable={editable}
          />

          {customRoutes.map((customRoute: Route) => {
            return (
              <RoutingRow
                key={`routing-item-${customRoute.id}`}
                route={customRoute}
                totalWeight={totalWeight}
                onSave={
                  onChange
                    ? (route: Route) => {
                        onChange(route);
                      }
                    : null
                }
                onRemove={
                  onDelete
                    ? (route: Route) => {
                        onDelete(route);
                      }
                    : null
                }
                editable={editable}
              />
            );
          })}

          {newRoutes.map((newRoute: Route, index: number) => {
            const close = () => {
              deleteRoute([...deletedRoutes, index]);
            };

            if (deletedRoutes.indexOf(index) !== -1) {
              return null;
            }

            return (
              <RoutingRow
                key={`new-routing-item-${index}`}
                route={newRoute}
                totalWeight={totalWeight}
                editing
                onSave={(newCustomRoute: Route) => {
                  if (onCreate) {
                    close();
                    onCreate(newCustomRoute);
                    setIsAdded(false);
                  }
                }}
                onDiscard={() => {
                  close();
                  setIsAdded(false);
                }}
              />
            );
          })}

          <tr>
            <td style={{ textAlign: "right" }}>
              Total Test & External Traffic
            </td>
            <td rowSpan={1} />
            <td rowSpan={1} />
            <td>{toLocaleNumber(totalTestAndExternalTraffic)}</td>
            <td rowSpan={1} />
          </tr>

          {editable && onCreate && (
            <tr>
              <td colSpan={5} style={{ backgroundColor: "#1a1a32" }}>
                <Flex justifyContent="flex-end" alignItems="center" mr={25}>
                  <Button
                    onClick={() => {
                      setNewRoutes([
                        ...newRoutes,
                        {
                          id: "",
                          url: "",
                          weight: -1,
                          visits: 0,
                          type: RouteType.custom,
                        },
                      ]);
                      setIsAdded(true);
                    }}
                    disabled={
                      customRoutes.length >= config.maxCustomRoutes || isAdded
                    }
                    link
                    icon={<CreateCustomPathIcon icon={Icons.faPlusCircle} />}
                    noHover
                  >
                    New Custom Path
                  </Button>
                </Flex>
              </td>
            </tr>
          )}
        </tbody>
      </CustomHTMLTable>
    </Section>
  );
}

// -- STYLED
export const CustomHTMLTable = styled("table")`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;

  thead > tr:first-of-type {
    th:first-child {
      border-top-left-radius: 10px;
    }

    th:last-child {
      border-top-right-radius: 10px;
    }
  }

  thead > tr:first-of-type {
    td:first-child {
      border-top-left-radius: 10px;
    }

    td:last-child {
      border-top-right-radius: 10px;
    }
  }

  tbody > tr:last-of-type {
    td:first-child {
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }

  thead,
  tfoot {
    background-color: #1a1a32;
    text-align: center;
  }
  th {
    color: ${(props: any) => hexToRgba(props.theme.colors.white, 0.4)};
    font-size: 14px;
    padding: 15px 20px 15px 20px;
  }
  td {
    background-color: #1a1a32;
    font-size: 16px;
    letter-spacing: 0.6px;
    color: ${(props: any) => props.theme.colors.white};
    padding: 15px 20px 15px 20px;
    text-align: center;
    word-break: break-all;

    &:first-child {
      background-color: #181831;
      color: ${(props: any) => props.theme.colors.lightGrey};
      text-align: initial;
    }
  }
  tr {
    padding-bottom: 1px;
    border-bottom: 1px solid
      ${(props: any) => props.theme.colors.backgroundDark};
  }
`;

const CreateCustomPathIcon = styled(Icon)`
  color: ${(props: any) => props.theme.colors.purple};
`;

const IconBox = styled("span")`
  padding: 0 10px;
`;
