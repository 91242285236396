import React, { FC } from "react";
import styled from "styled-components";

import themes from "./themes";

// - -TYPES
interface Props {
  value: number;
}

// -- COMPONENT
const BarChart: FC<Props> = ({ value = 0 }) => (
  <Wrapper>
    <ProgressBar width={value * 100} />
  </Wrapper>
);

// -- STYLES
const Wrapper = styled.div`
  display: flex;

  width: 100%;
  height: 6px;

  background-color: ${themes.colors.backgroundLight};
`;

const ProgressBar = styled.div<{ width: number }>`
  display: flex;

  width: ${(props: any) => props.width}%;
  ${(props) =>
    props.width < 0 &&
    `
    display: none;
  `}
  ${(props) =>
    props.width > 100 &&
    `
    width: 100%;
  `}

  background-color: #48edb9;
`;

export default BarChart;
