import React, { useContext, useState, FC, useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { useQuery } from "@apollo/client";
import { get, isArray, omit, flatten, isEqual } from "lodash";

import { GET_APPLICATION_TITLE_AND_STATUS } from "api";
import { UPDATE_CONTROL_PREVIEW } from "api/mutations/assets";
import {
  GET_APPLICATION_CONTROL_SCREENS,
  GET_ADDITIONAL_PREVIEWS,
} from "api/queries/assets";

import ApplicationControlPreviewsPage from "./ApplicationControlSinglePreviewEdit";
import ApplicationControlUpdateContainer from "../components/ApplicationControlUpdateContainer";
import { Spinner, MessageBox, Error } from "components";

import { Preview, ScreensType } from "api/models";
import { UploadFile } from "components/forms/UploadInput";

import { ApplicationContext } from "contexts";
import { ApplicationControlSetsEdit } from "./ApplicationControlSetsEdit";
import HeaderWithTypeSelector from "components/application/forms/components/PreviewStep/HeaderWithTypeSelector";
import { convertPreviewToUploadFile } from "utils/assets";

// -- TYPES
export interface AppControlResult {
  controlVariation: {
    previews: Preview[];
    additionalPreviews: Preview[];
  };
}

export interface SetsPreviews {
  type: ScreensType.set;
  assets: UploadFile[];
}

export interface SinglePreviews {
  type: ScreensType.single;
  control1: UploadFile[];
  control2: UploadFile[];
  control3: UploadFile[];
  additionals: UploadFile[];
}

interface Props extends RouteComponentProps<{ id: string; position: string }> {}

// -- COMPONENT
const ApplicationControlPreviewsContainer: FC<Props> = (props) => {
  const applicationCtx = useContext(ApplicationContext);
  const applicationId = props.match.params.id;

  // TODO: sprawdzić bo jest to w nadrzędnym containerze
  const { data, error, loading } = useQuery(GET_APPLICATION_TITLE_AND_STATUS, {
    variables: { id: applicationId },
  });

  // TODO: sprawdzić czy potrzebujemy tutaj tego czy to nie jest w nadrzędnym komponencie
  data?.application && applicationCtx.setCurrent(data.application);

  const controlScreens = useQuery<AppControlResult>(
    GET_APPLICATION_CONTROL_SCREENS,
    {
      fetchPolicy: "no-cache",
      variables: { id: applicationId },
    },
  );

  const additionalScreensResult = useQuery<AppControlResult>(
    GET_ADDITIONAL_PREVIEWS,
    {
      fetchPolicy: "no-cache",
      variables: { id: applicationId },
    },
  );
  const additionalScreens = get(
    additionalScreensResult,
    "data.controlVariation.additionalPreviews",
  );
  const additionalFiles = convertPreviewToUploadFile(additionalScreens);

  const [formData, setFormData] = useState<
    SetsPreviews | SinglePreviews | null
  >(null);

  const [
    selectedScreensType,
    setSelectedScreensType,
  ] = useState<ScreensType | null>(null);

  const [
    initialScreensType,
    setInitialScreensType,
  ] = useState<ScreensType | null>(null);

  const [areChangesMade, setAreChangesMade] = useState<boolean>(false);

  const currentControlAssets = useMemo((): Preview[] | null => {
    const initialControlScreens: Preview[] | undefined =
      controlScreens?.data?.controlVariation?.previews;

    if (!isArray(initialControlScreens)) {
      return null;
    }

    return [...initialControlScreens];
  }, [controlScreens.data?.controlVariation?.previews]);

  useEffect(() => {
    const screens = currentControlAssets;

    if (isArray(screens)) {
      const initScreenType = getCurrentControlScreenType(screens);
      setSelectedScreensType(initScreenType);
      setInitialScreensType(initScreenType);
      setFormData(createFormDataForScreenType(initScreenType, screens));
    }
  }, [currentControlAssets]);

  const getCurrentControlScreenType = (screens?: Preview[]): ScreensType => {
    const isSet: boolean = get(screens, "[0].isControlSet", false);
    return isSet ? ScreensType.set : ScreensType.single;
  };

  const createFormDataForScreenType = (
    type: ScreensType.set | ScreensType.single,
    screens: Preview[],
  ): SetsPreviews | SinglePreviews => {
    const assets: UploadFile[] = convertPreviewToUploadFile(screens);

    if (type === ScreensType.set) {
      return {
        type: ScreensType.set,
        assets,
      };
    }

    return {
      type: ScreensType.single,
      control1: [assets[0]],
      control2: [assets[1]],
      control3: [assets[2]],
      additionals: assets.slice(3),
    };
  };

  const onChange = (changedData: any) => {
    const newFormData = { ...formData, ...changedData };

    const dataSourceToCompare =
      selectedScreensType === ScreensType.single
        ? flatten(Object.values(omit(newFormData, "type")))
        : newFormData.assets;

    const newControlIds = dataSourceToCompare.map(
      (singleScreen: Preview) => singleScreen.id,
    );
    const currentControlIds = (currentControlAssets || []).map(
      (singleScreen) => singleScreen.id,
    );

    setFormData(newFormData);
    setAreChangesMade(!isEqual(currentControlIds, newControlIds));
  };

  const renderContent = () => {
    if (!formData) {
      return null;
    }

    if (formData.type === ScreensType.set) {
      return (
        <>
          {initialScreensType === ScreensType.single && (
            <MessageBox closeable={false} style={{ marginBottom: 20 }}>
              Assets from the current control have already been transformed and
              loaded as draft set of screens.
            </MessageBox>
          )}
          <ApplicationControlSetsEdit
            formData={formData}
            onChange={onChange}
            platform={data.application.platform}
          />
        </>
      );
    }

    return (
      <>
        {initialScreensType === ScreensType.set && (
          <MessageBox closeable={false} style={{ marginBottom: 20 }}>
            Assets from the current control set have already been divided and
            loaded into the appropriate sections.
          </MessageBox>
        )}
        <ApplicationControlPreviewsPage
          formData={formData}
          onChange={onChange}
          platform={data.application.platform}
          displayAdditionals={initialScreensType === ScreensType.set}
        />
      </>
    );
  };

  const onPreviewTypeChange = (value: string | number | boolean) => {
    let newFormData: SetsPreviews | SinglePreviews | null = null;

    newFormData = createFormDataForScreenType(
      value === ScreensType.set ? ScreensType.set : ScreensType.single,
      currentControlAssets || [],
    );

    setSelectedScreensType(
      value === ScreensType.set ? ScreensType.set : ScreensType.single,
    );

    if (newFormData.type === ScreensType.single) {
      newFormData.additionals = newFormData.additionals
        .concat(additionalFiles)
        .slice(0, 8);
    }

    setFormData(newFormData);
    setAreChangesMade(true);
  };

  const convertAssetsToReqFormat = (assets: UploadFile[]) => {
    const infoArray: any[] = [];
    const fileArray: File[] = [];

    assets.forEach((singleAsset, index) => {
      infoArray.push({
        id: singleAsset.id || null,
        position: index + 1,
        videoId: singleAsset.videoId || null,
      });
      singleAsset.file && fileArray.push(singleAsset.file);
    });

    return {
      files: fileArray,
      info: infoArray,
    };
  };

  const parseFormDataOnSubmit = (
    submitedFormData: SetsPreviews | SinglePreviews,
  ): object => {
    let setFiles: File[] = [];
    let setInfo: any = [];
    let previewsFiles: File[] = [];
    let previewsInfo: any = [];

    if (submitedFormData.type === ScreensType.set) {
      const filesAndInfo = convertAssetsToReqFormat(submitedFormData.assets);
      setFiles = filesAndInfo.files;
      setInfo = filesAndInfo.info;
    } else {
      const { control1, control2, control3, additionals } = submitedFormData;
      const filesAndInfo = convertAssetsToReqFormat([
        ...control1,
        ...control2,
        ...control3,
        ...additionals,
      ]);
      previewsFiles = filesAndInfo.files;
      previewsInfo = filesAndInfo.info;
    }

    return {
      setFiles,
      setInfo,
      previewsFiles,
      previewsInfo,
    };
  };

  const additionalRefetchQueries = React.useMemo(
    () => [
      {
        query: GET_APPLICATION_CONTROL_SCREENS,
        variables: { id: applicationId },
      },
    ],
    [applicationId],
  );

  if (loading || controlScreens.loading) {
    return <Spinner />;
  }

  if (error || controlScreens.error) {
    const errMsg = error?.message;
    const controlScreensErr = controlScreens?.error?.message;
    return <Error errorElement={errMsg || controlScreensErr} />;
  }

  return (
    <ApplicationControlUpdateContainer
      mutation={UPDATE_CONTROL_PREVIEW}
      formData={formData}
      submitDisabled={!areChangesMade}
      parseFormDataOnSubmit={parseFormDataOnSubmit}
      additionalRefetchQueries={additionalRefetchQueries}
      {...props}
    >
      {formData && selectedScreensType && (
        <>
          <HeaderWithTypeSelector
            title="Upload New Control Sets of Screens or Individual Screens"
            toggleValue={selectedScreensType}
            onToggleChange={onPreviewTypeChange}
          />
          {renderContent()}
        </>
      )}
    </ApplicationControlUpdateContainer>
  );
};

export default ApplicationControlPreviewsContainer;
