import { styled } from "@mui/system";

export const TrComponent = styled("tr", {
  shouldForwardProp: (prop: string) =>
    !["highlightFirstCell", "rowObject"].includes(prop),
})<{ highlightFirstCell?: boolean }>`
  padding-bottom: 1px;

  border-top: 1px solid ${(props: any) => props.theme.table.colors.borderColor};

  ${(props: any) =>
    props.highlightFirstCell &&
    `
    td:first-of-type {
      background-color: #181831;

      color: ${props.theme.colors.lightGrey};
    }
  `}
`;
