import React, { useContext, useState } from "react";
import { Flex } from "@rebass/grid";
import styled from "styled-components";

import { Route, RouteType } from "api/models";
import { TextInput } from "components/forms";
import themes from "components/themes";
import { Icon, Icons, Tooltip } from "components/index";
import { percentFormat, toLocaleNumber } from "utils/numbers";
import validators from "utils/validators";
import { ModalContext } from "contexts";

interface Props {
  route: Route;
  totalWeight: number;
  onRemove?: ((route: Route) => void) | null;
  onSave: ((route: Route) => void) | null;
  onDiscard?: ((route: Route) => void) | null;
  editing?: boolean;
  editable?: boolean;
}

function RoutingRow({
  route,
  totalWeight,
  onRemove,
  onSave,
  onDiscard = () => {},
  editing = false,
  editable = true,
}: Props) {
  const [isHovered, setHovered] = useState(false);
  const [isEditing, setEditing] = useState(editing);
  const [url, setUrl] = useState<string>(route.url);
  const [weight, setWeight] = useState<string>(
    route.weight >= 0 ? route.weight.toString() : "",
  );
  const [urlError, setUrlError] = useState<string>("");

  const setModal = useContext(ModalContext);

  const weightPercentage = percentFormat(
    Math.max(route.weight / totalWeight, 0) || 0,
  );

  const Tooltips = () => {
    return (
      <>
        {route.type === RouteType.control && (
          <IconBox>
            <Tooltip content="Additional traffic outside of traffic used for Tests meant for the continuous monitoring of Control performance over time.">
              <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
            </Tooltip>
          </IconBox>
        )}
        {route.type === RouteType.newTests && (
          <IconBox>
            <Tooltip content="Includes all traffic data that are used for testing. Please note this data includes traffic sent to Control as part of testing.">
              <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
            </Tooltip>
          </IconBox>
        )}
        {route.type === RouteType.actualStorePage && (
          <IconBox>
            <Tooltip content={route.redirectUrl || ""}>
              <Icon icon={Icons.faQuestionCircle} color={themes.colors.white} />
            </Tooltip>
          </IconBox>
        )}
      </>
    );
  };

  const handleRemove = () => {
    setModal({
      type: "set",
      props: {
        title: "Delete Route",
        content: "Are you sure you want to delete this route?",
        onConfirm: () => {
          onRemove && onRemove(route);
        },
      },
    });
  };

  let name;
  switch (route.type) {
    case RouteType.control:
      name = "Control Monitoring";
      break;
    case RouteType.newTests:
      name = "Tests";
      break;
    case RouteType.actualStorePage:
      name = "App Store URL";
      break;
    case RouteType.custom:
      name = route.url;
      break;
  }

  return (
    <Row
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {isEditing ? (
        <>
          {route.type === RouteType.custom ? (
            <EditCell>
              <TextInput
                placeholder={"Enter Custom Path URL"}
                style={{
                  backgroundColor: "rgba(7, 7, 30, 0.4)",
                  padding: 0,
                  paddingLeft: 15,
                }}
                name="url"
                value={url}
                error={urlError}
                showError={false}
                onChange={(event: any) => setUrl(event.target.value)}
                size={10}
                maxLength={50}
              />
            </EditCell>
          ) : (
            <td>
              {name}
              <Tooltips />
            </td>
          )}
          <EditCell light={editable && isHovered}>{weightPercentage}</EditCell>
          <EditCell light={editable && isHovered}>
            <WeightInput
              placeholder={"Enter Value"}
              name="weight"
              value={weight}
              onChange={(event: any) =>
                !isNaN(event.target.value) &&
                (parseInt(event.target.value, 10) >= 0 ||
                  !event.target.value) &&
                setWeight(event.target.value)
              }
              size={1}
              maxLength={7}
            />
          </EditCell>
          <EditCell light={editable && isHovered}>
            {toLocaleNumber(route.visits)}
          </EditCell>
        </>
      ) : (
        <>
          <EditCell>
            {name}
            <Tooltips />
          </EditCell>
          <EditCell light={editable && isHovered}>{weightPercentage}</EditCell>
          <EditCell light={editable && isHovered}>{route.weight}</EditCell>
          <EditCell light={editable && isHovered}>
            {toLocaleNumber(route.visits)}
          </EditCell>
        </>
      )}
      <ActionsCell light={isHovered && editable}>
        {isHovered && !isEditing && editable && onSave && (
          <>
            <Action onClick={() => setEditing(true)}>
              <ActionIcon icon={Icons.faPen} /> Edit
            </Action>
            {!!onRemove && (
              <Action onClick={handleRemove}>
                <ActionIcon icon={Icons.faTrash} />
                Delete
              </Action>
            )}
          </>
        )}
        {isHovered && isEditing && (
          <Actions>
            {!!onSave && (
              <Action
                onClick={() => {
                  if (route.type === RouteType.custom) {
                    const error = validators.url(url);
                    setUrlError(error || "");
                    if (error) {
                      return false;
                    }
                  }

                  onSave({
                    ...route,
                    url,
                    weight: parseInt(weight, 10) || 0,
                  });
                  setEditing(false);

                  if (!weight) {
                    setWeight("0");
                  }
                }}
              >
                <ActionIcon icon={Icons.faCheck} />
                Save
              </Action>
            )}

            {!!onDiscard && (
              <Action
                onClick={() => {
                  setEditing(false);
                  setUrl(route.url);
                  setWeight(route.weight.toString());
                  onDiscard(route);
                }}
              >
                <ActionIcon icon={Icons.faWindowClose} />
                Discard
              </Action>
            )}
          </Actions>
        )}
      </ActionsCell>
    </Row>
  );
}

const Row = styled.tr`
  margin-bottom: 0 !important;
  background-color: ${themes.colors.card} !important;
  color: white;
  height: 64px;
`;

const EditCell = styled.td<{ light?: boolean }>`
  background-color: ${(props) =>
    props.light ? `${themes.colors.backgroundLight} !important` : "inherit"};
  padding: 0 20px !important;
`;

const ActionsCell = styled(EditCell as any)`
  width: 280px;
  border-left: ${(props) =>
    props.light
      ? `1px solid ${themes.colors.backgroundDark} !important`
      : "inherit"};
`;

const Actions = styled(Flex)`
  align-items: center;
  justify-content: center;
  word-break: keep-all;
`;

const Action = styled.span`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.6px;
  margin-right: 20px;
  cursor: pointer;

  border-radius: 2px;
  padding-left: 8px;
  padding-right: 8px;
  text-decoration: underline;

  :hover {
    background-color: rgba(171, 104, 239, 0.2);
    transition: 0.5s;
  }
`;

const ActionIcon = styled(Icon)`
  margin-right: 8px;
`;

const WeightInput = styled(TextInput)`
  min-width: 120px;
  max-width: 120px;
  text-align: center;
  background-color: rgba(7, 7, 30, 0.4);
  padding: 0 15px;
`;

const IconBox = styled.span`
  padding: 0 10px;
`;

export default RoutingRow;
