import React from "react";
import { Field } from "react-final-form";
import { Box, Flex } from "@rebass/grid";

import { FormLabel, TextInput } from "components/forms";
import { Section } from "components";

import { detailsFormFieldList } from "./constants/detailsFormFieldList";
import { DetailsFormField } from "./constants/detailsFormField";

export function BillingDetailsFormFields(): JSX.Element {
  const renderSectionFields = (
    fieldsToRender: DetailsFormField[],
  ): JSX.Element[] =>
    fieldsToRender.map((singleField, index) => {
      const boxProps = {
        width: 1 / 2,
        [index % 2 === 0 ? "pr" : "pl"]: "10px",
      };

      return (
        <Box key={`${index}-${singleField.fieldKey}`} {...boxProps}>
          <Field name={singleField.fieldKey} validate={singleField.validator}>
            {({ input, meta }) => {
              return (
                <div>
                  <FormLabel name={singleField.fieldKey} spacing={index > 1}>
                    {singleField.fieldLabel}
                  </FormLabel>
                  <TextInput
                    type="text"
                    placeholder={singleField.placeholder}
                    error={meta.touched && meta.error}
                    {...input}
                    onChange={(event: any) =>
                      input.onChange(event.target.value)
                    }
                  />
                </div>
              );
            }}
          </Field>
        </Box>
      );
    });

  return (
    <Section transparentHeader>
      <Flex flexDirection="row" flexWrap="wrap">
        {renderSectionFields(detailsFormFieldList)}
      </Flex>
    </Section>
  );
}
