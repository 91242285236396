import React, { FC, useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { isEqual } from "lodash";

import { Preview } from "api";
import { UPDATE_CONTROL_ADDITIONAL_PREVIEWS } from "api/mutations/assets";
import { GET_ADDITIONAL_PREVIEWS } from "api/queries/assets";
import { UploadFile } from "components/forms/UploadInput";

import ApplicationControlUpdateContainer from "../components/ApplicationControlUpdateContainer";
import { Spinner, Error } from "components";
import ApplicationControlEditAdditionalPreviews from "./ApplicationControlEditAdditionalPreviews";
import { convertPreviewToUploadFile } from "utils/assets";

// -- TYPES
interface Props extends RouteComponentProps<{ id: string }> {}

interface AppControlAdditionalScreens {
  controlVariation: {
    additionalPreviews: Preview[];
  };
}

export interface FormDataShape {
  assets: UploadFile[];
}

// -- COMPONENT
const ApplicationControlAdditionalPreviewsPage: FC<Props> = ({
  match,
  ...rest
}) => {
  const { data, error, loading } = useQuery<AppControlAdditionalScreens>(
    GET_ADDITIONAL_PREVIEWS,
    {
      variables: { id: match.params.id },
    },
  );

  const [formData, setFormData] = useState<FormDataShape>({ assets: [] });
  const [isChange, setIsChange] = useState<boolean>(false);

  useEffect(() => {
    data &&
      setFormData({
        assets: convertPreviewToUploadFile(
          data.controlVariation.additionalPreviews,
        ),
      });
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  const parseFormDataOnSubmit = (dataOnSubmit: FormDataShape): object => {
    const additionalPreviewsInfo: any[] = [];
    const additionalPreviewsFiles: File[] = [];

    dataOnSubmit.assets.forEach((singleAsset, index) => {
      additionalPreviewsInfo.push({
        id: singleAsset.id || null,
        position: index + 4,
        videoId: singleAsset.videoId || null,
      });
      singleAsset.file && additionalPreviewsFiles.push(singleAsset.file);
    });

    return {
      additionalPreviewsFiles,
      additionalPreviewsInfo,
    };
  };

  const onChange = (newValues: FormDataShape) => {
    const initalDataIds = data
      ? data.controlVariation.additionalPreviews.map(
          (singlePreview) => singlePreview.id,
        )
      : [];
    const currentIds = newValues.assets.map((singleAsset) => singleAsset.id);

    setFormData(newValues);
    setIsChange(!isEqual(initalDataIds, currentIds));
  };

  return (
    <ApplicationControlUpdateContainer
      mutation={UPDATE_CONTROL_ADDITIONAL_PREVIEWS}
      formData={formData}
      parseFormDataOnSubmit={parseFormDataOnSubmit}
      match={match}
      {...rest}
      submitDisabled={!isChange}
      displayConfirmModal={false}
    >
      <ApplicationControlEditAdditionalPreviews
        formData={formData}
        onChange={onChange}
      />
    </ApplicationControlUpdateContainer>
  );
};

export default ApplicationControlAdditionalPreviewsPage;
