import { Flex } from "@rebass/grid";
import styled from "styled-components";

export const HiddenFlex = styled(Flex)`
  overflow-x: hidden;
`;

export const TrafficIndicator = styled.div`
  padding: 0 20px 20px;
  font-size: 16px;
`;

export const ScrollFlex = styled(Flex)`
  overflow-x: auto;
  padding-bottom: 5px;
`;
