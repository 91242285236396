import * as React from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";

import { HEATMAP_DATA } from "api/queries/analytics";
import { HeatMapResponse } from "./constants/heatMapResponse";

import SimpleXAxis from "../SimpleXAxis";
import { Spinner, Error } from "components";
import Chart from "components/Chart/Chart";
import SimpleChartTooltip from "../SimpleChartTooltip";
import { HeatMapIframe } from "./components/HeatMapIframe/HeatMapIframe";
import { IframeHeight } from "./components/HeatMapIframe/constants/iframeHeight";

// -- TYPES
type HeatMapProps = {
  applicationId: string;
};

// -- COMPONENT
export function HeatMap({ applicationId }: HeatMapProps): JSX.Element {
  const { loading, data, error } = useQuery<HeatMapResponse>(HEATMAP_DATA, {
    variables: {
      applicationId,
    },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <Wrapper id="chart-line-portal">
      <HeatMapIframe url={data?.controlVariation.url || ""} />
      <ChartWrapper>
        <Chart
          xAxisComponent={<SimpleXAxis />}
          yAxisTicks={["100%", "75%", "50%", "25%", "0%"]}
          reversedY
          reversedX
          tooltipComponent={
            <SimpleChartTooltip
              renderVerticalPositionLine
              renderIframeGradient
            />
          }
          showGradient={false}
          dataSet={data?.heatmapChartData.dataSet || []}
          legendElements={data?.heatmapChartData.legendElements || []}
          allowEscapeViewBox
          // + 35 because that is the height of horizontal axis legend
          // + 15 because that is the chart top margin
          height={IframeHeight + 35 + 15}
          width={"99%"}
          layout="vertical"
          yAxisLabelByField={"name"}
          yAxisType="category"
          xAxisType="number"
          xAxisLabel="% of visitors"
          yAxisLabel="page height"
          xAxisDomain={[0, 100]}
        />
      </ChartWrapper>
    </Wrapper>
  );
}

// -- STYLED
export const Wrapper = styled.div`
  display: flex;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
`;
