import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Box } from "@rebass/grid";
import { useQuery } from "@apollo/client";

import { GET_APPLICATION_BASE_DATA } from "api/queries/application";

import { ApplicationListing } from "api/models";

import { ApplicationContext } from "contexts";

import { Section, Spinner, Title, Error } from "components";
import TimeSpentChart from "./components/TimeSpentChart/TimeSpentChart";
import { HeatMap } from "./components/HeatMap/HeatMap";

// -- TYPES
interface Props extends RouteComponentProps<{ id: string }> {}

// -- COMPONENT
function ApplicationAnalyticsPage({ match }: Props) {
  const applicationId = match.params.id;

  const { data, error, loading } = useQuery<{
    application: ApplicationListing;
  }>(GET_APPLICATION_BASE_DATA, {
    variables: { applicationId },
  });

  const applicationCtx = React.useContext(ApplicationContext);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  applicationCtx.setCurrent(data?.application || null);

  return (
    <>
      <Title>User Behavior</Title>
      <Box>
        <Section title="Heatmap" transparentHeader>
          <HeatMap applicationId={applicationId} />
        </Section>
      </Box>
      <Box>
        <Section title="Time on Page" transparentHeader>
          <TimeSpentChart applicationId={applicationId} />
        </Section>
      </Box>
    </>
  );
}

export default ApplicationAnalyticsPage;
