import * as React from "react";
import { useLazyQuery } from "@apollo/client";
import styled from "styled-components";
import {
  SortingRule,
  TableState,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { isEqual } from "lodash";

import { DynamicTableQueryResponse } from "./constants/dynamicTableQueryResponse";
import { DynamicTableQueryParams } from "./constants/dynamicTableQueryparams";
import { SingleTableColumn } from "./constants/singleTableColumn";

import { getSortString } from "utils/getSortString";

import { TableFooterProps } from "./components/TableFooter";
import { Table } from "./components/TableComponent";
import { Spinner, Error } from "components";

// -- PROPS
type DynamicTableProps = {
  query: any;
  initialItemsPerPage?: number;
  additionalParamsToRequest?: object;
  responseDataFieldName: string;
  columns: any;
  displayHeader?: boolean;
  highlightFirstCell?: boolean;
  initialSortBy?: SortingRule<object>;
  trElement?: React.ComponentType<any>;
};

// -- COMPONENT
export function DynamicTable({
  query,
  initialItemsPerPage = 10,
  additionalParamsToRequest,
  responseDataFieldName,
  columns,
  initialSortBy,
  ...rest
}: DynamicTableProps) {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(
    initialItemsPerPage,
  );
  const [sortDetails, setSortDetails] = React.useState<
    undefined | SortingRule<object>
  >(initialSortBy);

  const [requestForPageData, { loading, data, error }] = useLazyQuery<
    DynamicTableQueryResponse,
    DynamicTableQueryParams
  >(query, {
    fetchPolicy: "no-cache",
  });

  const tableData = React.useMemo(() => {
    return data?.[responseDataFieldName]?.items || [];
  }, [data, responseDataFieldName]);

  const initialState = React.useMemo<Partial<TableState<object>>>(() => {
    const state: Record<string, unknown> = {
      pageIndex: 0,
    };

    if (initialSortBy) {
      // puts initialSortBy element in array because react-table requires this
      state.sortBy = [initialSortBy];
    }

    return state;
  }, [initialSortBy]);

  const {
    page,
    rows,
    state: { sortBy },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState,
      manualPagination: true,
      pageCount: data?.[responseDataFieldName]?.pagesCount || 1,
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    const filterObject = sortBy[0];

    if (!isEqual(sortDetails, filterObject)) {
      setSortDetails(filterObject);
    }
  }, [sortBy, sortDetails]);

  React.useEffect(() => {
    const sortString = getSortString(sortDetails);

    const variables: DynamicTableQueryParams = {
      page: currentPage,
      itemsPerPage,
      ...(additionalParamsToRequest || {}),
    };

    if (sortString) {
      variables.orderBy = sortString;
    }

    requestForPageData({ variables });
  }, [
    currentPage,
    itemsPerPage,
    requestForPageData,
    additionalParamsToRequest,
    sortDetails,
  ]);

  const onPaginationChange = React.useCallback(
    (newPageNumber: number, newItemsPerpage: number) => {
      newItemsPerpage !== itemsPerPage && setItemsPerPage(newItemsPerpage);
      newPageNumber !== currentPage && setCurrentPage(newPageNumber);
    },
    [setCurrentPage, setItemsPerPage, itemsPerPage, currentPage],
  );

  const footerProps: TableFooterProps = React.useMemo(() => {
    return {
      columnsNumber: columns.length,
      currentPage: data?.[responseDataFieldName]?.page || 1,
      onPaginationChange,
      pagesCount: data?.[responseDataFieldName]?.pagesCount || 1,
      displayItemsCountSelector: true,
      initialItemsCount: initialItemsPerPage,
      disableMultiSort: true,
    };
  }, [
    columns,
    onPaginationChange,
    initialItemsPerPage,
    responseDataFieldName,
    data,
  ]);

  if (error) {
    return (
      <Error
        errorElement={error.message}
        customErrorFirstRow="An error occurred while getting notifications"
      />
    );
  }

  return (
    <Wrapper>
      {loading && (
        <LoaderOverlay>
          <Spinner style={{ marginTop: 0 }} />
        </LoaderOverlay>
      )}
      <Table
        rows={page || rows}
        columnsNumber={columns.length}
        footerProps={footerProps}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        {...rest}
      />
    </Wrapper>
  );
}

// -- STYLED
const Wrapper = styled.div`
  position: relative;
`;

const LoaderOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 1000;

  background-color: rgba(32, 32, 32, 0.5);
`;
