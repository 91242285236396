import React, { useState, ReactNode, FC } from "react";
import { Form } from "react-final-form";

import Preview1, {
  StepData,
} from "components/application/forms/components/PreviewStep/Preview1";
import Preview2 from "components/application/forms/components/PreviewStep/Preview2";
import Preview3 from "components/application/forms/components/PreviewStep/Preview3";
import { FormFooter } from "components/forms";
import { MessageBox } from "components/application";
import PreviewSets from "components/application/forms/components/PreviewStep/PreviewSets";
import HeaderWithTypeSelector from "components/application/forms/components/PreviewStep/HeaderWithTypeSelector";

import { SetPreviewData } from "components/application/forms/PreviewStep";
import { Platform, ScreensType } from "api/models";

// -- TYPES
interface Props {
  position: string;
  onDiscard: () => void;
  onSubmit: (data: StepData | SetPreviewData) => void;
  platform?: Platform.ios | Platform.android;
  testingSets?: boolean;
}

// -- STATIC
const defaultSinglePreviewState: StepData = {
  control: null,
  assets: [],
};

const defaultSetsPreviewState: SetPreviewData = {
  screensType: ScreensType.set,
  sets: [
    {
      assets: [],
      isControl: false,
      name: "",
      tempId: 1, // temporary id for frontend
    },
  ],
};

// -- COMPONENT
const ApplicationUpdatePreviewsPage: FC<Props> = ({
  position,
  onDiscard,
  onSubmit,
  platform,
  testingSets,
}) => {
  const [formData, setFormData] = useState<StepData | SetPreviewData>(
    Number(position) === 1 && testingSets
      ? defaultSetsPreviewState
      : defaultSinglePreviewState,
  );
  const [screensType, setScreensType] = useState<ScreensType>(
    Number(position) === 1 && testingSets
      ? ScreensType.set
      : ScreensType.single,
  );

  const onPreviewTypeChange = (value: ScreensType) => {
    setScreensType(value);
    setFormData(
      value === ScreensType.single
        ? defaultSinglePreviewState
        : defaultSetsPreviewState,
    );
  };

  const onFieldsChange = (data: object) => {
    setFormData({
      ...formData,
      ...data,
    });
  };

  const renderInputs = (): ReactNode => {
    if (formData.screensType === ScreensType.set) {
      return (
        <PreviewSets
          data={formData}
          onChange={onFieldsChange}
          platform={platform}
          allowSetControl={false}
        />
      );
    }

    let FormStep: any = Preview1;
    if (position === "2") {
      FormStep = Preview2;
    } else if (position === "3") {
      FormStep = Preview3;
    }

    return (
      FormStep && (
        <FormStep
          data={formData}
          onChange={onFieldsChange}
          platform={platform}
        />
      )
    );
  };

  const submitForm = () => onSubmit(formData);

  return (
    <Form onSubmit={submitForm} initialValues={formData}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <MessageBox />
          <HeaderWithTypeSelector
            title="Upload Sets of Screens or New Screens"
            toggleValue={screensType}
            onToggleChange={onPreviewTypeChange}
          />
          {renderInputs()}
          <FormFooter onCancel={onDiscard} disabled={submitting} />
        </form>
      )}
    </Form>
  );
};

export default ApplicationUpdatePreviewsPage;
