import React, { useState, useEffect } from "react";

import { Box, Flex } from "@rebass/grid";

import {
  Application,
  ExperimentPhaseType,
  ExperimentStatistics,
  Status,
  CurrentIteration,
  FacebookPixel,
} from "api/models";

import {
  GET_APPLICATION_VIEWS_AND_CONVERSIONS,
  GET_APPLICATION_VIEWS_AND_CONVERSIONS_OLD,
} from "api/queries";

import { valueOrNA } from "utils/valueOrNA";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { currentDateTime } from "utils/dates";
import { toLocaleNumber } from "utils/numbers";
import { getCreativesTestingQueuedFormat } from "utils/statistics";

import { ModalSetter } from "contexts/modalContext";

import {
  ChartWithControls,
  ChartWithControlsOld,
  PercentageChartWithData,
  Refresh,
  Section,
  StatisticsCardsList,
} from "components";
import { Button } from "components/forms";
import { ApplicationPagePreview } from "components/application/ApplicationPagePreview";
import { OverviewStatusSection } from "./components/OverviewStatusSection";
import { ActionableItemsSection } from "./components/ActionableItemsSection";
import { OverviewDetailsSection } from "./components/OverviewDetailsSection";
import { useMutation } from "@apollo/client";
import { DUPLICATE_APPLICATION } from "api";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";
import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useDailyPlotCookie } from "../../../../hooks/useDailyPlotCookie";
import { PermissionRestriction } from "v2/components/PermissionRestriction";
import { UserPermissions } from "v2/api/types/UserPermissions";
import { useStores } from "contexts";

interface Props {
  application: Application;
  currentPhaseType: ExperimentPhaseType;
  testsWeight: number;
  experimentStatistics: ExperimentStatistics;
  topPageConversionLift: number | string;
  controlPageUrl: string;
  topPageUrl: string;
  topPageConversionRate: number | string;
  zipFileUrl: string;
  onRedirectUrlChange: (value: string | number) => void;
  onSignificanceChange: (value: string | number) => void;
  testUrl: string;
  liveUrls: string[];
  hasTopPage: boolean;
  topPageDate: string;
  refetchData: () => Promise<any>;
  loading?: boolean;
  onCancel?: () => void;
  setModal: ModalSetter | (() => void);
  history: any;
  onRecalculateBcr: () => void;
  applicationGroup: {
    id: string;
    applications: {
      id: string;
    }[];
  };
  phaseTraffic: number;
  controlConversionRate: number;
  topPageConfidence: number | string;
  trafficNeeded: string;
  visitsToNewLearning: string;
  currentIteration?: CurrentIteration;
  facebookPixel?: FacebookPixel;
}

function ApplicationOverviewPage({
  application,
  currentPhaseType,
  testsWeight,
  experimentStatistics,
  topPageConversionLift,
  controlPageUrl,
  topPageUrl,
  topPageConversionRate,
  zipFileUrl,
  onRedirectUrlChange,
  onSignificanceChange,
  testUrl,
  liveUrls,
  hasTopPage,
  topPageDate,
  refetchData,
  loading = false,
  onCancel,
  setModal,
  history,
  onRecalculateBcr,
  applicationGroup,
  phaseTraffic,
  controlConversionRate,
  topPageConfidence,
  trafficNeeded,
  visitsToNewLearning,
  currentIteration,
  facebookPixel,
}: Props) {
  const useDailyPlot = useDailyPlotCookie();
  const [dateTime, setDateTime] = useState(currentDateTime);
  const { hash } = window.location;
  const appPrevieRef = React.createRef();
  const { userStore } = useStores();

  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();
  const [duplicateApplication] = useMutation(DUPLICATE_APPLICATION);

  const handleError = useRequestErrorHandler();

  useEffect(() => {
    if (!!hash) {
      const element = document.getElementById(hash);
      setTimeout(() => {
        window.scrollTo({
          behavior: element ? "smooth" : "auto",
          top: element ? element.offsetTop : -130,
          left: 0,
        });
      }, 100);
    }
  }, [hash]);

  const onCancelClick = (): void => {
    const isActive = application.status !== Status.inactive;
    return setModal({
      type: "set",
      props: {
        title: `${isActive ? "Cancel" : "Reactivate"} All Tests`,
        content: `${
          isActive
            ? "Are you sure you want to cancel all tests? This will reset all testing and deactivate the app.  Only proceed with this option if you will no longer use this app for any future testing."
            : "Are you sure you want to reactivate all tests?"
        }`,
        onConfirm: onCancel,
      },
    });
  };

  const renderStatistics = (): React.ReactNode => {
    const cardStyle = {
      mb: 30,
    };

    const wrapperStyle = {
      mt: 20,
    };

    const formatVisitsUntilNewLearning = () => {
      if (visitsToNewLearning.indexOf("-") === -1) {
        const castedVisits = Number(visitsToNewLearning);

        if (isNaN(castedVisits)) {
          return visitsToNewLearning;
        }

        return toLocaleNumber(castedVisits);
      }

      return visitsToNewLearning
        .split("-")
        .map((visitsCount) => toLocaleNumber(Number(visitsCount)))
        .join("-");
    };

    const statisticsCards = [
      {
        title: <PercentageChartWithData value={controlConversionRate} />,
        description: "Current Control Conversion Rate",
      },
      {
        title: <PercentageChartWithData value={topPageConversionRate} />,
        description: "Top Page Conversion Rate",
      },
      {
        title: <PercentageChartWithData value={topPageConversionLift} />,
        description: "Top Page Conversion Lift",
      },
      {
        title: <PercentageChartWithData value={topPageConfidence} />,
        description: "Top Page Confidence",
      },

      {
        title: toLocaleNumber(experimentStatistics.totalTestsRun),
        description: "Total Tests Run",
      },
      {
        title: toLocaleNumber(experimentStatistics.totalTraffic),
        description: "Total Traffic",
      },
      {
        title: valueOrNA(
          getCreativesTestingQueuedFormat(
            experimentStatistics.creativesCurrentlyTesting,
            experimentStatistics.creativesQueued,
          ),
        ),
        description: "Creatives Testing / Queued",
      },
      {
        title: formatVisitsUntilNewLearning(),
        fontSize: visitsToNewLearning.indexOf("-") === -1 ? 46 : 26,
        description: "Visits Until New Learning",
      },
    ];

    return (
      <StatisticsCardsList
        cardsList={statisticsCards}
        cardStyle={cardStyle}
        wrapperStyle={wrapperStyle}
      />
    );
  };

  const onAppCopy = async () => {
    setModal({
      type: "set",
      props: {
        title: "Confirm copy",
        content:
          "Are you sure you want to create a duplicate copy of this app? This will create a new copy of this app without any historical data.",
        onConfirm: onAppCopyConfirm,
      },
    });
  };

  const onAppCopyConfirm = async () => {
    setFullPageLoader(true);

    const duplicateApplicationResponse = await handleError(
      duplicateApplication({
        variables: {
          sourceAppPlatformCode: application.code,
          clientCode: userStore.clientCode,
        },
        awaitRefetchQueries: true,
        refetchQueries: ["clientApplications"],
      }),
    );

    const duplicatedApplicationId =
      duplicateApplicationResponse?.data?.duplicateAppPlatform?.asoAppId;

    if (duplicatedApplicationId) {
      history.push(
        authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({
          id: duplicatedApplicationId,
        }),
      );
    }

    setFullPageLoader(false);
  };

  return (
    <>
      {fullPageLoader}
      <Flex flexDirection="column">
        <Flex>
          <Box width={3 / 4} mt={17} mr={20}>
            <OverviewStatusSection
              application={application}
              phaseTraffic={phaseTraffic}
              trafficNeeded={trafficNeeded}
              testsWeight={testsWeight}
              currentIteration={currentIteration}
              currentPhaseType={currentPhaseType}
              history={history}
              onRecalculateBcr={onRecalculateBcr}
              topPageDate={topPageDate}
              experimentStatistics={experimentStatistics}
            />
          </Box>
          <Box width={1 / 4} mt={17}>
            <ActionableItemsSection
              application={application}
              experimentStatistics={experimentStatistics}
              hasTopPage={hasTopPage}
              history={history}
              appPrevieRef={appPrevieRef}
            />
          </Box>
        </Flex>

        <Refresh
          pending={loading}
          datetime={dateTime}
          onClick={async () => {
            await refetchData();
            setDateTime(currentDateTime);
          }}
        />

        {renderStatistics()}

        <ApplicationPagePreview
          controlPageUrl={controlPageUrl}
          hasTopPage={hasTopPage}
          topPageUrl={topPageUrl}
          application={application}
          zipFileUrl={zipFileUrl}
          refetchPage={refetchData}
          ref={appPrevieRef}
        />

        <Box>
          <Section title="Views and Conversions" transparentHeader>
            {useDailyPlot ? (
              <ChartWithControls
                applicationId={application.id}
                query={GET_APPLICATION_VIEWS_AND_CONVERSIONS}
                conversionDataKey="appConversionRates"
              />
            ) : (
              <ChartWithControlsOld
                applicationId={application.id}
                query={GET_APPLICATION_VIEWS_AND_CONVERSIONS_OLD}
                conversionDataKey="appConversionRatesOld"
              />
            )}
          </Section>
        </Box>

        <OverviewDetailsSection
          application={application}
          onRedirectUrlChange={onRedirectUrlChange}
          applicationGroup={applicationGroup}
          history={history}
          testUrl={testUrl}
          liveUrls={liveUrls}
          onSignificanceChange={onSignificanceChange}
          facebookPixel={facebookPixel}
        />

        <Flex mt={20} alignSelf="flex-end" justifyContent="space-between">
          <Box>
            <PermissionRestriction
              requiredPermissions={[UserPermissions.createAppPlatformProfile]}
            >
              <Button outline={true} onClick={onAppCopy}>
                Copy Application
              </Button>
            </PermissionRestriction>
          </Box>
          <Box ml={20}>
            <PermissionRestriction
              requiredPermissions={[UserPermissions.updateAppPlatformProfile]}
            >
              <Button onClick={onCancelClick} outline={true}>
                {application.status === Status.inactive
                  ? "Reactivate"
                  : "Cancel All Tests"}
              </Button>
            </PermissionRestriction>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}

export default ApplicationOverviewPage;
