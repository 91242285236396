import React from "react";
import styled from "styled-components";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { ExperimentPhaseType, Platform } from "api/models";

const ANDROID_TITLE_LENGTH = 30;
const IOS_TITLE_LENGTH = 50;

export const displayPlatformName = (platform: Platform) => {
  return `${platform === Platform.ios ? "iOS" : "Android"} Version`;
};

export const getTitleLength = (platform: Platform) => {
  return platform === Platform.ios ? IOS_TITLE_LENGTH : ANDROID_TITLE_LENGTH;
};

export const phaseTypeToLink = (
  history: any,
  phaseType: ExperimentPhaseType,
  applicationId: string,
  isSetTested: boolean,
) => {
  const phaseInfo = {
    [ExperimentPhaseType.icon]: {
      route: authRoutes.APPLICATION_ELEMENTS_ICONS.getUrlWithParams({
        id: applicationId,
      }),
      name: "Icon",
    },
    [ExperimentPhaseType.promoArt]: {
      route: authRoutes.APPLICATION_ELEMENTS_PROMO_ARTS.getUrlWithParams({
        id: applicationId,
      }),
      name: "Promo Art",
    },
    [ExperimentPhaseType.preview_1]: {
      route: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
        id: applicationId,
        position: 1,
      }),
      name: isSetTested ? "Sets of Screens" : "1st Screen",
    },
    [ExperimentPhaseType.preview_2]: {
      route: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
        id: applicationId,
        position: 2,
      }),
      name: "2nd Screen",
    },
    [ExperimentPhaseType.preview_3]: {
      route: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
        id: applicationId,
        position: 3,
      }),
      name: "3rd Screen",
    },
    [ExperimentPhaseType.title]: {
      route: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
        id: applicationId,
        type: "titles",
      }),
      name: "Title",
    },
    [ExperimentPhaseType.subtitle]: {
      route: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
        id: applicationId,
        type: "subtitles",
      }),
      name: "Subtitle",
    },
    [ExperimentPhaseType.description]: {
      route: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
        id: applicationId,
        type: "descriptions",
      }),
      name: "Description",
    },
    [ExperimentPhaseType.featureGraphic]: {
      route: authRoutes.APPLICATION_ELEMENTS_FEATURE_GRAPHICS.getUrlWithParams({
        id: applicationId,
      }),
      name: "Feature Graphic",
    },
  };
  const phase = phaseInfo[phaseType];

  return (
    <Link
      onClick={() => {
        history.push(phase.route);
      }}
    >
      {phase.name}
    </Link>
  );
};

/**
 * Get enum key by value
 * @param {any} enumInstance instance of certain enum
 * @param {string | number} enumValue value of key
 * @returns {string | undefined} enum key by given value
 */
export const getEnumKeyByValue = (
  enumInstance: any,
  enumValue: string | number,
): string | undefined =>
  Object.keys(enumInstance).find((key) => {
    return enumInstance[key as keyof typeof enumInstance] === enumValue;
  });

const Link = styled.span`
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
`;

export default { displayPlatformName };
