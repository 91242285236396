import React, { useContext } from "react";
import { RouteComponentProps } from "react-router";
import { useMutation, useQuery } from "@apollo/client";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { ApplicationAsset, AssetType, Preview, Status } from "api/models";
import {
  updateReferenceVariation as updateReferenceVariationMutation,
  UpdateReferenceVariationResponse,
} from "v2/api/mutations/application/updateReferenceVariation";
import {
  createPreviews,
  deactivatePreview,
  DeactivatePreviewResponse,
  deletePreview,
  DeletePreviewResponse,
} from "v2/api/mutations/application/previews";

import { UPDATE_CONTROL_PREVIEW } from "api/mutations/assets";
import { GET_APPLICATION_PREVIEWS } from "api/queries/assets";

import { ApplicationContext, ModalContext } from "contexts";

import { getTrafficIndicator } from "utils/statistics";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { runAction } from "../helpers/runAction";
import { updateReferenceVariation } from "../helpers/updateReferenceVariation";

import { Spinner, Error } from "components";
import ApplicationElements from "screens/Application/ApplicationElements/components/ApplicationElementsContainer";
import Page from "./ApplicationElementsPreviewsPage";
import { useHasUserPermission } from "v2/hooks/useHasUserPermission";

interface Props extends RouteComponentProps<{ id: string; position: string }> {}

function ApplicationElementsPreviewsContainer({ history, match }: Props) {
  const hasUserPermissions = useHasUserPermission();

  const position = match.params.position;
  const applicationId = match.params.id;
  const { data, error, loading } = useQuery(GET_APPLICATION_PREVIEWS, {
    variables: { applicationId, position: parseInt(position, 10) },
    fetchPolicy: "network-only",
  });

  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const [deleteMutation] = useMutation<DeletePreviewResponse>(
    deletePreview.definition,
  );
  const [controlMutation] = useMutation(UPDATE_CONTROL_PREVIEW);
  const [updateReferenceMutation] = useMutation<
    UpdateReferenceVariationResponse
  >(updateReferenceVariationMutation.definition);
  const [deactivateMutation] = useMutation<DeactivatePreviewResponse>(
    deactivatePreview.definition,
  );

  const setModal = useContext(ModalContext);
  const applicationCtx = useContext(ApplicationContext);

  const assets = data?.previews.filter(
    (preview: Preview) => preview.position.toString() === position,
  );

  const refetchQuery = {
    query: GET_APPLICATION_PREVIEWS,
    variables: { applicationId, position: parseInt(position, 10) },
  };

  const trafficIndicator =
    applicationCtx.current &&
    data &&
    applicationCtx.current.status === Status.active
      ? getTrafficIndicator(data.phaseTraffic, data.trafficNeeded)
      : undefined;

  const removeAsset = React.useCallback(
    (asset: ApplicationAsset) => async () => {
      await runAction(
        applicationId,
        asset,
        deleteMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    },
    [applicationId, deleteMutation, setModal, refetchQuery, setFullPageLoader],
  );

  const displayRemoveModal = React.useMemo(() => {
    if (!hasUserPermissions(deletePreview.requiredPermissions)) {
      return null;
    }

    return (asset: ApplicationAsset) => {
      setModal({
        type: "set",
        props: {
          title: "Delete",
          content: "Are you sure you want to delete this asset?",
          onCancel: () => {},
          onConfirm: removeAsset(asset),
        },
      });
    };
  }, [hasUserPermissions, setModal, removeAsset]);

  const onDeactivateAsset = React.useCallback(
    (asset: ApplicationAsset) => async () => {
      await runAction(
        applicationId,
        asset,
        deactivateMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    },
    [
      applicationId,
      deactivateMutation,
      setModal,
      refetchQuery,
      setFullPageLoader,
    ],
  );

  const displayDeactivateModal = React.useMemo(() => {
    if (!hasUserPermissions(deactivatePreview.requiredPermissions)) {
      return null;
    }

    return (asset: ApplicationAsset) => {
      setModal({
        type: "set",
        props: {
          title: "Remove Asset From Test",
          content:
            "Are you sure you want to remove this asset from current test?",
          onCancel: () => {},
          onConfirm: onDeactivateAsset(asset),
        },
      });
    };
  }, [hasUserPermissions, setModal, onDeactivateAsset]);

  const onControl = async (asset: ApplicationAsset) => {
    await runAction(
      applicationId,
      asset,
      controlMutation,
      setModal,
      refetchQuery,
      setFullPageLoader,
    );
  };

  const onSetTop = React.useMemo(() => {
    if (
      !hasUserPermissions(updateReferenceVariationMutation.requiredPermissions)
    ) {
      return null;
    }

    return async (asset: ApplicationAsset) => {
      await updateReferenceVariation(
        applicationId,
        asset,
        AssetType.preview,
        updateReferenceMutation,
        setModal,
        refetchQuery,
        setFullPageLoader,
      );
    };
  }, [
    refetchQuery,
    setFullPageLoader,
    setModal,
    updateReferenceMutation,
    applicationId,
    hasUserPermissions,
  ]);

  if (loading || !data) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  return (
    <ApplicationElements
      applicationId={applicationId}
      history={history}
      uploadRoute={authRoutes.APPLICATION_CREATE_PREVIEWS.getUrlWithParams({
        id: applicationId,
        position: Number(position),
      })}
      permissionsForUpload={createPreviews.requiredPermissions}
    >
      {fullPageLoader}
      <Page
        applicationId={applicationId}
        currentPhase={data.currentPhase}
        assets={assets}
        position={position}
        onControl={onControl}
        onRemove={displayRemoveModal}
        onDeactivate={displayDeactivateModal}
        onSetTop={onSetTop}
        history={history}
        trafficIndicator={trafficIndicator}
        currentIteration={data.currentIteration}
      />
    </ApplicationElements>
  );
}

export default ApplicationElementsPreviewsContainer;
