import React from "react";

import { UploadFile } from "components/forms/UploadInput";
import { Platform, ScreensType } from "api/models";

import { ApplicationAssetsUpload } from "components/application";

// -- TYPES
export interface StepData {
  control: UploadFile | null;
  assets: UploadFile[];
  referenceVariationType?: string;
  screensType?: ScreensType.single;
}

interface Props {
  data: StepData;
  onChange: (values: StepData) => void;
  onControl?: ((object: any) => void) | null;
  maxUploadAssets?: number;
  platform?: Platform.ios | Platform.android;
}

// -- COMPONENT
class Preview1 extends React.PureComponent<Props> {
  public render() {
    const {
      data,
      onChange,
      onControl = null,
      maxUploadAssets,
      platform,
    } = this.props;

    return (
      <ApplicationAssetsUpload
        header="1st Position (Screen1)"
        assets={data.assets}
        controlAsset={data.control}
        buttonText="Upload Screens"
        emptyTitle="Upload Screens"
        emptyDescription="Use the button above to upload screens from your computer"
        onChange={onChange}
        onControl={onControl}
        video
        maxUploadAssets={maxUploadAssets}
        platform={platform}
      />
    );
  }
}

export default Preview1;
