import React, { useMemo } from "react";
import styled from "styled-components";
import { isArray } from "lodash";
import { Field } from "react-final-form";

import { convertToCurrency } from "utils/numbers";

import {
  subscriptionsDescription,
  contractRequired,
} from "./constants/optionsDescriptions";

import { Price } from "screens/Billing/UserBillingDetails/constants/types";

import theme from "components/themes";

import { Icons, Icon } from "components";
import { Button } from "components/forms";

// -- TYPES
interface TestingOptionsProps {
  prices?: Price[];
  userDomainAlreadyExist: boolean;
}

// -- COMPONENT
export function TestingOptions({ prices }: TestingOptionsProps): JSX.Element {
  const renderSubsOptions = useMemo(() => {
    const subsOptions =
      isArray(prices) &&
      prices
        .filter((singlePriceType) => singlePriceType?.priceType === "RECURRING")
        .sort((a, b) => b.unitAmount - a.unitAmount);

    return subsOptions
      ? subsOptions.map((singleSub) => (
          <p key={singleSub.product?.name}>
            {singleSub.product?.name}:{" "}
            {convertToCurrency(singleSub.unitAmount / 100)} / month
          </p>
        ))
      : "No pricing options available";
  }, [prices]);

  const changeSubscriptionNumber = (
    input: any,
    action: "increase" | "decrease",
  ) => (): void => {
    const newValue = action === "increase" ? input.value + 1 : input.value - 1;
    newValue && input.onChange(newValue);
  };

  return (
    <OptionsWrapper>
      <OptionItem>
        <Header>Unlimited Testing</Header>
        {subscriptionsDescription}
        <SubsOptions>{renderSubsOptions}</SubsOptions>
        <AppNumberWrapper>
          <Field name="subscriptionsNumber">
            {({ input }) => (
              <>
                <Button
                  onClick={changeSubscriptionNumber(input, "decrease")}
                  disabled={input.value === 1}
                >
                  <StyledIcon icon={Icons.faMinus} />
                </Button>
                {input.value}
                <Button onClick={changeSubscriptionNumber(input, "increase")}>
                  <StyledIcon icon={Icons.faPlus} />
                </Button>
              </>
            )}
          </Field>
        </AppNumberWrapper>
        {contractRequired()}
      </OptionItem>
    </OptionsWrapper>
  );
}

// -- STYLED
const Header = styled.h3`
  margin: 0;
  margin-bottom: 30px;

  text-align: center;

  font-size: 26px;
  font-weight: 600;

  color: #fff;
`;
const OptionsWrapper = styled.div`
  display: flex;

  justify-content: space-evenly;
`;
const OptionItem = styled.div`
  width: 100%;

  margin: 10px;
  padding: 15px;

  background-color: #181831;

  border-radius: 8px;

  color: ${theme.colors.white};

  display: flex;

  flex-direction: column;
  align-items: center;

  text-align: center;

  label:first-of-type {
    font-weight: bold;
    font-size: 20px;

    margin: 10px 0 20px;
  }

  p {
    font-size: 17px;
  }
`;
const AppNumberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    min-width: 44px;

    padding: 0 13px;
    margin: 0 20px;
  }
`;
const StyledIcon = styled(Icon)`
  font-size: 18px;

  color: ${theme.colors.white};
`;
const SubsOptions = styled.div`
  margin-bottom: 16px;

  p {
    margin: 0;
  }
`;
