import { Box } from "@rebass/grid";
import { styled } from "@mui/system";

export const ActionableItem = styled("div")`
  color: #fff;

  cursor: pointer;

  font-size: 14px;
  line-height: 24px;

  text-decoration: underline;
`;

export const ActionableItemWithoutLink = styled("div")`
  color: #fff;

  font-size: 14px;
  line-height: 24px;
`;

export const Header = styled("div")`
  font-size: 18px;
  font-weight: bold;

  color: ${(props: any) => props.theme.colors.white};

  margin-left: 30px;
  margin-bottom: 22px;
`;

export const IconBox = styled(Box)`
  padding: 0 10px;

  cursor: pointer;
`;
