import React, { FC } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import config from "config";

const stripePromise = loadStripe(config.stripeKey || "");

// -- TYPES
interface Props {
  children: any;
}

// -- COMPONENT
export const StripeProvider: FC<Props> = ({ children }) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);
