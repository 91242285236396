import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_TESTING_AND_QUEUED_ASSETS_PAGE_DATA } from "api/queries/assets";

import { ApplicationAsset, AssetStatus, Platform } from "api/models";
import { UserPermissions } from "v2/api/types";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { SplitedAssets } from "./constants/SplitedAssets";
import { TestingAndQueuedAssetsPageDataResponse } from "./constants/testingAndQueuedAssetsPageDataResponse";
import {
  GroupedImagesSection,
  GroupedTextsSection,
  Sections,
  SectionType,
  TextSection,
} from "./constants/sections";

import { ApplicationContext } from "contexts";

import Title from "components/Title/Title";
import { Spinner, Error } from "components";
import { ImageSection } from "./components/ImageSection";
import { GroupedImageSections } from "./components/GroupedImageSection";
import { GroupedTextsSections } from "./components/GroupedTextsSections";

// -- TYPES
interface TestingAndQueuedAssetsPageProps
  extends RouteComponentProps<{ id: string }> {}

// -- COMPONENT
function TestingAndQueuedAssetsPage({
  match,
}: TestingAndQueuedAssetsPageProps): JSX.Element {
  const [sections, setSections] = React.useState<Sections[]>([]);
  const applicationCtx = React.useContext(ApplicationContext);

  const { data, error, loading } = useQuery<
    TestingAndQueuedAssetsPageDataResponse
  >(GET_TESTING_AND_QUEUED_ASSETS_PAGE_DATA, {
    variables: {
      applicationId: match.params.id,
      statuses: [
        AssetStatus.testing,
        AssetStatus.queued,
        AssetStatus.new,
      ].map((status) => status.toLocaleLowerCase()),
    },
    fetchPolicy: "no-cache",
  });

  React.useEffect(() => {
    if (!data?.assetsByStatus) {
      return setSections([]);
    }

    const applicationPlatform = data.application.platform;

    const assets: SplitedAssets = data.assetsByStatus;
    const sortTestingFirst = <T extends ApplicationAsset>(
      assetsArray: T[],
    ): T[] =>
      assetsArray.sort((a, b) => {
        if (
          a.status === AssetStatus.testing &&
          b.status !== AssetStatus.testing
        ) {
          return -1;
        } else if (
          a.status !== AssetStatus.testing &&
          b.status === AssetStatus.testing
        ) {
          return 1;
        }

        return 0;
      });

    const appId = data?.application.id;

    const editPermissions = [UserPermissions.updateASOAsset];

    const iconSection: Sections = {
      type: SectionType.SINGLE_IMAGE,
      title: "Icons",
      assets: sortTestingFirst(assets.icons),
      url: authRoutes.APPLICATION_ELEMENTS_ICONS.getUrlWithParams({
        id: appId,
      }),
      editPermissions,
    };

    const promoArtSection: Sections = {
      type: SectionType.SINGLE_IMAGE,
      title: "Promo Arts",
      assets: sortTestingFirst(assets.promoArts),
      url: authRoutes.APPLICATION_ELEMENTS_PROMO_ARTS.getUrlWithParams({
        id: appId,
      }),
      editPermissions,
    };

    const featureGraphicSection: Sections = {
      type: SectionType.SINGLE_IMAGE,
      title: "Feature Graphic",
      assets: sortTestingFirst(assets.featureGraphics),
      url: authRoutes.APPLICATION_ELEMENTS_FEATURE_GRAPHICS.getUrlWithParams({
        id: appId,
      }),
      editPermissions,
    };

    const previewsSection: GroupedImagesSection = {
      type: SectionType.GROUPED_IMAGE,
      title: "Screens",
      groups: [
        {
          title: "1st Place",
          assets: sortTestingFirst(
            assets.previews.filter(
              (singlePreview) => singlePreview.position === 1,
            ),
          ),
          url: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
            id: appId,
            position: 1,
          }),
          editPermissions,
        },
        {
          title: "2nd Place",
          assets: sortTestingFirst(
            assets.previews.filter(
              (singlePreview) => singlePreview.position === 2,
            ),
          ),
          url: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
            id: appId,
            position: 2,
          }),
          editPermissions,
        },
        {
          title: "3rd Place",
          assets: sortTestingFirst(
            assets.previews.filter(
              (singlePreview) => singlePreview.position === 3,
            ),
          ),
          url: authRoutes.APPLICATION_ELEMENTS_PREVIEWS.getUrlWithParams({
            id: appId,
            position: 3,
          }),
          editPermissions,
        },
      ],
    };

    const subtitleSubsection: TextSection = {
      title: "Subtitles",
      assets: sortTestingFirst(assets.subtitles),
      url: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
        id: appId,
        type: "subtitles",
      }),
      editPermissions,
    };

    const textsSections: GroupedTextsSection = {
      type: SectionType.GROUPED_TEXTS,
      groups: [
        {
          title: "Titles",
          assets: sortTestingFirst(assets.titles),
          url: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
            id: appId,
            type: "titles",
          }),
          editPermissions,
        },
        {
          title: "Descriptions",
          assets: sortTestingFirst(assets.descriptions),
          url: authRoutes.APPLICATION_ELEMENTS_TEXTS.getUrlWithParams({
            id: appId,
            type: "descriptions",
          }),
          editPermissions,
        },
      ],
    };

    if (applicationPlatform === Platform.ios) {
      textsSections.groups.splice(1, 0, subtitleSubsection);
    }

    const assetsGrouped: Sections[] = [
      iconSection,
      applicationPlatform === Platform.ios
        ? promoArtSection
        : featureGraphicSection,
      previewsSection,
      textsSections,
    ];

    setSections(assetsGrouped);
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  applicationCtx.setCurrent(data?.application || null);

  return (
    <>
      <Title subtitle="Currently Testing/Queued Assets Overview">
        {data?.application.name}
      </Title>

      {sections.map((singleSection) => {
        if (singleSection.type === SectionType.SINGLE_IMAGE) {
          return <ImageSection {...singleSection} />;
        }

        if (singleSection.type === SectionType.GROUPED_IMAGE) {
          return <GroupedImageSections {...singleSection} />;
        }

        if (singleSection.type === SectionType.GROUPED_TEXTS) {
          return <GroupedTextsSections {...singleSection} />;
        }

        return null;
      })}
    </>
  );
}

export default TestingAndQueuedAssetsPage;
