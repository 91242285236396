import React, { FC, useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useMutation, useQuery } from "@apollo/client";

import {
  EDIT_NUMBER_OF_SUBSCRIPTIONS,
  GET_USER_BILLING_INFO,
  GET_CURRENT_SUBSCRIPTIONS_AND_PRODUCTS_PRICES,
} from "api";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import {
  SubscriptionDetails,
  Price,
} from "screens/Billing/UserBillingDetails/constants/types";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import {
  ApplicationContext,
  ApplicationUserContext,
  useStores,
} from "contexts";

import { EditSubscriptionsForm } from "./EditSubscriptionsForm";
import { Title, Spinner, Error } from "components";
import { NarrowTemplate } from "templates";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

// -- TYPES
interface Response {
  subscription: SubscriptionDetails | null;
  customerPrices: Price[];
}

// -- COMPONENT
const EditSubscriptionsPage: FC<RouteComponentProps> = ({ history }) => {
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const applicationUserCtx = useContext(ApplicationUserContext);
  const applicationCtx = useContext(ApplicationContext);
  const { userStore } = useStores();

  useEffect(() => {
    applicationCtx.current && applicationCtx.setCurrent(null);
  }, [applicationCtx]);

  const { data, error, loading } = useQuery<Response>(
    GET_CURRENT_SUBSCRIPTIONS_AND_PRODUCTS_PRICES,
    { variables: { userId: applicationUserCtx.userId } },
  );

  const [updateBillingDetails] = useMutation(EDIT_NUMBER_OF_SUBSCRIPTIONS);

  const handleError = useRequestErrorHandler();

  const redirectToBillingDetails = () => {
    history && history.push(authRoutes.USER_BILLING_DETAILS.getUrlWithParams());
  };

  const onFormCorrectlySubmit = async (numberOfItems: number) => {
    setFullPageLoader(true);

    const updateBillingDetailsResponse = await handleError(
      updateBillingDetails({
        variables: { numberOfItems, userId: applicationUserCtx.userId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_USER_BILLING_INFO,
            variables: { clientCode: userStore.clientCode },
          },
        ],
      }),
    );

    updateBillingDetailsResponse && redirectToBillingDetails();
    setFullPageLoader(false);
  };

  if (loading) {
    return <Spinner />;
  }

  if (!data || error) {
    return <Error />;
  }

  return (
    <>
      {fullPageLoader}
      <NarrowTemplate>
        <Title>Edit Number of Subscribed Apps Seats</Title>
        <EditSubscriptionsForm
          onSubmit={onFormCorrectlySubmit}
          onDiscard={redirectToBillingDetails}
          initialSubscriptionsNumber={
            data.subscription?.upcomingSubscription || 0
          }
          pricing={data.customerPrices}
        />
      </NarrowTemplate>
    </>
  );
};

export default withRouter(EditSubscriptionsPage);
