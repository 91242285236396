import React from "react";
import styled from "styled-components";
import { Box, Typography } from "@mui/material";

import { Link } from "components";

interface HintShape {
  title: string;
  route: string;
  link: string;
  state?: Record<string, unknown>;
}

interface Props {
  children: React.ReactNode;
  title: string | React.ReactNode;
  hints?: HintShape[];
  asIframe?: boolean;
}

function AuthSection({ children, title, hints = [], asIframe = false }: Props) {
  const renderHints = () => (
    <Box
      width="100%"
      mt={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {hints.map((hint, index) => {
        return (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            key={`auth-hint-${index}`}
          >
            <Typography
              component="div"
              variant="body2"
              fontSize={16}
              fontWeight={300}
              sx={{
                opacity: 0.4,
              }}
              color="#fff"
            >
              {hint.title}
            </Typography>
            <Box>
              <HintLink
                to={{
                  pathname: hint.route,
                  state: hint?.state || {},
                }}
              >
                {hint.link}
              </HintLink>
            </Box>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={4}
        sx={{
          backgroundColor: "#1e1e36",
        }}
      >
        <Typography
          component="div"
          variant="h3"
          fontSize={21}
          fontWeight={600}
          color="#fff"
          textAlign="center"
          mb={2}
        >
          {title}
        </Typography>

        <Box display="flex" alignItems="center" flexDirection="column">
          <Box width="100%">{children as any}</Box>
          {!asIframe && renderHints()}
        </Box>
      </Box>
    </Box>
  );
}

const HintLink = styled(Link)`
  font-size: 16px;
  margin-left: 10px;
  text-decoration: underline;
`;

export default AuthSection;
