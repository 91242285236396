import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";

import { GET_APPLICATION_PIXEL } from "api/queries/facebook_pixel";
import {
  CREATE_APP_PIXEL,
  EDIT_APP_PIXEL,
  DELETE_APP_PIXEL,
} from "api/mutations/facebook_pixel";
import { GET_APPLICATION_OVERVIEW } from "api/queries";

import { FacebookPixel } from "api/models";

import { getEnumKeyByValue } from "utils/enums";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { PixelEventsTypes } from "./constants/pixelEventsTypes";
import { GetApplicationPixelResponse } from "./constants/getApplicationPixelResponse";

import { ModalContext } from "contexts";

import { Spinner, Title, Error } from "components";
import { NarrowTemplate } from "templates";
import { EditPixelForm } from "./components/EditPixelForm";
import { Button } from "components/forms";

// -- TYPES
interface ApplicationPixelPageProps
  extends RouteComponentProps<{ id: string }> {}

// -- COMPONENT
function ApplicationPixelPage({
  history,
  match,
}: ApplicationPixelPageProps): JSX.Element {
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const setModal = React.useContext(ModalContext);

  const applicationId = match.params.id;

  // TODO: powinniśmy setować id apki aby było boczne menu

  const { data, error, loading } = useQuery<GetApplicationPixelResponse>(
    GET_APPLICATION_PIXEL,
    {
      variables: { applicationId },
    },
  );
  const [createMutation] = useMutation(CREATE_APP_PIXEL);
  const [editMutation] = useMutation(EDIT_APP_PIXEL);
  const [deleteMutation] = useMutation(DELETE_APP_PIXEL);

  const handleError = useRequestErrorHandler();

  // TODO: dodać generyka
  const initialEventType = getEnumKeyByValue(
    PixelEventsTypes,
    PixelEventsTypes.SubmitApplication,
  );

  const defaultValues: FacebookPixel = {
    pixelId: "",
    enabled: false,
    eventType: initialEventType as keyof typeof PixelEventsTypes,
    domainTagContent: "",
  };

  const onHandleSubmit = async (values: any) => {
    setFullPageLoader(true);
    const mutationToUse = data?.facebookPixel ? editMutation : createMutation;

    const result = await handleError(
      mutationToUse({
        variables: {
          ...values,
          applicationId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_APPLICATION_PIXEL,
            variables: { applicationId },
          },
          {
            query: GET_APPLICATION_OVERVIEW,
            variables: { applicationId },
          },
        ],
      }),
    );

    result &&
      history &&
      history.push(
        authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({ id: applicationId }),
      );
    setFullPageLoader(false);
  };

  const onDiscard = () =>
    history &&
    history.push(
      authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({ id: applicationId }),
    );

  const deletePixel = async () => {
    setFullPageLoader(true);

    const result = await handleError(
      deleteMutation({
        variables: { applicationId },
        refetchQueries: [
          {
            query: GET_APPLICATION_PIXEL,
            variables: { applicationId },
          },
          {
            query: GET_APPLICATION_OVERVIEW,
            variables: { applicationId },
          },
        ],
      }),
    );

    result &&
      history &&
      history.push(
        authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({ id: applicationId }),
      );
    setFullPageLoader(false);
  };

  const displayDeleteModal = () => {
    setModal({
      type: "set",
      props: {
        title: "Warning",
        content: "Are you sure you want to delete this pixel?",
        onConfirm: deletePixel,
      },
    });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  const isEdit = Boolean(data?.facebookPixel);

  return (
    <>
      {fullPageLoader}
      <NarrowTemplate>
        <Title
          button={
            <Button outline={true} onClick={onDiscard}>
              Cancel
            </Button>
          }
        >
          {isEdit ? "Edit" : "Add"} Facebook Pixel
        </Title>
        <EditPixelForm
          initialValues={data?.facebookPixel || defaultValues}
          onSubmit={onHandleSubmit}
          onDelete={isEdit ? displayDeleteModal : undefined}
        />
      </NarrowTemplate>
    </>
  );
}

export default ApplicationPixelPage;
