import { ApplicationAsset, AssetStatus } from "api/models";

export function groupAssets(assets: ApplicationAsset[]) {
  const assetsByStatus = (status: AssetStatus) =>
    assets.filter((asset) => asset.status === status);

  const top = [...assets]
    .filter(
      (asset) =>
        [
          AssetStatus.testing,
          AssetStatus.tested,
          AssetStatus.discarded,
        ].indexOf(asset.status || AssetStatus.new) !== -1,
    )
    .sort((a, b) => (b.conversionRate || 0) - (a.conversionRate || 0))
    .slice(0, 4);
  const testing = assetsByStatus(AssetStatus.testing);
  const newest = assetsByStatus(AssetStatus.new);
  const queued = [...assetsByStatus(AssetStatus.queued), ...newest];
  const tested = assets.filter(
    (asset) =>
      asset.status === AssetStatus.tested ||
      (asset.status === AssetStatus.testing && asset.isTop),
  );
  const discarded = assetsByStatus(AssetStatus.discarded);
  const control = assets.find((asset) => asset.isControl);

  return {
    top,
    testing,
    queued,
    tested,
    discarded,
    control,
  };
}
