import * as React from "react";
import styled from "styled-components";
import {
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
} from "react-table";

import { TableHeader } from "./TableHeader";
import { TableContent } from "./TableContent";
import { TableFooter, TableFooterProps } from "./TableFooter";

// -- PROPS
export type TableComponentProps = {
  displayHeader?: boolean;
  highlightFirstCell?: boolean;
  footerProps?: TableFooterProps;
  trElement?: React.ComponentType<any>;
  columnsNumber: number;
  rows: Row<{}>[];
  getTableProps: (
    propGetter?: TablePropGetter<object> | undefined,
  ) => TableProps;
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<object> | undefined,
  ) => TableBodyProps;
  headerGroups: HeaderGroup<object>[];
  prepareRow: (row: Row<object>) => void;
};

// -- COMPONENT
function TableComponent({
  displayHeader = true,
  highlightFirstCell = false,
  footerProps,
  trElement,
  columnsNumber,
  rows,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
}: TableComponentProps): JSX.Element {
  return (
    <StyledTable {...getTableProps()}>
      {displayHeader && <TableHeader headerGroups={headerGroups} />}
      <Tbody
        {...getTableBodyProps()}
        displayHeader={displayHeader}
        footerProps={footerProps}
      >
        <TableContent
          columnsNumber={columnsNumber}
          highlightFirstCell={highlightFirstCell}
          prepareRow={prepareRow}
          rows={rows}
          trElement={trElement}
        />
      </Tbody>
      {footerProps && <TableFooter {...footerProps} />}
    </StyledTable>
  );
}

export const Table = React.memo(TableComponent);

// -- STYLED
const StyledTable = styled.table`
  width: 100%;

  border-collapse: collapse;
  border-radius: 8px;
`;

const Tbody = styled.tbody<
  Pick<TableComponentProps, "displayHeader" | "footerProps">
>`
  ${(props) =>
    !props.displayHeader &&
    `
    & > tr:first-of-type {
      td:first-child {
        border-top-left-radius: 10px;
      }

      td:last-child {
        border-top-right-radius: 10px;
      }
    }
  `}

  ${(props) =>
    !props.footerProps &&
    `
    & > tr:last-of-type {
      td:first-child {
        border-bottom-left-radius: 10px;
      }

      td:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  `}
`;
