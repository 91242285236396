import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";
import { AuthSection } from "components/auth";
import { Link } from "components";

function ForgotPasswordConfirmPage() {
  const { state } = useLocation<{ userEmail?: string } | undefined>();

  const redirectTo = {
    pathname: nonAuthRoutes.AUTH_FORGOT_PASSWORD.getUrlWithParams(),
    state,
  };

  return (
    <AuthSection title="Forgot password" hints={[]}>
      <Text>
        We have sent a reset link to this email address. Please check your
        inbox. <br />
        <br />
        If you have not received any message you can{" "}
        <TextLink to={redirectTo} replace>
          resend it
        </TextLink>
        . If you have provided the wrong email address you can retype it{" "}
        <TextLink to={redirectTo} replace>
          here
        </TextLink>
        .
      </Text>
    </AuthSection>
  );
}

const Text = styled.p`
  margin: 60px 0;
`;

const TextLink = styled(Link)`
  text-decoration: underline;
  font-weight: bold;
`;

export default ForgotPasswordConfirmPage;
