import React from "react";
import styled from "styled-components";

import theme from "components/themes";
import FormError from "./FormError";

function TextInput(props: any) {
  const valid = !props.error;
  const showError = props.showError !== false;

  return (
    <>
      <Input light={props.light} {...props} id={props.name} valid={valid} />
      {!valid && showError ? <FormError>{props.error}</FormError> : null}
    </>
  );
}

// eslint-disable-next-line
const Input = styled.input<{
  light: boolean;
  valid: boolean;
  disabled?: boolean;
}>`
  outline: none;
  width: 100%;
  padding: 10px 15px;
  height: 38px;
  border-radius: 2px;
  border: 1px solid ${theme.colors.background};
  background-color: ${(props) =>
    props.light ? theme.colors.inputsLight : theme.colors.inputs};
  color: ${theme.colors.white};
  font-size: 16px;
  opacity: ${(props: any) => (props.disabled ? 0.5 : 0.8)};

  ${(props) =>
    !props.valid &&
    `
    box-shadow: 0 0 15px 0 ${theme.colors.notificationRedBoxShadow};
    border: 1px solid ${theme.colors.notificationRed};
  `};

  ::placeholder {
    opacity: 0.3;
    font-size: 16px;
    font-weight: 300;
    color: ${theme.colors.white};
  }
`;

export default TextInput;
