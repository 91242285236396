import { gql } from "@apollo/client";

import { ApiMutation } from "v2/api/classes/apiMutation";
import { UserPermissions } from "v2/api/types/UserPermissions";

export const updateApplication = new ApiMutation({
  definition: gql`
    mutation updateApplication(
      $clientCode: String!
      $applicationCode: String!
      $application: UpdateApplication!
    ) {
      updateApplication(
        clientCode: $clientCode
        applicationCode: $applicationCode
        application: $application
      )
    }
  `,
  requiredPermissions: [UserPermissions.updateAppPlatformProfile],
});
