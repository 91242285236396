import { useMediaQuery } from "@mui/material";

export const useIsMobile = () => {
  const isUserAgentMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent,
  );
  const smDown = useMediaQuery((defaultTheme: any) =>
    defaultTheme.breakpoints.down("sm"),
  );
  if (isUserAgentMobile || smDown) return true;
  return false;
};
