import * as React from "react";
import { styled } from "@mui/system";

import { TdComponent } from "./TdComponent";
import { TrComponent } from "./TrComponent";
import { Pagination } from "components/Pagination/Pagination";

// -- PROPS
export interface TableFooterProps {
  columnsNumber: number;
  pagesCount: number;
  currentPage: number;
  onPaginationChange: (pageNumber: number, itemsPerPage: number) => void;
  displayItemsCountSelector?: boolean;
  initialItemsCount?: number;
}
// -- COMPONENT
export function TableFooter({
  columnsNumber,
  pagesCount,
  currentPage,
  onPaginationChange,
  displayItemsCountSelector,
  initialItemsCount,
}: TableFooterProps): JSX.Element {
  return (
    <Tfoot>
      <TrComponent>
        <TdComponent colSpan={columnsNumber}>
          <Pagination
            pagesCount={pagesCount}
            currentPageNumber={currentPage}
            onPaginationChange={onPaginationChange}
            displayItemsCountSelector={displayItemsCountSelector}
            initialItemsCount={initialItemsCount}
          />
        </TdComponent>
      </TrComponent>
    </Tfoot>
  );
}

// -- STYLED
const Tfoot = styled("tfoot")`
  background-color: ${(props: any) =>
    props.theme.table.colors.tFootBackgroundColor};

  & > tr {
    td {
      background-color: ${(props: any) =>
        props.theme.table.colors.tFootBackgroundColor};
    }

    td:first-child {
      border-bottom-left-radius: 10px;
    }

    td:last-child {
      border-bottom-right-radius: 10px;
    }
  }
`;
