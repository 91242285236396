import React from "react";

import { NarrowTemplate } from "templates";

import { StripeProvider } from "components/billing/StripeProvider";
import { FailedPaymentsPage } from "./FailedPaymentsPage";

// -- COMPONENT
const FailedPaymentsContainer = () => (
  <StripeProvider>
    <NarrowTemplate>
      <FailedPaymentsPage />
    </NarrowTemplate>
  </StripeProvider>
);

export default FailedPaymentsContainer;
