import React, { useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Flex, Box } from "@rebass/grid";
import { writeText } from "clipboard-polyfill";
import { styled } from "@mui/system";
import { PlatformContext, PlatformStore } from "@upptic/module-directory";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";
import { GET_APPLICATION_TITLE_AND_STATUS } from "api/queries";
import { updateApplication } from "v2/api/mutations/application/updateApplication";

import { Icon, Icons, Spinner, Title, Popover, Error } from "components";
import { Button, HoverInput } from "components/forms";
import { ApplicationContext } from "contexts";
import { useStores } from "contexts/storeContext";

import themes from "components/themes";
import validators from "utils/validators";
import { UserPermissions } from "v2/api/types";

interface Props {
  history: any;
  applicationId: string;
  children: React.ReactNode;
  customPreviewLabel?: string;
  customPreviewUrl?: string;
}

function ApplicationDetailContainer({
  children,
  applicationId,
  history,
  customPreviewLabel,
  customPreviewUrl,
}: Props) {
  const { userStore } = useStores();

  const applicationCtx = useContext(ApplicationContext);
  const platformStore = useContext<PlatformStore>(PlatformContext);

  const [renameApplication] = useMutation(updateApplication.definition);
  const { data, error, loading } = useQuery(GET_APPLICATION_TITLE_AND_STATUS, {
    variables: { id: applicationId },
    onCompleted: ({ application }) => {
      applicationCtx.setCurrent(application);
    },
  });

  const onAppNameChange = React.useCallback(
    async (value: string | number) => {
      if (value !== data?.application?.name) {
        await renameApplication({
          variables: {
            clientCode: userStore.clientCode,
            applicationCode: data.application.groupCode,
            application: {
              name: value,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: ["application", "applications"],
        });
        await platformStore.loadApplications();
      }
    },
    [renameApplication, data, userStore],
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <Error errorElement={error.message} />;
  }

  if (data.application === null) {
    history.replace(authRoutes.APPLICATION_LIST.getUrlWithParams());
  }

  return (
    <>
      <Title
        button={
          <Flex>
            <Box mr={20}>
              <Link
                href={customPreviewUrl || data.testUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkButton outline={true}>
                  <LinkIcon
                    icon={Icons.faExternalLinkAlt}
                    color={themes.colors.white}
                  />
                  {customPreviewLabel || "Preview"}
                </LinkButton>
              </Link>
            </Box>
            <Box>
              <Popover content="Copied">
                <LinkButton
                  outline={true}
                  onClick={async () => {
                    await writeText(data.liveUrl);
                  }}
                >
                  <LinkIcon icon={Icons.faLink} color={themes.colors.white} />
                  Copy Live URL
                </LinkButton>
              </Popover>
            </Box>
          </Flex>
        }
      >
        <EditTitle
          value={data.application.name}
          onSave={onAppNameChange}
          inputProps={{
            placeholder: "No Title",
          }}
          valueValidators={validators.composeValidators(
            false,
            validators.maxStringLength(30),
          )}
          maxLength={30}
          isEditAvailable={updateApplication.hasUserPermissions(
            platformStore.currentUserFrontendPermissions as UserPermissions[],
          )}
        />
      </Title>

      {children}
    </>
  );
}

const Link = styled("a")`
  :hover {
    text-decoration: none;
  }
`;

const LinkButton = styled(Button)`
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
`;

const LinkIcon = styled(Icon)`
  margin-right: 10px;
`;

const EditTitle = styled(HoverInput)`
  font-size: 36px;
  color: ${(props: any) => props.theme.colors.white};
  margin: 35px 0;

  > div:first-of-type {
    input {
      font-size: 36px;
      color: ${(props: any) => props.theme.colors.white};
    }
  }
`;

export default ApplicationDetailContainer;
