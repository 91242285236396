import { AssetStatus } from "api/models";

import { conversionRateLift } from "utils/statistics";
import getConfidence from "utils/getConfidence";
import { percentFormat, toLocaleNumber } from "utils/numbers";
import { Item } from "components/StatsTable";

export function getStatisticsItems(
  asset: any,
  canAbort: boolean,
  displayConversions: boolean,
  displayConversionRate: boolean,
  displayLift: boolean,
  displayVisits: boolean,
  controlConversionRate: number,
  isImage: boolean = false,
) {
  const { conversionRate, conversion, visits } = asset;
  const statisticsItems: Item[] = [];

  if (displayConversionRate) {
    statisticsItems.push({
      key: "Conversion Rate",
      value: isNaN(conversionRate)
        ? conversionRate
        : percentFormat(conversionRate),
    });
  }

  if (displayVisits) {
    statisticsItems.push({
      key: "Visits",
      value: isNaN(visits) ? visits : toLocaleNumber(visits),
    });
  }

  if (displayConversions) {
    statisticsItems.push({
      key: "Conversions",
      value: isNaN(conversion) ? conversion : toLocaleNumber(conversion),
    });
  }

  if (displayLift && !asset.isControl) {
    const calculatedConversionRateLift = conversionRateLift(
      controlConversionRate,
      asset.conversionRate,
    );

    statisticsItems.push({
      key: "Lift Over Control",
      value: isNaN(Number(calculatedConversionRateLift))
        ? calculatedConversionRateLift.toString()
        : percentFormat(calculatedConversionRateLift as number),
    });
  }

  if (canAbort && asset.status === AssetStatus.testing) {
    const value = getConfidence(asset);

    statisticsItems.push({
      key: "Confidence",
      value: isNaN(Number(value))
        ? value.toString()
        : percentFormat(value as number),
      barWidth: isImage ? 200 : undefined,
      barValue: value,
    });
  }

  return statisticsItems;
}
