import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query notifications($page: Int!, $itemsPerPage: Int!) {
    notifications(page: $page, itemsPerPage: $itemsPerPage) {
      pagesCount
      page
      items {
        id
        type
        data
        createdAt
        opened
        application {
          id
          name
        }
        user {
          id
        }
      }
    }
  }
`;

export const HAS_NOTIFICATION_EMAILS_ENABLED = gql`
  query hasNotificationEmailsEnabled {
    hasNotificationEmailsEnabled
  }
`;

export const ARE_NEW_NOTIFICATIONS = gql`
  query hasNewNotifications($lastCheck: DateTime) {
    checkNew(lastCheck: $lastCheck)
  }
`;

export const GET_CURRENT_USER_HISTORY = gql`
  query currentUserNotificationHistory(
    $clientCode: String!
    $applicationCode: String
    $filter: Filter
    $limit: Int
    $offset: Int
  ) {
    currentUserNotificationHistory(
      clientCode: $clientCode
      applicationCode: $applicationCode
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      items {
        code
        event {
          name
          displayName
          group
          level
        }
        affectedEntity {
          type
          code
          name
        }
        subject
        content
        status
        link
        application {
          code
          name
        }
        audit {
          createDate
        }
      }
      totalItemCount
      unreadCount
    }
  }
`;

export const UPDATE_CURRENT_USER_HISTORY_ENTRY = gql`
  mutation updateCurrentUserHistoryEntry(
    $clientCode: String!
    $historyEntryCode: String!
    $status: UserHistoryEntryStaus!
  ) {
    updateCurrentUserHistoryEntry(
      clientCode: $clientCode
      code: $historyEntryCode
      status: $status
    )
  }
`;

export const DISMISS_CURRENT_USER_HISTORY_ENTRY = gql`
  mutation dismissCurrentUserHistoryEntry(
    $clientCode: String!
    $historyEntryCode: String!
  ) {
    dismissCurrentUserHistoryEntry(
      clientCode: $clientCode
      code: $historyEntryCode
    )
  }
`;

export const DISMISS_ALL_NOTIFICATIONS = gql`
  mutation dismissAllCurrentUserHistoryEntries(
    $applicationCode: String
    $clientCode: String!
  ) {
    dismissAllCurrentUserHistoryEntries(
      applicationCode: $applicationCode
      clientCode: $clientCode
    )
  }
`;
