import React, { useState } from "react";
import { RouteComponentProps } from "react-router";

import ApplicationControlIconsPage from "./ApplicationControlIconsPage";
import ApplicationControlUpdateContainer from "../components/ApplicationControlUpdateContainer";
import { UPDATE_CONTROL_ICON } from "api/mutations/assets";
import { StepData } from "components/application/forms/IconStep";

interface Props extends RouteComponentProps<{ id: string }> {}

function ApplicationControlIconsContainer(props: Props) {
  const defaultState: StepData = {
    control: null,
    assets: [],
  };
  const [formData, setFormData] = useState(defaultState);

  return (
    <ApplicationControlUpdateContainer
      mutation={UPDATE_CONTROL_ICON}
      formData={formData}
      {...props}
    >
      <ApplicationControlIconsPage
        formData={formData}
        setFormData={setFormData}
      />
    </ApplicationControlUpdateContainer>
  );
}

export default ApplicationControlIconsContainer;
