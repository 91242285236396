import React, { useContext } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react";

import { ApplicationListing } from "api";

import { useStores } from "contexts/storeContext";

import { menuTypes } from "./constants/menuTypes";
import {
  menus as directoryMenus,
  PlatformContext,
  PlatformStore,
} from "@upptic/module-directory";
import { createUrlWithParams } from "v2/utils/url";
import { UserPermissions } from "v2/api/types";
import { moduleNavigationElements } from "../../v2/components/SideMenuV2/moduleNavigationElements";

import SideMenuComponentV2 from "../../v2/components/SideMenuV2/SideMenuComponentV2";
import { useIsMobile } from "v2/components/SideMenuV2/helper";
interface SideMenuComponentProps extends RouteComponentProps<{}> {
  collapsed: boolean;
  application: ApplicationListing | null;
  onMenuToggle?: () => void;
}

function SideMenuComponent({
  collapsed,
  application,
  onMenuToggle,
}: SideMenuComponentProps) {
  const { layoutStore } = useStores();
  const platformStore = useContext<PlatformStore>(PlatformContext);
  const isMobile = useIsMobile();

  const currentModule = platformStore.currentModule;
  const routeParams = platformStore.currentRoute?.params as any;
  const currentApplicationCode = platformStore.selectedApplication?.code;

  const availableModuleElements = moduleNavigationElements
    .map((entry) => {
      const entryObj = { ...entry };
      if (entryObj.route?.includes(":")) {
        entryObj.route = createUrlWithParams(entryObj.route, {
          ...routeParams,
          clientCode: platformStore.currentClient?.code,
          applicationCode: currentApplicationCode,
        });
      }
      return entryObj;
    })
    .filter(({ permissions, menuType }) => {
      if (menuType === "uppticMenu" && platformStore.isDemoModeEnabled)
        return false;
      if (!permissions) return true;
      if (!permissions.length) return false;
      if (
        permissions.every((code) =>
          platformStore.currentUserFrontendPermissions.includes(
            code as UserPermissions,
          ),
        )
      )
        return true;
      return false;
    });

  const menuItemsArray = React.useMemo(() => {
    const menuEntries = [];
    const moduleElements = isMobile
      ? availableModuleElements.filter((element) => element.supportsMobile)
      : availableModuleElements;
    for (const element of moduleElements) {
      const directoryResult = directoryMenus[element?.menuType]?.entries;
      if (Array.isArray(directoryResult)) {
        const subItems = directoryResult
          .map((entry) => {
            const entryObj = {
              ...entry,
            };
            if (entryObj.route?.includes(":")) {
              entryObj.route = createUrlWithParams(entryObj.route, {
                ...routeParams,
                clientCode: platformStore.currentClient?.code,
                applicationCode: currentApplicationCode,
              });
            }
            return entryObj;
          })
          .filter(({ permissions }) => {
            if (!permissions) return true;
            if (!permissions.length) return false;
            if (
              permissions.every((code) =>
                platformStore.currentUserFrontendPermissions.includes(
                  code as UserPermissions,
                ),
              )
            )
              return true;
            return false;
          });
        menuEntries.push({
          ...element,
          items: isMobile
            ? subItems?.filter((itemElement) => itemElement.supportsMobile)
            : subItems,
        });
        continue;
      }
      menuEntries.push({
        ...element,
      });
    }
    return menuEntries;
  }, [
    availableModuleElements,
    collapsed,
    platformStore.currentUserFrontendPermissions,
    platformStore.currentClient,
    currentApplicationCode,
    routeParams,
    isMobile,
  ]);

  const bottomMenuItems = React.useMemo(() => {
    const directoryResult = directoryMenus?.bottomMenu?.entries;
    if (Array.isArray(directoryResult)) {
      const mappedDirectoryResult = directoryResult
        .map((entry) => {
          const entryObj = { ...entry };
          if (entryObj.route?.includes(":")) {
            entryObj.route = createUrlWithParams(entryObj.route, {
              ...routeParams,
              clientCode: platformStore.currentClient?.code,
              applicationCode: currentApplicationCode,
            });
          }
          return entryObj;
        })
        .filter(({ permissions }) => {
          if (!permissions) return true;
          if (!permissions.length) return false;
          if (
            permissions.every((code) =>
              platformStore.currentUserFrontendPermissions.includes(
                code as UserPermissions,
              ),
            )
          )
            return true;
          return false;
        });
      return isMobile
        ? mappedDirectoryResult?.filter((element) => element.supportsMobile)
        : mappedDirectoryResult;
    }
  }, [
    platformStore.currentUserFrontendPermissions,
    platformStore.currentClient,
    currentApplicationCode,
    routeParams,
    isMobile,
  ]);

  const asoMenuItems = React.useMemo(() => {
    const directoryResult = directoryMenus[layoutStore.sideMenuMode]?.entries;
    if (typeof directoryResult === "function")
      return directoryResult(
        collapsed,
        application,
        platformStore.currentUserFrontendPermissions,
      );
    if (menuTypes[layoutStore.sideMenuMode]) {
      const menuElementsGetter = menuTypes[layoutStore.sideMenuMode];
      return menuElementsGetter(
        collapsed,
        application,
        platformStore.currentUserFrontendPermissions as UserPermissions[],
        platformStore?.currentClient?.code,
      );
    }
  }, [
    layoutStore.sideMenuMode,
    application,
    platformStore.currentUserFrontendPermissions,
    platformStore.currentClient,
    currentApplicationCode,
    routeParams,
  ]);

  return (
    <SideMenuComponentV2
      menuItems={menuItemsArray}
      bottomMenuItems={bottomMenuItems}
      asoMenuItems={asoMenuItems}
      collapsed={collapsed}
      currentModule={currentModule}
      onMenuToggle={onMenuToggle as any}
    />
  );
}

export const SideMenu = withRouter(observer(SideMenuComponent));
