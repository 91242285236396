// -- CORE
import React from "react";
import { Redirect } from "react-router-dom";
// -- CONST
import { Route } from "v2/api/classes/route";
import { MenuMode } from "v2/api/types/menuMode";
// -- PAGES
const UserBillingDetailsPage = React.lazy(() =>
  import("screens/Billing/UserBillingDetails/UserBillingDetailsPage"),
);
const FailedPaymentsContainer = React.lazy(() =>
  import("screens/Billing/FailedPayments/FailedPaymentsContainer"),
);

// -- ROUTES DEFINITION
export const failedPaymentsRoutes = {
  USER_BILLING_DETAILS: new Route<never>({
    path: "/user/billing-details/failed-payment",
    component: UserBillingDetailsPage,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  FAILED_PAYMENTS: new Route<never>({
    path: "/user/pay-overdue-invoice",
    component: FailedPaymentsContainer,
    exact: true,
    menuMode: MenuMode.NONE,
  }),
  PAGE_NOT_FOUND: new Route<never>({
    render: () => <Redirect to={"/user/billing-details"} />,
    menuMode: MenuMode.NONE,
  }),
};
