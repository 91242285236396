import React, { useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router";
import { Redirect } from "react-router-dom";
import { getCookie } from "react-use-cookie";

import { nonAuthRoutes } from "v2/routes/routesGroups/nonAuthRoutes";

import { REGISTER } from "api/mutations/auth";
import SignUpPage, { FormData } from "./SignUpPage";
import { ModalContext } from "contexts";
import { errorMessageHandler } from "utils/errorHandlers/errorMessageHandler";

import config from "config";
import { GET_USER_IP } from "api/queries/user";

interface Props extends RouteComponentProps<{}> {}

function SignUpContainer({ history, location }: Props) {
  const [validationError, setValidationError] = useState<string | null>(null);

  const { data } = useQuery(GET_USER_IP);
  const [signUp] = useMutation(REGISTER);

  const setModal = useContext(ModalContext);

  return <Redirect to="/" />;

  // const handleSubmit = async (submitFormData: FormData) => {
  //   setValidationError(null);
  //   try {
  //     await signUp({
  //       variables: {
  //         user: { ...submitFormData, terms: undefined },
  //       },
  //     });

  //     const {
  //       hubspotFormId,
  //       hubspotFormUrl,
  //       hubspotPortalId,
  //       hubspotEnabled,
  //     } = config;

  //     if (
  //       hubspotFormId &&
  //       hubspotFormUrl &&
  //       hubspotPortalId &&
  //       hubspotEnabled
  //     ) {
  //       const hubSpotParams = {
  //         portalId: hubspotPortalId,
  //         formGuid: hubspotFormId,
  //         fields: [
  //           {
  //             name: "email",
  //             value: submitFormData.emailAddress,
  //           },
  //           {
  //             name: "firstname",
  //             value: submitFormData.firstName,
  //           },
  //           {
  //             name: "lastname",
  //             value: submitFormData.lastName,
  //           },
  //           {
  //             name: "company",
  //             value: submitFormData.company,
  //           },
  //           {
  //             name: "growth_services",
  //             value: "ASO",
  //           },
  //           {
  //             name: "referral_url",
  //             value: window.location.origin,
  //           },
  //         ],
  //         context: {
  //           hutk: getCookie("hubspotutk") || undefined,
  //         },
  //         ipAddress: data?.userIp,
  //         pageName: "sign-up",
  //         pageUri: window.location.href,
  //       };

  //       const url = hubspotFormUrl
  //         .replace(":portalId", hubspotPortalId)
  //         .replace(":formGuid", hubspotFormId);

  //       await fetch(url, {
  //         method: "POST",
  //         body: JSON.stringify(hubSpotParams),
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }).catch((error) => console.error(error));
  //     }

  //     history.push(nonAuthRoutes.AUTH_SIGN_UP_CONFIRM.getUrlWithParams());
  //   } catch (err) {
  //     const customErrorCode = [
  //       "INCORRECT_CODE",
  //       "EMAIL_IN_USE",
  //       "EMAIL_FORBIDDEN",
  //     ].find(
  //       (errorSign) => errorSign === err.graphQLErrors[0]?.extensions.code,
  //     );

  //     if (customErrorCode) {
  //       setValidationError(customErrorCode);
  //     } else {
  //       const errorMessage = errorMessageHandler(err);

  //       setModal({
  //         type: "set",
  //         props: {
  //           content: errorMessage,
  //         },
  //       });
  //     }
  //   }
  // };

  // return (
  //   <SignUpPage
  //     validationError={validationError}
  //     onSubmit={handleSubmit}
  //     setValidationError={setValidationError}
  //   />
  // );
}

export default SignUpContainer;
