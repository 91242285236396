import React, { FC } from "react";
import { Form, FormRenderProps } from "react-final-form";

import { FormFooter } from "components/forms";

import { Customer } from "screens/Billing/UserBillingDetails/constants/types";
import { BillingDetailsFormFields } from "components/billing/BillingDetailsFormField/BillingDetailsFormFields";

// -- TYPES
interface Props {
  onSubmit: (formData: Customer) => any;
  onDiscard: () => void;
  initialData: Customer;
}

// -- COMPONENT
export const BillingDetailsForm: FC<Props> = ({
  onSubmit,
  onDiscard,
  initialData,
}) => (
  <Form onSubmit={onSubmit} initialValues={initialData}>
    {({ handleSubmit }: FormRenderProps) => (
      <form onSubmit={handleSubmit}>
        <BillingDetailsFormFields />
        <FormFooter onCancel={onDiscard} disabled={false} />
      </form>
    )}
  </Form>
);
