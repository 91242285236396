import React, { FC } from "react";

import Preview1, {
  StepData,
} from "components/application/forms/components/PreviewStep/Preview1";
import Preview2 from "components/application/forms/components/PreviewStep/Preview2";
import Preview3 from "components/application/forms/components/PreviewStep/Preview3";
import AdditionalPreview from "components/application/forms/components/PreviewStep/AdditionalPreview";

import { Platform } from "api/models";
import { SinglePreviews } from "./ApplicationControlPreviewsContainer";
import { UploadFile } from "components/forms/UploadInput";

// -- TYPES
interface Props {
  onChange: (values: object) => void;
  formData: SinglePreviews;
  platform?: Platform.ios | Platform.android;
  displayAdditionals?: boolean;
}

// -- COMPONENT
const ApplicationControlPreviewsPage: FC<Props> = ({
  formData,
  platform,
  onChange,
  displayAdditionals = false,
}) => {
  const handleAssetsChange = (fieldName: string) => (data: StepData) =>
    onChange({ [fieldName]: data.assets });
  const handleAdditionalChange = (fieldName: string) => (data: {
    assets: UploadFile[];
  }) => onChange({ [fieldName]: data.assets });

  return (
    <>
      <Preview1
        data={{ assets: formData.control1, control: null }}
        onChange={handleAssetsChange("control1")}
        maxUploadAssets={1}
        platform={platform}
      />
      <Preview2
        data={{ assets: formData.control2, control: null }}
        onChange={handleAssetsChange("control2")}
        maxUploadAssets={1}
      />
      <Preview3
        data={{ assets: formData.control3, control: null }}
        onChange={handleAssetsChange("control3")}
        maxUploadAssets={1}
      />
      {displayAdditionals && (
        <AdditionalPreview
          data={{ assets: formData.additionals }}
          onChange={handleAdditionalChange("additionals")}
        />
      )}
    </>
  );
};

export default ApplicationControlPreviewsPage;
