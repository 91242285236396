import React from "react";
import styled from "styled-components";
import { authRoutes } from "v2/routes/routesGroups/authRoutes";
import { isEmpty } from "lodash";
import UnStyledIframe from "react-iframe";

import {
  EliminatedControlAssetNotification,
  NewBcrCalculationNotification,
  NewTopConversionNotification,
  NewTopElementTestedNotification,
  Notification,
} from "screens/Notification/consts/interfaces";
import { NotificationStatuses } from "screens/Notification/consts/enums";

import { percentFormat as castToPercentFormat } from "utils/numbers";
import { truncate } from "utils/strings";

import youtube from "./youtube";

import { Tooltip } from "components";

export const newBcrCalculationDescription = (
  notificationData: NewBcrCalculationNotification["data"],
): string => {
  const { new_baseline_conversion_rate } = notificationData;

  return `Calculation of a new Baseline Conversion Rate (BCR) is complete, 
   resulting at a ${castToPercentFormat(
     new_baseline_conversion_rate,
   )} conversion 
   rate. Now Upptic will begin subsequent testing of any creative variations. 
   Please maintain traffic audience and targeting consistency throughout the
   process.`;
};

export const newTopElementTestedDescription = (
  notificationData: NewTopElementTestedNotification["data"],
): string => {
  const { type, top_cvr, lift_over_control } = notificationData;

  return `New ${type.toUpperCase()} tested better than the Control at ${castToPercentFormat(
    top_cvr,
  )} conversion rate, which is a ${castToPercentFormat(
    lift_over_control,
  )} lift over Control.`;
};

export const newTopConversionDescription = (
  notificationData: NewTopConversionNotification["data"],
  applicationId: string,
) => {
  const {
    top_page_url,
    top_cvr,
    lift_over_control,
    control_cvr,
  } = notificationData;
  return (
    <span>
      A new{" "}
      <a target="_blank" rel="noopener noreferrer" href={top_page_url}>
        Top Converting Page
      </a>{" "}
      has been discovered with a {castToPercentFormat(top_cvr)} conversion rate,
      which is a {castToPercentFormat(lift_over_control)} lift over the Control
      conversion rate at {castToPercentFormat(control_cvr)}. You may want to{" "}
      <a
        href={
          authRoutes.APPLICATION_OVERVIEW.getUrlWithParams({
            id: applicationId,
          }) + "#app-preview"
        }
      >
        review the new Top Converting Page
      </a>{" "}
      and set it as the new Control for continuous improvement and testing.
    </span>
  );
};

export const eliminatedControlAssetDescription = (
  notification: EliminatedControlAssetNotification,
) => {
  return (
    <span>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={notification.data.asset_info.element_url}
      >
        The Control {notification.data.type}
      </a>{" "}
      has been eliminated from the test. The test will continue with the
      remaining {notification.data.remaining_assets} {notification.data.type}
      {notification.data.remaining_assets > 1 ? "s" : ""}. If media budget is a
      concern, you may want to end the test early by manually selecting a winner
      by hovering over the winning asset and selecting{" "}
      <a href={notification.data.zendesk}>"Set As Winner"</a>.
    </span>
  );
};

export const getNotificationDescription = (notification: Notification) => {
  let description = null;

  if (!isEmpty(notification.data)) {
    switch (notification.type) {
      case NotificationStatuses.NEW_TOP_ELEMENT_TESTED:
        description = newTopElementTestedDescription(notification.data);
        break;
      case NotificationStatuses.NEW_TOP_CONVERSION:
        description = newTopConversionDescription(
          notification.data,
          notification.application.id,
        );
        break;
      case NotificationStatuses.COMPLETED_TEST:
        description =
          "All tests have completed and there are no more creatives queued for further testing. Please upload more creatives to continue testing.";
        break;
      case NotificationStatuses.NEW_BCR_CALCULATION:
        description = newBcrCalculationDescription(notification.data);
        break;
      case NotificationStatuses.ELIMINATED_CONTROL_ASSET:
        description = eliminatedControlAssetDescription(notification);
        break;
      default:
        description = "";
        break;
    }
  }
  return description;
};

export const renderThumbnail = (notification: Notification) => {
  let thumbnail = null;
  let notificationData = null;
  if (!isEmpty(notification.data)) {
    switch (notification.type) {
      case NotificationStatuses.ELIMINATED_CONTROL_ASSET:
      case NotificationStatuses.NEW_TOP_ELEMENT_TESTED:
        notificationData = notification.data;
        const isTextAsset = notificationData.asset_info.url === undefined;

        if (isTextAsset) {
          thumbnail = (
            <Tooltip content={notificationData.asset_info.name}>
              <TextThumbnail>
                {truncate(
                  notificationData.asset_info.name ?? "Name not found.",
                  40,
                ).trim()}
              </TextThumbnail>
            </Tooltip>
          );
        } else {
          const thumbnailUrl =
            notificationData.asset_info.url ||
            youtube.getThumbnail(notificationData.asset_info.name);

          thumbnail = <ThumbnailImage src={thumbnailUrl} />;
        }
        break;
      case NotificationStatuses.NEW_TOP_CONVERSION:
        notificationData = notification.data;
        thumbnail = (
          <Container>
            <Iframe
              url={notificationData.top_page_url}
              frameBorder={0}
              display="block"
            />
            <IframeOverlay />
          </Container>
        );
        break;
      case NotificationStatuses.COMPLETED_TEST:
        notificationData = notification.data;
        thumbnail = (
          <Container>
            <Iframe
              url={notificationData.top_page_url}
              frameBorder={0}
              display="block"
            />
            <IframeOverlay />
          </Container>
        );
        break;
      case NotificationStatuses.NEW_BCR_CALCULATION:
        notificationData = notification.data;
        thumbnail = (
          <Container>
            <Iframe
              url={notificationData.control_page_url}
              frameBorder={0}
              display="block"
            />
            <IframeOverlay />
          </Container>
        );
        break;
      default:
        thumbnail = "";
        break;
    }
  }
  return thumbnail;
};

// -- STYLED
const ThumbnailImage = styled.img`
  height: 100px;
  width: auto;
`;

const TextThumbnail = styled.div`
  height: 100px;
  width: 100px;
  font-style: italic;
`;

const Container = styled.div`
  width: 100px;
  height: 100px;

  padding: 0;

  overflow: hidden;

  position: relative;
`;

const Iframe = styled(UnStyledIframe)`
  width: 360px;
  height: 740px;

  border: 0;

  -ms-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -o-transform: scale(0.4);
  -webkit-transform: scale(0.4);
  transform: scale(0.4);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
`;

const IframeOverlay = styled.div`
  position: absolute;

  width: 100px;
  height: 100px;

  border-radius: 2px;
`;
