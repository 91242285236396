import { ApplicationAsset } from "api/models";

import { ModalSetter } from "contexts/modalContext";

import { errorMessageHandler } from "utils/errorHandlers/errorMessageHandler";

import { GET_APPLICATION_OVERVIEW } from "api/queries";
import { ApolloError } from "@apollo/client";

export async function runAction(
  applicationId: string,
  asset: ApplicationAsset,
  mutation: any,
  modalSetter: ModalSetter,
  refetchQuery: any,
  setFullPageLoader: (isVisible: boolean) => void,
) {
  setFullPageLoader(true);
  try {
    await mutation({
      variables: {
        id: asset.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        refetchQuery,
        {
          query: GET_APPLICATION_OVERVIEW,
          variables: { applicationId },
        },
      ],
    });
  } catch (err) {
    modalSetter({
      type: "set",
      props: {
        title: "Error",
        content: errorMessageHandler(err as ApolloError),
      },
    });
  }
  setFullPageLoader(false);
}
