import React, { useState } from "react";
import styled from "styled-components";
import { Field, Form, FormSpy } from "react-final-form";
import { Flex, Box } from "@rebass/grid";

import { AuthSection, SignInWithGoogleButton } from "components/auth";
import { Button, FormLabel, TextInput } from "components/forms";
import validators from "utils/validators";
import formatters from "utils/formatters";

export interface FormData {
  password: string;
  passwordConfirm: string;
  firstName: string;
  lastName: string;
}

interface Props {
  onSubmit: (data: FormData) => void;
  title: string;
  submitButtonText: string;
  isNewUser?: boolean;
}

function UpdateForgottenPasswordPage({
  onSubmit,
  title,
  submitButtonText,
  isNewUser,
}: Props) {
  const defaultState: FormData = {
    password: "",
    passwordConfirm: "",
    firstName: "",
    lastName: "",
  };
  const [formData, setFormData] = useState(defaultState);

  return (
    <Form onSubmit={() => onSubmit(formData)}>
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FormSpy
            subscription={{ values: true }}
            onChange={(data: any) => setFormData(data.values)}
          />

          <AuthSection title={title}>
            <SignInWithGoogleButton />
            <span
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 0,
                display: "block",
              }}
            >
              or
            </span>
            {isNewUser ? (
              <>
                <Field
                  name="firstName"
                  validate={validators.required}
                  formatOnBlur
                  format={formatters.trim}
                >
                  {({ input, meta }) => {
                    return (
                      <div>
                        <FormLabel name="firstName">First Name</FormLabel>
                        <TextInput
                          type="text"
                          placeholder="Enter Your First Name"
                          maxLength={30}
                          light
                          error={meta.touched && meta.error}
                          {...input}
                        />
                      </div>
                    );
                  }}
                </Field>

                <Field
                  name="lastName"
                  validate={validators.required}
                  formatOnBlur
                  format={formatters.trim}
                >
                  {({ input, meta }) => {
                    return (
                      <div>
                        <FormLabel name="lastName">Last Name</FormLabel>
                        <TextInput
                          type="text"
                          placeholder="Enter Your Last Name"
                          maxLength={60}
                          light
                          error={meta.touched && meta.error}
                          {...input}
                        />
                      </div>
                    );
                  }}
                </Field>
              </>
            ) : null}
            <Field
              name="password"
              type="password"
              initialValue={formData.password}
              validate={(value: any) =>
                validators.required(value) ||
                validators.passwordLength(value) ||
                validators.passwordStrength(value)
              }
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="password">New Password</FormLabel>
                    <TextInput
                      placeholder="Enter Your New Password"
                      light
                      error={meta.touched && meta.error}
                      {...input}
                    />
                  </div>
                );
              }}
            </Field>

            <Field
              name="passwordConfirm"
              type="password"
              initialValue={formData.passwordConfirm}
              validate={(value: any) =>
                validators.required(value) ||
                validators.passwordMatch(value, formData.password)
              }
              formatOnBlur
              format={formatters.trim}
            >
              {({ input, meta }) => {
                return (
                  <div>
                    <FormLabel name="passwordConfirm">
                      Confirm New Password
                    </FormLabel>
                    <TextInput
                      placeholder="Repeat Your New Password"
                      light
                      error={meta.touched && meta.error}
                      {...input}
                    />
                  </div>
                );
              }}
            </Field>

            <Flex justifyContent="flex-end" alignItems="center" my={50}>
              <Box>
                <SubmitButton disabled={submitting} type="submit">
                  {submitButtonText}
                </SubmitButton>
              </Box>
            </Flex>
          </AuthSection>
        </form>
      )}
    </Form>
  );
}

const SubmitButton = styled(Button)`
  min-width: 240px;
`;

export default UpdateForgottenPasswordPage;
