import React, { useState, useContext } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { withRouter } from "react-router";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { omit } from "lodash";

import {
  ADD_FIRST_BILLING_DETAILS,
  GET_BASIC_PRICES_AND_USER_DOMAIN_STATUS,
} from "api";

import { detailsFormData } from "./DetailsSubPage/constants/detailsFormData";
import { optionsFormData } from "./OptionsSelectSubPage/constants/optionsFormData";
import { GetPricesAndDomainStatusResponse } from "./OptionsSelectSubPage/constants/getPricesAndDomainStatusResponse";

import { ModalContext } from "contexts";
import { useStores } from "contexts/storeContext";

import { createPaymentMethod } from "utils/stripe";

import { useRequestErrorHandler } from "v2/hooks/useRequestErrorHandler";
import { useFullPageLoader } from "v2/hooks/useFullPageLoader";

import { Spinner, Error } from "components";
import { OptionsSelectSubPage } from "./OptionsSelectSubPage/OptionsSelectSubPage";
import { DetailsSubPage } from "./DetailsSubPage/DetailsSubPage";

// -- COMPONENT
function FirstBillingContent(): JSX.Element {
  const { userStore } = useStores();
  const [fullPageLoader, setFullPageLoader] = useFullPageLoader();

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [optionsState, setOptionsState] = useState<optionsFormData | null>(
    null,
  );
  const [userDataState, setUserDataState] = useState<detailsFormData | null>(
    null,
  );

  const stripe = useStripe();
  const elements = useElements();

  const setModal = useContext(ModalContext);

  const { data, error, loading } = useQuery<GetPricesAndDomainStatusResponse>(
    GET_BASIC_PRICES_AND_USER_DOMAIN_STATUS,
  );

  const [saveFirstBilling] = useMutation(ADD_FIRST_BILLING_DETAILS);

  const handleError = useRequestErrorHandler();

  const onViewSubmit = async (
    options: optionsFormData,
    details?: detailsFormData,
  ) => {
    setFullPageLoader(true);

    const paymentMethodId = await createPaymentMethod(
      stripe,
      elements,
      setModal,
    );

    if (!paymentMethodId) {
      setFullPageLoader(false);
      return null;
    }

    const { subscriptionsNumber } = options;

    const variablesObject = {
      numberOfItems: subscriptionsNumber,
      subscriptions: true,
      payAsYouGo: false,
      paymentMethodId,
      ...omit(details, ["cardValid"]),
      accountId: userStore.asoAccountId,
    };

    const saveFirstBillingResponse = await handleError(
      saveFirstBilling({
        variables: variablesObject,
      }),
    );

    await userStore.loadUser();
    setFullPageLoader(false);
    if (saveFirstBillingResponse) userStore.setValidPaymentMethod(true);
  };

  const onOptionsFormSubmit = (formData: optionsFormData) => {
    setOptionsState(formData);
    setCurrentStep(2);
  };

  const onDetailsFormSubmit = (secondStepData: detailsFormData) => {
    setUserDataState(secondStepData);
    optionsState && onViewSubmit(optionsState, secondStepData);
  };

  const onStepBack = (secondStepData: detailsFormData) => {
    setUserDataState(secondStepData);
    setCurrentStep(1);
  };

  if (loading) {
    return <Spinner />;
  }

  if (!data || error) {
    return <Error errorElement={error?.message} />;
  }

  return (
    <>
      {fullPageLoader}
      {currentStep === 1 ? (
        <OptionsSelectSubPage
          prices={data.basicPrices}
          onSubmit={onOptionsFormSubmit}
          userDomainAlreadyExist={data?.isUserDomainAlreadyRegistered}
          initialData={optionsState}
        />
      ) : (
        <DetailsSubPage
          onSubmit={onDetailsFormSubmit}
          appSeatsNumber={
            optionsState?.subscriptions ? optionsState.subscriptionsNumber : 0
          }
          prices={data.basicPrices}
          onStepBack={onStepBack}
          initialData={userDataState}
        />
      )}
    </>
  );
}

export const FirstBillingPageContent = withRouter(FirstBillingContent);
