import React, { FC, useContext } from "react";
import { styled } from "@mui/system";

import { ApplicationUserContext } from "contexts";

import { GET_CUSTOMER_INVOICES } from "api";
import { Invoice } from "../constants/types";

import { convertDateToDisplayFormat } from "utils/dates";
import { convertToCurrency } from "utils/numbers";

import theme from "components/themes";

import { DynamicTable } from "v2/components/Table/DynamicTable";
import { SingleTableColumn } from "v2/components/Table/constants/singleTableColumn";
import { SingleCellProps } from "v2/components/Table/constants/singleCellProps";
import { Icon, Icons } from "components";

// -- COMPONENT
export const InvoiceList: FC<{}> = () => {
  const applicationUserCtx = useContext(ApplicationUserContext);

  const columns = React.useMemo<SingleTableColumn<Invoice>[]>(
    () => [
      {
        Header: "Invoice Billing Date",
        accessor: "billingStartDate",
        Cell: ({ cell }: SingleCellProps<Invoice>) => {
          const { billingStartDate, billingEndDate } = cell.row.original;

          return `${convertDateToDisplayFormat(
            billingStartDate,
          )} - ${convertDateToDisplayFormat(billingEndDate)}`;
        },
      },
      {
        Header: "Amount",
        accessor: "total",
        Cell: ({ cell }: SingleCellProps<Invoice>) => {
          const { total, currency } = cell.row.original;

          return convertToCurrency(total / 100, currency);
        },
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Invoice File",
        accessor: "linkToInvoice",
        Cell: ({ value }: SingleCellProps<Invoice>) => {
          return (
            <FileLink href={value} target="_blank">
              <Icon icon={Icons.faFileAlt} color={theme.colors.white} />
            </FileLink>
          );
        },
      },
    ],
    [],
  );

  return (
    <DynamicTable
      columns={columns}
      query={GET_CUSTOMER_INVOICES}
      responseDataFieldName="invoices"
      additionalParamsToRequest={{ userId: applicationUserCtx.userId }}
    />
  );
};

// -- STYLED
const FileLink = styled("a")`
  color: ${(props: any) => props.theme.colors.white};

  text-decoration: underline;

  &:hover {
    color: ${(props: any) => props.theme.colors.white};
  }
`;
