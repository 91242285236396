import * as React from "react";
import styled from "styled-components";
import { Flex, Box } from "@rebass/grid";
import hexToRgba from "hex-to-rgba";
import { observer } from "mobx-react";

import emptyPromoArt from "assets/images/empty-state-promo-art.svg";
import emptyImageMultiple from "assets/images/empty-state-multiple.png";
import emptyImageSingle from "assets/images/empty-state-single.png";

import { ordinalFormat } from "utils/numbers";

import { authRoutes } from "v2/routes/routesGroups/authRoutes";
import { Route } from "v2/api/classes/route";

import {
  Platform,
  Preview,
  AssetType,
  Variation,
  ApplicationControlAssets,
} from "api/models";

import { useStores } from "contexts/storeContext";

import {
  Section,
  DescriptionCard,
  ListItem,
  Tooltip,
  Icons,
  Icon,
} from "components";
import { Button, UploadImage } from "components/forms";
import themes from "components/themes";

import { UserPermissions } from "v2/api/types/UserPermissions";
import { PermissionRestriction } from "v2/components/PermissionRestriction";
import { useHasUserPermission } from "v2/hooks/useHasUserPermission";

// -- TYPES
type CurrentControlCreativesSectionProps = {
  applicationControlAssets: ApplicationControlAssets;
  controlVariation: Variation;
  platform: Platform;
  history?: any;
  applicationId: string;
};

// -- COMPONENT
function CurrentControlCreativesSection({
  applicationControlAssets,
  controlVariation,
  platform,
  history,
  applicationId,
}: CurrentControlCreativesSectionProps): JSX.Element {
  const { layoutStore } = useStores();
  const hasUserPerms = useHasUserPermission();

  const handleEdit = React.useCallback(
    (route: Route<any>, param: Record<string, number | string> = {}) => () => {
      history.push(route.getUrlWithParams({ id: applicationId, ...param }));
    },
    [applicationId, history],
  );

  const createEditHandler = React.useCallback(
    (requiredPerms: UserPermissions[], editHandler: () => void) => {
      return hasUserPerms(requiredPerms) ? editHandler : null;
    },
    [hasUserPerms],
  );

  const renderControlPreviews = (previews: Preview[]): React.ReactNode => {
    if (!previews || !previews.length) {
      return null;
    }
    let previewsToRender = previews
      .slice()
      .sort((preview1, preview2) => preview1.position - preview2.position);
    previewsToRender = previewsToRender[0].isControlSet
      ? [previewsToRender[0]]
      : previewsToRender;

    return previewsToRender.map((preview, index: number) => {
      const onEditHandeler = createEditHandler(
        [UserPermissions.updateAppPlatformProfile],
        handleEdit(authRoutes.APPLICATION_CONTROL_PREVIEWS, {
          position: preview.position,
        }),
      );

      return (
        <StyledBox key={`control-variation-screen-${index}`}>
          <DescriptionCard
            title={
              preview.isControlSet
                ? "Set of Screens"
                : `${ordinalFormat(index + 1)} Screen`
            }
          >
            <Flex justifyContent="center">
              <UploadImage
                image={preview.url}
                size={260}
                onEdit={onEditHandeler}
                isVideoPreview={preview.isVideo}
                isStack={preview.isControlSet}
                previewUrl={preview.previewLink}
              />
            </Flex>
          </DescriptionCard>
        </StyledBox>
      );
    });
  };

  const renderAdditionalScreens = (
    additionalsPreviews: Preview[],
  ): React.ReactNode => {
    const firstAdditionalPreview = Array.isArray(additionalsPreviews)
      ? additionalsPreviews[0]
      : null;

    const CardTitle: React.ReactNode = (
      <StyledCardTitle>
        {`Additional Screens (${
          firstAdditionalPreview ? additionalsPreviews.length : 0
        })`}
        <Tooltip
          content="Additional screens are not involved in tests cycle."
          placement="top"
        >
          <Icon
            icon={Icons.faQuestionCircle}
            color={hexToRgba(themes.colors.white, 0.4)}
          />
        </Tooltip>
      </StyledCardTitle>
    );

    const onEditHandeler = createEditHandler(
      [UserPermissions.updateAppPlatformProfile],
      handleEdit(authRoutes.APPLICATION_CONTROL_ADDITIONALS_PREVIEWS),
    );

    return (
      <StyledBox>
        <DescriptionCard title={CardTitle}>
          <Flex justifyContent="center">
            {firstAdditionalPreview && firstAdditionalPreview.url ? (
              <UploadImage
                image={firstAdditionalPreview.url}
                size={260}
                onEdit={onEditHandeler}
                isStack
              />
            ) : (
              <div>
                <EmptyImage src={emptyImageMultiple} />
                <PermissionRestriction
                  requiredPermissions={[
                    UserPermissions.updateAppPlatformProfile,
                  ]}
                >
                  <CreateButton
                    outline={true}
                    onClick={handleEdit(
                      authRoutes.APPLICATION_CONTROL_ADDITIONALS_PREVIEWS,
                    )}
                  >
                    Add Additionals Screens
                  </CreateButton>
                </PermissionRestriction>
              </div>
            )}
          </Flex>
        </DescriptionCard>
      </StyledBox>
    );
  };

  const renderPromoOrFG = React.useMemo((): React.ReactNode => {
    let element = controlVariation.promoArt;
    let elementUrl = element?.file.url || emptyPromoArt;
    let cardDescription = "Promo Art";
    let editUrl = authRoutes.APPLICATION_CONTROL_PROMO_ARTS;

    if (platform === Platform.android) {
      element = controlVariation.featureGraphic;
      elementUrl = element?.file.url || emptyImageSingle;
      cardDescription = "Feature Graphic";
      editUrl = authRoutes.APPLICATION_CONTROL_FEATURE_GRAPHIC;
    }

    const addButtonLabel = `Add ${cardDescription}`;

    const onEditHandeler = createEditHandler(
      [UserPermissions.updateAppPlatformProfile],
      handleEdit(editUrl),
    );

    return (
      <StyledBox>
        <DescriptionCard title={cardDescription}>
          <Flex justifyContent="center" flexDirection="column">
            {element ? (
              <UploadImage
                image={elementUrl}
                size={260}
                onEdit={onEditHandeler}
              />
            ) : (
              <>
                <EmptyImage src={elementUrl} />
                <PermissionRestriction
                  requiredPermissions={[
                    UserPermissions.updateAppPlatformProfile,
                  ]}
                >
                  <CreateButton outline={true} onClick={handleEdit(editUrl)}>
                    {addButtonLabel}
                  </CreateButton>
                </PermissionRestriction>
              </>
            )}
          </Flex>
        </DescriptionCard>
      </StyledBox>
    );
  }, [controlVariation, platform, handleEdit, createEditHandler]);

  return (
    <Box mt={20}>
      <Section title="Current Control Creatives" transparent>
        <FlexGrid flexWrap="wrap">
          <IconBox>
            <DescriptionCard title="Icon">
              <UploadImage
                image={controlVariation.icon.file.url}
                onEdit={createEditHandler(
                  [UserPermissions.updateAppPlatformProfile],
                  handleEdit(authRoutes.APPLICATION_CONTROL_ICONS),
                )}
              />
            </DescriptionCard>
          </IconBox>

          <TextsBox isMenuClosed={layoutStore.isMenuCallapsed}>
            <DescriptionCard title="Title">
              <ListItem
                showCounter={false}
                active={false}
                onEdit={createEditHandler(
                  [UserPermissions.updateAppPlatformProfile],
                  handleEdit(authRoutes.EDIT_CONTROL_TEXTS, {
                    type: AssetType.title,
                  }),
                )}
              >
                {applicationControlAssets.title.name}
              </ListItem>
            </DescriptionCard>
          </TextsBox>

          {platform === Platform.ios && (
            <TextsBox isMenuClosed={layoutStore.isMenuCallapsed}>
              <DescriptionCard title="Subtitle">
                <ListItem
                  showCounter={false}
                  active={false}
                  onEdit={createEditHandler(
                    [UserPermissions.updateAppPlatformProfile],
                    handleEdit(authRoutes.EDIT_CONTROL_TEXTS, {
                      type: AssetType.subtitle,
                    }),
                  )}
                >
                  {applicationControlAssets.subtitle?.name}
                </ListItem>
              </DescriptionCard>
            </TextsBox>
          )}

          <TextsBox isMenuClosed={layoutStore.isMenuCallapsed}>
            <DescriptionCard title="Description">
              <ListItem
                showCounter={false}
                active={false}
                onEdit={createEditHandler(
                  [UserPermissions.updateAppPlatformProfile],
                  handleEdit(authRoutes.EDIT_CONTROL_TEXTS, {
                    type: AssetType.description,
                  }),
                )}
              >
                {applicationControlAssets.description.name}
              </ListItem>
            </DescriptionCard>
          </TextsBox>

          {renderControlPreviews(applicationControlAssets.previews)}
          {renderAdditionalScreens(controlVariation.additionalPreviews)}
          {renderPromoOrFG}
        </FlexGrid>
      </Section>
    </Box>
  );
}

// -- STYLED
const FlexGrid = styled(Flex)`
  margin-left: -15px;
  margin-right: -15px;
`;

const StyledCardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyImage = styled.img`
  width: 260px;
  margin-bottom: 30px;
`;

const CreateButton = styled(Button)`
  margin: 0 auto;
  height: 35px;
`;

const StyledBox = styled(Box)`
  padding: 0 15px;

  margin-bottom: 20px;
`;

const IconBox = styled(StyledBox as any)`
  padding: 0 15px;
  margin-bottom: 20px;

  width: auto;
`;

const TextsBox = styled(StyledBox as any)<{ isMenuClosed: boolean }>`
  width: 25%;

  flex-grow: 1;

  ${(props) =>
    !props.isMenuClosed &&
    `
    @media(max-width: 1420px) {
      flex-grow: 1;
      width: 33%;
      max-width: 50%;
      min-width: 250px;
    }
  `}
`;

export default observer(CurrentControlCreativesSection);
