import React from "react";

import { Platform } from "api";

import { TextAssetComponentsProps } from "../constants/textAssetComponentsProps";

import { ApplicationAssetsString } from "components/application";

class TextAssetStepDescriptions extends React.PureComponent<
  TextAssetComponentsProps
> {
  private androidDescriptionTooltip: string = `For Android descriptions, the first paragraph (or first 80 characters followed by "...", whichever comes first), will be shown as the short description on the main app download page.`;

  public render() {
    const {
      title = "Add App Descriptions",
      data,
      disableControl,
      onChange,
      maxUploadAssets,
      platform,
    } = this.props;

    return (
      <>
        <ApplicationAssetsString
          title={title}
          titleTooltip={
            platform === Platform.android ? this.androidDescriptionTooltip : ""
          }
          inputTitle="Enter App Description"
          listTitle="Added Descriptions"
          placeholder="Enter Description"
          isInputArea={true}
          maxLength={5000}
          data={data}
          onChange={onChange}
          disableControl={disableControl}
          maxUploadAssets={maxUploadAssets}
        />
      </>
    );
  }
}

export default TextAssetStepDescriptions;
