import React, { useContext, useState } from "react";
import OtpPage from "./OtpPage";
import { useParams } from "react-router-dom";
import { useSignIn } from "v2/hooks/useSignIn";

function OtpExternalContainer() {
  const { token } = useParams<any>();
  const handleSubmit = useSignIn({ useExternalOtp: true });
  return <OtpPage onSubmit={handleSubmit} initialValues={{ token }} />;
}

export default OtpExternalContainer;
